import React from 'react'
import TextField from '@mui/material/TextField'

function HMTextField(props) {

    const fieldStyle = {
        width: props.width || '100%',
        fontWeight: props.fontWeight,
        margin: props.margin || '3% 0', 
        backgroundColor: props.bgColor,
        borderRadius: props.borderRadius,
        letterSpacing: props.letterSpacing,
        fontSize: props.fontSize || '12px !important',
        '&:active': {
            border: props.borderActive,
        }
    }

    return (
        <TextField 
            fullWidth
            id={props.id} 
            sx={fieldStyle}
            name={props.name} 
            label={props.label} 
            value={props.value}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            required={props.required}
            inputRef={props.inputRef}
            disabled={props.disabled}
            maxLength={props.maxLength}
            type={props.type || 'text'}
            onClick={props.handleClick}
            placeholder={props.placeholder}
            onTouchEnd={props.handleTouchEnd}
            className={`HM-text-field ${props.className}`}
        />
    )
}

export default HMTextField
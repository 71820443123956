import axios from 'axios'

const API_URL_CATEGORIES = '/api/v1/categories/private'
const API_URL_CATEGORIES_PUBLIC = '/api/v1/categories'

// Get all categories
const getCategories = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_CATEGORIES, config)

    const data = response.data
    
    if (data) {
        localStorage.setItem('categories', JSON.stringify(data))
    }
    
    return data
}

// Get all only sub categories
const getSubCategories = async () => {
    const response = await axios.get(`${API_URL_CATEGORIES_PUBLIC}/sub_categories`)

    const data = response.data
    
    if (data) {
        localStorage.setItem('subCategories', JSON.stringify(data))
    }
    
    return data
}

// Get all public categories
const getPublicCategories = async () => {
    const response = await axios.get(API_URL_CATEGORIES_PUBLIC)

    const data = response.data
    
    if (data) {
        localStorage.setItem('publicCategories', JSON.stringify(data))
    }
    
    return data
}

// Create a new category
const createCategory = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL_CATEGORIES, data, config)

    return response.data
}

// Update a category
const updateCategory = async (category, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_CATEGORIES}/${category._id}`, category, config)

    return response.data
}

// Delete a category
const deleteCategory = async (categoryID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_CATEGORIES}/${categoryID}`, config)

    return response.data
}

// Delete a categories
const deleteCategories = async (categoryIds, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: categoryIds
    }
    const response = await axios.delete(API_URL_CATEGORIES, config)

    return response.data
}

const categoryService = {
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    deleteCategories,
    getSubCategories,
    getPublicCategories,
}
export default categoryService

import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Grid } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import {
    fontSize,
    textStyle
} from '../tools/Styles'
import {
    gray
} from '../../../hooks/useColors'

function HMBreadcrumbs(props) {
    const breadTextStyle = {
        ...textStyle,
        margin: 'auto',
        lineHeight: 'normal'
    }
    const gridStyle = {
        display: 'flex',
        padding: props.padding,
        float: props.float || 'left',
        margin: props.margin || 'auto',
    }

    return (
        <Grid sx={gridStyle}>
            {props.options.map((option, index) => (
                option.link ? (
                    <Typography key={index} component='span' sx={{display: 'flex'}}>
                        <Link to={option.link}>
                            <Typography 
                                sx={breadTextStyle}
                                component='span'
                            >{option.text}</Typography> 
                        </Link>
                        <ChevronRightOutlinedIcon sx={{ fontSize: `${fontSize + 4}px`, color: gray}} />
                    </Typography>
                ) : (
                    <Typography 
                        key={index}
                        component='span'
                        sx={breadTextStyle}
                    >
                        {option.text}
                    </Typography>
                )
            ))}
        </Grid>
    )
}

export default HMBreadcrumbs
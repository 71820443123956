import React, { useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Grid,
  Typography
} from '@mui/material'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import { 
  getInsurer,
  resetInsurer, 
  deleteInsurer,
  updateInsurer,
} from '../../../features/insurers/insurerSlice'
import { 
  getInsurerWithProducts,
  resetRelationProductsInsurers
} from '../../../features/relationProductsInsurers/relationProductsInsurersSlice'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMAccordion from '../../../components/common/layout/HMAccordion'
import DataTable from '../../../components/common/layout/DataTable'
import Section from '../../../components/common/layout/Section'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { isInTitle } from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  red,
  gray,
  blue,
  white,
  green,
  orange,
  lightRed,
  lightBlue,
  lightBlack,
  lightGreen,
  lightOrange,
  getRandomColor
} from '../../../hooks/useColors'

function Insurer() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const insurerState = useSelector(state => state.insurer)
  const relationsState = useSelector(state => state.relationProductsInsurers)
  const {
    insurerProducts,
    isInsurerProducts
  } = relationsState

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [insurer, setInsurer] = useState({})
  const [isDelete, setIsDelete] = useState(false)
  const [activeState, setActiveState] = useState(false)

  useEffect(() => {
    dispatch(getInsurer(id))
    dispatch(getInsurerWithProducts(id))
  }, [dispatch, id])

  useEffect(() => {
    const { 
      isOne,
      message,
      isError,
      isUpdated,
      isDeleted,
    } = insurerState

    if ((isOne || isUpdated) && Object.keys(insurerState.insurer).length) {
      setInsurer(insurerState.insurer)
    }

    if (isError) {
      toast.error(message)
      dispatch(resetInsurer())
    } else if (isOne) {
      dispatch(resetInsurer())
      setActiveState(insurerState.insurer.status.toLowerCase() === 'active' ? true : false)
    } else if (isUpdated) {
      toast.success(`Insurer status is successfully changed to ${insurerState.insurer.status}`)
      dispatch(resetInsurer())
      setActiveState(insurerState.insurer.status.toLowerCase() === 'active' ? true : false)
    } else if (isDeleted) {
      toast.success(message)
      dispatch(resetInsurer())
      navigate(`/admin/insurers`)
    }

    if (isInsurerProducts) {
      dispatch(resetRelationProductsInsurers())
    }
  
  }, [dispatch, navigate, insurerState, isInsurerProducts])

  const breadOptions = [
    { text: 'Insurers', link: '/admin/insurers' },
    { text: insurer['name'] && insurer.name, link: null }
  ]

  const handleEdit = () => {
    navigate(`/admin/insurers/edit/${insurer._id}`)
  }
  const handleAdd = () => {
    navigate('/admin/insurers/create')
  }
  const handleEditProduct = (id) => {
    navigate(`/admin/products/edit/${id}`)
  }

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const fields = []

  const columns = [
    { field: 'image', headerName: 'Image', sortable: false},
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'hasTax', headerName: 'Tax', sortable: false},
    { field: 'needsPrescription', headerName: 'Rx', sortable: false},
    { field: 'insurancePrice', headerName: 'Price', sortable: false},
  ] 
  
  columns.forEach((column) => {
    fields.push(column.field)
  })

  const handleActiveStateModal = () => {
    setOpen(true)
    setActiveState(!activeState)
    setTitle(`${insurer.status.toLowerCase() !== 'active' ? 'Activate' : 'Deactivate'} Insurer`)
  }
  const handleActiveState = () => {
    const updatedInsurer = {
      _id: insurer._id,
      status: insurer.status === 'active' ? 'inactive' : 'active'
    }
    dispatch(updateInsurer(updatedInsurer))
    setOpen(false)
  }

  const handleDeleteModal = () => {
    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Insurer')
  }
  const handleDelete = () => {
    dispatch(deleteInsurer(insurer._id))
    handleClose()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setActiveState(insurer.status.toLowerCase() === 'active' ? true : false)
  }

  return (
    <Page>
      {insurerState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(insurer).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMToogleButton 
                status={activeState}
                handleChange={handleActiveStateModal}
                text={activeState ? 'Active' : 'Inactive'}
              />
              <HMButton 
                type='button'
                bgColor={blue}
                isResponsive={true}
                handleClick={handleEdit}
                icon={<EditIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Edit</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add insurer</Typography>} 
              />
              {!Object.keys(insurerProducts).length && !relationsState.isLoading && insurer.status.toLowerCase() !== 'active' ? (
                <HMButton 
                  type='button'
                  bgColor={red}
                  isResponsive={true}
                  handleClick={handleDeleteModal}
                  icon={<DeleteIcon sx={iconStyle} />} 
                  text={<Typography sx={textStyle}>Delete</Typography>} 
                />
              ) : <></>}
            </HMBox>
          </FullWidthContainer>
          <HMModal 
            open={open} 
            title={title}
            handleClose={handleClose} 
            colors={{lightBlack, lightBlue}}
          >
            {isDelete ? (
              <Typography sx={deleteTextStyle}>
                Are you sure your want to delete: 
              </Typography>
            ) : isInTitle(title, 'activate', 'deactivate') ? (
              <Typography sx={deleteTextStyle}>
                Are you sure your want to {activeState ? 'deactivate' : 'activate'}: 
              </Typography>
            ) : <></>}
            <Typography sx={deleteItemTextStyle}>
              {insurer.name} ?
            </Typography>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='10px 0'
            > 
              <HMButton 
                type='button'
                bgColor={gray}
                handleClick={handleClose}
                margin='auto auto auto 0'
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                margin='auto 0 auto auto'
                bgColor={
                  isDelete || (
                    isInTitle(title, 'activate', 'deactivate') && 
                    insurer.status.toLowerCase() === 'active'
                  ) ? red : green
                }
                handleClick={
                  isDelete ? handleDelete 
                  : isInTitle(title, 'activate', 'deactivate') && handleActiveState
                }
                text={<Typography sx={textStyle}>Yes, {
                  isDelete ? 'Remove' 
                  : (isInTitle(title, 'activate', 'deactivate') && insurer.status.toLowerCase() === 'active') ? 'Deactivate' 
                  : (isInTitle(title, 'activate', 'deactivate') && insurer.status.toLowerCase() !== 'active') && 'Activate'
                }!</Typography>}
              />
            </HMBox>
          </HMModal>
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid 
              container spacing={2} 
            >
              <Grid item xs={12} sm={8}>
                <HMBox 
                  width='100%'
                  display='flex'
                >
                  {insurer['logo'] ? (
                    <img 
                      alt={insurer.name} 
                      src={insurer.logo}
                      className='small-image'  
                      style={{margin: 'auto'}}
                    />
                  ) : (
                    <Grid className='small-logo'>
                      <HMText 
                        float='none'
                        width='100%'
                        margin='auto'
                        height='100%'
                        color={white}
                        fontSize='34px'
                        fontWeight={700}
                        textAlign='center'
                        padding='0 10px 0 8px'
                        bgColor={getRandomColor()}
                        text={insurer.name.substr(0,1).toUpperCase()}
                      />
                    </Grid>
                  )}
                  <HMBox 
                    width='100%'
                    height='100%'
                    padding='0px'
                    margin='auto auto auto 10px'
                  >
                    <Title 
                      width='100%'
                      textAlign='left'
                      margin='2px 0 0 0'
                      title={insurer.name}
                      size={fontSize - 2}
                    />
                    <HMText 
                      margin='auto'
                      padding='0 3px'
                      borderRadius={5}
                      text={insurer.type}
                      bgColor={lightBlue}
                      fontSize={`${fontSize - 2}px`}
                    />
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <HMBox 
                  width='100%'
                  height='50px'
                  display='flex'
                >
                  <HMBox 
                    height='100%'
                    display='flex'
                    margin='auto 0 auto auto'
                    width={isTabletScreen ? '100%' : 'auto'}
                    flexDirection={`${isTabletScreen ? 'row' : 'column'}`}
                    border={`${isTabletScreen && `1px solid ${lightBlue}`}`}
                  >
                    <HMText 
                      text='Origin'
                      margin={`${isTabletScreen ? 'auto 0' : 'auto'}`}
                    />
                    <Title 
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      title={`${insurer.origin}`}
                      margin={`${isTabletScreen ? 'auto 0 auto auto' : 'auto'}`}
                      color={insurer.origin.toLowerCase() === 'local' ? blue : orange}
                      bgColor={insurer.origin.toLowerCase() === 'local' ? lightBlue : lightOrange}
                    />
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginBottom: '30px' }}>
                <HMText 
                  height='30px'
                  zIndex={999}
                  bgColor={white}
                  fontWeight={700}
                  className='fade-in'
                  text='About Insurer'
                  margin='10px 0 -15px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMBox
                  width='100%'
                  height='90%'
                  display='flex'
                  bgColor={lightBlue}
                  padding='20px 10px 0 10px'
                >
                  <HMText
                    margin='auto 0'
                    text={insurer.description ? parse(insurer.description) : 'Your store description is not set!'}
                  />
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginBottom: '30px' }}>
                <HMText 
                  height='30px'
                  zIndex={999}
                  bgColor={white}
                  fontWeight={700}
                  text='Requirements'
                  className='fade-in'
                  margin='10px 0 -15px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMBox 
                  width='100%'
                  height='90%'
                  display='flex'
                  bgColor={lightBlue}
                  flexDirection='column'
                  padding='20px 10px 0 10px'
                >
                  <HMBox 
                    width='100%'
                    height='auto'
                    margin='5px 0'
                    display='flex'
                    bgColor={white}
                    flexDirection='row'
                  >
                    <HMText 
                      margin='auto 0'
                      text='Fingerprint'
                    />
                    <Title 
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      margin='auto 0 auto auto'
                      color={!insurer.needsFingerPrints ? red : green}
                      title={insurer.needsFingerPrints ? 'Yes' : 'No'}
                      bgColor={!insurer.needsFingerPrints ? lightRed : lightGreen}
                    />
                  </HMBox>
                  <HMBox 
                    width='100%'
                    height='auto'
                    margin='5px 0'
                    display='flex'
                    bgColor={white}
                    flexDirection='row'
                  >
                    <HMText 
                      margin='auto 0'
                      text='Insurace Card'
                    />
                    <Title 
                      title='Yes'
                      color={green}
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      bgColor={lightGreen}
                      margin='auto 0 auto auto'
                    />
                  </HMBox>
                  <HMBox 
                    width='100%'
                    height='auto'
                    margin='5px 0'
                    display='flex'
                    bgColor={white}
                    flexDirection='row'
                  >
                    <HMText 
                      margin='auto 0'
                      text='Prescription'
                    />
                    <Title 
                      title='Yes'
                      color={green}
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      bgColor={lightGreen}
                      margin='auto 0 auto auto'
                    />
                  </HMBox>
                </HMBox>
              </Grid>
            </Grid>
          </Section>

          <Section 
            padding={10}
            bgColor={white}
          >
            <Grid>
              {(!Object.keys(insurerProducts).length && !relationsState.isLoading) ? (
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={lightBlue}
                >
                  <HMText 
                    margin='auto'
                    text='This insurer has no products assigned yet!'
                  />
                </HMBox>
              ) : Object.keys(insurerProducts).length ? (
                <HMAccordion 
                  title='Products'
                  label='products'
                  listings={insurerProducts.products.length}
                >
                  <DataTable
                    title='Products'
                    fields={fields}
                    columns={columns}
                    defaultRowsNum={20}
                    disableSelect={true}
                    disableDelete={true}
                    searchBgColor={lightBlue}
                    viewLink='/admin/products'
                    handleEdit={handleEditProduct}
                    data={insurerProducts.products}
                  />
                </HMAccordion>
              ) : <></>}
            </Grid>
          </Section>                
        </>
      ) : <></>}
    </Page>
  )
}

export default Insurer
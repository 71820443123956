
import React from 'react'
import ReactApexChart from 'react-apexcharts'

function HMSparkLine(props) {
  const data = [
    {
      type: 'area',
      name: 'change ',
      data: props.data || [0, 40, 20, 60],
    },
  ]

  const options = {
    chart: {
      height: 280,
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false,
      },
    },
    colors: props.colors || ['#16D39A', '#16D39A'],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
        enabled: false
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'light',
            type: 'vertical',
          shadeIntensity: 1,
        //   gradientToColors: '#16D39A',
          inverseColors: false,
          opacityFrom: 0.6,
          opacityTo: .8,
          stops: [0, 100],
        //   colorStops: ['#16D39A']
        },
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        // colors: ['#16D39A'],
        width: 1,
        dashArray: 0,      
    },
    grid: {
      show: false,
    },
  }
    
  return (
    <ReactApexChart
      width='100%'
      height='100%'
      series={data}
      options={options}
    />
  )
}

export default HMSparkLine;
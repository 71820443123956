import React from 'react'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import HMBox from './HMBox'
import DropdownFilter from '../tools/DropdownFilter'
import HMRadioButton from '../tools/HMRadioButton'
import HMDatePicker from '../tools/HMDatePicker'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
    textStyle,
    addIconStyle,
    renoveIconStyle
} from '../tools/Styles'
import {
    blue,
    white,
    green,
    lightBlue,
    lightOrange
} from '../../../hooks/useColors'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

function HMListingCreationFields(props) {
    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 720 ? true : false

    const {
        price,
        product,
        alertLevel,
        soldOnline,
        onlinePrice,
        availableStock,
    } = props.formData

    const sellOnline = {
        question: 'Sell online?',
        choices: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
    }

    const minExpirationDate = new Date()
    minExpirationDate.setDate(minExpirationDate.getDate() + 16)

    return (
        <Grid 
            component='form'
            container spacing={2} 
            onSubmit={props.handleSubmit}
        >
            <Grid item xs={12} sm={12} className='HM-dropdown listing'>
                {!props.areOptionsSet && props.isCreateLising ? (
                    <HMText 
                        margin='10px 0'
                        text='Loading...'
                    />
                ) : (
                    <DropdownFilter 
                        type='text'
                        field='name'
                        required={true}
                        value={product}
                        label='Product name'
                        prompt='Select product'
                        options={props.availableOptions}
                        onChange={props.handleProductChange}
                        disabled={props.isCreateLising ? false : true}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{display : 'flex'}}>
                <HMRadioButton 
                    name='soldOnline'
                    padding='2px 5px'
                    value={soldOnline}
                    flexDirection='row'
                    label={sellOnline.question}
                    options={sellOnline.choices}
                    onChange={props.handleChange}
                    labelId='tax-applicable-radio-group'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <HMTextField 
                    width='100%'
                    name='price'
                    type='number'
                    value={price}
                    required={true}
                    label='Listing price'
                    onChange={props.handleChange}
                    placeholder='Enter listing price'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <HMTextField 
                    width='100%'
                    type='number'
                    name='onlinePrice'
                    value={onlinePrice}
                    onChange={props.handleChange}
                    label='Online listing price'
                    placeholder='Enter online listing price'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <HMTextField 
                    width='100%'
                    type='number'
                    name='alertLevel'
                    value={alertLevel}
                    onChange={props.handleChange}
                    label='Stock alert level'
                    placeholder='Enter stock alert level'
                />
            </Grid>
            {availableStock.map((stock, index) => (
                <Grid key={index} item xs={12} sm={12} md={6}>
                    <HMBox 
                        padding='0' 
                        width='100%' 
                        display='flex' 
                        position='relative'
                        margin={!isTabletScreen && '10px auto'}
                    >
                        {(props.stockCount > 0 && index === 0) ? (
                            <HMBox
                                left='45%'
                                zIndex={999}
                                position='absolute'
                                bgColor={lightOrange}
                                marginTop={isTabletScreen ? '-22px' : '-14px'}
                            >
                                <HMText 
                                    margin='auto'
                                    fontWeight={500}
                                    text={props.stockCount}
                                />
                            </HMBox>
                        ) : <></>}
                        <HMBox
                            width='100%'
                            margin='auto'
                            display='flex'
                            className='fade-in'
                            bgColor={lightBlue}
                            padding='0 10px 5px 10px'
                            flexDirection={isTabletScreen ? 'column' : 'row'}
                        >
                            <HMTextField
                                type='number'
                                name='quantity'
                                required={true}
                                bgColor={white}
                                label='Stock quantity'
                                placeholder='Enter stock quantity'
                                width={isTabletScreen ? '100%' : '50%'}
                                value={stock.quantity ? stock.quantity : ''}
                                onChange={(e) => props.handleStocks(index, e)}
                            /> 
                            <HMBox
                                width='100%'
                                height='100%'
                                margin='auto'
                                display='flex'
                                className='stock-date'
                            >
                                <HMText 
                                    minWidth='70px'
                                    width='max-content'
                                    text='Epiration date'
                                    margin={isTabletScreen ? 'auto 0' : 'auto 5px auto auto'}
                                />
                                <HMDatePicker
                                    margin='auto 0 auto auto'
                                    minDate={dayjs(minExpirationDate)}
                                    onChange={(e) => props.handleStocks(index, e)}
                                    value={stock.expiresOn ? dayjs(stock.expiresOn) : dayjs(minExpirationDate)}
                                />
                            </HMBox>
                        </HMBox>
                        {availableStock.length > 1 && (
                            <IconButton 
                                onClick={() => props.handleRemoveStock(index)}
                                sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                            >
                                <RemoveIcon sx={renoveIconStyle} />
                            </IconButton>
                        )}  
                        {availableStock.length < 10 && (
                            <IconButton   
                                onClick={() => props.handleAddStock(index)} 
                                sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                            >
                                <AddIcon sx={addIconStyle} />
                            </IconButton>
                        )}  
                    </HMBox>
                </Grid>
            ))}
            <Grid item xs={12} sm={12}>
                <HMButton 
                    width='100%'
                    type='submit'
                    float='right'
                    padding='6px'
                    margin='20px 0 5px 0'
                    paddingActive='5.5px'
                    disabled={props.disabled}
                    bgColor={props.isCreateLising ? green : blue}
                    text={<Typography sx={textStyle}>{props.isCreateLising ? 'Add Listing' : 'Update Listing'}</Typography>}
                />
            </Grid> 
        </Grid>
    )
}

export default HMListingCreationFields
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { 
  getMySupplier,
} from '../../features/suppliers/supplierSlice'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import Page from '../../components/common/layout/Page'
import HMBox from '../../components/common/layout/HMBox'
import HMSpiner from '../../components/common/tools/HMSpiner'
import {
  white
} from '../../hooks/useColors'

function Dashboard() {
  const dispatch = useDispatch()
  const { supplierId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const supplierState = useSelector((state) => state.supplier)
  const { mySuppliers } = supplierState



  useEffect(() => {
    if (user) {
      if (mySuppliers.length) {
          dispatch(getMySupplier(supplierId))
      }
    }
  }, [dispatch, user, mySuppliers, supplierId])

  return user ? (
    <Page>
      {supplierState.isLoading ? (
          <HMSpiner 
              size={60}
              zIndex={999}
              width='100%'
              height='80vh'
              margin='auto'
              position='absolute'
              bgColor='transparent'
          />
      ) : <></>}
      <Section 
        padding={10} 
        bgColor={white}
      >
        Supplier Dashboard
      </Section>
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing supplier content'
      />
    </Section>
  )
}

export default Dashboard
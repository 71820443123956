import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import HMDonutChart from '../../analytics/HMDonutChart'
import SimpleCard from '../../analytics/SimpleCard'
import HMPieChart from '../../analytics/HMPieChart'
import DotAndLabel from '../../analytics/DotAndLabel'
import HMDataRange from '../../analytics/HMDataRange'
import ProgressBarWithData from '../../analytics/ProgressBarWithData'
import RecentActivities from '../../analytics/RecentActivities'
import HMBox from '../../common/layout/HMBox'
import Title from '../../common/tools/Title'
import HMText from '../../common/tools/HMText' 

import { 
  fontSize
} from '../../../components/common/tools/Styles'
import {
  blue,
  white,
  green,
  darkBlue,
  lightBlue,
  lightBlack,
  middleOrange,
  superLightBlue,
} from '../../../hooks/useColors'
import { currencies } from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function Overview() {
  const { storeId } = useParams()
  const { visible } = useSelector((state) => state.nav)
  const { storeSales } = useSelector((state) => state.store)

  const [endDate, setEndDate] = useState(dayjs())
  const [isFilter, setIsFilter] = useState(false)
  const [isAllTime, setIsAllTime] = useState(false)
  const [isThisWeek, setIsThisWeek] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [isThisYear, setIsThisYear] = useState(false)
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [startDate, setStartDate] = useState(dayjs())
  const [currentSales, setCurrentSales] = useState([])
  const [isThisMonth, setIsThisMonth] = useState(false)
  const [isSmallWidth, setIsSmallWidth] = useState(false)
  const [posTotalRevenue, setPOSTotalRevenue] = useState(0)
  const [todayPOSRevenue, setTodayPOSRevenue] = useState(0)
  const [posCreditRevenue, setPOSCreditRevenue] = useState(0)
  const [onlineCashRevenue, setOnlineCashRevenue] = useState(0)
  const [todayOnlineRevenue, setTodayOnlineRevenue] = useState(0)
  const [onlineTotalRevenue, setOnlineTotalRevenue] = useState(0)
  const [posInsuranceRevenue, setPOSInsuranceRevenue] = useState(0)
  const [yesterdayPOSRevenue, setYesterdayPOSRevenue] = useState(0)
  const [onlineInsuranceRevenue, setOnlineInsuranceRevenue] = useState(0)
  const [yesterdayOnlineRevenue, setYesterdayOnlineRevenue] = useState(0)
  const [totalPOSRevenuePercentage, setTotalPOSRevenuePercentage] = useState(0)
  const [posCreditRevenuePercentage, setPOSCreditRevenuePercentage] = useState(0)
  const [onlineCashRevenuePercentage, setOnlineCashRevenuePercentage] = useState(0)
  const [totalOnlineRevenuePercentage, setTotalOnlineRevenuePercentage] = useState(0)
  const [posInsuranceRevenuePercentage, setPOSInsuranceRevenuePercentage] = useState(0)
  const [onlineInsuranceRevenuePercentage, setOnlineInsuranceRevenuePercentage] = useState(0)

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 768 ? true : false
  const isCloseTabletScreen = windowW < 810 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false

  useEffect(() => {
    setIsSmallWidth(
      isSmallTabletScreen ? true 
      : (isTabletScreen || isCloseTabletScreen) ? true  
      : (isSamllComputerScreen && visible) ? true
      : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? false
      : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? false 
      : false
    )
    // eslint-disable-next-line
  }, [windowW, visible])

  useEffect(() => {
    let tempTotalRevenue = posTotalRevenue + onlineTotalRevenue
    setTotalRevenue(tempTotalRevenue)
    if (posTotalRevenue && tempTotalRevenue) {
      setTotalPOSRevenuePercentage(Number(((posTotalRevenue / tempTotalRevenue) * 100).toFixed(2)))
      setPOSCreditRevenuePercentage(Number(((posCreditRevenue / posTotalRevenue) * 100).toFixed(2)))
      setPOSInsuranceRevenuePercentage(Number(((posInsuranceRevenue / posTotalRevenue) * 100).toFixed(2)))
    } else {
      setTotalPOSRevenuePercentage(0)
      setPOSCreditRevenuePercentage(0)
      setPOSInsuranceRevenuePercentage(0)
    }

    if (onlineTotalRevenue && tempTotalRevenue) {
      setTotalOnlineRevenuePercentage(Number(((onlineTotalRevenue / tempTotalRevenue) * 100).toFixed(2)))
      setOnlineCashRevenuePercentage(Number(((onlineCashRevenue / onlineTotalRevenue) * 100).toFixed(2)))
      setOnlineInsuranceRevenuePercentage(Number(((onlineInsuranceRevenue / onlineTotalRevenue) * 100).toFixed(2)))
    } else {
      setOnlineCashRevenuePercentage(0)
      setTotalOnlineRevenuePercentage(0)
      setOnlineInsuranceRevenuePercentage(0)
    }
    // eslint-disable-next-line
  }, [posTotalRevenue, onlineTotalRevenue])

  useEffect(() => {
    if(Object.keys(storeSales).length && storeSales.storeId === storeId){
      setCurrentSales(filterSales())
      setIsAllTime(true)
    }
    // eslint-disable-next-line
  }, [storeSales])

  const assignData = (sale, obj) => {
    const now_timestamp = Math.round(new Date().getTime() / 1000)  
    const last_24_hours_timestamp = now_timestamp - (24 * 3600)
    const last_48_hours_timestamp = now_timestamp - (2 * 24 * 3600)
    const db_timestamp = Math.round(new Date(sale.createdAt).getTime() / 1000)
    
    if(sale.type === 'online'){
      obj.tempOnlineTotal += sale.orderTotal
      if (
        sale.paymentInfo.method.toLowerCase() === 'insurance' 
        // && storeData.approvedInsurance
      ) {
        obj.tempOnlineInsuranceRevenue += sale.orderTotal
      } else if (sale.paymentInfo.method.toLowerCase().includes('cash')) {
        obj.tempOnlineCashRevenue += sale.orderTotal
      }
      if (db_timestamp > last_24_hours_timestamp) {
        obj.tempTodayOnlineRevenue += sale.orderTotal
      } else if (db_timestamp > last_48_hours_timestamp) {
        obj.tempYesterdayOnlineRevenue += sale.orderTotal
      }
    } else if(sale.type === 'pos'){
      obj.tempPOSTotal += sale.orderTotal
      if (sale.paymentInfo.method.toLowerCase() === 'insurance') {
        obj.tempPOSInsuranceRevenue += sale.orderTotal
      } else if (sale.paymentInfo.method.toLowerCase() === 'credit') {
        obj.tempPOSCreditRevenue += sale.orderTotal
      }
      if (db_timestamp > last_24_hours_timestamp) {
        obj.tempTodayPOSRevenue += sale.orderTotal
      } else if (db_timestamp > last_48_hours_timestamp) {
        obj.tempYesterdayPOSRevenue += sale.orderTotal
      }
    }
    
    return obj
  }

  const filterSales = (start_timestamp, end_timestamp) => {
    const tempCurrentSales = []
    let obj = {
      tempPOSTotal: 0,
      tempOnlineTotal: 0,
      tempTodayPOSRevenue: 0,
      tempPOSCreditRevenue: 0,
      tempOnlineCashRevenue: 0,
      tempTodayOnlineRevenue: 0,
      tempPOSInsuranceRevenue: 0,
      tempYesterdayPOSRevenue: 0,
      tempOnlineInsuranceRevenue: 0,
      tempYesterdayOnlineRevenue: 0
    }

    storeSales.sales.forEach(sale => {
      const db_timestamp = Math.round(new Date(sale.createdAt).getTime() / 1000)
      if((db_timestamp >= start_timestamp) && (db_timestamp <= end_timestamp)){
        tempCurrentSales.push(sale) 
        obj = assignData(sale, obj)
      }else if(!start_timestamp && !end_timestamp) {
        tempCurrentSales.push(sale) 
        obj = assignData(sale, obj)

      }
    })

    setPOSTotalRevenue(obj.tempPOSTotal)
    setOnlineTotalRevenue(obj.tempOnlineTotal)
    setTodayPOSRevenue(obj.tempTodayPOSRevenue)
    setPOSCreditRevenue(obj.tempPOSCreditRevenue)
    setOnlineCashRevenue(obj.tempOnlineCashRevenue)
    setTodayOnlineRevenue(obj.tempTodayOnlineRevenue)
    setYesterdayPOSRevenue(obj.tempYesterdayPOSRevenue)
    setPOSInsuranceRevenue(obj.tempPOSInsuranceRevenue)
    setOnlineInsuranceRevenue(obj.tempOnlineInsuranceRevenue)
    setYesterdayOnlineRevenue(obj.tempYesterdayOnlineRevenue)

    return tempCurrentSales
  }

  const onAllTimeClick = () => {
    setIsFilter(false)
    setIsAllTime(true)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(false)
    setCurrentSales(filterSales())
  }
  const onThisWeekClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisWeek(true)
    setIsThisYear(false)
    setIsThisMonth(false)

    const end_timestamp = Math.round(new Date().getTime() / 1000)
    const start_of_week_timestamp = end_timestamp - ((new Date().getDay()) * 24 * 3600)
    setCurrentSales(filterSales(start_of_week_timestamp, end_timestamp))
    
  }
  const onThisMonthClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(true)
    
    const start_of_month_timestamp = Math.round(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000);
    const end_timestamp = Math.round(new Date().getTime() / 1000)
    setCurrentSales(filterSales(start_of_month_timestamp, end_timestamp))
    

  }
  const onThisYearClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisYear(true)
    setIsThisWeek(false)
    setIsThisMonth(false)
    
    const end_timestamp = Math.round(new Date().getTime() / 1000)
    const start_of_year_timestamp = Math.round(new Date(new Date().getFullYear(), 0, 1).getTime() / 1000);
    setCurrentSales(filterSales(start_of_year_timestamp, end_timestamp))
  }
  const onFilter = () => {
    setIsFilter(true)
    setIsAllTime(false)
    setOpenFilter(true)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(false)
  }

  const handleCloseFilter = () => {
    setOpenFilter(false)
  }
  
  const handleSartDateChange = (newValue) => {
    setStartDate(newValue)
  }
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
  }
  const handleCustomFilter = () => {
    const end_date_timestamp = Math.round(new Date(endDate.$d).getTime() / 1000)
    const start_date_timestamp = Math.round(new Date(startDate.$d).getTime() / 1000)
    setCurrentSales(filterSales(start_date_timestamp, end_date_timestamp))

    handleCloseFilter()
  }
  
  return (    
    <HMBox
      width='100%'
      padding='10px'
      display='flex'
      className='fed-in'
      bgColor={lightBlue}
      flexDirection='column'
      borderRadius='0 0 8px 8px'
    >
      <Grid 
        container 
        spacing={0}
        width='100%'
        sx={{ 
            padding: '10px',
            borderRadius: '8px',
            margin: '20px auto',
            backgroundColor: white 
        }}
      >
        <Grid 
          item xs={12} md={12} 
          sx={{ paddingBottom: '40px', display: 'flex', flexDirection: 'column' }} 
        >
          <HMDataRange 
            endDate={endDate}
            isFilter={isFilter}
            onFilter={onFilter}
            startDate={startDate}
            isAllTime={isAllTime}
            openFilter={openFilter}
            isThisWeek={isThisWeek}
            isThisYear={isThisYear}
            isThisMonth={isThisMonth}
            onAllTimeClick={onAllTimeClick}
            onThisYearClick={onThisYearClick}
            onThisWeekClick={onThisWeekClick}
            onThisMonthClick={onThisMonthClick}
            handleCloseFilter={handleCloseFilter}
            handleCustomFilter={handleCustomFilter}
            handleEndDateChange={handleEndDateChange}
            handleSartDateChange={handleSartDateChange}
          />
        </Grid> 
        <Grid 
          item xs={12} md={isSmallWidth ? 12 : 7}
          sx={{ display: 'flex', flexDirection: 'column' }} 
        >
          <Title 
            title='Revenue'
            color={darkBlue}
            fontWeight={500}
            size={fontSize + 4}
            margin={isTabletScreen ? '0 auto 20px 20px' : '-20px auto 20px 20px'}
          />
          <HMBox
            padding='0'
            width='100%'
            margin='auto'
            height='260px'
            display='flex' 
          >
            <HMDonutChart 
              name_show={true}
              total_show={true}
              colors={[ blue, green ]}
              labels={['Online Sales', 'POS Sales']}  
              data={[totalOnlineRevenuePercentage, totalPOSRevenuePercentage]} 
            />
            <HMBox 
              width='auto'
              display='flex'
              flexDirection='column'
            >
              <HMText 
                fontWeight={400} 
                color={lightBlack}
                text='Combined Total' 
                margin='auto auto -5px 0'
                fontSize={`${fontSize + 4}px`}
              />
              <Title 
                margin='0'
                textAlign='left'
                color={darkBlue}
                size={isSmallTabletScreen ? fontSize + 10 : fontSize + 26}
                title={`${currency} ${totalRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
              />
              <HMBox 
                padding='0'
                width='100%'
                display='flex'
                margin='-5px auto auto 0'
              >
                <DotAndLabel
                  dotColor={blue}
                  color={lightBlack}
                  title={'Online Sales'}
                  fontSize={fontSize - 2}
                  margin='auto 10px auto 0'
                />
                <DotAndLabel
                  dotColor={green}
                  color={lightBlack}
                  title='In-store Sales'
                  fontSize={fontSize - 2}
                />
              </HMBox>
            </HMBox>
          </HMBox>
        </Grid>
        <Grid 
          item xs={12} md={isSmallWidth ? 12 : 5} 
          sx={{display: 'flex', flexDirection: 'column' }} 
        >
          <Title 
            color={darkBlue}
            fontWeight={500}
            size={fontSize + 4}
            margin='auto auto auto 0'
            title='Wallet Distribution'
          />
          <ProgressBarWithData 
            title='Online Sales'
            amount={onlineTotalRevenue}
            value={totalOnlineRevenuePercentage}
          />        
          <ProgressBarWithData 
            strokeColor={green}
            title='In-store Sales'
            amount={posTotalRevenue}
            value={totalPOSRevenuePercentage}
          />    
        </Grid> 
      </Grid>

      <Grid 
        container 
        spacing={0}
        width='100%'
        sx={{ 
            padding: '10px',
            borderRadius: '8px',
            margin: '20px auto',
            backgroundColor: white 
        }}
      >
        <Grid 
          item xs={12} md={isSmallWidth || !currentSales.length ? 12 : 7} 
          sx={{ display: 'flex', flexDirection: 'column', padding: '0 10px 0 0' }} 
        >
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='20px auto 10px auto'
          >
            <Title 
              height='auto'
              color={darkBlue}
              fontWeight={500}
              size={fontSize + 2}
              margin='auto auto auto 0'
              title='Daily Sales with Insurances'
            />
          </HMBox>  
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
          >
            <SimpleCard 
              firstText='Today'
              margin='auto 5px auto 0'
              secondText={`${currency} ${(todayPOSRevenue + todayOnlineRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            />
            <SimpleCard 
              firstText='Yesterday'
              bgColor={middleOrange}
              margin='auto 0 auto 5px'
              secondText={`${currency} ${(yesterdayPOSRevenue + yesterdayOnlineRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            />
          </HMBox> 
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
            flexDirection={isSmallWidth ? 'column' : 'row'}
          >
            <HMPieChart 
              bgColor={superLightBlue}
              title='Online Sales vs Insurances'
              labels={[ 'Insurances', 'Others' ]}
              width={isSmallWidth ? '100%' : '50%'}
              margin={isSmallWidth ? '5px auto' : 'auto 0'}
              data={[ onlineInsuranceRevenuePercentage, Number((100 - onlineInsuranceRevenuePercentage).toFixed(2)) ]}
            />
            <HMPieChart 
              bgColor={superLightBlue}
              labels={[ 'Cash', 'Others']}
              title='Online Sales vs Cash Payment'
              width={isSmallWidth ? '100%' : '50%'}
              margin={isSmallWidth ? '5px auto' : 'auto 0 auto 10px'}
              data={[ onlineCashRevenuePercentage, Number((100 - onlineCashRevenuePercentage).toFixed(2)) ]}
            />
          </HMBox> 
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
            flexDirection={isSmallWidth ? 'column' : 'row'}
          >
            <HMPieChart 
              bgColor={superLightBlue}
              title='In-store Sales vs Insurances'
              width={isSmallWidth ? '100%' : '50%'}
              margin={isSmallWidth ? '5px auto' : 'auto 0'}
              labels={[ 'Insurances', 'Others']}
              data={[ posInsuranceRevenuePercentage, Number((100 - posInsuranceRevenuePercentage).toFixed(2)) ]}
            />
            <HMPieChart 
              bgColor={superLightBlue}
              title='In-store Sales vs Credit'
              width={isSmallWidth ? '100%' : '50%'}
              labels={[ 'Credit', 'Others']}
              margin={isSmallWidth ? '5px auto' : 'auto 0 auto 10px'}
              data={[ posCreditRevenuePercentage, Number((100 - posCreditRevenuePercentage).toFixed(2)) ]}
            />
          </HMBox> 
        </Grid> 
        {currentSales.length ? (
          <Grid 
            item xs={12} md={isSmallWidth ? 12 : 5} 
            sx={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 10px' }} 
            >  
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='20px auto 10px auto'
            >
              <Title 
                height='auto'
                color={darkBlue}
                fontWeight={500}
                size={fontSize + 4}
                margin='auto auto auto 0'
                title='Recent Activities'
              />
            </HMBox>  
            <RecentActivities 
              margin='0'
              limit={10}
              activities={currentSales}
              map={{
                type: 'type',
                date: 'createdAt',
                amount: 'orderTotal',
                paymentMethod: 'paymentInfo'
              }}
            />          
          </Grid> 
        ) : <></>}
      </Grid>
    </HMBox>
  )
}

export default Overview
import React from 'react'
import Link from '@mui/material/Link'
import HMBox from '../common/layout/HMBox'
import HMCheckbox from '../common/tools/HMCheckbox'
import HMText from '../common/tools/HMText'
import Title from '../common/tools/Title'
import {
    fontSize
} from '../common/tools/Styles'
import {
    red,
    blue,
    green,
    lightGray,
    lightBlack,
    superLightBlue
} from '../../hooks/useColors'

function CartListings(props) {
  return (
    <HMBox 
      width='100%'
      display='flex'
      margin='2.5px auto'
      className={props.className}
      bgColor={props.bgColor || superLightBlue}
      padding={props.checkbox ? '2.5px 0 0 0' : '2.5px 0 0 10px'}
    >
      {props.checkbox ? (
        <HMCheckbox 
          margin='auto 5px'
          handleChange={props.handleChange} 
          checked={props.item.addInfoSelect} 
        />
      ) : <></>}
      <Link
        onClick={props.handleProduct}
        sx={{margin: 'auto 0', cursor: 'pointer'}}
      >
        <img 
          className='mini-image'
          alt={props.item.productName}
          src={props.item.productImage}
        />
      </Link>
      <HMBox
        display='flex'
        flexDirection='column'
        margin='auto 0 auto 5px'
      >
        <Title
          margin='0'
          width='100%'
          textAlign='left'
          size={fontSize - 2}
          title={props.item.productName}
        />
        <HMBox
          padding='0'
          margin='2px 0'
          display='flex'
          color={lightGray}
          width='max-content'
        >
          <HMText 
            margin='0'
            fontWeight={500}
            fontSize={`${fontSize - 2}px`}
            color={props.item.stockCount - (props.item.transitStockCount || 0) > 0 ? green : red}
            text={props.item.stockCount - (props.item.transitStockCount || 0) > 0 ? 'in stock' : 'sold out'}
          />
          &nbsp; | &nbsp;
          <Link
            onClick={props.handleStore}
            sx={{margin: 'auto 0', cursor: 'pointer'}}
          >
            <HMText
              color={blue}
              margin='auto 0'
              text={props.cart.storeName}
              fontSize={`${fontSize - 1}px`}
            />
          </Link>
          &nbsp; | &nbsp;
          <HMText
            margin='auto 0'
            color={lightBlack}
            fontSize={`${fontSize - 1}px`}
            text={
              props.forInsurance 
                ? `${props.item.insurancePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Rwf`
                : `${props.item.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Rwf`
            }
          />
        </HMBox>
      </HMBox>
    </HMBox>
  )
}

export default CartListings
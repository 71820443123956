import React from 'react'
import ReactApexChart from 'react-apexcharts'
import HMBox from '../common/layout/HMBox'
import {
    fontSize
} from '../common/tools/Styles'
import {
    blue,
    green,
    darkBlue,
    lightBlack,
    middleOrange
} from '../../hooks/useColors'

function HMPieChart(props) {

    const series = props.data || [20, 30, 50]
    const options = {
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -20,
                    enabled: true,
                    dropShadow: {
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: .4,
                        color: '#000',
                        enabled: false,
                    }
                },
            },
            dataLabels: {
                enabled: true,
                dropShadow: {
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.15,
                    enabled: true,
                }
            }, 
        },
        title: {
            offsetX: 0,
            offsetY: 0,
            align: 'left',
            text: props.title || 'Pie Chart',
            style: {
                fontWeight:  props.fontWeight || 600,
                color:  props.titleColor || darkBlue,
                fontSize:  props.titlefontSize || fontSize + 2,
            },
        },
        chart: {
            type: 'pie',
            width: '100%',
        },
        stroke: {
            show: true
        },
        labels: props.labels || ['Label1', 'Label2', 'Lable3'],
        colors: props.colors || [middleOrange, blue, green, darkBlue],
        legend: {
            offsetX: 1,
            offsetY: 90,
            fontWeight: 500,
            position: 'right',
            verticalAlign: 'center',
            fontSize: `${fontSize + 2}`,
            fontColor: props.labelColor || lightBlack,
            width: '100px',
            markers: {
                width: 12,
                height: 12,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                customHTML: undefined,
                onClick: undefined,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                        background: 'green',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    }

    return (
        <HMBox
            display='flex'
            // maxWidth='500px'
            minWidth='350px'
            minHeight='200px'
            width={props.width}
            height={props.height}
            bgColor={props.bgColor}
            margin={props.margin || 'auto 0'}
            flexDirection={props.flexDirection || 'column'}
        >
            <ReactApexChart
                type='pie'
                width='100%'
                height='100%'
                series={series}
                options={options}
            />
        </HMBox>
    )
}

export default HMPieChart
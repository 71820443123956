import React from 'react'
import Link from '@mui/material/Link'
import HMBox from './HMBox'
import HMImage from '../tools/HMImage'
import grow_movement from '../../../assests/partners/grow_movement.png'
import ministryICT from '../../../assests/partners/ministry_ict.png'
import norrsken from '../../../assests/partners/norrsken.png'
import andela from '../../../assests/partners/andela.png'
import ihuzo from '../../../assests/partners/ihuzo.jpg'
import babyl from '../../../assests/partners/babyl.png'
import hanga from '../../../assests/partners/hanga.png'
import jica from '../../../assests/partners/jica.png'
import fda from '../../../assests/partners/fda.png'
import bid from '../../../assests/partners/bid.png'
import lse from '../../../assests/partners/lse.png'

function HMParntersLogo(props) {
  return (
    <HMBox
        width='50%'
        display='flex'
        className={props.className}
    >
        <Link href='https://www.rwandafda.gov.rw/home' target='_blank'>
            <HMImage 
                src={fda}
            />
        </Link>
        <Link href='https://www.minict.gov.rw/' target='_blank'>
            <HMImage 
                src={ministryICT}
            />
        </Link>
        <Link href='https://www.hangapitchfest.rw/' target='_blank'>
            <HMImage 
                src={hanga}
            />
        </Link>
        <Link href='http://www.ihuzo.rw/' target='_blank'>
            <HMImage 
                src={ihuzo}
            />
        </Link>
        <Link href='https://www.lse.ac.uk/' target='_blank'>
            <HMImage 
                src={lse}
            />
        </Link>
        <Link href='https://andela.com/' target='_blank'>
            <HMImage 
                src={andela}
            />
        </Link>
        <Link href='https://www.norrsken.org/eastafrica' target='_blank'>
            <HMImage 
                src={norrsken}
            />
        </Link>
        <Link href='https://www.jica.go.jp/english/index.html' target='_blank'>
            <HMImage 
                src={jica}
            />
        </Link>
        <Link href='https://www.babyl.rw/' target='_blank'>
            <HMImage 
                src={babyl}
            />
        </Link>
        {/* <Link href='https://www.bidcp.com/' target='_blank'>
            <HMImage 
                src={bid}
            />
        </Link> */}
        <Link href='https://www.growmovement.org/' target='_blank'>
            <HMImage 
                src={grow_movement}
            />
        </Link>
        <Link href='https://andela.com/' target='_blank'>
            <HMImage 
                src={andela}
            />
        </Link>
    </HMBox>
  )
}

export default HMParntersLogo
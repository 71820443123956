import React from 'react'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/ArrowBack'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import { 
  fontSize,
  textStyle, 
  iconStyle,
} from '../../components/common/tools/Styles'
import {
  blue,
  black,
  orange,
  lightBlack,
} from '../../hooks/useColors'

function NotFound() {
  const navigate = useNavigate()
 
  return (
    <Page
      width='100vw'
      height='100vh'
    >
      <HMBox
        margin='auto'
        display='flex'
        flexDirection='column'
      >
        <Typography 
          component='h1' 
          sx={{ 
            color: blue,
            fontWeight: 800,
            margin: 'auto auto 0 auto',
            fontSize: `${fontSize + 106}px`,
          }}
        >
          4
          <Typography 
            component='span'  
            sx={{ 
              color: orange,
              fontSize: 'inherit',
              fontWeight: 'inherit',
            }}
          >
          0
          </Typography>
          4
        </Typography>
        <HMText
          color={black}
          fontWeight={800}
          text='Page Not Found!'
          fontSize={fontSize * 2}
          margin='0 auto 20px auto'
        />
        <Typography
          sx={{
            color: lightBlack,
            textAlign: 'center',
            margin: 'auto auto 10px auto'
          }}
        >
          We searched high and low, but couldn’t find what you’re looking for. <br />
          Let’s find a better place for you to go.
        </Typography>
        <HMBox
          display='flex'
          margin='10px auto'
        >
          <HMButton
            width='130px'
            type='button'
            bgColor={orange}
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Go back</Typography>}
            icon={<BackIcon sx={{ iconStyle, color: 'inherit', fontSize: '16px' }} />}
          />
          <HMButton
            width='130px'
            type='button'
            bgColor={blue}
            handleClick={() => navigate('/')}
            text={<Typography sx={textStyle}>Take me home</Typography>}
          />
        </HMBox>
      </HMBox>
    </Page>
  )
}

export default NotFound
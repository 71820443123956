import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import cloudinaryService from './cloudinaryService'

const initialState = {
    file: '',
    message: '',
    isUploaded: false,
    isUploading: false,
    isUploadError: false,
}

// Upload a file to cloudinary
export const uploadFileToCloudinary = createAsyncThunk(
    'cloudinary/uploadToCloudinary',
    async (obj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await cloudinaryService.uploadFileToCloudinary(obj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const cloudinarySlice = createSlice({
    name: 'cloudinary',
    initialState,
    reducers: {
        resetCloudinary: (state) => {
            state.file = ''
            state.message = ''
            state.isUploaded = false
            state.isUploading = false
            state.isUploadError = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFileToCloudinary.pending, (state) => {
                state.isUploaded = false
                state.isUploading = true
                state.isUploadError = false
            })
            .addCase(uploadFileToCloudinary.fulfilled, (state, action) => {
                state.isUploaded = true
                state.isUploading = false
                state.isUploadError = false
                state.file = action.payload.file
            })
            .addCase(uploadFileToCloudinary.rejected, (state) => {
                state.isUploaded = false
                state.isUploading = false
                state.isUploadError = true
            })
    }
})

export const { resetCloudinary } = cloudinarySlice.actions
export default cloudinarySlice.reducer
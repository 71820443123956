import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows'
import CircleIcon from '@mui/icons-material/Circle'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMText from '../../components/common/tools/HMText'
import HMTextField from '../../components/common/tools/HMTextField'
import HMButton from '../../components/common/tools/HMButton'
import { textStyle } from '../../components/common/tools/Styles'
import { blue, lightBlack, white } from '../../hooks/useColors'

const LogedDeviceHistory = (props) => {
  return (
    <Box>
      <Box sx={{ paddingBottom: '10px' }}>
        <Divider width="100%" />
      </Box>

      <Grid item sm={12} sx={{ display: 'flex', marginTop: '20px' }}>
        <Box>
          <LaptopWindowsIcon />
        </Box>
        <Box>
          <HMText text={props.deviceName} padding="0 10px" />
        </Box>
      </Grid>

      <HMText
        text={`${props.address}, ${props.date}`}
        padding="0 10px"
        margin="0 28px"
      />
    </Box>
  )
}

function ChangePassword() {
  return (
    <Page>
      <FullWidthContainer>
        <Grid
          container
          spacing={4}
          sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
        >
          <Grid item sm={5} xs={12} sx={{ margin: '0 auto' }}>
            <HMText
              text="Change Password"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Please enter  your current password to change your password"
              padding="12px 12px"
            />

            <Box sx={{ padding: '12px 12px' }}>
              <HMTextField
                id="password"
                name="password"
                type="password"
                value="current password"
                label="Current password"
                placeholder="Enter your old password"
              />
              <HMTextField
                name="password"
                type="password"
                label="New password"
                placeholder="Enter new password"
              />
              <HMTextField
              type="password"
                name="password"
                label="Confirm new password"
                placeholder="Re-enter you new password"
              />
            </Box>

            <Box sx={{ display: 'flex', float: 'right', padding: '12px 12px' }}>
              <HMButton
                float="initial"
                type="button"
                bgColor={white}
                color={lightBlack}
                border={`1px solid${lightBlack}`}
                text={<Typography sx={textStyle}>No, Cansel</Typography>}
              />
              <HMButton
                float="initial"
                margin="6px auto"
                type="button"
                bgColor={blue}
                text={<Typography sx={textStyle}>Update password</Typography>}
              />
            </Box>
          </Grid>

          <Grid item sm={5}>
            <HMText
              text="Your login history"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="We’ll alert you via alert@harakameds.com if ther is any unusual activity on your account"
              padding="12px 12px"
            />
            <Box>
              <Divider width="100%" />
              <Grid item sm={12} sx={{ display: 'flex', marginTop: '20px' }}>
                <Box>
                  <LaptopWindowsIcon />
                </Box>
                <Box>
                  <HMText text="2021 Mackbook Pro 15-inch" padding="0 10px" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#D6FFF3',
                    borderRadius: '50px',
                    padding: '0 15px',
                  }}
                >
                  <CircleIcon
                    sx={{ color: '#1EBF19', width: '10px', marginRight: '8px' }}
                  />
                  <HMText text="Active now" />
                </Box>
              </Grid>

              <HMText
                text="Kigali, Rwanda .20 July 2022 at 2:30pm"
                padding="0 10px"
                margin="0 28px"
              />
            </Box>
            <LogedDeviceHistory
              deviceName="2021 Mackbook Pro 15-inch"
              address="Musanze, Rwanda "
              date="20 July 2022 at 2:30pm"
            />

            <LogedDeviceHistory
              deviceName="2021 Mackbook Pro 15-inch"
              address="Musanze, Rwanda "
              date="20 July 2022 at 2:30pm"
            />

            <LogedDeviceHistory
              deviceName="2021 Samusang 21"
              address="Musanze, Rwanda "
              date="20 July 2022 at 2:30pm"
            />
          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  )
}

export default ChangePassword

import React from 'react'
import IconButton from '@mui/material/IconButton'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
  fontSize
} from '../common/tools/Styles'
import {
  red,
  blue,
  white,
  orange,
  darkBlue,
  lightRed,
  lightBlack,
} from '../../hooks/useColors'
import {
  stringifyPhoneNumber
} from '../../hooks/helperFunctions'

function AddressDetails(props) {
  return (
    <HMBox
      padding='10px'
      display='flex'
      className='fade-in'
      bgColor={props.bgColor}
      hoverBgColor={props.hoverBgColor}
      margin={props.margin || '10px 5px'}
      handleClick={props.handleChooseClick}
      width={props.width || 'match-content'}
      cursor={props.activeCursor && 'pointer'}
    >
      {props.canDelete ? (
        <IconButton   
          onClick={props.handleDeleteClick} 
          sx={{
            right: '10px',
            bottom: '5px',
            padding: '5px',
            position: 'absolute',
            backgroundColor: lightRed,
            '&:hover': {
              backgroundColor: red,
            },
          }}
        >
          <DeleteForeverTwoToneIcon 
            sx={{
              color: red,
              margin: 'auto',
              borderRadius: '25px',
              fontSize: `${fontSize + 2}px`,
              '&:hover': {
                color: white,
              },
            }}
          />
        </IconButton>
      ) : <></>}
      <LocationOnOutlinedIcon 
        sx={{
          color: red, 
          margin: 'auto 0', 
          fontSize: `${fontSize + 20}px`
        }} 
      />
      <HMBox
        padding='0'
        width='100%'
        display='flex'
        flexDirection='column'
        margin='auto 0 auto 10px'
      >
        <HMBox
          padding='0'
          width='100%'
          display='flex'
          color={darkBlue}
          margin='auto auto auto 0'
        >
          <HMText
            margin='auto 0'
            fontWeight={500}
            fontSize={`${fontSize - 1}px`}
            text={props.addressInfo.recipientName}
          />
          <HMText
            text='|'
            margin='auto 0'
            padding='0 10px'
            fontSize={`${fontSize - 1}px`}
          />
          <HMText
            margin='auto 0'
            fontSize={`${fontSize - 1}px`}
            text={stringifyPhoneNumber(props.addressInfo.phone)}
          />
          {(!props.desableChnageAddress && !props.isTabletScreen) ? (
            <HMText
              color={blue}
              text='Change'
              margin='auto 0 auto auto'
              fontSize={`${fontSize - 1}px`}
            />
          ) : <></>}
          {!props.desableChnageAddress ? (
            <IconButton   
              onClick={props.handleChangeClick} 
              sx={{margin: props.isTabletScreen ? 'auto 0 auto auto' : 'auto 0 auto 2.5px'}}
            >
              <DriveFileRenameOutlineOutlinedIcon 
                sx={{
                  margin: 'auto',
                  fontSize: `${fontSize + 2}px`,
                }}
              />
            </IconButton>
          ) : <></>}
        </HMBox>
        <HMBox
          display='flex'
          margin='auto auto auto 0'
          padding={!props.desableChnageAddress ? '0' : '10px 0 0 0'}
          flexDirection={props.isTabletScreen || props.isColumn ? 'column' : 'row'}
        >
          <HMBox
            padding='0'
            display='flex'
            margin={props.isTabletScreen || props.isColumn ? 'auto 0' : 'auto 0'}
          >
            <HMText
              color={white}
              bgColor={blue}
              padding='0 5px'
              margin='auto 2.5px auto 0'
              fontSize={`${fontSize - 2}px`}
              text={props.addressInfo.category}
            />
            {props.isDefault ? (
              <HMText
                color={white}
                bgColor={orange}
                padding='0 7.5px'
                margin='auto 0 auto 2.5px'
                fontSize={`${fontSize - 2}px`}
                text='Default delivery address'
              />
            ) : <></>}
          </HMBox>
          <HMText
            color={lightBlack}
            fontSize={`${fontSize - 1}px`}
            text={props.addressInfo.mapAddress}
            margin={props.isTabletScreen || props.isColumn ? 'auto 0' : 'auto 0 auto 2.5px'}
          />
        </HMBox>
      </HMBox>
    </HMBox>
  )
}

export default AddressDetails
import React from 'react'
import HMBox from '../common/layout/HMBox'
import HMImage from '../common/tools/HMImage'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'
import {
    lightRed,
    bgGreen,
    darkBlue,
    bgOrange,
} from '../../hooks/useColors'
import analyticsOrangeIcon from '../../assests/analyticsOrangeIcon.png'
import analyticsGreenIcon from '../../assests/analyticsGreenIcon.png'
import analyticsRedIcon from '../../assests/analyticsRedIcon.png'

function SimpleCardWithIcon(props) {
  return (
    <HMBox
        display='flex'
        width={props.width || '100%'}
        color={props.color || darkBlue}
        margin={props.margin || 'auto'}
        height={props.height || '150px'}
        flexDirection={props.flexDirection || 'row'}
        bgColor={props.bgColor || props.isGreen ? bgGreen : props.isRed ? lightRed : bgOrange}
    >
        <HMImage 
            width='100px'
            height='100px'
            margin='auto 10px'
            src={
                props.src || (
                    props.isGreen ? analyticsGreenIcon 
                    : props.isRed ? analyticsRedIcon 
                    : analyticsOrangeIcon
                )
            }
        />
        <HMBox
            display='flex'
            margin='auto 10px'
            flexDirection='column'
        >
            <HMText 
                color='inherit'
                text={props.firstText}
                fontWeight={props.fontWeight || 500}
                fontSize={`${props.fontSize || fontSize + 4}px`}
                margin={props.firstMargin || 'auto auto 0 0'}
            />
            <HMText 
                color='inherit'
                text={props.secondText}
                fontWeight={props.fontWeight || 700}
                fontSize={`${props.fontSize || fontSize + 10}px`}
                margin={props.secondMargin || '0 auto auto 0'}
            />
        </HMBox>
    </HMBox>
  )
}

export default SimpleCardWithIcon
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import parse from 'html-react-parser'

import { getFaqs, resetFaq, deleteFaq } from '../../../features/faqs/faqsSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMModal from '../../../components/common/layout/HMModal'
import DataTable from '../../../components/common/layout/DataTable'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import Spinner from '../../../components/common/tools/Spinner'
import Title from '../../../components/common/tools/Title'
import {
  iconStyle,
  textStyle,
  deleteTextStyle,
  deleteItemTextStyle,
} from '../../../components/common/tools/Styles'
import useColors from '../../../hooks/useColors'

const { red, gray, blue, green, lightBlue, lightBlack } = useColors

const faqRole = (role) => {
  if (role.includes(50) || role.includes(51) || role.includes(55))
    return 'Store owner | Store manager | Store employee'
  else if (role.includes(40) || role.includes(41) || role.includes(45))
    return 'Supplier owner | supplier manager | supplier emmployee'
  else if (role.includes(0) || role.includes(1) || role.includes(5) || role.includes(20))
    return 'Admin | Owners | Operator'
  else return 'Customers'
}

function Faqs() {
  const { isError, message, faqs, isDeleted, isSuccess } = useSelector(
    (state) => state.faq,
  )

  const [newFaqs, setNewFaqs] = useState(faqs)

  const cleanFaqs = (faqs) => {
    let fullFaqs = []

    faqs.forEach((faq) => {
      const textRole = faqRole(faq.role)
      const textAnswer = parse(faq.answer)
      fullFaqs.push({ ...faq, role: textRole, answer: textAnswer })
    })

    setNewFaqs(fullFaqs)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError || isDeleted || isSuccess) {
      dispatch(resetFaq())
    }

    if (isError) {
      toast.error(message)
    } else if (isDeleted) {
      toast.success(message)
      dispatch(getFaqs())
    }
  }, [dispatch, isSuccess, isError, message, isDeleted])

  useEffect(() => {
    dispatch(getFaqs())
    cleanFaqs(faqs)
  }, [dispatch, faqs])

  const fields = []
  const columns = [
    { field: 'question', headerName: 'Question', sortable: true },
    { field: 'role', headerName: 'Role', sortable: true },
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [currentFaq, setCurrentFaq] = useState({})
  const [selectedFaqs, setSelectedFaqs] = useState([])

  const getCurentFaq = (id) => {
    return faqs.filter((faq) => faq._id === id)[0]
  }
  const handleCreate = () => {
    navigate('/admin/faqs/create')
  }
  const handleEdit = (id) => {
    navigate(`/admin/faqs/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
    setCurrentFaq(getCurentFaq(id))

    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Faq')
  }
  const handleDelete = () => {
    dispatch(deleteFaq(currentFaq._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    // TODO
    handleClose()
  }
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setCurrentFaq({})
  }

  return (
    <Page>
      <FullWidthContainer display="flex">
        <Title title="Faqs" margin="auto auto auto 0" />
        <HMBox float="right" display="flex">
          <HMButton
            type="button"
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreate}
            icon={<Typography sx={iconStyle}>+</Typography>}
            text={<Typography sx={textStyle}>Add Faq</Typography>}
          />
          <HMButton
            type="button"
            bgColor={blue}
            isResponsive={true}
            icon={<UploadFileIcon sx={iconStyle} />}
            text={<Typography sx={textStyle}>Import Faqs</Typography>}
          />
        </HMBox>
        <HMModal
          handleClose={handleClose}
          open={open}
          colors={{ lightBlack, lightBlue }}
          title={title}
        >
          <Typography sx={deleteTextStyle}>
            Are you sure your want to delete:
          </Typography>
          {isDelete ? (
            <Typography sx={deleteItemTextStyle}>
              {currentFaq.question} ?
            </Typography>
          ) : !selectedFaqs.length ? (
            <Typography sx={deleteItemTextStyle}>
              No insurer selected. Select insurers and try again!
            </Typography>
          ) : selectedFaqs.length > 10 ? (
            <Typography sx={deleteItemTextStyle}>
              {selectedFaqs.length} Faqs?
            </Typography>
          ) : (
            <Box sx={{ marginBottom: '20px' }}>
              {selectedFaqs.map((faq, index) => (
                <Typography
                  sx={{ ...deleteItemTextStyle, marginBottom: '0px' }}
                  key={index}
                >
                  {faq.name}
                </Typography>
              ))}
            </Box>
          )}
          {(selectedFaqs.length || isDelete) && (
            <HMButton
              type="button"
              text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
              bgColor={red}
              handleClick={isDelete ? handleDelete : handleBulkDelete}
              float="right"
              margin="auto"
            />
          )}
          <HMButton
            type="button"
            text={<Typography sx={textStyle}>No, Cancel!</Typography>}
            bgColor={gray}
            handleClick={handleClose}
            float={(selectedFaqs.length || isDelete) && 'left'}
            margin="auto"
          />
        </HMModal>
      </FullWidthContainer>
      {!faqs.length ? (
        <Spinner marginTop={25} />
      ) : (
        <DataTable
          data={newFaqs}
          columns={columns}
          fields={fields}
          handleEdit={handleEdit}
          handleDelete={handleDeleteModal}
          title="Faqs"
          defaultRowsNum={20}
        />
      )}
    </Page>
  )
}

export default Faqs

import React from 'react'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'
import {
    blue,
    white,
} from '../../hooks/useColors'

function SimpleCard(props) {
  return (
    <HMBox
        display='flex'
        color={props.color || white}
        width={props.width || '100%'}
        margin={props.margin || 'auto'}
        bgColor={props.bgColor || blue}
        height={props.height || '120px'}
        flexDirection={props.flexDirection || 'column'}
    >
        <HMText 
            color='inherit'
            text={props.firstText}
            fontWeight={props.fontWeight || 500}
            fontSize={`${props.fontSize || fontSize + 4}px`}
            margin={props.firstMargin || 'auto auto 0 auto'}
        />
        <HMText 
            color='inherit'
            text={props.secondText}
            fontWeight={props.fontWeight || 700}
            fontSize={`${props.fontSize || fontSize + 10}px`}
            margin={props.secondMargin || '0 auto auto auto'}
        />
    </HMBox>
  )
}

export default SimpleCard
import React, { useState } from 'react'
import {
  Box,
  Grid,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import TextField from '@mui/material/TextField'
import MailIcon from '@mui/icons-material/Mail'
import AddIcon from '@mui/icons-material/Add'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMText from '../../components/common/tools/HMText'
import {
  lightBlack,
  white,
  blue,
} from '../../hooks/useColors'
import HMButton from '../../components/common/tools/HMButton'
import { textStyle } from '../../components/common/tools/Styles'
import DataTable from '../../components/common/layout/DataTable'

const MemberTextField = (props) => {
  return (
    <Grid item xs={8}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: `1px solid ${lightBlack}`,
          borderRadius: '10px',
          marginTop: '12px',
        }}
      >
        <TextField
          name="email"
          className="HM-text-field"
          id="input-with-sx"
          variant="standard"
          onChange={props.onChange}
          fullWidth
          placeholder="Enter a member email"
          InputProps={{
            startAdornment: (
              <MailIcon sx={{ color: lightBlack }} fontSize="medium" />
            ),
            disableUnderline: true,
          }}
          sx={{
            border: 'none',
            padding: '10px',
            fontSize: '12px',
            fontWeight: '900',
          }}
        />
      </Box>
    </Grid>
  )
}

const MemberRoleSelector = (props) => {
  return (
    <Grid item xs={4}>
      <FormControl fullWidth sx={{ marginTop: '10px' }}>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          onChange={props.onChange}
        >
          {props.options}
        </Select>
      </FormControl>
    </Grid>
  )
}
const SuccesStatus = () => {
  return (
    <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
      <HMText text='Success' float="initial" color={lightBlack} />
      <CircleIcon
        sx={{ color: '#1EBF19', width: '10px', marginLeft: '-0.1px' }}
      />
    </Box>
  )
}

function Team() {
  const [role, setRole] = useState('')
  const roles = ['Admin', 'Operator', 'Manager', 'Employee', 'Consumer']

  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const fields = []
  const columns = [
    { field: 'image', headerName: 'Image', sortable: true },
    { field: 'name', headerName: 'Name', sortable: true },
    { field: 'email', headerName: 'Email', sortable: true },
    { field: 'role', headerName: 'Role', sortable: true },
    { field: 'status', headerName: 'Invitation Status', sortable: false },

  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const members = [
    {
      image: 'https://res.cloudinary.com/harakameds/image/upload/v1658473201/samples/people/smiling-man.jpg',
      name: 'Kagabo MURAGIJIMANA',
      email: 'nirifabien@gmail.com',
      role: 'Admin',
      status: 'Pending',
    },
    {
      image: 'https://res.cloudinary.com/harakameds/image/upload/v1659245557/Users/s9a3ej7b7pqi473hugxw.png',
      name: 'Kagabo MURAGIJIMANA',
      email: 'nirifabien@gmail.com',
      role: 'Admin',
      status: <SuccesStatus/>,
    },
    {
      image: 'https://res.cloudinary.com/harakameds/image/upload/v1658473201/samples/people/smiling-man.jpg',
      name: 'Kagabo MURAGIJIMANA',
      email: 'nirifabien@gmail.com',
      role: 'Admin',
      status: 'Pending',
    },
    {
      image: 'https://res.cloudinary.com/harakameds/image/upload/v1658473201/samples/people/smiling-man.jpg',
      name: 'Kagabo MURAGIJIMANA',
      email: 'nirifabien@gmail.com',
      role: 'Admin',
      status: 'Pending',
    },
  ]
  return (
    <Page>
      <FullWidthContainer>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
        >
          <Grid item sm={10} sx={{ marginBottom: '50px' }}>
            <HMText
              text="Team management"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Manage your team members and their account permissions. "
              padding="6px 12px"
              size="16px"
            />
            <Divider width="100%" />
          </Grid>

          <Grid item sm={9}>
            <HMText
              text="Team management"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="6px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Manage your team members and their account permissions. "
              padding="2px 12px"
              size="16px"
            />
          </Grid>

          <Grid
            item
            container
            spacing={2}
            sm={9}
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MemberTextField />
            <MemberRoleSelector
              label="Role"
              value={role}
              onChange={handleRoleChange}
              options={roles.map((role) => (
                <MenuItem value={role}>{role}</MenuItem>
              ))}
            />
         
            <MemberTextField />
            <MemberRoleSelector
              label="Role"
              value={role}
              onChange={handleRoleChange}
              options={roles.map((role) => (
                <MenuItem value={role}>{role}</MenuItem>
              ))}
            />
            <Grid item xs={7}>
              <HMButton
                float="initial"
                color={lightBlack}
                fontSize="16px"
                margin="0"
                type="button"
                icon={<AddIcon sx={{ color: lightBlack }} />}
                text={<Typography sx={textStyle}>Add another </Typography>}
              />
            </Grid>
            <Grid item xs={4}>
              <HMButton
                color={lightBlack}
                float="right"
                bgColor={blue}
                fontSize="16px"
                margin="0"
                type="button"
                icon={<MailIcon sx={{ color: white }} />}
                text={
                  <Typography sx={{ ...textStyle, color: white }}>
                    Send invites{' '}
                  </Typography>
                }
              />
            </Grid>
          </Grid>

        </Grid>
        <Grid container sx={{ marginTop: '40px' }}>
          <Grid item container>
            <Grid item sm={9}>
              <HMText
                float="initial"
                size="20px"
                fontWeight="700"
                text="Team members"
              />
              <HMText
                float="initial"
                text="Manage your existing team members and change roles/permissions"
              />
            </Grid>
            
          </Grid>
        </Grid>
      </FullWidthContainer>


      <DataTable
        data={members}
        columns={columns}
        fields={fields}
        title="Invited members"
        defaultRowsNum={3}
      />
    </Page>
  )
}

export default Team

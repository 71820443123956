import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import socketIO from 'socket.io-client'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import CreateStoreListing from './pages/store/listings/CreateListing'
import EditStoreListing from './pages/store/listings/EditListing'
import StoreListings from './pages/store/listings/Listings'
import StoreListing from './pages/store/listings/Listing'
import StorePOSSettings from './pages/store/pos/Settings'
import StorePOSLanding from './pages/store/pos/Landing'
import StorePOSHistory from './pages/store/pos/History'
import StorePOSWallet from './pages/store/pos/Wallet'
import StorePOSHome from './pages/store/pos/Home'
import StoreApplication from './pages/store/Application'
import StoreDashboard from './pages/store/Dashboard'
import StoreAnalytics from './pages/store/Analytics'
import StoreSales from './pages/store/Sales'
import StoreSale from './pages/store/Sale'
import StoreHome from './pages/store/Home'
import SupplierDashboard from './pages/supplier/Dashboard'
import SupplierApplication from './pages/supplier/Application'
import PublicStoreProduct from './pages/customer/PublicStoreProduct'
import PublicCategories from './pages/customer/PublicCategories'
import PublicCategory from './pages/customer/PublicCategory'
import PublicProduct from './pages/customer/PublicProduct'
import PublicStores from './pages/customer/PublicStores'
import PublicStore from './pages/customer/PublicStore'
import SupplierHome from './pages/supplier/Home'
import SupplierAnalytics from './pages/supplier/Analytics'
import CreateSupplierListing from './pages/supplier/listings/CreateListing'
import ClientSupplierListings from './pages/supplier/listings/Listings'
import SupplierListing from './pages/supplier/listings/Listing'
import EditSupplierListings from './pages/supplier/listings/EditingListing'
import ByWithPrescription from './pages/customer/ByWithPrescription'
import Checkout from './pages/customer/Checkout'
import MyOrders from './pages/customer/MyOrders'
import MyOrder from './pages/customer/MyOrder'
import Landing from './pages/customer/Landing'
import Search from './pages/customer/Search'
import Cart from './pages/customer/Cart'
import Home from './pages/customer/Home'
import AdminDashboard from './pages/admin/AdminDashboard'
import AdminHome from './pages/admin/AdminHome'
import AdminCreateProduct from './pages/admin/product/CreateProduct'
import AdminEditProduct from './pages/admin/product/EditProduct'
import AdminProducts from './pages/admin/product/Products'
import AdminCategory from './pages/admin/product/Category'
import AdminProduct from './pages/admin/product/Product'
import AdminEditOrder from './pages/admin/order/EditOrder'
import AdminOrders from './pages/admin/order/Orders'
import AdminOrder from './pages/admin/order/Order'
import AdminCreateInsurer from './pages/admin/insurer/CreateInsurer'
import AdminEditInsurer from './pages/admin/insurer/EditInsurer'
import AdminInsurers from './pages/admin/insurer/Insurers'
import AdminInsurer from './pages/admin/insurer/Insurer'
import AdminCreateStoreListing from './pages/admin/storeListing/CreateStoreListing'
import AdminEditStoreListing from './pages/admin/storeListing/EditStoreListing'
import AdminStoreListings from './pages/admin/storeListing/StoreListings'
import AdminStoreListing from './pages/admin/storeListing/StoreListing'
import AdminCreateStore from './pages/admin/store/CreateStore'
import AdminEditStore from './pages/admin/store/EditStore'
import AdminStores from './pages/admin/store/Stores'
import AdminStore from './pages/admin/store/Store'
import AdminCreateSupplierListing from './pages/admin/supplierListing/CreateSupplierListing'
import AdminEditSupplierListing from './pages/admin/supplierListing/EditSupplierListing'
import AdminSupplierListings from './pages/admin/supplierListing/SupplierListings'
import AdminSupplierListing from './pages/admin/supplierListing/SupplierListing'
import AdminCreateSupplier from './pages/admin/supplier/CreateSupplier'
import AdminEditSupplier from './pages/admin/supplier/EditSupplier'
import AdminSuppliers from './pages/admin/supplier/Suppliers'
import AdminSupplier from './pages/admin/supplier/Supplier'
import AdminCreateUser from './pages/admin/users/CreateUser'
import AdminEditUser from './pages/admin/users/EditUser'
import AdminUsers from './pages/admin/users/Users'
import AdminUser from './pages/admin/users/User'
import AdminFaqCategory from './pages/admin/faqs/FaqCategory'
import AdminCreateFaq from './pages/admin/faqs/CreateFaqs'
import AdminEditFaq from './pages/admin/faqs/EditFaq'
import AdminFaqs from './pages/admin/faqs/Faqs'
import TermsAndConditions from './pages/common/TermsAndConditions'
import ForgotPassword from './pages/common/ForgotPassword'
import ResetPassword from './pages/common/ResetPassword'
import EditProfile from './pages/common/EditProfile'
import Privacy from './pages/common/PrivacyPolices'
import VerifyOtp from './pages/common/VerifyOtp'
import Forbidden from './pages/common/Forbidden'
import Analytics from './pages/admin/Analytics'
import Settings from './pages/common/Settings'
import Register from './pages/common/Register'
import Profile from './pages/common/Profile'
import NotFound from './pages/common/404'
import Login from './pages/common/Login'
import About from './pages/common/About'
import FAQS from './pages/common/FAQs'

const socket = socketIO.connect('http://localhost:8000', {
  transports: ['websocket'],
})

function App() {
  return (
    <SkeletonTheme baseColor='#effbff' highlightColor='#d6fff3'>
      <Router>
        <Routes>
          <Route path='/' element={<Home socket={socket} />}>
            <Route path='cart' element={<Cart />} />
            <Route path='faqs' element={<FAQS />} />
            <Route path='aboutus' element={<About />} />
            <Route path='landing' element={<Landing />} />
            <Route path='checkout' element={<Checkout />} />
            <Route path='myorders' element={<MyOrders />} />
            <Route path='settings' element={<Settings />} />
            <Route path='stores' element={<PublicStores />} />
            <Route path='categories' element={<PublicCategories />} />
            <Route path='store-application' element={<StoreApplication />} />
            <Route path='buy-with-prescription' element={<ByWithPrescription />} />
            <Route path='supplier-application' element={<SupplierApplication />} />
            <Route path='terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='myorders/:myOrderId' element={<MyOrder socket={socket} />} />
            <Route path='stores/:storeId' element={<PublicStore />} />
            <Route path='stores/:productId/:listingId' element={<PublicStoreProduct />} />
            <Route path='privacy-policies' element={<Privacy />} />
            <Route path='search/:searchText' element={<Search />} />
            <Route path='categories/:parent' element={<PublicCategories />} />
            <Route path='categories/:parent/:categoryId' element={<PublicCategory />} />
            <Route path='categories/:parent/:categoryId/:productId' element={<PublicProduct />} />
            <Route path='profile' element={<Profile />} />
            <Route path='profile/edit' element={<EditProfile />} />
          </Route>

          <Route path='store' element={<StoreHome />}>
            <Route path=':storeId/sales' element={<StoreSales />} />
            <Route path=':storeId/sales/:orderId' element={<StoreSale socket={socket} />} />
            <Route path=':storeId/analytics' element={<StoreAnalytics />} />
            <Route path=':storeId/dashboard' element={<StoreDashboard />} />
            <Route path=':storeId/listings' element={<StoreListings />} />
            <Route path=':storeId/listings/:listingId' element={<StoreListing />} />
            <Route path=':storeId/listings/create' element={<CreateStoreListing />} />
            <Route path=':storeId/listings/edit/:listingId' element={<EditStoreListing />} />
            <Route path='application' element={<StoreApplication />} />
            <Route path='application/:storeId' element={<StoreApplication socket={socket} />} />
            <Route path='my-profile' element={<Profile />} />
            <Route path='my-profile/edit' element={<EditProfile />} />
            <Route path='my-settings' element={<Settings />} />
          </Route>

          <Route path='store-pos' element={<StorePOSHome socket={socket} />}>
            <Route path=':storeId/settings' element={<StorePOSSettings />} />
            <Route path=':storeId/landing' element={<StorePOSLanding />} />
            <Route path=':storeId/history' element={<StorePOSHistory />} />
            <Route path=':storeId/online' element={<StoreSales />} />
            <Route path=':storeId/online/:orderId' element={<StoreSale socket={socket} />} />
            <Route path=':storeId/wallet' element={<StorePOSWallet />} />
          </Route>

          <Route path='supplier' element={<SupplierHome />}>
            <Route path=':supplierId/analytics' element={<SupplierAnalytics />} />
            <Route path=':supplierId/dashboard' element={<SupplierDashboard />} />
            <Route path=':supplierId/listings' element={<ClientSupplierListings />} />
            <Route path=':supplierId/listings/:listingId' element={<SupplierListing />} />
            <Route path=':supplierId/listings/create' element={<CreateSupplierListing />} />
            <Route path=':supplierId/listings/edit/:listingId' element={<EditSupplierListings />} />
            <Route path='application' element={<SupplierApplication />} />
            <Route path='application/:supplierId' element={<SupplierApplication />} />
            <Route path='my-profile' element={<Profile />} />
            <Route path='my-profile/edit' element={<EditProfile />} />
            <Route path='my-settings' element={<Settings />} />
          </Route>

          <Route path='admin' element={<AdminHome />}>
            <Route path='my-profile' element={<Profile />} />
            <Route path='my-profile/edit' element={<EditProfile />} />
            <Route path='my-settings' element={<Settings />} />

            <Route path='dashboard' element={<AdminDashboard />} />
            <Route path='categories' element={<AdminCategory />} />

            <Route path='products' element={<AdminProducts />} />
            <Route path='products/:id' element={<AdminProduct />} />
            <Route path='products/create' element={<AdminCreateProduct />} />
            <Route path='products/edit/:id' element={<AdminEditProduct />} />

            <Route path='orders' element={<AdminOrders socket={socket} />} />
            <Route path='orders/:orderId' element={<AdminOrder socket={socket} />} />
            <Route path='orders/edit/:orderId' element={<AdminEditOrder />} />

            <Route path='insurers' element={<AdminInsurers />} />
            <Route path='insurers/:id' element={<AdminInsurer />} />
            <Route path='insurers/create' element={<AdminCreateInsurer />} />
            <Route path='insurers/edit/:id' element={<AdminEditInsurer />} />

            <Route path='stores' element={<AdminStores />} />
            <Route path='stores/:id' element={<AdminStore socket={socket} />} />
            <Route path='stores/create' element={<AdminCreateStore />} />
            <Route path='stores/edit/:id' element={<AdminEditStore />} />

            <Route path='stores/:id/listings' element={<AdminStoreListings />} />
            <Route path='stores/:id/listings/:listingId' element={<AdminStoreListing />} />
            <Route path='stores/:id/listings/create' element={<AdminCreateStoreListing />} />
            <Route path='stores/:id/listings/edit/:listingId' element={<AdminEditStoreListing />} />

            <Route path='suppliers' element={<AdminSuppliers />} />
            <Route path='suppliers/:id' element={<AdminSupplier />} />
            <Route path='suppliers/create' element={<AdminCreateSupplier />} />
            <Route path='suppliers/edit/:id' element={<AdminEditSupplier />} />

            <Route path='suppliers/:id/listings' element={<AdminSupplierListings />} />
            <Route path='suppliers/:id/listings/:listingId' element={<AdminSupplierListing />} />
            <Route path='suppliers/:id/listings/create' element={<AdminCreateSupplierListing />} />
            <Route path='suppliers/:id/listings/edit/:listingId' element={<AdminEditSupplierListing />} />

            <Route path='users' element={<AdminUsers />} />
            <Route path='users/:id' element={<AdminUser />} />
            <Route path='users/create' element={<AdminCreateUser />} />
            <Route path='users/edit/:id' element={<AdminEditUser />} />

            <Route path='faqs' element={<AdminFaqs />} />
            <Route path='faqs/create' element={<AdminCreateFaq />} />
            <Route path='faqs/edit/:id' element={<AdminEditFaq />} />
            <Route path='faqsCategories' element={<AdminFaqCategory />} />

            <Route path='analytics' element={<Analytics />} />
          </Route>

          <Route path='login' element={<Login />} />
          <Route path='admin/login' element={<Login />} />
          <Route path='store/login' element={<Login />} />
          <Route path='supplier/login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='forbidden' element={<Forbidden />} />
          <Route path='verify-otp' element={<VerifyOtp />} />
          <Route path='admin/verify-otp' element={<VerifyOtp />} />
          <Route path='store/verify-otp' element={<VerifyOtp />} />
          <Route path='supplier/verify-otp' element={<VerifyOtp />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='admin/forgot-password' element={<ForgotPassword />} />
          <Route path='store/forgot-password' element={<ForgotPassword />} />
          <Route path='supplier/forgot-password' element={<ForgotPassword />} />
          <Route path='reset-password/:id/:token' element={<ResetPassword />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </SkeletonTheme>
  )
}
export default App

import React from 'react'
import Box from '@mui/material/Box'

function HMBox(props) {
    
    const boxStyle = {
        left: props.left,
        right: props.right,
        width: props.width,
        color: props.color,
        margin: props.margin,
        zIndex: props.zIndex,
        cursor: props.cursor,
        border: props.border,
        bottom: props.bottom,
        height: props.height,
        boxRef: props.boxRef,
        opacity: props.opacity,
        display: props.display,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        overflow: props.overflow,
        position: props.position,
        overflowX: props.overflowX, 
        overflowY: props.overflowY,
        minHeight: props.minHeight,
        marginTop: props.marginTop,
        alignSelf: props.alignSelf,
        maxHeight: props.maxHeight,
        borderTop: props.borderTop,
        whiteSpace: props.whiteSpace,
        backgroundColor: props.bgColor,
        padding: props.padding || '5px',
        borderBottom: props.borderBottom,
        float: `${props.float || 'left'}`,
        flexDirection: props.flexDirection,
        borderRadius: props.borderRadius || '8px',
        '&:hover' : {
            color: props.hoverColor,
            border: props.hoverBorder,
            backgroundColor: props.hoverBgColor
        }
    }

    return (
        <Box id={props.id} sx={boxStyle} className={props.className} onClick={props.handleClick}>
            {props.children}
        </Box>
    )
}

export default HMBox
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import {
    Box,
    Grid,
    Link,
    Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { toast } from 'react-toastify'
import { useReactToPrint } from 'react-to-print'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import CloseIcon from '@mui/icons-material/Close'
import ArrowUpDown from '../tools/ArrowUpDown'
import HMSearchField from '../tools/HMSearchField'
import DeleteIcon from '../tools/DeleteIcon'
import ButtonIcon from '../tools/ButtonIcon'
import HMCheckbox from '../tools/HMCheckbox'
import EditIcon from '../tools/EditIcon'
import ReactCSV from '../tools/ReactCSV'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    trStyle,
    fontSize,
    textStyle,
    linkStyle,
    iconStyle,  
    tbodyStyle,
    tableStyle,
    headerStyle,
    loadMoreStyle,
    iconsContainerStyle,
} from '../tools/Styles'
import FullWidthContainer from './FullWidthContainer'
import Section from './Section'
import HMBox from './HMBox'
import {
    red,
    blue,
    white,
    green,
    orange,
    warning,
    lightBlue,
    lightBlack,
    lightOrange,
    lightWarning,
} from '../../../hooks/useColors'
import {
    currencies
} from '../../../hooks/helperFunctions'

function DataTable({
    data, 
    title, 
    isPOS,
    fields, 
    columns, 
    addStyle, 
    viewOnly,
    viewLink,
    handleEdit,
    handleDelete, 
    disableSelect,
    searchBgColor,
    disableDelete,
    defaultRowsNum, 
    emptyDataMessage,
    handleBulkDelete,
}) {
    const thW = Math.floor(90 / columns.length) 
    const isViewOnly = viewOnly ? viewOnly : false
    const isSelectDisabled = disableSelect ? disableSelect : false
    const isDeleteDisabled = disableDelete ? disableDelete : false
    const currency = currencies.find(curr => curr.country === 'Rwanda').currency
    const stylingText = {
        ...textStyle,
        color: lightBlack,
        margin: 'auto auto auto 0',
    }

    const toPrint = useRef()
    const { id, storeId } = useParams()
    const navigate = useNavigate()

    const [noMatch, setNoMatch] = useState(true)
    const [isUpward, setIsUpward] = useState(true)
    const [searchText, setSearchText] = useState('')
    
    const initialData = data.map(item => {
        return {
            select: false,
            ...item
        }
    })

    const [rows, setRows] = useState(initialData)
    const [showingRows, setShowingRows] = useState(0)

    useEffect(() => {
        setRows(data.map(item => {
            return {
                select: false,
                ...item
            }
        }))
        const tempRowsNum = defaultRowsNum > data.length ? data.length : defaultRowsNum
        setShowingRows(tempRowsNum)
    }, [data])

    useEffect(() => {
        !noMatch && toast.info('No match found!')
    }, [noMatch])
    

    const loadMore = () => {
        const diff = rows.length - showingRows
        if (diff < defaultRowsNum) {
            setShowingRows(showingRows + diff)
        } else {
            setShowingRows(showingRows + defaultRowsNum)
        }
    }
    
    const sortData = (sortingTools, e) => {
        e.stopPropagation()

        if (e.target.style.transform === 'rotate(180deg)') {
            e.target.style.transform = 'rotate(0deg)'
        } else {
            e.target.style.transform = 'rotate(180deg)'
        }

        setIsUpward(!isUpward)

        const {
            field,
            direction
        } = sortingTools

        if (direction === 'asc') {
            rows.sort((a,b) => {
                if (typeof a[field] === 'string') {
                    return (a[field].toLowerCase() > b[field].toLowerCase()) ? 1 
                        : ((b[field].toLowerCase() > a[field].toLowerCase()) ? -1 : 0)
                } else {
                    return (a[field] > b[field]) ? 1 
                        : ((b[field] > a[field]) ? -1 : 0)
                }
            })
        } else if (direction === 'desc') {
            rows.sort((a,b) => {
                if (typeof a[field] === 'string') {
                    return (b[field].toLowerCase() > a[field].toLowerCase()) ? 1 
                        : ((a[field].toLowerCase() > b[field].toLowerCase()) ? -1 : 0)
                } else {
                    return (b[field] > a[field]) ? 1 
                    : ((a[field] > b[field]) ? -1 : 0)
                }
                
            })
        }
        setRows(rows)
    }

    const handleCheckboxChange = (e, id) => {
        const checked = e.target.checked
        setRows(
            rows.map((row) => {
                if (id === row._id) {
                    row.select = checked
                }
                return row
            })
        )
    }
    const handleCheckAll = (e) => {
        const checked = e.target.checked
        let count = showingRows
        setRows(
            rows.map((row) => {
                if (count > 0) {
                    row.select = checked
                }
                count--
                return row
            })
        )
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
        dynamicSearch()
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault()
        dynamicSearch()
    }
    const handleSearchClear = () => {
        setSearchText('')
        setRows(initialData)
    }
    
    const dynamicSearch = () => {
        if (searchText === '') {
            setRows(initialData)
        }
        if (searchText !== '') {
            const searchFields = columns.filter(item => item.sortable === true).map(item => item.field)
            const searchData = searchText.toLowerCase().split(' ').filter(item => item !== '')
            
            const result = initialData.filter((row) => {
                let check = false
                searchData.forEach((item) => {
                    for (let i = 0; i < searchFields.length; i++) {
                        const field = searchFields[i]
                        if (row[field].toLowerCase().includes(item)) {
                            check = true
                        } 
                    }
                })
                if (check) {
                    return row
                } else {
                    return false
                }
            })
            
            if (result.length) {
                setRows(result)
                setNoMatch(true)
            } else {
                setNoMatch(false)
            }
        }
    }

    const findSelectedData = () => {
        return rows.filter((row) => row.select === true)
    }
    const pageStyle = `{ 
        size: 2.5in 4in,
        padding: 20px
    }`
    const handlePrint = useReactToPrint({
        documentTitle: title,
        pageStyle: pageStyle,
        bodyClass: 'print-data-table',
        content: () => toPrint.current
    })

    const viewItem = (e, itemId) => {
        e.stopPropagation()
        if (id && !viewLink) {
            const getStoresOrSuppliers = title.toLowerCase().split(' ')[0].concat('s')
            navigate(`/admin/${getStoresOrSuppliers}/${id}/listings/${itemId}`)
        } else if (storeId) {
            if (isPOS) {
                navigate(`/store-pos/${storeId}/${viewLink}/${itemId}`)
            } else {
                navigate(
                    viewLink
                        ? `/store/${storeId}/${viewLink}/${itemId}`
                        : `/store/${storeId}/listings/${itemId}`
                )
            }
        } else {
            navigate(
                viewLink 
                    ? `${viewLink}/${itemId}`
                    : `/admin/${title.toLowerCase()}/${itemId}`
            )
        }
    }

    
    return (
        <>
            <FullWidthContainer
                display='flex'
                marginTop={20}
                padding='0 5px'
                marginBottom={5}
            >
                <Typography sx={stylingText}>
                    showing {showingRows} out of {rows.length} {title.toLowerCase()}
                </Typography>
                <HMSearchField 
                    title={title}
                    className='data-table'
                    searchText={searchText}
                    bgColor={searchBgColor}
                    handleClick={handleSearchClear}
                    closeMargin='auto auto auto 5px'
                    handleChange={handleSearchChange}
                    handleSubmit={handleSearchSubmit}
                />
                <Grid sx={iconsContainerStyle}>
                    <ButtonIcon 
                        bgColor={blue}
                        handleClick={handlePrint}
                        icon={<LocalPrintshopIcon sx={iconStyle} />}
                    />
                    <ReactCSV
                        data={data}
                        filename={title}
                    >
                        <ButtonIcon 
                            bgColor={green}
                            icon={<InsertDriveFileIcon sx={iconStyle} />}
                        />
                    </ReactCSV>
                    {!(isViewOnly || isDeleteDisabled) && (
                        <ButtonIcon 
                            bgColor={red}
                            icon={<CloseIcon sx={iconStyle} />}
                            handleClick={() => handleBulkDelete(findSelectedData())}
                        />
                    )}
                    {!(isViewOnly || isDeleteDisabled || isSelectDisabled) && (
                        <ButtonIcon 
                            bgColor={orange}
                            icon={<RemoveRedEyeIcon sx={iconStyle} />}
                            secondIcon={<ArrowDropDownIcon sx={iconStyle} />}
                        />  
                    )}
                </Grid>
            </FullWidthContainer>
            
            {rows.length > 0 ? (
                <>
                    <Section 
                        bgColor={white}
                        className='fade-in'
                    >
                        <Box ref={toPrint} sx={{width: '100%'}}>
                            <Title 
                                float='none'
                                title={title} 
                                display='none'
                                textAlign='center'
                                className='print-page-title'
                            />
                            <table 
                                cellSpacing='0' 
                                className='table' 
                                style={tableStyle} 
                            >
                                <thead style={{borderBottom: `1px solid ${lightBlue}`}}>
                                    <tr>
                                        {!(isSelectDisabled || isViewOnly) && (
                                            <th style={{minWidth: '30px', width: '5%'}} className='select'>
                                                <HMCheckbox 
                                                    handleChange={(e) => handleCheckAll(e)} 
                                                />
                                            </th>
                                        )}
                                        {Object.values(columns).map((obj, index) => (
                                            <th key={index} style={{...headerStyle, width: obj.width || `${thW}%`}} className={`column-${index}`}>
                                                {obj.headerName}
                                                {obj.sortable && (
                                                    <IconButton   
                                                        sx={{
                                                            padding: '5px',
                                                            margin: ' 0 0 0 10px'
                                                        }}
                                                    >
                                                        <ArrowUpDown 
                                                            field={obj.field} 
                                                            isUpward={isUpward}
                                                            handleClick={sortData}
                                                            sx={{ fontSize: `${fontSize + 2}px`}}
                                                        />
                                                    </IconButton>
                                                )}
                                            </th>
                                        ))}
                                        {!isViewOnly && (
                                            <th style={{...headerStyle, minWidth: '52px', textAlign: 'right', paddingRight: '15px'}}  className='action'>Action</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody style={tbodyStyle}>
                                    {Object.values(rows).slice(0, showingRows).map((obj, index) => (
                                        <tr 
                                            key={index} 
                                            style={{backgroundColor: index % 2 === 0 ? lightBlue : 'inherit'}}
                                            className= {index % 2 === 0 ? 'odd' : 'even'}
                                        > 
                                            {!(isSelectDisabled || isViewOnly) && (
                                                <td className='select'>
                                                    <HMCheckbox 
                                                        checked={obj.select} 
                                                        handleChange={(e) => handleCheckboxChange(e, obj._id)} 
                                                    />
                                                </td>
                                            )}
                                            {fields.map((field, index2) => (
                                                <td key={index2} 
                                                    className={`column-${index2}`} 
                                                    onClick={(e) => viewItem(e, obj._id)}
                                                    style={{...trStyle, cursor: 'pointer'}} 
                                                >
                                                    {obj[field] === undefined ? (
                                                        '-'
                                                    ) : field === 'image' ? (
                                                        <Link sx={linkStyle} onClick={(e) => viewItem(e, obj._id)}>
                                                            <img className='mini-image' alt={obj.name ? obj.name : ''} src={obj[field]} />
                                                        </Link>
                                                    ) : (field === 'name' || field === 'firstName' || field === 'lastName' || field === 'orderNumber') ? (
                                                        <Link sx={{...linkStyle, fontWeight: 500}} onClick={(e) => viewItem(e, obj._id)}>
                                                            {obj[field]}
                                                        </Link>
                                                    ) : (field === 'createdAt' || field.includes('date')) ? (
                                                        `${moment(obj[field]).utc().format('MMM DD, YYYY')}`
                                                    ) : (field.toLowerCase().includes('price') || field.toLowerCase().includes('total') ) ? (
                                                        `${currency} ${obj[field].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` 
                                                    ) : typeof(obj[field]) === 'object' ? (
                                                        <Typography sx={{paddingLeft: '10%', fontSize: 'inherit'}}>
                                                            {obj[field].length}
                                                        </Typography>
                                                    ) : obj[field] === true ? (
                                                        <Typography sx={{paddingLeft: '3%', fontSize: 'inherit'}}>
                                                            Yes
                                                        </Typography>
                                                    ) : obj[field] === false ? (
                                                        <Typography sx={{paddingLeft: '3%', fontSize: 'inherit'}}>
                                                            No
                                                        </Typography>
                                                    ) : field === 'paymentStatus' && addStyle ? (
                                                        <Typography 
                                                            sx={{
                                                                color: white, 
                                                                padding: '0 5px',
                                                                borderRadius: '5px',
                                                                width: 'max-content',
                                                                fontSize: `${fontSize - 2}px`,
                                                                backgroundColor: obj[field].toLowerCase() === 'paid' ? green : red,
                                                            }}
                                                        >
                                                            {obj[field]}
                                                        </Typography>
                                                    ) : field === 'status' && addStyle ? (
                                                        <Typography 
                                                            sx={{
                                                                color: white, 
                                                                padding: '0 5px',
                                                                borderRadius: '5px',
                                                                width: 'max-content',
                                                                fontSize: `${fontSize - 2}px`,
                                                                backgroundColor: obj[field].toLowerCase() === 'pending' ? orange
                                                                                    : obj[field].toLowerCase() === 'confirmed' ? blue 
                                                                                    : obj[field].toLowerCase() === 'processing' ? blue 
                                                                                    : obj[field].toLowerCase() === 'completed' ? blue 
                                                                                    : obj[field].toLowerCase() === 'accepted' ? orange 
                                                                                    : obj[field].toLowerCase() === 'delivering' ? blue 
                                                                                    : obj[field].toLowerCase() === 'canceled' ? red 
                                                                                    : obj[field].toLowerCase() === 'returned' ? red 
                                                                                    : obj[field].toLowerCase() === 'refunded' ? red 
                                                                                    : obj[field].toLowerCase() === 'received' ? green 
                                                                                    : obj[field].toLowerCase() === 'delivered' && green 
                                                            }}
                                                        >
                                                            {obj[field]}
                                                        </Typography>
                                                    ) : field === 'expiredStockCount' && addStyle ? (
                                                        <Typography 
                                                            sx={{
                                                                padding: '0 5px',
                                                                borderRadius: '5px',
                                                                width: 'max-content',
                                                                fontSize: `${fontSize - 2}px`,
                                                                color: obj[field] > 0 ? white : lightBlack, 
                                                                backgroundColor: obj[field] > 0 ? red : 'inherit',
                                                            }}
                                                        >
                                                            {obj[field]}
                                                        </Typography>
                                                    ) : field === 'aboutToExpireStockCount' && addStyle ? (
                                                        <Typography 
                                                            sx={{
                                                                padding: '0 5px',
                                                                color: lightBlack, 
                                                                borderRadius: '5px',
                                                                width: 'max-content',
                                                                fontSize: `${fontSize - 2}px`,
                                                                backgroundColor: obj[field] > 0 ? warning : 'inherit',
                                                            }}
                                                        >
                                                            {obj[field]}
                                                        </Typography>
                                                    ) : field === 'stockCount' && addStyle ? (
                                                        <Typography 
                                                            sx={{
                                                                padding: '0 5px',
                                                                borderRadius: '5px',
                                                                width: 'max-content',
                                                                fontSize: `${fontSize - 2}px`,
                                                                color: obj[field] < 5 ? white : lightBlack, 
                                                                backgroundColor: obj[field] < 5 ? red : 'inherit',
                                                            }}
                                                        >
                                                            {obj[field]}
                                                        </Typography>
                                                    ) : (
                                                        obj[field]
                                                    )}
                                                </td>
                                            ))}
                                            {!isViewOnly && (
                                                <td className='action' style={{paddingRight: `${isDeleteDisabled ? '10px' : '0px'}`}}>
                                                    {!isDeleteDisabled && (
                                                        <DeleteIcon handleDelete = {() => handleDelete(obj._id)} />
                                                    )}
                                                    <EditIcon handleEdit = {() => handleEdit(obj._id)} />
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </Section>
                    <FullWidthContainer
                        display='flex'
                        marginBottom={20}
                        className='fade-in'
                    >
                        {showingRows < rows.length && (
                            <Typography sx={loadMoreStyle} onClick={loadMore}>
                                Load more...                     
                            </Typography>
                        )}                            
                    </FullWidthContainer>
                </>                            
            ) : emptyDataMessage && (
                <HMBox
                    width='100%'
                    height='50vh'
                    display='flex'
                    className='fade-in'
                >
                    <HMText
                        margin='auto'
                        color={lightBlack}
                        text={emptyDataMessage}
                    />
                </HMBox>
            )}
        </>
    )
}

export default DataTable
import React from "react";

import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import FullWidthContainer from "../../components/common/layout/FullWidthContainer";
import Section from "../../components/common/layout/Section";
import HMText from "../../components/common/tools/HMText";
import Page from "../../components/common/layout/Page";
import Title from "../../components/common/tools/Title";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import {
  iconStyle,
  fontSize,
  textStyle,
} from "../../components/common/tools/Styles";

import {
  white,
  blue,
  orange,
  darkBlue,
  lightBlack,
} from "../../hooks/useColors";
import HMBreadcrumbs from "../../components/common/navigation/HMBreadcrumbs";
import HMBox from "../../components/common/layout/HMBox";
import HMButton from "../../components/common/tools/HMButton";

function About() {
  const navigate = useNavigate();
  const breadOptions = [
    { text: "Home", link: "/landing" },
    { text: "Privacy Policy", link: null },
  ];
  return (
    <Page>
      <FullWidthContainer>
        <HMBreadcrumbs options={breadOptions} margin="auto auto auto 0" />
        <HMBox padding="0" float="right" display="flex">
          <HMButton
            type="button"
            bgColor={orange}
            isResponsive={true}
            margin="auto 0 10px auto"
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>}
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />}
          />
        </HMBox>
        <Section bgcolor={white} padding={10} marginTop={20}>
          <Grid bgcolor={white} margin="inherit" spacing={2}>
            {/* About us section */}

            <Box
              width="100%"
              display="flex"
              marginBottom={6}
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Box width="80%" sx={{ justifyContent: "center" }}>
                <Title
                  title="Privacy Policy"
                  margin="revert"
                  fontSize={fontSize + 2}
                  color={blue}
                  float="initial"
                />
                <HMText
                  text="We care about your privacy"
                  margin="revert"
                  fontSize={fontSize + 2}
                  fontWeight="800"
                  float="initial"
                  color={darkBlue}
                />
                <Box
                  component="div"
                  display="inline"
                  fontSize={fontSize}
                  float="initial"
                  color={lightBlack}
                >
                  Your privacy is important to us at
                </Box>
                <Box
                  component="div"
                  display="inline"
                  fontSize={fontSize}
                  float="initial"
                  color={orange}
                >
                  {" "}
                  HarakaMeds{" "}
                </Box>
                <Box
                  component="div"
                  display="inline"
                  fontSize={fontSize}
                  float="initial"
                  color={lightBlack}
                >
                  . We respect your privacy regarding any information we may
                  collect from you across our website.
                </Box>
                <Box width="100%" textAlign="justify">
                  <HMText
                    marginTop="10px"
                    color={lightBlack}
                    margin="revert"
                    text="HarakaMeds, (“HarakaMeds,” “We,” “Us,” “Our”) is committed to protecting both the personal as well as business information
                            you share and/or store with us. By using this website, you consent to this Privacy Policy, which applies to transactions and activities
                              and data gathered through the HarakaMeds Website and interaction you may have with its related Social Media accounts. Please review this
                              Privacy Policy periodically as we may revise it without notice."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="What information do we collect?"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="Generally, we collect information that may personally identify you when you voluntarily:"
                  />
                  <Grid container>
                    <Grid>
                      <ul
                        style={{
                          listStyle: "disc",
                          color: "#7D7D7D",
                          padding: "revert",
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <li>Register an account on this Website, </li>
                        <li>place an order, </li>
                        <li>enter a contest,</li>
                        <li>
                          {" "}
                          respond to a survey or communication such as e-mail,
                          or
                        </li>
                        <li>engage with another Website feature.</li>
                      </ul>
                    </Grid>
                  </Grid>
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="You may choose to create a personal account on our Website, which may require that you 
                                    disclose certain personally identifiable information for identification, including, phone number,
                                     a unique email address and demographic information."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Cookies and Google Analytics"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={lightBlack}
                  >
                    Accepting cookies is not necessary to visit our website, but
                    mandatory in order to complete correctly an order. What are
                    cookies? Cookies are small text files placed on your
                    computer while visiting websites. They are necessary to have
                    websites work more efficiently or to provide information to
                    the website’s owners. We collect cookies to remember your IP
                    address, so that you can save time when coming back on our
                    website. To get more information about cookies, please visit
                  </Box>
                  <Link
                    href="www.allaboutcookies.org"
                    sx={{ m: "0.2rem" }}
                    fontSize={fontSize}
                    float="initial"
                    color={blue}
                    underline="none"
                  >
                    www.allaboutcookies.org .
                  </Link>
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="How will we use the information we collect from you?"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="We collect and use your personal data on secured servers. We ask third organisms to check and certify our confidentiality principles.
                                    Information that we collect from you is used to process your order and to manage your account. We may also use your information to email you about other products or services that we think may be of interest to you.
                                    In processing your order we may send your information to credit reference and fraud prevention agencies, or to third companies (pharmacies and delivering companies).
                                    From time to time we may send your information to third parties which we consider may have goods or services which are of interest to you.
                                    
                                    You may choose to create a personal account on our Website, which may require that you 
                                    disclose certain personally identifiable information for identification, including, phone number,
                                     a unique email address and demographic information."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Changes to Personally Identifiable Information"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={lightBlack}
                  >
                    To change any personally identifiable information that you
                    have shared with us, please access the “My Account” section
                    of our Website.
                  </Box>
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={blue}
                  >
                    My Account
                  </Box>
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={lightBlack}
                  >
                    ” section of our Website.
                  </Box>
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Direct Communication"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    width="100%"
                    text="We may contact you via email, telephone, text, paper mail, or other delivery method 
                                    regarding the status and/or delivery of any products or services that you may have purchased through this Website."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="E-mail List"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="If you have opted-in to receive email newsletters from us, you may periodically receive communications including links to and information 
                                    regarding sales, promotions, sweepstakes, and other information that we believe you may find interesting."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="If you have not opted-in to receive email newsletters from us, you will not receive these e-mails."
                  />
                </Box>

                <Box width="100%" textAlign="justify">
                  <Title
                    title="What Information is Collected Through Technology and by Third-Parties?"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="As you browse through our website and place orders, we automatically collect and store the following non-personal, or anonymous information about your visit:"
                  />
                  <Grid container>
                    <Grid>
                      <ul
                        style={{
                          listStyle: "disc",
                          color: "#7D7D7D",
                          padding: "revert",
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <li>The date and time you access our site; </li>
                        <li>the pages you visit on our site;</li>
                        <li>
                          if You navigate to our site by clicking a link, the
                          location of that link;
                        </li>
                        <li>
                          the technical capabilities of the computer you use to
                          access our site;
                        </li>
                        <li>
                          the internet service provider You use to connect to
                          our site (for example “companyX.com” if you have a
                          commercial internet account, or “universityX.edu” if
                          you connect from a university); and
                        </li>
                        <li>
                          {" "}
                          the IP address (a number automatically assigned to
                          your computer whenever you are surfing the internet)
                          from which you access our site.
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                  <HMText
                    float="initial"
                    color={lightBlack}
                    margin="revert"
                    text="We use this information, in the aggregate, to make our website more useful to visitors — to learn about the number of visitors to our site and the types of technology used, 
                                    to detect operational problems, and to improve the website’s overall security."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Cookies, Beacons, Local Storage and Other Similar Technologies"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We use “cookies,” Web beacons, HTML5 local storage and other similar technologies. Cookies are small files that a site or its service 
                                    provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s 
                                    systems to recognize your browser and capture and remember certain information. For example, we use cookies to help us remember and 
                                    process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site 
                                    activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site 
                                    traffic and site interaction so that we can offer better site experiences and tools in the future."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="You may choose to have your computer warn you each time a cookie is being sent, or you may even choose to turn off all cookies 
                                    through your browser (like Mozilla Firefox or Internet Explorer) settings. Each browser is a little different, so look to your browser 
                                    Help menu to learn the correct way to modify your cookies. Do note, that if you turn cookies off, you may lose access to many features 
                                    that make your experience on our website more efficient, and some of our services will not function properly. If you turn cookies off 
                                    and are unable to place an order online through our website, you may still place orders over the telephone by contacting customer service."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We do not respond to browser-based “do not track” signals."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We may transmit non-personally identifiable website usage information to third parties in order to show you 
                                    advertising for HarakaMeds when you visit other sites."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Analytics, Log Files and Reading History"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We gather certain information automatically and store it in log files. This information may include IP addresses, 
                                    browser type, operating system and other usage information about the use of our Services, including a history of the pages you view."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We may combine this automatically collected log information with other information we collect about you. We do this to improve services 
                                    we offer you, including customized recommendations, advertising, to improve marketing, and to track access and use of our Services across 
                                    the devices that you may use to access our Services."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We have hired third parties to provide us information, reports and analysis about the usage, browsing patterns of our users. They may 
                                    independently record the type of device and operating system you are using, general location information, as well as events that occur on 
                                    our Website, such as how often you use our Website."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Which Third-party Persons/Entities is My Personally Identifiable Information Shared With?"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={lightBlack}
                  >
                    We do not sell, trade, or otherwise transfer to outside
                    parties your personally identifiable information unless we
                    provide you with advance notice, except as described below.
                    The term “outside parties” does not include
                  </Box>
                  <Link
                    href="www.HarakaMeds.com"
                    sx={{ m: "0.2rem" }}
                    fontSize={fontSize}
                    float="initial"
                    color={blue}
                    underline="none"
                  >
                    www.HarakaMeds.com
                  </Link>
                  <Box
                    component="div"
                    display="inline"
                    fontSize={fontSize}
                    float="initial"
                    color={lightBlack}
                  >
                    . It also does not include website hosting partners and
                    other parties who assist us in operating our website,
                    conducting our business, or servicing you, so long as those
                    parties agree to keep your information confidential. We may
                    also release your information when we believe release is
                    appropriate to comply with the law, enforce our site
                    policies, or protect ours or others’ rights, property, or
                    safety.
                  </Box>
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Third Parties"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We may share information about our visitors in aggregate or de-identified form with third parties. Nothing in this Privacy Policy is intended to indicate a restriction of our 
                                    use or sharing of aggregated or de-identified information in any way."
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    float="initial"
                    text="We may also include links to third party websites on our Website in an attempt to provide you with increased value. These linked websites have separate and independent 
                                    privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our 
                                    site and welcome any feedback about these linked sites (including whether a specific link works)."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Disclosure by Necessity"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="We may occasionally access, preserve, and/or disclose personal information as required by law, for example, to comply with a court order or 
                              subpoena or we have a good faith belief that such action is necessary to:"
                  />
                  <Grid container>
                    <Grid>
                      <ul
                        style={{
                          listStyle: "disc",
                          color: "#7D7D7D",
                          padding: "revert",
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <li>Comply with the law or with legal process; </li>
                        <li>Protect and defend our rights and property;</li>
                        <li>
                          Protect against misuse or unauthorized use of our
                          Services; or
                        </li>
                        <li>
                          protect the personal safety or property of our users
                          or the public (among other things, this means that if
                          you provide false information or attempt to pose as
                          someone else, information about you may be disclosed
                          as part of any investigation into your actions).
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                  <HMText
                    float="initial"
                    color={lightBlack}
                    margin="revert"
                    text="As we continue to develop our business, we or our affiliates may sell or buy other businesses or entities, or we may merge with another company, or be bought by another company. 
                                    In such transactions, personal information may be among the transferred assets."
                  />
                  <HMText
                    float="initial"
                    color={lightBlack}
                    margin="revert"
                    text="Your information may be stored and processed in any country in which we maintain facilities or conduct operations. By using our Website, your personal information may be 
                                    transferred outside of your country of use and/or residence."
                  />
                  <HMText
                    float="initial"
                    color={lightBlack}
                    margin="revert"
                    text="When your account becomes inactive, your information may remain in our computers, electronic databases, and archives, and may not be reasonably capable of expungement."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Children’s Guidelines"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    float="initial"
                    margin="revert"
                    text="We do not knowingly collect or store any personal information about children under the age of 14."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Amendments"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="This Privacy Policy may be amended by us at any time and without notice, but only by amending this Policy as posted on this Website. Any amendments will become effective 30 days
                                     after being posted on the website, unless circumstances require that a change be immediately implemented."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Other Websites"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    width="100%"
                    margin="revert"
                    text="Our website may have links to other websites. This privacy policy only applies to this website. You should therefore read the privacy policies of the other websites when you are using those sites."
                  />
                </Box>
                <Box width="100%" textAlign="justify">
                  <Title
                    title="Questions and Feedback"
                    margin="revert"
                    textAlign="justify"
                    color={darkBlue}
                    float="initial"
                  />
                  <HMText
                    color={lightBlack}
                    margin="revert"
                    text="We welcome your questions, comments, and concerns about privacy. Please feel free to contact us HERE with any comments and/or concerns regarding this policy."
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Section>
      </FullWidthContainer>
    </Page>
  );
}

export default About;

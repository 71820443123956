import React from 'react'
import Grid from '@mui/material/Grid'
import HMPlacesAutocomplete from '../../components/common/tools/HMPlacesAutocomplete'
import DropdownFilter from '../../components/common/tools/DropdownFilter'
import HMTextField from '../../components/common/tools/HMTextField'
import { 
    Cells,
    Sectors, 
    Villages, 
    Districts, 
    Provinces, 
} from 'rwanda'
import {
    white,
} from '../../hooks/useColors'

function LastStep(props) {
  const {
    cell,
    sector,
    street,
    village,
    province,
    district,
    mapAddress,
    coordinates,
  } = props.formData 

  return (
    <Grid container spacing={2} sx={{marginBottom: '15px'}}>
      <Grid 
        item 
        sm={6} 
        xs={6} 
      >
        <DropdownFilter 
          field='name'
          margin='auto'
          required={true}
          bgColor={white}
          label='Province'
          value={province}
          prompt='Select province...'
          onChange={props.handleProvinceChange}
          options={Provinces().map(item => ({name: item}))}
        />
      </Grid>
      {province.name ? (
        <Grid 
          item 
          sm={6} 
          xs={6} 
        >
          <DropdownFilter 
            field='name'
            margin='auto'
            bgColor={white}
            required={true}
            label='District'
            value={district}
            prompt='Select disctrict...'
            onChange={props.handleDistrictChange}
            options={Districts(province.name).map(item => ({name: item}))}
          />
        </Grid>
      ) : <></>}
      {district.name ? (
        <Grid 
          item 
          sm={6} 
          xs={6} 
        >
          <DropdownFilter 
            field='name'
            margin='auto'
            label='Sector'
            value={sector}
            required={true}
            bgColor={white}
            prompt='Select sector...'
            onChange={props.handleSectorChange}
            options={Sectors(province.name, district.name).map(item => ({name: item}))}
          />
        </Grid>
      ) : <></>}
      {sector.name ? (
        <Grid 
          item 
          sm={6} 
          xs={6} 
        >
          <DropdownFilter 
            field='name'
            label='Cell'
            value={cell}
            margin='auto'
            required={true}
            bgColor={white}
            prompt='Select cell...'
            onChange={props.handleCellChange}
            options={Cells(province.name, district.name, sector.name).map(item => ({name: item}))}
          />
        </Grid>
      ) : <></>}
      {cell.name ? (
        <Grid 
          item 
          sm={6} 
          xs={6} 
        >
          <DropdownFilter 
            field='name'
            margin='auto'
            label='Village'
            value={village}
            required={true}
            bgColor={white}
            prompt='Select village...'
            onChange={props.handleVillageChange}
            options={Villages(province.name, district.name, sector.name, cell.name).map(item => ({name: item}))}
          />
        </Grid>
      ) : <></>}
      {village.name ? (
        <Grid 
          item 
          sm={6} 
          xs={6} 
        >
          <HMTextField 
            margin='auto'
            name='street'
            value={street}
            bgColor={white}
            required={true}
            onChange={props.handleChange}
            label='Street and house number'
            placeholder='Enter street and house number'
          />
        </Grid>
      ) : <></>}
      {(village.name && street.length) ? (
        <Grid 
          item 
          sm={12} 
          xs={12} 
        >
          <HMPlacesAutocomplete
              width='100%' 
              margin='auto' 
              value={mapAddress}
              coordinates={coordinates}
              handleChange={props.handleChangeMapAddress}
              handleSelect={props.handleSelectMapAddress}
          />   
        </Grid>
      ) : <></>}
    </Grid>
  )
}

export default LastStep
import Typography from '@mui/material/Typography';
import { Card } from "@mui/material"
import Box from '@mui/material/Box';
import Title from '../../../components/common/tools/Title';
import { fontSize } from '../../../components/common/tools/Styles';
import Grid from '@mui/material/Grid'
import HMRadialBar from "./HMRadialBar"

const HMCardRadial = (props) => {
    return (
    <Card sx={{ width: '100%',height:'auto',margin: "auto", padding: '0px', boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.07)" }}>
        <Grid container spacing={1} margin="auto">
            <Grid item xs={12} md={6}>
                <Box marginTop="10px">
                    <HMRadialBar data={props.data} backColor={props.backColor} fillColor={props.fillColor} />
                </Box>  
            </Grid>
            <Grid item xs={12} md={6} margin="auto">
                <Typography fontSize={`${fontSize -1}px`}>
                    {props.title} 
                </Typography>
                <Title 
                    title= {props.value}
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 6}px`}
                />
            </Grid>
        </Grid>
        
        
    </Card>
  )
}

export default HMCardRadial
import Box from '@mui/material/Box'
import MoreVert from '@mui/icons-material/MoreVert'
import TrendingUp from '@mui/icons-material/TrendingUp'
import TrendingDown from '@mui/icons-material/TrendingDown'
import HMSparkLine from './HMSparkLine'
import HMBox from '../common/layout/HMBox'
import Title from '../common/tools/Title'
import { 
    fontSize, 
} from '../common/tools/Styles'

function CreditDetailsCard(props) {
    return ( 
        <HMBox
            display='flex'
            minWidth='250px'
            height={props.height}
            bgColor={props.bgColor}
            width={props.width || '100%'}
            padding={props.padding || '0'}
            margin={props.margin || 'auto 0'}
            flexDirection={props.flexDirection || 'column'}
        >
            <Box 
                border={1} 
                width='100%' 
                display='flex'
                borderRadius='15px'
                flexDirection='column'
                padding='10px 0 0 10px'
                justifyContent='space-between' 
                borderColor='rgba(32, 184, 241, 0.35)'
            >
                <Box 
                    width='100%' 
                    display='flex'
                    justifyContent='space-between' 
                >
                    <Title 
                        margin='0'
                        height='auto'
                        float='initial'
                        textAlign={'top'}
                        fontWeight={'400'}
                        size={fontSize + 4}
                        title={props.title} 
                        color='rgba(47, 56, 84, 0.8)'
                    />
                    <HMBox>
                        <MoreVert sx={{ color: 'rgba(47, 56, 84, 0.8)', margin: '0' }}/>    
                    </HMBox>
                </Box>
                <Box 
                    display='flex'
                    marginTop='10px'
                    justifyContent='space-between' 
                >
                    <Box 
                        display='flex'
                        width='auto'
                        flexDirection='column'
                        justifyContent='center'
                    >
                        <Title 
                            margin='0'
                            height='auto'
                            float='initial'
                            textAlign={'top'}
                            fontWeight={'bold'}
                            title={props.amount} 
                            size={fontSize + 14}
                            color='rgba(47, 56, 84, 0.8)'
                        />
                        <Box 
                            gap={1} 
                            display='flex'
                            alignItems='center'
                            padding='10px 20px'
                        >
                            {props.colors[0] === '#16D39A' ?  (
                                <Box 
                                    display='flex'
                                >
                                    <TrendingUp sx={{margin: '0 2px 0 0', color: `${props.colors[0]}`}} /> 
                                    <Title 
                                        float='initial'
                                        title= {`${props.change}%`} 
                                        height= 'auto'
                                        textAlign={'top'}
                                        size={fontSize }
                                        color= {props.colors[0]}
                                        margin = {'0'}
                                        fontWeight={'500'}
                                    />
                                </Box>
                            ) : (
                                <Box 
                                    display='flex'
                                >
                                    <TrendingDown sx={{margin: '0 2px 0 0', color: `${props.colors[0]}`}} /> 
                                    <Title 
                                        margin='0'
                                        height='auto'
                                        textAlign='top'
                                        float='initial'
                                        size={fontSize}
                                        fontWeight='500'
                                        color={props.colors[0]}
                                        title={`${props.change * (-1)}%`} 
                                    />
                                </Box>
                            )}
                            <Title 
                                margin='0'
                                height='auto'
                                float='initial'
                                textAlign='top'
                                fontWeight={500}
                                size={fontSize - 1}
                                title='vs Total Credit' 
                                color='rgba(47, 56, 84, 0.8)'
                            />
                        </Box>
                    </Box>
                    <HMBox 
                        width='48%'
                        height='80px'
                        margin='0 10px 15px 0'
                    >
                        <HMSparkLine data={props.data} colors={props.colors}/>
                    </HMBox>
                </Box>
            </Box>
        </HMBox> 
     )
}

export default CreditDetailsCard
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import moment from 'moment'
import Box  from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Typography  from '@mui/material/Typography'
import CircleIcon from '@mui/icons-material/Circle'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  getMe,
  resetMe,
  updateMe
} from '../../features/me/meSlice'
import { 
  getInsurers,
  resetInsurer,
} from '../../features/insurers/insurerSlice'
import PaymentMethodDetails from '../../components/checkout/PaymentMethodDetails'
import HMInsuranceCardForm from '../../components/checkout/HMInsuranceCardForm'
import EditDeliveryInfo from '../../components/checkout/EditDeliveryInfo'
import HMInsuranceCard from '../../components/checkout/HMInsuranceCard'
import AddressDetails from '../../components/checkout/AddressDetails'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMPasswordGenerator from '../../components/common/tools/HMPasswordGenerator'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import HMImage from '../../components/common/tools/HMImage'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  red,
  gray,
  blue, 
  green, 
  white, 
  orange,
  darkBlue,
  lightRed,
  lightBlue, 
  lightGreen,
  lightBlack, 
  lightOrange,
  getRandomColor,
  seeThroughLightBlue
} from '../../hooks/useColors'
import { 
  fontSize, 
  textStyle, 
  iconStyle, 
  deleteTextStyle
} from '../../components/common/tools/Styles'
import {
  accessLevels,
  generatePassword,
  validatePhoneNumber,
  stringifyPhoneNumber, 
} from '../../hooks/helperFunctions'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const deliveryInfoSekeleton = {
  phone: '',
  mapAddress: '',
  default: false,
  category: 'Home',
  recipientName: '',
  coordinates: {
    lng: null,
    lat: null,
  }
}
const insuranceInfoSekeleton = {
  save: true,
  insurer: '',
  cardImage: '',
  default: false,
  nameOnCard: '',
  cardNumber: '',
}

function Profile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { 
    me, 
    isOne, 
    isUpdated,
    isLoading, 
  } = useSelector(state => state.me)
  const { user } = useSelector(state => state.auth)
  const { visible } = useSelector(state => state.nav)
  const { insurers, isSuccess } = useSelector(state => state.insurer)

  const path = window.location.pathname.toLowerCase()

  const {
    windowW,
    windowH
  } = useWindowDimensions()

  const isBigHeightScreen = windowH < 1000 ? true : false
  const isNormalHeightScreen = windowH < 650 ? true : false
  const isSmallHeightScreen = windowH < 400 ? true : false
  const isVerySmallHeightScreen = windowH < 300 ? true : false

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false
  
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [cardImageUrl, setCardImageUrl] = useState('')
  const [wrongNumber, setWrongNumber] = useState(false)
  const [isAddAddress, setIsAddAddress] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [isAddInsurance, setIsAddInsurance] = useState(false)
  const [specificInsurer, setSpecificInsurer] = useState(null)
  const [isEditInsurance, setIsEditInsurance] = useState(false)
  const [isDeleteAddress, setIsDeleteAddress] = useState(false)
  const [isPasswordChange, setIsPasswordChange] = useState(false)
  const [isDeleteInsurance, setIsDeleteInsurance] = useState(false)
  const [currentAddressIndex, setCurrentAddressIndex] = useState(null)
  const [currentInsuranceCard, setCurrentInsuranceCard] = useState({})
  const [isAddSpecificInsurer, setIsAddSpecificInsurer] = useState(false)
  const [deliveryInfo, setDeliveryInfo] = useState(deliveryInfoSekeleton)
  const [currentInsuranceIndex, setCurrentInsuranceIndex] = useState(null)
  const [insurerFormData, setInsurerFormData] = useState(insuranceInfoSekeleton)

  const [formData, setFormData] = useState({
    password: '',
    hasNumber: false,
    hasSymbols: false,
    passwordLength: 5,
    hasUpperCase: false,
    hasLowerCase: false,
  })

  const handleGeneratePassword = () => {
    const { 
      hasNumber,
      hasSymbols,
      hasUpperCase,
      hasLowerCase,
      passwordLength,
    } = formData

    setFormData((prevState) => ({
      ...prevState,
      password: generatePassword(hasNumber, hasSymbols, hasUpperCase, hasLowerCase, passwordLength)
    }))
  }
  
  useEffect(() => {
    if (user) {
      dispatch(getMe())
      dispatch(getInsurers())
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    setInsurerFormData((prevState) => ({
      ...prevState,
      cardImage: cardImageUrl
    }))
  }, [cardImageUrl])
  
  useEffect(() => {
    if (isLoading || isUpdated || isOne) {
      dispatch(resetMe())
    }

    if (isSuccess) {
      dispatch(resetInsurer)
    }
    // eslint-disable-next-line
  }, [isOne, isUpdated, isLoading, isSuccess])


  const handleEdit = () => {
    navigate(
      path.includes('admin') ? '/admin/my-profile/edit'
      : path.includes('store') ? '/store/my-profile/edit'
      : path.includes('supplier') ? '/supplier/my-profile/edit'
      : '/profile/edit'
    )
  }

  const breadOptions = [
    { text: 'Home', link: `${
        path.includes('store') ? '/store'
        : path.includes('supplier') ? '/supplier'
        : path.includes('admin') ? '/admin/dashboard' 
        : '/landing'
      }` 
    },
    { text: 'Profile', link: null }
  ]

  const handlePasswordChangeModal = () => {
    setOpen(true)
    setIsPasswordChange(true)
    setTitle('Change Password') 
  }
  const handleFieldsChange = (e) => {
    const id = e.target.id
    let value = null
    if (id === 'passwordLength') {
      value = (
        parseInt(e.target.value) >= 5 && parseInt(e.target.value) <= 15
        ) ? e.target.value : formData.passwordLength
    } else if (id === 'password') {
      value = e.target.value
    } else {
      value = e.target.checked
    }
    
    if (value !== null) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: value
      }))
    }
  }
  const handlePasswordSubmit = (e) => {
    e.preventDefault()

    const updatedMe = {
      _id: me._id,
      password: formData.password
    }
    dispatch(updateMe(updatedMe))
    handleClose()
  }

  const handleAddAddressModal = () => {
    setOpen(true)
    setIsAddAddress(true)
    setIsEditAddress(false)
    setTitle('Add Delivery Address')
    setDeliveryInfo(deliveryInfoSekeleton)
  }
  const handleAddAddressSave = () => {
    let updatedDeliveryInfo = []
    if (me.deliveryInfo && me.deliveryInfo.length) { 
      if (deliveryInfo.default) {
        updatedDeliveryInfo = me.deliveryInfo.map(data => ({
          ...data,
          default: false,
        }))
      } else {
        updatedDeliveryInfo = me.deliveryInfo.map(data => data)
      }
      updatedDeliveryInfo.push(deliveryInfo)
    } else {
      updatedDeliveryInfo.push({
        ...deliveryInfo,
        default: true,
      })
    }

    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }
    dispatch(updateMe(updatedMe))
    
    handleClose()
  }

  const handleEditAddressModal = (e, addressInfo, addressIndex) => {
    e.stopPropagation()
    setOpen(true)
    setIsEditAddress(true)
    setDeliveryInfo(addressInfo)
    setTitle('Edit Delivery Address')
    setCurrentAddressIndex(addressIndex)
  }
  const handleEditAddressSave = () => {
    const updatedDeliveryInfo = me.deliveryInfo.map((data, index) => {
      if (index === currentAddressIndex) {
        return deliveryInfo
      } else {
        if (deliveryInfo.default) {
          return {
            ...data,
            default: false
          }
        } else {
          return data
        }
      }
    })
    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }
    dispatch(updateMe(updatedMe))
    handleClose()
  }

  const handleDeleteAddressModal = (e, addressInfo, addressIndex) => {
    e.stopPropagation()
    setOpen(true)
    setIsDeleteAddress(true)
    setDeliveryInfo(addressInfo)
    setTitle('Delete Delivery Address')
    setCurrentAddressIndex(addressIndex)
  }
  const handleDeleteAddress = () => {
    const updatedDeliveryInfo = me.deliveryInfo.filter(addressInfo => addressInfo._id !== deliveryInfo._id)

    if (deliveryInfo.default && updatedDeliveryInfo.length) {
      updatedDeliveryInfo[0] = {
        ...updatedDeliveryInfo[0],
        default: true
      }
    }
    
    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }

    dispatch(updateMe(updatedMe))

    handleClose()
  }

  const handleAddInsuranceModal = () => {
    setOpen(true)
    setIsAddInsurance(true)
    setTitle('Select Your Insurer')
  }
  const handleAddSpecificInsurerModal = (insurer) => {
    setOpen(true)
    setIsAddInsurance(false)
    setSpecificInsurer(insurer)
    setIsAddSpecificInsurer(true)
    setInsurerFormData(insuranceInfoSekeleton)
    setTitle(`Adding Insurance - ${insurer.name}`)
  }
  const handleAddInsurance = () => {
    let updatedInsuranceInfo = []
    const newInsuranceData = {
      insurer: specificInsurer._id,
      default: insurerFormData.default,
      cardImage: insurerFormData.cardImage,
      cardNumber: insurerFormData.cardNumber,
      nameOnCard: insurerFormData.nameOnCard,
    }
    if (me.insuranceInfo && me.insuranceInfo.length) {
      if (newInsuranceData.default) {
        updatedInsuranceInfo = me.insuranceInfo.map(data => ({
          ...data,
          default: false,
        }))
      } else {
        updatedInsuranceInfo = me.insuranceInfo.map(data => data)
      }
    } 
    updatedInsuranceInfo.push(newInsuranceData)

    const updatedMe = {
      insuranceInfo: updatedInsuranceInfo
    }

    dispatch(updateMe(updatedMe))

    handleClose()
  }
  const handleEditInsuranceModal = (info, index) => {
    setOpen(true)
    setIsEditInsurance(true)
    setTitle(`Update Insurance Card - ${info.insurer.name}`)
    setInsurerFormData({
      ...info,
      cardImage: insurerFormData.cardImage,
    })
    setCardImageUrl(info.cardImage)
    setCurrentInsuranceIndex(index)
  }
  const handleEditInsurance = () => {
    let updatedInsuranceData = {
      default: insurerFormData.default,
      cardImage: insurerFormData.cardImage,
      cardNumber: insurerFormData.cardNumber,
      nameOnCard: insurerFormData.nameOnCard,
    }
    const updatedInsuranceInfo = me.insuranceInfo.map((data, index) => {
      if (index === currentInsuranceIndex) {
        updatedInsuranceData = {
          ...updatedInsuranceData,
          insurer: data.insurer,
        }
        return updatedInsuranceData
      } else {
        if (insurerFormData.default) {
          return {
            ...data,
            default: false
          }
        } else {
          return data
        }
      }
    })
    const updatedMe = {
      insuranceInfo: updatedInsuranceInfo
    }
    dispatch(updateMe(updatedMe))
    
    handleClose()
  }
  const handleDeleteInsuranceModal = (info, index) => {
    setOpen(true)
    setIsDeleteInsurance(true)
    setCurrentInsuranceIndex(index)
    setTitle('Delete Insurance Card')
    setCurrentInsuranceCard(info)
  }
  const handleDeleteInsurance = () => {
    const updatedInsuranceInfo = me.insuranceInfo.filter(info => info._id !== currentInsuranceCard._id)

    if (deliveryInfo.default && updatedInsuranceInfo.length) {
      updatedInsuranceInfo[0] = {
        ...updatedInsuranceInfo[0],
        default: true
      }
    }
    
    const updatedMe = {
      insuranceInfo: updatedInsuranceInfo
    }

    dispatch(updateMe(updatedMe))

    handleClose()
  }
  
  const handleInsurerDataChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    setInsurerFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleSaveInsurer = () => {
    setInsurerFormData((prevState) => ({
      ...prevState,
      save: !insurerFormData.save,
    }))
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setCardImageUrl('')
    setIsAddAddress(false)
    setIsEditAddress(false)
    setIsAddInsurance(false)
    setIsEditInsurance(false)
    setIsDeleteAddress(false)
    setIsPasswordChange(false)
    setIsDeleteInsurance(false)
    setCurrentInsuranceCard({})
    setIsAddSpecificInsurer(false)
  }

  const handleChangeMapAddress = (address) => {
    setDeliveryInfo((prevState) => ({
      ...prevState,
      mapAddress: address
    }))
  }

  const handleSelectMapAddress = async (address) => {
    const results = await geocodeByAddress(address)
    const ll = await getLatLng(results[0])

    setDeliveryInfo((prevState) => ({
      ...prevState,
      coordinates: ll,
      mapAddress: address,
    }))
  }
  const handleDeliveyInfoChange = (e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'phone' && validatePhoneNumber(value) !== null) {
      const {
        isValid,
        phoneNumber
      } = validatePhoneNumber(value)

      if (!isValid && phoneNumber.length) {
        e.target.classList.add('wrong-input')
        setWrongNumber(true)
      } else if (isValid || !phoneNumber.length) {
        e.target.classList.remove('wrong-input')
        setWrongNumber(false)
      } 

      setDeliveryInfo((prevState) => ({
        ...prevState,
        [name]: phoneNumber,
      }))
    } 
    
    if (name !== 'phone') {
      setDeliveryInfo((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  const disabledDilivery = (
    deliveryInfo.category === '' ||
    deliveryInfo.mapAddress === '' ||
    deliveryInfo.recipientName === '' ||
    wrongNumber === true 
  ) ? true : false

  return user ? (
    <Page>
      {isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer  
        display='flex' 
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          {Object.keys(me).length ? (
            <Typography 
              sx={{ 
                fontWeight: 500,
                display: 'flex',
                padding: '0 5px',
                borderRadius: '10px',
                margin: 'auto 10px auto auto', 
                color: me.status === 'active' ? green : red, 
                backgroundColor: me.status === 'active' ? lightGreen : lightRed,
              }}
            >
              <CircleIcon 
                sx={{ 
                  width: '10px', 
                  margin: 'auto 5px auto auto', 
                  color: me.status === 'active' ? green : red, 
                }} 
              />
              {me.status}
            </Typography>
          ) : <></>}
          <HMButton 
            type='button'
            bgColor={blue}
            margin='auto 10px'
            isResponsive={true}
            handleClick={handleEdit}
            icon={<EditIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Edit</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto 10px'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal
        open={open} 
        title={title}
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isPasswordChange ? (
          <Box 
            component='form' 
            sx={{margin: '10px 0'}}
            onSubmit={handlePasswordSubmit}
          >
            <HMPasswordGenerator 
              password={formData.password}
              hasNumber={formData.hasNumber}
              hasSymbols={formData.hasSymbols}
              hasUpperCase={formData.hasUpperCase}
              hasLowerCase={formData.hasLowerCase}
              handleFieldsChange={handleFieldsChange}
              passwordLength={formData.passwordLength}
              handleGeneratePassword={handleGeneratePassword}
            />
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='20px 0 10px 0'
            >
              <HMButton 
                type='button'
                bgColor={gray}
                handleClick={handleClose}
                margin='auto auto auto 0'
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='submit'
                bgColor={blue}
                margin='auto 0 auto auto'
                text={<Typography sx={textStyle}>Submit</Typography>}
                disabled={(formData.password.length < 5 || formData.password.length > 15) ? true : false}
              />
            </HMBox>
          </Box>
        ) : (isAddAddress || isEditAddress) ? (
          <HMBox
            padding='0'
            width='100%'
            minWidth={!isTabletScreen && '500px'}
          > 
            <HMBox
              padding='0'
              margin='15px 0'
              className='fade-in'
              width={isTabletScreen ? '80vw' : '100%'}
            >
              <EditDeliveryInfo 
                deliveryInfoItem={deliveryInfo}
                isTabletScreen={isTabletScreen}
                handleChange={handleDeliveyInfoChange}
                handleChangeMapAddress={handleChangeMapAddress}
                handleSelectMapAddress={handleSelectMapAddress}
              />
            </HMBox> 
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={
                  <Typography sx={textStyle}>
                    Cancel
                  </Typography>
                }
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={green}
                margin='auto 0 auto auto'
                disabled={disabledDilivery}
                handleClick={isEditAddress ? handleEditAddressSave : handleAddAddressSave}
                text={
                  <Typography sx={textStyle}>
                    Save
                  </Typography>
                }
              />
            </HMBox>
          </HMBox>
        ) : isDeleteAddress ? (
          <HMBox
            padding='0'
            width='100%'
            minWidth={!isTabletScreen && '300px'}
          > 
            <Typography sx={deleteTextStyle} className='fade-in'>
              Are you sure your want to delete the address? <br />
              Once you click on yes, it cannot be undone! 
            </Typography>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='25px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                color={darkBlue}
                fontWeight={500}
                margin='auto 10px auto 0'
                text={<Typography sx={textStyle}>Cencel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                color={white}
                bgColor={red}
                margin='auto 0 auto auto'
                handleClick={handleDeleteAddress}
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
              />
            </HMBox>
          </HMBox>
        ) : (isAddInsurance) ? (
          <HMBox
            width='100%'
            display='flex'
            overflowY='auto'
            color={darkBlue}
            flexDirection='column'
            maxHeight={
              isVerySmallHeightScreen ? '50vh' 
              : isSmallHeightScreen ? '60vh' 
              : isNormalHeightScreen ? '70vh' 
              : isBigHeightScreen ? '80vh' 
              : '80vh'
            }
          >
            {insurers.length ? insurers.filter(ins => ins.status === 'active').map((insurer, index) => (
              <HMBox
                padding='0'
                key={index}
                width='100%'
                display='flex'
                className='fade-in'
                flexDirection='column'
              >
                <PaymentMethodDetails 
                  upperText={insurer.name}
                  hoverBgColor={lightGreen}
                  bgColor={seeThroughLightBlue}
                  lowerText={`${insurer.type} | ${insurer.origin}`}
                  handleClick={() => handleAddSpecificInsurerModal(insurer)}
                  leftIcon={
                    insurer.logo ? (
                      <img
                        alt={insurer.name}
                        src={insurer.logo}
                        className='super-mini-image'
                        style={{ margin: 'auto 10px auto 0' }}
                      />
                    ) : (
                      <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                        <HMText
                          float='none'
                          width='100%'
                          margin='auto'
                          height='100%'
                          color={white}
                          fontWeight={700}
                          textAlign='center'
                          bgColor={getRandomColor()}
                          text={insurer.name.substr(0, 1).toUpperCase()}
                        />
                      </Grid>
                    )
                  }
                  rightIcon={
                    <ChevronRightOutlinedIcon 
                      sx={{
                        margin: 'auto 0 auto auto'
                      }} 
                    />
                  }
                />
              </HMBox>
            )) : <></>}
          </HMBox>
        ) : (isAddSpecificInsurer || isEditInsurance) ? ( 
          <HMInsuranceCardForm 
            cardImageUrl={cardImageUrl}
            isTabletScreen={isTabletScreen}
            setCardImageUrl={setCardImageUrl}
            insurerFormData={insurerFormData}
            handleSaveInsurer={handleSaveInsurer}
            handleInsurerDataChange={handleInsurerDataChange}
            backOrCancelText={isEditInsurance ? 'Cancel' : 'Back'}
            handleConfirm={isEditInsurance ? handleEditInsurance : handleAddInsurance}
            handleBackOrCancel={isEditInsurance ? handleClose : handleAddInsuranceModal}
          />
        ) : isDeleteInsurance ? (
          <HMBox
            padding='0'
            width='100%'
            minWidth={!isTabletScreen && '300px'}
          > 
            <Typography sx={deleteTextStyle} className='fade-in'>
              Are you sure your want to delete this insurance? <br />
              Once you click on yes, it cannot be undone! 
            </Typography>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='25px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                color={darkBlue}
                fontWeight={500}
                margin='auto 10px auto 0'
                text={<Typography sx={textStyle}>Cencel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                color={white}
                bgColor={red}
                margin='auto 0 auto auto'
                handleClick={handleDeleteInsurance} 
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
              />
            </HMBox>
          </HMBox>
        ) : <></>}
      </HMModal>
      {Object.keys(me).length ? (
        <>
          <Section
            padding={10}
            bgColor={white}
          >
            <Grid
              container spacing={0} 
            >
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMBox
                  padding='0'
                  display='flex'
                  height='20vh'
                  minHeight='110px'
                  margin='auto auto auto 0'
                >
                  {me['image'] ? (
                    <HMImage
                      width='20vh'
                      height='20vh'
                      src={me.image}
                      margin='auto 0'
                      borderRadius='50%'
                      alt={me.firstName}
                      border={`4px solid ${lightBlue}`}
                    />
                  ) : (
                    <HMBox 
                      padding='0'
                      display='flex'
                      margin='auto 0'
                      borderRadius='50%'
                      bgColor={lightBlue}
                    >
                      <AccountCircleIcon sx={{ width: '20vh', height: '20vh', borderRadius: '50%' }} />
                    </HMBox>
                  )}
                  <HMBox
                    width='100%'
                    display='flex'
                    margin='auto 0'
                    height='max-content'
                    bgColor={lightBlue}
                    border={`4px solid ${lightBlue}`}
                  >
                    <Grid
                    container spacing={1} 
                    sx={{margin: 'auto'}}
                    >
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          color={darkBlue}
                          fontWeight={500}
                          text={`${me.firstName} ${me.lastName}`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          bgColor={white}
                          padding='0 3px'
                          text={me.email}
                          borderRadius={5}
                          color={darkBlue}
                          fontWeight={500}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          fontWeight={500}
                          color={lightBlack}
                          text={'phone' in me ? stringifyPhoneNumber(me.phone) : ''}
                        />
                      </Grid>
                    </Grid>
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMBox 
                  width='98%'
                  display='flex'
                  bgColor={lightBlue}
                  flexDirection='column'
                  height={isTabletScreen ? '210px' : '20vh'}
                  margin={isTabletScreen ? 'auto' : 'auto auto auto 0'}
                >
                  <Grid container spacing={1} sx={{margin: 'auto', paddingRight: '10px'}}>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        margin='5px 0'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                      >
                        <HMText 
                          text='Gender'
                          margin='auto 0'
                        />
                        <Title 
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          margin='auto 0 auto auto'
                          color={!me.gender ? orange : blue}
                          title={me.gender ? me.gender : 'not set'}
                          bgColor={!me.gender ? lightOrange : lightBlue}
                          />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        margin='5px 0'
                        bgColor={white}
                        flexDirection='row'
                      >
                        <HMText 
                          margin='auto 0'
                          text='Birthday'
                        />
                        <Title 
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          margin='auto 0 auto auto'
                          color={!me.birthdate ? orange : blue}
                          bgColor={!me.birthdate ? lightOrange : lightBlue}
                          title={me.birthdate ? moment(me.birthdate).utc().format('MMM DD, YYYY') : 'not set'}
                        />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                        margin='5px 0'
                      >
                        {(() => {
                          const access = accessLevels.find(access => access.level === me.accessLevel)
                          return (
                            <HMText 
                              margin='auto 0'
                              text={access ? access.role : ''}
                            />
                          )
                        })()}
                        <Title 
                          color={blue}
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          bgColor={lightBlue}
                          title={me.accessLevel}
                          margin='auto 0 auto auto'
                        />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        margin='5px 0'
                        flexDirection='row'
                      >
                        <HMButton 
                          type='button'
                          padding='0 5px'
                          bgColor={blue}
                          paddingActive='0 4.5px'
                          margin='auto 0 auto auto'
                          icon={<EditIcon sx={iconStyle} />} 
                          handleClick={handlePasswordChangeModal}
                          text={<Typography sx={textStyle}>Reset password</Typography>} 
                        />
                      </HMBox>
                    </Grid>
                  </Grid>
                </HMBox>  
              </Grid>
            </Grid>
          </Section>

          <Section
            padding={10}
            display='flex'
            bgColor={white}
            flexDirection='column'
          >
            <HMText 
              left='0'
              top='-35px'
              height='30px'
              fontWeight={500}
              width='max-content'
              className='fade-in'
              position='relative'
              bgColor={lightBlue}
              margin='10px 0 -25px 20px'
              padding='5px 10px 0px 10px'
              border={`1px solid ${white}`}
              text='Delivery Address Information'
            />
            <Grid
              container spacing={0} 
            >
              {me.deliveryInfo.map((info, index) => (
                <Grid 
                  item 
                  xs={12} 
                  key={index}
                  sx={{ padding: '10px', display: 'flex' }}
                  sm={
                    isSmallTabletScreen ? 12 
                    : isTabletScreen ? 12  
                    : (isSamllComputerScreen && visible) ? 12
                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                    : 6
                  }
                >
                  <AddressDetails 
                    width='100%'
                    margin='auto'
                    isColumn={true}
                    canDelete={true}
                    addressInfo={info}
                    bgColor={lightBlue}
                    isDefault={info.default}
                    isTabletScreen={isTabletScreen}
                    handleChangeClick={(e) => handleEditAddressModal(e, info, index)}
                    handleDeleteClick={(e) => handleDeleteAddressModal(e, info, index)}
                  />
                </Grid>
              ))}
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMButton 
                  width='100%'
                  type='button'
                  color={blue}
                  padding='20px'
                  bgColor='inherit'
                  borderRadius={10}
                  paddingActive='20px'
                  margin='10px auto 15px auto'
                  border={`1px solid ${blue}`}
                  handleClick={handleAddAddressModal}
                  icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                  text={
                    <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                      Add delivery address
                    </Typography>
                  } 
                />
              </Grid>
            </Grid>
          </Section>

          <Section
            padding={10}
            display='flex'
            bgColor={white}
            flexDirection='column'
          >
            <HMText 
              left='0'
              top='-35px'
              height='30px'
              fontWeight={500}
              width='max-content'
              className='fade-in'
              position='relative'
              bgColor={lightBlue}
              margin='10px 0 -25px 20px'
              padding='5px 10px 0px 10px'
              text='Insurance Information'
              border={`1px solid ${white}`}
            />
            <Grid
              container spacing={0} 
            >
              {me.insuranceInfo.map((info, index) => (
                <Grid 
                  item 
                  xs={12} 
                  key={index}
                  sx={{ padding: '10px', display: 'flex' }}
                  sm={
                    isSmallTabletScreen ? 12 
                    : isTabletScreen ? 12  
                    : (isSamllComputerScreen && visible) ? 12
                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                    : 6
                  }
                >
                  <HMInsuranceCard 
                    margin='5px auto'
                    bgColor={lightBlue}
                    isProfilePage={true}
                    insuranceInfo={info}
                    insurer={info.insurer}
                    showIfDefault={isTabletScreen ? false : true}
                    handleChangeClick={() => handleEditInsuranceModal(info, index)}
                    handleDeleteClick={() => handleDeleteInsuranceModal(info, index)}
                  />
                </Grid>
              ))}
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMButton 
                  width='100%'
                  type='button'
                  color={blue}
                  padding='20px'
                  bgColor='inherit'
                  borderRadius={10}
                  paddingActive='20px'
                  margin='10px auto 15px auto'
                  border={`1px solid ${blue}`}
                  handleClick={handleAddInsuranceModal}
                  icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                  text={
                    <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                      Add Insurance Card
                    </Typography>
                  } 
                />
              </Grid>
            </Grid>
          </Section>
        </>
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing your profile'
      />
    </Section>
  )
}

export default Profile


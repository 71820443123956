import React from 'react'
import Box from '@mui/material/Box'

function Section(props) {

    const sectionStyle = {
        width: '100%',
        borderRadius: '8px',
        margin: props.margin,
        background: props.bg, 
        height: props.height,
        display: props.display,
        position: props.position,
        overflow: props.overflow,
        minHeight: props.minHeight,
        backgroundColor: props.bgColor, 
        padding: `${props.padding || 0}px`,
        flexDirection: props.flexDirection,
        marginTop: `${props.marginTop || 10}px`,
        paddingBottom: `${props.paddingBottom}px`,
        marginBottom: `${props.marginBottom || 20}px`,
    }

    return (
        <Box 
            ref={props.ref}
            sx={sectionStyle} 
            className={`section ${props.className} fade-in`} 
        >
            {props.children}
        </Box>
    )
}

export default Section
import React from 'react'
import Button from '@mui/material/Button'
import HMText from '../common/tools/HMText'
import {
    fontSize,
} from '../common/tools/Styles'
import { 
    blue,
    white,
    lightBlue,
    lightBlack,
} from '../../hooks/useColors'

function PaymentOptionCard(props) {

    const itemStyle = {
        display: 'flex',
        cursor: 'pointer',
        width: 'fit-content',
        minWidth: 'fit-content',
        flexDirection: 'column',
        textTransform: 'capitalize',
        color: props.color || lightBlack,
        margin: props.margin || '5px auto',
        padding: props.padding || '10px 30px',
        backgroundColor: props.bgColor || lightBlue,
        '&:hover': {
            color: props.hoverColor || `${white} !important`,
            backgroundColor: props.hoverBgColor || `${blue} !important`
        }, 
        '&:active': {
            padding: props.hoverPadding || '9.5px 29.5px',
        }
    }

    return (
        <Button
            sx={itemStyle}
            className='fade-in'
            onClick={props.handleClick}
        >
            {props.icon}
            <HMText 
                color='inherit'
                text={props.text}
                margin='0 auto auto auto'
                fontWeight={props.fontWeight || 500}
                fontSize={`${props.fontSize || fontSize}px`}
            />
        </Button>
    )
}

export default PaymentOptionCard
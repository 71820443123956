import React from 'react'
import HMBox from '../layout/HMBox'
import HMText from './HMText'
import HMTextField from './HMTextField'
import {
    fontSize
} from './Styles'
import rwanda from '../../../assests/rwanda.png'
import {
    red
} from '../../../hooks/useColors'
import { 
    beautify
} from '../../../hooks/helperFunctions'

function HMPhoneNumber(props) {
  return (
    <HMBox
        padding='0'
        display='flex'
        flexDirection='column'
        width={props.width || '100%'}
        margin={props.width || 'auto 0'}
        className='checkout-payment-method'
    >
        <HMBox
            padding='0'
            width='100%'
            display='flex'
        >
            <HMTextField 
                type='text'
                width='100%'
                required={true}
                name={props.name}
                className='phone-number'
                onChange={props.onChange}
                value={beautify(props.value)}
                margin='auto auto auto 62.5px'
                label={props.label || 'Phone number'}
                placeholder={props.placeholder || '788 888 888'}
            />
            <HMBox
                padding='0'
                display='flex'
                margin='7px 0 0 0'
                position='absolute'
            >
                <img 
                    alt='rwanda'
                    src={rwanda}
                    style={{
                        height: '15px',
                        margin: 'auto',
                    }}
                />
                <HMText 
                    text='+250'
                    color='black'
                    fontWeight={500}
                    margin='auto 2.5px'
                    fontSize={`${fontSize}px`}
                />
            </HMBox>
        </HMBox>
        {props.displayError ? (
            <HMText 
                color={red}
                margin='5px auto auto 0'
                fontSize={`${fontSize - 2}px`}
                text='Please input the right value!'
            />
        ) : <></>}
    </HMBox>
  )
}

export default HMPhoneNumber
import axios from 'axios'
const API_URL_BASKET = '/api/v1/basket'

// Add basket
const addBasket = async (basket, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL_BASKET, basket, config)

    const data = response.data

    return data 
}

// Get basket
const getBasket = async (storeId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_BASKET}/${storeId}`, config)

    const data = response.data

    return data 
}

// Delete basket
const deleteBasket = async (storeId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(`${API_URL_BASKET}/${storeId}`, config)

    const data = response.data

    return data 
}

const basketService = {
    getBasket,
    addBasket,
    deleteBasket,
}

export default basketService
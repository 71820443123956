import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
    useSelector,
    useDispatch
} from 'react-redux'
import { toast} from 'react-toastify'
import { 
    login, 
    reset,
    gmailOauth 
} from '../../features/auth/authSlice'
import BeforeLogin from '../../components/common/layout/BeforeLogin'
import HMLoginForm from '../../components/common/layout/HMLoginForm'

function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        message,
        isError,
        isLoading,
        isGmailOauth,
        isTransition,
    } = useSelector(state => state.auth)

    const path = window.location.pathname
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        rememberMe: false
    })

    const {
        email,
        password,
        // rememberMe
    } = formData

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        
        // Redirect when logged in
        if (isTransition) {
            navigate(
                path.includes('admin') ? '/admin/verify-otp'
                : path.includes('store') ? '/store/verify-otp'
                : path.includes('supplier') ? 'supplier/verify-otp'
                : '/verify-otp'
            )
        }

        if (isGmailOauth) {
            navigate(
                path.includes('admin') ? '/admin'
                : path.includes('store') ? '/store'
                : path.includes('supplier') ? 'supplier'
                : '/'
            )
        }

        dispatch(reset())
        // eslint-disable-next-line
    }, [isError, isTransition, isGmailOauth])

    const handleGoogleLoginSuccess = (response) => {
        dispatch(gmailOauth({ token: response.credential }))
    }
    const handleGoogleLoginError = (error) => {
        console.log('ERROR Login with Gmail' + error)
    }

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        const isChecked = e.target.checked

        if (name === 'rememberMe') {
            setFormData((prevState) => ({
                ...prevState,
                rememberMe: isChecked
            }))
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
    }
    
    const onSubmit = (e) => {
        e.preventDefault() 
        const userData = {
            email,
            password
        }
        dispatch(login(userData))
    }

    return (
        <BeforeLogin
            title={
                path.includes('admin') ? 'Admin Login'
                : path.includes('store') ? 'Store Login'
                : path.includes('supplier') ? 'Supplier Login'
                : 'Login'
            }
            isLoading={isLoading}
        >
            <HMLoginForm 
                path={path}
                onChange={onChange}
                onSubmit={onSubmit}
                formData={formData}
                onError={(error) => handleGoogleLoginError(error)}
                onSuccess={(response) => handleGoogleLoginSuccess(response)}
            />
        </BeforeLogin>
    )
}
  
export default Login

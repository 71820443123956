import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { 
  getUser,
  resetUser, 
  updateUser, 
} from '../../../features/users/userSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMDatePicker from '../../../components/common/tools/HMDatePicker'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMText from '../../../components/common/tools/HMText'
import {
  iconStyle,
  textStyle,
} from '../../../components/common/tools/Styles'
import { 
  accessLevels,
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  blue,
  white,
  green,
  orange,
  lightBlue
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'


const minBirthdate = new Date()
minBirthdate.setDate(minBirthdate.getDate() - 4745)

const defaultFormData = (user) => {
  const checkData = user && Object.keys(user).length ? true : false

  return {
    phone: (checkData && user.phone) ? user.phone : '',
    email: (checkData && user.email) ? user.email : '', 
    gender: (checkData && user.gender) ? user.gender : '',
    image: (checkData && user.image) ? user.image : '',
    lastName: (checkData && user.lastName) ? user.lastName : '',
    firstName: (checkData && user.firstName) ? user.firstName : '',
    birthdate: (checkData && user.birthdate) ? dayjs(user.birthdate) : dayjs(minBirthdate),
    accessLevel: checkData ? {
      role: accessLevels.find(item => item.level === user.accessLevel).role, 
      level: user.accessLevel
    } : { role: 'Customer', level: 100 },
  }
}

function EditUser() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isUploading } = useSelector((state) => state.cloudinary)
  const { user } = useSelector(state => state.auth)
  const userState = useSelector(state => state.user)
  const {
    isOne,
    isError,
    message,
    isLoading,
    isUpdated,
  } = userState

  const [currentUser, setCurrentUser] = useState({})
  const [userImageUrl, setUserImageUrl] = useState('')
  const [wrongNumber, setWrongNumber] = useState(false)
  const [isFileUploading, setFileUploading] = useState(false)

  const breadOptions = [
    { text: 'Users', link: '/admin/users' },
    { text: currentUser['firstName'] && `Eidt - ${currentUser.firstName} ${currentUser.lastName}`, link: null }
  ]
  const handleAdd = () => {
    navigate('/admin/users/create')
  }
  const handleView = () => {
    navigate(`/admin/users/${currentUser._id}`)
  }

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false

  useEffect(() => {
    if (!Object.keys(currentUser).length || (currentUser['_id'] && currentUser._id !== id)) {
      dispatch(getUser(id))
    } 
    
    if (Object.keys(currentUser).length) {
      setUserImageUrl(currentUser.image)
      setFormData(defaultFormData(currentUser))
    }
    // eslint-disable-next-line
  }, [currentUser, id])

  useEffect(() => {
    if (isError || isUpdated || isOne) {
      dispatch(resetUser())
    }

    if (isError) {
      setCurrentUser({})
      toast.success(message)
    } else if (isOne) {
      setCurrentUser(userState.user)
    } else if (isUpdated) {
      setCurrentUser(userState.user)
      toast.success('User updated successfully')
    }
    // eslint-disable-next-line
  }, [dispatch, navigate, isError, isUpdated, isOne])

  const [formData, setFormData] = useState(defaultFormData(currentUser))
  
  const { 
    phone,
    email,
    image,
    gender,
    lastName,
    firstName,
    birthdate,
    accessLevel,
  } = formData

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  useEffect(()=>{
    if(userImageUrl) setFileUploading(false)
  },[userImageUrl])

  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      image: userImageUrl,
    }))
  },[userImageUrl])
  
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    if (name === 'phone') {
      if (value.length <= 10) {
        const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value)
        if (!isValid && value.length) {
          e.target.classList.add('wrong-input')
          setWrongNumber(true)
        } else if (isValid || !value.length) {
          e.target.classList.remove('wrong-input')
          setWrongNumber(false)
        } 
  
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
      }
    } 
    
    if (name !== 'phone') {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  const handleBirthdateChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      birthdate: newValue
    }))
  }

  const handleAccessLevelChangeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      accessLevel: val
    }))
  }

  const genders = {
    question: 'Gender',
    choices: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' }
    ]
  }

  const disabled = (
    email === '' ||
     isFileUploading || 
    lastName === '' ||
    firstName === '' ||
    wrongNumber === true
  ) ? true : false

  const handleUpdate = (e) => {
    e.preventDefault()

    const updatedUser = {
      email,
      image,
      gender,
      lastName,
      firstName,
      _id: currentUser._id,
      phone: parseInt(phone),
      birthdate: birthdate.$d,
      accessLevel: accessLevel.level
    }

    for (let key in updatedUser) {
      if (!updatedUser[key]) {
        delete updatedUser[key]
      }
    } 

    dispatch(updateUser(updatedUser))
  } 

  return user ? (
    <Page>
      {isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            handleClick={handleView}
            icon={<RemoveRedEyeIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>View</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleAdd}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add User</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      {Object.keys(currentUser).length ? (
        <Section
          bgColor={white}
          padding={10}
        >
          <Grid 
            component='form'
            container spacing={1} 
            onSubmit={handleUpdate}
          >
            <Grid item xs={12} sm={12}>
              <HMBox
                width='100%'
                display='flex'
                padding='10px'
                margin='30px 0 0 0'
                bgColor={lightBlue}
                flexDirection='column'
                maxWidth={`${isTabletScreen && '100% !important'}`}
              >
                <HMText 
                  left='0'
                  top='-35px'
                  height='30px'
                  bgColor={white}
                  fontWeight={500}
                  width='max-content'
                  className='fade-in'
                  position='relative'
                  text='Upload your photo'
                  margin='10px 0 -25px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMDropZone 
                  bgColor={white}
                  allowedSizeInMB={1}
                  className='drag-drop' 
                  cloudinaryFileUrl={userImageUrl}
                  endPoint={cloudinary.API_URL_USERS}
                  setCloudinaryFileUrl={setUserImageUrl}
                  supportedFiles = {{
                    'image/png': [],
                    'image/jpg': [],
                    'image/jpeg': [],
                  }}
                />
              </HMBox>
            </Grid>
            <Grid item xs={12} sm={3}>
              <HMTextField 
                name='firstName'
                required={true}
                value={firstName}
                label='FirstName'
                onChange={handleChange}
                placeholder='Enter fristname'
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <HMTextField 
                name='lastName'
                required={true}
                value={lastName}
                label='LastName'
                onChange={handleChange}
                placeholder='Enter lastName'
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <HMTextField 
                name='email'
                type='email'
                label='Email'
                value={email}
                required={true}
                onChange={handleChange}
                placeholder='Enter email'
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <HMTextField 
                name='phone'
                type='number'
                value={phone}
                label='Phone number'
                onChange={handleChange}
                placeholder='Enter phone number'
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{display: 'flex'}}>
              <HMRadioButton 
                name='gender'
                value={gender}
                padding='2px 5px'
                flexDirection='row'
                onChange={handleChange}
                label={genders.question}
                options={genders.choices}
                labelId='require-prescription-radio-group'
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{display: 'flex'}}>
              <HMBox
                width='100%'
                margin='auto'
                display='flex'
              >
                <HMText 
                  width='100%'
                  margin='auto'
                  text='Birthdate'
                  textAlign={isTabletScreen ? 'left' : 'center'}
                />
                <HMDatePicker
                  value={birthdate}
                  margin='auto 0 auto auto'
                  maxDate={dayjs(minBirthdate)}
                  onChange={handleBirthdateChange}
                />
              </HMBox>
            </Grid>
            <Grid item xs={12} sm={4} sx={{display: 'flex'}}>
              <DropdownFilter
                width='100%' 
                field='role'
                label='Role'
                bgColor={white}
                mainMargin='auto'
                value={accessLevel}
                prompt='Select role'
                options={accessLevels}
                onChange={handleAccessLevelChangeChange}
              />
            </Grid>
            <Grid item xs={12}>
              <HMButton 
                width='100%'
                type='submit'
                float='right'
                bgColor={blue}
                disabled={disabled}
                margin='20px 0 5px 0'
                text={<Typography sx={textStyle}>Update user</Typography>}
              />
            </Grid> 
          </Grid>
        </Section>
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing this page'
      />
    </Section>
  )
}

export default EditUser
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import PublishIcon from '@mui/icons-material/Publish'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone'
import { 
  getProducts, 
  resetProduct,
  deleteProduct, 
  insertProducts
} from '../../../features/products/productSlice'
import { 
  resetCategory,
  getCategories,
} from '../../../features/categories/categorySlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import ReactCSV from '../../../components/common/tools/ReactCSV'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
  iconStyle,
  textStyle,
  errorStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  excludeKeysFromObject
} from '../../../hooks/helperFunctions'
import { 
  red,
  blue, 
  gray,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'

function Products() {
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  const {
    isError,
    message,
    products,
    isDeleted,
    isSuccess,
    isLoading,
  } = useSelector((state) => state.product)
  const { importData } = useSelector((state) => state.import)
  const categoryState = useSelector((state) => state.category)
  const { categories } = categoryState

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isFinal, setIsFinal] = useState(false)
  const [validData, setValidData] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [currentProduct, setCurrentProduct] = useState({})
  const [selectedProducts, setSelectedProducts] = useState([])

  const {
    windowW,
  } = useWindowDimensions()
  const isTabletScreen = windowW < 600 ? true : false
  
  useEffect(() => {
    if(isError || isDeleted || isSuccess) {
      dispatch(resetProduct())
    }

    if (isSuccess) {
      setIsFinal(true)
    } else if (!isLoading && !isError) {
      setIsFinal(true)
    }

    if (categoryState.isSuccess) {
      dispatch(resetCategory())
    }

    if (isError) {
      toast.error(message)
      setIsDeleting(false)
    } else if (isDeleted) {
      setIsDeleting(false)
      toast.success('Product deleted successfully')
      dispatch(getProducts())
    }
    
  }, [dispatch, isLoading, isSuccess, isError, message, isDeleted, categoryState])

  useEffect(() => {
    if (importData.length && isFinal && categories.length) {
      validateImportedData(importData)
    }
    // eslint-disable-next-line
  }, [importData, isFinal, categories])

  useEffect(() => {
    dispatch(getProducts())
    dispatch(getCategories())
  }, [dispatch])

  const fields = []
  const columns = [
    { field: 'image', headerName: 'Image', sortable: false},
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'needsPrescription', headerName: 'Rx', sortable: false},
    { field: 'hasTax', headerName: 'Tax', sortable: false},
    { field: 'insurancePrice', headerName: 'Ins Price', sortable: false},
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const sampleData = [
    {
      name: 'Product name',
      image: 'Put image URL here',
      category: 'Product category',
      classification: 'Prescription Classification',
      needsPrescription: '[Yes / No]', 
      brand: 'Product brand',
      hasTax: '[Yes / No]',
      description: 'Product Description',
      strength: 'Product Strength',
      howToUse: 'How to consume the medication',
      sideEffects: 'Product Sides Effects',
      additional: 'Additonal information',
    },
    {
      name: 'Product name',
      image: 'URL',
      category: 'Product category',
      classification: 'Prescription Classification',
      needsPrescription: 'Yes', 
      brand: 'Product brand',
      hasTax: 'No',
      description: 'Product Description',
      strength: 'Product Strength',
      howToUse: 'How to consume the medication',
      sideEffects: 'Product Sides Effects',
      additional: 'Additonal information',
    }
  ]

  const supportedFiles = {
    'text/csv': [],
  }

  const getCurentProduct = (id) => {
    return products.filter(product => product._id === id)[0]
  }
  const handleCreate = () => {
    navigate('create')
  }
  const handleDeleteModal = (id) => {
    setCurrentProduct(getCurentProduct(id))

    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Product')
  }
  const handleBulkDeleteModal = (data) => {
    setOpen(true)
    setIsDelete(false)
    setSelectedProducts(data)
    setTitle('Delete Products')
  }
  const handleEdit = (id) => {
    navigate(`/admin/products/edit/${id}`)
  }
  const handleDelete = () => {
    setIsDeleting(true)
    dispatch(deleteProduct(currentProduct._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    // TODO
    handleClose()
  }

  const handleImportModal = () => {
    setOpen(true)
    setIsImport(true)
    setTitle('Import Products')
  }
  const handleBulkCreate = () => {
    const limit = 50
    const dataLenth = validData.length

    if (dataLenth > limit) {
      dispatch(insertProducts(validData.slice(0, limit)))
    } else {
      dispatch(insertProducts(validData))
    }
    handleClose()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsImport(false)
    setIsDelete(false)
    setCurrentProduct({})
  }

  const validateImportedData = (data) => {
    const productsObj = {}
    const alreadyInDB = []
    const tempValidData = []
    const invalidProducts = []
    for (let product of products) {
      productsObj[product.name.toLowerCase()] = product
    }

    for (let item of data) {
      if (
        (!('name' in item) || typeof(item['name']) !== 'string' || item['name'].length < 5) ||
        (!('image' in item) || typeof(item['image']) !== 'string') ||
        (!('brand' in item) || typeof(item['brand']) !== 'string') ||
        (!('hasTax' in item) || typeof(item['hasTax']) !== 'string') ||
        (!('category' in item) || typeof(item['category']) !== 'string') ||
        (!('strength' in item) || typeof(item['strength']) !== 'string') ||
        (!('howToUse' in item) || typeof(item['howToUse']) !== 'string') ||
        (!('description' in item) || typeof(item['description']) !== 'string') ||
        (!('sideEffects' in item) || typeof(item['sideEffects']) !== 'string') ||
        (!('classification' in item) || typeof(item['classification']) !== 'string') ||
        (!('needsPrescription' in item) || typeof(item['needsPrescription']) !== 'string') 
      ) {
        invalidProducts.push(item)
        continue
      }
  
      if (!(productsObj[item.name.toLowerCase()])) {
        const category = categories.find(cat => (
            cat.name.toLowerCase().replace(/\s/g, '') === item.classification.toLowerCase().replace(/\s/g, '')
          ))
        if (category) {
          tempValidData.push({
            ...excludeKeysFromObject(item, ['classification']),
            category: { id: category._id },
            hasTax: item.hasTax.toLowerCase() === 'yes' ? true : false,
            needsPrescription: item.needsPrescription.toLowerCase() === 'yes' ? true : false,
          })
        } else {
          invalidProducts.push(item)
        }
      } else {
        alreadyInDB.push(item)
      }
    }
    setValidData(tempValidData)
  }
  
  return (
    <Page>
      {isDeleting && isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <Title 
          title='Products' 
          margin='auto auto auto 0'
        />
        <HMBox 
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreate}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Product</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={blue}
            isResponsive={true}
            handleClick={handleImportModal}
            icon={<UploadFileIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Import Products</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isImport ? (
          <HMBox
            width='100%'
            className='fade-in'
            maxWidth={isTabletScreen ? '100%' : '500px'}
          > 
            <Typography sx={{...textStyle, fontStyle: 'italic', textAlign: 'center'}}>
              The field labels marked with <span style={{ color: red }}>*</span> are required input fields.  
            </Typography>
            <Typography sx={{...textStyle, textAlign: 'center'}}>
              The correct columns order is&nbsp;:&nbsp; <br />
                <span style={deleteItemTextStyle}>&nbsp;name&nbsp;<span style={{ color: red }}>*</span></span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;image&nbsp;<span style={{ color: red }}>*</span></span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;category&nbsp;<span style={{ color: red }}>*</span></span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;classification&nbsp;<span style={{ color: red }}>*</span></span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;needsPrescription&nbsp;<span style={{ color: red }}>*</span></span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;brand&nbsp;</span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;hasTax&nbsp;</span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;strength&nbsp;</span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;description&nbsp;</span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;howToUse&nbsp;</span>,&nbsp;
                <span style={deleteItemTextStyle}>&nbsp;sideEffects&nbsp;</span>,&nbsp;
                and &nbsp;
                <span style={deleteItemTextStyle}>&nbsp;additional&nbsp;</span>,&nbsp;
            </Typography>
            <ReactCSV 
              data={sampleData}
              filename='Products'
            >
              <HMButton 
                width='100%'
                type='button'
                bgColor={green}
                margin='10px 0px'
                icon={<FileDownloadTwoToneIcon sx={{...iconStyle, margin: 'auto 5px auto auto'}} />} 
                text={<Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>Download CSV sample</Typography>} 
              />
            </ReactCSV>
            <HMDropZone 
              allowedSizeInMB={1}
              className='drag-drop' 
              supportedFiles={supportedFiles} 
              cloudinaryFileUrl = ''
            />
            {validData.length ? (
              <HMBox 
                float='none'
                width='100%'
                margin='5px 0'  
                className='fade-in'
                bgColor={lightBlue}
              >
                <Typography sx={{...textStyle, textAlign: 'center'}}>
                  Received {importData.length} products.
                </Typography>
                <Typography sx={{...textStyle, textAlign: 'center'}}>
                  Only {validData.length} products are valid for creation.
                </Typography>
              </HMBox>
            ) : importData.length ? (
              <Typography sx={errorStyle}>The file conntent is not supported / valid</Typography>
            ) : <></>}
            <HMButton 
              width='100%'
              type='button'
              bgColor={blue}
              margin='15px 0 0 0'
              handleClick={handleBulkCreate}
              disabled={validData.length ? false : true}
              icon={<PublishIcon sx={{...iconStyle, margin: 'auto 5px auto auto'}} />} 
              text={<Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>Submit</Typography>} 
            />
          </HMBox>
        ) : (
          <>
            <Typography sx={deleteTextStyle}>
              Are you sure your want to delete: 
            </Typography>
            {isDelete ? (
              <Typography sx={deleteItemTextStyle}>
                {currentProduct.name} ?
              </Typography>
            ) : !selectedProducts.length ? (
              <Typography sx={deleteItemTextStyle}>
                No product selected. Select products and try again!
              </Typography>
            ) : selectedProducts.length > 10 ? (
              <Typography sx={deleteItemTextStyle}>
                {selectedProducts.length} Products?
              </Typography>
            ) : (
              <Box sx={{marginBottom: '20px'}}>
                {selectedProducts.map((product, index) => (
                    <Typography 
                      sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                      key={index}
                    >
                      {product.name}
                    </Typography>
                ))}
              </Box>
            )}
            {(selectedProducts.length || isDelete) && (
              <HMButton 
                type='button'
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                bgColor={red}
                handleClick={isDelete ? handleDelete : handleBulkDelete}
                float='right'
                margin='auto'
              />
            )}
            <HMButton 
              type='button'
              margin='auto'
              bgColor={gray}
              handleClick={handleClose}
              float={(selectedProducts.length || isDelete) && 'left'}
              text={<Typography sx={textStyle}>No, Cancel!</Typography>}
            />
          </>
        )}
      </HMModal>
      {products.length ? (
        <DataTable
          data={products}
          fields={fields}
          title='Products'
          columns={columns}
          defaultRowsNum={20}
          handleEdit={handleEdit}
          handleDelete={handleDeleteModal}    
          handleBulkDelete={handleBulkDeleteModal}
          emptyDataMessage='No products available!'
        />
      ) : <></>}
    </Page>
  )
}

export default Products
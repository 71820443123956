import React from 'react'
import Multiselect from 'multiselect-react-dropdown'
import HMBox from '../layout/HMBox'
import { 
    textStyle
} from './Styles'
import {
    blue,
} from '../../../hooks/useColors'

function HMSelectMultiple({ options, placeholder, handleRemove, handleAdd, showCheckbox, selectedValues }) {

    return (
        <HMBox
            padding='0'
            width='100%'
        >
            <Multiselect
                options={options}
                displayValue='name'
                onSelect={handleAdd}
                onRemove={handleRemove}
                placeholder={placeholder}
                onSearch={function noRefCheck(){}}
                showCheckbox={showCheckbox || false}
                emptyRecordMsg='Loading products...'
                selectedValues={selectedValues || []}
                onKeyPressFn={function noRefCheck(){}}
                style={{
                    chips: {
                      ...textStyle,
                      padding: '2px 5px',
                      marginRight: '3px',
                      backgroundColor: blue,
                    },
                    multiselectContainer: {
                      ...textStyle
                    },
                    highlightOption: {
                        backGroundColor: 'black !important'
                    }
                  }}
            />
        </HMBox>
    )
}

export default HMSelectMultiple
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Box,
  Typography
} from '@mui/material'
import { toast } from 'react-toastify'
import { 
  getStore,
  resetStore
} from '../../../features/stores/storeSlice'
import { 
  resetStoreListing, 
  deleteStoreListing,
} from '../../../features/storeListings/storeListingSlice'
import { 
  getStoreWithListings,
  resetStoresWithListings, 
} from '../../../features/storesWithListings/storesWithListingsSlice'
import AddIcon from '@mui/icons-material/Add'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import { 
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { getReadableListings } from '../../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'

function StoreListings() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  
  const storeState = useSelector(state => state.store)  
  const { 
    store,
  } = storeState
  const { 
    isLoading,
    storeWithListings,
    isStoreWithListings,
  } = useSelector(state => state.storesWithListings)
  const storeListingState = useSelector(state => state.storeListing)

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [areListingsSet, setAreListingsSet] = useState(false)
  const [currentStoreListing, setCurrentStoreListing] = useState({})
  const [selectedStoreListings, setSelectedStoreListings] = useState([])
  const [readableStoreListings, setReadableStoreListings] = useState([])

  useEffect(() => {
    dispatch(getStore(id))
    dispatch(getStoreWithListings(id))
  }, [dispatch, id])

  useEffect(() => {
    if (storeState.isError || storeState.isSuccess) {
      dispatch(resetStore())
    }

    if (storeState.isOne) {
      dispatch(resetStore())
    }

    if (storeState.isError) {
      toast.error(storeState.message)
    } else if (storeListingState.isError) {
      toast.error(storeListingState.message)
    } else if (storeListingState.isDeleted) {
      dispatch(getStoreWithListings(id))
      toast.success(storeListingState.message)
    }

    if (storeListingState.isDeleted || storeListingState.isError) {
      dispatch(resetStoreListing())
    } 

    if (isStoreWithListings) {
      dispatch(resetStoresWithListings())
        const {
          doneSetting,
          readableStoreListings,
        } = getReadableListings(storeWithListings)
        setAreListingsSet(doneSetting)
        setReadableStoreListings(readableStoreListings)
    }
  }, [dispatch, storeState, storeListingState, store, id, isStoreWithListings])

  const breadOptions = [
    { text: 'Stores', link: '/admin/stores' },
    { text: Object.keys(store).length && store.name, link: `/admin/stores/${store._id}` },
    { text: 'listings', link: null }
  ]

  const fields = []
  const columns = [
    { field: 'image', headerName: 'Image', sortable: false},
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'prodCategory', headerName: 'Category', sortable: true},
    { field: 'prodParentCategory', headerName: 'Class', sortable: true},
    { field: 'price', headerName: 'Price', sortable: false},
    { field: 'soldOnline', headerName: 'Sold Online', sortable: false},
    { field: 'onlinePrice', headerName: 'Online Price', sortable: false},
    { field: 'stockCount', headerName: 'Stock', sortable: false},
    { field: 'aboutToExpireStockCount', headerName: 'About to Expire', sortable: false},
    { field: 'expiredStockCount', headerName: 'Expired', sortable: false},
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const getCurentStoreListing = (id) => {
    if (readableStoreListings.length) {
      return readableStoreListings.find(listing => listing._id === id)
    }
  }

  const handleCreate = () => {
    navigate(`/admin/stores/${store._id}/listings/create`)
  }
  const handleEdit = (id) => {
    navigate(`/admin/stores/${store._id}/listings/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Listing')
    setCurrentStoreListing(getCurentStoreListing(id))
  }
  const handleBulkDeleteModal = (data) => {
    setOpen(true)
    setIsDelete(false)
    setTitle('Delete Listings')
    setSelectedStoreListings(data)
  }
  const handleDelete = () => {
    dispatch(deleteStoreListing(currentStoreListing._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    // TODO
    handleClose()
  }
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setCurrentStoreListing({})
  }

  return (
    <Page>
      {(isLoading || storeState.isLoading || storeListingState.isLoading) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(store).length ? (
        <FullWidthContainer
          display='flex'
          className='fade-in'
        >
            <HMBreadcrumbs 
              margin='auto auto auto 0'
              options={breadOptions}
            />
          <HMBox 
            float='right'
            display='flex'
          >
            <HMButton 
              type='button'
              bgColor={green}
              isResponsive={true}
              handleClick={handleCreate}
              icon={<AddIcon sx={iconStyle} />} 
              text={<Typography sx={textStyle}>Add Listing</Typography>} 
            />
            <HMButton 
                type='button'
                bgColor={blue}
                isResponsive={true}
                icon={<UploadFileIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Import Listings</Typography>} 
            />
          </HMBox>
        </FullWidthContainer>
      ) : <></>}
      <HMModal 
          open={open} 
          title={title}
          handleClose={handleClose} 
          colors={{lightBlack, lightBlue}}
      >
        <Typography sx={deleteTextStyle}>
          Are you sure your want to delete the {selectedStoreListings.length ? 'listings' : 'listing'} for: 
        </Typography>
        {isDelete ? (
            <Typography sx={deleteItemTextStyle}>
              {currentStoreListing.name} ?
            </Typography>
        ) : !selectedStoreListings.length ? (
            <Typography sx={deleteItemTextStyle}>
              No listing selected. Select listings and try again!
            </Typography>
        ) : selectedStoreListings.length > 10 ? (
            <Typography sx={deleteItemTextStyle}>
              {selectedStoreListings.length} listings?
            </Typography>
        ) : (
            <Box sx={{marginBottom: '20px'}}>
              {selectedStoreListings.map((listing, index) => (
                  <Typography 
                    sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                    key={index}
                  >
                    {listing.name}
                  </Typography>
              ))}
            </Box>
        )}
        {(selectedStoreListings.length || isDelete) && (
          <HMButton 
            type='button'
            float='right'
            margin='auto'
            bgColor={red}
            handleClick={isDelete ? handleDelete : handleBulkDelete}
            text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
          />
        )}
        <HMButton 
          type='button'
          margin='auto'
          bgColor={gray}
          handleClick={handleClose}
          float={(selectedStoreListings.length || isDelete) && 'left'}
          text={<Typography sx={textStyle}>No, Cancel!</Typography>}
        />
      </HMModal>
      {areListingsSet ?  (
          <DataTable
            fields={fields}
            addStyle={true}
            columns={columns}
            defaultRowsNum={20}
            title='Store Listings'
            handleEdit={handleEdit}
            data={readableStoreListings}
            handleDelete={handleDeleteModal}
            emptyDataMessage='No Listings available!'
            handleBulkDelete={handleBulkDeleteModal}
          />
      ) : <></>}
    </Page>
  )
}

export default StoreListings
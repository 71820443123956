import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Outlet, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
import PrivacyTipTwoToneIcon from '@mui/icons-material/PrivacyTipTwoTone'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined'
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined'
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import AccessibleForwardOutlinedIcon from '@mui/icons-material/AccessibleForwardOutlined'
import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone'
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import {
    resetLog,
    getMyLatestLog,
} from '../../../features/log/logSlice'
import {
    resetPOS,
    setPOSPayMethod,
    getPOSSalesByStore,
} from '../../../features/pos/posSlice'
import { 
    resetOrder,
    getStoreOrders,
    updateStoreOrder,
    getTodayStoreOrders
} from '../../../features/orders/orderSlice'
import { 
    resetStore,
    getMyStores,
    setStoreSales
} from '../../../features/stores/storeSlice'
import { 
    resetSearch,
    setSearchText,
    searchProductsInStore,
} from '../../../features/search/searchSlice'
import { 
    resetCategory,
    getPublicCategories
} from '../../../features/categories/categorySlice'
import { 
    getPublicStore,
    getAllPublicStores,
    resetStoresWithListings,
} from '../../../features/storesWithListings/storesWithListingsSlice'
import { 
    getAllPublicProducts,
    resetProductsWithStoreListings,
} from '../../../features/productsWithStoreListings/productsWithStoreListingsSlice'
import SidebarPOSItem from '../../../components/pos/SidebarPOSItem'
import PaymentOptionCard from '../../../components/pos/PaymentOptionCard'
import HMUserNavigation from '../../../components/common/navigation/HMUserNavigation'
import HMScrollTop from '../../../components/common/navigation/HMScrollTop'
import SidebarItem from '../../../components/common/navigation/SidebarItem'
import HMAppBar from '../../../components/common/navigation/HMAppBar'
import SideBar from '../../../components/common/navigation/SideBar'
import HMSearchField from '../../../components/common/tools/HMSearchField'
import HMContainer from '../../../components/common/layout/HMContainer'
import HMMenuIcon from '../../../components/common/tools/HMMenuIcon'
import HMSocials from '../../../components/common/tools/HMSocials' 
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMButton from '../../../components/common/tools/HMButton'
import HMModal from '../../../components/common/layout/HMModal' 
import HMImage from '../../../components/common/tools/HMImage'
import HMLogo from '../../../components/common/tools/HMLogo'
import HMText from '../../../components/common/tools/HMText'
import HMBox from '../../../components/common/layout/HMBox' 
import order_notification from '../../../assests/audio/order_notification.wav'
import airtel from '../../../assests/airtel.png'
import mtn from '../../../assests/mtn.png'
import {
    fontSize,
    textStyle, 
    iconStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    playNewOrderSound
} from '../../../hooks/helperFunctions'
import {
    red,
    blue,
    white,
    green,
    orange,
    darkBlue,
    lightBlue,
    lightGray,
    strongRed,
    lightBlack,
    lightGreen,
    skeletonsLightBlue,
} from '../../../hooks/useColors'

function Home({ socket }) {
    const audioRef = useRef()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const { play, pause } = useAudioPlayer()

    const path = window.location.pathname.toLowerCase()

    const { 
        myLog,
        isMyLog,
    } = useSelector((state) => state.log)
    const { 
        posPayMethod,
        storePOSSales,
        areStorePOSSales 
    } = useSelector((state) => state.pos)
    const {
        visible,
        sidebarWidth
    } = useSelector((state) => state.nav)
    const { 
        user 
    } = useSelector((state) => state.auth)
    const { 
        myStore,
        myStores,
        isMyStore,
        areMyStores
    } = useSelector((state) => state.store)
    const {
        storeOrders,
        areStoreOrders,
        todayStoreOrders,
        areTodayStoreOrders,
        isStoreOrderUpdated
    } = useSelector((state) => state.order)
    const { 
        searchText,
        searchLoading,
        areFoundProductsInStore,
    } = useSelector((state) => state.search)
    const { 
        isPublicSuccess,
        publicCategories,
    } = useSelector((state) => state.category)
    const { 
        isPublicStore,
    } = useSelector((state) => state.storesWithListings)

    const [limit, setLimit] = useState(10)
    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const [fixed, setFixed] = useState(false)
    const [pageName, setPageName] = useState('')
    const [newOrder, setNewOrder] = useState({})
    const [newPOSSale, setNewPOSSale] = useState({})
    const [isNewOrder, setIsNewOrder] = useState(false)
    const [activeColor, setActiveColor] = useState(null)
    const [currentStorePOSSales, setCurrentStorePOSSales] = useState([])
    const [newOrderNotifications, setNewOrderNotifications] = useState(0)
    const [currentStoreOnlineSales, setCurrentStoreOnlineSales] = useState([])
    
    const {
        windowH,
        windowW
    } = useWindowDimensions()

    useEffect(() => {
        socket.on('new_order', (data) => {
            setNewOrder(data)
            setIsNewOrder(true)
        })
        socket.on('new_pos_sale', (data) => {
            setNewPOSSale(data)
        })
    }, [socket])

    useEffect(() => {
        if (
            Object.keys(myStore).length && (
                (Object.keys(newPOSSale).length && newPOSSale.store === myStore._id) ||
                (isNewOrder && newOrder.orderDetails.find(detail => detail.store === myStore._id)) 
            )
        ) {
            setIsNewOrder(false)
            dispatch(getPublicStore(myStore._id))
            dispatch(getTodayStoreOrders(myStore._id))
        }
        // eslint-disable-next-line
    }, [newPOSSale, isNewOrder])

    useEffect(() => {
        if (Object.keys(myStore).length) {
            dispatch(getPublicStore(myStore._id))
            dispatch(getTodayStoreOrders(myStore._id))
        }
        // eslint-disable-next-line
    }, [myStore])

    useEffect(() => {
        if (Object.keys(myStore).length) { 
            dispatch(getPublicStore(myStore._id))
            dispatch(getStoreOrders(myStore._id))
            dispatch(getPOSSalesByStore(myStore._id))
            dispatch(getTodayStoreOrders(myStore._id))
        }
        // eslint-disable-next-line
    }, [isStoreOrderUpdated])

    useEffect(() => {
        if (areTodayStoreOrders) {
            setNewPOSSale({})
            dispatch(resetOrder())
            if (todayStoreOrders.length) {
                let counter = 0
                todayStoreOrders.forEach(order => {
                    const status = order.status.toLowerCase()
                    if (status === 'pending' || status === 'processing' ) {
                        counter++
                    } 
                })
    
                if (counter > newOrderNotifications && Object.keys(newOrder).length) {
                    if (myLog.status === 'working') {
                        setOpen(true)
                        playNewOrderSound()
                        setTitle('New Order')
                    }
                }
    
                setNewOrderNotifications(counter)
            }
        } 
        // eslint-disable-next-line
    }, [areTodayStoreOrders])
    
    useEffect(() => {
        if (isMyLog) {
            dispatch(resetLog())
        }

        if (areStoreOrders) {
            dispatch(resetOrder())
            if (storeOrders.length) {
                const tempOnlineSales = []
                storeOrders.forEach(order => {
                    const storeData = order.orderDetails[0] 
                    const subtotal = typeof storeData.subtotal === 'number' ? storeData.subtotal : 0
                    tempOnlineSales.push({
                        type: 'online',
                        id: order._id,
                        operator: null,
                        customer: order.user,
                        orderTotal: subtotal,
                        satus: storeData.satus,
                        tax: (storeData.tax || 0),
                        createdAt: order.createdAt,
                        items: storeData.orderItems,
                        saleNumber: order.orderNumber,
                        paymentInfo: order.paymentInfo,
                        discount: (order.discount || 0),
                        insuranceInfo: order.insuranceInfo,
                        copaymentInfo: order.copaymentInfo,
                        approvedInsurance: storeData.approvedInsurance
                    })
                })
                setCurrentStoreOnlineSales(tempOnlineSales)
            }
        }
        
        if (areStorePOSSales) {
            dispatch(resetPOS())
            if (storePOSSales.length) {
                const tempPOSSales = []
                storePOSSales.forEach(sale => {
                    tempPOSSales.push({
                        type: 'pos',
                        id: sale._id,
                        tax: sale.tax,
                        items: sale.items,
                        satus: sale.status,
                        approvedInsurance: null,
                        customer: sale.customer,
                        discount: sale.discount,
                        operator: sale.operator,
                        createdAt: sale.createdAt,
                        orderTotal: sale.subtotal,
                        saleNumber: sale.saleNumber,
                        paymentInfo: sale.paymentInfo,
                        insuranceInfo: sale.insuranceInfo,
                        copaymentInfo: sale.copaymentInfo,
                    })
                })
                setCurrentStorePOSSales(tempPOSSales)
            }
        }
        // eslint-disable-next-line
    }, [areStoreOrders, areStorePOSSales, isMyLog])

    useEffect(() => {
        dispatch(setStoreSales({
            storeId: myStore._id,
            sales: [ ...currentStorePOSSales, ...currentStoreOnlineSales ],
        }))
        
        // eslint-disable-next-line
    }, [currentStorePOSSales, currentStoreOnlineSales])

    useEffect(() => {
        if (searchText.length && Object.keys(myStore).length) {
            const storeId = myStore._id
            dispatch(searchProductsInStore({searchText, storeId, limit}))
        }
        // eslint-disable-next-line
    }, [searchText])

    useEffect(() => {
        dispatch(getPublicCategories())
        if (user && Object.keys(user).length) {
            dispatch(getMyStores())
            dispatch(getMyLatestLog())
        }
        
        if (path === '/store-pos') {
            navigate('/store')
        }

        if (path.includes('landing')) {
            setPageName('landing')
        } else if (path.includes('online')) {
            setPageName('online')
        } else if (path.includes('wallet')) {
            setPageName('wallet')
        } else if (path.includes('history')) {
            setPageName('history')
        } else if (path.includes('settings')) {
            setPageName('settings')
        }
        // eslint-disable-next-line
    }, [user, path])

    useEffect(() => {
        
        // eslint-disable-next-line
    }, [pageName])

    useEffect(() => {
        if (areMyStores) {
            dispatch(resetStore())
            if(!myStores.length) {
              navigate('/store-application')
            }
        } else if (isMyStore) {
            dispatch(resetStore())
            dispatch(getStoreOrders(myStore._id))
            dispatch(getPOSSalesByStore(myStore._id))
        }

        if (isPublicSuccess) {
            dispatch(resetCategory())
        }

        if (areFoundProductsInStore) {
            dispatch(resetSearch())
        }

        if (isPublicStore) {
            dispatch(resetStoresWithListings())
        }
        // eslint-disable-next-line
    }, [isMyStore, areMyStores, isPublicStore, isPublicSuccess, areFoundProductsInStore])

    const isTabletScreen = windowW < 768 ? true : false
    const isSideBarHeight = windowH < 610 ? true : false

    const marginLeft = isTabletScreen ? 0 : sidebarWidth
    const width = isTabletScreen ? '100vw' : `${windowW - sidebarWidth - 12}px` 
    let headerWidth = '100vw'
    let hearderMarginLeft = 0
    let headerMarginRight = 0
    let className = 'fixed'
    if (isTabletScreen) {
        if (visible) {
            headerWidth = fixed ? '100vw' : `${windowW - 22}px` 
            hearderMarginLeft = fixed ? `${marginLeft}px` : `${marginLeft + 10}px`
            headerMarginRight = fixed ? '0px' : '10px'
            className = fixed ? 'fixed hide-header' : ''
        } else {
            headerWidth = fixed ? '100vw' : `${windowW - 22}px` 
            hearderMarginLeft = fixed ? `${marginLeft}px` : `${marginLeft + 10}px`
            headerMarginRight = fixed ? '0px' : '10px'
            className = fixed ? 'fixed slide-bottom' : ''
        }
    } else {
        if (visible) {
            headerWidth = `${windowW - sidebarWidth - 32}px` 
            hearderMarginLeft = `${marginLeft + 10}px`
            headerMarginRight = '10px'
            className = fixed ? 'fixed' : ''
        } else {
            headerWidth = fixed ? '100vw' : `${windowW - 32}px` 
            hearderMarginLeft = fixed ? `${marginLeft-5}px` : `${marginLeft + 10}px`
            headerMarginRight = fixed ? '0px' : '10px'
            className = fixed ? 'fixed' : ''
        }
    }

    const handleSearchChange = (e) => {
        dispatch(setSearchText(e.target.value))
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault()
    }
    const handleSearchClear = () => {
        dispatch(setSearchText(''))
    }

    const fixHeader = () => {
        if (window.scrollY >= 15) {
          setFixed(true)
        } else {
          setFixed(false)
        }
    }

    window.addEventListener('scroll', fixHeader)

    const stylingIcon = {
        margin: 'auto',
        color: 'inherit',
        fontSize: `${fontSize + 20}px`,
        '&:hover': {
            opacity: 1,
        }
    }
    
    const navigateUser = (e, target) => {
        navigate(`/store-pos/${myStore._id}/${target}`)
        // if (activeColor) {
        //     activeColor.color = lightBlack  
        //     activeColor.background = white  
        // }
        setPageName(target)
        // setActiveColor(e.currentTarget.style)
        // e.currentTarget.style.color = white
        // e.currentTarget.style.background = blue
    }

    const navigateToStore = () => {
        navigate(`/store/${myStore._id}/dashboard`) 
    }

    const assignPaymentMethod = (method) => {
        dispatch(setPOSPayMethod(method))
    }

    const handleCancelOrder = () => {
        const activityStack = {
            status: 'Refused',
            updatedBy: user._id,
            updatedAt: new Date(),
        }
        const obj = {
            storeId: myStore._id,
            orderId: newOrder._id,
            updatedOrder: {
                ...newOrder,
                orderDetails: newOrder.orderDetails.map(order => {
                    if (order.store === myStore._id) {
                        return {
                            ...order,
                            activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
                        }
                    } else {
                        return order
                    }
                 }),
            }
        }
        dispatch(updateStoreOrder(obj))
        handleClose()
    }
    const handleAcceptOrder = () => {
        const activityStack = {
            updatedBy: user._id,
            status: 'Processing',
            updatedAt: new Date(),
        }
        const obj = {
            storeId: myStore._id,
            orderId: newOrder._id,
            updatedOrder: {
                ...newOrder,
                orderDetails: newOrder.orderDetails.map(order => {
                    if (order.store === myStore._id) {
                        return {
                            ...order,
                            status: 'Processing',
                            activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
                        }
                    } else {
                        return order
                    }
                 }),
                orderActivityStack: [
                    ...newOrder.orderActivityStack,
                    {
                        state: 'at_store',
                        updatedBy: user._id,
                        updatedAt: Date.now(),
                    }
                ]
            }
        }
        
        dispatch(updateStoreOrder(obj))
        // TO DO 
        // Send a socket confirming order is being proccessed 
        // This can be sent from Database and act on it accordingly
        navigate(`/store-pos/${myStore._id}/online/${newOrder._id}`) 
        handleClose()
    }
    const handleClose = () => {
        
        setOpen(false)
    }

    return (
        <>
            <HMModal 
                open={open} 
                title={title}
                maxHeight='95vh'
                handleClose={handleClose} 
                width={isTabletScreen ? '90vw' : 'auto'}
                maxWidth={isTabletScreen ? '95vw' : '600px'}
            >
                <HMBox
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    minWidth={!isTabletScreen && '500px'}
                >

                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        margin='15px auto 0 auto'
                        className='change-store-button fade-in'
                    >
                        <HMButton 
                            type='button'
                            width='100px'
                            bgColor={red}
                            color={white}
                            fontWeight={500}
                            margin='auto 10px auto 0'
                            handleClick={handleCancelOrder}
                            text={<Typography sx={textStyle}>Decline!</Typography>}
                        />
                        <HMButton 
                            type='button'
                            width='100px'
                            color={white}
                            bgColor={green} 
                            margin='auto 0 auto auto'
                            disabled={false}
                            handleClick={handleAcceptOrder}
                            text={<Typography sx={textStyle}>Accept</Typography>}
                        />
                    </HMBox>
                </HMBox>
            </HMModal>
            <HMAppBar
                height={60}
                zIndex={99}
                width={headerWidth}
                className={className}
                elevation={fixed ? 3 : 0}
                marginLeft={hearderMarginLeft}
                marginRight={headerMarginRight}
            >
                <Grid container spacing={0} sx={{width: '100%'}}>
                    <Grid 
                        item 
                        sx={{display: 'flex'}}
                        xs={((!visible && !isTabletScreen) || !path.includes('landing')) ? 5 : 1} 
                    >
                        <HMMenuIcon 
                            fontSize='34px'
                            margin='8px 5px'
                            activeFontSize='33px'
                        />
                        {((!visible && !isTabletScreen) || !path.includes('landing')) ? (
                            <HMText
                                cursor='pointer'
                                color={darkBlue}
                                fontWeight={700}
                                textAlign='left'
                                width='max-content'
                                text={myStore.name}
                                hoverColor={orange}
                                margin='auto auto auto 10px'
                                handleClick={navigateToStore}
                                fontSize={`${fontSize + 4}px`}
                            />
                        ) : <></>}
                    </Grid>
                    <Grid 
                        item 
                        sx={{display: 'flex'}}
                        xs={
                            ((!visible && !isTabletScreen) || !path.includes('landing')) ? 5
                            : ((visible || isTabletScreen) && user) ? 9
                            : ((!visible && !isTabletScreen) && !user) ? 5
                            : ((visible || isTabletScreen) && !user) && 7
                        } 
                    >
                        {path.includes('landing') ? (
                            <HMSearchField 
                                width='100%'
                                boxPadding='0 8px'
                                className='landing'
                                borderRadius='10px'
                                bgColor={lightBlue}
                                searchText={searchText}
                                title='for products...'
                                searching={searchLoading}
                                margin='4px 20px !important'
                                handleClick={handleSearchClear}
                                handleChange={handleSearchChange}
                                handleSubmit={handleSearchSubmit}
                                closeMargin={isTabletScreen && 'auto auto auto 7px'}
                            />
                        ) : <></>}
                    </Grid>
                    <Grid 
                        item 
                        xs={
                            user ? 2 : 4
                        } 
                        sx={{display: 'flex'}}
                    >
                        <HMBox
                            padding='0'
                            display='flex'
                            margin='0 10px 0 auto'
                        >
                            {user ? (
                                <HMUserNavigation 
                                    top='75px'
                                    width='50px'
                                    height='50px'
                                    fontSize='47px'
                                />
                            ) : (
                                <>
                                    <HMButton 
                                        text='Login'
                                        color={white}
                                        bgColor={blue}
                                        border={`1px solid ${blue}`}
                                        handleClick={() => navigate('/login')}
                                        margin={isTabletScreen ? 'auto 2.5px auto auto' : 'auto 5px auto auto'}
                                    />
                                    <HMButton 
                                        text='Sign up'
                                        color={orange}
                                        bgColor={white}
                                        border={`1px solid ${orange}`}
                                        handleClick={() => navigate('/register')}
                                        margin={isTabletScreen ? 'auto auto auto 2.5px' : 'auto auto auto 5px'}
                                    />
                                </>
                            )}
                        </HMBox>
                    </Grid>
                </Grid>
            </HMAppBar>
            <SideBar
                isOtherLogo={true}
                logo={Object.keys(myStore).length ? (
                    <Link 
                        style={{ margin: '10px auto' }}
                        to={myStores.length > 1 ? '/store' : `/store/${myStore._id}/dashboard`} 
                    >
                        <h2 style={{ marigin: 'auto' }}>
                            <span style={{color: blue}}>My</span>
                            <span style={{color: orange}}>{myStores.length > 1 ? 'Stores' : 'Store'}</span>
                        </h2>
                    </Link>
                ) : <></>}
            >
                <HMBox 
                    width='100%'
                    color={lightBlack}
                    padding={isTabletScreen ? '0 4.5%' : '0 10%'}
                >
                    <SidebarPOSItem 
                        text='Home'
                        name='Home'
                        handleClick={(e) => navigateUser(e, 'landing')}
                        icon={<CottageOutlinedIcon sx={stylingIcon} />}
                        color={pageName === 'landing' ? white : 'inherit'}
                        bgColor={pageName === 'landing' ? blue : 'inherit'}
                    />
                    <SidebarPOSItem 
                        text='Online'
                        name='Online'
                        showCounter={true}
                        counter={newOrderNotifications}
                        handleClick={(e) => navigateUser(e, 'online')}
                        color={pageName === 'online' ? white : 'inherit'}
                        bgColor={pageName === 'online' ? blue : 'inherit'}
                        icon={<ShoppingCartCheckoutOutlinedIcon sx={stylingIcon} />}
                    />
                    <SidebarPOSItem 
                        text='Wallet'
                        name='Wallet'
                        handleClick={(e) => navigateUser(e, 'wallet')}
                        color={pageName === 'wallet' ? white : 'inherit'}
                        bgColor={pageName === 'wallet' ? blue : 'inherit'}
                        icon={<AccountBalanceWalletOutlinedIcon sx={stylingIcon} />}
                    />
                    <SidebarPOSItem 
                        text='History'
                        name='History'
                        handleClick={(e) => navigateUser(e, 'history')}
                        color={pageName === 'history' ? white : 'inherit'}
                        bgColor={pageName === 'history' ? blue : 'inherit'}
                        icon={<ManageHistoryOutlinedIcon sx={stylingIcon} />}
                    />
                    <SidebarPOSItem 
                        text='Settings'
                        name='Settings'
                        handleClick={(e) => navigateUser(e, 'settings')}
                        color={pageName === 'settings' ? white : 'inherit'}
                        bgColor={pageName === 'settings' ? blue : 'inherit'}
                        icon={<SettingsSuggestOutlinedIcon sx={stylingIcon} />}
                    />
                </HMBox>
                <HMSocials 
                    bottom={!isSideBarHeight && 0}
                    position={!isSideBarHeight && 'absolute'}
                />
            </SideBar>
            <HMContainer
                width={width}
                marginTop={fixed && '70px'}
                marginLeft={`${marginLeft}px`}
                className={`customer page ${fixed && 'fixed'} fade-in`}
            >
                <Outlet />
                <HMScrollTop />
            </HMContainer>
            {path.includes('landing') ? (
                <Box
                    sx={{
                        bottom: 0,
                        position: 'fixed',
                        width: headerWidth,
                        marginLeft: hearderMarginLeft,
                        backgroundColor: 'transparent',
                        marginRight: headerMarginRight,
                    }}   
                >
                    <Grid container spacing={2} >
                        <Grid 
                            item 
                            xs={12} 
                            sm={8} 
                            sx={{ display: 'flex', flexDirection: 'column', color: 'red', position: 'relative' }}
                        >
                            <HMBox
                                width='100%'
                                display='flex'
                                margin='auto auto 10px auto'
                            >
                                <PaymentOptionCard 
                                    text='Cash'
                                    name='Cash'
                                    margin='auto 5px auto auto'
                                    handleClick={() => assignPaymentMethod('Cash')}
                                    color={posPayMethod === 'Cash' ? white : lightBlack}
                                    bgColor={posPayMethod === 'Cash' ? blue : lightBlue}
                                    icon={<MoneyOutlinedIcon sx={{...stylingIcon, margin: 'auto auto 0 auto'}} />}
                                />
                                <PaymentOptionCard 
                                    name='MTN'
                                    text='MTN MoMo'
                                    margin='auto 5px'
                                    hoverColor={darkBlue}
                                    hoverBgColor='#ffc907cf'
                                    handleClick={() => assignPaymentMethod('MTN')}
                                    color={posPayMethod === 'MTN' ? darkBlue : lightBlack}
                                    bgColor={posPayMethod === 'MTN' ? '#ffc907cf' : lightBlue}
                                    icon={
                                        <img 
                                            alt='mtn'
                                            src={mtn}
                                            style={{
                                                width: '40px',
                                                height: '20px',
                                                margin: '7px auto',
                                                borderRadius: '8px',
                                            }}
                                        />
                                    }
                                />
                                <PaymentOptionCard 
                                    name='Airtel'
                                    margin='auto 5px'
                                    text='Airtel MoMo'
                                    hoverBgColor='#ff1f1fed'
                                    handleClick={() => assignPaymentMethod('Airtel')}
                                    color={posPayMethod === 'Airtel' ? white : lightBlack}
                                    bgColor={posPayMethod === 'Airtel' ? '#ff1f1fed' : lightBlue}
                                    icon={
                                        <img 
                                            alt='airtel'
                                            src={airtel}
                                            style={{
                                                width: '50px',
                                                height: '20px',
                                                margin: '7px auto',
                                                borderRadius: '8px',
                                            }}
                                        />
                                    }
                                />
                                <PaymentOptionCard 
                                    text='Insurance'
                                    name='Insurance'
                                    margin='auto 5px'
                                    handleClick={() => assignPaymentMethod('Insurance')}
                                    color={posPayMethod === 'Insurance' ? white : lightBlack}
                                    bgColor={posPayMethod === 'Insurance' ? blue : lightBlue}
                                    icon={<AccessibleForwardOutlinedIcon sx={{...stylingIcon, margin: 'auto auto 0 auto'}} />}
                                />
                                <PaymentOptionCard 
                                    text='Card'
                                    name='Card'
                                    margin='auto 5px'
                                    handleClick={() => assignPaymentMethod('Card')}
                                    color={posPayMethod === 'Card' ? white : lightBlack}
                                    bgColor={posPayMethod === 'Card' ? blue : lightBlue}
                                    icon={<CreditScoreOutlinedIcon sx={{...stylingIcon, margin: 'auto auto 0 auto'}} />}
                                />
                                <PaymentOptionCard 
                                    text='Credit'
                                    name='Credit'
                                    margin='auto auto auto 5px'
                                    handleClick={() => assignPaymentMethod('Credit')}
                                    color={posPayMethod === 'Credit' ? white : lightBlack}
                                    bgColor={posPayMethod === 'Credit' ? blue : lightBlue}
                                    icon={<CreditCardOffOutlinedIcon sx={{...stylingIcon, margin: 'auto auto 0 auto'}} />}
                                />
                            </HMBox>
                        </Grid>
                        <Grid 
                            item 
                            sm={4}
                            xs={12} 
                        >
                        </Grid>  
                    </Grid>  
                </Box>
            ) : <></>}  
            <div style={{ display: 'none' }}>
                <audio ref={audioRef}>
                    <source src={order_notification} type='audio/mp3' />
                </audio>
            </div>      
        </>
    )
}

export default Home
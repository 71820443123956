import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import HMBox from './HMBox'
import {
  fontSize
} from '../tools/Styles'
import {
  orange,
  white,
  lightOrange,
} from '../../../hooks/useColors'

function HMSlider(props) {
  const sliderRef = useRef()
  const [isScrollableLeft, setIsScrollableLeft] = useState(false)
  const [isScrollableRight, setIsScrollableRight] = useState(true)

  const iconStyle = {
    opacity: 0.5,
    zIndex: 999,
    cursor: 'pointer',
    borderRadius: '25px',
    color: props.iconColor,
    fontSize: `${fontSize + 18}px`,
    '&:hover': {
      opacity: 1,
    }
  }


  const slideLeft = () => {
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft - 400

    if (sliderRef.current.scrollLeft === 0 || sliderRef.current.scrollLeft < 400) {
      setIsScrollableLeft(false)
    } else {
      setIsScrollableLeft(true)
    }

    if (sliderRef.current.scrollLeft > 400) {
      setIsScrollableRight(false)
    } else {
      setIsScrollableRight(true)
    }
  }
  const slideRight = () => {
    sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + 400

    if (sliderRef.current.scrollLeft === 0 || sliderRef.current.scrollLeft < 400) {
      setIsScrollableLeft(false)
    } else {
      setIsScrollableLeft(true)
    }
    
    if (sliderRef.current.scrollLeft > 400) {
      setIsScrollableRight(false)
    } else {
      setIsScrollableRight(true)
    }
  }
  
  return (
    <HMBox
      padding='0'
      width='100%'
      display='flex'
      margin='0 0 10px 0'
      className='HM-slider'
      height={props.height}
      bgColor={props.bgColor}
    >
      <ArrowLeftIcon 
        onClick={slideLeft} 
        sx={{
          ...iconStyle, 
          margin: 'auto -15px auto -10px',
          opacity: isScrollableLeft ? 0.8 : 0.5,
          color: isScrollableLeft ? white : orange,
          display: props.hideIcons ? 'none' : 'block', 
          backgroundColor: isScrollableLeft ? orange : lightOrange,
        }} 
      />
      <Box
        width='100%'
        ref={sliderRef}
        className='slider'
        sx={{ padding: props.hideIcons ? 'auto' : '5px 10px'}}
      >
        {props.children}
      </Box>
      <ArrowRightIcon 
        onClick={slideRight} 
        sx={{
          ...iconStyle,
          margin: 'auto -10px auto -15px', 
          opacity: isScrollableRight ? 0.8 : 0.5,
          color: isScrollableRight ? white : orange,
          display: props.hideIcons ? 'none' : 'block', 
          backgroundColor: isScrollableRight ? orange : lightOrange,
        }} 
      />
    </HMBox>
  )
}

export default HMSlider
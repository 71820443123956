import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import storeListingService from './storeListingService'

const initialState = {
    message: '',
    isOne: false,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    storeListing: {},
    storeListings: [],
    publicStoreListing: {},
    publicStoreListings: [],
    isPublicStoreListing: false,
    arePublicStoreListings: false,
}

// Get all public storeListings
export const getPublicStoreListings = createAsyncThunk(
    'storeListings/publicAll',
    async (_, thunkAPI) => {
        try {
            return await storeListingService.getPublicStoreListings()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a public storeListing
export const getPublicStoreListing = createAsyncThunk(
    'storeListings/publicOne',
    async (listingId, thunkAPI) => {
        try {
            return await storeListingService.getPublicStoreListing(listingId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all storeListings
export const getStoreListings = createAsyncThunk(
    'storeListings/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeListingService.getStoreListings(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a storeListing
export const getStoreListing = createAsyncThunk(
    'storeListings/one',
    async (listingId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeListingService.getStoreListing(listingId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Create a new storeListing
export const createStoreListing = createAsyncThunk(
    'storeListings/create',
    async (newStoreListing, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeListingService.createStoreListing(newStoreListing, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an storeListing
export const updateStoreListing = createAsyncThunk(
    'storeListings/update',
    async (storeListing, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeListingService.updateStoreListing(storeListing, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an storeListing
export const deleteStoreListing = createAsyncThunk(
    'storeListings/delete',
    async (storeListingID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeListingService.deleteStoreListing(storeListingID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const storeListingSlice = createSlice({
    name: 'storeListing',
    initialState,
    reducers: {
        resetStoreListing: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isPublicStoreListing = false
            state.arePublicStoreListings = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPublicStoreListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.arePublicStoreListings = false
            })
            .addCase(getPublicStoreListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.arePublicStoreListings = true
                state.publicStoreListings = action.payload
            })
            .addCase(getPublicStoreListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.arePublicStoreListings = false
                state.message = action.payload
            })

            .addCase(getPublicStoreListing.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isPublicStoreListing = false
            })
            .addCase(getPublicStoreListing.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isPublicStoreListing = true
                state.publicStoreListing = action.payload
            })
            .addCase(getPublicStoreListing.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isPublicStoreListing = false
                state.message = action.payload
            })

            .addCase(getStoreListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getStoreListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isSuccess = true
                state.storeListings = action.payload
            })
            .addCase(getStoreListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
            })

            .addCase(getStoreListing.pending, (state) => {
                state.isOne = false
                state.isError = false
                state.isLoading = true
            })
            .addCase(getStoreListing.fulfilled, (state, action) => {
                state.isOne = true
                state.isError = false
                state.isLoading = false
                state.storeListing = action.payload
            })
            .addCase(getStoreListing.rejected, (state, action) => {
                state.isOne = false
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(createStoreListing.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createStoreListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.storeListing = action.payload
            })
            .addCase(createStoreListing.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(updateStoreListing.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateStoreListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.storeListing = action.payload
            })
            .addCase(updateStoreListing.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(deleteStoreListing.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteStoreListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteStoreListing.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetStoreListing } = storeListingSlice.actions
export default storeListingSlice.reducer
import React from 'react'
import { Box } from '@mui/material'

function Page(props) {
  
  const gridStyle = {
    flexWrap: 'wrap', 
    margin: props.margin,
    height: props.height,
    width: props.width ||'100%',
    display: props.display || 'flex',
    flexDirection: props.flexDirection,
  }
  return (
    <Box sx={gridStyle} className={`page ${props.className}`}>
        {props.children}
    </Box>
  )
}

export default Page
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { 
    reset,
    otpResend,
    verifyOtp, 
    getOPTExpiration
} from '../../../features/auth/authSlice'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import {
    fontSize,
    textStyle,
} from '../tools/Styles'
import {
    red,
    blue,
    orange, 
    darkBlue,
} from '../../../hooks/useColors'
import { hideEmail } from '../../../hooks/helperFunctions'

function OTPForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { 
        user, 
        isError, 
        message,
        isSuccess, 
        transition,
        isTransition,
    } = useSelector((state) => state.auth)

    const path = window.location.pathname
    const [intervalId, setIntervalId] = useState(null)
    const [otpMinutesTimer, setOTPMinutesTimer] = useState(null)
    const [otpSecondsTimer, setOTPSecondsTimer] = useState(null)
    const [otpCode, setOTPCode] = useState(new Array(4).fill(''))

    const countDown = (startTime) => {
        clearInterval(intervalId)
        let secondsLeft = startTime
        setIntervalId(setInterval(() => {
          secondsLeft--
          if (secondsLeft < 0) {
            clearInterval(intervalId)
            return
          } 
          const minutes = Math.floor(secondsLeft / 60)
          const seconds = secondsLeft % 60
          setOTPMinutesTimer(Math.floor(minutes > 0 ? minutes : 0))
          setOTPSecondsTimer(Math.floor(seconds > 0 ? seconds : 0))
        }, 1000))
    }

    useEffect(() => {
        if ((transition || Object.keys(transition).length)) {
            dispatch(getOPTExpiration({ email: transition.email }))
        }
        // eslint-disable-next-line
    }, [])    
      
    useEffect(() => {
        if (isTransition) {
          const secondsLeft = (transition.expiringTime - new Date().getTime()) / 1000
          countDown(secondsLeft)
          dispatch(reset())
        }
        // eslint-disable-next-line
    }, [isTransition]) 

    useEffect(() => {
        if (isError) {
          toast.error(message)
        }
    
        // Redirect when logged in
        if (isSuccess || user) {
          navigate(
            path.includes('admin') ? '/admin'
            : path.includes('store') ? '/store'
            : path.includes('supplier') ? 'supplier'
            : '/'
          )
        }
    
        dispatch(reset())
        // eslint-disable-next-line
    }, [isError, isSuccess])

    const handleLogin = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(true)
            props.setIsRegister(false)
            props.setIsForgotPassword(false)
        } else {
            navigate(
                path.includes('admin') ? '/admin/login'
                    : path.includes('store') ? '/store/login'
                    : path.includes('supplier') ? 'supplier/login'
                    : '/login'
            )
        }
    }

    const handleRegister = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(false)
            props.setIsRegister(true)
            props.setIsForgotPassword(false)
        } else {
            navigate('/register')
        }
    }

    const onChange = (e, currentIndex) => {
        const value = e.target.value 
        
        if (isNaN(value)) {
          return false
        } else if (parseInt(value) >= 0 && parseInt(value) <= 9) {
          setOTPCode([
            ...otpCode.map((code, index) => index === currentIndex ? value : code)
          ])
        }
    
        if (e.target.nextSibling) {
          e.target.nextSibling.focus()
        }
    }
    
    const handleOTPResend = () => {
        dispatch(otpResend({ email: transition.email }))
    }
    
    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(verifyOtp({ otp_code: parseInt(otpCode.join('')) }))
    }
    
    const isCustomer = (!path.includes('admin') && !path.includes('store') && !path.includes('supplier')) ? true : false

    return (
        <Grid align='center' sx={{ margin: 'auto' }}>
            <HMBox 
                padding='0'
                width='100%'
                display='flex'
                margin='auto auto 10px auto'
            >
                <Divider
                    flexItem
                    orientation='vertical'
                    sx={{
                        mr: 1,
                        height: 70,
                        margin: 'auto 0',
                        background: orange,
                        borderRightWidth: 5,
                        borderRadius: '3px',
                    }}
                />
                <Typography 
                    sx={{ 
                        mb: 2,
                        textAlign: 'left',
                        fontSize: `${fontSize}px`,
                        margin: 'auto 0 auto 10px'
                    }}
                >
                    We just sent your authentication code via email to
                    <Typography
                        component='span'
                        sx={{
                            fontWeight: 600,
                            fontSize: 'inherit', 
                        }}
                    >
                        &nbsp;{hideEmail(transition.email)}.&nbsp;
                    </Typography>
                    Input the code received in the field below.
                </Typography>
            </HMBox>
            <HMBox
                width='100%'
                display='flex'
                margin='auto auto 10px auto'
            >
                {otpMinutesTimer !== null && otpSecondsTimer !== null ? (
                    <Typography
                        sx={{ 
                            mb: 2,
                            margin: 'auto',
                            fontweight: 600,
                            fontSize: `${fontSize + 4}px`,
                            color: otpMinutesTimer > 0 ? darkBlue : red,
                        }}
                    >
                        {
                            otpSecondsTimer === 0 && otpMinutesTimer === 0 
                            ? 'OTP Code has expired!' 
                            : `${otpMinutesTimer.toString().padStart(2, '0')}:${otpSecondsTimer.toString().padStart(2, '0')}`
                        }
                    </Typography>
                ) : <></>}
            </HMBox>
            <form onSubmit={onSubmit}>
                <HMBox
                    width='100%'
                    display='flex'
                    padding='10px 0 20px 0'
                >
                    {otpCode.map((code, index) => (
                        <input
                            key={index}
                            value={code}
                            maxLength='1'
                            className='otp_filed fade-in'
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => onChange(e, index)}
                            style={{
                                width: '50px',
                                fontWeight: 500,
                                textAlign: 'center',
                                borderRadius: '8px',
                                backgroundColor: 'rgb(201 241 255)',
                                fontSize: `${fontSize + 2}px !important`,
                                margin: index === 0 ? 'auto 5px auto auto'
                                : (index === 1 || index === 2) ? 'auto 5px'
                                : 'auto auto auto 5px' 
                            }}
                        />
                    ))}
                </HMBox>
                <Typography 
                    sx={{ 
                        mb: 2,
                        float: 'right',
                        fontSize: `${fontSize}px`
                    }}
                >
                    Didn't receive a code?&nbsp;
                    <Typography
                        component='span'
                        sx={{
                            fontWeight: 500,
                            cursor: 'pointer',
                            fontSize: 'inherit', 
                            '&:hover': {
                                color: orange
                            }
                        }}
                        onClick={handleOTPResend}
                    >
                        Resend
                    </Typography>
                </Typography>
                <HMButton 
                    width='100%'
                    type='submit'
                    text='Submit'
                    bgColor={orange}
                    margin='10px auto'
                    className='fade-in'
                    disabled={otpCode.join('').length !== 4}
                />
            </form>
            <HMBox
                width='100%'
                display='flex'
                className='fade-in'
            >
                <Typography 
                    sx={{
                        ...textStyle,
                        margin: isCustomer ? 'auto 0' : 'auto'
                    }}
                > 
                    <Link 
                        onClick={handleLogin}
                        sx={{
                            color: blue,
                            fontWeight: 500,
                            cursor: 'pointer',
                            textDecoration: 'none'   
                        }}
                    >
                        Login
                    </Link>
                </Typography>
                {isCustomer ? (
                    <Typography 
                        sx={{
                            ...textStyle,
                            margin: 'auto 0 auto auto'
                        }}
                    > 
                        <Link 
                            onClick={handleRegister}
                            sx={{
                                color: blue,
                                fontWeight: 500,
                                cursor: 'pointer',
                                textDecoration: 'none'
                            }}
                        >
                            Register
                        </Link>
                    </Typography>
                ) : <></>}
            </HMBox>
        </Grid>
    )
}

export default OTPForm
import React from 'react'
import Grid from '@mui/material/Grid'
import spinner from '../../../assests/spinner.gif'

function Spinner(props) {
  const gridStyle = {
    zIndex: 5000,
    margin: 'auto',
    marginTop: `${props.marginTop}vh`
  }
  return (
    <Grid sx={gridStyle} className={props.className}>
      <img 
        width={50}
        src={spinner}
        alt='Loading...'
        className='spinner'
      />
    </Grid>
  )
}

export default Spinner
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
import PrivacyTipTwoToneIcon from '@mui/icons-material/PrivacyTipTwoTone'
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone'
import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone'
import {
  getMe,
  resetMe,
} from '../../features/me/meSlice'
import { 
  // hideSidebar 
} from '../../features/nav/navSlice'
import { 
  getCart,
  resetCart,
  setCounter,
  setCartData
} from '../../features/cart/cartSlice'
import { 
  setOtherStores,
  setFavoriteStores,
} from '../../features/stores/storeSlice'
import { 
  resetSearch,
  searchOverallProducts,
} from '../../features/search/searchSlice'
import { 
  resetCategory,
  getPublicCategories
} from '../../features/categories/categorySlice'
import { 
  getAllPublicStores,
  resetStoresWithListings,
} from '../../features/storesWithListings/storesWithListingsSlice'
import { 
  getAllPublicProducts,
  resetProductsWithStoreListings,
} from '../../features/productsWithStoreListings/productsWithStoreListingsSlice'
import HMUserNavigation from '../../components/common/navigation/HMUserNavigation'
import HMScrollTop from '../../components/common/navigation/HMScrollTop'
import SidebarItem from '../../components/common/navigation/SidebarItem'
import HMAppBar from '../../components/common/navigation/HMAppBar'
import SideBar from '../../components/common/navigation/SideBar'
import HMSearchField from '../../components/common/tools/HMSearchField'
import HMContainer from '../../components/common/layout/HMContainer'
import HMMenuIcon from '../../components/common/tools/HMMenuIcon'
import HMSocials from '../../components/common/tools/HMSocials' 
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import HMImage from '../../components/common/tools/HMImage'
import HMLogo from '../../components/common/tools/HMLogo'
import HMText from '../../components/common/tools/HMText'
import WhatsApp from '../../assests/socials/WhatsApp.png'
import HMBox from '../../components/common/layout/HMBox' 

// import HMMap from '../../components/common/layout/HMMap'

import {
  fontSize,
  iconStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  storeDelivery,
  getMatchingCategory,
  getStockAvailability,
} from '../../hooks/helperFunctions'
import {
  blue,
  white,
  green,
  orange,
  lightGray,
  lightBlue,
  lightBlack,
  lightGreen,
} from '../../hooks/useColors'

function Home({ socket }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { 
    me,
    isOne,
  } = useSelector((state) => state.me)
  const {
    visible,
    sidebarWidth
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const { 
    cart,
    isLoading,
    isSuccess,
  } = useSelector((state) => state.cart)
  const { 
    foundOverallProducts,
    areFoundOverallProducts,
  } = useSelector((state) => state.search)
  const { 
    isPublicSuccess,
    publicCategories,
  } = useSelector((state) => state.category)
  const { 
    publicStores,
    arePublicStores,
  } = useSelector((state) => state.storesWithListings)
  const { 
    publicProducts,
    arePublicProducts,
  } = useSelector((state) => state.productsWithStoreListings)

  const [fixed, setFixed] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [activeColor, setActiveColor] = useState(null)
  const [newConnection, setNewConnection] = useState('')
  
  const {
    windowH,
    windowW
  } = useWindowDimensions()

  useEffect(() => {
    socket.on('new_connection', (id) => {
      setNewConnection(id)
    })
  }, [])

  useEffect(() => {
    if (newConnection.length) {
      console.log('New Connection', newConnection)
    }
  }, [newConnection])

  useEffect(() => {
    if (searchText) {
      const limit = 7
      dispatch(searchOverallProducts({searchText, limit}))
    }
    // eslint-disable-next-line
  }, [searchText])

  useEffect(() => {
    // dispatch(hideSidebar())
    dispatch(getAllPublicStores())
    dispatch(getPublicCategories())
    dispatch(getAllPublicProducts())
    if (user && Object.keys(user).length) {
      dispatch(getCart())
      dispatch(getMe(user._id))
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (user && Object.keys(cart).length && publicCategories.length) {
      const storesObj = {}
      let tempCartData = []
      cart.cartItems.forEach(data => {
        const listing = data.storeListing
        const {
          store,
          product,
        } = listing
        const availableStock = getStockAvailability(listing.availableStock, listing.transitStockCount)
        const categoryId = product.category.id
        const tempStockCount = availableStock.stockCount
        const addedInfo = 'addedInfo' in data ? data.addedInfo : {}
        const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[0].type)
        const quantity = tempStockCount > data.quantity ? data.quantity : tempStockCount < 0 ? 0 : tempStockCount
        const categoryInfo = getMatchingCategory(categoryId, publicCategories)
        const classification = categoryInfo.parent.name.toLowerCase() 
        const category = categoryInfo.name.toLowerCase() 
        const categoryParentId = categoryInfo.parent.id

        const cartItem = {
          quantity,
          category,
          categoryId,
          select: true,
          classification,
          categoryParentId,
          addInfoSelect: true,
          product: product._id,
          listing: listing._id,
          productName: product.name,
          productImage: product.image,
          onlinePrice: listing.onlinePrice,
          stockCount: availableStock.stockCount,
          insurancePrice: product.insurancePrice,
          needsPrescription: product.needsPrescription,
        }

        if (addedInfo && Object.keys(addedInfo).length) {
          cartItem['addedInfo'] = addedInfo
        }
        if (storesObj[store._id]) {
          tempCartData = tempCartData.map(obj => {
            if (obj.store === store._id) {
              let tempCartItems = obj.cartItems.map(item => item)
              tempCartItems.push(cartItem)
              return {
                ...obj,
                cartItems: tempCartItems,
                quantity: obj.quantity + quantity,
                subtotal: obj.subtotal + (quantity * listing.onlinePrice),
              }
            } else {
              return obj
            }
          })
        } else {
          storesObj[store._id] = 1
          
          tempCartData.push({
            quantity,
            select: true,
            deliveryOption,
            store: store._id,
            storeName: store.name,
            storeType: store.type,
            cartItems: [ cartItem ],
            storePhone: `0${store.phone[0]}`,
            subtotal: (quantity * listing.onlinePrice),
          })
        }
      })
      dispatch(setCounter(1))
      dispatch(setCartData(tempCartData))
    } 
    
    // eslint-disable-next-line
  }, [cart, publicStores, publicCategories])

  useEffect(() => {
    if (publicStores.length && Object.keys(me).length) {
      let tempOtherStores = []
      const tempFavoriteStores = []
      if ('favoriteStores' in me && me.favoriteStores.length) {
        const favoriteStoreIdsObj = {}
        for (let id of me.favoriteStores) {
          favoriteStoreIdsObj[id.toString()] = 1
        }

        publicStores.forEach(data => {
          if (favoriteStoreIdsObj[data.store._id.toString()]) {
            tempFavoriteStores.push(data)
          } else {
            tempOtherStores.push(data)
          }
        })
      } else {
        tempOtherStores = [ ...publicStores ]
      }

      dispatch(setOtherStores(tempOtherStores))
      dispatch(setFavoriteStores(tempFavoriteStores))
    }

    if (window.location.pathname === '/') {
      navigate('/landing')
    }
    // eslint-disable-next-line
  }, [publicProducts, publicStores, publicCategories, me])

  useEffect(() => {
    if (isOne) {
      dispatch(resetMe())
    }

    if (isSuccess) {
      dispatch(resetCart())
    }

    if (isPublicSuccess) {
      dispatch(resetCategory())
    }

    if (areFoundOverallProducts) {
      dispatch(resetSearch())
    }

    if (arePublicStores) {
      dispatch(resetStoresWithListings())
    }

    if (arePublicProducts) {
      dispatch(resetProductsWithStoreListings())
    }
    // eslint-disable-next-line
  }, [areFoundOverallProducts, arePublicProducts, arePublicStores, isPublicSuccess, isSuccess, isOne])

  const isTabletScreen = windowW < 768 ? true : false
  const isSideBarHeight = windowH < 610 ? true : false

  const marginLeft = isTabletScreen ? 0 : sidebarWidth
  const width = isTabletScreen ? '100vw' : `${windowW - sidebarWidth - 12}px` 
  let headerWidth = '100vw'
  let hearderMarginLeft = 0
  let headerMarginRight = 0
  let className = 'fixed'
  if (isTabletScreen) {
    if (visible) {
      headerWidth = fixed ? '100vw' : `${windowW - 22}px` 
      hearderMarginLeft = fixed ? `${marginLeft}px` : `${marginLeft + 10}px`
      headerMarginRight = fixed ? '0px' : '10px'
      className = fixed ? 'fixed hide-header' : ''
    } else {
      headerWidth = fixed ? '100vw' : `${windowW - 22}px` 
      hearderMarginLeft = fixed ? `${marginLeft}px` : `${marginLeft + 10}px`
      headerMarginRight = fixed ? '0px' : '10px'
      className = fixed ? 'fixed slide-bottom' : ''
    }
  } else {
    if (visible) {
      headerWidth = `${windowW - sidebarWidth - 32}px` 
      hearderMarginLeft = `${marginLeft + 10}px`
      headerMarginRight = '10px'
      className = fixed ? 'fixed' : ''
    } else {
      headerWidth = fixed ? '100vw' : `${windowW - 32}px` 
      hearderMarginLeft = fixed ? `${marginLeft-5}px` : `${marginLeft + 10}px`
      headerMarginRight = fixed ? '0px' : '10px'
      className = fixed ? 'fixed' : ''
    }
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
  }
  const handleSearchSubmit = (e) => {
    e.preventDefault()
  }
  const handleSearchClear = (e) => {
    e.stopPropagation()
    setSearchText('')
  }

  const fixHeader = () => {
    if (window.scrollY >= 15) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }

  window.addEventListener('scroll', fixHeader)

  const stylingIcon = {
    ...iconStyle,
    color: blue,
    marginRight: '15px',
    fontSize: `${fontSize + 8}px`,
  }
 
  const navigateUser = (e, target) => {
    navigate(`/${target}`)
    if (activeColor) {
      activeColor.color = lightBlack  
    }
    setActiveColor(e.target.style)
    e.target.style.color = orange
  }

  return (
    <>
      <HMAppBar
        height={60}
        zIndex={99}
        width={headerWidth}
        className={className}
        elevation={fixed ? 3 : 0}
        marginLeft={hearderMarginLeft}
        marginRight={headerMarginRight}
      >
        <Grid container spacing={0} sx={{width: '100%'}}>
          <Grid 
            item 
            sx={{display: 'flex'}}
            xs={(!visible && !isTabletScreen) ? 3 : 1} 
          >
            <HMMenuIcon 
              fontSize='34px'
              margin='8px 5px'
              activeFontSize='33px'
            />
            {(!visible && !isTabletScreen) ? (
              <HMLogo margin='10px' /> 
            ): <></>}
          </Grid>
          <Grid 
            item 
            sx={{display: 'flex'}}
            xs={
              ((!visible && !isTabletScreen) && user) ? 7
              : ((visible || isTabletScreen) && user) ? 9
              : ((!visible && !isTabletScreen) && !user) ? 5
              : ((visible || isTabletScreen) && !user) && 7
            } 
          >
            <HMSearchField 
              width='100%'
              boxPadding='0 8px'
              borderRadius='8px'
              className='landing'
              bgColor={lightBlue}
              searchText={searchText}
              canSelectOptions={true}
              title='for products...'
              margin='4px 20px !important'
              handleClick={handleSearchClear}
              products={foundOverallProducts}
              handleChange={handleSearchChange}
              handleSubmit={handleSearchSubmit}
              closeMargin={isTabletScreen && 'auto auto auto 7px'}
            />
          </Grid>
          <Grid 
            item 
            xs={
              user ? 2 : 4
            } 
            sx={{display: 'flex'}}
          >
            {user ? (
              <HMBox
                padding='0'
                display='flex'
                margin='0 0 auto auto'
              >
                <IconButton   
                  sx={{margin: 'auto 0 auto auto'}}
                  onClick={() => { navigate('/cart') }} 
                >
                  <AddShoppingCartTwoToneIcon sx={{}} />  
                  <Grid 
                    sx={{ 
                      zIndex: 999,
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      borderRadius: '50%',
                      margin: '0 0 14px -9px',
                      backgroundColor: isLoading ? white : orange, 
                    }}
                  >
                      {!isLoading ? (
                        <Typography 
                          variant='body2' 
                          component='span' 
                          sx={{
                            color: white,
                            margin: 'auto',
                            fontWeight: 400,
                            fontSize: `${fontSize - 5}px`,
                          }}
                        >
                          {Object.keys(cart).length ? cart.cartCount : 0}
                        </Typography>
                      ) : (
                        <HMSpiner 
                          size={15}
                          margin='auto'
                          bgColor='transparent'
                          className='cart-spinner'
                        />
                      )}
                  </Grid>
                </IconButton>
              </HMBox>
            ) : <></>}
            <HMBox
              padding='0'
              display='flex'
              margin='0 10px 0 auto'
            >
              {user ? (
                <HMUserNavigation 
                  top='75px'
                  width='50px'
                  height='50px'
                  fontSize='47px'
                />
              ) : (
                <>
                  <HMButton 
                    text='Login'
                    color={white}
                    bgColor={blue}
                    border={`1px solid ${blue}`}
                    handleClick={() => navigate('/login')}
                    margin={isTabletScreen ? 'auto 2.5px auto auto' : 'auto 5px auto auto'}
                  />
                  <HMButton 
                    text='Sign up'
                    color={orange}
                    bgColor={white}
                    border={`1px solid ${orange}`}
                    handleClick={() => navigate('/register')}
                    margin={isTabletScreen ? 'auto auto auto 2.5px' : 'auto auto auto 5px'}
                  />
                </>
              )}
            </HMBox>
          </Grid>
        </Grid>
      </HMAppBar>
      <SideBar> 
        <HMBox
          width='100%'
          padding={isTabletScreen ? '0 0 0 4.5%' : '0 0 0 10%'}
        >
          <SidebarItem 
            name='Home'
            width='max-content'
            textFontWeight={500}
            textColor={!activeColor && orange}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'landing')} 
            leftIcon={<CottageTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Categories'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'categories')} 
            leftIcon={<CategoryTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Stores'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'stores')} 
            leftIcon={<StoreMallDirectoryTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Orders'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'myorders')} 
            leftIcon={<ListAltTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Cart'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'cart')} 
            leftIcon={<AddShoppingCartTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='About Us'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize + 1}px`}
            navigateUser={(e) => navigateUser(e, 'aboutus')} 
            leftIcon={<InfoTwoToneIcon sx={stylingIcon} />} 
          />
          <br />
          <Divider color={lightGray} width='140px' sx={{ borderBottomWidth: '1px', margin: 'auto' }} />
          <br />
          <SidebarItem 
            name='FAQs'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize}px`}
            navigateUser={(e) => navigateUser(e, 'faqs')} 
            leftIcon={<QuizTwoToneIcon sx={{...stylingIcon, fontSize: `${fontSize + 5}px`}} />} 
          />
          <SidebarItem 
            name='Terms'
            width='max-content'
            textFontWeight={500}
            textFontSize={`${fontSize}px`}
            navigateUser={(e) => navigateUser(e, 'terms-and-conditions')} 
            leftIcon={<GavelTwoToneIcon sx={{...stylingIcon, fontSize: `${fontSize + 5}px`}} />} 
          />
          <SidebarItem 
            width='max-content'
            textFontWeight={500}
            name='Privacy Policy'
            textFontSize={`${fontSize}px`}
            navigateUser={(e) => navigateUser(e, 'privacy-policies')}
            leftIcon={<PrivacyTipTwoToneIcon sx={{...stylingIcon, fontSize: `${fontSize + 5}px`}} />} 
          />
          <br />
          <Divider color={lightGray} width='140px' sx={{ borderBottomWidth: '1px', margin: 'auto' }} />
          <br />
        </HMBox>
        <HMBox
          width='100%'
          padding='0px'
          display='flex'
          flexDirection='column'
        >
          <Link 
            href='#' 
            width='100%'
            target='_blank' 
            sx={{ cursor: 'pointer', margin: '5px auto', display: 'flex' }}
          >
            <HMImage 
              width='auto'
              height='22px'
              src={WhatsApp}
              margin='auto 0'
              borderRadius='4px'
            />
            <HMText 
              width='80%'
              margin='auto'
              color={green}
              fontWeight='500'
              textAlign='center'
              bgColor={lightGreen}
              text='+250 785 187 855'
              padding='auto 10px !important'
            />
          </Link>
          <Link 
            href='#' 
            width='100%'
            target='_blank' 
            sx={{ cursor: 'pointer', margin: '5px auto', display: 'flex' }}
          >
            <EmailOutlinedIcon sx={{ fontSize: `${fontSize + 6}px`, color: orange, margin: 'auto 0' }} />
            <HMText 
              color={blue}
              margin='auto auto auto 5px'
              text='help@harakameds.com'
            />
          </Link>
        </HMBox>
        <HMSocials 
          bottom={!isSideBarHeight && 0}
          position={!isSideBarHeight && 'absolute'}
        />
      </SideBar>
      <HMContainer
        width={width}
        marginTop={fixed && '70px'}
        marginLeft={`${marginLeft}px`}
        className={`customer page ${fixed && 'fixed'} fade-in`}
      >
        {/* <HMMap /> */}
        <Outlet />
        <HMScrollTop />
      </HMContainer>
    </>
  )
  
}

export default Home
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function HMDatePcker({ value, onChange, maxDate, minDate, name }) {
  return (
    <DatePicker
      name={name}
      selected={value}
      showYearDropdown
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      dateFormat='dd/MM/yyyy'
      scrollableMonthYearDropdown
    />
  )
}

export default HMDatePcker
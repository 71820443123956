import React, { useState } from 'react'
import HMBox from '../../components/common/layout/HMBox'
import HMDatePicker from '../../components/common/tools/HMDatePicker'
import HMTextField from '../../components/common/tools/HMTextField'
import HMCheckbox from '../../components/common/tools/HMCheckbox'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import { fontSize } from '../../components/common/tools/Styles'
import { white, lightRed, lightBlue } from '../../hooks/useColors'
import { cloudinary } from '../../hooks/helperFunctions'
import HMDropZone from '../common/tools/HMDropZone'

function FDAStep(props) {
  const {
    issueOn,
    lastName,
    firstName,
    expiresOn,
    pharmacist,
    useSameNameForPharmacist,
  } = props.formData

  return (
    <>
      <HMBox
        padding='0'
        width='100%'
        margin='auto'
        display='flex'
        className='fade-in'
      >
        <HMCheckbox
          margin='auto 5px auto 0'
          checked={useSameNameForPharmacist}
          handleChange={props.handleUseSameNameForPharmacist}
        />
        <HMText
          float='initial'
          margin='auto auto auto 0'
          text={`I use [ ${firstName} ${lastName} ] on my FDA licence`}
        />
      </HMBox>
      {!useSameNameForPharmacist ? (
        <>
          <HMTextField
            required={true}
            className='fade-in'
            name='pharmacistFirstName'
            value={pharmacist.firstName}
            label='Pharmacist First Name'
            onChange={props.handleChange}
            placeholder='Enter pharmacist first name'
          />
          <HMTextField
            required={true}
            className='fade-in'
            name='pharmacistLastName'
            value={pharmacist.lastName}
            label='Pharmacist Last Name'
            onChange={props.handleChange}
            placeholder='Enter pharmacist last name'
          />
        </>
      ) : (
        <></>
      )}
      {!props.isAcceptableLicense &&
      (new Date(issueOn).valueOf() !== Date.now().valueOf() ||
        new Date(expiresOn).valueOf() !== Date.now().valueOf()) ? (
        <HMAlert
          error={true}
          margin='10px auto'
          bgColor={lightRed}
          fontSize={fontSize - 2}
          text='The licence should at least have a validity of 30 days'
        />
      ) : (
        <></>
      )}
      <HMBox
        padding='0'
        width='100%'
        display='flex'
        className='fade-in'
        margin='5px auto 10px auto'
      >
        <HMText width='100px' text='Date issued on' margin='auto 5px auto 0' />
        <HMDatePicker
          value={issueOn}
          maxDate={new Date()}
          margin='auto 0 auto auto'
          onChange={props.handleIssueOnChange}
        />
      </HMBox>
      <HMBox
        padding='0'
        width='100%'
        display='flex'
        className='fade-in'
        margin='5px auto 10px auto'
      >
        <HMText width='100px' text='Expiration Date' margin='auto 5px auto 0' />
        <HMDatePicker
          value={expiresOn}
          minDate={props.minDate}
          margin='auto 0 auto auto'
          onChange={props.handleExpiresOnChange}
        />
      </HMBox>
      <HMBox
        width='100%'
        display='flex'
        padding='10px'
        margin='30px 0px'
        bgColor={lightBlue}
        flexDirection='column'
      >
        <HMText 
          left='0'
          top='-35px'
          height='30px'
          bgColor={white}
          fontWeight={500}
          text='FDA License'
          width='max-content'
          className='fade-in'
          position='relative'
          margin='10px 0 -25px 20px'
          padding='5px 10px 0px 10px'
          border={`1px solid ${lightBlue}`}
        />
        <HMDropZone
          bgColor={white}
          className='drag-drop'
          endPoint={cloudinary.API_URL_FDA}
          cloudinaryFileUrl={props.licenseFileUrl}
          setCloudinaryFileUrl={props.setLicenseFileUrl}
          supportedFiles={{
            'image/jpg': [],
            'image/png': [],
            'image/jpeg': [],
            'application/pdf': [],
          }}
          allowedSizeInMB={1}
        />
      </HMBox>
    </>
  )
}

export default FDAStep

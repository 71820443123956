import React, { useState } from 'react'
import { Box, Divider } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PropTypes from 'prop-types'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Title from '../../components/common/tools/Title'
import { white, lightBlue, blue, black, darkBlue } from '../../hooks/useColors'
import Income from './analytics/Income'
import Users from './analytics/Users'
import Orders from './analytics/Orders'
import Products from './analytics/Products'
import Stores from './analytics/Stores'
import Suppliers from './analytics/Suppliers'
import Revenue from './analytics/Revenue'
import { fontSize } from '../../components/common/tools/Styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function tabIndex(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const Analytics = () => {
  const TAB_LIST = [
    'Revenue',
    'Income',
    'Users',
    'Products',
    'Orders',
    'Stores',
    'Suppliers',
  ]

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const tabStyles = {
    fontSize: { xs: '9px', sm: '14px' },
    background: '#B1E7FB',
    mr: 0.2,
    borderRadius: '20px 20px 0 0',
    color: black
    
  }

  const tabsStyles = { 
    "& .MuiTab-root.Mui-selected": {color: white}, 
    '& .MuiTabs-indicator':{ display: 'none'}, 
    "& .MuiTab-root": {
    color: darkBlue,
    fontSize: fontSize + 3,
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: "600",
    fontFamily: [ 'Roboto',].join(',')}
  }

  const selectedTabStyles = {
    ...tabStyles,
    background:  blue,

   
  }


  return (
    <Page>
      <FullWidthContainer>
        <Box sx={{ background: lightBlue, minHeight: '100vh' }}>
          <Box sx={{ width: '90%', height: '50px', pl: 3, pt: 1 }}>
            <Title title="Analytics" fontWeight='700' size={fontSize + 8}/>
          </Box>

          <Tabs
            value={value}
            onChange={handleChange}
            visibleScrollbar="true"
            textColor="primary"
            variant="fullWidth"
            aria-label="analytics tabs "
            sx={tabsStyles}
          >
            <Tab label={TAB_LIST[0]} {...tabIndex(0)} sx={value === 0? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[1]} {...tabIndex(1)} sx={value === 1? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[2]} {...tabIndex(2)} sx={value === 2? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[3]} {...tabIndex(3)} sx={value === 3? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[4]} {...tabIndex(4)} sx={value === 4? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[5]} {...tabIndex(5)} sx={value === 5? selectedTabStyles: tabStyles} />
            <Tab label={TAB_LIST[6]} {...tabIndex(6)} sx={value === 6? selectedTabStyles: tabStyles} />
          </Tabs>
          <Divider sx={{ border: '2px solid #00AEEF' }} />
          <TabPanel value={value} index={0}>
            <Revenue/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Income/>
          </TabPanel>
          <TabPanel value={value} index={2}>
           <Users/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Products/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Orders/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Stores/>
          </TabPanel>
          <TabPanel value={value} index={6}>
          <Suppliers/>
          </TabPanel>
        </Box>
      </FullWidthContainer>
    </Page>
  )
}

export default Analytics

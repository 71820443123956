
import Box from '@mui/material/Box';
import { fontSize } from '../../../components/common/tools/Styles';
import HMText from '../../../components/common/tools/HMText'
import useColors from '../../../hooks/useColors';
import Section from '../../../components/common/layout/Section';

const { 
    white,
} = useColors

const HMDetailCard = (props) => {
    return (
        <Section
        bgColor={props.detailColor}
        padding={0}>
          <Box display="flex"
          textAlign="center"
          justifyContent="space-between"
          padding={2.6}
          >
            <HMText
              color={white}
              fontSize={`${fontSize - 1}px`}
              text={props.title}
              fontWeight="600"
            />  
            <HMText
              color={white}
              fontSize={`${fontSize + 2}px`}
              text={props.value}
              fontWeight="600"
            />  
          </Box>
      </Section>
  )
}

export default HMDetailCard
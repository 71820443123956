import React from 'react'
import ReactApexChart from 'react-apexcharts'

 const HMLineChart = (props) => {
    const data = [
        {
          name: 'Visits ',
          type: 'line',
          data: [34, 21, 40, 10, 28, 30, 80],
        },
      ]
      const options = {
        chart: {
          height: 200,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        colors: ['#00AEEF'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          show: true,
          borderColor: '#e7e7e7',
          xaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: ['#f9f9f9', 'transparent'],
            opacity: 0.5,
          },
          column: {
            colors: ['#f9f9f9', 'transparent'],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'July',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
      }
    
      return (
        <ReactApexChart
          width="100%"
          height="100%"
          options={options}
          series={data}
        />
      )
  }

  export default HMLineChart;
import React, { useRef, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import HMSearchDropDown from '../layout/HMSearchDropDown'
import HMSpiner from '../tools/HMSpiner'
import {
  searcFieldStyle,
  searchIconStyle,
  searchButtonStyle
} from './Styles'
import {
  red,
  lightBlack,
} from '../../../hooks/useColors'
import HMBox from '../layout/HMBox'

function HMSearchField(props) {
  const ref = useRef(null)
  const boxRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    ['click', 'touchend'].forEach(e => {
      document.addEventListener(e, toggle)
    })
    return () => ['click', 'touchend'].forEach(e => {
      document.removeEventListener(e, toggle)
    })
  }, [])

  const toggle = (e) => {
    setOpen(e && e.target === ref.current)
  }

  const searchPaperStyle = {
    width: props.width || '40vw',
    backgroundColor: props.bgColor,
    margin: props.margin || 'auto',
    borderRadius: props.borderRadius,
    display: props.display || 'flex', 
    marginLeft: props.marginLeft || '0px',
    alignItems: props.alignItems || 'center', 
    flexDirection: props.flexDirection || 'column'
  }
  const iconStyle = {
    fontSize: '12px',
    cursor: 'pointer',
    color: lightBlack,
    marginLeft: '5px',
    marginRight: '-8px',
    display: props.searchText.length ? 'block' : 'none',
    '&:hover': {
      color: red,
    },
    '&:active': {
      fontSize: '8.8px',
    }
  }

  return (
    <Paper
      elevation={0}
      component='form'
      sx={searchPaperStyle} 
      onSubmit={(e) => props.handleSubmit(e)}
      className={`HM-search ${props.className}`}
    >
      <HMBox
        width='100%'
        margin='auto'
        display='flex'
        padding={props.boxPadding || '0'}
      >
        <CloseIcon sx={{...iconStyle, margin: props.closeMargin || 'auto'}} onClick={props.handleClick} />
        <input
          ref={ref}
          value={props.searchText}
          style={{
            ...searcFieldStyle,
            padding: props.canSelectOptions && props.searchText.length ? '0 8px' : '5px 8px 0 8px'
          }}
          placeholder={`Search ${props.title}`}
          onChange={(e) => props.handleChange(e)}
        />
        {props.searching ? (
          <HMSpiner 
            size={20}
            bgColor='transparent'
            className='cart-spinner'
            margin='auto 5px auto auto'
          />
        ) : (
          <IconButton type='submit' sx={searchButtonStyle} aria-label='search'>
            <SearchIcon sx={{...searchIconStyle, margin: 'auto'}} />
          </IconButton>
        )}
      </HMBox>
      {props.canSelectOptions && props.searchText.length ? (
        <HMSearchDropDown 
          open={open}
          boxRef={boxRef}
          products={props.products}
          searchText={props.searchText}
        />
      ) : <></>}
    </Paper>
  )
}

export default HMSearchField
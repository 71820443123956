import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import { toast } from 'react-toastify'
import { 
  createProduct, 
  resetProduct, 
  getProducts 
} from '../../../features/products/productSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import HMProductCreationFileds from '../../../components/common/layout/HMProductCreationFileds'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import Page from '../../../components/common/layout/Page'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  white,
} from '../../../hooks/useColors'

function CreateProduct() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const breadOptions = [
    { text: 'Products', link: '/admin/products' },
    { text: 'Add product', link: null }
  ]

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false
  
  const productState = useSelector((state) => state.product)

  const supportedFiles = {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': []
  }
  const [productImageUrl, setProductImageUrl] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    brand: '',
    image: '',
    dosage: '',
    parent: '',
    category: '',
    strength: '',
    sideEffects: '',
    description: '',
    insurancePrice: '',
  })
  const {
    name,
    image,
    brand,
    dosage,
    parent,
    category,
    strength,
    description,
    sideEffects,
    insurancePrice,
  } = formData

  useEffect(() => {
    if (productState.isError || productState.isCreated) {
      dispatch(resetProduct())
    }
    
    if (productState.isError) {
      toast.error(productState.message) 
    } else if (productState.isCreated) {
      toast.success('Product created successfully')
      dispatch(getProducts())

      Object.keys(productState.product).length && navigate(`/admin/products/${productState.product._id}`)
    }
    // eslint-disable-next-line
  }, [productState])
  
  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      image: productImageUrl,
    }))
    // eslint-disable-next-line
  },[productImageUrl])

  const handleChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    }))
  }
  const handleDrowdownChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      category: val,
      parent: val && val.parent ? val.parent : ''
    }))
  }
  const handleEditorChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      description: content
    }))
  }
  
  const disabled = (name === '' || category === ''|| description === '' || image==='') ? true : false
  const handleCreate = (e) => {
    e.preventDefault()
    const mainCat = parent.toLowerCase()
    const hasTax = mainCat.includes('health') ? true : false
    const needsPrescription = mainCat.includes('prescription') ? true : false
    const product = {
      name,
      image,
      brand,
      hasTax,
      dosage,
      strength,
      sideEffects,
      description,
      needsPrescription,
      category: {
        id: category && category._id
      },
    }

    if (insurancePrice && insurancePrice > 0) {
      product.insurancePrice = insurancePrice
    }

    dispatch(createProduct(product))
  }

  return (
    <Page>
      {productState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
        flexDirection={isTabletScreen ? 'column' : 'row'}
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
          padding={isTabletScreen && '10px 0 0 10px'}
        />
      </FullWidthContainer>
      <Section
        padding={10}
        bgColor={white}
      >
        <HMProductCreationFileds 
          formData={formData}
          disabled={disabled}
          handleChange={handleChange}
          handleSubmit={handleCreate}
          supportedFiles={supportedFiles}
          productImageUrl={productImageUrl}
          setProductImageUrl={setProductImageUrl}
          handleEditorChange={handleEditorChange}
          handleDrowdownChange={handleDrowdownChange}
        />
      </Section>
    </Page>
  )
}

export default CreateProduct
import axios from 'axios'

const API_URL_STORES_RELATIONS_PUBLIC = '/api/v1/stores_with_listings/public'
const API_URL_STORES_RELATIONS_PRIVATE = '/api/v1/stores_with_listings/private'

// Get all stores with store listings for public use
const getPublicStore = async (storeId) => {
    const response = await axios.get(`${API_URL_STORES_RELATIONS_PUBLIC}/${storeId}`)

    const data = response.data

    return data
}

// Get a store with store listings for public use
const getAllPublicStores = async () => {
    const response = await axios.get(API_URL_STORES_RELATIONS_PUBLIC)

    const data = response.data

    return data
}

// Get all stores with store listings
const getStoreWithListings = async (storeId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_STORES_RELATIONS_PRIVATE}/${storeId}`, config)

    const data = response.data

    return data
}

// Get a store with store listings
const getAllStoresWithListings = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_STORES_RELATIONS_PRIVATE, config)

    const data = response.data

    return data
}

const storesWithListingsService = {
    getPublicStore,
    getAllPublicStores,
    getStoreWithListings,
    getAllStoresWithListings,
}
export default storesWithListingsService
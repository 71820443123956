import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {
  resetMe,
  updateMe
} from '../../features/me/meSlice'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMStoreCard from '../../components/common/layout/HMStoreCard'
import HMSpiner from '../../components/common/tools/HMSpiner'
import Section from '../../components/common/layout/Section'
import Page from '../../components/common/layout/Page'
import {
  white,
} from '../../hooks/useColors'

function PublicStores() {
  const dispatch = useDispatch()
  const { 
    me,
    isUpdated,
    isLoading,
  } = useSelector((state) => state.me)
  const { 
    user,
  } = useSelector((state) => state.auth)
  const { 
    otherStores,
    favoriteStores
  } = useSelector((state) => state.store)
  const { 
    publicStores,
  } = useSelector((state) => state.storesWithListings)

  const navigate = useNavigate()

  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Stores', link: null },
  ]

  useEffect(() => {
    if (isUpdated) {
      dispatch(resetMe)
    } 
  }, [dispatch, isUpdated])

  const handleStoreClick = (storeId) => {
    navigate(`/stores/${storeId}`)
  }

  const handleFavorite = (e, storeId) => {
    e.stopPropagation()
    const updatedUser = {}
    const favoriteStoreIds = me.favoriteStores.length ? me.favoriteStores.map(a => a.toString()) : []
    if (favoriteStoreIds.includes(storeId)) {
      const tempFavoriteStores = favoriteStoreIds.filter(id => id !== storeId)
      updatedUser['favoriteStores'] = tempFavoriteStores
    } else {
      favoriteStoreIds.push(storeId)
      updatedUser['favoriteStores'] = favoriteStoreIds
    }
    dispatch(updateMe(updatedUser))
  }

  return (
    <Page>
      {isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer>
        <HMBreadcrumbs options={breadOptions} margin='auto auto auto 0' />
      </FullWidthContainer>
      <Section 
        padding={10} 
        bgColor={white}
        minHeight='80vh'
      >
        <Grid 
          container 
          spacing={2}
        >
          {user && favoriteStores.length ? favoriteStores.map((data, index) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              key={index}
            >
              <HMStoreCard
                isFavorite={true}
                showAddress={true}
                store={data.store}
                handleClick={() => handleStoreClick(data.store._id)}
                handleFavorite={(e) => handleFavorite(e, data.store._id.toString())}
              />
            </Grid>
          )) : <></>}
          {user && otherStores.length ? otherStores.map((data, index) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              key={index}
            >
              <HMStoreCard
                key={index}
                store={data.store}
                isFavorite={false}
                showAddress={true}
                handleClick={() => handleStoreClick(data.store._id)}
                handleFavorite={(e) => handleFavorite(e, data.store._id.toString())}
              />
            </Grid>
          )) : <></>}
          {!user && publicStores.length ? publicStores.map((data, index) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              key={index}
            >
              <HMStoreCard
                key={index}
                store={data.store}
                isFavorite={false}
                showAddress={true}
                handleClick={() => handleStoreClick(data.store._id)}
                handleFavorite={(e) => handleFavorite(e, data.store._id.toString())}
              />
            </Grid>
          )) : <></>}
        </Grid>
      </Section>
    </Page>
  )
}

export default PublicStores

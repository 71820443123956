import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import HMButton from './HMButton'
import {
    fontSize,
    textStyle,
} from './Styles'
import HMBox from '../layout/HMBox'

function HMSwitchButton(props) {
    const width = props.width || 265
    const btnStyle = {
        top: '0',
        height: '100%',
        transition: '.5s',
        position: 'absolute',
        width: `${width / 2}px`,
        background: props.btnBgColor,
        borderRadius: props.borderRadius || '30px',
        left: props.isLeft ? '0' : `${width / 2}px`,
    }
    const btnTxtStyle = {
        ...textStyle, 
        fontWeight: 500,
        fontSize: `${fontSize - 2}px`
    }

    return (
        <HMBox 
            padding='0'
            display='flex'
            position='relative'
            width={`${width}px`}
            margin={props.margin}
            bgColor={props.bgColor}
            minWidth={props.minWidth}
            className='HM-switch-button button-box'
            borderRadius={`${props.borderRadius || 30 }px`}
        >
            <Box style={btnStyle}></Box>
            <HMButton 
                width='50%'
                opacity={1}
                type='button'
                bgColor='transparent'
                borderRightActive='0'
                borderBottomActive='0'
                margin='auto auto auto 0'
                handleClick={props.leftClick}
                borderRadius={props.borderRadius || 30}
                color={props.isLeft ? props.btnActiveColor : props.btnColor}
                text={
                    <Typography 
                        sx={btnTxtStyle}
                    >
                        {props.leftText}
                    </Typography>
                } 
            />
            <HMButton 
                width='50%'
                opacity={1}
                type='button'
                bgColor='transparent'
                borderRightActive='0'
                borderBottomActive='0'
                margin='auto 0 auto auto'
                handleClick={props.rightClick}
                borderRadius={props.borderRadius || 30}
                color={!props.isLeft ? props.btnActiveColor : props.btnColor}
                text={
                    <Typography 
                        sx={btnTxtStyle}
                    >
                        {props.rightText}
                    </Typography>
                } 
            />
        </HMBox>
    )
}

export default HMSwitchButton
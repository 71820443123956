import React from 'react'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import HMToogleButton from '../common/tools/HMToogleButton'
import HMRadioButton from '../common/tools/HMRadioButton'
import HMTextField from '../common/tools/HMTextField'
import HMDropZone from '../common/tools/HMDropZone'
import HMButton from '../common/tools/HMButton'
import HMAlert from '../common/tools/HMAlert'
import HMText from '../common/tools/HMText'
import HMBox from '../common/layout/HMBox'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../common/tools/Styles'
import {
    white,
    green,
    orange,
    darkBlue,
    lightGray,
    lightBlue,
    lightWarning,
} from '../../hooks/useColors'
import { cloudinary } from '../../hooks/helperFunctions'

const insuranceDefault = {
    question: 'Default insurance',
    choices: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ]
}

function HMInsuranceCardForm(props) {
    const {
        save,
        cardImage,
        cardNumber,
        nameOnCard,
    } = props.insurerFormData

    const disabledInsurer = (
        cardImage === '' ||
        cardNumber === '' ||
        nameOnCard === '' ||
        props.cardImageUrl === ''
      ) ? true : false

    return (
        <HMBox
            width='100%'
            display='flex'
            margin='10px 0 0 0'
            flexDirection='column'
            className='save-insurer fade-in'
        >
            <HMAlert 
                warning={true}
                margin='0 0 10px 0'
                bgColor={lightWarning}
                fontSize={fontSize - 2}
                text='Note that your insurance is not applicable until is validated!.'
            />
            <HMText 
                margin='10px 0'
                width='max-content'
                className='fade-in'
                padding='5px 5px 0px 5px'
                text='Upload photo of your card'
            />
            <HMDropZone 
                allowedSizeInMB={1}
                bgColor={lightBlue}
                className='drag-drop' 
                endPoint={cloudinary.API_URL_LOGOS}
                cloudinaryFileUrl={props.cardImageUrl}
                setCloudinaryFileUrl={props.setCardImageUrl}
                supportedFiles = {{
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/png': []
                }}
            />
            <HMTextField 
                type='text'
                required={true}
                name='cardNumber'
                value={cardNumber}
                label='Card number'
                className='fade-in'
                margin='10px auto 5px auto'
                placeholder='Enter card number'
                onChange={props.handleInsurerDataChange}
            />
            <HMTextField 
                type='text'
                required={true}
                name='nameOnCard'
                value={nameOnCard}
                className='fade-in'
                label='Name on card'
                margin='5px auto auto auto'
                onChange={props.handleInsurerDataChange}
                placeholder='Enter name on the card'
            />
            <HMRadioButton 
                padding='5px'
                name='default'
                bgColor='inherit'
                flexDirection='row'
                label={insuranceDefault.question}
                options={insuranceDefault.choices}
                labelId='tax-applicable-radio-group'
                value={props.insurerFormData.default}
                onChange={props.handleInsurerDataChange}
                margin={props.isTabletScreen ? '5px auto auto auto' : '10px auto auto auto'}
            />
            {props.isAddSpecificInsurer ? (
                <HMBox
                    padding='0'
                    width='100%'
                    display='flex'
                    margin='10px auto'
                    className='checkout-insurerance fade-in'
                >
                    <HMText 
                        width='100%'
                        margin='15px 0 10px 0'
                        fontSize={`${fontSize}px`}
                        text='Save insurance information'
                    /> 
                    <HMToogleButton 
                        status={save}
                        margin='auto 0 auto auto'
                        text={save ? 'Yes' : 'No'}
                        handleChange={props.handleSaveInsurer}
                    />
                </HMBox>
            ) : <></>}
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='15px auto 0 auto'
                className='change-store-button'
            >
                <HMButton 
                    type='button'
                    width='100px'
                    fontWeight={500}
                    margin='auto 10px auto 0'
                    handleClick={props.handleBackOrCancel}
                    color={props.backOrCancelText === 'Back' ? white : darkBlue}
                    bgColor={props.backOrCancelText === 'Back' ? orange : lightGray}
                    icon={props.backOrCancelText === 'Back' ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
                    text={
                        <Typography sx={textStyle}>
                            {props.backOrCancelText}
                        </Typography>
                    }
                />
                <HMButton 
                    type='button'
                    width='100px'
                    bgColor={green}
                    margin='auto 0 auto auto'
                    disabled={disabledInsurer}
                    handleClick={props.handleConfirm}
                    text={
                        <Typography sx={textStyle}>
                            Confirm
                        </Typography>
                    }
                />
            </HMBox>
        </HMBox>
    )
}

export default HMInsuranceCardForm
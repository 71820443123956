import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import HMBox from '../layout/HMBox'

function HMPlacesAutocomplete(props) {
    
    return (
        <PlacesAutocomplete
            value={props.value || ''}
            onChange={props.handleChange}
            onSelect={props.handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <HMBox 
                    margin={props.margin}
                    width={props.width || '100%'}
                    padding={props.padding || '0'}
                >
                    <input
                        {...getInputProps({
                            placeholder: 'Search your location ...',
                            className: 'location-search-input',
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item'
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' }
                            return (
                                <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            )
                        })}
                    </div>
                </HMBox>
            )}
        </PlacesAutocomplete>
    )
}

export default HMPlacesAutocomplete
import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { 
    lightBlue,
    seeThroughLightBlue
} from '../../../hooks/useColors'

function HMPasswordField(props) {
    const fieldStyle = {
        fontSize: '12px !important',
        width: props.width || '100%',
        margin: props.margin || '3% 0', 
        backgroundColor: props.bgColor,
        borderRadius: props.borderRadius,
        '&:active': {
            border: props.borderActive,
        }
    }

    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)

    return (
        <TextField 
            required
            fullWidth
            style={fieldStyle}
            value={props.value}
            onChange={props.onChange}
            id={props.id || 'password'}
            name={props.name || 'password'}
            label={props.label || 'password'} 
            type={showPassword ? 'text' : 'password'}
            className={`HM-password-field ${props.className}`}
            placeholder={props.placeholder || 'Enter password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment 
                        position='end' 
                        sx={{ 
                            width: '50px',
                            height: '100%', 
                            padding: '0 5px',
                            backgroundColor: lightBlue, 
                            margin: 'auto -12px auto auto',
                        }}
                    >
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            aria-label='toogle password visibility'
                        >
                        {
                            showPassword 
                                ? <Visibility sx={{ fontSize: '1rem' }} /> 
                                : <VisibilityOff sx={{ fontSize: '1rem' }} />
                        }    
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

export default HMPasswordField
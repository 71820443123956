import React, { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import HMBox from '../layout/HMBox'
import HMPasswordTextField from './HMPasswordTextField'
import HMPasswordField from './HMPasswordField'
import HMTextField from './HMTextField'
import HMCheckbox from './HMCheckbox'
import HMButton from './HMButton'
import HMText from './HMText'
import {
    fontSize,
    iconStyle,
    textStyle,
    cancelStyle
} from './Styles'
import { 
    white,
    orange,
    lightBlue,
    lightOrange,
} from '../../../hooks/useColors'

function HMPasswordGenerator(props) {
  const parentRef = useRef()
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    if (props.password.length >= 5 && props.password.length <= 15) {
        navigator.clipboard.writeText(props.password)
        setCopied(true)
        setInterval(() => {
            setCopied(false)
        }, 30000)
    }
  }

  useEffect(() => {
    if (props.password.length >= 5 && props.password.length <= 15) {
        setCopied(false)
    }
  }, [props.password])
  
  return (
    <HMBox
        padding='0'
        width='100%'
        display='flex'
        flexDirection='column'
    >
        <HMPasswordField 
            className='fade-in'
            value={props.password}
            onChange={props.handleFieldsChange}
        />
        <HMBox
            width='100%'
            padding='0'
            display='flex'
            margin='5px auto 10px auto'
        >
            {open ? (
                <HMBox
                    width='100%'
                    padding='0'
                    display='flex'
                    className='fade-in'
                >
                    <HMBox
                        padding='0'
                        display='flex'
                    >
                        <Typography 
                            component='span'
                            sx={cancelStyle}
                            onClick={() => setOpen(!open)}
                        >
                            <CloseIcon sx={{...iconStyle, fontSize: '8px', cursor: 'pointer'}} />
                        </Typography>
                        <HMText
                            text='Close'
                            margin='3px 0 auto 5px'
                        />
                    </HMBox>
                    {(props.password.length >= 5 && props.password.length <= 15) ? (
                        <HMButton 
                            type='button'
                            color={orange}
                            padding='0 5px'
                            bgColor={lightOrange}
                            paddingActive='0 4.5px'
                            handleClick={handleCopy}
                            margin='auto 0 auto auto'
                            icon={
                                copied ? <LibraryAddCheckIcon sx={iconStyle} /> 
                                : <ContentCopyIcon sx={iconStyle} />
                            } 
                            text={
                                <Typography sx={textStyle}>
                                    {copied ? 'Copied!' : 'Copy'}
                                </Typography>
                            } 
                        />
                    ) : <></>}
                </HMBox>
            ) : (
                <Box 
                    width='100%'
                    display='flex'
                    className='fade-in'
                    onClick={() => setOpen(!open)}
                    sx={{
                        cursor: 'pointer', 
                        padding: '5px 5px',
                        borderRadius: '5px',
                        backgroundColor: lightBlue, 
                        '&:hover': {
                            opacity: 0.8,
                        }
                    }}
                >
                    <HMText 
                        margin='auto 0'
                        fontWeight={500}
                        fontSize={fontSize - 2}
                        text='Generate password'
                    />
                    <ArrowBackIosIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />
                </Box>
            )}
            
        </HMBox>
        <Box
            className='content'
            ref={parentRef}
            style={ 
                open 
                ? {
                    width: '100%',
                    height: `${parentRef.current.scrollHeight}px`,
                } 
                : {
                    height: '0px',
                    width: '100%',
                }
            }
        >
            <HMBox
                width='100%'
                display='flex'
                padding='8px 8px'
                bgColor={lightBlue}
                flexDirection='column'
            >
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMText 
                        margin='auto 0'
                        fontSize={fontSize - 2}
                        text='Specify password length'
                    />
                    <HMTextField 
                        width='60px'
                        type='number'
                        bgColor={white}
                        id='passwordLength'
                        margin='auto 0 auto auto'
                        value={props.passwordLength}
                        onChange={props.handleFieldsChange}
                    />
                </HMBox>
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMText 
                        margin='auto 0'
                        fontSize={fontSize - 2}
                        text='Include uppercase latters'
                    />
                    <HMCheckbox 
                        id='hasUpperCase'
                        margin='auto 0 auto auto'
                        checked={props.hasUpperCase}
                        handleChange={props.handleFieldsChange}
                    />
                </HMBox>
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMText 
                        margin='auto 0'
                        fontSize={fontSize - 2}
                        text='Include lowercase letters'
                    />
                    <HMCheckbox 
                        id='hasLowerCase'
                        margin='auto 0 auto auto'
                        checked={props.hasLowerCase}
                        handleChange={props.handleFieldsChange}
                    />
                </HMBox>
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMText 
                        margin='auto 0'
                        fontSize={fontSize - 2}
                        text='Include numbers'
                    />
                    <HMCheckbox 
                        id='hasNumber'
                        margin='auto 0 auto auto'
                        checked={props.hasNumber}
                        handleChange={props.handleFieldsChange}
                    />
                </HMBox>
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMText 
                        margin='auto 0'
                        fontSize={fontSize - 2}
                        text='Include symbols'
                    />
                    <HMCheckbox 
                        id='hasSymbols'
                        margin='auto 0 auto auto'
                        checked={props.hasSymbols}
                        handleChange={props.handleFieldsChange}
                    />
                </HMBox>
                <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMButton 
                        width='100%'
                        type='button'
                        margin='5px 0'
                        padding='0 5px'
                        bgColor={orange}
                        fontWeight='normal'
                        paddingActive='0 4.5px'
                        text='Generate password'
                        icon={<ShuffleIcon sx={iconStyle}/>} 
                        handleClick={props.handleGeneratePassword}
                        disabled={
                            (
                                !props.hasSymbols &&
                                !props.hasNumber &&
                                !props.hasUpperCase &&
                                !props.hasLowerCase
                            ) ? true : false
                        }
                    />
                </HMBox>
            </HMBox>
        </Box>
    </HMBox>
  )
}

export default HMPasswordGenerator
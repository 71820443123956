import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import HMButton from '../common/tools/HMButton'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../common/tools/Styles'
import {
    red,
    blue,
    white,
    green,
    orange,
    darkBlue,
    lightRed,
    lightBlue,
    lightBlack,
    lightOrange,
    getRandomColor
} from '../../hooks/useColors'
import {
    currencies
} from '../../hooks/helperFunctions'

function HMInsuranceCard(props) {
    const [currency, setCurrency] = useState('')
    const [randomColor, setRandomColor] = useState('')
    const [storeValidation, setStoreValidation] = useState({})

    useEffect(() => {
        setRandomColor(getRandomColor())
        setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
        if ('storeValidation' in props.insuranceInfo && props.isStoreView) {
            setStoreValidation(props.insuranceInfo.storeValidation[props.storeId])
        }
        // eslint-disable-next-line
    }, [props.insuranceInfo, props.storeId])

    const handleViewCard = (cardImage) => {
        window.open(cardImage, '_blank', 'noopener,noreferrer')
    }

    return (
        <HMBox
            width='100%'
            height='100%'
            display='flex'
            color={lightBlack}
            className='fade-in'
            margin={props.margin}
            flexDirection='column'
            bgColor={props.bgColor}
            cursor={!props.isDisabled && props.isStoreView ? 'pointer' : 'normal'}
            hoverBgColor={!props.isDisabled && props.isStoreView ? lightBlue : props.bgColor}
            handleClick={!props.isDisabled && props.isStoreView ? props.handleInsranceValidationModal : () => {}}
        >
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='auto auto 5px auto'
            >
                {props.insurer.logo ? (
                    <img
                        alt={props.insurer.name}
                        src={props.insurer.logo}
                        className='super-mini-image'
                        style={{ margin: 'auto 10px auto 0' }}
                    />
                ) : (
                    <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                        <HMText
                            float='none'
                            width='100%'
                            margin='auto'
                            height='100%'
                            color={white}
                            fontWeight={700}
                            textAlign='center'
                            bgColor={randomColor}
                            text={props.insurer.name.substr(0, 1).toUpperCase()}
                        />
                    </Grid>
                )}
                <HMText
                    fontWeight={500}
                    color={darkBlue}
                    margin='auto 5px'
                    text={props.insurer.name}
                    fontSize={`${fontSize - 1}px`}
                />
                <HMText
                    text='-'
                    margin='auto 5px'
                    fontSize={`${fontSize - 1}px`}
                />
                <HMText
                    margin='auto auto auto 5px'
                    fontSize={`${fontSize - 1}px`}
                    text={`${props.insurer.type} | ${props.insurer.origin}`}
                />
                {props.isCheckoutPage ? (
                    <Radio
                        size='small'
                        checked={true}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        className='listing-radio-button checked'
                        sx={{
                            margin: 'auto 0 auto auto',
                        }}
                    />
                ) : props.isOrderPage ? (
                    <>
                        <HMText
                            text='Coverage:'
                            margin='auto 5px auto auto'
                            fontSize={`${fontSize - 1}px`}
                        />
                        <HMText
                            color='orange'
                            fontWeight={500}
                            margin='auto 0 auto 5px'
                            fontSize={`${fontSize - 1}px`}
                            text={`${currency} ${props.coverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    </>
                ) : props.isProfilePage ? (
                    <>
                        <HMText
                            color={blue}
                            text='Change'
                            margin='auto 0 auto auto'
                            fontSize={`${fontSize - 1}px`}
                        />
                        <IconButton   
                            onClick={props.handleChangeClick} 
                            sx={{margin: 'auto 0 auto 2.5px' }}
                        >
                            <DriveFileRenameOutlineOutlinedIcon 
                                sx={{
                                    margin: 'auto',
                                    fontSize: `${fontSize + 2}px`,
                                }}
                            />
                        </IconButton>
                    </>
                ) : <></>}
            </HMBox>
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='5px auto 10px auto'
            >
                <HMText
                    margin='auto 0'
                    fontWeight={500}
                    fontSize={`${fontSize - 2}px`} 
                    text={props.insuranceInfo.nameOnCard}
                />
                <HMText
                    text='-'
                    fontWeight={500}
                    margin='auto 5px'
                    fontSize={`${fontSize - 2}px`} 
                />
                <HMText
                    fontWeight={500}
                    color={darkBlue}
                    margin='auto 10px auto 0'
                    fontSize={`${fontSize - 1}px`} 
                    text={props.insuranceInfo.cardNumber}
                />
                {props.isProfilePage && props.showIfDefault && props.insuranceInfo.default ? (
                    <HMText
                        color={white}
                        bgColor={orange}
                        margin='auto 5px'
                        padding='1.5px 5px'
                        text='Default Insurance'
                        fontSize={`${fontSize - 1}px`}
                    />
                ) : <></>}
                {(Object.keys(storeValidation).length && props.isStoreView) ? (
                    <HMButton 
                        type='button'
                        fontWeight={500}
                        margin='auto 0 auto auto'
                        padding='2px 10px 2px 5px'
                        disabled={props.isDisabled}
                        paddingActive='2px 9.5px 2px 4.5px'
                        handleClick={props.handleInsranceValidationModal}
                        color={
                            storeValidation.state.toLowerCase() === 'valid' ? white 
                                : storeValidation.state.toLowerCase() === 'invalid' ? white
                                : orange
                        }
                        bgColor={
                            storeValidation.state.toLowerCase() === 'valid' ? green 
                                : storeValidation.state.toLowerCase() === 'invalid' ? red
                                : lightOrange
                        }
                        text={
                            <Typography sx={{...textStyle, fontSize: `${fontSize}px`}}>
                                {
                                    storeValidation.state.toLowerCase() === 'valid' ? 'Valid' 
                                        : storeValidation.state.toLowerCase() === 'invalid' ? 'Invalid'
                                        : 'Validate Insurance'
                                }
                            </Typography>
                        }
                        icon={
                            storeValidation.state.toLowerCase() === 'valid' 
                                ? (<FactCheckOutlinedIcon sx={{ ...iconStyle, marginRight: '5px', fontSize: '16px' }} />) 
                                : storeValidation.state.toLowerCase() === 'invalid' 
                                ? (<CancelPresentationOutlinedIcon sx={{ ...iconStyle, marginRight: '5px', fontSize: '16px' }} />)
                                : (<LiveHelpOutlinedIcon sx={{ ...iconStyle, marginRight: '5px', fontSize: '16px' }} />)
                        }
                    />
                ) : (
                    <>
                        <HMButton 
                            type='button'
                            padding='0 5px'
                            bgColor={blue}
                            fontWeight={500}
                            paddingActive='0 4.5px'
                            margin='auto 0 auto auto'
                            icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                            handleClick={() => handleViewCard(props.insuranceInfo.cardImage)}
                            text={
                                <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                                    View card
                                </Typography>
                            }
                        />
                        {props.isProfilePage ? (
                            <IconButton   
                                onClick={props.handleDeleteClick} 
                                sx={{
                                    padding: '5px',
                                    margin: 'auto 0 auto 10px',
                                    backgroundColor: lightRed,
                                    '&:hover': {
                                        backgroundColor: red,
                                    },
                                }}
                            >
                                <DeleteForeverTwoToneIcon 
                                    sx={{
                                        color: red,
                                        margin: 'auto',
                                        borderRadius: '25px',
                                        fontSize: `${fontSize + 2}px`,
                                        '&:hover': {
                                            color: white,
                                        },
                                    }}
                                />
                            </IconButton>
                        ) : <></>}
                    </>
                )}
                
            </HMBox>
        </HMBox>
    )
}

export default HMInsuranceCard
import React from 'react'
import { Link } from '@mui/material'
import {
    fontSize,
    childLinkStyle
} from '../tools/Styles'

function SidebarItemLink(props) {

    const linkStyle = {
        ...childLinkStyle,
        fontWeight: props.textFontWeight,
        fontSize: props.textFontSize || `${fontSize}px`,
    }

    return (
        <Link sx={linkStyle} name={props.page} onClick={props.navigateUser}>
            {props.text}
        </Link>
    )
}

export default SidebarItemLink
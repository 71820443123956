import React from 'react'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import HMParntersLogo from './HMParntersLogo'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import Title from '../tools/Title'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    blue,
    white,
    orange,
    darkBlue,
} from '../../../hooks/useColors'

function HMPartners() {
    const {
        visible
    } = useSelector((state) => state.nav)
    const {
        windowW
    } = useWindowDimensions()

    const isMediumComputerScreen = windowW < 1170 ? true : false
    const isSamllComputerScreen = windowW < 1000 ? true : false
    const isTabletScreen = windowW < 768 ? true : false
    const isMobileScreen = windowW < 480 ? true : false 

    return (
        <>
            <HMBox
                width='100%'
                display='flex'
                flexDirection='column'
                margin='20px auto auto auto'
            >
                <Title 
                    margin='auto'
                    color={darkBlue}
                    textAlign='center'
                    size={isTabletScreen ? 15 : 25}
                    title='Stakeholders &#38; Partners'
                    width={isTabletScreen ? '100%' : '80%'}
                />
                <Divider color={orange} width='200px' sx={{ borderBottomWidth: '3px', margin: 'auto' }} />
            </HMBox>
            <HMBox
                width='100%'
                display='flex'
                margin='20px auto'
            >
                <HMButton 
                    type='button'
                    color={white}
                    bgColor={blue}
                    padding='5px 10px'
                    margin='auto 5px auto auto'
                    text='See Customer Histories'
                />
                <HMButton 
                    type='button'
                    color={white}
                    bgColor={orange}
                    padding='5px 10px'
                    margin='auto auto auto 5px'
                    text='See Partner Histories'
                />
            </HMBox>
            <HMBox
                display='flex'
                padding='10px'
                margin='20px 0 0 0'
                position='relative'
                className={
                    `HM-partners animated-slider ${
                        isMobileScreen ? 'small' 
                        : isTabletScreen ? 'medium' 
                        : (isSamllComputerScreen && visible) ? 'medium'
                        : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 'mid-normal'
                        : 'normal'
                    }`
                }
            >
                <HMParntersLogo className='one' />
                <HMParntersLogo className='two' />
            </HMBox>
        </>
  )
}

export default HMPartners
import React from 'react'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ErrorIcon from '@mui/icons-material/Error'
import RemoveIcon from '@mui/icons-material/Remove'
import WarningIcon from '@mui/icons-material/Warning'
import VerifiedIcon from '@mui/icons-material/Verified'
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone'
import HMBox from './HMBox'
import HMTextField from '../tools/HMTextField'
import HMCheckbox from '../tools/HMCheckbox'
import HMSpiner from '../tools/HMSpiner'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../tools/Styles'
import {
    red,
    white,
    green,
    orange,
    lightRed,
    lightGray,
    lightBlue,
    lightBlack,
} from '../../../hooks/useColors'
import {
    currencies,
} from '../../../hooks/helperFunctions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMStoreItem(props) {
    const item = props.item
    return (
        <HMBox
            width='100%'
            display='flex'
            margin='0 0 5px 0'
            className='fade-in'
            padding='7.5px 0 5px 2.5px'
            bgColor={props.bgColor || lightBlue}
        >
            <HMCheckbox 
                checked={item.select} 
                margin='auto 5px auto 0'
                handleChange={props.handleItemCheckboxChange} 
            />
            <Link
                onClick={props.navegateToProductPage}
                sx={{ margin: 'auto 0', cursor: 'pointer' }}
            >
            <img 
                alt={item.productName}
                src={item.productImage}
                className='medium-image'
            />
            </Link>
            <HMBox
                width='100%'
                height='100%'
                display='flex'
                padding='0 5px'
                margin='auto 0'
                flexDirection='column'
            >
                <HMText
                    fontWeight={500}
                    margin='0 auto auto 0'
                    text={item.productName}
                    fontSize={`${fontSize - 1}px`}
                />
                <HMBox
                    padding='0'
                    width='100%'
                    display='flex'
                    margin='auto 0'
                >
                    <HMText 
                        margin='auto 0'
                        fontWeight={500}
                        fontSize={`${fontSize - 2}px`}
                        color={item.stockCount > 0 ? green : red}
                        text={item.stockCount > 0 ? 'in stock' : 'sold out'}
                    />
                    {props.isOperator ? (
                        <HMText
                            fontWeight={500}
                            color={lightBlack}
                            margin='auto 0 auto 5px'
                            fontSize={`${fontSize - 3}px`}
                            text={`( ${item.stockCount} )`}
                        />
                    ) : <></>}
                    <HMText
                        text='|'
                        margin='auto 5px'
                        color={lightBlack}
                        fontSize={`${fontSize - 1}px`}
                    />
                    <HMText
                        color={lightBlack}
                        margin='auto auto auto 0'
                        fontSize={`${fontSize - 1}px`}
                        text={`${currency} ${item.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                    <HMText
                        color={orange}
                        fontWeight={500}
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 1}px`}
                        text={`${currency} ${(item.onlinePrice * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                <HMBox 
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                >
                    <HMBox 
                        display='flex'
                        bgColor={white}
                        padding='1px 5px'
                        width='max-content'
                    >
                        <IconButton   
                            onClick={props.handleQuantityReduce} 
                            sx={{ padding: '3px', margin: 'auto 0' }}
                            disabled={(props.updatingListing === item.listing || item.quantity === 1) ? true : false}
                        >
                            <RemoveIcon sx={{width: '15px', height: '15px'}} />
                        </IconButton>
                        <HMTextField 
                            width='30px'
                            type='number'
                            name='quantity'
                            margin='auto 5px'
                            value={item.quantity}
                            className='cart-product quantity'
                            onChange={props.handleQuantityChange}
                        />
                        <IconButton   
                            onClick={props.handleQuantityIncrease} 
                            sx={{ padding: '3px', margin: 'auto 0' }}
                            disabled={props.updatingListing === item.listing || item.quantity === item.stockCount ? true : false}
                        >
                            <AddIcon sx={{width: '15px', height: '15px'}} />
                        </IconButton>
                    </HMBox>
                    {(props.isLoading && props.updatingListing === item.listing) ? (
                        <HMSpiner 
                            size={15}
                            bgColor={white}
                            position='absolute'
                            margin='5px 0px 0 39px'
                        />
                    ) : <></>}
                    {item.stockCount === item.quantity ? (
                        <HMBox
                            display='flex'
                            padding='2px 5px'
                            className='fade-in'
                            bgColor={lightRed}
                            margin='auto 0 auto 10px'
                        >
                            <WarningIcon 
                                sx={{
                                    color: red,
                                    padding: '0',
                                    margin: 'auto 5px auto auto',
                                    fontSize: `${fontSize - 2}px`,
                                }}
                            />
                            <HMText
                                color={red}
                                margin='auto 0'
                                fontWeight={500}
                                borderRadius={5}
                                text='Maximum stock!'
                                fontSize={`${fontSize - 4}px`}
                            />
                        </HMBox>
                    ) : <></>}
                    {props.isCart && item.classification.includes('prescription') ? (
                        <>
                            <Tooltip 
                                arrow
                                placement='top' 
                                title="A doctor's prescription is required before buying this medicine! Click to uplaod a prescription."
                            >
                                <HelpOutlineTwoToneIcon 
                                    sx={{
                                        cursor: 'pointer',
                                        color: lightBlack,
                                        width: 'max-content',
                                        fontSize: `${fontSize + 2}px`,
                                        margin: 'auto 10px auto auto',
                                    }} 
                                />
                            </Tooltip>
                            <HMButton 
                                type='button'
                                margin='auto 0'
                                fontWeight={500}
                                padding='1px 5px'
                                width='max-content'
                                paddingActive='0.5px 4.5px'
                                className='cart-prescription-button'
                                handleClick={props.handlePrescriptionModal}
                                color={
                                    (item.addedInfo && item.addedInfo.uploadedPrescription) ? white
                                    : !item.select ? lightBlack 
                                    : (item.select && !(item.addedInfo && item.addedInfo.uploadedPrescription)) && red
                                }
                                bgColor={
                                    (item.addedInfo && item.addedInfo.uploadedPrescription) ? green
                                    : !item.select ? lightGray 
                                    : (item.select && !(item.addedInfo && item.addedInfo.uploadedPrescription)) && lightRed
                                }
                                icon={
                                    (item.addedInfo && item.addedInfo.uploadedPrescription) ? <VerifiedIcon sx={{...iconStyle, fontSize: `${fontSize - 2}px`, marginRight: '5px'}} />
                                    : !item.select ? <ErrorIcon sx={{...iconStyle, fontSize: `${fontSize}px`, marginRight: '5px'}} /> 
                                    : (item.select && !(item.addedInfo && item.addedInfo.uploadedPrescription)) && <WarningIcon sx={{...iconStyle, fontSize: `${fontSize - 2}px`, marginRight: '5px'}} />
                                } 
                                text={
                                    <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                                    {
                                        (item.addedInfo && item.addedInfo.uploadedPrescription) ? 'Uploaded'
                                        : !item.select ? 'Requires prescription' 
                                        : (item.select && !(item.addedInfo && item.addedInfo.uploadedPrescription)) && 'Click to upload prescription'
                                    }
                                    </Typography>
                                }
                            />
                        </>
                    ) : props.isCart && item.classification.includes('otc') ? (
                        <>
                            <Tooltip 
                                arrow
                                placement='top' 
                                title='Additional information is required before buying this product! Click to prodive few details about the patient.' 
                            >
                                <HelpOutlineTwoToneIcon 
                                    sx={{
                                        cursor: 'pointer',
                                        color: lightBlack,
                                        width: 'max-content',
                                        fontSize: `${fontSize + 2}px`,
                                        margin: 'auto 10px auto auto',
                                    }} 
                                />
                            </Tooltip>
                            <HMButton 
                                type='button'
                                margin='auto 0'
                                fontWeight={500}
                                padding='1px 5px'
                                width='max-content'
                                paddingActive='0.5px 4.5px'
                                className='cart-prescription-button'
                                handleClick={props.handleAddInfoModal}
                                color={
                                    ('addedInfo' in item) ? white
                                    : !item.select ? lightBlack 
                                    : (item.select && !('addedInfo' in item)) && red
                                }
                                bgColor={
                                    ('addedInfo' in item) ? green
                                    : !item.select ? lightGray 
                                    : (item.select && !('addedInfo' in item)) && lightRed
                                }
                                icon={
                                    ('addedInfo' in item) ? <VerifiedIcon sx={{...iconStyle, fontSize: `${fontSize - 2}px`, marginRight: '5px'}} />
                                    : !item.select ? <ErrorIcon sx={{...iconStyle, fontSize: `${fontSize}px`, marginRight: '5px'}} /> 
                                    : (item.select && !('addedInfo' in item)) && <WarningIcon sx={{...iconStyle, fontSize: `${fontSize - 2}px`, marginRight: '5px'}} />
                                } 
                                text={
                                    <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                                    {
                                        ('addedInfo' in item) ? 'Added'
                                        : !item.select ? 'Requires additinal info' 
                                        : (item.select && !('addedInfo' in item)) && 'Click to add info'
                                    }
                                    </Typography>
                                }
                            />
                        </>
                    ) : <></>}
                </HMBox>
            </HMBox>
        </HMBox>
    )
}

export default HMStoreItem
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import supplierListingService from './supplierListingService'

const initialState = {
    message: '',
    isOne: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    supplierListing: {},
    supplierListings: [],
}

// Get all supplierListings
export const getSupplierListings = createAsyncThunk(
    'supplierListings/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierListingService.getSupplierListings(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a supplierListing
export const getSupplierListing = createAsyncThunk(
    'supplierListings/one',
    async (listingId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierListingService.getSupplierListing(listingId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Create a new supplierListing
export const createSupplierListing = createAsyncThunk(
    'supplierListings/create',
    async (newSupplierListing, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierListingService.createSupplierListing(newSupplierListing, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an supplierListing
export const updateSupplierListing = createAsyncThunk(
    'supplierListings/update',
    async (supplierListing, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierListingService.updateSupplierListing(supplierListing, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an supplierListing
export const deleteSupplierListing = createAsyncThunk(
    'supplierListings/delete',
    async (supplierListingID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierListingService.deleteSupplierListing(supplierListingID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const supplierListingSlice = createSlice({
    name: 'supplierListing',
    initialState,
    reducers: {
        resetSupplierListing: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSupplierListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getSupplierListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isSuccess = true
                state.supplierListings = action.payload
            })
            .addCase(getSupplierListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
            })

            .addCase(getSupplierListing.pending, (state) => {
                state.isOne = false
                state.isError = false
                state.isLoading = true
            })
            .addCase(getSupplierListing.fulfilled, (state, action) => {
                state.isOne = true
                state.isError = false
                state.isLoading = false
                state.supplierListing = action.payload
            })
            .addCase(getSupplierListing.rejected, (state, action) => {
                state.isOne = false
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(createSupplierListing.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createSupplierListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.supplierListing = action.payload
            })
            .addCase(createSupplierListing.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateSupplierListing.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateSupplierListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.supplierListing = action.payload
            })
            .addCase(updateSupplierListing.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteSupplierListing.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteSupplierListing.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteSupplierListing.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetSupplierListing } = supplierListingSlice.actions
export default supplierListingSlice.reducer
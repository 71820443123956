import axios from 'axios'

const API_URL_DELIVERY = '/api/v1/extension/get_nearest_agent_info'

// Get nearest delivery agent
const getNearestDeliveryAgent = async (obj, token) => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL_DELIVERY, obj, config)

    const data = response.data

    return data
}

const deliveryService = {
    getNearestDeliveryAgent
}

export default deliveryService

import { Box } from "@mui/material"

import HMText from "./HMText"
import { fontSize } from "./Styles"
import { white } from "../../../hooks/useColors"



 const HMDashboardCard = (props) => {
    return (
      <Box
        sx={{
          background: props.bgColor,
          height: props.height || '120px',
          width: props.width || '33%',
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HMText text={props.text} color={white}  />
        <HMText text={props.money} color={white} fontSize = {fontSize + 10} fontWeight='700'/>
      </Box>
    )
  }

  export default HMDashboardCard
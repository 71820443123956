import axios from 'axios'

const API_URL_FAQCATEGORIES = '/api/v1/faqcategories/private'
const API_URL_FAQCATEGORIES_PUBLIC = '/api/v1/faqcategories/'

// Get all public Faq categories
const getFaqCategories = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_FAQCATEGORIES_PUBLIC, config)

    const data = response.data
    
    if (data) {
        localStorage.setItem('publiFaqCategories', JSON.stringify(data))
    }
    console.log("================================",data)
    return data
}

// Get all public Faq categories
const getFaqOneCategories = async (catId,token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_FAQCATEGORIES_PUBLIC}/${catId}`, config)

    const data = response.data
    return data
}

// Create a new Faq category
const createFaqCategory = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL_FAQCATEGORIES, data, config)

    return response.data
}

// Update a Faq category
const updateFaqCategory = async (faqCategory, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_FAQCATEGORIES}/${faqCategory._id}`, faqCategory, config)

    return response.data
}

// Delete a Faq category
const deleteFaqCategory = async (faqcategoryID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_FAQCATEGORIES}/${faqcategoryID}`, config)

    return response.data
}



const faqCategoryService = {
    createFaqCategory,
    updateFaqCategory,
    deleteFaqCategory,
    getFaqCategories,
    getFaqOneCategories,
}
export default faqCategoryService

import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import HMBox from '../../components/common/layout/HMBox'
import HMTextField from '../../components/common/tools/HMTextField'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import { fontSize, iconStyle } from '../../components/common/tools/Styles'
import { white, lightRed, lightBlue } from '../../hooks/useColors'
import HMDropZone from '../common/tools/HMDropZone'
import HMRadioButton from '../common/tools/HMRadioButton'
import { cloudinary } from '../../hooks/helperFunctions'

function StepTwo(props) {
  const { 
    type, 
    phone, 
    storeName 
  } = props.formData

  return (
    <>
      <HMBox
        width='100%'
        display='flex'
        padding='10px'
        margin='30px 0px'
        bgColor={lightBlue}
        flexDirection='column'
      >
        <HMText 
          left='0'
          top='-35px'
          height='30px'
          bgColor={white}
          fontWeight={500}
          text='Store Logo'
          width='max-content'
          className='fade-in'
          position='relative'
          margin='10px 0 -25px 20px'
          padding='5px 10px 0px 10px'
          border={`1px solid ${lightBlue}`}
        />
        <HMDropZone
          bgColor={white}
          allowedSizeInMB={1}
          className='drag-drop'
          endPoint={cloudinary.API_URL_LOGOS}
          cloudinaryFileUrl={props.storeLogoUrl}
          setCloudinaryFileUrl={props.setStoreLogo}
          supportedFiles={{
            'image/jpg': [],
            'image/png': [],
            'image/jpeg': [],
          }}
        />
      </HMBox>
      {props.isStoreNameTaken || (storeName.length && storeName.length < 4) ? (
        <HMAlert
          error={true}
          margin='10px auto'
          bgColor={lightRed}
          fontSize={fontSize - 2}
          text={
            (storeName.length && storeName.length < 4) 
            ? 'The name of the store should at least be 4 characters long'
            : `'${storeName}' is already taken`
          }
        />
      ) : (
        <></>
      )}
      <HMTextField
        required={true}
        name='storeName'
        value={storeName}
        label='Store name'
        className='fade-in'
        onChange={props.handleChange}
        placeholder='Enter store name'
      />
      {props.wrongNumber ? (
        <HMAlert
          error={true}
          margin='10px auto'
          bgColor={lightRed}
          fontSize={fontSize - 2}
          text={`Input a correct phone number`}
        />
      ) : (
        <></>
      )}
      {phone.map((item, index) => {
        const n =
          index === 0
            ? '1st'
            : index === 1
            ? '2nd'
            : index === 2
            ? '3rd'
            : index + 1 + 'th'
        return (
          <HMBox
            padding='0'
            key={index}
            width='100%'
            display='flex'
            className='fade-in'
          >
            <HMTextField
              value={item}
              name='phone'
              type='number'
              margin='5px 0'
              required={true}
              bgColor={white}
              placeholder='07XXXXXXXX'
              label={`${n} Phone number`}
              onChange={(e) => props.handlePhones(index, e)}
            />
            {phone.length > 1 && (
              <IconButton
                onClick={() => props.handleRemovePhone(index)}
                sx={{ padding: '5px', margin: 'auto 5px', height: '30px' }}
              >
                <RemoveIcon sx={iconStyle} />
              </IconButton>
            )}
            {phone.length < 3 && (
              <IconButton
                onClick={() => props.handleAddPhone(index)}
                sx={{ padding: '5px', margin: 'auto 5px', height: '30px' }}
              >
                <AddIcon sx={iconStyle} />
              </IconButton>
            )}
          </HMBox>
        )
      })}
      <HMBox
        width='100%'
        padding='20px 0'
        margin='10px auto auto auto'
      >
        <HMText 
          left='0'
          top='-25px'
          height='30px'
          bgColor={white}
          fontWeight={500}
          text='Store Type'
          width='max-content'
          className='fade-in'
          position='relative'
          margin='10px 0 -25px 20px'
          padding='5px 10px 0px 10px'
          border={`1px solid ${lightBlue}`}
        />
        <HMRadioButton 
          label=''
          name='type'
          value={type}
          padding='5px'
          margin='auto 0 10px 0'
          fontSize={`${fontSize + 2}px`}
          onChange={props.handleTypeChange}
          labelId='ask-prescription-radio-group'
          options={[
            { label: 'Pharmacy', value: 'Pharmacy' },
            { label: 'Health Care', value: 'Health Care' }
          ]}
        />
      </HMBox>
    </>
  )
}

export default StepTwo

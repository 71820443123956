import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import StepContent from '@mui/material/StepContent'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
  textStyle,
} from '../tools/Styles'
import {
  blue,
  green,
  darkBlue,
  lightGray
} from '../../../hooks/useColors'

function BuyWithPrescriptionStepper (props) {
  const steps = props.steps 

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box className='HM-Stepper fade-in' sx={{ margin: '5% auto 5% 0' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                flexDirection='column'
              >
                {step.description}
              </HMBox>
              <HMBox
                width='100%'
                display='flex'
              >
                <HMButton 
                  type='button'
                  color={darkBlue}
                  bgColor={lightGray}
                  disabled={index === 0}
                  handleClick={handleBack}
                  margin='auto 10px auto 0'
                  text={<Typography sx={textStyle}>Back</Typography>} 
                />
                <HMButton 
                  type='button'
                  bgColor={blue}
                  handleClick={handleNext}
                  margin='auto 0 auto 10px'
                  disabled={
                    index === 0 ? props.disableStepOne 
                    : index === 1 ? props.disableStepTwo 
                    : (steps.length === 3 && index === 2) ? props.disableLastStep
                    : (steps.length === 4 && index === 2) ? props.disableInsuranceStep
                    : (steps.length === 4 && index === 3) && props.disableLastStep
                  }
                  text={
                    <Typography sx={{...textStyle, fontWeight: 500 }}>
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Typography>
                  } 
                />
              </HMBox>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <HMText 
            text='Thanks for completing all the steps! You may now submit your order.'
          />
          <HMBox
            width='100%'
            display='flex'
            margin='20px auto'
          >
            <HMButton 
              type='button'
              color={darkBlue}
              bgColor={lightGray}
              handleClick={handleBack}
              margin='auto 10px auto 0'
              text={<Typography sx={textStyle}>Back</Typography>} 
            />
            <HMButton 
              type='button'
              color={darkBlue}
              bgColor={lightGray}
              handleClick={handleReset}
              margin='auto auto auto 10px'
              text={<Typography sx={textStyle}>Reset</Typography>} 
            />
            <HMButton 
              type='submit'
              bgColor={green}
              margin='auto auto auto 10px'
              disabled={props.disableSubmit}
              handleClick={props.handleSubmit}
              text={
                <Typography sx={{...textStyle, fontWeight: 500, marginRight: '10px',  marginLeft: '10px'}}>
                  Place order
                </Typography>
              } 
            />
          </HMBox>
        </Paper>
      )}
    </Box>
  )
}

export default BuyWithPrescriptionStepper

import React from 'react'
import ReactApexChart from 'react-apexcharts'
import HMBox from '../common/layout/HMBox'
import {
  blue,
  green,
} from '../../hooks/useColors'

 const HMDonutChart = (props) => {
    const options = {
      chart: {
        width: '100%',
        type: 'donut',
      },
      colors:props.colors || [ blue, green],
      labels: props.labels,
      legend: {
        show: false 
      },
      stroke: {
        show: false,
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
              offset: 0,
          }, 
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          donut: {
            size: props.donutSize || '65%',
            labels: {
              show: true,
              value: {
                fontSize: '30px',
                fontWeight: 'bold',
                color: 'rgba(47, 56, 84, 0.8)',
                formatter: function (val, opts) {
                  return val + '%'
                },
              },
              total: {
                show: true,
                fontSize: '16px',
                fontWeight: 'bold',
                display: 'none',
                formatter: function (w) {
                  let result = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  return `${Math.round(result * 100) / 100}%` // * 100 for 2 decimal numbers
                },
              },
              name: {
                show: props.name_show || false,
                fontSize: '16px',
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: props.showDataLabels,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            }
          },
        },
      ],
    }
  
    return (
      <HMBox
        display='flex'
        minWidth='250px'
        minHeight='250px'
        bgColor={props.bgColor}
        width={props.width || '250px'}
        padding={props.padding || '0'}
        height={props.height || '250px'}
        margin={props.margin || 'auto 0'}
        flexDirection={props.flexDirection || 'column'}
      >
        <ReactApexChart
          type='donut'
          width='100%'
          height='100%'
          margin= '10px'
          position='center'
          options={options}
          series={props.data}
        />
      </HMBox>
    )
  }

  export default HMDonutChart
import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import HMBox from './HMBox'
import HMSpiner from '../tools/HMSpiner'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText' 
import {
  textStyle,
  deleteItemTextStyle
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  blue,
  white,
  orange,
  superLightBlue,
  superLightGray
} from '../../../hooks/useColors'

function HMSearchDropDown(props) {
  const boxRef = useRef(null)
  const navigate = useNavigate()
  const [height, setHeight] = useState(0)

  const { 
    searchLoading,
  } = useSelector((state) => state.search)

  const {
    windowW
  } = useWindowDimensions()

  const isSamllComputerScreen = windowW < 1200 ? true : false

  useEffect(() => {
    if (boxRef.current) {
      setHeight(boxRef.current.children[0].clientHeight)
    } 

    if (props.products && !props.products.length) {
      setHeight(isSamllComputerScreen ? 100 : 61)
    }
  }, [props.searchText, props.products, isSamllComputerScreen])

  const navigateToSearchPage = () => {
    navigate(`/search/${props.searchText}`)
  }

  const handleProductClick = (product) => {
    navigate(`/categories/${product.category.id.parent._id}/${product.category.id._id}/${product._id}`)
  }

  return (
    <Box
      ref={boxRef}
      sx={{
        zIndex: 999,
        width: '100%',
        padding: '0px',
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: white,
        height: props.open && boxRef.current ? `${height}px` : '0px'
      }}
      className={`search-drop-down ${props.open ? 'open' : 'close'}`}
    >
      <HMBox
        width='100%'
        padding='0px'
        display='flex'
        flexDirection='column'
      >
        {searchLoading ? (
          <HMSpiner 
            size={30}
            zIndex={999}
            width='100%'
            margin='auto'
            height='100%'
            position='absolute'
            bgColor='transparent'
          />
        ) : <></>}
        {props.products && props.products.length ? 
          props.products.map((data, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                display: 'flex',
              }}
            >
              {index <= 5 ? (
                <Box
                  sx={{
                    width: '100%',
                    padding: '5px',
                    display: 'flex',
                    cursor: 'pointer',
                    borderBottom: `1px solid ${superLightGray}`,
                    '&:hover': {
                      backgroundColor: superLightBlue
                    }
                  }}
                  onClick={() => handleProductClick(data.product)}
                >
                  <HMImage 
                    width='35px'
                    height='35px'
                    margin='auto 0'
                    borderRadius='10px'
                    src={data.product.image}
                  />
                  <HMText 
                    text={data.name}
                    margin='auto 0 auto 10px'
                  />
                </Box>
              ) : index === 6 ? (
                <Link 
                  onClick={navigateToSearchPage}
                  sx={{
                    ...textStyle,
                    color: blue,
                    cursor: 'pointer',
                    margin: '10px auto',
                    textDecoration: 'none',
                    '&:hover': {
                      color: orange
                    }
                  }}
                >  
                  View more
                </Link>
              ) : <></>}
            </Box>
          ))
        : (
            <HMBox
              width='100%'
              padding='20px 10px'
            >
              <Typography sx={textStyle} component='span'>
                So sorry! We looked everywhere but your search&nbsp;&nbsp;
              </Typography>
              <Typography sx={deleteItemTextStyle} component='span'>
                {props.searchText}
              </Typography>
              <Typography sx={textStyle} component='span'>
                &nbsp;&nbsp;doesn't much any product or medicine!
              </Typography>
            </HMBox>
          )
        }
      </HMBox>
    </Box>
  )
}

export default HMSearchDropDown
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { googleLogout } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
    addLog,
    resetLog
} from '../../../features/log/logSlice'
import { 
    reset, 
    logout, 
} from '../../../features/auth/authSlice'
import HMToogleButton from '../tools/HMToogleButton'
import DialItem from '../tools/DialItem'
import HMImage from '../tools/HMImage'
import {
    fontSize,
    userStyle,
    imageStyle,
    dialGridStyle,
    dialIconStyle,
} from '../tools/Styles'
import {
    logsObj
} from '../../../hooks/helperFunctions'
import { 
    green,
    strongRed,
} from '../../../hooks/useColors'

function HMUserNavigation(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { 
        myLog,
        isLogAdded
    } = useSelector((state) => state.log)
    const { 
        user 
    } = useSelector(state => state.auth)

    const parentRef = useRef()
    const avatarRef = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const path = window.location.pathname.toLowerCase()

    const toggle = (e) => {
        e.stopPropagation()
        setIsMenuOpen(isMenuOpen ? !isMenuOpen : (e && e.target === avatarRef.current.children[0]))
    }

    useEffect(() => {
        ['click', 'touchend'].forEach(e => {
          document.addEventListener(e, toggle)
        })
        return () => ['click', 'touchend'].forEach(e => {
          document.removeEventListener(e, toggle)
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isLogAdded) {
            dispatch(resetLog())
        }
        // eslint-disable-next-line
    }, [isLogAdded])

    const toProfile = () => {
        setIsMenuOpen(false)
        navigate(
            path.includes('admin') ? '/admin/my-profile'
            : path.includes('store') ? '/store/my-profile'
            : path.includes('supplier') ? '/supplier/my-profile'
            : '/profile'
        )
    }
    const toSettings = () => {
        setIsMenuOpen(false)
        navigate(
            path.includes('admin') ? '/admin/my-settings'
            : path.includes('store') ? '/store/my-settings'
            : path.includes('supplier') ? '/supplier/my-settings'
            : '/settings'
        )
    }
    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        googleLogout()
        navigate(
            path.includes('admin') ? '/admin/login'
            : path.includes('store') ? '/store/login'
            : path.includes('supplier') ? '/supplier/login'
            : '/landing'
        )
    }
    const onAddLog = () => {
        const status = myLog.status === 'working' ? 'unavailable' : 'working'
        dispatch(addLog({ status }))
    }

    return user ? (
        <>
            <Grid 
                onClick={toggle} 
                ref={avatarRef}
                sx={{
                    ...userStyle,
                    margin: props.margin,
                    width: props.width || '31px',
                    height: props.height || '31px',
                }} 
            >
                {user.image ? (
                    <HMImage 
                        width='inherit'
                        height='inherit'
                        src={user.image}
                        borderRadius='50%'
                        alt={user.firstName}
                        border={
                            `4px solid ${
                                (
                                    !Object.keys(myLog).length ||  
                                    (Object.keys(myLog).length && myLog.status === 'working')
                                )
                                ? green 
                                : strongRed
                            }`
                        }
                    />
                ) : (
                    <AccountCircleIcon 
                        sx={{
                            ...imageStyle,
                            border: `4px solid ${
                                (
                                    !Object.keys(myLog).length ||  
                                    (Object.keys(myLog).length && myLog.status === 'working')
                                )
                                ? green 
                                : strongRed
                            }`
                        }} 
                        className={isMenuOpen ? 'user-mini-image' : ''}
                    />
                )}
            </Grid>
            <Grid 
                ref={parentRef}
                className='content'
                sx={{
                    ...dialGridStyle,
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    top: props.top || '55px',
                }}
                style={
                    isMenuOpen 
                        ? {
                            height: `${parentRef.current.scrollHeight}px`,
                        } 
                        : {
                            height: '0px'
                        }
                }
            >
                <Grid sx={{ margin: '20px auto' }} className='small-navigation'>
                    <DialItem 
                        text='Profile'
                        handleClick={toProfile}
                        icon={
                            <PersonOutlineTwoToneIcon 
                                sx={{
                                    ...dialIconStyle,
                                    fontSize: `${fontSize + 10}px`
                                }}
                            />
                        }
                    />
                    <DialItem 
                        text='Settings'
                        handleClick={toSettings}
                        icon={
                            <SettingsOutlinedIcon 
                                sx={{
                                    ...dialIconStyle,
                                    fontSize: `${fontSize + 10}px`
                                }}
                            />
                        }
                    />
                    <DialItem 
                        text='Logout'
                        handleClick={onLogout}
                        icon={
                            <LogoutTwoToneIcon 
                                sx={{
                                    ...dialIconStyle,
                                    fontSize: `${fontSize + 10}px`
                                }}
                            />
                        }
                    />
                    {Object.keys(myLog).length ? (
                        <HMToogleButton 
                          handleChange={onAddLog}
                          status={myLog.status === 'working'}
                          text={logsObj[myLog.status].operator.status}
                        />
                    ) : <></>}
                </Grid>
            </Grid>
        </>
    ) : <></>
}

export default HMUserNavigation
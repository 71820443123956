import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import Page from '../../../components/common/layout/Page'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMButton from '../../../components/common/tools/HMButton'
import { fontSize, textStyle } from '../../../components/common/tools/Styles'
import {
  blue,
  green,
  lightBlue,
  white,
} from '../../../hooks/useColors'
import HMBox from '../../../components/common/layout/HMBox'
import Title from '../../../components/common/tools/Title'
import analyticsBlueIcon from '../../../assests/analyticsBlueIcon.png'
import analyticsGreenIcon from '../../../assests/analyticsGreenIcon.png'

import HMPieChart from '../../../components/common/tools/HMPieChart'
import HMDashboardCard from '../../../components/common/tools/HMDashboardCard'
import DashboardBottomCard from '../../../components/common/tools/DashboardBottomCard'
import HMLineChart from '../../../components/common/tools/HMLineChart'
import HMAreaChart from '../../../components/common/tools/HMAreaChart'
import HMDonutChart from '../../../components/common/tools/HMDonutChart'



const PIE_CHART_DATA = [30, 39, 31];
const PIE_CHART_LABELS = ['Service fee', 'Commission', 'Transport'];

const LINE_CHART_DATA =[
  {
    name: 'Commission ',
    data: [60, 95, 60, 100, 42, 109, 80],
  },
  {
    name: 'Transport fee',
    data: [20, 70, 50, 80, 34, 75, 41],
  },
  {
    name: 'Service fee',
    data: [4, 40, 12, 50, 12, 52, 9],
  },
];

const AREA_CHART_DATA = [
  {
    name: 'Wholesale ',
    data: [31, 40, 28, 51, 42, 109, 100],
    type: 'area',
  },
  {
    name: 'Retailing',
    data: [11, 32, 45, 32, 34, 52, 41],
    type: 'area',
  },
]

 const DONUT_CHART_DATA = [30, 39]
 const DONUT_CHART_LABELS = ['Wholesale', 'Retailing'];

const Income = ()=> {
  return (
    <Page>
      <FullWidthContainer>
        <HMButton
          type="button"
          isResponsive={true}
          float="right"
          className="report-btn"
          //  handleClick={handleMakeReport}
          text={<Typography sx={textStyle}>Make report</Typography>}
        />
        <Grid container md={12} gap={1.5}>
          <Grid
            item
            xs={12}
            md={4.8}
            sx={{
              background: white,
              height: '270px',
              borderRadius: '20px',
              pt: 2,
            }}
          >

            <HMPieChart 
            title="Income distribution"
            labels = {PIE_CHART_LABELS}
            data={PIE_CHART_DATA}
            />
            {/* 
    
            {/* !!!!!!!!!!!!!!!!!!HM-cards section!!!!!!!!!!!!!!!!! */}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              background: white,
              height: '270px',
              p: 2,
              borderRadius: '20px',
            }}
          >
            <Title title="Revenue" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)'/>
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!HM-main-cards!!!!!!!!!!!!!!!!! */}
            <Box sx={{ mt: 8, padding: '0 5px', display: 'flex', gap: 1 }}>
              <HMDashboardCard
                bgColor="#FFA500"
                text="Service fee Income"
                money="1M  Rwf"
              />

              <HMDashboardCard
                bgColor={blue}
                text="Commission Income"
                money="1M  Rwf"
              />

              <HMDashboardCard
                bgColor={green}
                text="Transport fee Income"
                money="1M  Rwf"
              />
            </Box>
          </Grid>

          {/* !!!!!!!!!!!!!!!!!!HM-cards end here !!!!!!!!!!!!!!!!! */}

          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: '500px',
              p: '30px 20px',
              borderRadius: '20px',
            }}
          >
            <Title title="Income" size={fontSize + 4} fontWeight="700"  color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!main-line Graph start here ---->>>>>> !!!!!!!!!!!!!!!!! */}

            <Box sx={{ width: '100%', height: '80%', mt: 8 }}>
              <HMLineChart data={LINE_CHART_DATA}  />
            </Box>
          </Grid>


          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: '800px',
              p: '30px 33px',
              borderRadius: '20px',
            }}
          >

            <Title title="Commission" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            <Grid container sx={{ mt: 8 }} gap={2}>
              <Grid item xs={12} md={8} sx={{ background: white }}>
                <HMAreaChart data={AREA_CHART_DATA}  />
              </Grid>

              <Grid item xs={12} md={3.7} sx={{ background: white, p: 3 }}>


                <DashboardBottomCard
                  bgColor="#94DDF8"
                  src={analyticsBlueIcon}
                  text="Wholesale Revenue"
                  money={60}
                />

                <DashboardBottomCard
                  bgColor="#9DEDD5"
                  src={analyticsGreenIcon}
                  text="Wholesale Revenue"
                  money={80}
                  mt = {4}
                />


                {/* !!!!!!!!!!!!!Donut-chart-here!!!!!!!!!!!!! */}
                <Box
                  sx={{
                    // background: '#9DEDD5',
                    padding: '20px 0 0',
                    mt: 2,
                  }}
                >
                  <Box sx={{ m: 'auto' }}>
                    <HMDonutChart data={DONUT_CHART_DATA} labels={DONUT_CHART_LABELS} />
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  )
}

export default Income

import React from 'react'
import { CSVLink } from 'react-csv'

function ReactCSV({ data, filename, children }) {
  return (
    <div style={{margin: 'auto'}}>
        <CSVLink
            data={data}
            filename={filename}
            target='_blank'
        >
            {children}
        </CSVLink>
    </div>
  )
}

export default ReactCSV
import React, { useState, useEffect,  useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { parse } from 'papaparse'
import {
    Box,
    Typography
} from '@mui/material'
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone'
import CloseIcon from '@mui/icons-material/Close'
import {
    resetCloudinary,
    uploadFileToCloudinary
} from '../../../features/cloudinary/cloudinarySlice'
import HMSpiner from './HMSpiner'
import HMImage from './HMImage'
import { 
    textStyle,
    iconStyle,
    errorStyle,
    cancelStyle
} from './Styles'
import { reset, loadDataToImport } from '../../../features/import/importSlice'
import {
    white,
    green,
    lightBlue,
    lightGreen,
} from '../../../hooks/useColors'
import csv from '../../../assests/csv.png'
import pdf from '../../../assests/pdf.png'

const filenameStyle = {
    ...textStyle, 
    padding: '5px',
    borderRadius: '7px',
    backgroundColor: white,
    margin: 'auto 5px auto auto !important',
}

function HMDropZone(props) {
    const dispatch = useDispatch()
    const { 
        file,
        isUploaded,
        isUploading,
        isUploadError
    } = useSelector((state) => state.cloudinary)

    const allowedSize = props.allowedSizeInMB * 1000000

    const [error, setError] = useState('')
    const [filenames, setFilenames] = useState('')
    const [showFileType, setShowFileType] = useState(false)
    const [fileType, setFileType] = useState(props.fileType)
    const [supportedFiles, setSupportedFiles] = useState([])

    useEffect(() => {
        if (isUploaded) {
            setShowFileType(true)
            props.setCloudinaryFileUrl(file)
        } 

        if (props.cloudinaryFileUrl && props.cloudinaryFileUrl.length) {
            setShowFileType(true)

            if (!fileType || (fileType && !fileType.length)) {
                const tempFileName = props.cloudinaryFileUrl.split('/').pop()
                const tempFileType = tempFileName.split('.').pop()
                if (tempFileType === 'csv') {
                    setFileType('csv')
                } else if (tempFileType === 'jpeg' || tempFileType === 'jpg' || tempFileType === 'png') {
                    setFileType('image')
                } else if (tempFileType === 'pdf') {
                    setFileType('pdf')
                }
            }
        }

        if (isUploadError || isUploaded || isUploading) {
            dispatch(resetCloudinary())
        }
        // eslint-disable-next-line 
    }, [isUploadError, isUploading, isUploaded, file, props.cloudinaryFileUrl])

    const onDrop = useCallback(async(acceptedFiles, fileRejections) => {
        if (acceptedFiles.length) {
            setError('')

            const files = acceptedFiles.map(file => file.path)
            setFilenames(files)
            
            for (let i = 0; i < files.length; i++) {
                const file = acceptedFiles[i]
                if (file.type === 'text/csv') {
                    setFileType('csv')
                    const text = await file.text()
                    const result = parse(text, {header: true})
                    dispatch(loadDataToImport(result.data))
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    setFileType('image')
                } else if (file.type === 'application/pdf') {
                    setFileType('pdf')
                }

                if ((
                    file.type === 'image/jpg' || 
                    file.type === 'image/png' || 
                    file.type === 'image/jpeg' || 
                    file.type === 'application/pdf'
                ) && props.endPoint) {
                    dispatch(uploadFileToCloudinary({
                        file: acceptedFiles[0], 
                        endPoint: props.endPoint
                    }))
                }
            }
        } else if (fileRejections.length) {
            console.log('FILE REJECTED => ', fileRejections)
            const format = []
            for (let key in props.supportedFiles) {
                format.push(key.split('/')[1])
            }

            setSupportedFiles(format)

            if (fileRejections.length > 1) {
                setError('too-many-files')
            } else {
                setError(fileRejections[0].errors[0].code)
                console.log(fileRejections[0].errors[0].code)
            }
        }
    }, [props.supportedFiles, dispatch])

    const [displayError, setDisplayError] = useState(error)

    useEffect(() => {
        setDisplayError(error)
        if (error) {
            dispatch(reset())
        }
    }, [error, dispatch])


    const {
        getRootProps, 
        getInputProps, 
        isDragActive
    } = useDropzone({
        accept: props.supportedFiles, 
        maxFiles: props.maxFiles || 1,
        maxSize: allowedSize,
        onDrop
    })

    const removeFile = () => {
        setFileType('')
        setFilenames('')
        dispatch(reset())
        setShowFileType(false)
        dispatch(resetCloudinary())
        props.setCloudinaryFileUrl('')
    }

    const viewFile = () => {
        if (props.cloudinaryFileUrl && props.cloudinaryFileUrl.length) {
            window.open(props.cloudinaryFileUrl, '_blank', 'noopener,noreferrer')
        }
    }

    return (
        <>
            {filenames.length || (props.cloudinaryFileUrl && props.cloudinaryFileUrl.length) ? (
                <Box sx={{display: 'flex', backgroundColor: lightGreen}} className={`fade-in ${props.className}`}>
                    {isUploading || !showFileType ? (
                        <HMSpiner 
                            size={30}
                            zIndex={999}
                            width='30px'
                            bgColor='transparent'
                            margin='auto 10px auto 0'
                        />
                    ) : showFileType ? (
                    <HMImage 
                            width='30px'
                            height='30px'
                            cursor='pointer'
                            handleClick={viewFile}
                            margin='auto 10px auto 0'
                            src={
                                fileType === 'csv' ? csv  
                                : fileType === 'pdf' ? pdf 
                                : fileType === 'image' ? props.cloudinaryFileUrl 
                                : ''
                            }
                        />
                    ) : <></>}
                    <Typography sx={filenameStyle}>{filenames[0] ? filenames[0] : props.cloudinaryFileUrl.split('/').pop()}</Typography>
                    <Typography 
                        component='span'
                        onClick={removeFile}
                        sx={cancelStyle}
                    >
                        <CloseIcon sx={{...iconStyle, fontSize: '8px'}} />
                    </Typography>
                </Box>
            ) : (
                <Box 
                    sx={{ backgroundColor: props.bgColor || lightBlue}}
                    className={`${props.className} ${isDragActive && 'drag-active'} fade-in`} 
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <Typography sx={textStyle}>Drop the image here ...</Typography> :
                            <Typography sx={textStyle}>
                                <UploadTwoToneIcon /> <br />
                                Drag 'n' drop your file here, or click to upload 
                            </Typography>
                    }
                </Box>
            )}
            
            {(displayError === 'too-many-files') ? (
                <Typography sx={errorStyle}>
                    Too many files! Add only up to <b>{props.maxFiles === undefined ? ' 1 file' : props.maxFiles + ' files'}</b>.
                </Typography>
            ) : displayError === 'file-invalid-type' ? (
                <Typography sx={errorStyle}>
                    {`Invalid file! - Supported file format : `}
                    {supportedFiles.map((file, index) => (
                        <Typography 
                            key={index}
                            component='span' 
                            sx={{...textStyle, color: green}}
                        >
                            {file} 
                            {index !== supportedFiles.length - 1 && ', '}
                        </Typography>
                    ))}
                </Typography>
            ) : displayError === 'file-too-large' ? (
                <Typography sx={errorStyle}>
                    File too large! Maximum size is : 
                    <Typography 
                        component='span' 
                        sx={{...textStyle, color: green}}
                    >
                        {` ${props.allowedSizeInMB}MB`}
                    </Typography>
                </Typography>
            ) : <></>}
        </>
    )
}

export default HMDropZone
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import ProgressBarWithData from '../../analytics/ProgressBarWithData'
import SimpleCardWithIcon from '../../analytics/SimpleCardWithIcon'
import RecentActivities from '../../analytics/RecentActivities'
import HMDonutChart from '../../analytics/HMDonutChart'
import DotAndLabel from '../../analytics/DotAndLabel'
import HMDataRange from '../../analytics/HMDataRange'
import SimpleCard from '../../analytics/SimpleCard'
import HMBox from '../../common/layout/HMBox'
import HMButton from '../../common/tools/HMButton'
import HMText from '../../common/tools/HMText'
import Title from '../../common/tools/Title'
import { 
    fontSize, 
} from '../../common/tools/Styles'
import insurance from '../../../assests/insurance.png'
import credit from '../../../assests/credit.png'
import wallet from '../../../assests/wallet.png'
import airtel from '../../../assests/airtel.png'
import visa from '../../../assests/visa.png'
import mtn from '../../../assests/mtn.png'
import {
    red,
    blue,
    white,
    green,
    darkBlue,
    lightBlue,
    lightBlack,
} from '../../../hooks/useColors'
import { currencies } from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const end_timestamp = Math.round(new Date().getTime() / 1000)
const currency = currencies.find(curr => curr.country === 'Rwanda').currency
const start_of_week_timestamp = end_timestamp - ((new Date().getDay()) * 24 * 3600)
const start_of_year_timestamp = Math.round(new Date(new Date().getFullYear(), 0, 1).getTime() / 1000)
const start_of_month_timestamp = Math.round(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() / 1000)

function PaymentChannels() {
    const { storeId } = useParams()
    const { storeSales } = useSelector((state) => state.store)
    const DONUT_CHART_LABELS = ['Online Payment', 'In-Store Payment']

    const skeletonData = {
        posRevenue: 0,
        totalSales: [], 
        totalRevenue: 0,
        onlineRevenue: 0,
        posPercentage: 0,
        onlinePercentage: 0, 
        successfullTransactions: 0,
        unsuccessfullTransactions: 0,
    }

    const [mtnData, setMTNData] = useState(skeletonData)
    const [cashData, setCashData] = useState(skeletonData)
    const [cardData, setCardData] = useState(skeletonData)
    const [airtelData, setAirtelData] = useState(skeletonData)
    const [creditData, setCreditData] = useState(skeletonData)
    const [insuranceData, setInsuranceData] = useState(skeletonData)

    const [buttonState, setButtonState] = useState('')
    const [currentData, setCurrentData] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    
    const [stateOfMTN, setStateOfMTN] = useState(null)
    const [stateOfCash, setStateOfCash] = useState(null)
    const [stateOfCard, setStateOfCard] = useState(null)
    const [stateOfCredit, setStateOfCredit] = useState(null)
    const [stateOfAirtel, setStateOfAirtel] = useState(null)
    const [stateOfInsurance, setStateOfInsurance] = useState(null)
    
    const [overallTotalRevenue, setOverallTotalRevenue] = useState(0)
    const [overallWeeklyRevenue, setOverallWeeklyRevenue] = useState(0)
    const [overallYearlyRevenue, setOverallYearlyRevenue] = useState(0)
    const [overallMonthlyRevenue, setOverallMonthlyRevenue] = useState(0)

    const {
        windowW
    } = useWindowDimensions()

    const isSmallTabletScreen = windowW < 670 ? true : false

    const handleChoosePayment = (method) => {
        setButtonState(method)
        if (method !== currentData[0].method) {
            const index = currentData.findIndex(item => item.method === method)
            const item = currentData.splice(index, 1)
            const tempNewData = item.concat(currentData)
            setCurrentData(tempNewData)
        } 
    }
    
    useEffect(() => {
        setCashData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfCash])

    useEffect(() => {
        setMTNData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfMTN])

    useEffect(() => {
        setAirtelData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfAirtel])

    useEffect(() => {
        setCreditData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfCredit])

    useEffect(() => {
        setCardData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfCard])

    useEffect(() => {
        setInsuranceData((prevState) => ({
            ...prevState,
            posPercentage: prevState.totalRevenue 
                ? Number(((prevState.posRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0,
            onlinePercentage: prevState.totalRevenue 
                ? Number(((prevState.onlineRevenue / prevState.totalRevenue) * 100).toFixed(2)) : 0
        }))
        // eslint-disable-next-line
    }, [stateOfInsurance])
    
    useEffect(() => {
        if(Object.keys(storeSales).length && storeSales.storeId === storeId){
            const tempCurrentData = extractData()
            setCurrentData(tempCurrentData)
            setPaymentMethods(tempCurrentData.map(data => data.method))
        }
        // eslint-disable-next-line
    }, [storeSales])

    const assignData = (itemData, start_timestamp, end_date_timestamp) => {
        const data = { ... skeletonData } 
        itemData.forEach(sale => {
            const status = sale.satus ? sale.satus.toLowerCase() : 'undefined'
            const db_timestamp = Math.round(new Date(sale.createdAt).getTime() / 1000)
            if ((db_timestamp >= start_timestamp) && (db_timestamp <= end_date_timestamp)){
                data['totalSales'] = data['totalSales'].concat([sale])
                data['totalRevenue'] = data['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    data.onlineRevenue += sale.orderTotal
                } else {
                    data.posRevenue += sale.orderTotal
                }
    
                if(status === 'canceled' || status === 'returned') {
                    data.unsuccessfullTransactions++
                } else {
                    data.successfullTransactions++
                }
            } else if(!start_timestamp && !end_date_timestamp) {
                data['totalSales'] = data['totalSales'].concat([sale])
                data['totalRevenue'] = data['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    data.onlineRevenue += sale.orderTotal
                } else {
                    data.posRevenue += sale.orderTotal
                }
    
                if(status === 'canceled' || status === 'returned') {
                    data.unsuccessfullTransactions++
                } else {
                    data.successfullTransactions++
                }
            }
        })

        return data
    }
    
    const filterSales = (start_timestamp, end_date_timestamp, index) => {
        const itemData = currentData[index].data
        const method = currentData[index].method

        if (method === 'cash') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setCashData(data)
        } else if (method === 'mtn') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setMTNData(data) 
        } else if (method === 'card') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setCardData(data)
        } else if (method === 'credit') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setCreditData(data)
        } else if (method === 'airtel') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setAirtelData(data)
        } else if (method === 'insurance') {
            const data = assignData(itemData, start_timestamp, end_date_timestamp)
            setInsuranceData(data)
        }
    }

    const extractData = () => {
        let tempOverallTotalRevenue = 0
        let tempOverallWeeklyRevenue = 0
        let tempOverallYearlyRevenue = 0
        let tempOverallMonthlyRevenue = 0
        const timeNow = new Date()
        const filterData = {
            isAllTime: true,
            isFilter: false,
            endDate: dayjs(),
            isThisWeek: false,
            isThisYear: false,
            openFilter: false,
            isThisMonth: false,
            startDate: dayjs(),
        }
        const tempCurrentData = []
        const tempMTNData = { ...skeletonData }
        const tempCashData = { ...skeletonData }
        const tempCardData = { ...skeletonData }
        const tempAirtelData = { ...skeletonData }
        const tempCreditData = { ...skeletonData }
        const tempInsuranceData = { ...skeletonData }
        
        storeSales.sales.forEach(sale => {
            const method = sale.paymentInfo.method.toLowerCase()
            const status = sale.satus ? sale.satus.toLowerCase() : 'undefined'
            const db_timestamp = Math.round(new Date(sale.createdAt).getTime() / 1000)

            tempOverallTotalRevenue += sale.orderTotal

            if ((db_timestamp >= start_of_week_timestamp) && (db_timestamp <= end_timestamp)) {
                tempOverallWeeklyRevenue += sale.orderTotal
            } 
            
            if ((db_timestamp >= start_of_month_timestamp) && (db_timestamp <= end_timestamp)) {
                tempOverallMonthlyRevenue += sale.orderTotal
            }
            
            if ((db_timestamp >= start_of_year_timestamp) && (db_timestamp <= end_timestamp)) {
                tempOverallYearlyRevenue += sale.orderTotal
            } 

            if (method.includes('cash') || method === '' || method === 'cod') {
                tempCashData['totalSales'] = tempCashData['totalSales'].concat([sale])
                tempCashData['totalRevenue'] = tempCashData['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    tempCashData.onlineRevenue += sale.orderTotal
                } else {
                    tempCashData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempCashData.unsuccessfullTransactions++
                } else {
                    tempCashData.successfullTransactions++
                }

            } else if (method.includes('mtn') || method === 'momo') {
                tempMTNData['totalSales'] = tempMTNData['totalSales'].concat([sale])
                tempMTNData['totalRevenue'] = tempMTNData['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    tempMTNData.onlineRevenue += sale.orderTotal
                } else {
                    tempMTNData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempMTNData.unsuccessfullTransactions++
                } else {
                    tempMTNData.successfullTransactions++
                }
            } else if (method.includes('insurance')) {
                tempInsuranceData['totalSales'] = tempInsuranceData['totalSales'].concat([sale])
                tempInsuranceData['totalRevenue'] = tempInsuranceData['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    tempInsuranceData.onlineRevenue += sale.orderTotal
                } else {
                    tempInsuranceData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempInsuranceData.unsuccessfullTransactions++
                } else {
                    tempInsuranceData.successfullTransactions++
                }
            } else if (method.includes('card')) {
                tempCardData['totalSales'] = tempCardData['totalSales'].concat([sale])
                tempCardData['totalRevenue'] = tempCardData['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    tempCardData.onlineRevenue += sale.orderTotal
                } else {
                    tempCardData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempCardData.unsuccessfullTransactions++
                } else {
                    tempCardData.successfullTransactions++
                }
            } else if (method.includes('airtel')) {
                tempAirtelData['totalSales'] = tempAirtelData['totalSales'].concat([sale])
                tempAirtelData['totalRevenue'] = tempAirtelData['totalRevenue'] + sale.orderTotal
                
                if(sale.type === 'online') {
                    tempAirtelData.onlineRevenue += sale.orderTotal
                } else {
                    tempAirtelData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempAirtelData.unsuccessfullTransactions++
                } else {
                    tempAirtelData.successfullTransactions++
                }
            } else if (method.includes('credit')) {
                tempCreditData['totalSales'] = tempCreditData['totalSales'].concat([sale])
                tempCreditData['totalRevenue'] = tempCreditData['totalRevenue'] + sale.orderTotal

                if(sale.type === 'online') {
                    tempCreditData.onlineRevenue += sale.orderTotal
                } else {
                    tempCreditData.posRevenue += sale.orderTotal
                }

                if(status === 'canceled' || status === 'returned') {
                    tempCreditData.unsuccessfullTransactions++
                } else {
                    tempCreditData.successfullTransactions++
                }
            }
        })
        
        if (tempCashData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'cash',
                data: tempCashData['totalSales'],
            })
        }
        if (tempCardData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'card',
                data: tempCardData['totalSales']
            })
        }
        if (tempMTNData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'mtn',
                data: tempMTNData['totalSales']
            })
        }
        if (tempAirtelData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'airtel',
                data: tempAirtelData['totalSales']
            })
        }
        if (tempCreditData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'credit',
                data: tempCreditData['totalSales']
            })
        }
        if (tempInsuranceData['totalSales'].length) {
            tempCurrentData.push({
                filterData,
                method: 'insurance',
                data: tempInsuranceData['totalSales']
            })
        }

        setMTNData(tempMTNData)
        setCashData(tempCashData)
        setCardData(tempCardData)
        setAirtelData(tempAirtelData)
        setCreditData(tempCreditData)
        setInsuranceData(tempInsuranceData)

        setStateOfMTN(timeNow)
        setStateOfCash(timeNow)
        setStateOfCard(timeNow)
        setStateOfCredit(timeNow)
        setStateOfAirtel(timeNow)
        setStateOfInsurance(timeNow)
        
        setOverallTotalRevenue(tempOverallTotalRevenue)
        setOverallWeeklyRevenue(tempOverallWeeklyRevenue)
        setOverallYearlyRevenue(tempOverallYearlyRevenue)
        setOverallMonthlyRevenue(tempOverallMonthlyRevenue)

        return tempCurrentData
    }

    const getValue = (method, total) => {
        if (total > 0) {
            if (method === 'cash') {
                return Number(((cashData.totalRevenue * 100) / total).toFixed(2)) 
            } else if (method === 'mtn') {
                return Number(((mtnData.totalRevenue * 100) / total).toFixed(2)) 
            } else if (method === 'card') {
                return Number(((cardData.totalRevenue * 100) / total).toFixed(2)) 
            } else if (method === 'credit') {
                return Number(((creditData.totalRevenue * 100) / total).toFixed(2))
            } else if (method === 'airtel') {
                return Number(((airtelData.totalRevenue * 100) / total).toFixed(2))
            } else if (method === 'insurance') {
                return Number(((insuranceData.totalRevenue * 100) / total).toFixed(2))
            } 
            return 0
        }
        return 0
    }

    const assignStateValue = (method, value) => {
        if (method === 'cash') {
            setStateOfCash(value)
        } else if (method === 'mtn') {
            setStateOfMTN(value)
        } else if (method === 'card') {
            setStateOfCard(value)
        } else if (method === 'credit') {
            setStateOfCredit(value)
        } else if (method === 'airtel') {
            setStateOfAirtel(value)
        } else if (method === 'insurance') {
            setStateOfInsurance(value)
        } 
    }

    const onAllTimeClick = (item, index) => {
        setCurrentData(currentData.map(data => { 
            if (data.method === item.method) {
                assignStateValue(item.method, 'all')
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isAllTime: true,
                        isFilter: false,
                        isThisWeek: false,
                        isThisYear: false,
                        openFilter: false,
                        isThisMonth: false,
                    }
                }
            } else {
                return data
            }
        }))
        filterSales(null, null, index)
    }
    const onThisWeekClick = (item, index) => {
        assignStateValue(item.method, 'week')
        setCurrentData(currentData.map(data => {
            if (data.method === item.method) {
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isAllTime: false,
                        isFilter: false,
                        isThisWeek: true,
                        isThisYear: false,
                        openFilter: false,
                        isThisMonth: false,
                    }
                }
            } else {
                return data
            }
        }))

        filterSales(start_of_week_timestamp, end_timestamp, index)
    }
    const onThisMonthClick = (item, index) => {
        assignStateValue(item.method, 'month')
        setCurrentData(currentData.map(data => {
            if (data.method === item.method) {
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isAllTime: false,
                        isFilter: false,
                        isThisWeek: false,
                        isThisYear: false,
                        openFilter: false,
                        isThisMonth: true,
                    }
                }
            } else {
                return data
            }
        }))
        
        filterSales(start_of_month_timestamp, end_timestamp, index)
    }
    const onThisYearClick = (item, index) => {
        assignStateValue(item.method, 'year')
        setCurrentData(currentData.map(data => {
            if (data.method === item.method) {
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isAllTime: false,
                        isFilter: false,
                        isThisWeek: false,
                        isThisYear: true,
                        openFilter: false,
                        isThisMonth: false,
                    }
                }
            } else {
                return data
            }
        }))
        
        filterSales(start_of_year_timestamp, end_timestamp, index)
    }
    const onFilter = (item) => {
        setCurrentData(currentData.map(data => {
            if (data.method === item.method) {
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isFilter: true,
                        isAllTime: false,
                        openFilter: true,
                        isThisWeek: false,
                        isThisYear: false,
                        isThisMonth: false,
                    }
                }
            } else {
                return data
            }
        }))
    }

    const handleCloseFilter = (item) => {
        setCurrentData(currentData.map(data => {
            if (data.method === item.method){
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        isFilter: true,
                        isAllTime: false,
                        isThisWeek: false,
                        isThisYear: false,
                        openFilter: false,
                        isThisMonth: false,
                    }
                }
            } else {
                return data
            }
        }))
    }
    
    const handleSartDateChange = (newValue, item) => {
        setCurrentData(currentData.map(data => {
            if(data.method === item.method){
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        startDate: newValue,
                    }
                }
            } else {
                return data
            }
        }))
    }
    const handleEndDateChange = (newValue, item) => {
        setCurrentData(currentData.map(data => {
            if(data.method === item.method){
                return {
                    ...data,
                    filterData: {
                        ...data.filterData,
                        endDate: newValue,
                    }
                }
            } else {
                return data
            }
        }))
    }
    const handleCustomFilter = (item, index) => {
        const filterData = currentData[index].filterData
        const end_date_timestamp = Math.round(new Date(filterData.endDate.$d).getTime() / 1000)
        const start_date_timestamp = Math.round(new Date(filterData.startDate.$d).getTime() / 1000)
        filterSales(start_date_timestamp, end_date_timestamp, index)
        
        assignStateValue(item.method, new Date())
        handleCloseFilter(item)
    }

    return (    
        <HMBox
            width='100%'
            padding='10px'
            display='flex'
            className='fed-in'
            bgColor={lightBlue}
            flexDirection='column'
            borderRadius='0 0 8px 8px'
        >
            <HMBox
                width='100%'
                padding='20px'
                display='flex'
                bgColor={white}
                margin='10px auto auto auto'
            >
                <HMButton 
                    text='All'
                    borderRadius={10}
                    padding='5px 10px'
                    hoverColor={white}
                    hoverBgColor={darkBlue}
                    margin='auto 5px auto 0'
                    paddingActive='4.5px 9.5px'
                    fontSize={`${fontSize - 2}px`}
                    border={`1px solid ${darkBlue}`}
                    handleClick={ () => setButtonState('') }
                    color={buttonState === '' ? white : darkBlue}
                    bgColor={buttonState === '' ? darkBlue : white}
                />
                {paymentMethods.map((item, index) => (
                    <HMButton 
                        key={index} 
                        text={item}
                        margin='auto 5px'
                        borderRadius={10}
                        padding='5px 10px'
                        hoverColor={white}
                        hoverBgColor={darkBlue}
                        paddingActive='4.5px 9.5px'
                        fontSize={`${fontSize - 2}px`}
                        border={`1px solid ${darkBlue}`}
                        color={item === buttonState ? white : darkBlue}
                        bgColor={item === buttonState ? darkBlue : white}
                        handleClick={ () => handleChoosePayment(item) }
                    />
                ))}
                <HMButton 
                    borderRadius={10}
                    text='Make report'
                    padding='5px 10px'
                    className='report-btn'
                    handleClick={ () => {} }
                    margin='auto 0 auto auto'
                    paddingActive='4.5px 9.5px'
                    fontSize={`${fontSize - 2}px`}
                />
            </HMBox>
            {currentData.map((item, index) => (
                <Grid 
                    container 
                    key={index}
                    spacing={0}
                    width='100%'
                    sx={{ 
                        padding: '10px',
                        borderRadius: '8px',
                        margin: '20px auto',
                        backgroundColor: white 
                    }}
                >
                    <Grid 
                        item xs={12} md={7} 
                        sx={{ paddingBottom: '40px', display: 'flex', flexDirection: 'column' }} 
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='10px auto'
                        >
                            <img 
                                alt='wallet'
                                style={{
                                    width: '80px',
                                    height: '40px',
                                    margin: 'auto 0',
                                    borderRadius: '8px',
                                }}
                                src={
                                    item.method === 'mtn' ? mtn
                                    : item.method === 'card' ? visa
                                    : item.method === 'cash' ? wallet
                                    : item.method === 'airtel' ? airtel
                                    : item.method === 'insurance' ? insurance
                                    : credit
                                } 
                            />
                            <Title 
                                height='auto'
                                color={darkBlue}
                                fontWeight={500}
                                size={fontSize + 4}
                                margin='auto auto auto 10px'
                                title={
                                    item.method === 'cash' ? 'Cash Payment'
                                    : item.method === 'card' ? 'Card Payment'
                                    : item.method === 'mtn' ? 'MTN Mobile Money'
                                    : item.method === 'airtel' ? 'Airtel Mobile Money'
                                    : item.method === 'insurance' ? 'Insurance Payment'
                                    : 'Credit Payment'
                                } 
                            />
                        </HMBox>
                    </Grid>
                    <Grid 
                        item xs={12} md={5} 
                        sx={{ paddingBottom: '40px', display: 'flex', flexDirection: 'column' }} 
                    >
                        <HMDataRange 
                            onFilter={() => onFilter(item)}
                            endDate={item.filterData.endDate}
                            isFilter={item.filterData.isFilter}
                            startDate={item.filterData.startDate}
                            isAllTime={item.filterData.isAllTime}
                            openFilter={item.filterData.openFilter}
                            isThisWeek={item.filterData.isThisWeek}
                            isThisYear={item.filterData.isThisYear}
                            isThisMonth={item.filterData.isThisMonth}
                            handleCloseFilter={() => handleCloseFilter(item)}
                            onAllTimeClick={() => onAllTimeClick(item, index)}
                            onThisYearClick={() => onThisYearClick(item, index)}
                            onThisWeekClick={() => onThisWeekClick(item, index)}
                            onThisMonthClick={() => onThisMonthClick(item, index)}
                            handleCustomFilter={() => handleCustomFilter(item, index)}
                            handleEndDateChange={(newValue) => handleEndDateChange(newValue, item)}
                            handleSartDateChange={(newValue) => handleSartDateChange(newValue, item)}
                        />
                    </Grid> 
                    <Grid 
                        item xs={12} md={7} 
                        sx={{ display: 'flex', flexDirection: 'column', padding: '0 10px 0 0' }} 
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            margin='auto'
                            height='260px'
                            display='flex' 
                        >
                            <HMDonutChart 
                                name_show={true}
                                total_show={true}
                                colors={[ blue, green ]}
                                labels={DONUT_CHART_LABELS}  
                                data={
                                    item.method === 'mtn' ? [mtnData.onlinePercentage, mtnData.posPercentage]
                                    : item.method === 'cash' ? [cashData.onlinePercentage, cashData.posPercentage]
                                    : item.method === 'card' ? [cardData.onlinePercentage, cardData.posPercentage]
                                    : item.method=== 'credit' ? [creditData.onlinePercentage, creditData.posPercentage]
                                    : item.method === 'airtel' ? [airtelData.onlinePercentage, airtelData.posPercentage]
                                    : item.method === 'insurance' && [insuranceData.onlinePercentage, insuranceData.posPercentage]
                                } 
                            />
                            <HMBox 
                                width='auto'
                                display='flex'
                                flexDirection='column'
                            >
                                <HMText 
                                    fontWeight={400} 
                                    color={lightBlack}
                                    text='Total Payment' 
                                    margin='auto auto -5px 0'
                                    fontSize={`${fontSize + 4}px`}
                                />
                                <Title 
                                    margin='0'
                                    textAlign='left'
                                    color={darkBlue}
                                    size={isSmallTabletScreen ? fontSize + 10 : fontSize + 26}
                                    title ={`${currency} ${(
                                            item.method === 'cash' ? cashData.totalRevenue
                                            : item.method === 'mtn' ? mtnData.totalRevenue
                                            : item.method === 'card' ? cardData.totalRevenue
                                            : item.method === 'airtel' ? airtelData.totalRevenue
                                            : item.method === 'credit' ? creditData.totalRevenue
                                            : item.method === 'insurance' ? insuranceData.totalRevenue
                                            : 0
                                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                    }
                                />
                                <HMBox 
                                    padding='0'
                                    width='100%'
                                    display='flex'
                                    margin='-5px auto auto 0'
                                >
                                    <DotAndLabel
                                        dotColor={blue}
                                        color={lightBlack}
                                        fontSize={fontSize - 2}
                                        title={'Online Payment'}
                                        margin='auto 10px auto 0'
                                    />
                                    <DotAndLabel
                                        dotColor={green}
                                        color={lightBlack}
                                        fontSize={fontSize - 2}
                                        title='In-Store Payment'
                                    />
                                </HMBox>
                            </HMBox>
                        </HMBox>
                    </Grid>
                    <Grid 
                        item xs={12} md={5} 
                        sx={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 10px' }} 
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='20px auto 10px auto'
                        >
                            <Title 
                                height='auto'
                                color={darkBlue}
                                fontWeight={500}
                                size={fontSize + 4}
                                margin='auto auto auto 0'
                                title={
                                    item.method === 'cash' ? 'Cash Payment Distribution'
                                    : item.method === 'card' ? 'Card Payment Distribution'
                                    : item.method === 'mtn' ? 'MTN Mobile Money Distribution'
                                    : item.method === 'airtel' ? 'Airtel Mobile Money Distribution'
                                    : item.method === 'insurance' ? 'Insurance Payment Distribution'
                                    : 'Credit Payment Distribution'
                                } 
                            />
                        </HMBox>       
                        <ProgressBarWithData 
                            title='Online Payment'
                            value={
                                item.method === 'mtn' ? mtnData.onlinePercentage 
                                : item.method === 'cash' ? cashData.onlinePercentage
                                : item.method === 'card' ? cardData.onlinePercentage
                                : item.method === 'credit' ? creditData.onlinePercentage
                                : item.method === 'airtel' ? airtelData.onlinePercentage
                                : item.method === 'insurance' && insuranceData.onlinePercentage
                            }
                            amount={
                                item.method === 'mtn' ? mtnData.onlineRevenue 
                                : item.method === 'cash' ? cashData.onlineRevenue
                                : item.method === 'card' ? cardData.onlineRevenue
                                : item.method === 'credit' ? creditData.onlineRevenue
                                : item.method === 'airtel' ? airtelData.onlineRevenue
                                : item.method === 'insurance' && insuranceData.onlineRevenue
                            }
                        />        
                        <ProgressBarWithData 
                            strokeColor={green}
                            title='In-store Payment'
                            value={
                                item.method === 'mtn' ? mtnData.posPercentage 
                                : item.method === 'cash' ? cashData.posPercentage
                                : item.method === 'card' ? cardData.posPercentage
                                : item.method === 'credit' ? creditData.posPercentage
                                : item.method === 'airtel' ? airtelData.posPercentage
                                : item.method === 'insurance' && insuranceData.posPercentage
                            }
                            amount={
                                item.method === 'mtn' ? mtnData.posRevenue 
                                : item.method === 'cash' ? cashData.posRevenue
                                : item.method === 'card' ? cardData.posRevenue
                                : item.method === 'credit' ? creditData.posRevenue
                                : item.method === 'airtel' ? airtelData.posRevenue
                                : item.method === 'insurance' && insuranceData.posRevenue
                            }
                        />        
                    </Grid>   
                    <Grid 
                        item xs={12} md={7} 
                        sx={{ display: 'flex', flexDirection: 'column', padding: '0 10px 0 0' }} 
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='20px auto 10px auto'
                        >
                            <Title 
                                height='auto'
                                color={darkBlue}
                                fontWeight={500}
                                size={fontSize + 4}
                                margin='auto auto auto 0'
                                title={
                                    item.method === 'cash' ? 'Cash Payment Statistics'
                                    : item.method === 'card' ? 'Card Payment Statistics'
                                    : item.method === 'mtn' ? 'MTN Mobile Money Statistics'
                                    : item.method === 'credit' ? 'Credit Payment Statistics'
                                    : item.method === 'airtel' ? 'Airtel Mobile Money Statistics'
                                    : item.method === 'insurance' && 'Insurance Payment Statistics'
                                } 
                            />
                        </HMBox> 
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='5px auto'
                        >
                            <SimpleCard 
                                margin='auto 5px auto 0'
                                firstText='Successful Transactions'
                                secondText={`${(
                                    item.method === 'cash' ? cashData.successfullTransactions
                                    : item.method === 'mtn' ? mtnData.successfullTransactions
                                    : item.method === 'card' ? cardData.successfullTransactions
                                    : item.method === 'airtel' ? airtelData.successfullTransactions
                                    : item.method === 'credit' ? creditData.successfullTransactions
                                    : item.method === 'insurance' ? insuranceData.successfullTransactions
                                    : 0
                                ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            />
                            <SimpleCard 
                                bgColor={red}
                                margin='auto 0 auto 5px'
                                firstText='Unsuccessful Transactions'
                                secondText={`${(
                                    item.method === 'cash' ? cashData.unsuccessfullTransactions
                                    : item.method === 'mtn' ? mtnData.unsuccessfullTransactions
                                    : item.method === 'card' ? cardData.unsuccessfullTransactions
                                    : item.method === 'airtel' ? airtelData.unsuccessfullTransactions
                                    : item.method === 'credit' ? creditData.unsuccessfullTransactions
                                    : item.method === 'insurance' ? insuranceData.unsuccessfullTransactions
                                    : 0
                                ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            />
                        </HMBox>
                        {!item.filterData.isFilter ? (
                            <HMBox
                                padding='0'
                                width='100%'
                                display='flex'
                                margin='5px auto'
                            >
                                <SimpleCardWithIcon  
                                    isGreen={true}
                                    firstText='Amount'
                                    margin='auto 5px auto 0'
                                    secondText={`${currency} ${(
                                        item.filterData.isAllTime ? overallTotalRevenue
                                        : item.filterData.isThisWeek ? overallWeeklyRevenue
                                        : item.filterData.isThisMonth ? overallMonthlyRevenue
                                        : item.filterData.isThisYear ? overallYearlyRevenue 
                                        : 0
                                    ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                />
                                <SimpleCardWithIcon  
                                    firstText='Percentage'
                                    margin='auto 0 auto 5px'
                                    secondText={`${(
                                        item.filterData.isAllTime ? getValue(item.method, overallTotalRevenue) 
                                        : item.filterData.isThisWeek ? getValue(item.method, overallWeeklyRevenue) 
                                        : item.filterData.isThisMonth ? getValue(item.method, overallMonthlyRevenue) 
                                        : item.filterData.isThisYear ? getValue(item.method, overallYearlyRevenue)  
                                        : 0
                                    )}%`}
                                />
                            </HMBox>   
                        ) : <></>}
                    </Grid> 
                    <Grid 
                        item xs={12} md={5} 
                        sx={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 10px' }} 
                    >  
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='20px auto 10px auto'
                        >
                            <Title 
                                height='auto'
                                color={darkBlue}
                                fontWeight={500}
                                size={fontSize + 4}
                                margin='auto auto auto 0'
                                title='Recent Activities'
                            />
                        </HMBox>  
                        <RecentActivities 
                            margin='0'
                            limit={item.filterData.isFilter ? 3 : 6}
                            activities={
                                item.method === 'mtn' ? mtnData.totalSales
                                : item.method === 'cash' ? cashData.totalSales
                                : item.method === 'card' ? cardData.totalSales
                                : item.method === 'airtel' ? airtelData.totalSales
                                : item.method === 'credit' ? creditData.totalSales
                                : item.method === 'insurance' && insuranceData.totalSales
                            }
                            map={{
                                type: 'type',
                                date: 'createdAt',
                                amount: 'orderTotal',
                                paymentMethod: 'paymentInfo'
                            }}
                        />          
                    </Grid>  
                </Grid>
            ))}
        </HMBox>
    )

}

export default PaymentChannels
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone'
import HMPaymentOption from './HMPaymentOption'
import HMBox from './HMBox'
import HMSwitchButton from '../tools/HMSwitchButton'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize,
    textStyle,
} from '../tools/Styles'
import {
    currencies,
    storeDelivery,
} from '../../../hooks/helperFunctions'
import {
    gray,
    blue,
    white,
    orange,
    darkBlue,
    lightGreen,
    lightGray,
    lightBlack,
    skeletonsLightBlue,
} from '../../../hooks/useColors'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMOrderComputation(props) {

    const leftText = `${storeDelivery[0].type} ${props.paymentMethod ? 'delivery' : ''}: ${currency} ${storeDelivery[0].cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    const rightText = `${storeDelivery[1].type} ${props.paymentMethod ? 'delivery' : ''}: ${currency}  ${storeDelivery[1].cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    
    return (
        <HMBox
            width='100%'
            display='flex'
            color={darkBlue}
            className='fade-in'
            margin={props.margin}
            flexDirection='column'
            maxWidth={props.maxWidth || '400px'}
            bgColor={props.bgColor || skeletonsLightBlue}
        >
            {!props.paymentMethod ? (
                <Title 
                    width='100%'
                    textAlign='center'
                    margin={props.isCheckout ? '20px auto' : 'auto'}
                    title={props.isCheckout ? 'Order Summary' : 'Delivery'}
                />  
            ) : <></>}
            {!props.isCheckout ? (
                <>
                    <HMSwitchButton 
                        btnBgColor={blue}
                        bgColor={lightGray}
                        leftText={leftText}
                        rightText={rightText}
                        btnColor={lightBlack}
                        btnActiveColor={white}
                        margin='20px auto 0 auto'
                        leftClick={props.handleNormalDelivery}
                        width={props.paymentMethod ? 350 : 260}
                        rightClick={props.handleUrgentDelivery}
                        isLeft={props.deliveryType === storeDelivery[0].type}
                    />
                    <HMBox
                        padding='0'
                        display='flex'
                        margin='10px auto'        
                    >
                        <AccessTimeTwoToneIcon sx={{fontSize: `${fontSize + 6}px` , margin: 'auto 5px auto auto'}} />
                        <HMText
                            color={blue}
                            fontWeight={500}
                            margin='auto auto auto 0'
                            fontSize={`${fontSize - 2}px`} 
                            text={storeDelivery.find(data => data.type === props.deliveryType).duration}
                        />
                    </HMBox>
                    <Divider width='90%' sx={{ borderBottom: `2px solid ${lightGray}`, margin: 'auto' }} />
                </>
            ) : <></>}
            <HMBox
                width='100%'
                display='flex'
                padding='0 10px'
                margin='10px auto'        
            >
                <Title 
                    size={fontSize}
                    title='Subtotal'
                    margin='auto auto auto 0'
                />
                <Title
                    size={fontSize}
                    textAlign='right'
                    margin='auto 0 auto auto'
                    title={`${currency} ${props.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
            </HMBox>  
            <HMBox
                width='100%'
                margin='auto'        
                display='flex'
                padding='0 10px'
            >
                <HMText 
                    width='max-content'
                    text='Delivery&nbsp;fee'
                    margin='auto 10px auto 0'
                    fontSize={`${fontSize - 2}px`} 
                />
                <Tooltip 
                    arrow
                    placement='top' 
                    sx={{ margin: 'auto 0' }}
                    title='Delivery fee is computated based on the proximity and number of stores' 
                >
                <HelpOutlineTwoToneIcon 
                    sx={{
                        cursor: 'pointer',
                        color: lightBlack,
                        fontSize: `${fontSize + 2}px`
                    }} 
                />
                </Tooltip> 
                <HMText
                    width='100%'
                    margin='auto 0'
                    textAlign='right'
                    fontSize={`${fontSize - 2}px`} 
                    text={
                        `${props.numberOfStores === 1 
                            ? '(1 store)' 
                            : props.numberOfStores > 1 
                                ? `(${props.numberOfStores} stores)` 
                                : ''}  
                                ${currency} ${props.deliveryCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }
                />
            </HMBox>  
            <HMBox
                width='100%'
                margin='auto'        
                display='flex'
                padding='0 10px'
            >
                <HMText 
                    text='Tax'
                    margin='auto 10px auto 0'
                    fontSize={`${fontSize - 2}px`} 
                />
                <Tooltip 
                    arrow
                    placement='bottom' 
                    sx={{ margin: 'auto 0' }}
                    title='Tax is included in the product cost' 
                >
                <HelpOutlineTwoToneIcon 
                    sx={{
                        cursor: 'pointer',
                        color: lightBlack,
                        fontSize: `${fontSize + 2}px`
                    }} 
                />
                </Tooltip>
                <HMText
                    width='100%'
                    textAlign='right'
                    margin='auto 0 auto auto'
                    fontSize={`${fontSize - 2}px`} 
                    text={`${currency} ${props.tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
            </HMBox>     
            <HMBox
                width='100%'
                display='flex'
                padding='0 10px'
                margin='20px auto'        
            > 
                <Divider width='100%' sx={{ borderBottom: `2px dashed ${gray}`, margin: 'auto' }} />
            </HMBox>  
            <HMBox
                width='100%'
                margin='auto'        
                display='flex'
                padding='0 10px'
            >
                <Title 
                    title='Total'
                    margin='auto auto auto 0'
                />
                <Title
                    textAlign='right'
                    margin='auto 0 auto auto'
                    title={`${currency} ${props.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
            </HMBox> 
            {props.paymentMethod ? (
                <HMPaymentOption 
                    isEdit={true}
                    bgColor={lightGreen}
                    hoverBgColor={lightGreen}
                    margin='20px 12px 0px 12px'
                    paymentInfo={props.confirmedPaymentInfo}
                    handleChangePaymentOption={props.handleChangePaymentOption}
                />
            ) : <></>} 
            <HMBox
                width='100%'
                display='flex'
                padding='0 10px'
                margin='20px auto'        
            > 
                <HMButton 
                    width='100%'
                    type='button'
                    padding='7.5px'
                    bgColor={orange}
                    paddingActive='7px'
                    margin='auto 0 auto auto'
                    disabled={props.isDisabled}
                    handleClick={props.handleProceed}
                    text={<Typography sx={{...textStyle, fontWeight: 500}}>{props.actionMessage}</Typography>} 
                />
            </HMBox>    
        </HMBox>
    )
}

export default HMOrderComputation
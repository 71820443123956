import React from 'react'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
  fontSize
} from '../common/tools/Styles'
import {
  lightBlack,
} from '../../hooks/useColors'

function PaymentMethodDetails(props) {
  return (
    <HMBox
      display='flex'
      minWidth='300px'
      className='fade-in'
      height={props.height}
      border={props.border}
      flexDirection='column'
      bgColor={props.bgColor}
      margin={props.margin || '5px 0'}
      width={props.width || 'match-content'}
      opacity={props.notClickable ? 0.7 : 1} 
      cursor={props.notClickable ? 'not-allowed' : 'pointer'} 
      handleClick={props.notClickable ? () => {} : props.handleClick}
      hoverBgColor={props.notClickable ? props.bgColor : props.hoverBgColor}
    >
      <HMBox
        padding='0'
        width='100%'
        display='flex'
      >
        {props.leftIcon}
        <HMBox
          padding='0'
          display='flex'
          margin='auto 0'
          flexDirection='column'
        >
          <HMText 
            fontWeight={500}
            text={props.upperText}
            fontSize={`${fontSize}px`}
            color={props.color || 'black'}
            margin={props.isBig ? '5px 0' : 'auto 0'}
          />
          <HMText 
            text={props.lowerText}
            color={props.color || lightBlack}
            margin={props.isBig ? '5px 0' : 'auto 0'}
            fontSize={`${props.isBig ? fontSize : fontSize - 3}px`}
          />
        </HMBox>
        {props.beforeRightIcon}
        {props.rightIcon}
      </HMBox>
      {props.children ? (
        <HMBox
          padding='0'
          width='100%'
          display='flex'
        >
          {props.children}
        </HMBox>
      ) : <></>}
    </HMBox>
  )
}

export default PaymentMethodDetails
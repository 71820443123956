import React from 'react'
import { Line } from 'rc-progress'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'
import {
    blue,
    white,
    lightBlack,
    lightGray,
} from '../../hooks/useColors'
import {
    currencies
} from '../../hooks/helperFunctions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function ProgressBarWithData(props) {
  return (
    <HMBox
        display='flex'
        height={props.height}
        flexDirection='column'
        bgColor={props.bgColor}
        color={props.color || white}
        width={props.width || '100%'}
        margin={props.margin || 'auto'}
    >
        <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='auto auto 0 auto'
        >
            <HMText 
                fontWeight={600}
                color={lightBlack}
                text={props.title}
                margin='auto auto auto 0'
                fontSize={`${fontSize + 2}px`}
            /> 
             <HMText 
                fontWeight={600}
                color={lightBlack}
                margin='auto 0 auto auto'
                fontSize={`${fontSize + 2}px`}
                text={`${currency} ${props.amount ? props.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}`}
            /> 
        </HMBox>
        <HMBox 
            padding='0'
            width='100%'
            display='flex'
            flexDirection='column'
            margin='0 auto auto auto'
        >
            <HMText 
                color={lightBlack}
                margin='auto auto 0 0'
                fontSize={`${fontSize}px`}
                text={`${props.value || 0}%`}
            /> 
            <Line 
                trailWidth={2}
                strokeWidth={2} 
                percent={props.value || 0} 
                strokeColor={props.strokeColor || blue}
                trailColor={props.trailColor || lightGray}
            />
        </HMBox>
    </HMBox>
  )
}

export default ProgressBarWithData
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { fontSize } from '../../../components/common/tools/Styles'

const HMMultipleRadialBar = (props) => {
    
    const   options= {
        chart: {
            width: "100%",
            type: 'radialBar',
            offsetX: -140,
        },
        title: {
            text: props.title || 'Insurance activity',
            align: 'left',
            offsetX: 140,
            style: {
            fontSize:  fontSize + 2,
            fontWeight:  '700',
            color:  'rgba(47, 56, 84, 0.7)',
            },
        },
        series: [76, 67, 61],
        plotOptions: {
            radialBar: {
            dataLabels: {
                total: {
                offsetY: 2,
                show: true,
                label: ''
                },
                name: {
                offsetY: 5,
                show: false,
                color: "#888",
                fontSize: fontSize
                },
            }
            }
        },
        colors: props.MultColor || ['#F78800', '#00AEEF', '#16D39A'],
        labels: props.MultLabel || ['MMI', 'RAMA', 'Mutuel de sante'],
        legend: {
            show: true,
            floating: true,
            fontSize: fontSize,
            position: 'right',
            verticalAlign: 'center',
            offsetX: -40,
            offsetY: 50,
            labels: {
            useSeriesColors: true,
            },
            markers: {
            size: 0
            },
            formatter: function(seriesName, opts) {
            return seriesName + "          " + opts.w.globals.series[opts.seriesIndex] + "%"
            },
            itemMargin: {
            vertical: 4
            }
        },
        responsive: [
            {
            breakpoint: 480,
            options: {
                chart: {
                width: 200,
                offsetX: 60,
                },
                title: {
                    offsetX: 20,
                },
                legend: {
                position: 'bottom',
                },
            },
            },
        ],
        }
    return (
        <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
        type="radialBar"
        />
    )
    }
  export default HMMultipleRadialBar;
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'

const initialState = {
    lat: null,
    lng: null
}

export const setLocation = createAsyncThunk(
    'location/setLocation',
    async (data) => {
        return data
    }
)

export const location = createSlice({
    name: 'location',
    initialState,
    reducers: {
        reset: (state) => {
            state.lat = null
            state.lng = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLocation.fulfilled, (state, action) => {
                state.lat = action.payload.lat
                state.lng = action.payload.lng
            })
    }
})

export const { reset } = location.actions
export default location.reducer

import axios from 'axios'
import _ from 'lodash'

const API_URL_ME = '/api/v1/users/me'

// Get me
const getMe = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_ME, config)

    const data = response.data
    
    const me = JSON.parse(localStorage.getItem('me'))

    if (me && !_.isEqual(data, me)) {
        localStorage.setItem('me', JSON.stringify(data))
    }
    
    return data
}

// Update me
const updateMe = async (updatedMe, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const response = await axios.post(API_URL_ME, updatedMe, config)

    const data = response.data
    
    const me = JSON.parse(localStorage.getItem('me'))

    if (data && !_.isEqual(data, me)) {
        localStorage.setItem('me', JSON.stringify(data))
    }

    return data
} 

// Delete me
const deleteMe = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(API_URL_ME, config)

    const data = response.data

    return data
}

const meService = {
    updateMe,
    deleteMe,
    getMe
}
export default meService

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BeforeLogin from '../../components/common/layout/BeforeLogin'
import OTPForm from '../../components/common/layout/OTPForm'

function VerifyOtp() {
  const navigate = useNavigate()
  const { 
    isLoading, 
    transition,
  } = useSelector((state) => state.auth)

  const path = window.location.pathname

  useEffect(() => {
    if (!transition || !Object.keys(transition).length) {
      navigate(
        path.includes('admin') ? '/admin/login'
        : path.includes('store') ? '/store/login'
        : path.includes('supplier') ? 'supplier/login'
        : '/login'
      )
    }
    // eslint-disable-next-line
  }, [])

  return transition && Object.keys(transition).length ? (
    <BeforeLogin
      isLoading={isLoading}
      title='OTP (One Time Password)'
    >
      <OTPForm />
    </BeforeLogin>
  ) : <></>
}

export default VerifyOtp

import React from 'react'
import HMBox from './HMBox'
import HMLoginModal from './HMLoginModal'
import HMText from '../tools/HMText'
import {
  fontSize,
} from '../tools/Styles'

function HMLoginFirst(props) {
  return (
    <>
      <HMLoginModal />
      <HMBox
        display='flex'
        width={props.width || '100%'}
        height={props.height || '65vh'}
      >
        <HMText 
          margin='auto'
          fontWeight={700}
          text={props.message}
          fontSize={`${fontSize + 2}px`}
        />
      </HMBox>
    </>
  )
}

export default HMLoginFirst
import React, { useState } from 'react'
import { Typography, Box, Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import CircleIcon from '@mui/icons-material/Circle'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Title from '../../components/common/tools/Title'
import HMText from '../../components/common/tools/HMText'
import HMButton from '../../components/common/tools/HMButton'
import { textStyle } from '../../components/common/tools/Styles'
import { lightBlack, orange, lightOrange } from '../../hooks/useColors'
import SmsModel from './SmsModel'

function TwoFactor() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Page>
      <FullWidthContainer>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
        >
          <Grid item sm={10} sx={{ marginBottom: '50px' }}>
            <HMText
              text="2FA settings"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Manage your two factor authentication settings as an extra layer of security to your account"
              padding="12px 12px"
              size="16px"
            />
            <Divider width="100%" />
          </Grid>

          <Grid item container spacing={10} xs={12} sm={10}>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box 
                sx={{
                  background: lightOrange,
                  borderRadius: '50%',
                  marginRight: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  height: '30px',
                }}
              >
                <CircleIcon
                  sx={{ color: orange, width: '20px', margin: '2px 0 0 5px' }}
                />
              </Box>

              <Grid item >
                <Title size="20px" title="SMS Authentication" />
                <HMText
                  color={lightBlack}
                  fontSize="16px"
                  fontWeight="500"
                  float="right"
                  margin="10px 0"
                  text="By enabling this, you are allowing us to send you a one time code to your phone number  for you to use everytime you login or authorize different actions under your account. "
                />
              </Grid>
            </Grid>

            <Grid item sm={4} xs={12} >
              <HMButton
                float="initial"
                fontSize="16px"
                width="30%"
                borderRadius="10px"
                margin="6px auto"
                type="button"
                bgColor={orange}
                handleClick={handleOpen}
                text={<Typography sx={textStyle}>Enable </Typography>}
              />
            </Grid>
            <SmsModel open={open} onClose={handleClose} />
          </Grid>

          <Grid item container spacing={10} sm={10} sx={{ marginTop: '10px' }}>
            <Grid
              item
              sm={8}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  background: lightOrange,
                  borderRadius: '50%',
                  marginRight: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  height: '30px',
                }}
              >
                <CircleIcon
                  sx={{ color: orange, width: '20px', margin: '2px 0 0 5px' }}
                />
              </Box>

              <Box>
                <Title size="20px" title="Google 2 factor Authentication" />
                <HMText
                  color={lightBlack}
                  fontSize="16px"
                  fontWeight="500"
                  float="right"
                  margin="10px 0"
                  text="This will require you to use you third party app (Google 2fa) to authorize yourself everytime you login or need to authorize different actions under your account."
                />
              </Box>
            </Grid>

            <Grid item sm={4} xs={12}>
              <HMButton
                float="initial"
                fontSize="16px"
                width="30%"
                borderRadius="10px"
                margin="6px auto"
                type="button"
                bgColor={orange}
                text={<Typography sx={textStyle}>Enable </Typography>}
              />
            </Grid>
          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  )
}

export default TwoFactor

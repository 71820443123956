import React from 'react'
import IconButton from '@mui/material/IconButton'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from '../../components/common/layout/HMBox'
import HMTextField from '../../components/common/tools/HMTextField'
import { 
  fontSize,
} from '../../components/common/tools/Styles'

function StepOne(props) {
    const {
        email,
        lastName,
        firstName,
    } = props.formData 

    return (
        <>
            <HMBox
                padding='0'
                width='100%'
                margin='auto'
                display='flex'
                className='fade-in'
            >
                <HMTextField
                    required={true}
                    name='firstName'
                    value={firstName}
                    label='First name'
                    onChange={props.handleChange}
                    placeholder='Enter your first name'
                    disabled={!props.isFirstNameEditable}
                />
                <IconButton   
                    sx={{margin: 'auto 0 auto 5px'}}
                    onClick={() => props.handleEnableChange('firstName')} 
                >
                    <DriveFileRenameOutlineOutlinedIcon 
                        sx={{
                            margin: 'auto',
                            fontSize: `${fontSize}px`,
                        }}
                    />
                </IconButton>
            </HMBox>
            <HMBox
                padding='0'
                width='100%'
                margin='auto'
                display='flex'
                className='fade-in'
            >
                <HMTextField 
                    type='text'
                    margin='auto'
                    name='lastName'
                    required={true}
                    value={lastName}
                    label='Last name'
                    onChange={props.handleChange}
                    disabled={!props.isLastNameEditable}
                    placeholder='Enter your last name'
                />
                <IconButton   
                    sx={{margin: 'auto 0 auto 5px'}}
                    onClick={() => props.handleEnableChange('lastName')} 
                >
                    <DriveFileRenameOutlineOutlinedIcon 
                        sx={{
                            margin: 'auto',
                            fontSize: `${fontSize}px`,
                        }}
                    />
                </IconButton>
            </HMBox>
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='15px auto'
                className='fade-in'
            >
                <HMTextField 
                    type='text'
                    name='email'
                    margin='auto'
                    value={email}
                    label='Email'
                    required={true}
                    onChange={props.handleChange}
                    disabled={!props.isEmailEditable}
                    placeholder='Enter your email'
                />
            </HMBox>
        </>
    )
}

export default StepOne
import axios from 'axios'
const API_URL_ORDER = '/api/v1/orders'
const API_URL_MY_ORDER = '/api/v1/orders/me'
const API_URL_STORE_ORDER = '/api/v1/orders/store'

// Create order
const createOrder = async (newOrder, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL_ORDER, newOrder, config)

    const data = response.data

    return data 
}
// Get order
const getOrder = async (orderId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_ORDER}/${orderId}`, config)

    const data = response.data

    return data 
}
// Get my order
const getMyOrder = async (orderId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_MY_ORDER}/${orderId}`, config)

    const data = response.data

    return data 
}
// Get store order
const getStoreOrder = async (storeId, orderId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_STORE_ORDER}/${storeId}/${orderId}`, config)

    const data = response.data

    return data 
}
// Get orders
const getOrders = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_ORDER, config)

    const data = response.data

    return data 
}
// Get my orders
const getMyOrders = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_MY_ORDER, config)

    const data = response.data

    return data 
}
// Get store orders
const getStoreOrders = async (storeId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_STORE_ORDER}/${storeId}`, config)

    const data = response.data

    return data 
}
// Update order
const updateOrder = async (updatedOrder, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(`${API_URL_ORDER}/${updatedOrder._id}`, updatedOrder, config)

    const data = response.data

    return data 
}
// Update my order
const updateMyOrder = async (updatedOrder, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const response = await axios.post(`${API_URL_MY_ORDER}/${updatedOrder._id}`, updatedOrder, config)

    const data = response.data

    return data 
}
// Update store order
const updateStoreOrder = async (obj, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const {
        storeId, 
        orderId, 
        updatedOrder
    } = obj

    const response = await axios.post(`${API_URL_STORE_ORDER}/${storeId}/${orderId}`, updatedOrder, config)

    const data = response.data

    return data 
}
// Delete order
const deleteOrder = async (orderId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(`${API_URL_ORDER}/${orderId}`, config)

    const data = response.data

    return data 
}
// order my order
const deleteMyOrder = async (orderId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(`${API_URL_MY_ORDER}/${orderId}`, config)

    const data = response.data

    return data 
}

const orderService = {
    getOrder,
    getOrders,
    getMyOrder,
    getMyOrders,
    createOrder,
    updateOrder,
    deleteOrder,
    updateMyOrder,
    deleteMyOrder,
    getStoreOrder,
    getStoreOrders,
    updateStoreOrder
}

export default orderService

import Typography from '@mui/material/Typography';
import useColors from '../../../hooks/useColors';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import HMText from '../../../components/common/tools/HMText'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import {
    fontSize,
    iconStyle,
  } from '../../../components/common/tools/Styles'
  const { 
    red,
} = useColors
  

const HMAvatar = (props) => {

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          backgroundColor: props.backBadge || '#44b700',
          color: props.colorBadge || '#44b700',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '80%',
            height: '80%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
          },
        },
      }));


    return (
        <Box display="flex"
        gap={2}
        marginBottom={1}
        
  >
    <Stack direction="row" spacing={2}margin >
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar sx={{width: '70px', height:'70px',borderRadius:"16px", background: props.backAvatar || "#D6FFF3", flexDirection:"column"}}  alt="Remy Sharp" variant="rounded">
            <Typography fontWeight="700" fontSize={`${fontSize + 2}px`} color="black">{props.date}</Typography>
            <Typography fontSize={`${fontSize - 1}px`} color="black">{props.day}</Typography>
        </Avatar>
      </StyledBadge>
    </Stack>
    <Box>
      <HMText
            float="none"
            fontSize={`${fontSize - 1}px`}
            padding={1}
            text={props.event}
        />  
      <Box display="flex">
        <LocationOnOutlinedIcon sx={{...iconStyle, color: red}} />
        <HMText
            fontSize={`${fontSize - 1}px`}
            text={props.location}
        />  
      </Box>
    </Box>
    
</Box>
  )
}

export default HMAvatar
import React from 'react'
import ReactApexChart from 'react-apexcharts'

 const HMDonutChart = (props) => {
    const options = {
      chart: {
        width: '100%',
        type: 'donut',
      },
      colors:props.colors || ['#00AEEF', '#16D39A'],
      labels: props.labels,
      legend: {
        position: 'right',
        verticalAlign: 'center',
        //  offsetX: 1,
        offsetY: 5,
      },
      plotOptions: {
        pie: {
          dataLabels: {
              offset: -3,
          }, 
          customScale: 1.1,
          donut: {
            size: props.donutSize || '60%',
            labels: {
              show: true,
              total: {
                show: true,
                fontSize: '13px',
                display: 'none',
                //label: `M`,
               // formatter: () => 'M',
              },
              name: {
                show: false,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: props.showDataLabels,
        // formatter: function (val) {
        //   return val + 'M'
        // },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
  
    return (
      <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
        type="donut"
      />
    )
  }

  export default HMDonutChart;
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import { 
    resetCart,
    updateCart,
} from '../../features/cart/cartSlice'
import { 
  resetOrder,
  getMyOrder, 
  updateMyOrder
} from '../../features/orders/orderSlice'
import HMInsuranceCard from '../../components/checkout/HMInsuranceCard'
import AddressDetails from '../../components/checkout/AddressDetails'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import HMInsuranceConfirmation from '../../components/common/layout/HMInsuranceConfirmation'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMOrderComputation from '../../components/common/layout/HMOrderComputation'
import HMPaymentMethods from '../../components/common/layout/HMPaymentMethods'
import HMPaymentOptions from '../../components/common/layout/HMPaymentOptions'
import HMViewAddedInfo from '../../components/common/layout/HMViewAddedInfo'
import HMPaymentMethod from '../../components/common/layout/HMPaymentMethod'
import HMOrderDetail from '../../components/common/layout/HMOrderDetail'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMDropZone from '../../components/common/tools/HMDropZone'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMImage from '../../components/common/tools/HMImage'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  currencies,
  cloudinary,
  getFileType,
  storeDelivery,
  orderActivityObj,
  computateOrderData,
  validatePhoneNumber,
  paymentMethodDetails,
//   computeInsuredSubtotal,
} from '../../hooks/helperFunctions'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../components/common/tools/Styles'
import pdf from '../../assests/pdf.png'
import csv from '../../assests/csv.png'
import {
  blue,
  gray,
  white,
  orange,
  darkBlue,
  lightBlue,
  lightBlack,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../hooks/useColors'

const paymentDummyData = [
    {
      value: '4544 04** **** 0145',
      method: 'Credit/Debit Card',
    }, {
      value: '4544 04** **** 0144',
      method: 'Credit/Debit Card',
    }, {
      value: '+250 788 888 888',
      method: 'MTN Mobile Money',
    }, {
      value: '+250 722 222 222',
      method: 'Airtel Mobile Money',
    }
]
const cashInfoSekeleton = {
    value: 'cash',
    method: 'Cash On Delivery',
}
const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function MyOrder({ socket }) {
    const { myOrderId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { 
        cart,
        isUpdated,
        isLoading,
    } = useSelector((state) => state.cart)
    const { 
        user 
    } = useSelector((state) => state.auth)
    const orderState = useSelector((state) => state.order)
    const { 
        myOrder,
        isMyOrder,
        isMyOrderUpdated
    } = orderState
    
    const [title, setTitle] = useState('')
    const [state, setState] = useState('')
    const [open, setOpen] = useState(false)
    const [currentOrder, setCurrentOrder] = useState({})
    const [isCopayment, setIsCopayment] = useState(false)
    const [isUpdateOrder, setIsUpdateOrder] = useState(false)
    const [customerPayment, setCustomerPayment] = useState(0)
    const [insurancePayment, setInsurancePayment] = useState(0)
    const [isViewAddedInfo, setIsViewAddedInfo] = useState(false)
    const [currentAddedInfo, setCurrentAddedInfo] = useState(null)
    const [currentOrderDetail, setCurrentOrderDetail] = useState({})
    const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
    const [isAddPaymentMethod, setIsAddPaymentMethod] = useState(false)
    const [deliveryType, setDeliveryType] = useState(storeDelivery[0].type)
    const [isClientConfirmation, setIsClientConfirmation] = useState(false)
    const [isChangePrescription, setIsChangePrescription] = useState(false)
    const [specificPaymentMethod, setSpecificPaymentMethod] = useState(null)
    const [isChangePaymentMethod, setIsChangePaymentMethod] = useState(false)
    const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(cashInfoSekeleton)
    const [confirmedPaymentInfo, setConfirmedPaymentInfo] = useState(cashInfoSekeleton)
    const [isAddSpecificPaymentMethod, setIsAddSpecificPaymentMethod] = useState(false)
    const [isAfterInsuranceValidation, setIsAfterInsuranceValidation] = useState(false)
    const [confirmedCopaymentInfo, setConfirmedCopaymentInfo] = useState(cashInfoSekeleton)
    const [insuranceDeliveryType, setInsuranceDeliveryType] = useState(storeDelivery[0].type)
    const [airtelFormData, setAirtelFormData] = useState({
      name: '',
      save: true,
      airtelNumber: '',
    })
    const [cardFormData, setCardFormData] = useState({
      cvv: '',
      cardNumber: '',
      nameOnCard: '',
      save: true,
      cardExpirationDate: '',
    })
    const [mtnFormData, setMtnFormData] = useState({
      name: '',
      save: true,
      mtnNumber: '',
      isValid: false
    })
    const [orderComputation, setOrderComputation] = useState({
        total: 0,
        subtotal: 0,
        deliveryCost: 0,
        numberOfStores: 0,
        insuranceCoverage: 0
    })
    const {
        total,
        subtotal,
        deliveryCost,
        numberOfStores,
        insuranceCoverage
    } = orderComputation

    const {
        windowW,
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    const breadOptions = [
        { text: 'Home', link: '/landing' },
        { text: 'My Orders', link: '/myorders' },
        { text: 'My Order', link: null }
    ]

    useEffect(() => {
        socket.on('store_updates_order', (data) => {
            if (data.orderId === myOrderId) {
                dispatch(getMyOrder(myOrderId))
            }
        })
        socket.on('assigned_order', (data) => {
            // console.log('Rececived the ASSINED ORDER')
            if (data.orderId === myOrderId) {
                dispatch(getMyOrder(myOrderId))
            }
        })
        socket.on('order', (data) => {
            // console.log('Rececived the ORDER socket')
            if (data._id === myOrderId) {
                dispatch(getMyOrder(myOrderId))
            }
        })
        // eslint-disable-next-line
    }, [socket])
    
    useEffect(() => {
        if (user) {
            dispatch(getMyOrder(myOrderId))
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (isUpdateOrder) {
            const deliveryOption = storeDelivery.find(data => data.type === deliveryType)
            const updatedOrder = {
                _id: currentOrder._id,
                orderDetails: currentOrder.orderDetails.map(orderDetail => ({
                    ...orderDetail,
                    deliveryOption
                }))
            }
            dispatch(updateMyOrder(updatedOrder))
            setIsUpdateOrder(false)
        }
        // eslint-disable-next-line
    }, [isUpdateOrder])

    useEffect(() => {
        if (isMyOrder || isMyOrderUpdated) {
            setCurrentOrder(myOrder)
            setOrderComputation(computateOrderData(myOrder))
            if ('orderActivityStack' in myOrder && myOrder.orderActivityStack.length) {
                const tempState = myOrder.orderActivityStack[myOrder.orderActivityStack.length - 1].state
                if (tempState === 'intial_user_confirmation' && isMyOrder) {
                    setOpen(true)
                    setIsClientConfirmation(true)
                    setTitle('Confirming My Order')
                    setDeliveryType(myOrder.orderDetails[0].deliveryOption.type)
                }
                setState(tempState)
            }

            if (
                'insuranceInfo' in myOrder && 
                Object.keys(myOrder.insuranceInfo).length && 
                'storeValidation' in myOrder.insuranceInfo
                ) {
                let tempId = ''
                for (let key in myOrder.insuranceInfo.storeValidation) {
                    const vlidation = myOrder.insuranceInfo.storeValidation[key]
                    if ('userConfirmed' in vlidation && !vlidation.userConfirmed) {
                        tempId = key
                        break
                    } 
                }

                if (tempId) {
                    for (let i = 0; i < myOrder.orderDetails.length; i++) {
                        const orderDetail = myOrder.orderDetails[i]
                        if (orderDetail.store === tempId) {
                            handleActionAfterInsuranceValidation(orderDetail)
                            break
                        }
                    }
                }
            }

            dispatch(resetOrder())
        }

        if (isUpdated) {
            dispatch(resetCart())
            navigate('/cart')
        }
        // eslint-disable-next-line
    }, [isMyOrder, isMyOrderUpdated,  isUpdated])

    const navegateToProductPage = (item) => {
        navigate(`/categories/${item.categoryParentId}/${item.categoryId}/${item.product}`)
    }
    const navegateToStorePage = (storeId) => {
        navigate(`/stores/${storeId}`)
    }

    const handleNormalDelivery = () => {
        setIsUpdateOrder(true)
        setDeliveryType(storeDelivery[0].type)
    }
    const handleUrgentDelivery = () => {
        setIsUpdateOrder(true)
        setDeliveryType(storeDelivery[1].type)
    }

    const handleViewAddedInfoModal = (addedInfo) => {
        setOpen(true)
        setIsViewAddedInfo(true)
        setCurrentAddedInfo(addedInfo)
        setTitle('Additional Information')
    }

    const handleViewPrescription = (prescription) => {
        window.open(prescription, '_blank', 'noopener,noreferrer')
    }

    const handleChangePrescriptionModal = () => {
        setOpen(true)
        setIsChangePrescription(true)
        setTitle('Change The Prescription')
        setPrescriptionFileUrl(currentOrder.prescription)
    }
    const handleChangePrescription = () => {
        const updatedOrder = {
            _id: myOrderId,
            prescription: prescriptionFileUrl
        }
        dispatch(updateMyOrder(updatedOrder))
        handleClose()
    }

    const handleUserInteractionModal = () => {
        setOpen(true)
        setIsCopayment(false)
        setIsAddPaymentMethod(false)
        setIsClientConfirmation(true)
        setIsChangePaymentMethod(false)
        setTitle('Confirming My Order')
        setIsAddSpecificPaymentMethod(false)
    }
    const handleUserInteraction = (e) => {
        e.stopPropagation()
        handleUserInteractionModal()
    }
    const handleChangePaymentOptionModal = () => {
        setOpen(true)
        setIsAddPaymentMethod(false)
        setIsClientConfirmation(false)
        setIsChangePaymentMethod(true)
        setTitle('Select Payment Method')
    }
    const handleSetPaymentInfo = (info) => {
        setSelectedPaymentInfo(info)
    }
    const handleSelectPaymentInfo = (e) => {
        e.stopPropagation()
        let tempPayInfo = null
        const value = e.target.value
        paymentDummyData.forEach(item => {
          if (item.value === value) {
            tempPayInfo = item
          }
        })
        
        if (tempPayInfo) {
          setSelectedPaymentInfo(tempPayInfo)
        } else {
          setSelectedPaymentInfo({
            value: 'cash',
            method: 'Cash On Delivery'
          })
        }
    }
    const handleAddPaymentMethodModal = () => {
        setOpen(true)
        setIsAddPaymentMethod(true)
        setTitle('Add Payment Method')
        setIsChangePaymentMethod(false)
        setIsAddSpecificPaymentMethod(false)
    }
    const handleAddPaymentMethodConfirm = (e) => {
        // setConfirmedPaymentInfo(selectedPaymentInfo)
        setConfirmedPaymentInfo(cashInfoSekeleton)
        if (isCopayment) {
            handleActionAfterInsuranceValidationModal(e, currentOrderDetail)
        } else {
            handleUserInteractionModal()
        }
    }
    const handleAddSpecificPaymentMethodModal = (type) => {
        const tempPaymentMethod = paymentMethodDetails.find(item => item.type === type)
        setIsAddPaymentMethod(false)
        setTitle(tempPaymentMethod.title)
        setIsAddSpecificPaymentMethod(true)
        setSpecificPaymentMethod(tempPaymentMethod)
    }
    const handleCardDataChange = (e) => {
        const value = e.target.value
        const name = e.target.name
    
        setCardFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }))
    }
    const handleMtnDataChange = (e) => {
        const name = e.target.name
        const value = e.target.value
    
        if (name === 'mtnNumber' && validatePhoneNumber(value) !== null) {
          const {
            isValid,
            phoneNumber
          } = validatePhoneNumber(value)
          
          setMtnFormData((prevState) => ({
            ...prevState,
            isValid,
            mtnNumber: phoneNumber,
          }))
        }  
        
        if (name !== 'mtnNumber') {
          setMtnFormData((prevState) => ({
            ...prevState,
            [name]: value,
          }))
        }
    }
    const handleAirtelDataChange = (e) => {
        const name = e.target.name
        const value = e.target.value
    
        if (name === 'airtelNumber' && validatePhoneNumber(value) !== null) {
          const {
            isValid,
            phoneNumber
          } = validatePhoneNumber(value)
          
          setMtnFormData((prevState) => ({
            ...prevState,
            isValid,
            airtelNumber: phoneNumber,
          }))
        }  
        
        if (name !== 'airtelNumber') {
          setAirtelFormData((prevState) => ({
            ...prevState,
            [name]: value,
          }))
        }
    }
    const handleSavePaymentMethod = () => {
        if (specificPaymentMethod.type === 'card') {
          setCardFormData((prevState) => ({
            ...prevState,
            save: !cardFormData.save,
          }))
        } else if (specificPaymentMethod.type === 'mtn') {
          setMtnFormData((prevState) => ({
            ...prevState,
            save: !mtnFormData.save,
          }))
        } else if (specificPaymentMethod.type === 'airtel') {
          setAirtelFormData((prevState) => ({
            ...prevState,
            save: !airtelFormData.save,
          }))
        }
    }
    const handleConfirmOrder = () => {
        const orderActivityStack = [ ...currentOrder.orderActivityStack ]
        orderActivityStack.push({
            updatedBy: null,
            updatedAt: Date.now(),
            state: isCopayment ? 'at_store_final' : 'placed',
        })

        const updatedOrder = {
            orderActivityStack,
            _id: currentOrder._id, 
        }

        if (isCopayment) {
            const storeValidation = { ...currentOrder.insuranceInfo.storeValidation }

            storeValidation[currentOrderDetail.store] = {
                ...storeValidation[currentOrderDetail.store],
                userConfirmed: true,
            } 

            updatedOrder['insuranceInfo'] = {
                ...currentOrder.insuranceInfo,
                storeValidation
            }
            
            updatedOrder['copaymentInfo'] = {
                paid: false,
                method: confirmedCopaymentInfo.method,
            }
        } 
        dispatch(updateMyOrder(updatedOrder))
        handleClose() 
    }
    const handleCancelOrder = () => {
        const orderActivityStack = [ ...currentOrder.orderActivityStack ]
        orderActivityStack.push({
            updatedBy: null,
            state: 'canceled',
            updatedAt: Date.now(),
        })

        const updatedOrder = {
            orderActivityStack,
            _id: currentOrder._id, 
        }

        const storeValidation = { ...currentOrder.insuranceInfo.storeValidation }

        storeValidation[currentOrderDetail.store] = {
            ...storeValidation[currentOrderDetail.store],
            userConfirmed: true,
        } 
        updatedOrder['insuranceInfo'] = {
            ...currentOrder.insuranceInfo,
            storeValidation
        }

        updatedOrder.orderDetails = currentOrder.orderDetails.map(orderDetail => ({
            ...orderDetail,
            status: orderDetail.store === currentOrderDetail.store ? 'Canceled' : orderDetail.status
        }))

        dispatch(updateMyOrder(updatedOrder))
        handleClose() 
    }
    const handleActionAfterInsuranceValidation = (orderDetail) => {
        setOpen(true)
        setIsCopayment(true)
        setIsAddPaymentMethod(false)
        setIsClientConfirmation(false)
        setIsChangePaymentMethod(false)
        setCurrentOrderDetail(orderDetail)
        setIsAddSpecificPaymentMethod(false)
        setIsAfterInsuranceValidation(true)
        setTitle('Confirm Order After Insurance Validation')
        setInsuranceDeliveryType(orderDetail.deliveryOption.type)

        const {
            coverage
        } = myOrder.insuranceInfo.storeValidation[orderDetail.store]

        let tempCustomer = 0
        let tempInsurance = 0
        orderDetail.orderItems.forEach(item => {
            if (orderDetail.approvedInsurance) {
                const cost = item.quantity * item.insurancePrice
                const insPayment = (cost * coverage) / 100
                tempInsurance += insPayment
                tempCustomer += cost - insPayment
            } else {
                tempCustomer = item.quantity * item.onlinePrice
            }
        })
        setInsurancePayment(tempInsurance)
        setCustomerPayment(tempCustomer + orderDetail.deliveryOption.cost)
    }
    const handleActionAfterInsuranceValidationModal = (e, orderDetail) => {
        e.stopPropagation()
        handleActionAfterInsuranceValidation(orderDetail)
    }
    const handleInsuranceNormalDelivery = () => {
        setInsuranceDeliveryType(storeDelivery[0].type)
        const updatedOrder = {
            _id: currentOrder._id,
            orderDetails: currentOrder.orderDetails.map(orderDetail => ({
                ...orderDetail,
                deliveryOption: currentOrderDetail.store === orderDetail.store 
                    ? storeDelivery[0] : orderDetail.deliveryOption
            }))
        }
        dispatch(updateMyOrder(updatedOrder))
        setCustomerPayment(customerPayment - storeDelivery[1].cost + storeDelivery[0].cost)
    }
    const handleInsuranceUrgentDelivery = () => {
        setInsuranceDeliveryType(storeDelivery[1].type)
        const updatedOrder = {
            _id: currentOrder._id,
            orderDetails: currentOrder.orderDetails.map(orderDetail => ({
                ...orderDetail,
                deliveryOption: currentOrderDetail.store === orderDetail.store 
                    ? storeDelivery[1] : orderDetail.deliveryOption
            }))
        }
        dispatch(updateMyOrder(updatedOrder))
        setCustomerPayment(customerPayment - storeDelivery[0].cost + storeDelivery[1].cost)
    }
    const handleClose = () => {
        setTitle('')
        setOpen(false)
        setIsCopayment(false)
        setIsViewAddedInfo(false)
        setPrescriptionFileUrl('')
        setIsAddPaymentMethod(false)
        setIsClientConfirmation(false)
        setIsChangePrescription(false)
        setIsChangePaymentMethod(false)
        setIsAddSpecificPaymentMethod(false)
        setIsAfterInsuranceValidation(false)
    }

    const handleOrderAgain = (orderItems) => {
        const cartItems = []
        orderItems.forEach(item => {
            if (!cart.cartItems.find(cartItem => cartItem.storeListing === item.listing)) {
                cartItems.push({
                    quantity: 1,
                    storeListing: item.listing,
                })
            }
        })
        dispatch(updateCart({cartItems}))
    }

    const supportedFiles = {
        'image/png': [],
        'image/jpg': [],
        'image/jpeg': [],
        'application/pdf': [],
    }
    
    return user ? (
        <Page>
            {(
                orderState.isLoading ||
                !Object.keys(currentOrder).length || 
                (isLoading && Object.keys(currentOrder).length)  
            ) ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <FullWidthContainer
                display='flex'
            >
                <HMBreadcrumbs 
                    options={breadOptions}
                    margin='auto auto auto 0'
                />
                <HMBox 
                    padding='0'
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={orange}
                        isResponsive={true}
                        margin='auto 0 auto auto'
                        handleClick={() => navigate(-1)}
                        text={<Typography sx={textStyle}>Back</Typography>} 
                        icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    />
                </HMBox>
            </FullWidthContainer>
            <HMModal 
                open={open} 
                title={title}
                maxHeight='95vh'
                handleClose={handleClose} 
                width={isTabletScreen ? '90vw' : '550px'}
                maxWidth={isTabletScreen ? '95vw' : '600px'}
            >
                {orderState.isLoading ? (
                    <HMSpiner 
                        size={60}
                        width='100%'
                        zIndex={999}
                        height='50vh'
                        position='absolute'
                        bgColor='transparent'
                        margin='auto auto auto -20px'
                    />
                ) : <></>}
                {isViewAddedInfo ? (
                    <HMViewAddedInfo 
                        addedInfo={currentAddedInfo}
                    />
                ) : isChangePrescription ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        minWidth={!isTabletScreen && '500px'}
                    >
                        <HMDropZone 
                            allowedSizeInMB={1}
                            className='drag-drop' 
                            supportedFiles={supportedFiles} 
                            cloudinaryFileUrl={prescriptionFileUrl}
                            endPoint={cloudinary.API_URL_PRESCRIPTION}
                            setCloudinaryFileUrl={setPrescriptionFileUrl}
                        />
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='15px auto 0 auto'
                            className='change-store-button fade-in'
                        >
                            <HMButton 
                                type='button'
                                width='100px'
                                bgColor={gray}
                                fontWeight={500}
                                margin='auto 10px auto 0'
                                handleClick={handleClose}
                                text={<Typography sx={textStyle}>Cancel</Typography>}
                            />
                            <HMButton 
                                type='button'
                                width='100px'
                                color={white}
                                bgColor={blue} 
                                margin='auto 0 auto auto'
                                disabled={!prescriptionFileUrl}
                                handleClick={handleChangePrescription}
                                text={
                                    <Typography sx={textStyle}>
                                        Submit
                                    </Typography>
                                }
                            />
                        </HMBox>
                    </HMBox>
                ) : isClientConfirmation ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        flexDirection='column'
                    >
                        <HMOrderComputation 
                            tax={0}
                            total={total}
                            maxWidth='100%'
                            margin='10px auto'
                            subtotal={subtotal}
                            paymentMethod='Method'
                            deliveryCost={deliveryCost}
                            deliveryType={deliveryType}
                            numberOfStores={numberOfStores}
                            isDisabled={orderState.isLoading}
                            handleProceed={handleConfirmOrder}
                            actionMessage='I confirm the order'
                            confirmedPaymentInfo={confirmedPaymentInfo}
                            handleNormalDelivery={handleNormalDelivery}
                            handleUrgentDelivery={handleUrgentDelivery}
                            handleChangePaymentOption={handleChangePaymentOptionModal}
                        /> 
                    </HMBox>
                ) : isChangePaymentMethod ? (
                    <HMPaymentOptions 
                        isBack={true}
                        paymentInfo={paymentDummyData}
                        selectedPaymentInfo={selectedPaymentInfo}
                        handleSelectPaymentInfo={handleSelectPaymentInfo}
                        handleAddPaymentMethodModal={handleAddPaymentMethodModal}
                        handleConfirmPaymentMethod={handleAddPaymentMethodConfirm}
                        handleSetPaymentInfo={(info) => handleSetPaymentInfo(info)}
                        handleExit={
                            isCopayment 
                                ? (e) => handleActionAfterInsuranceValidationModal(e, currentOrderDetail)
                                : handleUserInteractionModal
                        }
                    />
                ) : isAddPaymentMethod ? (
                    <HMPaymentMethods 
                        isShowBackIcon={true}
                        handleBack={handleChangePaymentOptionModal}
                        handleAddPayment={(method) => handleAddSpecificPaymentMethodModal(method)}
                    />
                ) : isAddSpecificPaymentMethod ? (
                    <HMPaymentMethod 
                        mtnFormData={mtnFormData}
                        cardFormData={cardFormData}
                        airtelFormData={airtelFormData}
                        paymentType={specificPaymentMethod.type}
                        handleMtnDataChange={handleMtnDataChange}
                        handleCardDataChange={handleCardDataChange}
                        handleAirtelDataChange={handleAirtelDataChange}
                        handleSavePaymentMethod={handleSavePaymentMethod}
                        handleAddPaymentMethodModal={handleAddPaymentMethodModal}
                        handleAddPaymentMethodConfirm={handleAddPaymentMethodConfirm}
                    />
                ) : isAfterInsuranceValidation ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        flexDirection='column'
                    >
                        <HMInsuranceConfirmation 
                            margin='10px auto'
                            orderDetail={currentOrderDetail}
                            customerPayment={customerPayment}
                            isDisabled={orderState.isLoading}
                            handleProceed={handleConfirmOrder}
                            actionMessage='I confirm the order'
                            insurancePayment={insurancePayment}
                            handleCancelOrder={handleCancelOrder}
                            insuranceInfo={currentOrder.insuranceInfo}
                            insuranceDeliveryType={insuranceDeliveryType}
                            confirmedCopaymentInfo={confirmedCopaymentInfo}
                            handleChangePaymentOption={handleChangePaymentOptionModal}
                            handleInsuranceNormalDelivery={handleInsuranceNormalDelivery}
                            handleInsuranceUrgentDelivery={handleInsuranceUrgentDelivery}
                        /> 
                    </HMBox>
                ) : <></>}
            </HMModal>
            {(Object.keys(currentOrder).length) ? (
                <Section
                    padding={10}
                    bgColor={white}
                >
                    {(
                        state === 'picked' || 
                        state === 'received' || 
                        state === 'intial_user_confirmation'
                    )  ? (
                        <HMBox
                            width='100%'
                            display='flex'
                            padding='20px 10px'
                            bgColor={skeletonsLightBlue}
                            flexDirection={isTabletScreen ? 'column' : 'row'}
                            cursor={
                                state === 'intial_user_confirmation'
                                    ? 'pointer' 
                                    : 'normal'
                            }
                            handleClick={
                                state === 'intial_user_confirmation'
                                    ? handleUserInteractionModal 
                                    : () => {}
                            }
                            hoverBgColor={
                                state === 'intial_user_confirmation'
                                    ? lightBlue 
                                    : skeletonsLightBlue
                            }
                        >
                            <HMText 
                                maxWidth='100%'
                                fontWeight={400}
                                width='max-content'
                                margin='auto auto auto 0'
                                fontSize={`${fontSize + 1}px`}
                                text={orderActivityObj[state].client.message}
                            />
                            <HMText 
                                color={white}
                                fontWeight={500}
                                padding='5px 10px'
                                bgColor={darkBlue}
                                width='max-content'
                                fontSize={`${fontSize}px`}
                                text={orderActivityObj[state].client.action}
                                margin={isTabletScreen ? 'auto 0 auto auto' : 'auto 0 auto 10px'}
                                handleClick={
                                    state === 'intial_user_confirmation'
                                        ? handleUserInteraction 
                                        : () => {}
                                }
                                hoverBgColor={
                                    state === 'intial_user_confirmation'
                                        ?  '#2f3854e8' 
                                        :  darkBlue
                                }
                            />
                        </HMBox>
                    ) : <></>}
                    <HMBox
                        width='100%'
                        display='flex'
                        className='fade-in'
                    >
                        <HMBox
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            margin='auto auto auto 0'
                        >
                            <Title 
                                size={fontSize + 2}
                                margin='auto auto auto 0'
                                title={currentOrder.orderNumber}
                            />
                            <HMText 
                                color={lightBlack}
                                margin='auto auto auto 0'
                                fontSize={`${fontSize - 1}px`}
                                text={moment(currentOrder.createdAt).utc().format('MMM DD, YYYY  h:mm:ss a')}
                            />
                        </HMBox>
                        <HMBox
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            margin='auto 0 auto auto'
                        >
                            <HMBox
                                padding='0'
                                width='100%'
                                display='flex'
                                margin='auto 0 auto auto'
                            >
                                <HMText 
                                    text='Payment Method :'
                                    fontSize={`${fontSize}px`}
                                    margin='auto 5px auto auto'
                                />
                                <HMText 
                                    color={white}
                                    bgColor={blue}
                                    padding='0 5px'
                                    margin='auto 0 auto 5px'
                                    fontSize={`${fontSize - 1}px`}
                                    text={
                                        insuranceCoverage 
                                        ? currentOrder.copaymentInfo.method 
                                        : currentOrder.paymentInfo.method
                                    }
                                />
                            </HMBox>
                            <HMBox
                                padding='0'
                                width='100%'
                                display='flex'
                                margin='5px 0 auto auto'
                            >
                                <HMText 
                                    text='Order Total :'
                                    fontSize={`${fontSize}px`}
                                    margin='auto 5px auto auto'
                                />
                                <HMText 
                                    color={orange}
                                    padding='0 5px'
                                    fontWeight={500}
                                    margin='auto 0 auto 5px'
                                    fontSize={`${fontSize + 2}px`}
                                    text={`${currency} ${(total - insuranceCoverage).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                />
                            </HMBox>
                        </HMBox>
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        className='fade-in'
                        flexDirection={isTabletScreen ? 'column' : 'row'}
                    >
                        <AddressDetails 
                            width='100%'
                            desableChnageAddress={true}
                            bgColor={skeletonsLightBlue}
                            addressInfo={currentOrder.deliveryInfo}
                            margin={isTabletScreen ? '10px auto 5px auto' : '10px 5px 10px 0'}
                        />
                        {currentOrder.insuranceInfo && Object.keys(currentOrder.insuranceInfo).length ? (
                            <HMInsuranceCard 
                                isOrderPage={true}
                                coverage={insuranceCoverage}
                                bgColor={skeletonsLightBlue}
                                insuranceInfo={currentOrder.insuranceInfo}
                                insurer={currentOrder.insuranceInfo.insurer}
                                margin={isTabletScreen ? '5px auto 10px auto' : '10px 0 10px 5px'}
                            />
                        ) : <></>}
                    </HMBox>
                    <HMBox
                        width='100%'
                        className='fade-in'
                        padding='10px 5px 5px'
                        bgColor={seeThroughLightBlue}
                    >
                        {('prescription' in currentOrder && currentOrder.prescription) ? (() => {
                            const fileType = getFileType(currentOrder.prescription)
                            return (
                                <HMBox
                                    width='100%'
                                    display='flex'
                                    bgColor={white}
                                    margin='auto auto 10px auto'
                                    border={`1px solid ${lightBlue}`}
                                >
                                    <HMImage 
                                        width='50px'
                                        height='50px'
                                        cursor='pointer'
                                        borderRadius='8px'
                                        margin='auto 10px auto 0'
                                        handleClick={() => handleViewPrescription(currentOrder.prescription)}
                                        src={
                                            fileType === 'csv' ? csv  
                                            : fileType === 'pdf' ? pdf 
                                            : fileType === 'image' ? currentOrder.prescription 
                                            : ''
                                        }
                                    />
                                    <HMText 
                                        width='max-content'
                                        margin='auto auto auto 10px'
                                        fontSize={`${fontSize + 1}px`}
                                        text='Submitted prescription file'
                                    />
                                    {!currentOrder.orderDetails.length ? (
                                        <>
                                            <HMText
                                                color={blue}
                                                text='Change'
                                                margin='auto 0 auto auto'
                                                fontSize={`${fontSize - 1}px`}
                                            />
                                            <IconButton   
                                                sx={{ margin: 'auto 0 auto 5px' }}
                                                onClick={handleChangePrescriptionModal} 
                                            >
                                                <DriveFileRenameOutlineOutlinedIcon 
                                                    sx={{
                                                        margin: 'auto',
                                                        fontSize: `${fontSize + 2}px`,
                                                    }}
                                                />
                                            </IconButton>
                                        </>
                                    ) : <></>}
                                </HMBox>
                            )
                        })() : <></>}
                        {currentOrder.orderDetails.map((order, index) => {
                            let needUserInteraction = false
                            if ('storeValidation' in currentOrder.insuranceInfo) {
                                const vlidation = currentOrder.insuranceInfo.storeValidation[order.store]
                                if (vlidation && 'userConfirmed' in vlidation && !vlidation.userConfirmed) {
                                    needUserInteraction = true
                                } 
                            }
                            return (
                                <HMOrderDetail 
                                    key={index}
                                    state={state}
                                    orderDetail={order}
                                    needUserInteraction={needUserInteraction}
                                    insuranceInfo={currentOrder.insuranceInfo}
                                    handleOrderAgain={() => handleOrderAgain(order.orderItems)}
                                    navegateToStorePage={() => navegateToStorePage(order.store)}
                                    navegateToProductPage={(item) => navegateToProductPage(item)}
                                    handleViewAddedInfoModal={(addedInfo) => handleViewAddedInfoModal(addedInfo)}
                                    handleViewPrescription={(prescription) => handleViewPrescription(prescription)}
                                    handleActionAfterInsuranceValidationModal={(e) => handleActionAfterInsuranceValidationModal(e, order)}
                                />
                            )
                        })}
                    </HMBox>
                </Section>
            ) : <></>}
        </Page>
    ) : (
        <Section 
            padding={10} 
            bgColor={white}
        >
            <HMLoginFirst 
                message='You need to login first before accessing your order page'
            />
        </Section>
    )
}

export default MyOrder
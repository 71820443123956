import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import { 
  getOrder,
  resetOrder,
  updateOrder,
} from '../../../features/orders/orderSlice'
import { 
  resetProduct, 
  getActiveProducts, 
} from '../../../features/products/productSlice'
import { 
  getAllPublicStores,
  resetStoresWithListings,
} from '../../../features/storesWithListings/storesWithListingsSlice'
import HMInsuranceCard from '../../../components/checkout/HMInsuranceCard'
import AddressDetails from '../../../components/checkout/AddressDetails'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMOrderComputation from '../../../components/common/layout/HMOrderComputation'
import HMViewAddedInfo from '../../../components/common/layout/HMViewAddedInfo'
import HMStoreOption from '../../../components/common/layout/HMStoreOption'
import HMOrderDetail from '../../../components/common/layout/HMOrderDetail'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import HMStoreData from '../../../components/common/layout/HMStoreData'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMSelectMultiple from '../../../components/common/tools/HMSelectMultiple'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import HMProgressBar from '../../../components/common/tools/HMProgressBar'
import HMCheckbox from '../../../components/common/tools/HMCheckbox'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import pdf from '../../../assests/pdf.png'
import csv from '../../../assests/csv.png'
import { 
  currencies,
  cloudinary,
  getFileType,
  storeDelivery,
  orderActivityObj,
  computateOrderData,
  getStockAvailability,
} from '../../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  darkBlue,
  lightBlue,
  lightBlack,
  lightGreen,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../../hooks/useColors'

const filteringStoresWithListings = (publicStores, productObj, prescription) => {
  const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[0].type)
  const tempFilteredStoresWithListings = publicStores.reduce((result, data) => {
    let subtotal = 0
    let quantity = 0
    const orderItems = []
    const filteredProductsAndListings = data.productsAndListings.filter(productAndListing => {
      const stockCount = getStockAvailability(
          productAndListing.storeListing.availableStock, 
          productAndListing.storeListing.transitStockCount
        ).stockCount
      const item = productObj[productAndListing.product._id.toString()]
      const isObj = typeof(item) === 'object' ? true : false
      const itemQty = isObj ? item.quantity : 1
      if ((item && !isObj) || (isObj && stockCount >= itemQty)) {
        quantity += itemQty
        subtotal += productAndListing.storeListing.onlinePrice * itemQty
        orderItems.push({
          stockCount,
          select: true,
          quantity: itemQty,
          usedInsurance: false,
          product: productAndListing.product._id,
          productName: productAndListing.product.name,
          listing: productAndListing.storeListing._id,
          productImage: productAndListing.product.image,
          categoryId: productAndListing.product.category.id._id,
          onlinePrice: productAndListing.storeListing.onlinePrice,
          insurancePrice: productAndListing.product.insurancePrice,
          categoryParentId: productAndListing.product.category.id.parent._id,
          category: productAndListing.product.category.id.name.toLowerCase(),
          classification: productAndListing.product.category.id.parent.name.toLowerCase(),
          addedInfo: {
            prescription,
            uploadedPrescription: true
          },
        })
        return true
      } 
      return false
    })
  
    if (filteredProductsAndListings.length > 0) {
      result.push({
        subtotal,
        quantity,
        orderItems,
        select: true,
        deliveryOption,
        status: 'Pending',
        store: data.store,
        approvedInsurance: false,
        storeName: data.store.name,
        storeType: data.store.type,
        storePhone: data.store.phone,
      })
    }
  
    return result
  }, [])

  const neededProductCount = Object.keys(productObj).length

  tempFilteredStoresWithListings.sort((a, b) => {
    const itemA = a.subtotal
    const itemB = b.subtotal
    const diffA = neededProductCount - a.orderItems.length
    const diffB = neededProductCount - b.orderItems.length
    
    if (diffA === 0 && diffB === 0) {
      if (itemA < itemB) {
        return -1
      } else if (itemA > itemB) {
        return 1
      }
    } else if (diffA !== 0 && diffB === 0) {
      return 1
    } else if (diffB !== 0 && diffA === 0) {
      return -1
    } else if (diffB !== 0 && diffA !== 0) {
      if (diffA < diffB) {
        return -1
      } else if (diffA > diffB) {
        return 1
      } else {
        if (itemA < itemB) {
          return -1
        } else if (itemA > itemB) {
          return 1
        }
      }
    }
    
    return 0
  })

  return tempFilteredStoresWithListings
}

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

const updateQuantiy = (array, listingId, newQuantity) => {
  return array.reduce((result, data) => {
    let subtotal = data.subtotal
    let storeQuantity = data.quantity
    const orderItems = data.orderItems.map((orderItem) => {
      if (orderItem.listing === listingId) {
        const diff = newQuantity - orderItem.quantity
        storeQuantity += diff
        subtotal += diff * orderItem.onlinePrice
        return {
          ...orderItem,
          quantity: newQuantity
        }
      } else {
        return orderItem
      }
    })
    
    result.push({
      ...data,
      subtotal,
      orderItems,
      quantity: storeQuantity,
    })

    return result
  }, [])
}

function Order({ socket }) {
  const dispatch = useDispatch()
  const navigate = useNavigate() 
  const { orderId } = useParams()
  
  const { 
    visible,
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const {
    order,
    isOrder,
    isLoading,
    isOrderUpdated
  } = useSelector((state) => state.order)
  const { 
    activeProducts,
    areActiveProducts
  } = useSelector((state) => state.product)
  const { 
    publicStores,
    arePublicStores,
  } = useSelector((state) => state.storesWithListings)

  const tax = 0
  // const [tax, setTax] = useState(0)
  const [title, setTitle] = useState('')
  const [state, setState] = useState('')
  const [open, setOpen] = useState(false)
  const [storesObj, setStoresObj] = useState({})
  const [storeIndex, setStoreIndex] = useState(0)
  const [orderDetails, setOrderDetails] = useState([])
  const [currentOrder, setCurrentOrder] = useState({})
  const [cumputedData, setCumputedData] = useState(null)
  const [productIdsObj, setProductIdsObj] = useState({})
  const [isAcceptOrder, setIsAcceptOrder] = useState(false)
  const [selectedStoreId, setSelectedStoreId] = useState('')
  const [detailProductObj, setDetailProductObj] = useState({})
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isViewAddedInfo, setIsViewAddedInfo] = useState(false)
  const [currentAddedInfo, setCurrentAddedInfo] = useState(null)
  const [isAnyItemSelected, setIsAnyItemSelected] = useState(false)
  const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
  const [isChangePrescription, setIsChangePrescription] = useState('')
  const [improvedOrderDetails, setImprovedOrderDetails] = useState([])
  const [deliveryType, setDeliveryType] = useState(storeDelivery[0].type)
  const [filteredStoresWithListings, setFilteredStoresWithListings] = useState([])
  const [orderComputation, setOrderComputation] = useState({
    total: 0,
    subtotal: 0,
    deliveryCost: 0,
    numberOfStores: 0,
    insuranceCoverage: 0
  })
  const {
    total,
    insuranceCoverage
  } = orderComputation

  const {
    windowW,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isMediumScreen = windowW < 1110 ? true : false
  const isNormalTabletScreen = windowW < 880 ? true : false

  const breadOptions = [
    { text: 'Orders', link: '/admin/orders' },
    { text: Object.keys(currentOrder).length ? `${order.orderNumber}` : '', link: null }
  ]

  useEffect(() => {
    if (user && user.accessLevel <= 5) {
      dispatch(getOrder(orderId))
      dispatch(getActiveProducts())
      dispatch(getAllPublicStores())
    }

    const handleBeforeUnload = (e) => {
      if (orderDetails.length) {
        e.preventDefault()
        e.returnValue = '' // Required for Chrome and Firefox
      }
    }

    if (orderDetails.length) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    socket.on('order', (data) => {
      if (data._id === orderId) {
        dispatch(getOrder(orderId))
      }
    })
    socket.on('store_updates_order', (data) => {
      if (data.orderId === orderId) {
        dispatch(getOrder(orderId))
      }
    })
    socket.on('assigned_order', (data) => {
      if (data.orderId === orderId) {
        dispatch(getOrder(orderId))
      }
    })
    // eslint-disable-next-line
  }, [socket])

  useEffect(() => {
    let tempSubtotal = 0
    let tempDeliveryCost = 0
    let tempNumberOfStores = 0
    let isAnySelected = false
    const tempStoresObj = {}
    const tempDetailsProducObj = {}
    orderDetails.forEach(orderDetail => {
      tempStoresObj[orderDetail.store] = orderDetail
      let isThisStore = false
      orderDetail.orderItems.forEach(orderItem => {
        if (orderItem.select) {
          isAnySelected = true
          isThisStore = true
          tempSubtotal += orderItem.onlinePrice * orderItem.quantity
        }
        tempDetailsProducObj[orderItem.product] = orderItem
      })
      if (isThisStore) {
        tempDeliveryCost += orderDetail.deliveryOption.cost
        tempNumberOfStores += 1
      }
    })
    setCumputedData({
      subtotal: tempSubtotal,
      deliveryCost: tempDeliveryCost,
      numberOfStores: tempNumberOfStores,
      total: tempDeliveryCost + tempSubtotal,
    })
    setStoresObj(tempStoresObj)
    setIsAnyItemSelected(isAnySelected)
    setDetailProductObj(tempDetailsProducObj)

    if (tempNumberOfStores > 1) {
      const filtered = filteringStoresWithListings(publicStores, tempDetailsProducObj, currentOrder.prescription)
      if (filtered.length && (filtered[0].orderItems.length === Object.keys(tempDetailsProducObj).length)) {
        setImprovedOrderDetails([filtered[0]])
      } else {
        setImprovedOrderDetails([])
      }
    } else {
      setImprovedOrderDetails([])
    }
    // eslint-disable-next-line
  }, [orderDetails])

  useEffect(() => {
    if (isAcceptOrder) {
      const orderActivityStack = [ ...currentOrder.orderActivityStack ]
      orderActivityStack.push({
        state: 'picked',
        updatedBy: user._id,
        updatedAt: Date.now(),
      })

      const updatedOrder = {
        _id: order._id, 
        orderActivityStack
      }
      dispatch(updateOrder(updatedOrder))
    }
    // eslint-disable-next-line
  }, [isAcceptOrder])

  useEffect(() => {
    if (publicStores.length) {
      const filtered = filteringStoresWithListings(publicStores, productIdsObj, currentOrder.prescription)

      if (filtered.length) {
        setSelectedStoreId(filtered[0].store._id)
      }
      setStoreIndex(0)
      setFilteredStoresWithListings(filtered)
    }
    // eslint-disable-next-line
  }, [productIdsObj, publicStores])

  useEffect(() => {
    if (isOrder || isOrderUpdated) {
      setCurrentOrder(order)
      setIsAcceptOrder(false)
      setOrderComputation(computateOrderData(order))
      if ('orderActivityStack' in order && order.orderActivityStack.length) {
        setState(order.orderActivityStack[order.orderActivityStack.length - 1].state)
      }
      dispatch(resetOrder()) 
    }

    if (areActiveProducts) {
      dispatch(resetProduct())
    }

    if (arePublicStores) {
      dispatch(resetStoresWithListings())
    }
    // eslint-disable-next-line
  }, [isOrder, arePublicStores, isOrderUpdated, areActiveProducts])

  const navegateToProductPage = (storeId, item) => {
    window.open(`/admin/stores/${storeId}/listings/${item.listing}`, '_blank', 'noopener,noreferrer')
  }
  const navegateToStorePage = (storeId) => {
    window.open(`/admin/stores/${storeId}`, '_blank', 'noopener,noreferrer')
  }

  const handleAddProductId = (selectedList, removedItem) => {
    const tempProductIdsObj = {}
    const tempSelectedProducts = []
    selectedList.forEach(item => {
      tempProductIdsObj[item._id] = 1
      tempSelectedProducts.push(item)
    })
    setProductIdsObj(tempProductIdsObj)
    setSelectedProducts(tempSelectedProducts)
  }
  const handleRemoveProductId = (selectedList, selectedItem) => {
    const tempProductIdsObj = {}
    const tempSelectedProducts = []
    selectedList.forEach(item => {
      tempProductIdsObj[item._id] = 1
      tempSelectedProducts.push(item)
    })
    setProductIdsObj(tempProductIdsObj)
    setSelectedProducts(tempSelectedProducts)
  }

  const handleViewAddedInfoModal = (addedInfo) => {
    setOpen(true)
    setIsViewAddedInfo(true)
    setCurrentAddedInfo(addedInfo)
    setTitle('Additional Information')
  }
  const handleViewPrescription = (prescription) => {
    window.open(prescription, '_blank', 'noopener,noreferrer')
  }
  const handleChangePrescriptionModal = () => {
    setOpen(true)
    setIsChangePrescription(true)
    setTitle('Change The Prescription')
    setPrescriptionFileUrl(currentOrder.prescription)
  }
  const handleChangePrescription = () => {
    const updatedOrder = {
      _id: orderId,
      prescription: prescriptionFileUrl
    }
    dispatch(updateOrder(updatedOrder))
    handleClose()
  }
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsViewAddedInfo(false)
    setPrescriptionFileUrl('')
    setIsChangePrescription(false)
  }
  
  const handleSelectedStoreChange = (e, index) => {
    setStoreIndex(index)
    setSelectedStoreId(e.target.value)
  }
  const handleSelectedStore = (storeId, index) => {
    setStoreIndex(index)
    setSelectedStoreId(storeId)
  }

  const handleUpdateOrderDetails = () => {
    const storeData = filteredStoresWithListings[storeIndex]
    if (!orderDetails.length) {
      setOrderDetails([{
        ...storeData,
        store: storeData.store._id
      }])
    } else if (storesObj[storeData.store._id]) {
      let quantity = 0
      const orderItems = [] 
      const itemsToRemoveObj = {}
      storeData.orderItems.forEach(orderItem => {
        const prod = detailProductObj[orderItem.product]
        if (prod && prod.stockCount > prod.quantity + orderItem.quantity) {
          quantity += prod.quantity + orderItem.quantity
          itemsToRemoveObj[orderItem.product] = 1
          orderItems.push({
            ...orderItem,
            quantity: prod.quantity + orderItem.quantity
          })
        } else if (!prod) {
          orderItems.push(orderItem)
          quantity += orderItem.quantity
        }
      })

      storesObj[storeData.store._id].orderItems.forEach(orderItem => {
        if (!itemsToRemoveObj[orderItem.product]) {
          orderItems.push(orderItem)
          quantity += orderItem.quantity
        }
      })

      if (orderItems.length) {
        setOrderDetails(orderDetails.map(orderDetail => {
          if (orderDetail.store === storeData.store._id) {
            return {
              ...orderDetail,
              quantity,
              orderItems,
            }
          } else {
            return orderDetail
          }
        }))
      }
    } else {
      const orderItems = [] 
      const tempOrderDetails = [ ...orderDetails ]
      storeData.orderItems.forEach(orderItem => {
        const prod = detailProductObj[orderItem.product]
        if (!prod || (prod && prod.stockCount === prod.quantity)) {
          orderItems.push(orderItem)
        }
      })
      
      if (orderItems.length) {
        tempOrderDetails.push({
          ...storeData,
          orderItems,
          store: storeData.store._id,
        })
      }
      setOrderDetails(tempOrderDetails)
    }
  } 

  const handleIncreaseQuantity = (item) => {
    if (item.stockCount > item.quantity) {
      setOrderDetails(updateQuantiy(orderDetails, item.listing, item.quantity + 1))
    }
  }
  const handleReduceQuantity = (item) => {
    if (item.quantity > 1) {
      setOrderDetails(updateQuantiy(orderDetails, item.listing, item.quantity - 1))
    }
  }
  const handleQuantityChange = (e, item) => {
    const value = parseInt(e.target.value)
    
    if (item.stockCount >= value && value > 0) {
      setOrderDetails(updateQuantiy(orderDetails, item.listing, value))
    }
  }

  const handleNormalDelivery = () => {
    const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[0].type)
    const tempOrderDetails = orderDetails.map(orderDetail => ({
      ...orderDetail,
      deliveryOption,
      subtotal: orderDetail.subtotal - orderDetail.deliveryOption.cost + deliveryOption.cost,
    }))
    setOrderDetails(tempOrderDetails)
    setDeliveryType(storeDelivery[0].type)
  }
  const handleUrgentDelivery = () => {
    const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[1].type)
    const tempOrderDetails = orderDetails.map(orderDetail => ({
      ...orderDetail,
      deliveryOption,
      subtotal: orderDetail.subtotal - orderDetail.deliveryOption.cost + deliveryOption.cost,
    }))
    setOrderDetails(tempOrderDetails)
    setDeliveryType(storeDelivery[1].type)
  }

  const handleCheckAll = (e) => {
    const checked = e.target.checked
    setOrderDetails(orderDetails.map(orderDetail => ({
      ...orderDetail,
      select: checked,
      orderItems: orderDetail.orderItems.map(item => ({
        ...item,
        select: checked
      }))
    })))
  }
  const handleStoreCheckboxChange = (e, store) => {
    const checked = e.target.checked
    setOrderDetails(orderDetails.map(orderDetail => ({
      ...orderDetail,
      select: orderDetail.store === store ? checked : orderDetail.select,
      orderItems: orderDetail.store === store ? orderDetail.orderItems.map(item => ({
        ...item,
        select: checked
      })) : orderDetail.orderItems,
    })))
  }
  const handleItemCheckboxChange = (e, listingId) => {
    const checked = e.target.checked
    setOrderDetails(orderDetails.map(orderDetail => {
      let isUnselected = false
      const orderItems = orderDetail.orderItems.map(item => {
        const select = item.listing === listingId ? checked : item.select
        if (!select) {
          isUnselected = true
        }
        return {
          ...item,
          select
        }
      })
      return {
        ...orderDetail,
        orderItems,
        select: isUnselected ? false : true,
      }
    }))
  }

  const handleOrderDetailsDelete = () => {
    const tempOrderDetails = []
    for (let orderDetail of orderDetails) {
      const orderItems = []
      if (!orderDetail.select) {
        for (let item of orderDetail.orderItems) {
          if (!item.select) {
            orderItems.push(item)
          }
        }
        if (orderItems.length) {
          tempOrderDetails.push({
            ...orderDetail,
            orderItems
          })
        }
      }
    }

    setOrderDetails(tempOrderDetails)
  }
  const handleBuyFromSingleStore = () => {
    setOrderDetails(improvedOrderDetails) 
  }
  const handlePlaceOrder = () => {
    const orderActivityStack = [ ...currentOrder.orderActivityStack ]
    orderActivityStack.push({
      updatedBy: user._id,
      updatedAt: Date.now(),
      state: currentOrder.paymentInfo.method.toLowerCase().includes('insurance') ? 'at_store' : 'intial_user_confirmation',
    })

    const updatedOrder = {
      _id: order._id, 
      orderDetails,
      orderActivityStack,
      orderTotal: cumputedData.total,
    }

    dispatch(updateOrder(updatedOrder))
  }

  const supportedFiles = {
    'image/png': [],
    'image/jpg': [],
    'image/jpeg': [],
    'application/pdf': [],
  }

  return user ? (
    <Page>
      {(isLoading || !Object.keys(currentOrder).length) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        maxHeight='95vh'
        handleClose={handleClose} 
        width={isTabletScreen ? '90vw' : 'auto'}
        maxWidth={isTabletScreen ? '95vw' : '600px'}
      >
        {isViewAddedInfo ? (
          <HMViewAddedInfo 
            addedInfo={currentAddedInfo} 
          />
        ) : isChangePrescription ? (
          <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              minWidth={!isTabletScreen && '500px'}
          >
              <HMDropZone 
                  allowedSizeInMB={1}
                  className='drag-drop' 
                  supportedFiles={supportedFiles} 
                  cloudinaryFileUrl={prescriptionFileUrl}
                  endPoint={cloudinary.API_URL_PRESCRIPTION}
                  setCloudinaryFileUrl={setPrescriptionFileUrl}
              />
              <HMBox
                  padding='0'
                  width='100%'
                  display='flex'
                  margin='15px auto 0 auto'
                  className='change-store-button fade-in'
              >
                  <HMButton 
                      type='button'
                      width='100px'
                      bgColor={gray}
                      fontWeight={500}
                      margin='auto 10px auto 0'
                      handleClick={handleClose}
                      text={<Typography sx={textStyle}>Cancel</Typography>}
                  />
                  <HMButton 
                      type='button'
                      width='100px'
                      color={white}
                      bgColor={blue} 
                      margin='auto 0 auto auto'
                      disabled={!prescriptionFileUrl}
                      handleClick={handleChangePrescription}
                      text={
                          <Typography sx={textStyle}>
                              Submit
                          </Typography>
                      }
                  />
              </HMBox>
          </HMBox>
        ) : <></>}
      </HMModal>
      {(Object.keys(currentOrder).length) ? (
        <Section
          padding={10}
          bgColor={white}
        >
          {(
            state === 'picked' || 
            state === 'received' ||
            state === 'at_store' || 
            state === 'at_store_final' ||
            state === 'final_user_confirmation' || 
            state === 'intial_user_confirmation' 
          ) ? (
            <HMBox
              width='100%'
              display='flex'
              padding='20px 10px'
              bgColor={skeletonsLightBlue}
              flexDirection={isTabletScreen ? 'column' : 'row'}
            >
              <HMProgressBar 
                color={orderActivityObj[state].color}
                value={orderActivityObj[state].location}
                margin={isTabletScreen ? 'auto 0' : 'auto 20px auto 0'}
              />
              <HMBox
                color={white}
                display='flex'
                borderRadius={0}
                padding='5px 10px 5px 7.5px'
                className='operator-order fade-in'
                bgColor={orderActivityObj[state].color}
                margin={isTabletScreen ? '10px 0 auto auto' : 'auto 0 auto auto'}
              >
                <HMText
                  color='inherit'
                  fontWeight={500}
                  fontSize={`${fontSize}px`}
                  margin='auto auto auto 5px'
                  text={orderActivityObj[state].operator.action}
                />  
                {state === 'received' ? (
                  <HMToogleButton 
                    status={isAcceptOrder}
                    text={isAcceptOrder ? 'Accepted' : 'Accept'}
                    handleChange={() => setIsAcceptOrder(!isAcceptOrder)}
                  /> 
                ) : <></>} 
              </HMBox>
            </HMBox>
          ) : <></>}
          <HMBox
            width='100%'
            display='flex'
            className='fade-in'
          >
            <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              margin='auto auto auto 0'
            >
              <Title 
                size={fontSize + 2}
                margin='auto auto auto 0'
                title={currentOrder.orderNumber}
              />
              <HMText 
                color={lightBlack}
                margin='auto auto auto 0'
                fontSize={`${fontSize - 1}px`}
                text={moment(currentOrder.createdAt).utc().format('MMM DD, YYYY  h:mm:ss a')}
              />
            </HMBox>
            <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              margin='auto 0 auto auto'
            >
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='auto 0 auto auto'
              >
                <HMText 
                  fontSize={`${fontSize}px`}
                  margin='auto 5px auto auto'
                  text='Payment Method :'
                />
                <HMText 
                  color={white}
                  bgColor={blue}
                  padding='0 5px'
                  margin='auto 0 auto 5px'
                  fontSize={`${fontSize - 1}px`}
                  text={currentOrder.paymentInfo.method}
                />
              </HMBox>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='5px 0 auto auto'
              >
                <HMText 
                  text='Order Total :'
                  fontSize={`${fontSize}px`}
                  margin='auto 5px auto auto'
                />
                <HMText 
                  color={orange}
                  padding='0 5px'
                  fontWeight={500}
                  margin='auto 0 auto 5px'
                  fontSize={`${fontSize + 2}px`}
                  text={`${currency} ${(total - insuranceCoverage).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
              </HMBox>
            </HMBox>
          </HMBox>
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <AddressDetails 
              width='100%'
              desableChnageAddress={true}
              bgColor={skeletonsLightBlue}
              addressInfo={currentOrder.deliveryInfo}
              margin={isTabletScreen ? '10px auto 5px auto' : '10px 5px 10px 0'}
            />
            {currentOrder.insuranceInfo && Object.keys(currentOrder.insuranceInfo).length ? (
              <HMInsuranceCard 
                isOrderPage={true}
                coverage={insuranceCoverage}
                bgColor={skeletonsLightBlue}
                insuranceInfo={currentOrder.insuranceInfo}
                insurer={currentOrder.insuranceInfo.insurer}
                margin={isTabletScreen ? '5px auto 10px auto' : '10px 0 10px 5px'}
              />
            ) : <></>}
          </HMBox>
          <HMBox
            width='100%'
            className='fade-in'
            padding='10px 5px 5px'
            bgColor={seeThroughLightBlue}
          >
            {('prescription' in currentOrder && currentOrder.prescription) ? (() => {
              const fileType = getFileType(currentOrder.prescription)
              return (
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={white}
                  margin='auto auto 10px auto'
                  border={`1px solid ${lightBlue}`}
                >
                  <HMImage 
                    width='50px'
                    height='50px'
                    cursor='pointer'
                    borderRadius='8px'
                    margin='auto 10px auto 0'
                    handleClick={() => handleViewPrescription(currentOrder.prescription)}
                    src={
                      fileType === 'csv' ? csv  
                      : fileType === 'pdf' ? pdf 
                      : fileType === 'image' ? currentOrder.prescription 
                      : ''
                    }
                  />
                  <HMText 
                    width='max-content'
                    margin='auto auto auto 10px'
                    fontSize={`${fontSize + 1}px`}
                    text='Submitted prescription file'
                  />
                  {!currentOrder.orderDetails.length ? (
                    <>
                      <HMText
                        color={blue}
                        text='Change'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 1}px`}
                      />
                      <IconButton   
                        sx={{ margin: 'auto 0 auto 5px' }}
                        onClick={handleChangePrescriptionModal} 
                      >
                        <DriveFileRenameOutlineOutlinedIcon 
                          sx={{
                              margin: 'auto',
                              fontSize: `${fontSize + 2}px`,
                          }}
                        />
                      </IconButton>
                    </>
                  ) : <></>}
                </HMBox>
              )
            })() : <></>}
            {currentOrder.orderDetails.map((order, index) => (
              <HMOrderDetail 
                key={index}
                state={state}
                orderDetail={order}
                insuranceInfo={currentOrder.insuranceInfo}
                navegateToStorePage={() => navegateToStorePage(order.store)}
                navegateToProductPage={(item) => navegateToProductPage(item)}
                handleViewAddedInfoModal={(addedInfo) => handleViewAddedInfoModal(addedInfo)}
                handleViewPrescription={(prescription) => handleViewPrescription(prescription)}
              />
            ))}
          </HMBox>
          {state === 'picked' && orderDetails.length ? (
            <HMBox
              width='100%'
              padding='10px'
              margin='10px auto'
              className='fade-in'
              border={`15px solid ${lightBlue}`}
            >
              <Grid container spacing={2}>
                <Grid 
                  item 
                  xs={12} 
                  sx={{display: 'flex', flexDirection: 'column'}}
                  sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 8} 
                >
                  <HMBox 
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    className='fade-in'
                    margin='5px 5px 5px 2.5px'
                  >
                    <HMCheckbox 
                      margin='auto 0'
                      handleChange={handleCheckAll} 
                      checked={!orderDetails.find(orderDetail => orderDetail.orderItems.find(item => !item.select))} 
                    />
                    <HMText
                      float='none'
                      text='Select all'
                      color={lightBlack}
                      width='max-content'
                      fontSize={`${fontSize}px`}
                      margin='auto auto auto 2.5px'
                    />
                    {cumputedData.numberOfStores > 1 && improvedOrderDetails.length ? (
                      <HMBox
                        padding='0'
                        margin='auto'        
                        display='flex'
                        className='fade-in'
                      >
                        <Tooltip 
                          arrow
                          placement='top' 
                          sx={{ margin: 'auto 0' }}
                          title='You can order from a single store and spend less on delivery' 
                        >
                          <HelpOutlineTwoToneIcon 
                            sx={{
                              cursor: 'pointer',
                              color: lightBlack,
                              fontSize: `${fontSize + 2}px`
                            }} 
                          />
                        </Tooltip> 
                        <HMButton 
                          type='button'
                          color={white}
                          fontWeight={500}
                          bgColor={orange}
                          padding='1px 5px'
                          margin='auto auto auto 5px'
                          paddingActive='0.5px 4.5px'
                          className='cart-prescription-button'
                          handleClick={handleBuyFromSingleStore}
                          icon={
                            <StoreOutlinedIcon 
                              sx={{
                                ...iconStyle, 
                                marginRight: '5px',
                                fontSize: `${fontSize}px`, 
                              }} 
                            /> 
                          } 
                          text={
                            <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                              Buy from a single store
                            </Typography>
                          }
                        />
                      </HMBox>
                    ) : <></>}
                    <HMButton 
                      type='button'
                      fontWeight={500}
                      padding='1px 5px'
                      margin='auto 0 auto auto'
                      paddingActive='0.5px 4.5px'
                      className='cart-prescription-button'
                      handleClick={handleOrderDetailsDelete}
                      disabled={isAnyItemSelected ? false : true}
                      bgColor={isAnyItemSelected ? red : 'inherit'}
                      color={isAnyItemSelected ? white : lightBlack}
                      icon={
                        <DeleteIcon 
                          sx={{
                            ...iconStyle, 
                            marginRight: '5px',
                            fontSize: `${fontSize}px`, 
                          }} 
                        /> 
                      } 
                      text={
                        <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}> 
                          {isAnyItemSelected ? 'Remove selected' : 'Select item'}
                        </Typography>
                      }
                    />
                  </HMBox>
                  {orderDetails.map((orderDetail, index) => (
                    <HMStoreData 
                      key={index} 
                      isOperator={true}
                      isLoading={isLoading}
                      storeData={orderDetail}
                      handleQuantityReduce={(item) => handleReduceQuantity(item)}
                      handleQuantityIncrease={(item) => handleIncreaseQuantity(item)}
                      handleQuantityChange={(e, item) => handleQuantityChange(e, item)}
                      navegateToStorePage={() => navegateToStorePage(orderDetail.store)}
                      isStoreChecked={!orderDetail.orderItems.find(item => !item.select)}
                      navegateToProductPage={(item) => navegateToProductPage(orderDetail.store, item)}
                      handleStoreCheckboxChange={(e) => handleStoreCheckboxChange(e, orderDetail.store)}
                      handleItemCheckboxChange={(e, listingId) => handleItemCheckboxChange(e, listingId)}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 4}>
                  <HMOrderComputation 
                    tax={tax}
                    total={cumputedData.total}
                    deliveryType={deliveryType}
                    isDisabled={!isAnyItemSelected}
                    subtotal={cumputedData.subtotal}
                    handleProceed={handlePlaceOrder}
                    deliveryCost={cumputedData.deliveryCost}
                    numberOfStores={cumputedData.numberOfStores}
                    handleNormalDelivery={handleNormalDelivery}
                    handleUrgentDelivery={handleUrgentDelivery}
                    actionMessage={
                      currentOrder.paymentInfo.method.toLowerCase().includes('insurance') 
                        ? 'Send to store' 
                        : 'Send back to client'
                    }
                    margin={
                      isNormalTabletScreen || (isMediumScreen && visible) 
                      ? 'auto' 
                      : '10px auto auto auto'
                    }
                  />
                </Grid>
              </Grid>
            </HMBox>
          ) : <></>}
          {state === 'picked' ? (
            <HMBox
              width='100%'
              padding='10px'
              margin='10px auto'
              className='fade-in'
              border={`15px solid ${lightGreen}`}
            >
              <Title 
                color={darkBlue}
                className='fade-in'
                size={fontSize + 2}
                title='Select products'
                margin='20px auto 10px 0'
              />
              <HMSelectMultiple 
                options={activeProducts}
                placeholder='Select products'
                handleAdd={handleAddProductId}
                selectedValues={selectedProducts}
                handleRemove={handleRemoveProductId}
              />
              {filteredStoresWithListings.length ? (
                <HMBox 
                  width='100%'
                  display='flex'
                  flexDirection={isNormalTabletScreen || (isMediumScreen && visible) ? 'column-reverse' : 'row'}
                >
                  <HMBox 
                    width='100%'
                    display='flex'
                    flexDirection={isNormalTabletScreen || (isMediumScreen && visible) ? 'column' : 'column'}
                    margin={isNormalTabletScreen || (isMediumScreen && visible) ? 'auto' : '0 20px auto auto'}
                  >
                    <Title 
                      color={darkBlue}
                      className='fade-in'
                      size={fontSize + 2}
                      margin='20px auto 10px 0'
                      title='Choose a store you want to buy from.'
                    />
                    {filteredStoresWithListings.map((data, index) => {
                      const itemNum = data.orderItems.length
                      const neededProductCount = Object.keys(productIdsObj).length
                      const diff = neededProductCount - itemNum
                      const operatorMsg = neededProductCount === 1 
                        ? '' 
                        : diff === 0 
                        ? `${neededProductCount > 1 
                          ? `All ${neededProductCount} products are` 
                          : `${neededProductCount} product is`} available` 
                        : ` ${itemNum > 1 
                          ? `${itemNum} products are` 
                          : `${itemNum} product is`} available |  Missing ${diff > 1 
                            ? `${diff} products` 
                            : `${diff} product`}`

                      return (
                        <HMStoreOption 
                          key={index}
                          isOperator={true}
                          store={data.store}
                          currency={currency}
                          cost={data.subtotal}
                          value={data.store._id}
                          operatorMsg={operatorMsg}
                          border={`1px solid ${white}`}
                          selectedStoreId={selectedStoreId}
                          hoverBorder={`1px solid ${green}`} 
                          handleStore={() => navegateToStorePage(data.store._id)}
                          bgColor={diff === 0 ? lightGreen : seeThroughLightBlue}
                          handleClick={() => handleSelectedStore(data.store._id, index)}
                          handleRadioButtonChange={(e) => handleSelectedStoreChange(e, index)}
                        />
                      )
                    })}
                  </HMBox>
                  <HMBox
                    padding='10px'
                    bgColor={skeletonsLightBlue}
                    border={`3px solid ${blue}`}
                    width={isNormalTabletScreen || (isMediumScreen && visible) ? '100%' : '500px'}
                    margin={isNormalTabletScreen || (isMediumScreen && visible) ? 'auto' : '40px auto auto auto'}
                  >
                    {filteredStoresWithListings[storeIndex].orderItems.map((item, index) => (
                      <HMBox
                        key={index}
                        width='100%'
                        display='flex'
                        margin='2.5px auto'
                        className='fade-in'
                        bgColor={lightBlue}
                        padding='7.5px 0 5px 2.5px'
                      >
                        <Link
                          sx={{margin: 'auto 0', cursor: 'pointer'}}
                          onClick={() => navegateToProductPage(filteredStoresWithListings[storeIndex].store._id, item)}
                        >
                          <img 
                            alt={item.productName}
                            src={item.productImage}
                            className='small-image'
                          />
                        </Link>
                        <HMBox
                          width='100%'
                          height='100%'
                          display='flex'
                          margin='auto 0'
                          padding='0 10px'
                          flexDirection='column'
                        >
                          <HMText
                            fontWeight={500}
                            color={lightBlack}
                            margin='auto auto 0 0'
                            text={item.productName}
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMBox
                            padding='0'
                            width='100%'
                            height='100%'
                            display='flex'
                            margin='5px auto auto 0'
                          >
                            <HMText
                              color={orange}
                              margin='auto 0'
                              fontWeight={500}
                              fontSize={`${fontSize - 1}px`}
                              text={`${currency} ${(item.onlinePrice * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            />
                            <HMText
                              fontWeight={500}
                              color={lightBlack}
                              margin='auto 0 auto auto'
                              fontSize={`${fontSize - 3}px`}
                              text={`Stock Qty ( ${item.stockCount} )`}
                            />
                          </HMBox>
                        </HMBox>
                      </HMBox>
                    ))}
                    <HMBox 
                      padding='0'
                      width='100%'
                      display='flex'
                      margin='20px auto'
                    >
                      <HMButton 
                        margin='0'
                        width='100%'
                        type='button'
                        bgColor={blue}
                        handleClick={handleUpdateOrderDetails}
                        text={
                          <Typography sx={{...textStyle, fontWeight: 500}}>
                            Add
                          </Typography>
                        } 
                      />
                    </HMBox>
                  </HMBox>
                </HMBox>
              ) : <></>}
            </HMBox>
          ) : <></>}
        </Section>
      ) : <></>}                  
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing admin content'
      />
    </Section>
  )
}

export default Order
import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import Page from "../../../components/common/layout/Page";
import FullWidthContainer from "../../../components/common/layout/FullWidthContainer";
import HMButton from "../../../components/common/tools/HMButton";
import { fontSize, textStyle } from "../../../components/common/tools/Styles";
import {
  blue,
  green,
  lightBlue,
  white,
} from "../../../hooks/useColors";
import HMBox from "../../../components/common/layout/HMBox";
import Title from "../../../components/common/tools/Title";
import analyticsBlueIcon from "../../../assests/analyticsBlueIcon.png";
import analyticsGreenIcon from "../../../assests/analyticsGreenIcon.png";

import HMPieChart from '../../../components/common/tools/HMPieChart'
import HMDashboardCard from '../../../components/common/tools/HMDashboardCard'
import DashboardBottomCard from '../../../components/common/tools/DashboardBottomCard'
import HMLineChart from '../../../components/common/tools/HMLineChart'
import HMAreaChart from '../../../components/common/tools/HMAreaChart'
import HMDonutChart from '../../../components/common/tools/HMDonutChart'
import HMBarChart from '../../../components/common/tools/HMBarChart'

const PIE_CHART_DATA = [30, 39, 31];
const PIE_CHART_LABELS = ["Potential", "Customers", "Officials"];

const LINE_CHART_DATA = [
  {
    name: "Customers ",
    data: [60, 95, 60, 100, 42, 109, 80],
  },
  {
    name: "Officials ",
    data: [20, 70, 50, 80, 34, 75, 41],
  },
  {
    name: "Potential customers",
    data: [4, 40, 12, 50, 12, 52, 9],
  },
];

const AREA_CHART_DATA = [
  {
    name: "Recurring ",
    data: [31, 40, 28, 51, 42, 109, 100],
    type: "area",
  },
  {
    name: "One-Time",
    data: [11, 32, 45, 32, 34, 52, 41],
    type: "area",
  },
];

const DONUT_CHART_DATA = [30, 39];
const DONUT_CHART_LABELS = ["Recurring", "One-Time"];

const BAR_CHART_DATA = [
  {
    name: "HM Officials",
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  },
  {
    name: "Store Officials",
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  },
  {
    name: "Supplier Officials",
    data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  },
  {
    name: "Insurance Officials",
    data: [30, 36, 40, 30, 40, 58, 66, 53, 10],
  },
];

//!!!!!!!!!!!!bottom section
const HM_OFFICIALS_DONUT_CHART_DATA = [40, 49, 10];
const HM_OFFICIALS_DONUT_CHART_LABELS = ["Admins","Operators", "Absolute Admin(s)"];
const HM_OFFICIALS_DONUT_CHART_COLORS = ["#2F3854", "#00AEEF", "#F78800"];

const STORE_OFFICIALS_DONUT_CHART_DATA = [40, 49, 10];
const STORE_OFFICIALS_DONUT_CHART_LABELS = [ "Managers","Employees","Store Owners"];
const STORE_OFFICIALS_DONUT_CHART_COLORS = ["#2F3854", "#00AEEF", "#F78800"];

const SUPPLIER_OFFICIALS_DONUT_CHART_DATA = [40, 49, 10];
const SUPPLIER_OFFICIALS_DONUT_CHART_LABELS = [ "Managers","Employees","Supplier Owners"];
const SUPPLIER_OFFICIALS_DONUT_CHART_COLORS = ["#2F3854", "#00AEEF", "#F78800"];

const INSURANCE_OFFICIALS_DONUT_CHART_DATA = [20];
const INSURANCE_OFFICIALS_DONUT_CHART_LABELS = [ "Insurer Operators",];
const INSURANCE_OFFICIALS_DONUT_CHART_COLORS = [ "#00AEEF", ];


const Users = () => {
  return (
    <Page>
      <FullWidthContainer>
        <HMButton
          type="button"
          isResponsive={true}
          float="right"
          className="report-btn"
          //  handleClick={handleMakeReport}
          text={<Typography sx={textStyle}>Make report</Typography>}
        />
        <Grid container md={12} gap={1.5}>
          <Grid
            item
            xs={12}
            md={4.8}
            sx={{
              background: white,
              height: "270px",
              borderRadius: "20px",
              pt: 2,
            }}
          >
            <HMPieChart
              title="Users distribution"
              labels={PIE_CHART_LABELS}
              data={PIE_CHART_DATA}
            />
            {/* 
    
            {/* !!!!!!!!!!!!!!!!!!HM-cards section!!!!!!!!!!!!!!!!! */}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              background: white,
              height: "270px",
              p: 2,
              borderRadius: "20px",
            }}
          >
            <Title title="Users" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!HM-main-cards!!!!!!!!!!!!!!!!! */}
            <Box sx={{ mt: 8, padding: "0 5px", display: "flex", gap: 1 }}>
              <HMDashboardCard
                bgColor="#FFA500"
                text="Potential customers"
                money={`${10}k`}
              />

              <HMDashboardCard
                bgColor={blue}
                text="Customers"
                money={`${500}k`}
              />

              <HMDashboardCard
                bgColor={green}
                text="Officials"
                money={`${400}k`}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: "500px",
              p: "30px 20px",
              borderRadius: "20px",
            }}
          >
            <Title title="Users" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!HM Line Graph !!!!!!!!!!!!!!!!! */}

            <Box sx={{ width: "100%", height: "80%", mt: 8 }}>
              <HMLineChart data={LINE_CHART_DATA} />
            </Box>
          </Grid>


          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: "800px",
              p: "30px 33px",
              borderRadius: "20px",
            }}
          >
            <Title
              title="One-Time vs Recurring Customers"
              size={fontSize + 4}
              fontWeight="700"
               color='rgba(47, 56, 84, 0.7)'
            />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            <Grid container sx={{ mt: 8 }} gap={2}>
              <Grid item xs={12} md={8} sx={{ background: white }}>
                <HMAreaChart data={AREA_CHART_DATA} />
              </Grid>

              <Grid item xs={12} md={3.7} sx={{ background: white, p: 3 }}>

                <DashboardBottomCard
                  bgColor="#94DDF8"
                  src={analyticsBlueIcon}
                  text="Recurring Customers"
                  money={60}
                />

                <DashboardBottomCard
                  bgColor="#9DEDD5"
                  src={analyticsGreenIcon}
                  text="One-Time Customers"
                  money={80}
                  mt={4}
                />

                {/* !!!!!!!!!!!!!Donut-chart-here!!!!!!!!!!!!! */}
                <Box
                  sx={{
                    // background: '#9DEDD5',
                    padding: "20px 0 0",
                    mt: 2,
                  }}
                >
                  <Box sx={{ m: "auto",  }}>
                    <HMDonutChart
                      data={DONUT_CHART_DATA}
                      labels={DONUT_CHART_LABELS}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </Grid>

          {/* !!!!!!!!!!!!!!!!!!!!!!!HM bar chart!!!!!!!!!!!!!!!!!!!! */}

          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: "500px",
              p: "30px 20px",
              borderRadius: "20px",
            }}
          >
            <Title title="Officials" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>


            <Box sx={{ width: "100%", height: "80%", mt: 8 }}>
              <HMBarChart data={BAR_CHART_DATA} />
            </Box>
          </Grid>


          <Grid item container>
            <Grid item container xs={7} gap={3} sx={{}}>
              <Grid
                item
                xs={11.5}
                md={5.7}
                sx={{
                  background: white,
                  height: "250px",
                  p: "30px 20px",
                  borderRadius: "20px",
                }}
              >
                <Title
                  title="HM Officials"
                  size={fontSize + 4}
                  fontWeight="700" 
                  color='rgba(47, 56, 84, 0.7)'
                />
                <Box sx={{ width: "100%", height: "80%", mt: 5 }}>
                  <HMDonutChart
                    data={HM_OFFICIALS_DONUT_CHART_DATA}
                    labels={HM_OFFICIALS_DONUT_CHART_LABELS}
                    showDataLabels={true}
                    colors={HM_OFFICIALS_DONUT_CHART_COLORS}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={11.5}
                md={5.7}
                sx={{
                  background: white,
                  height: "250px",
                  p: "30px 20px",
                  borderRadius: "20px",
                }}
              >
                <Title
                  title="Store Officials"
                  size={fontSize + 4}
                  fontWeight="700"
                   color='rgba(47, 56, 84, 0.7)'
                />
                <Box sx={{ width: "100%", height: "80%", mt: 5 }}>
                  <HMDonutChart
                    data={STORE_OFFICIALS_DONUT_CHART_DATA}
                    labels={STORE_OFFICIALS_DONUT_CHART_LABELS}
                    showDataLabels={true}
                    colors={STORE_OFFICIALS_DONUT_CHART_COLORS}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={11.5}
                md={5.7}
                sx={{
                  background: white,
                  height: "250px",
                  p: "30px 20px",
                  borderRadius: "20px",
                }}
              >
                <Title
                  title="Supplier Officials"
                  size={fontSize + 4}
                  fontWeight="700" 
                  color='rgba(47, 56, 84, 0.7)'
                />
                <Box sx={{ width: "100%", height: "80%", mt: 5 }}>
                  <HMDonutChart
                    data={SUPPLIER_OFFICIALS_DONUT_CHART_DATA}
                    labels={SUPPLIER_OFFICIALS_DONUT_CHART_LABELS}
                    showDataLabels={true}
                    colors={SUPPLIER_OFFICIALS_DONUT_CHART_COLORS}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={11.5}
                md={5.7}
                sx={{
                  background: white,
                  height: "250px",
                  p: "30px 20px",
                  borderRadius: "20px",
                }}
              >
                <Title
                  title="Insurance Officials"
                  size={fontSize + 4}
                  fontWeight="700" 
                  color='rgba(47, 56, 84, 0.7)'
                />
                <Box sx={{ width: "100%", height: "80%", mt: 5 }}>
                  <HMDonutChart
                    data={INSURANCE_OFFICIALS_DONUT_CHART_DATA}
                    labels={INSURANCE_OFFICIALS_DONUT_CHART_LABELS}
                    showDataLabels={true}
                    colors={INSURANCE_OFFICIALS_DONUT_CHART_COLORS}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              xs={5}
              sx={{
                p: 2,
                borderRadius: "20px",
                background: "rgba(32, 184, 241, 0.3)",
              }}
            >
              HM CARDS HERE!!
            </Grid>
          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  );
};

export default Users;

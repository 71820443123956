import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import supplierService from './supplierService'

const initialState = {
    supplier: {},
    suppliers: [],
    isSuccess: false,
    isLoading: false,
    supplierNames:[],
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isError: false,
    isOne: false,
    isMySupplier: false,
    mySupplier: {},
    mySuppliers: [],
    areMySuppliers:false,
    otherSuppliers: [],
    publicSuppliers: [],
    isAllSupplierNames: false,
    message: '',
    suppliersForApplication: [],
    isApplicationSuppliers: false,
}

// Create a new supplier
export const createSupplier = createAsyncThunk(
    'suppliers/create',
    async (newSupplier, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.createSupplier(newSupplier, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an supplier
export const updateSupplier = createAsyncThunk(
    'suppliers/update',
    async (supplier, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.updateSupplier(supplier, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an supplier
export const deleteSupplier = createAsyncThunk(
    'suppliers/delete',
    async (supplierID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.deleteSupplier(supplierID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get suppliers
export const getSuppliers = createAsyncThunk(
    'suppliers/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.getSuppliers(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my suppliers
export const getMySuppliers = createAsyncThunk(
    'suppliers/mySuppliers',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.getMySuppliers(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my supplier
export const getMySupplier = createAsyncThunk(
    'suppliers/mySupplier',
    async (supplierId, thunkAPI) => {
        try {
            const mySuppliers = thunkAPI.getState().supplier.mySuppliers
            return mySuppliers.find(supplier => supplier._id === supplierId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get supplier names for application
export const getSuppliersForApplication = createAsyncThunk(
    'suppliers/applicationSuppliers',
    async (_, thunkAPI) => {
        try {
            return await supplierService.getSuppliersForApplication()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get supplier
export const getSupplier = createAsyncThunk(
    'supplier/one',
    async (supplierID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await supplierService.getSupplier(supplierID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Set other suppliers
export const setOtherSuppliers = createAsyncThunk(
    'supplier/otherSuppliers',
    async (suppliers) => {
        return suppliers
    }
    
)

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        resetSupplier: (state) => {
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isMySupplier = false
            state.areMySuppliers = false
            state.isAllSupplierNames = false
            state.message = ''
            state.isApplicationSuppliers = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSupplier.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createSupplier.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.supplier = action.payload
            })
            .addCase(createSupplier.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateSupplier.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateSupplier.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.supplier = action.payload
            })
            .addCase(updateSupplier.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteSupplier.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteSupplier.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteSupplier.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isDeleted = false
                state.message = action.payload
            })
            .addCase(getSupplier.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSupplier.fulfilled, (state, action) => {
                state.isOne = true
                state.isLoading = false
                state.supplier = action.payload
            })
            .addCase(getSupplier.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(getSuppliers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSuppliers.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isLoading = false
                state.suppliers = action.payload
            })
            .addCase(getSuppliers.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(getMySuppliers.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areMySuppliers = false
            })
            .addCase(getMySuppliers.fulfilled, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areMySuppliers = true
                state.mySuppliers = action.payload
            })
            .addCase(getMySuppliers.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areMySuppliers = false
                state.message = action.payload
            })

            .addCase(getMySupplier.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isMySupplier = false
            })
            .addCase(getMySupplier.fulfilled, (state, action) => {
                state.isError = true
                state.isMySupplier = true
                state.isLoading = false
                state.mySupplier = action.payload
            })
            .addCase(getMySupplier.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isMySupplier = false
                state.message = action.payload
            })
            .addCase(getSuppliersForApplication.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isApplicationSuppliers = false
            })
            .addCase(getSuppliersForApplication.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isApplicationSuppliers = true
                state.suppliersForApplication = action.payload
            })
            .addCase(getSuppliersForApplication.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isApplicationSuppliers = false
                state.message = action.payload
            })

            .addCase(setOtherSuppliers.fulfilled, (state, action) => {
                state.otherSuppliers = action.payload
            })
    }
})

export const { resetSupplier } = supplierSlice.actions
export default supplierSlice.reducer

import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'

const width = 200

const initialState = {
    sidebarWidth: width,
    visible: true
}

export const hideSidebar = createAsyncThunk(
    'nav/hideSidebar',
    async () => {
        return {
            sidebarWidth: 0,
            visible: false
        }
    }
)

export const showSidebar = createAsyncThunk(
    'nav/showSidebar',
    async () => {
        return {
            sidebarWidth: width,
            visible: true
        }
    }
)
export const hideSidebarForSmall = createAsyncThunk(
    'nav/hideSidebarForSmall',
    async () => {
        return {
            sidebarWidth: 0,
            visible: false
        }
    }
)
export const showSidebarForSmall = createAsyncThunk(
    'nav/showSidebarForSmall',
    async () => {
        return {
            sidebarWidth: width,
            visible: true
        }
    }
)

export const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        reset: (state) => {
            state.sidebarWidth = 200
            state.visible = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(hideSidebar.fulfilled, (state, action) => {
                state.visible = action.payload.visible
                state.sidebarWidth = action.payload.sidebarWidth
            })
            .addCase(showSidebar.fulfilled, (state, action) => {
                state.visible = action.payload.visible
                state.sidebarWidth = action.payload.sidebarWidth
            })
            .addCase(showSidebarForSmall.fulfilled, (state, action) => {
                state.visible = action.payload.visible
                state.sidebarWidth = action.payload.sidebarWidth
            })
            .addCase(hideSidebarForSmall.fulfilled, (state, action) => {
                state.visible = action.payload.visible
                state.sidebarWidth = action.payload.sidebarWidth
            })
    }
})

export const { reset } = navSlice.actions
export default navSlice.reducer
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { fontSize } from '../../../components/common/tools/Styles'

 const HMPieChart = (props) => {
    const options = {
      title: {
        text: props.title || 'Products categories',
        align: 'left',
        offsetX: 50,
        style: {
          fontSize:  fontSize + 2,
          fontWeight:  '700',
          color:  'rgba(47, 56, 84, 0.7)',
        },
      },
      chart: {
        width: 380,
        type: 'pie',
      },
      colors: props.colors || ['#FFA500', '#00AEEF', '#16D39A'],
      labels: props.labels || ['OTC', 'Health & Wellbeing', 'Prescription medication'],
      legend: {
        position: 'right',
        verticalAlign: 'center',
        offsetX: 1,
        offsetY: 50,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              offsetY: -20,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    return (
      <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
        type="pie"
      />
    )
  }

  export default HMPieChart;
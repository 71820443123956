import axios from 'axios'
const API_URL_POS_SALES = '/api/v1/pos_sales'

// A function that creates a POS sale 
const createPOSSale = async (newPOSSale, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL_POS_SALES, newPOSSale, config)

    const data = response.data

    return data 
}

// A function that gets all POS sales 
const getPOSSales = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_POS_SALES, config)

    const data = response.data

    return data 
}

// A function that gets POS sales for a single store
const getPOSSalesByStore = async (storeId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_POS_SALES}/${storeId}`, config)

    const data = response.data

    return data 
}

// A function that gets POS sales for a certain operator
const getPOSSalesByOperator = async (parmsObj, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const {
        storeId,
        opertorId
    } = parmsObj 

    const response = await axios.get(`${API_URL_POS_SALES}/opertor/${storeId}/${opertorId}`, config)

    const data = response.data

    return data 
}

// A function that gets a POS sale by ID 
const getPOSSale = async (parmsObj, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const {
        storeId,
        posSaleId,
    } = parmsObj 

    const response = await axios.get(`${API_URL_POS_SALES}/${storeId}/${posSaleId}`, config)

    const data = response.data

    return data 
}

// A function that updates a POS sale
const updatePOSSale = async (updatedPOSSale, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const {
        _id,
        store,
    } = updatedPOSSale

    const response = await axios.post(`${API_URL_POS_SALES}/${store}/${_id}`, updatedPOSSale, config)

    const data = response.data

    return data 
}

// A function that deletes a POS sale
const deletePOSSale = async (parmsObj, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const {
        storeId,
        posSaleId,
    } = parmsObj
    
    const response = await axios.delete(`${API_URL_POS_SALES}/${storeId}/${posSaleId}`, config)

    const data = response.data

    return data 
}

const posService = {
    getPOSSale,
    getPOSSales,
    updatePOSSale,
    deletePOSSale,
    createPOSSale,
    getPOSSalesByStore,
    getPOSSalesByOperator,
}

export default posService
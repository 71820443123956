import React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import HMOrderItem from './HMOrderItem'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
  fontSize,
  textStyle,
} from '../tools/Styles'
import HMOrderStatus from '../../order/HMOrderStatus'
import {
  currencies,
  storeDelivery,
} from '../../../hooks/helperFunctions'
import {
  blue,
  white,
  orange,
  lightRed,
  darkBlue,
  lightBlue,
  lightBlack,
  lightGreen,
} from '../../../hooks/useColors'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMOrderDetail(props) {
    const {
        store,
        status,
        quantity,
        subtotal,
        storeName,
        storeType,
        orderItems,
        deliveryOption,
        approvedInsurance,
    } = props.orderDetail
    
    return (
        <HMBox
            width='100%'
            display='flex'
            bgColor={white}
            color={lightBlack}
            margin='0 0 10px 0'
            className='fade-in'
            flexDirection='column'
        >
            <HMBox
                width='100%'
                display='flex'
            >
                {!props.isStore ? (
                    <>
                        <Link
                            onClick={props.navegateToStorePage}
                            sx={{ margin: 'auto 0', cursor: 'pointer' }}
                        >
                            <HMText
                                color={blue}
                                margin='auto 0'
                                text={storeName}
                                width='max-content'
                                fontSize={`${fontSize - 1}px`}
                            />
                        </Link>
                        <HMText
                            padding='0 10px'
                            text={storeType}
                            bgColor={lightBlue}
                            margin='auto 10px auto 5px'
                            fontSize={`${fontSize - 1}px`}
                        />
                    </>
                ) : <></>}
                {props.state !== 'intial_user_confirmation' && !props.needUserInteraction ? (
                    <HMOrderStatus 
                        status={status}
                        margin='auto 20px auto auto'
                        canChangeOrderStatus={props.isStore}
                        isStatusChanging={props.isStatusChanging}
                        handleChangingStatus={props.handleChangingStatus}
                        handleStatusChange={props.isStore && props.handleChangeStatusModal}
                    />
                ) : !props.isStore && props.needUserInteraction ? (
                    <HMBox
                        width='100%'
                        padding='10px'
                        display='flex'
                        cursor='pointer'
                        margin='10px 0 10px 10px'
                        hoverBgColor={lightGreen}
                        handleClick={props.handleActionAfterInsuranceValidationModal}
                        bgColor={
                            props.insuranceInfo.storeValidation[store].state.toLowerCase() === 'valid'
                                ? lightBlue
                                : lightRed
                        }
                    >
                        <HMText 
                            maxWidth='100%'
                            fontWeight={400}
                            width='max-content'
                            margin='auto auto auto 0'
                            fontSize={`${fontSize + 1}px`}
                            text={
                                props.insuranceInfo.storeValidation[store].state.toLowerCase() === 'valid'
                                    ? 'The store is awaiting for your action'
                                    : `Invalid Insurance : ${props.insuranceInfo.storeValidation[store].reason}`
                            }
                        />
                        <HMText 
                            color={white}
                            fontWeight={500}
                            padding='5px 10px'
                            bgColor={darkBlue}
                            width='max-content'
                            text='Confirm Order'
                            hoverBgColor='#2f3854e8'
                            margin='auto 0 auto 10px'
                            fontSize={`${fontSize}px`}
                            handleClick={props.handleActionAfterInsuranceValidationModal}
                        />
                    </HMBox>
                ) : <></>}
            </HMBox>
            {orderItems.map((item, index) => (
                <HMOrderItem 
                    key={index}
                    item={item}
                    approvedInsurance={approvedInsurance}
                    navegateToProductPage={() => props.navegateToProductPage(item)}
                    handleViewAddedInfoModal={() => props.handleViewAddedInfoModal(item.addedInfo)}
                    handleViewPrescription={() => props.handleViewPrescription(item.addedInfo.prescription)}
                    validation={
                        (props.insuranceInfo && 'storeValidation' in props.insuranceInfo) 
                            ? props.insuranceInfo.storeValidation[store].state.toLowerCase() 
                            : ''
                    }
                />
            ))}
            <HMBox
                width='100%'
                display='flex'
            >
                <HMText
                    margin='auto 5px auto 0'
                    fontSize={`${fontSize - 1}px`}
                    text={`${quantity} ${quantity > 1 ? 'items' : 'item'}, Store Total:`}
                />
                <HMText
                    color={orange}
                    fontWeight={500}
                    margin='auto auto auto 0'
                    fontSize={`${fontSize}px`}
                    text={`${currency} ${subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
                {!props.isStore ? (
                    <>
                        <HMText
                            margin='auto 5px auto auto'
                            fontSize={`${fontSize - 1}px`}
                            text={`${deliveryOption.type} delivery:`}
                        />
                        <HMText
                            padding='0 5px'
                            fontWeight={500}
                            margin='auto 10px auto 0'
                            fontSize={`${fontSize - 1}px`}
                            color={deliveryOption.type === storeDelivery[0].type ? blue : white}
                            bgColor={deliveryOption.type === storeDelivery[0].type ? lightBlue : blue}
                            text={`${currency} ${deliveryOption.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    </>
                ) : <></>}
            </HMBox>
            {(
                status.toLowerCase() === 'confirmed' || 
                status.toLowerCase() === 'delivered' || 
                status.toLowerCase() === 'received'
            ) ? (
                <HMBox
                    width='100%'
                    display='flex'
                > 
                    <HMButton 
                        type='button'
                        width='120px'
                        color={orange}
                        padding='0 5px'
                        hoverColor={white}
                        hoverBgColor={orange}
                        paddingActive='0 4.5px'
                        margin='10px 10px 10px auto'
                        border={`1px solid ${orange}`}
                        handleClick={props.handleOrderAgain}
                        text={<Typography sx={textStyle}>Buy again</Typography>} 
                    />
                </HMBox>
            ) : <></>}
        </HMBox>
    )
}

export default HMOrderDetail
import React from 'react'
import ReactApexChart from 'react-apexcharts'

 const HMLineChart = (props) => {
  
    const options = {
      chart: {
        height: 200,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      colors: props.colors || ['#00AEEF', '#16D39A', '#F78800'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      // title: {
      //   text: 'Income',
      //   align: 'left'
      // },
      grid: {
        show: true,
        borderColor: '#e7e7e7',
        xaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: ['#f9f9f9', 'transparent'],
          opacity: 0.5,
        },
        column: {
          colors: ['#f9f9f9', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        // title: {
        //   text: 'X-axis dumy title'
        // }
      },
      // yaxis: {
      //   title: {
      //     text: 'Y-axis dumy title'
      //   },
      //   min: 5,
      //   max: 40
      // },
      legend: {
        position: 'bottom',
        width: '100%',
      },
    }
  
    return (
      <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
      />
    )
  }

  export default HMLineChart;
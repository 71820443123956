import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import {
  Provinces, 
  Districts,
  Villages,
  Sectors, 
  Cells, 
} from 'rwanda'
import { 
  createUser, 
  resetUser, 
} from '../../../features/users/userSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMPasswordGenerator from '../../../components/common/tools/HMPasswordGenerator'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMDatePcker from '../../../components/common/tools/HMDatePcker'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import HMText from '../../../components/common/tools/HMText'
import {
  textStyle,
  addIconStyle,
  renoveIconStyle,
} from '../../../components/common/tools/Styles'
import { 
  accessLevels,
  generatePassword,
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  white,
  green,
  lightBlue
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'
import HMDatePicker from '../../../components/common/tools/HMDatePicker'

function CreateUser() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    user,
    isError,
    message,
    isCreated,
  } = useSelector(state => state.user)
    const {isUploaded, isUploading, isUploadError, file} = useSelector((state) => state.cloudinary)

  const breadOptions = [
    { text: 'Users', link: '/admin/users' },
    { text: 'Add a user', link: null }
  ]

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false
  
  const [userImageUrl, setUserImageUrl] = useState('')
  const [isFileUploading, setFileUploading] = useState(false)
  const [wrongNumber, setWrongNumber] = useState(false)
  const [formData, setFormData] = useState({
    phone: '',
    email: '', 
    gender: '',
    lastName: '',
    password: '', 
    firstName: '',
    birthdate: '',
    addresses: [''],
    hasNumber: false,
    hasSymbols: false,
    passwordLength: 5,
    hasUpperCase: false,
    hasLowerCase: false,
    accessLevel: { role: 'Customer', level: 100 },
    image: '',
  })
  const { 
    image,
    phone,
    email,
    gender,
    lastName,
    password,
    firstName,
    birthdate,
    addresses,
    hasNumber,
    hasSymbols,
    accessLevel,
    hasUpperCase,
    hasLowerCase,
    passwordLength,
  } = formData

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  useEffect(()=>{
    if(userImageUrl) setFileUploading(false)
  },[userImageUrl])

  useEffect(() => {
    if (isError || isCreated) {
      dispatch(resetUser())
    }

    if (isError) {
      toast.error(message) 
    } else if (isCreated) {
      toast.success('User created successfully')
      navigate(`/admin/users/${user._id}`)
    }

  }, [dispatch, navigate, message, isError, user, isCreated])

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const handleGeneratePassword = () => {
    setFormData((prevState) => ({
      ...prevState,
      password: generatePassword(hasNumber, hasSymbols, hasUpperCase, hasLowerCase, passwordLength)
    }))
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    if (name === 'phone') {
      if (value.length <= 10) {
        const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value)
        if (!isValid && value.length) {
          e.target.classList.add('wrong-input')
          setWrongNumber(true)
        } else if (isValid || !value.length) {
          e.target.classList.remove('wrong-input')
          setWrongNumber(false)
        } 
  
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
      }
    } 
    
    if (name !== 'phone') {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      image: userImageUrl,
    }))
  },[userImageUrl])

  const handleFieldsChange = (e) => {
    const id = e.target.id
    let value = null
    if (id === 'passwordLength') {
      value = (
        parseInt(e.target.value) >= 5 && parseInt(e.target.value) <= 15
        ) ? e.target.value : formData.passwordLength
    } else if (id === 'password') {

      value = e.target.value.length <= 15 ? e.target.value : password
    } 
    
    if (id !== 'password' && id !== 'passwordLength') {
      value = e.target.checked
    }
    
    if (value !== null) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: value
      }))
    }
  }

  const handleBirthdateChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      birthdate: newValue
    }))
  }

  const handleAccessLevelChangeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      accessLevel: val
    }))
  }

// =====================================================================================








  const handleProvinceChange = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      province: val ? val : '',
      cell: '',
      usage: '',
      street: '',
      sector: '',
      village: '',
      district: '',
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleDistrictChange = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      district: val ? val : '',
      cell: '',
      usage: '',
      street: '',
      sector: '',
      village: '',
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleSectorChange = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      sector: val ? val : '',
      cell: '',
      usage: '',
      street: '',
      village: '',
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleCellChange = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      cell: val ? val : '',
      usage: '',
      street: '',
      village: ''
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleVillageChange = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      village: val ? val : '',
      usage: '',
      street: '',
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleChangeStreet = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      street: val.target.value
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }
  const handleChangeUsage = (index, val) => {
    const addressValues = [...addresses]
    addressValues[index] = {
      ...addressValues[index],
      usage: val.target.value
    }

    setFormData((prevState) => ({
      ...prevState,
      addresses: addressValues
    }))
  }

  const handleRemoveAddress = (index) => {
    const addressValues = [...addresses]
    if (addresses.length > 1) {
      addressValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          addresses: addressValues
      }))
    }
  }
  const handleAddAddress = (index) => {
    const addressValues = [...addresses]
    addressValues.splice(index + 1, 0, {
      cell: '',
      usage: '',
      sector: '',
      street: '',
      village: '',
      district: '',
      province: '',
    })
    setFormData((prevState) => ({
        ...prevState,
        addresses: addressValues
    }))
  }











// =====================================================================================
  const genders = {
    question: 'Gender',
    choices: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' }
    ]
  }

  const minBirthdate = new Date()
  minBirthdate.setDate(minBirthdate.getDate() - 5840)

  const disabled = (
    email === '' || isFileUploading || 
    lastName.length < 4 ||
    firstName.length < 4 ||
    password.length < 5 ||
    wrongNumber === true 
  ) ? true : false
  
  const handleCreate = (e) => {
    e.preventDefault()

    const addressValues = []
    
    addresses.forEach(address => {
      let addressObj = {}
      let checker = true
      for (let key in address) {
        if (address[key]['name']) {
          addressObj[key] = address[key]['name']
          if (addressObj[key].name === '') {
            checker = false
          } 
        } else {
          addressObj[key] = address[key]
          if (addressObj[key] === '') {
            checker = false
          }
        }
      }
      if (checker) {
        addressValues.push(addressObj)
      }
    })

    const newUser = {
      email,
      image,
      gender,
      lastName,
      password,
      firstName,
      birthdate,
      phone: parseInt(phone),
      addresses: addressValues,
      accessLevel: accessLevel.level,
    }

    for (let key in newUser) {
      if (!newUser[key]) {
        delete newUser[key]
      }
    }

    dispatch(createUser(newUser))
  }

  return (
    <Page>
      <FullWidthContainer>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <HMBreadcrumbs 
              options={breadOptions}
            />
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
      </FullWidthContainer>
      <Section
        bgColor={white}
        padding={10}
      >
        <Grid 
          component='form'
          container spacing={2} 
          onSubmit={handleCreate}
        >
          <Grid item xs={12} sm={12}>
            <HMBox
              width='100%'
              display='flex'
              padding='10px'
              margin='30px 0 0 0'
              bgColor={lightBlue}
              flexDirection='column'
              maxWidth={`${isTabletScreen && '100% !important'}`}
            >
              <HMText 
                left='0'
                top='-35px'
                height='30px'
                bgColor={white}
                fontWeight={500}
                width='max-content'
                className='fade-in'
                position='relative'
                text='Upload your photo'
                margin='10px 0 -25px 20px'
                padding='5px 10px 0px 10px'
                border={`1px solid ${lightBlue}`}
              />
              <HMDropZone 
               cloudinaryFileUrl={userImageUrl}
                 setCloudinaryFileUrl = {setUserImageUrl}
                 endPoint = {cloudinary.API_URL_USERS}
                  bgColor={white}
                  className='drag-drop' 
                  supportedFiles = {{
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/png': []
                  }}
                  allowedSizeInMB={1}
              />
            </HMBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <HMTextField 
              name='firstName'
              required={true}
              value={firstName}
              label='FirstName'
              onChange={handleChange}
              placeholder='Enter fristname'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <HMTextField 
              name='lastName'
              required={true}
              value={lastName}
              label='LastName'
              onChange={handleChange}
              placeholder='Enter lastName'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <HMTextField 
              name='email'
              type='email'
              label='Email'
              value={email}
              required={true}
              onChange={handleChange}
              placeholder='Enter email'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <HMTextField 
              name='phone'
              type='number'
              value={phone}
              label='Phone number'
              onChange={handleChange}
              placeholder='Enter phone number'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <HMPasswordGenerator 
              password={password}
              hasNumber={hasNumber}
              hasSymbols={hasSymbols}
              hasUpperCase={hasUpperCase}
              hasLowerCase={hasLowerCase}
              showPassword={showPassword}
              passwordLength={passwordLength}
              handleFieldsChange={handleFieldsChange}
              handleGeneratePassword={handleGeneratePassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{display: 'flex'}}>
            <HMRadioButton 
              name='gender'
              value={gender}
              padding='2px 5px'
              flexDirection='row'
              onChange={handleChange}
              label={genders.question}
              options={genders.choices}
              labelId='require-prescription-radio-group'
              RadioflexDirection={isTabletScreen ? 'row' : 'column'}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{display: 'flex'}}
          >
            <HMBox
              width='100%'
              margin='auto'
              display='flex'
            >
              <HMText 
                width='40%'
                margin='auto'
                text='Birthdate'
                textAlign={isTabletScreen ? 'left' : 'center'}
              />
               <HMDatePicker
               value={birthdate}
                maxDate={minBirthdate}
                onChange={handleBirthdateChange}
                />
            </HMBox>
          </Grid>
          <Grid item xs={12} sm={3} sx={{display: 'flex'}}>
            <DropdownFilter
              width='100%' 
              field='role'
              label='Role'
              bgColor={white}
              mainMargin='auto'
              value={accessLevel}
              prompt='Select role'
              options={accessLevels}
              onChange={handleAccessLevelChangeChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <HMBox
              width='100%'
              display='flex'
              padding='10px'
              margin='30px 0 0 0'
              bgColor={lightBlue}
              flexDirection='column'
              maxWidth={`${isTabletScreen && '100% !important'}`}
            >
              <HMText 
                left='0'
                top='-35px'
                height='30px'
                bgColor={white}
                fontWeight={500}
                width='max-content'
                className='fade-in'
                position='relative'
                text='Address Information'
                margin='10px 0 -25px 20px'
                padding='5px 10px 0px 10px'
                border={`1px solid ${lightBlue}`}
              />
              <Grid 
                container spacing={2} 
              >
                {addresses.map((address, index) => (
                  <Grid item xs={12} sm={6} key={index} sx={{display: 'flex'}} >
                    <HMBox
                      margin='0'
                      width='100%'
                      display='flex'
                      bgColor={white}
                      flexDirection='column'
                      padding='0 10px 5px 10px'
                    >
                      <DropdownFilter 
                        field='name'
                        bgColor={white}
                        label='Province'
                        margin='10px 0 5px 0'
                        prompt='Select province...'
                        value={address.province ? address.province : ''}
                        onChange={(val) => handleProvinceChange(index, val)}
                        options={Provinces().map(item => ({name: item})).filter(province => province.name.toLowerCase() === 'kigali')}
                      />
                    {(address.province && address.province.name) && (
                        <DropdownFilter 
                          field='name'
                          margin='5px 0'
                          bgColor={white}
                          required={true}
                          label='District'
                          prompt='Select disctrict...'
                          value={address.district ? address.district : ''}
                          onChange={(val) => handleDistrictChange(index, val)}
                          options={Districts(address.province.name).map(item => ({name: item}))}
                        />
                      )}
                      {(address.district && address.district.name) && (
                        <DropdownFilter 
                          field='name'
                          label='Sector'
                          margin='5px 0'
                          required={true}
                          bgColor={white}
                          prompt='Select sector...'
                          value={address.sector ? address.sector : ''}
                          onChange={(val) => handleSectorChange(index, val)}
                          options={Sectors(address.province.name, address.district.name).map(item => ({name: item}))}
                        />
                      )}
                      {(address.sector && address.sector.name) && (
                        <DropdownFilter 
                          field='name'
                          label='Cell'
                          margin='5px 0'
                          required={true}
                          bgColor={white}
                          prompt='Select cell...'
                          value={address.cell ? address.cell : ''}
                          onChange={(val) => handleCellChange(index, val)}
                          options={Cells(address.province.name, address.district.name, address.sector.name).map(item => ({name: item}))}
                        />
                      )}
                      {(address.cell && address.cell.name) && (
                        <DropdownFilter 
                          field='name'
                          margin='5px 0'
                          label='Village'
                          required={true}
                          bgColor={white}
                          prompt='Select village...'
                          value={address.village ? address.village : ''}
                          onChange={(val) => handleVillageChange(index, val)}
                          options={Villages(address.province.name, address.district.name, address.sector.name, address.cell.name).map(item => ({name: item}))}
                        />
                      )}
                      {(address.village && address.village.name) && (
                        <>
                          <HMTextField 
                            name='street'
                            margin='5px 0'
                            bgColor={white}
                            required={true}
                            label='Street and house number'
                            placeholder='Enter street and house number'
                            value={address.street ? address.street : ''}
                            onChange={(val) => handleChangeStreet(index, val)}
                          />
                          <HMTextField 
                            name='street'
                            margin='5px 0'
                            bgColor={white}
                            required={true}
                            label='Use this address for'
                            placeholder='Enter the use of the address'
                            value={address.usage ? address.usage : ''}
                            onChange={(val) => handleChangeUsage(index, val)}
                          />
                        </>
                      )}
                    </HMBox>
                    {addresses.length > 1 && (
                      <IconButton 
                        onClick={() => handleRemoveAddress(index)}
                        sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                      >
                        <RemoveIcon sx={renoveIconStyle} />
                      </IconButton>
                    )}
                    {addresses.length < 5 && (
                      <IconButton   
                        onClick={() => handleAddAddress(index)} 
                        sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                      >
                        <AddIcon sx={addIconStyle} />
                      </IconButton>
                    )}  
                  </Grid>
                ))}
              </Grid>
            </HMBox>
          </Grid>
          <Grid item xs={12}>
            <HMButton 
              width='100%'
              type='submit'
              float='right'
              bgColor={green}
              disabled={disabled}
              margin='5px 0 5px 0'
              text={<Typography sx={textStyle}>Add user</Typography>}
            />
          </Grid> 
        </Grid>
      </Section>
    </Page>
  )
}

export default CreateUser
import React, { useState, useRef } from 'react'
import { Grid, Typography, Link } from '@mui/material'
import {
    fontSize,
    linksHodlerStyle,
} from '../tools/Styles'
import { 
    orange,
    lightBlack,
} from '../../../hooks/useColors'


function SidebarItem(props) {
    const parentSingleStyle = {
        fontSize: `${fontSize}px`,
        margin: props.margin || 0,
        width: props.width || '100%',
        color: props.color || lightBlack,
        display: props.display || 'flex',
        padding: props.padding || '7px 0',
        cursor: props.cursor || 'pointer',
        flexWrap: props.flexWrap || 'wrap',
        '&:hover': {
            color: props.hoverColor || orange,
        },
        '&:active': {
            margin: props.activeMargin || '0.2px',
            fontSize: props.activeFontSize || `${fontSize - 0.2}px`,
        }
    }
    const stylingText = {
        color: props.textColor || 'inherit',
        margin: props.textMargin || '2px auto',
        fontWeight: props.textFontWeight || 500,
        margin: props.textmargin || 'auto 0 auto 5px',
        fontSize: props.textFontSize || `${fontSize + 1}px`,
        textDecoration: props.textTextDecoration || 'none',
        '&:hover': {
            color: props.hoverTextColor || `${orange} !important`,
        },
    }
    
    const parentRef = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <>
            {
                props.children ? (
                    <>
                        <Grid 
                            sx={parentSingleStyle} 
                            onClick={() => {setIsMenuOpen(!isMenuOpen)}} 
                            className={isMenuOpen ? 'opened' : 'closed'}
                        >
                            { props.leftIcon }
                            <Typography sx={stylingText}>{ props.name }</Typography>
                            { props.rightIcon } 
                        </Grid>
                        <Grid 
                            ref={parentRef}
                            className='content'
                            style={ 
                                isMenuOpen 
                                ? {
                                    height: `${parentRef.current.scrollHeight}px`
                                } 
                                : {
                                    height: '0px'
                                }
                            }
                        >
                            <Grid sx={linksHodlerStyle}>
                                { props.children }
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid sx={parentSingleStyle} >
                            { props.leftIcon } 
                            <Link 
                                sx={stylingText} 
                                onClick={props.navigateUser} 
                                name={props.name.toLowerCase()} 
                            >
                                { props.name }
                            </Link>
                        </Grid>
                    </>
                )
            }       
        </>
    )
}

export default SidebarItem
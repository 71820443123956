import { useState, useEffect } from 'react'

function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      windowW: width,
      windowH: height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [hasWindow])

  return windowDimensions
}

export default useWindowDimensions
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import productsWithSupplierListingsService from './productsWithSupplierListingsService'

const initialState = {
    message: '',
    isError: false,
    isLoading: false,
    productWithSupplierListings: {},
    productsWithSupplierListings: [],
    isProductWithSupplierListings: false,
    areProductsWithSupplierListings: false,
}

// Get all products with supplier listings
export const getProductWithSupplierListings = createAsyncThunk(
    'productsWithSupplierListings/one',
    async (productId, thunkAPI) => {
        try {
            return await productsWithSupplierListingsService.getProductWithSupplierListings(productId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a product with supplier listings
export const getAllProductsWithSupplierListings = createAsyncThunk(
    'productsWithSupplierListings/all',
    async (_, thunkAPI) => {
        try {
            return await productsWithSupplierListingsService.getAllProductsWithSupplierListings()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const productsWithSupplierListingsSlice = createSlice({
    name: 'productsWithSupplierListings',
    initialState,
    reducers: {
        resetProductsWithSupplierListings: (state) => {
            state.message = ''
            state.isError = false
            state.isLoading = false
            state.isProductWithSupplierListings = false
            state.areProductsWithSupplierListings = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductWithSupplierListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isProductWithSupplierListings = false
            })
            .addCase(getProductWithSupplierListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isProductWithSupplierListings = true
                state.productWithSupplierListings = action.payload
            })
            .addCase(getProductWithSupplierListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isProductWithSupplierListings = false
            })

            .addCase(getAllProductsWithSupplierListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areProductsWithSupplierListings = false
            })
            .addCase(getAllProductsWithSupplierListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areProductsWithSupplierListings = true
                state.productsWithSupplierListings = action.payload
            })
            .addCase(getAllProductsWithSupplierListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areProductsWithSupplierListings = false
            })
    }
})

export const { resetProductsWithSupplierListings } = productsWithSupplierListingsSlice.actions
export default productsWithSupplierListingsSlice.reducer
import React from 'react'

import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Section from '../../components/common/layout/Section'
import FrontIcon from '@mui/icons-material/ArrowForward'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import Page from '../../components/common/layout/Page'
import HMTextField from '../../components/common/tools/HMTextField'
import Title from '../../components/common/tools/Title'
import AccessTime from '@mui/icons-material/AccessTime'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import Avatar from '@mui/material/Avatar'
import HMImage from '../../components/common/tools/HMImage'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'


import {
  textStyle,
} from '../../components/common/tools/Styles'
import {
  black,
  white,
  blue,
  orange,
  darkBlue,
  lightBlue,
  lightDarkBlue,
  lightBlack, 
} from '../../hooks/useColors'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import HMBox from '../../components/common/layout/HMBox'
import { iconStyle } from '../../components/common/tools/Styles'

function About() {
    const navigate = useNavigate()
    const breadOptions = [
        { text: 'Home', link: '/landing' },
        { text: 'About', link: null }
      ]
  return (
    <Page>
    <FullWidthContainer
    >
         <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
           <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 10px auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
        <Grid bgcolor={white} margin='inherit' container spacing={2}>
             <Section bgcolor={white}
                padding={10}>

                {/* About us section */}

                <Box width= '100%'
                    display="flex" 
                    marginBottom={6}
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center">
                        <Box width='60%' sx={{justifyContent: 'center'}}>   
                        <Title title='About Us' margin='revert' color={blue} float='initial' />
                        <HMText text='We are not only saving lives, but also money and time.' margin='revert' fontSize='14px' fontWeight='800' float='initial' color={darkBlue} />
                        <Box component="div" display="inline" fontSize='14px' float='initial' color={orange}>HarakaMeds </Box>
                        <Box component="div" display="inline" fontSize='14px' float='initial'  color={lightBlack}>is a fast medical and healthcare products delivery company based in kigali - Rwanda, which uses cutting edge technologies
                        to deliver Health Products to the comforts of its clients</Box>
                    </Box>
                </Box>

                {/* Client serverd section */}


                <Grid item xs={12} md={12}>
                <Box width= '100%'
                    display="flex" 
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box width='80%' sx={{backgroundColor:'#fff3e4', borderRadius: '16px',justifyContent: 'center'}}>   
                        <Grid padding='20px' marginBottom={4} container spacing={2}>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMText text='1000+' fontSize='14px' fontWeight='800' float='initial' color={blue} />
                                <HMText text='Clients Served' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMText text='300%' fontSize='14px' fontWeight='800' float='initial' color={blue} />
                                <HMText text='Return on investment' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMText text='200+' fontSize='14px' fontWeight='800' float='initial' color={blue} />
                                <HMText text='Patnered Stores & Pharmacies' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                            <Button 
                                    type='button'
                                    float='initial'
                                    sx={{width: '100%',bgcolor:'#00aeef',"&:hover": {
                                        opacity: 0.8,
                                        bgcolor:'#00aeef'
                                      },}}
                            >
                                <Box     sx={{width: 'inherit'}} component="div" display="inline" fontSize='14px' float='initial' color={white}>FDA Licensed  </Box>
                                <Box component="div" display="inline" fontSize='14px' float='initial'  >
                                <FrontIcon sx={{color:'white'}} />
                            </Box>
                            </Button>
                            
                            </Grid>
                        </Grid>
                        <HMText text='It’s just the begining, the best is yet to come' margin={3} fontSize='14px' fontWeight='800' float='initial' color={darkBlue} />
                    </Box>
                </Box>
                </Grid>

                {/* Our value section */}


                <Box width= '100%'
                    display="flex" 
                    padding={5}
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center">
                        <Box width='60%' sx={{justifyContent: 'center'}}>   
                        <Title title='Our Values' margin='revert' color={blue} float='initial' />
                        <HMText text='At HarakaMeds, we value all sort of things! But there are some of them we cherish, protect and ensure.' fontSize='14px' fontWeight='800' float='initial' color={darkBlue} />
                    </Box>
                </Box>

                <Grid item xs={12} md={12}>
                <Box width= '100%'
                    display="flex" 
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box width='80%' sx={{ borderRadius: '16px',justifyContent: 'center'}}>   
                        <Grid padding='20px' container spacing={2}>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid item margin='auto' xs={12} md={2.7} sm={5.5}>
                                <Avatar sx={{ bgcolor: blue,marginRight: 'auto',marginLeft: 'auto' }}>
                                    <AccessTime sx={{ color: white }} />
                                </Avatar>
                                <Title title='Time management' color={black} fontWeight='500' float='initial' />
                                <HMText text='We highly respect and manage our time closely for us to be able to deliver our services on time' fontSize='12px' float='initial' color={lightBlack} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                </Grid>


                {/* Trusted by the big names section */}


                <Grid item marginBottom={4} xs={12} md={12}>
                <Box width= '100%'
                    display="flex" 
                    margin='inhert'
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box width='80%' margin='inhert' sx={{backgroundColor:'#F1F1F1', borderRadius: '16px',justifyContent: 'center'}}>  
                    <Title title='Trusted by the big names' margin='15px' color={lightBlack} float='initial' /> 
                        <Grid padding='20px' container spacing={2}>
                            <Grid item xs={12} md={3} sm={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <HMImage
                                src="https://res.cloudinary.com/basha18/image/upload/v1657904643/Ellipse_31_ib62b5.png"
                                alt="user image"
                                borderRadius='50%'
                                height="20vh"
                                width="20vh"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657904190/zJKeLEit_400x400_zl6qmj.jpg"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMImage
                                src="https://res.cloudinary.com/basha18/image/upload/v1657864174/45B7318_arxxj1.webp"
                                alt="user image"
                                borderRadius='50%'
                                height="20vh"
                                width="20vh"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657904380/isometric-pharmacy-store-doctor-pharmacist-patient-woman-pharmacist-holding-prescription-checking-medicine-pharmacy-155804643_ma0mmu.jpg"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                </Grid>

                {/* Our Team section */}


                <Box width= '100%'
                    display="flex" 
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center">
                        <Box width='60%' sx={{justifyContent: 'center'}}>   
                        <Title title='Our Team' margin='revert' color={blue} float='initial' />
                        <HMText text='We believe that an outstanding team is a sound foundation for the growth of any company
                         out there. At HarakaMeds, we managed to gather a great team of Pharmacists, Economists, Technologists
                          and more. Meet our team!' margin='revert' fontSize='14px' fontWeight='800' float='initial' color={darkBlue} />
                    </Box>
                </Box>
                <Grid item xs={12} md={12} sm={12}>
                <Box width= '100%'
                    display="flex" 
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box width='80%'  sx={{ borderRadius: '16px',justifyContent: 'center'}}>   
                        <Grid padding='20px' container spacing={2}>
                        <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto'marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905014/Ellipse_40_hoagn1.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Aristide Y. I. Karasira' color={black} float='initial' />    
                                <HMText text='Pharmacist' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Managing Director' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto'marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905014/Ellipse_39_w5bphd.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Rene U. Ishimwe' color={black} float='initial' />    
                                <HMText text='Pharmacist' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Chief Operating Officer' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto' marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905014/Ellipse_41_xe6mc9.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Olivier Habimana' color={black} float='initial' />    
                                <HMText text='Software Engineer' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Chief Technology Officer' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto' marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905014/Ellipse_42_rx8lkc.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Cynthia Uwicyeza' color={black} float='initial' />    
                                <HMText text='Consultant' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Partnership Development Specialist' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto' marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905016/Ellipse_47_uwezsx.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Cynthia Mulindi' color={black} float='initial' />    
                                <HMText text='Accountant' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Financial Advisor/Social Work Lead' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto' marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905014/Ellipse_48_btb5ds.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Nyirabisabo Vanessa ' color={black} float='initial' />    
                                <HMText text='Pharmacist' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Sales support operator' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                            <Grid borderRadius={5} padding='12px' marginLeft='auto' marginRight='auto' marginBottom={4} bgcolor={lightBlue} item xs={12} md={2.7} sm={5}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657905015/Ellipse_49_n4cixv.png"
                                    alt="user image"
                                    borderRadius='50%'
                                    height="20vh"
                                    width="20vh"
                                    />
                                <Title title='Fabien Niringiyimana' color={black} float='initial' />    
                                <HMText text='PSoftware Developer & Designer' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='Product Designer' fontSize='12px' float='initial' color={blue} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                </Grid>

                {/* Activity and Event section */}

                <Box width= '100%'
                    display="flex" 
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center">
                        <Box width='60%' sx={{justifyContent: 'center'}}>   
                        <Title title='Activities and Events' margin='revert' color={blue} float='initial' />
                        <HMText text='We usually organize different events - say humanitarian,
                         business meetups and more. Get to know the lifestyle at HarakaMeds' margin='revert' fontSize='14px' fontWeight='800' float='initial' color={darkBlue} />
                    </Box>
                </Box>
                <Grid item xs={12} md={12}>
                <Box width= '100%'
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box width='80%'  sx={{ borderRadius: '16px',justifyContent: 'center'}}>  
                        <Grid padding='20px' container spacing={2}>
                            <Grid borderRadius={4} padding='15px' marginLeft={2} marginBottom={4} bgcolor={lightDarkBlue} item xs={12} md={5.7}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657828073/WhatsApp_Image_2022-07-11_at_6.47.21_PM_mqjoyh.jpg"
                                    alt="user image"
                                    />
                                <Grid container spacing={3} justify="space-between">
                                    <Grid item xs={9}>
                                    <HMText text='Team meetup and lunch at Marriott Hotel' fontWeight='800' sx={{fontSize:'12px'}} color={darkBlue} /> 
                                    </Grid>
                                    <Grid item xs={3}>
                                    <HMText text='1 day ago' fontSize='12px' float='right' color={blue} />
                                    </Grid>
                                </Grid>
                                <Divider color={orange} width='40%' sx={{ borderBottomWidth: '2px' }} />   
                                <HMText text='We believe that an outstanding team is a sound foundation for the growth of any company out 
                                there. At HarakaMeds, we managed to gather a great team of Pharmacists, Economists, Technologists
                                 and more. Meet our team!' fontSize='14px' float='initial' color={lightBlack} />
                            </Grid>
                            <Grid borderRadius={4} padding='15px' marginLeft={2} marginBottom={4} bgcolor={lightDarkBlue} item xs={12} md={5.7}>
                                <HMImage
                                    src="https://res.cloudinary.com/basha18/image/upload/v1657828074/WhatsApp_Image_2022-07-11_at_6.47.35_PM_huspe8.jpg"
                                    alt="user image"
                                    />
                                <Grid container spacing={3} justify="space-between">
                                    <Grid item xs={9}>
                                    <HMText text='Team meetup and lunch at Marriott Hotel' fontWeight='800' sx={{fontSize:'12px'}} color={darkBlue} /> 
                                    </Grid>
                                    <Grid item xs={3}>
                                    <HMText text='1 day ago' fontSize='12px' float='right' color={blue} />
                                    </Grid>
                                </Grid>
                                <Divider color={orange} width='40%' sx={{ borderBottomWidth: '2px' }} />   
                                <HMText text='We believe that an outstanding team is a sound foundation for the growth of any company out 
                                there. At HarakaMeds, we managed to gather a great team of Pharmacists, Economists, Technologists
                                 and more. Meet our team!' fontSize='14px' float='initial' color={lightBlack} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                </Grid>

                {/* got something on mind? section */}


                <Grid item marginBottom={4} xs={12} md={12}>
                <Box width= '100%'
                    display="flex" 
                    margin='inhert'
                    justifyContent="center"
                    >
                    <Box width='80%' sx={{marginLeft:'auto',marginRight:'auto',backgroundColor:'#EFFBFF', borderRadius: '16px',justifyContent: 'center'}} margin='inhert'>  
                    <Title textAlign='start' title='Got something on mind?' margin='17px 36px 12px;' color={blue} float='initial' /> 
                        <Grid padding='20px' sx={{marginLeft:'auto',marginRight:'auto'}}  container spacing={2}>
                        <Grid borderRadius={1} padding='12px' marginLeft={2} marginBottom={4} bgcolor={white} item xs={12} md={2.7} sm={5.3}>
                                <Box 
                                    width='20%'
                                    bgcolor={orange}
                                    borderRadius={2}
                                    marginBottom={6}
                                    display="flex" 
                                    padding='5px'
                                    justifyContent="center"
                                >
                                    <EmailOutlinedIcon sx={{ color: white }} display='block'/>
                                </Box>
                                <HMText text='Email Us your concern' marginTop={4} textAlign='start' fontWeight='800' fontSize='14px' color={darkBlue} float='initial' />    
                                <HMText text='Caring for customers is our area of strength at Harakameds, just mail us. You will leave with testmonies.' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='sales@harakameds.com' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid borderRadius={1} padding='12px' marginLeft={2} marginBottom={4} bgcolor={white} item xs={12} md={2.7} sm={5.3}>
                                <Box 
                                    width='20%'
                                    bgcolor={orange}
                                    borderRadius={2}
                                    marginBottom={6}
                                    display="flex" 
                                    padding='5px'
                                    justifyContent="center"
                                >
                                    <LocationOnOutlinedIcon sx={{ color: white }} display='block'/>
                                </Box>
                                <HMText text='Meet out team' marginTop={4} textAlign='start' fontWeight='800' fontSize='14px' color={darkBlue} float='initial' />    
                                <HMText text='Come and meet our friendly team at our headquarters' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='KN 173 St' fontSize='12px' float='initial' color={orange} />
                                <HMText text='Rwanda, Kigali - Nyarugenge' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid borderRadius={1} padding='12px' marginLeft={2} marginBottom={4} bgcolor={white} item xs={12} md={2.7} sm={5.3}>
                                <Box 
                                    width='20%'
                                    bgcolor={orange}
                                    borderRadius={2}
                                    marginBottom={6}
                                    display="flex" 
                                    padding='5px'
                                    justifyContent="center"
                                >
                                    <LocalPhoneOutlinedIcon sx={{ color: white }} display='block'/>
                                </Box>
                                <HMText text='Call Us' marginTop={4} textAlign='start' fontWeight='800' fontSize='14px' color={darkBlue} float='initial' />    
                                <HMText text='Caring for customers is our area of strength at Harakameds, just mail us. You will leave with testmonies.' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='+250 785 187 855' fontSize='12px' float='initial' color={orange} />
                                <HMText text='+250 783 454 761' fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            <Grid borderRadius={1} padding='12px' marginLeft={2} marginBottom={4} bgcolor={white} item xs={12} md={2.7} sm={5.3}>
                                <Box 
                                    width='20%'
                                    bgcolor={orange}
                                    borderRadius={2}
                                    marginBottom={6}
                                    display="flex" 
                                    padding='5px'
                                    justifyContent="center"
                                >
                                    <WhatsAppIcon sx={{ color: white }} display='block'/>
                                </Box>
                                <HMText text='Text us on WhatsApp' marginTop={4} textAlign='start' fontWeight='800' fontSize='14px' color={darkBlue} float='initial' />    
                                <HMText text='Caring for customers is our area of strength at Harakameds, just mail us. You will leave with testmonies.' fontSize='12px' float='initial' color={lightBlack} />
                                <HMText text='+250 785 187 855'fontSize='12px' float='initial' color={orange} />
                            </Grid>
                            
                        </Grid>

                    </Box>
                </Box>
                </Grid>
          </Section>
         </Grid>
         
    </FullWidthContainer>
  </Page>
  )
}

export default About
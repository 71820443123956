import axios from 'axios'

const API_URL_PRODUCTS_RELATIONS_PUBLIC = '/api/v1/products_with_storeListings/public'
const API_URL_PRODUCTS_RELATIONS_PRIVATE = '/api/v1/products_with_storeListings/private'

// Get all stores with store listings for public use
const getProductWithStoreListings = async (productId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${API_URL_PRODUCTS_RELATIONS_PRIVATE}/${productId}`, config)

    const data = response.data

    return data
}

// Get a store with store listings for public use
const getProductsWithStoreListings = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL_PRODUCTS_RELATIONS_PRIVATE, config)

    const data = response.data

    return data
}


// Get all stores with store listings
const getPublicProduct = async (productId) => {
    const response = await axios.get(`${API_URL_PRODUCTS_RELATIONS_PUBLIC}/${productId}`)

    const data = response.data

    return data
}

// Get a store with store listings
const getAllPublicProducts = async () => {
    const response = await axios.get(API_URL_PRODUCTS_RELATIONS_PUBLIC)

    const data = response.data

    return data
}

const productsWithStoreListingsService = {
    getPublicProduct,
    getAllPublicProducts,
    getProductWithStoreListings,
    getProductsWithStoreListings,
}
export default productsWithStoreListingsService
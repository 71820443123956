import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import productsWithStoreListingsService from './productsWithStoreListingsService'

const initialState = {
    message: '',
    isError: false,
    isLoading: false,
    publicProduct: {},
    publicProducts: [],
    isPublicProduct: false,
    arePublicProducts: false,
    productWithStoreListings: {},
    productsWithStoreListings: [],
    isProductWithStoreListings: false,
    areProductsWithStoreListings: false,
}

// Get all products with store listings for public use
export const getPublicProduct = createAsyncThunk(
    'productsWithStoreListings/publicOne',
    async (productId, thunkAPI) => {
        try {
            return await productsWithStoreListingsService.getPublicProduct(productId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a product with store listings for public use
export const getAllPublicProducts = createAsyncThunk(
    'productsWithStoreListings/publicAll',
    async (_, thunkAPI) => {
        try {
            return await productsWithStoreListingsService.getAllPublicProducts()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all products with store listings
export const getProductWithStoreListings = createAsyncThunk(
    'productsWithStoreListings/one',
    async (productId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productsWithStoreListingsService.getProductWithStoreListings(productId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a product with store listings
export const getAllProductsWithStoreListings = createAsyncThunk(
    'productsWithStoreListings/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productsWithStoreListingsService.getAllProductsWithStoreListings(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const productsWithStoreListingsSlice = createSlice({
    name: 'productsWithStoreListings',
    initialState,
    reducers: {
        resetProductsWithStoreListings: (state) => {
            state.message = ''
            state.isError = false
            state.isLoading = false
            state.isPublicProduct = false
            state.arePublicProducts = false
            state.isProductWithStoreListings = false
            state.areProductsWithStoreListings = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPublicProduct.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isPublicProduct = false
            })
            .addCase(getPublicProduct.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isPublicProduct = true
                state.publicProduct = action.payload
            })
            .addCase(getPublicProduct.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isPublicProduct = false
                state.message = action.payload
            })

            .addCase(getAllPublicProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.arePublicProducts = false
            })
            .addCase(getAllPublicProducts.fulfilled, (state, action) => {
                state.isError = false
                state.arePublicProducts = true
                state.isLoading = false
                state.publicProducts = action.payload
            })
            .addCase(getAllPublicProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.arePublicProducts = false
            })

            .addCase(getProductWithStoreListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isProductWithStoreListings = false
            })
            .addCase(getProductWithStoreListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isProductWithStoreListings = true
                state.productWithStoreListings = action.payload
            })
            .addCase(getProductWithStoreListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isProductWithStoreListings = false
            })

            .addCase(getAllProductsWithStoreListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areProductsWithStoreListings = false
            })
            .addCase(getAllProductsWithStoreListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areProductsWithStoreListings = true
                state.productsWithStoreListings = action.payload
            })
            .addCase(getAllProductsWithStoreListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areProductsWithStoreListings = false
            })
    }
})

export const { resetProductsWithStoreListings } = productsWithStoreListingsSlice.actions
export default productsWithStoreListingsSlice.reducer
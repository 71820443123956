import React from 'react'
import { Link } from 'react-router-dom'

import { 
    blue, 
    orange 
} from '../../../hooks/useColors'

function HMLogo (props) {
    const logoStyle = {
        margin: props.margin || '10px auto'
    }

    return (
        <>
            {props.isOtherLogo ? (
                <>
                    { props.logo } 
                </> 
            ) : (
                <Link to='/landing' style={logoStyle}>
                    <h2 style={{ marigin: 'auto' }}>
                        <span style={{color: props.leftColor || blue, fontSize: props.fontSize }}>Haraka</span>
                        <span style={{color: props.rightColor || orange, fontSize: props.fontSize }}>Meds</span>
                    </h2>
                </Link>
            )}
        </>
    )
}

export default HMLogo
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { 
    getCategories, 
    resetCategory 
} from '../../../features/categories/categorySlice'
import HMBox from './HMBox'
import DropdownFilter from '../tools/DropdownFilter'
import HMTextField from '../tools/HMTextField'
import HMDropZone from '../tools/HMDropZone'
import HMButton from '../tools/HMButton'
import HMEditor from '../tools/HMEditor'
import HMText from '../tools/HMText'
import {
    textStyle,
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    white,
    green,
    lightBlue,
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'

function HMProductCreationFileds(props) {
    const dispatch = useDispatch()

    const categoryState = useSelector((state) => state.category)
    const { categories } = categoryState
    
    useEffect(() => {
        if (!categoryState.categories.length) {
            dispatch(getCategories())
        }

        if (categoryState.success || categoryState.isError) {
            dispatch(resetCategory())
        }
    }, [categoryState])
    

    const {
        name,
        brand,
        dosage,
        parent,
        category,
        strength,
        description,
        sideEffects,
        insurancePrice,
    } = props.formData

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 720 ? true : false
    const isMediumScreen = windowW < 900 ? true : false

    return (
        <Grid 
            container 
            component='form'
            onSubmit={props.handleSubmit}
            spacing={isMediumScreen ? 0 : 1} 
        >
            <Grid item xs={12}>
                <HMBox
                    width='100%'
                    display='flex'
                    padding='10px'
                    margin='30px 0px'
                    bgColor={lightBlue}
                    flexDirection='column'
                    maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                    <HMText 
                        left='0'
                        top='-35px'
                        height='30px'
                        bgColor={white}
                        fontWeight={500}
                        width='max-content'
                        className='fade-in'
                        position='relative'
                        margin='10px 0 -25px 20px'
                        padding='5px 10px 0px 10px'
                        text='Upload product image'
                        border={`1px solid ${lightBlue}`}
                    />
                    <HMDropZone 
                        bgColor={white}
                        allowedSizeInMB={1}
                        className='drag-drop' 
                        supportedFiles={props.supportedFiles}
                        endPoint = {cloudinary.API_URL_PRODUCTS}
                        cloudinaryFileUrl = {props.productImageUrl}
                        setCloudinaryFileUrl = {props.setProductImageUrl}
                    />
                </HMBox>
            </Grid> 
            <Grid item xs={12} md={4} className='fade-in'>
                <HMTextField 
                    name='name'
                    value={name}
                    label='Name'
                    required={true}
                    onChange={props.handleChange}
                    placeholder='Enter product name'
                />
            </Grid>
            <Grid item xs={12} md={4} className='fade-in'>
                <HMTextField 
                    name='brand'
                    value={brand}
                    label='Brand'
                    onChange={props.handleChange}
                    placeholder='Enter product name'
                />
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} className='fade-in'>
                <HMTextField 
                    value={parent}
                    disabled={true}
                    label='Category'
                    className='not-allowed'
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DropdownFilter 
                    id='_id'
                    field='name'
                    required={true}
                    value={category}
                    label='Classification'
                    prompt='Select class...'
                    onChange={props.handleDrowdownChange}
                    options={categories.filter(category => category.parent !== undefined)}
                />
            </Grid>
            {!parent.toLowerCase().includes('health') ? (
                <Grid item xs={12} md={4} className='fade-in'>
                    <HMTextField 
                        type='number'
                        name='insurancePrice'
                        label='Insurance Price'
                        value={insurancePrice}
                        onChange={props.handleChange} 
                        placeholder='Enter insurance price'
                    />
                </Grid>
            ) : <Grid item xs={12} md={4}></Grid>}
            <Grid item xs={12} md={4} className='fade-in'>
                <HMTextField 
                    name='strength'
                    label='Strength'
                    value={strength}
                    onChange={props.handleChange} 
                    placeholder='Enter strengths of the product separated by a semi colon (" ; ")'
                />
            </Grid>
            <Grid item xs={12} md={4} className='fade-in'>
                <HMTextField 
                    name='dosage'
                    label='Dosage'
                    value={dosage}
                    onChange={props.handleChange} 
                    placeholder='Enter dasage of the product'
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <HMBox
                    width='100%'
                    display='flex'
                    padding='10px'
                    bgColor={lightBlue}
                    flexDirection='column'
                    margin='20px auto auto auto'
                    maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                    <HMText 
                        left='0'
                        top='-35px'
                        width='135px'
                        height='30px'
                        bgColor={white}
                        fontWeight={500}
                        className='fade-in'
                        position='relative'
                        text='Side Effects'
                        margin='10px 0 -25px 20px'
                        padding='5px 10px 0px 10px'
                        border={`1px solid ${lightBlue}`}
                    />
                    <TextareaAutosize 
                        required={true}
                        name='sideEffects'
                        value={sideEffects}
                        label='Side Effects'
                        onChange={props.handleChange}
                        aria-label='empty textarea'
                        placeholder='Side effects of the product'
                        style={{ 
                            width: '100%', 
                            border: '0px',
                            padding: '10px', 
                            maxWidth: '100%', 
                            margin: '10px auto',
                            borderRadius: '8px'
                        }}
                    />
                </HMBox>
            </Grid>
            <Grid item xs={12}> 
                <HMEditor 
                    value={description}
                    onBlur={props.handleEditorChange}
                    placeholder='Enter description here...'
                />         
            </Grid>
            <Grid item xs={12}>
                <HMButton 
                    width='100%'
                    type='submit'
                    margin='5px 0'
                    disabled={props.disabled}
                    bgColor={props.buttonColor || green}
                    text={<Typography sx={textStyle}>{props.buttonText || 'Add product'}</Typography>}
                />
            </Grid> 
        </Grid>
    )
}

export default HMProductCreationFileds
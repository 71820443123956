import React from 'react'
import parse from 'html-react-parser'
import HMText from './HMText'
import Title from './Title'
import { fontSize } from './Styles'
import HMBox from '../layout/HMBox'
import { lightBlue } from '../../../hooks/useColors'

function ProductDetail(props) {
    return props.text ? (
        <HMBox
            display='flex'
            margin={props.margin}
            width={props.width || '100%'}
            padding={props.padding || '0'}
            flexDirection={props.flexDirection || 'column'}
        >
            <Title
                width='100%'
                textAlign='left'
                title={props.title}
                size={props.titleSize || fontSize}
                margin={props.titleMargin || '10px 0 5px 0'}
            />
            <HMBox
                width='100%'
                display='flex'
                bgColor={props.bgColor || lightBlue}
                minHeight={props.title.toLowerCase() === 'description' ? '100px' : 'auto'}
                className={props.title.toLowerCase() === 'description' ? 'description' : props.className}
            >
                {props.title.toLowerCase() === 'description' ? (
                    <div>
                        {parse(props.text)}
                    </div>
                ) : (
                    <HMText
                        margin='auto 0'
                        text={props.text}
                    />
                )}
            </HMBox>
        </HMBox>
    ) : <></>
}

export default ProductDetail
import React, { useState } from 'react'
import { Typography, Grid } from '@mui/material'
import DropdownFilter from '../common/tools/DropdownFilter'
import HMTextField from '../common/tools/HMTextField'
import HMButton from '../common/tools/HMButton'
import { 
    textStyle 
} from '../common/tools/Styles'
import {
    orange,
    gray
} from '../../hooks/useColors'

function CategoryForm({ 
    onSubmit, 
    className,
    categories, 
    handleClick,
    currentCategory, 
    currentParentCategory, 
}) {
    const [formData, setFormData] = useState({
        name: Object.keys(currentCategory).length ? currentCategory.name : '',
        parent: Object.keys(currentParentCategory).length !== 0 ? currentParentCategory._id : ''
    })
    const initialData = Object.keys(currentParentCategory).length !== 0 ? currentParentCategory: ''
    const [category, setCategory] = useState(initialData)

    const {  
        name
    } = formData

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            name: e.target.value
        }))
    }
    const handleDrowdownChange = (val) => {
        setCategory(val)
        setFormData((prevState) => ({
            ...prevState,
            parent: val && val._id ? val._id : ''
        }))
    }

    return (
        <Grid 
            component='form'
            className={className}
            sx={{minWidth: '200px'}}
            onSubmit={(e) => onSubmit(e, formData)}
        >
                <HMTextField 
                    type='text'
                    name='name'
                    value={name}
                    width='300px'
                    required={true}
                    label='Category'
                    onChange={onChange}
                    placeholder='Enter a category name'
                />
                <DropdownFilter 
                    id='_id'
                    field='name'
                    label='Parent'
                    value={category}
                    prompt='Select parent...'
                    onChange={handleDrowdownChange}
                    options={categories.filter(category => !category.parent)}
                />
                <HMButton 
                    float='left'
                    type='button'
                    bgColor={gray}
                    handleClick={handleClick}
                    margin='10px auto auto auto'
                    text={<Typography sx={textStyle}>Cancel</Typography>}
                />
                <HMButton 
                    type='submit'
                    float='right'
                    bgColor={orange}
                    margin='10px auto auto auto'
                    text={<Typography sx={textStyle}>Submit</Typography>}
                />
        </Grid>
    )
}

export default CategoryForm
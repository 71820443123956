import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import {
  resetUser,
  getUserByEmail,
} from '../../../features/users/userSlice'
import { 
  reset, 
  forgotPassword 
} from '../../../features/auth/authSlice'
import HMBox from './HMBox'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import HMSpiner from '../tools/HMSpiner'
import HMAlert from '../tools/HMAlert'
import HMText from '../tools/HMText'
import {
  fontSize,
  textStyle,
} from '../tools/Styles'
import {
  blue,
  orange,
  lightRed,
  lightBlue,
  lightGreen,
} from '../../../hooks/useColors'
import { 
    hideEmail,
    isValidEmail 
} from '../../../hooks/helperFunctions'

function HMForgotPasswordForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userState = useSelector((state) => state.user)
    const { 
        isError, 
        isForgotPassword, 
        forgotPasswordResponse
    } = useSelector((state) => state.auth)

    const path = window.location.pathname
    const [email, setEmail] = useState('')
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [isExistingEmail, setIsExistingEmail] = useState(false)

    useEffect(() => {
        if (isForgotPassword) {
            setEmail('')
            dispatch(reset())
            setIsEmailSent(true)
        }

        if (isError) {
            dispatch(reset())
        }
        
        // eslint-disable-next-line
    }, [isError, isForgotPassword])

    useEffect(() => {
        if (userState.isOneByEmail) {
            if (userState.user && Object.keys(userState.user).length && email === userState.user.email) {
                setIsEmailSent(false)
                setIsExistingEmail(true)
            } else {
                setIsExistingEmail(false)
            }
        }

        if (userState.isOneByEmail || userState.isError) {
            dispatch(resetUser())
        }
        
        // eslint-disable-next-line
    }, [userState])

    useEffect(() => {
        if (isValidEmail(email)) {
            dispatch(getUserByEmail({ email }))
        } 
        setIsExistingEmail(false)
        
        // eslint-disable-next-line
    }, [email])

    const handleLogin = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(true)
            props.setIsRegister(false)
            props.setIsForgotPassword(false)
        } else {
            navigate(
                path.includes('admin') ? '/admin/login'
                    : path.includes('store/') ? '/store/login'
                    : path.includes('supplier') ? 'supplier/login'
                    : '/login'
            )
        }
    }

    const handleRegister = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(false)
            props.setIsRegister(true)
            props.setIsForgotPassword(false)
        } else {
            navigate('/register')
        }
    }

    const onChange = (e) => {
        setEmail(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(forgotPassword({ email }))
    }

    const isCustomer = (!path.includes('admin') && !path.includes('store/') && !path.includes('supplier')) ? true : false

    return (
        <Grid align='center' sx={{ margin: 'auto' }}>
            <HMBox 
                padding='0'
                width='100%'
                height='70px'
                display='flex'
                margin='auto auto 10px auto'
            >
                {isEmailSent ? (
                    <HMAlert
                        success={true}
                        margin='10px auto'
                        textAlign='justify'
                        bgColor={lightGreen}
                        fontSize={fontSize - 2}
                        text={`${forgotPasswordResponse.message}: ${hideEmail(forgotPasswordResponse.email)}`}
                    />
                ) : (isExistingEmail) ? (
                    <HMAlert
                        good={true}
                        margin='10px auto'
                        textAlign='justify'
                        bgColor={lightBlue}
                        fontSize={fontSize - 2}
                        text='If you have access to this email. Click on the send email button below.'
                    />
                ) : (isValidEmail(email) && !isExistingEmail) ? (
                    <HMAlert
                        error={true}
                        margin='10px auto'
                        textAlign='justify'
                        bgColor={lightRed}
                        fontSize={fontSize - 2}
                        text='We cannot find your email in our system. Please double check!'
                    />
                ) : (
                    <> 
                        <Divider
                            flexItem
                            orientation='vertical'
                            sx={{
                                mr: 1,
                                height: 70,
                                margin: 'auto 0',
                                background: orange,
                                borderRightWidth: 5,
                                borderRadius: '3px',
                            }}
                        />
                        <HMText
                            width='100%'
                            textAlign='justify'
                            margin='auto 0 auto 10px'
                            text='In the field below, input the email you used upon registering, and we
                            will send you a password reset link.'
                        />
                    </>
                )}
            </HMBox>
            <form onSubmit={onSubmit}>
                <HMBox
                    width='100%'
                    display='flex'
                    padding='5px 0'
                    position='relative'
                >
                    {(userState.isLoading && !isExistingEmail) ? (
                        <HMSpiner 
                            top='35%'
                            size={20}
                            right='5px'
                            position='absolute'
                        />
                    ) : <></>}
                    <HMTextField 
                        name='email' 
                        type='email'
                        label='Email'
                        value={email}
                        required={true}
                        className='fade-in'
                        onChange={onChange}
                        placeholder='Enter your email'
                    />
                </HMBox>
                <HMButton 
                    width='100%'
                    type='submit'
                    bgColor={blue}
                    text='Send Email'
                    margin='10px auto'
                    className='fade-in'
                    disabled={!isExistingEmail}
                />
            </form>
            <HMBox
                width='100%'
                display='flex'
                className='fade-in'
            >
                <Typography 
                    sx={{
                        ...textStyle,
                        margin: isCustomer ? 'auto 0' : 'auto'
                    }}
                >  
                    <Link 
                        onClick={handleLogin}
                        sx={{
                            color: blue,
                            fontWeight: 500,
                            cursor: 'pointer',
                            textDecoration: 'none'   
                        }}
                    >
                        Login
                    </Link>
                </Typography>
                {isCustomer ? (
                    <Typography 
                        sx={{
                            ...textStyle,
                            margin: 'auto 0 auto auto'
                        }}
                    > 
                        <Link 
                            onClick={handleRegister}
                            sx={{
                                color: blue,
                                fontWeight: 500,
                                cursor: 'pointer',
                                textDecoration: 'none'
                            }}
                        >
                            Register
                        </Link>
                    </Typography>
                ) : <></>}
            </HMBox>
        </Grid>
    )
}

export default HMForgotPasswordForm
import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import HMText from '../common/tools/HMText'
import {
    fontSize,
} from '../common/tools/Styles'
import { 
    blue,
    white,
    lightBlack,
    middleOrange
} from '../../hooks/useColors'

function SidebarPOSItem(props) {
    const itemStyle = {
        width: '100%',
        padding: '15px',
        display: 'flex',
        cursor: 'pointer',
        margin: '5px auto',
        flexDirection: 'column',
        textTransform: 'capitalize',
        color: props.color || lightBlack,
        backgroundColor: props.bgColor || 'inherit',
        '&:hover': {
            opacity: 0.8,
            color: props.hoverColor || `${white} !important`,
            backgroundColor: props.hoverBgColor || `${blue} !important`
        }
    }

    return (
        <Button
            sx={itemStyle}
            onClick={props.handleClick}
        >
            {props.icon}
                <HMText 
                    margin='auto'
                    color='inherit'
                    fontWeight={500}
                    text={props.text}
                    fontSize={`${fontSize + 2}px`}
                />
            {props.showCounter && props.counter > 0 ? (
                <Grid 
                    sx={{ 
                        zIndex: 999,
                        height: '18px',
                        display: 'flex',
                        padding: '0 5px',
                        borderRadius: '50%',
                        width: 'max-content',
                        position: 'absolute',
                        margin: '0 0 50px 40px',
                        backgroundColor: middleOrange, 
                    }}
                >
                    <Typography 
                        variant='body2' 
                        component='span' 
                        sx={{
                            color: white,
                            margin: 'auto',
                            fontWeight: 400,
                            fontSize: `${fontSize}px`,
                        }}
                    >
                        {props.counter}
                    </Typography>
                </Grid>
            ) : <></>}
        </Button>
    )
}

export default SidebarPOSItem
import React from 'react'
import { useSelector } from 'react-redux'
import HMForgotPasswordForm from '../../components/common/layout/HMForgotPasswordForm'
import BeforeLogin from '../../components/common/layout/BeforeLogin'

function ForgotPassword() {
  const {  
    isLoading, 
  } = useSelector((state) => state.auth)

  return (
    <BeforeLogin
      isLoading={isLoading}
      title='Forgot Password'
    >
      <HMForgotPasswordForm />
    </BeforeLogin>
  )
}

export default ForgotPassword

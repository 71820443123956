import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import posService from './posService'

const initialState = {
    posSale: {},
    allPOSSales: [],
    posPayMethod: '',
    isPOSMessage: '',
    isPOSSale: false,
    storePOSSales: [],
    isPOSError: false,
    isPOSLoading: false,
    operatorPOSSales: [],
    areAllPOSSales: false,
    areStorePOSSales: false,
    isCreatedPOSSale: false,
    isDeletedPOSSale: false,
    isUpdpatedPOSSale: false,
    areOperatorPOSSales: false,
}

export const setPOSPayMethod = createAsyncThunk(
    'pos/posPayMethod',
    async (paymentMethod) => {
        return paymentMethod
    }
)

// A function that creates a POS sale 
export const createPOSSale = createAsyncThunk(
    'pos/createPOSSale',
    async (newPOSSale, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.createPOSSale(newPOSSale, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that gets all POS sales 
export const getPOSSales = createAsyncThunk(
    'pos/getPOSSales',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.getPOSSales(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that gets POS sales for a single store
export const getPOSSalesByStore = createAsyncThunk(
    'pos/getPOSSalesByStore',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.getPOSSalesByStore(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that gets POS sales for a certain operator
export const getPOSSalesByOperator = createAsyncThunk(
    'pos/getPOSSalesByOperator',
    async (paramsObj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.getPOSSalesByOperator(paramsObj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that gets a POS sale by ID 
export const getPOSSale = createAsyncThunk(
    'pos/getPOSSale',
    async (paramsObj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.getPOSSale(paramsObj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that updates a POS sale
export const updatePOSSale = createAsyncThunk(
    'pos/updatePOSSale',
    async (updatedPOSSale, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.updatePOSSale(updatedPOSSale, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

// A function that deletes a POS sale
export const deletePOSSale = createAsyncThunk(
    'pos/deletePOSSale',
    async (parmsObj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await posService.deletePOSSale(parmsObj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    } 
)

export const posSlice = createSlice({
    name: 'pos',
    initialState,
    reducers: {
        resetPOS: (state) => {
            state.posPayMethod = ''
            state.isPOSMessage = ''
            state.isPOSSale = false
            state.isPOSError = false
            state.isPOSLoading = false
            state.areAllPOSSales = false
            state.areStorePOSSales = false
            state.isCreatedPOSSale = false
            state.isDeletedPOSSale = false
            state.isUpdpatedPOSSale = false
            state.areOperatorPOSSales = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setPOSPayMethod.fulfilled, (state, action) => {
                state.posPayMethod = action.payload
            })

            .addCase(createPOSSale.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.isCreatedPOSSale = false
            })
            .addCase(createPOSSale.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.isCreatedPOSSale = true
                state.posSale = action.payload
            })
            .addCase(createPOSSale.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.isCreatedPOSSale = false
                state.isPOSMessage = action.payload
            })

            .addCase(getPOSSales.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.areAllPOSSales = false
            })
            .addCase(getPOSSales.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.areAllPOSSales = true
                state.allPOSSales = action.payload
            })
            .addCase(getPOSSales.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.areAllPOSSales = false
                state.isPOSMessage = action.payload
            })

            .addCase(getPOSSalesByStore.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.areStorePOSSales = false
            })
            .addCase(getPOSSalesByStore.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.areStorePOSSales = true
                state.storePOSSales = action.payload
            })
            .addCase(getPOSSalesByStore.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.areStorePOSSales = false
                state.isPOSMessage = action.payload
            })

            .addCase(getPOSSalesByOperator.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.areOperatorPOSSales = false
            })
            .addCase(getPOSSalesByOperator.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.areOperatorPOSSales = true
                state.operatorPOSSales = action.payload
            })
            .addCase(getPOSSalesByOperator.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.areOperatorPOSSales = false
                state.isPOSMessage = action.payload
            })

            .addCase(getPOSSale.pending, (state) => {
                state.isPOSSale = false
                state.isPOSError = false
                state.isPOSLoading = true
            })
            .addCase(getPOSSale.fulfilled, (state, action) => {
                state.isPOSSale = true
                state.isPOSError = false
                state.isPOSLoading = false
                state.posSale = action.payload
            })
            .addCase(getPOSSale.rejected, (state, action) => {
                state.isPOSSale = false
                state.isPOSError = true
                state.isPOSLoading = false
                state.isPOSMessage = action.payload
            })

            .addCase(updatePOSSale.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.isUpdpatedPOSSale = false
            })
            .addCase(updatePOSSale.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.isUpdpatedPOSSale = true
                state.posSale = action.payload
            })
            .addCase(updatePOSSale.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.isUpdpatedPOSSale = false
                state.isPOSMessage = action.payload
            })

            .addCase(deletePOSSale.pending, (state) => {
                state.isPOSError = false
                state.isPOSLoading = true
                state.isDeletedPOSSale = false
            })
            .addCase(deletePOSSale.fulfilled, (state, action) => {
                state.isPOSError = false
                state.isPOSLoading = false
                state.isDeletedPOSSale = true
                state.isPOSMessage = action.payload.message
            })
            .addCase(deletePOSSale.rejected, (state, action) => {
                state.isPOSError = true
                state.isPOSLoading = false
                state.isDeletedPOSSale = false
                state.isPOSMessage = action.payload
            })
    }
})

export const { resetPOS } = posSlice.actions
export default posSlice.reducer
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { 
  getMyStore,
} from '../../../features/stores/storeSlice'
import { 
    getStoreListing,
    resetStoreListing,
    updateStoreListing,
} from '../../../features/storeListings/storeListingSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import HMListingCreationFields from '../../../components/common/layout/HMListingCreationFields'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMButton from '../../../components/common/tools/HMButton'
import { 
  iconStyle,
  textStyle, 
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  white, 
  green,
  orange,
} from '../../../hooks/useColors'

function EditListing() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { storeId, listingId } = useParams()
    const { 
        user 
    } = useSelector((state) => state.auth)
    const { myStores } = useSelector((state) => state.store)
    const storeListingState = useSelector(state => state.storeListing)  
    const { 
      storeListing
    } = storeListingState

    const {
      windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const breadOptions = [
    { text: 'Products', link: `/store/${storeId}/listings` },
    { text: Object.keys(storeListing).length && `Edit - ${storeListing.product.name}`, link: null },
  ]

  const handleAdd = () => {
    navigate(`/store/${storeId}/listings/create`)
  }
  const handleView = () => {
    navigate(`/store/${storeId}/listings/${listingId}`)
  }

  const minExpirationDate = new Date()
  minExpirationDate.setDate(minExpirationDate.getDate() + 16)

  const defaultFormData = (listing) => {
    const checkData = listing && Object.keys(listing).length ? true : false
    const stockValues = []
    if (checkData) {
      for (let stock of listing.availableStock) {
        stockValues.push({
          quantity: stock.quantity,
          expiresOn: dayjs(stock.expiresOn)
        })
      }
    }
    return {
      price: checkData ? listing.price : '',
      product: checkData ? listing.product : '',
      alertLevel: checkData ? listing.alertLevel : '',
      onlinePrice: checkData ? listing.onlinePrice : '',
      soldOnline: checkData ? listing.soldOnline : false,
      availableStock: checkData ? stockValues : [{
        quantity: 1,
        expiresOn: dayjs(minExpirationDate),
      }],
    }
  }

  const [stockCount, setStockCount] = useState(0)
  const [wrongStock, setWrongStock] = useState(false)
  
  useEffect(() => {
    if (user) {
      dispatch(getStoreListing(listingId))
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }
    }
    // eslint-disable-next-line
  }, [myStores])

  useEffect(() => {
    const {
      isOne,
      isError,
      message,
      isUpdated
    } = storeListingState

    if (isError) {
      toast.error(message)
    } else if (isOne) {
      setFormData(defaultFormData(storeListingState.storeListing))
    } else if (isUpdated) {
      toast.success('Product is updated successfuly!')
    }

    if (isOne || isError || isUpdated) {
      dispatch(resetStoreListing())
    }
    // eslint-disable-next-line
  }, [storeListingState]) 

  const [formData, setFormData] = useState(defaultFormData(storeListing))
  const {
    price,
    product,
    alertLevel,
    soldOnline,
    onlinePrice,
    availableStock,
  } = formData

  useEffect(() => {
    let qty = 0
    availableStock.forEach(stock => {
      if (stock['quantity']) {
        qty += parseInt(stock['quantity'])
      }

      if (
        !stock || 
        !stock['quantity'] || 
        !stock['expiresOn'] || 
        isNaN(new Date(stock['expiresOn']).valueOf())
      ) {
        setWrongStock(true)
      } else {
        setWrongStock(false)
      }
    })
    setStockCount(qty)
  }, [availableStock])

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'soldOnline') {
      setFormData((prevState) => ({
          ...prevState,
          [name]: (value === 'true' || value === true) ? true : false,
      }))
    } else {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  const handleStocks = (index, e) => {
    const stockValues = [...availableStock]
    if (e['target']) {
      const value = e.target.value
      const name = e.target.name
      stockValues[index] = {
        ...stockValues[index],
        [name]: parseInt(value)
      }
    } else {
      stockValues[index] = {
        ...stockValues[index],
        expiresOn: e
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      availableStock: stockValues
    }))
  }

  const handleRemoveStock = (index) => {
    const stockValues = [...availableStock]
    if (availableStock.length > 1) {
      stockValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          availableStock: stockValues
      }))
    }
  }
  const handleAddStock = (index) => {
    const stockValues = [...availableStock]
    stockValues.splice(index + 1, 0, {
      quantity: 1,
      expiresOn: dayjs(minExpirationDate),
    })
    setFormData((prevState) => ({
        ...prevState,
        availableStock: stockValues
    }))
  }
  
  const disabled = (
    wrongStock === true ||
    price === '' ||
    (product === '' || (product['name'] && product['name'] === '')) 
  ) ? true : false

  const handleUpdate = (e) => {
    e.preventDefault()

    const updatedStoreLisiting = {
      soldOnline,
      store: storeId,
      _id: listingId,
      product: product._id,
      price: parseFloat(price),
      alertLevel: alertLevel && parseInt(alertLevel),
      onlinePrice: onlinePrice && parseFloat(onlinePrice),
      availableStock: availableStock.map(stock => ({
        ...stock,
        expiresOn: stock.expiresOn.$d
      })),
    }

    if (!updatedStoreLisiting.alertLevel) {
      delete updatedStoreLisiting['alertLevel']
    } 

    if (!updatedStoreLisiting.onlinePrice) {
      delete updatedStoreLisiting['onlinePrice']
    } 

    dispatch(updateStoreListing(updatedStoreLisiting))
  }

  return user ? (
    <Page>
      {storeListingState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(storeListing).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMButton 
                type='button'
                bgColor={orange}
                isResponsive={true}
                handleClick={handleView}
                icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>View</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add Listing</Typography>} 
              />
            </HMBox>
          </FullWidthContainer>
          <Section
            bgColor={white}
            padding={10}
          >
            <HMListingCreationFields 
              formData={formData}
              disabled={disabled}
              availableOptions={[]}
              stockCount={stockCount}
              handleChange={handleChange}
              handleSubmit={handleUpdate}
              handleAddStock={(index) => handleAddStock(index)}
              handleStocks={(index, e) => handleStocks(index, e)}
              handleRemoveStock={(index) => handleRemoveStock(index)}
            />
          </Section>
        </>
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default EditListing
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import orderService from './orderService'

// Get storeOrders from local storage
const storeOrders = JSON.parse(localStorage.getItem('storeOrders'))

// Get storeOrders from local storage
const todayStoreOrders = JSON.parse(localStorage.getItem('todayStoreOrders'))

const initialState = {
    order: {},
    isOrder: false,
    orders: [],
    areOrders: false,
    myOrder: {},
    isMyOrder: false,
    myOrders: [],
    areMyOrders: false,
    storeOrder: {},
    isStoreOrder: false,
    storeOrders: storeOrders || [],
    areStoreOrders: false,
    todayStoreOrders: todayStoreOrders || [],
    areTodayStoreOrders: false,
    isLoading: false,
    isOrderCreated: false,
    isOrderUpdated: false,
    isMyOrderUpdated: false,
    isStoreOrderUpdated: false,
    isOrderDeleted: false,
    isMyOrderDeleted: false,
    isStoreOrderDeleted: false,
    isError: false,
    message: ''
}

// Create order
export const createOrder = createAsyncThunk(
    'orders/create',
    async (newOrder, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.createOrder(newOrder, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get order
export const getOrder = createAsyncThunk(
    'orders/order',
    async (orderId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getOrder(orderId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my order
export const getMyOrder = createAsyncThunk(
    'orders/myOrder',
    async (orderId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getMyOrder(orderId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get store order
export const getStoreOrder = createAsyncThunk(
    'orders/storeOrder',
    async (obj, thunkAPI) => {
        const { storeId, orderId } = obj
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getStoreOrder(storeId, orderId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get orders
export const getOrders = createAsyncThunk(
    'orders/orders',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getOrders(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my orders
export const getMyOrders = createAsyncThunk(
    'orders/myOrders',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getMyOrders(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get store orders
export const getStoreOrders = createAsyncThunk(
    'orders/storeOrders',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getStoreOrders(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get store today store orders
export const getTodayStoreOrders = createAsyncThunk(
    'orders/todayStoreOrders',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.getStoreOrders(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update order
export const updateOrder = createAsyncThunk(
    'orders/updateOrder',
    async (updatedOrder, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.updateOrder(updatedOrder, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update my order
export const updateMyOrder = createAsyncThunk(
    'orders/updateMyOrder',
    async (updatedOrder, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.updateMyOrder(updatedOrder, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update store order
export const updateStoreOrder = createAsyncThunk(
    'orders/updateStoreOrder',
    async (obj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.updateStoreOrder(obj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete order
export const deleteOrder = createAsyncThunk(
    'orders/deleteOrder',
    async (orderId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.deleteOrder(orderId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// order my order
export const deleteMyOrder = createAsyncThunk(
    'orders/deleteMyOrder',
    async (orderId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await orderService.deleteMyOrder(orderId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrder: (state) => {
            state.message = ''
            state.isOrder = false
            state.isError = false
            state.areOrders = false
            state.isLoading = false
            state.isMyOrder = false
            state.areMyOrders = false
            state.isStoreOrder = false
            state.areStoreOrders = false
            state.isOrderCreated = false
            state.isOrderDeleted = false
            state.isOrderUpdated = false
            state.isMyOrderUpdated = false
            state.isMyOrderDeleted = false
            state.isStoreOrderUpdated = false
            state.isStoreOrderDeleted = false
            state.areTodayStoreOrders = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isOrderCreated = false
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isOrderCreated = true
                state.myOrder = action.payload
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isOrderCreated = false
                state.message = action.payload
            })
            
            .addCase(getOrder.pending, (state) => {
                state.isOrder = true
                state.isError = false
                state.isLoading = true
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.isOrder = true
                state.isError = false
                state.isLoading = false
                state.order = action.payload
            })
            .addCase(getOrder.rejected, (state, action) => {
                state.isError = true
                state.isOrder = false
                state.isLoading = false
                state.message = action.payload
            })
            
            .addCase(getMyOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isMyOrder = false
            })
            .addCase(getMyOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isMyOrder = true
                state.isLoading = false
                state.myOrder = action.payload
            })
            .addCase(getMyOrder.rejected, (state, action) => {
                state.isError = true
                state.isMyOrder = false
                state.isLoading = false
                state.message = action.payload
            })
            
            .addCase(getStoreOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isStoreOrder = false
            })
            .addCase(getStoreOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isStoreOrder = true
                state.storeOrder = action.payload
            })
            .addCase(getStoreOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isStoreOrder = false
                state.message = action.payload
            })
            
            .addCase(getOrders.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areOrders = false
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.isError = false
                state.areOrders = true
                state.isLoading = false
                state.orders = action.payload
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.isError = true
                state.areOrders = false
                state.isLoading = false
                state.message = action.payload
            })
            
            .addCase(getMyOrders.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areMyOrders = false
            })
            .addCase(getMyOrders.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areMyOrders = true
                state.myOrders = action.payload
            })
            .addCase(getMyOrders.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areMyOrders = false
                state.message = action.payload
            })
            
            .addCase(getStoreOrders.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areStoreOrders = false
            })
            .addCase(getStoreOrders.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areStoreOrders = true
                state.storeOrders = action.payload

                localStorage.setItem('storeOrders', JSON.stringify(action.payload))
            })
            .addCase(getStoreOrders.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areStoreOrders = false
                state.message = action.payload
            })
            
            .addCase(getTodayStoreOrders.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areTodayStoreOrders = false
            })
            .addCase(getTodayStoreOrders.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areTodayStoreOrders = true

                const tempStoreOrders = action.payload
                const tempTodayStoreOrders = []

                tempStoreOrders.forEach(order => {
                    const orderDetail = order.orderDetails[0]
                    const tempSale = {
                      _id: order._id,
                      date: order.createdAt,
                      status: orderDetail.status,
                      total: orderDetail.subtotal,
                      orderNumber: order.orderNumber,
                      quantity: orderDetail.quantity,
                      paymentMethod: order.paymentInfo.method,
                      paymentStatus: order.paymentInfo.paid ? 'Paid' : 'Unpaid',
                      client: order.user ? `${order.user.firstName} ${order.user.lastName}` : '',
                    }
            
                    const now_timestamp = Math.round(new Date().getTime() / 1000) 
                    const last_24_hours_timestamp = now_timestamp - (24 * 3600)
                    const db_timestamp = Math.round(new Date(order.createdAt).getTime() / 1000)
            
                    // if (db_timestamp > last_24_hours_timestamp) {
                    //     tempTodayStoreOrders.push(tempSale)
                    // }
                    tempTodayStoreOrders.push(tempSale)
                })

                state.todayStoreOrders = tempTodayStoreOrders

                localStorage.setItem('todayStoreOrders', JSON.stringify(tempTodayStoreOrders))
            })
            .addCase(getTodayStoreOrders.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areTodayStoreOrders = false
                state.message = action.payload
            })

            .addCase(updateOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isOrderUpdated = false
            })
            .addCase(updateOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isOrderUpdated = true
                state.order = action.payload
            })
            .addCase(updateOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isOrderUpdated = false
                state.message = action.payload
            })
            
            .addCase(updateMyOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isMyOrderUpdated = false
            })
            .addCase(updateMyOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isMyOrderUpdated = true
                state.myOrder = action.payload
            })
            .addCase(updateMyOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isMyOrderUpdated = false
                state.message = action.payload
            })
            
            .addCase(updateStoreOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isStoreOrderUpdated = false
            })
            .addCase(updateStoreOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isStoreOrderUpdated = true
                state.storeOrder = action.payload
            })
            .addCase(updateStoreOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isStoreOrderUpdated = false
            })
            
            .addCase(deleteOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isOrderDeleted = false
            })
            .addCase(deleteOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isOrderDeleted = true
                state.message = action.payload.message
            })
            .addCase(deleteOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isOrderDeleted = false
                state.message = action.payload
            })
            
            .addCase(deleteMyOrder.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isMyOrderDeleted = false
            })
            .addCase(deleteMyOrder.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isMyOrderDeleted = true
                state.message = action.payload.message
            })
            .addCase(deleteMyOrder.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isMyOrderDeleted = false
                state.message = action.payload
            })
            
    }
})

export const { resetOrder } = orderSlice.actions
export default orderSlice.reducer
import React from 'react'
import moment from 'moment'
import Avatar from '@mui/material/Avatar'
import CircleIcon from '@mui/icons-material/Circle'
import HMBox from '../common/layout/HMBox'
import HMButton from '../common/tools/HMButton'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'
import {
    blue,
    gray,
    white,
    darkBlue,
    lightBlue,
    lightBlack,
} from '../../hooks/useColors'
import {
    currencies
} from '../../hooks/helperFunctions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function RecentActivities(props) {

    const sortByDate = (array, dateProperty) => {
        return array.sort((a, b) => {
          const dateA = new Date(a[dateProperty])
          const dateB = new Date(b[dateProperty])
          return dateB - dateA
        })
    }
      
    const activities = props.map && props.activities.length ? (() => {
        const tempActivities = [ ...props.activities ]

        return sortByDate(tempActivities, props.map.date)
    })() : props.activities

    return (
        <HMBox
            display='flex'
            flexDirection='column'
            bgColor={props.bgColor}
            width={props.width || '100%'}
            margin={props.margin || 'auto'}
        >
            {activities.slice(0, props.limit || 5).map((activity, index) => (
                <HMBox
                    key={index}
                    padding='0'
                    width='100%'
                    display='flex'
                    margin='2.5px auto'
                >
                    <Avatar sx={{ backgroundColor: lightBlue }}>
                        <HMText
                            color={darkBlue}
                            fontWeight={500}
                            bgColor={lightBlue}
                            text={`${index + 1}`} 
                        />
                    </Avatar>
                    <HMBox
                        padding='0'
                        display='flex'
                        width='max-content'
                        flexDirection='column'
                        margin='auto auto auto 10px'
                    >
                        <HMBox
                            padding='0'
                            display='flex'
                            width='max-content'
                            margin='auto auto 0 0'
                        >
                            <HMText 
                                fontWeight={600}
                                color={lightBlack}
                                margin='auto 5px auto 0'
                                fontSize={`${fontSize + 1}px`}
                                text={activity.type.toLowerCase() === 'pos' ? 'In-store' : 'Online'}
                            />
                            <CircleIcon 
                                sx={{ 
                                    color: gray, 
                                    margin: 'auto 2.5px', 
                                    width: `${props.dotSize || 10}px`, 
                                }} 
                            />
                            <HMText 
                                fontWeight={600}
                                color={lightBlack}
                                margin='auto 0 auto 5px'
                                fontSize={`${fontSize + 1}px`}
                                text={
                                    props.map ? 
                                    (
                                        activity[props.map.paymentMethod].method ? 
                                        activity[props.map.paymentMethod].method : 'Cash'
                                    )   
                                    : activity.paymentMethod
                                }
                            />
                        </HMBox>
                        <HMText 
                            color={lightBlack}
                            margin='0 auto auto 0'
                            fontSize={`${fontSize - 1}px`}
                            text={moment(
                                props.map 
                                ? activity[props.map.date] 
                                : activity.date
                            ).utc().format('MMM DD, YYYY  h:mm:ss a')}
                        />
                    </HMBox>
                    <HMText 
                        color={darkBlue}
                        fontWeight={600}
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize + 2}px`}
                        text={`${currency} ${(
                            props.map ? 
                            activity[props.map.amount] 
                            : activity.amount ? activity.amount : 0
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    /> 
                </HMBox>
            ))}
            {props.activities.length > 5 ? (
                <HMButton 
                    color={blue}
                    margin='10px'
                    borderRadius={25}
                    padding='5px 10px'
                    text='View more...'
                    hoverColor={white}
                    hoverBgColor={blue}
                    bgColor={lightBlue}
                    handleClick={() => {}}
                    paddingActive='4.5px 9.5px'
                    fontSize={`${fontSize - 2}px`}
                />
            ) : <></>}
        </HMBox>
    )
}

export default RecentActivities
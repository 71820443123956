import React from 'react'
import Link from '@mui/material/Link'
import HMBox from '../../components/common/layout/HMBox'
import HMText from '../../components/common/tools/HMText'
import { 
    fontSize,
} from '../../components/common/tools/Styles'
import {
    red,
    blue,
    green,
    white,
    orange,
    lightRed,
    lightBlue,
    lightBlack,
} from '../../hooks/useColors'
import { 
  currencies
} from '../../hooks/helperFunctions'

function ProductCard(props) {

    const item = props.item
    const isSummary = props.isSummary 
    const isValidatedInsurance = props.isValidatedInsurance
    const price = isValidatedInsurance ? item.insurancePrice : item.price
    const currency = currencies.find(curr => curr.country === 'Rwanda').currency
    
    return (
        <HMBox
            width='100%'
            display='flex'
            margin='0 0 5px 0'
            className='fade-in'
            padding='7.5px 0 5px 2.5px'
            handleClick={props.handleCardClick}
            cursor={props.showStock && item.availableStock.stockCount === 0 ? 'no-drop' : 'pointer'}
            bgColor={props.showStock && item.availableStock.stockCount === 0 ? lightRed : lightBlue}
        >
            <Link
                onClick={props.navegateToProductPage}
                sx={{margin: 'auto 0', cursor: 'pointer'}}
            >
                <img 
                    alt={item.productName}
                    className='small-image'
                    src={item.productImage}
                />
            </Link>
            <HMBox
                height='100%'
                display='flex'
                margin='auto 0'
                padding='0 10px'
                flexDirection='column'
            >
                <HMText
                    fontWeight={500}
                    color={lightBlack}
                    margin='auto auto 0 0'
                    text={item.productName}
                    fontSize={`${fontSize - 1}px`}
                />
                <HMBox
                    padding='0'
                    display='flex'
                    margin='0 auto auto 0'
                >
                    <HMText 
                        margin='auto 0'
                        fontWeight={500}
                        fontSize={`${fontSize - 2}px`}
                        color={!props.showStock ? lightBlack : item.availableStock.stockCount > 0 ? green : red}
                        text={!props.showStock ? 'Quantity' : item.availableStock.stockCount > 0 ? 'in stock' : 'sold out'}
                    />  
                    <HMText 
                        color={lightBlack}
                        margin='auto auto auto 5px'
                        fontSize={`${fontSize - 2}px`}
                        text={`( ${props.showStock ? item.availableStock.stockCount : item.quantity} )`}
                    />
                    {((props.showStock || (isSummary && item.usedInsurance)) && item.needsPrescription) ? (
                        <HMText 
                            color={white}
                            bgColor={blue}
                            padding='0 5px'
                            margin='auto auto auto 5px'
                            fontSize={`${fontSize - 2}px`}
                            text={isSummary && item.usedInsurance ? 'Insurance Applied' : 'Requires prescription'} 
                        />
                    ) : <></>}
                </HMBox>
            </HMBox>
            <HMText
                color={orange}
                fontWeight={500}
                margin='auto 10px auto auto'
                fontSize={`${fontSize - 1}px`}
                text={`${currency} ${
                    props.showStock 
                        ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : (price * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }`}
            />
        </HMBox>
    )
}

export default ProductCard
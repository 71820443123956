import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import searchService from './searchService'

const initialState = {
    message: '',
    searchText: '',
    isError: false,
    foundStores: [],
    foundCategories: [],
    searchLoading: false,
    areFoundStores: false,
    foundProductsInStore: [],
    foundOverallProducts: [],
    areFoundCategories: false,
    areFoundProductsInStore: false,
    areFoundOverallProducts: false,
}

// Search for products overall  products
export const searchOverallProducts = createAsyncThunk(
    'search/overallProducts',
    async (obj, thunkAPI) => {
        try {
            return await searchService.searchOverallProducts(obj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Search for products  in store  
export const searchProductsInStore = createAsyncThunk(
    'search/foundProductsInStore',
    async (obj, thunkAPI) => {
        try {
            return await searchService.searchProductsInStore(obj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Search for stores
export const searchFoundStores = createAsyncThunk(
    'search/foundStores',
    async (obj, thunkAPI) => {
        try {
            return await searchService.searchFoundStores(obj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Search for categories
export const searchFoundCategories = createAsyncThunk(
    'search/foundCategories',
    async (obj, thunkAPI) => {
        try {
            return await searchService.searchFoundCategories(obj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// set searchText
export const setSearchText = createAsyncThunk(
    'search/searchText',
    async (text, thunkAPI) => {
        try {
            return text
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const searchSlice = createSlice({
    initialState,
    name: 'search',
    reducers: {
        resetSearch: (state) => {
            state.message = ''
            state.isError = false
            state.searchLoading = false
            state.areFoundStores = false
            state.areFoundCategories = false
            state.areFoundProductsInStore = false
            state.areFoundOverallProducts = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchOverallProducts.pending, (state) => {
                state.isError = false
                state.searchLoading = true
                state.areFoundOverallProducts = false
            })
            .addCase(searchOverallProducts.fulfilled, (state, action) => {
                state.isError = false
                state.searchLoading = false
                state.areFoundOverallProducts = true 
                state.foundOverallProducts = action.payload
            })
            .addCase(searchOverallProducts.rejected, (state, action) => {
                state.isError = true 
                state.searchLoading = false
                state.message = action.payload 
                state.areFoundOverallProducts = false 
            })
            
            .addCase(searchProductsInStore.pending, (state) => {
                state.isError = false
                state.searchLoading = true
                state.areFoundProductsInStore = false
            })
            .addCase(searchProductsInStore.fulfilled, (state, action) => {
                state.isError = false
                state.searchLoading = false
                state.areFoundProductsInStore = true 
                state.foundProductsInStore = action.payload
            })
            .addCase(searchProductsInStore.rejected, (state, action) => {
                state.isError = true 
                state.searchLoading = false
                state.message = action.payload 
                state.areFoundProductsInStore = false 
            })

            .addCase(searchFoundStores.pending, (state) => {
                state.isError = false
                state.searchLoading = true
                state.areFoundStores = false
            })
            .addCase(searchFoundStores.fulfilled, (state, action) => {
                state.isError = false
                state.searchLoading = false
                state.areFoundStores = true
                state.foundStores = action.payload
            })
            .addCase(searchFoundStores.rejected, (state, action) => {
                state.isError = true
                state.searchLoading = false
                state.areFoundStores = false 
                state.message = action.payload
            })

            .addCase(searchFoundCategories.pending, (state) => {
                state.isError = false
                state.searchLoading = true
                state.areFoundCategories = false
            })
            .addCase(searchFoundCategories.fulfilled, (state, action) => {
                state.isError = false
                state.searchLoading = false
                state.areFoundCategories = true 
                state.foundCategories = action.payload
            })
            .addCase(searchFoundCategories.rejected, (state, action) => {
                state.isError = true
                state.searchLoading = false
                state.message = action.payload
                state.areFoundCategories = false
            })

            .addCase(setSearchText.fulfilled, (state, action) => {
                state.searchText = action.payload 
            })
    }
})

export const { resetSearch } = searchSlice.actions
export default searchSlice.reducer
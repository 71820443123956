import React from 'react'
import Checkbox from '@mui/material/Checkbox'

function HMCheckbox(props) {
    const checboxStyle = {
        width: props.width,
        height: props.height,
        margin: props.margin,
    }
    return (
        <Checkbox 
            size='small'
            id={props.id}
            sx={checboxStyle}
            checked={props.checked}
            onChange={(e) => props.handleChange(e)}
        />
    )
}

export default HMCheckbox
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { getSuppliers, resetSupplier } from '../../features/suppliers/supplierSlice'
import { getProducts, resetProduct } from '../../features/products/productSlice'
import { getInsurers, resetInsurer } from '../../features/insurers/insurerSlice'
import { getStores, resetStore } from '../../features/stores/storeSlice'
import { getOrders, resetOrder } from '../../features/orders/orderSlice'
import { getUsers, resetUser } from '../../features/users/userSlice'
import { getPOSSales, resetPOS } from '../../features/pos/posSlice'
import HMMultipleRadialBar from './components/HMMultipleRadialBar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HMDetailCard from './components/HMDetailCard'
import HMCardRadial from './components/HMCardRadial'
import HMDonutChart from './components/HMDonutChart'
import HMLineChart from './components/HMLineChart'
import HMPieChart from './components/HMPieChart'
import HMBarChart from './components/HMBarChart'
import HMAvatar from './components/HMAvatar'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  blue, 
  white,
  darkBlue,
  lightBlue,
} from '../../hooks/useColors'
import { 
  currencies,
} from '../../hooks/helperFunctions'
import {
  fontSize,
  iconStyle,
  textStyle
} from '../../components/common/tools/Styles'

// Line Chart
const LINE_CHART_DATA= [34, 21, 40, 10, 28, 30, 80]

// Card Avatar Data
const avatarData = [
  {
    date: 25,
    day: 'Tue',
    backbadge: '#16D39A',
    colorbadge: '#16D39A',
    location: 'Kimironko', 
    backcolor: '#D6FFF3',
    event: 'Brand new HarakaMeds headquartes inauguration',
  },
  {
    date: 26,
    day: 'Wed',
    location:'Kimironko', 
    backcolor: '#FFEDF0',
    backbadge: '#FF7588',
    colorbadge: '#FF7588',
    event: 'New employees onboarding to different departments',
  }
]

//Multiple Radial Chart
const Mult_Radial_Data= [76, 67, 61]
const Mult_Radial_Color= ['#F78800', '#00AEEF', '#16D39A']
const Mult_Radial_label= ['MMI', 'RAMA', 'Mutuel de sante']

const BAR_CHART_DATA = [
  {
    name: 'Users',
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  },
  {
    name: 'Income',
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  },
  {
    name: 'Orders',
    data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  },
  {
    name: 'Products',
    data: [30, 36, 40, 30, 40, 58, 66, 53, 10],
  },
]

function AdminDashboard() {
  const dispatch = useDispatch() 
  const posState = useSelector((state) => state.pos)
  const { allPOSSales } = posState
  const userState = useSelector((state) => state.user)
  const { users } = userState
  const orderState = useSelector((state) => state.order)
  const { orders } = orderState
  const storeState = useSelector((state) => state.store)
  const { stores } = storeState
  const insurerState = useSelector((state) => state.insurer)
  const { insurers } = insurerState
  const productState = useSelector((state) => state.product)
  const { products } = productState
  const supplierState = useSelector((state) => state.supplier)
  const { suppliers } = supplierState

  const [currency, setCurrency] = useState('')
  const [allAdmins, setAllAdmins] = useState(0)
  const [customers, setCustomers] = useState(0)
  const [countUsers, setCountUsers] = useState(0)
  const [countStores, setCountStores] = useState(0)
  const [allManagers, setAllManagers] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [countOrders, setCountOrders] = useState(0)
  const [allOperators, setAllOperators] = useState(0)
  const [countInsures, setCountInsures] = useState(0)
  const [countPOSSales, setCountPOSSales] = useState(0)
  const [countProducts, setCountProducts] = useState(0)
  const [countSuppliers, setCountSuppliers] = useState(0)
  const [deliveryAgents, setDeliveryAgents] = useState(0)
  const [countOTCProducts, setCountOTCProducts] = useState(0)
  const [countHealthProducts, setCountHealthProducts] = useState(0)
  const [countPrescriptionProducts, setCountPrescriptionProducts] = useState(0)

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getStores())
    dispatch(getOrders())
    dispatch(getInsurers())
    dispatch(getProducts())
    dispatch(getSuppliers())
    setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
  }, [dispatch])

  useEffect(() => {
    if (orders.length) {
      let tempTotalIncome = 0

      orders.forEach((order) => {
        tempTotalIncome += order.orderTotal
      })

      setTotalIncome(tempTotalIncome)
    }
  }, [orders])

  useEffect(() => {
    if (products.length) {
      let tempCountOTCProducts = 0
      let tempCountHealthProducts = 0
      let tempCountPrescriptionProducts = 0

      products.forEach((product) => {
        if (product.category.id.parent.name.toLowerCase().includes('otc')) {
          tempCountOTCProducts ++
        } else if (product.category.id.parent.name.toLowerCase().includes('health')) {
          tempCountHealthProducts ++
        } else if (product.category.id.parent.name.toLowerCase().includes('prescription')) {
          tempCountPrescriptionProducts ++
        }
      })

      setCountOTCProducts(tempCountOTCProducts)
      setCountHealthProducts(tempCountHealthProducts)
      setCountPrescriptionProducts(tempCountPrescriptionProducts)
    }
  }, [products])

  useEffect(() => {
    if (users.length) {
      let tempCustomers = 0
      let tempAllAdmins = 0
      let tempAllManagers = 0
      let tempAllOperators = 0 
      let tempDeliveryAgents = 0

      users.forEach(user => {
        const accessLevel = user.accessLevel
        if (
          accessLevel === 0 || 
          accessLevel === 1 || 
          accessLevel === 20 || 
          accessLevel === 40 || 
          accessLevel === 50
        ) {
          tempAllAdmins++
        } else if (
          accessLevel === 5 || 
          accessLevel === 30 ||
          accessLevel === 45 || 
          accessLevel === 55  
        ) {
          tempAllOperators++
        } else if (accessLevel === 41 || accessLevel === 51) {
          tempAllManagers++
        } else if (accessLevel === 60) {
          tempDeliveryAgents++
        } else if (accessLevel === 100) {
          tempCustomers++
        } 
      })
      setCustomers(tempCustomers)
      setAllAdmins(tempAllAdmins)
      setAllManagers(tempAllManagers)
      setAllOperators(tempAllOperators)
      setDeliveryAgents(tempDeliveryAgents)
    }
  }, [users])

  useEffect(() => {
    if (posState.areAllPOSSales) {
      dispatch(resetPOS())
      setCountPOSSales(posState.allPOSSales.length)
    } else if (posState.isError) {
      dispatch(resetPOS())
    } 

    if (userState.isSuccess) {
      dispatch(resetUser())
      setCountUsers(userState.users.length)
    } else if (userState.isError) {
      dispatch(resetUser())
    }

    if (orderState.areOrders) {
      dispatch(resetOrder())
      setCountOrders(orderState.orders.length)
    } else if (orderState.isError) {
      dispatch(resetOrder())
    }

    if (storeState.isSuccess) {
      dispatch(resetStore())
      setCountStores(storeState.stores.length)
    } else if (storeState.isError) {
      dispatch(resetStore())
    }

    if (insurerState.isSuccess) {
      dispatch(resetInsurer())
      setCountInsures(insurerState.insurers.length)
    } else if (insurerState.isError) {
      dispatch(resetInsurer())
    }

    if (productState.isSuccess) {
      dispatch(resetProduct())
      setCountProducts(productState.products.length)
    } else if (productState.isError) {
      dispatch(resetProduct())
    }

    if (supplierState.isSuccess) {
      dispatch(resetSupplier())
      setCountSuppliers(supplierState.supplier.length)
    } else if (supplierState.isError) {
      dispatch(resetSupplier())
    }
  }, [
    dispatch,
    posState,
    userState,
    orderState,
    storeState,
    insurerState,
    productState,
    supplierState,
  ])

  // Donut Chart
  const DONUT_CHART_COLORS = ['#2F385480', '#00AEEF','#F78800','#16D39A']
  const DONUT_CHART_DATA = [allAdmins, customers, allOperators, allManagers]
  const DONUT_CHART_LABELS = ['Admins', 'Customer','Operators','Store & Supplier staffs']

  // Pie Chart
  const PIE_CHART_COLORS = ['#FFA500', '#00AEEF', '#16D39A']
  const PIE_CHART_LABELS = ['OTC', 'Health & Wellbeing', 'Prescription medication']
  const PIE_CHART_DATA = [countOTCProducts, countHealthProducts, countPrescriptionProducts]

  // Detail card dta
  const Detail_data = [
    {title: 'Insures', value:countInsures, color:'#FFA500'},
    {title: 'Suppliers', value:countSuppliers, color:'#16D39A'},
    {title: 'Stores', value:countStores, color:'rgba(47, 56, 84, 0.85)'}
  ]

  // Radial bar income
  const fill_Income = '#16D39A'
  const Income_Radia_Bar_Data = [70]
  const Income_Title = 'Total Income'
  const Income_Radial_Bar_Color = '#D6FFF3'
  const Income_Total = `${currency} ${totalIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

  // Order Radial Bar

  const fill_Order = '#2F3854'
  const Order_Radia_Bar_Data = [70]
  const Order_Title = 'Total Orders'
  const Order_Radial_Bar_Color = '#D9D9D999'
  const Order_Total = countOrders.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Product Radial Bar
  const fill_Product = '#F78800'
  const Product_Radia_Bar_Data = [10]
  const Product_Title = 'Total Products'
  const Product_Radial_Bar_Color = '#FFF3E4'
  const Product_Total = countProducts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // User Radial Bar
  const fill_User = '#00AEEF'
  const User_Title = 'Total Users'
  const User_Radia_Bar_Data = [20]
  const User_Radial_Bar_Color = '#EFFBFF'
  const User_Total = countUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Page>
      <Section
        padding={5}
        bgColor={white}
        height='fit-content'
      >
        <FullWidthContainer>
          <Title 
            margin='10px'
            title='Overview' 
            color= 'rgba(47, 56, 84, 0.7)'
            fontSize={`${fontSize + 4}px`}
          />
          <HMBox 
            float='right'
            padding='0px'
            display='flex'
            bgColor={lightBlue}
          >
            <HMButton 
              type='button'
              bgColor={white}
              isResponsive={true}
              text={<Typography color={darkBlue} >Weekly</Typography>} 
            />
            <HMButton 
              type='button'
              bgColor={blue}
              isResponsive={true}
              text={<Typography color='white'>Monthly</Typography>} 
            />
            <HMButton 
              type='button'
              bgColor={white}
              isResponsive={true}
              text={<Typography color={darkBlue}>Yearly</Typography>} 
            />
          </HMBox>
        </FullWidthContainer>
        
        <Box width='100%' height='auto' margin='auto'>
          <Grid container spacing={2} margin='auto'>
            <Grid item xs={5.5} md={2.9}>
              <HMCardRadial title={Income_Title} value={Income_Total} data={Income_Radia_Bar_Data} backColor={Income_Radial_Bar_Color} fillColor={fill_Income} />
            </Grid>
            <Grid item xs={5.5} md={2.9}>           
              <HMCardRadial title={Order_Title} value={Order_Total} data={Order_Radia_Bar_Data} backColor={Order_Radial_Bar_Color} fillColor={fill_Order} />
            </Grid>
            <Grid item xs={5.5} md={2.9}>
              <HMCardRadial title={Product_Title} value={Product_Total} data={Product_Radia_Bar_Data} backColor={Product_Radial_Bar_Color} fillColor={fill_Product} />
            </Grid>
            <Grid item xs={5.5} md={2.9}>
              <HMCardRadial title={User_Title} value={User_Total} data={User_Radia_Bar_Data} backColor={User_Radial_Bar_Color} fillColor={fill_User} />
            </Grid>
          </Grid>
        </Box>
      </Section>

      {/* first section end */}


      {/* Second section start */}
      <Box width='100%'>
        <Grid container spacing={2}>

          {/*  Total Visits */}

          <Grid item xs={12} md={8}>
            <Section
              padding={5}
              height='95%'
              bgColor={white}
            >
              <FullWidthContainer>
                <Title 
                  margin='10px'
                  title='Total Visits' 
                  color= 'rgba(47, 56, 84, 0.7)'
                  fontSize={`${fontSize + 4}px`}
                />
                <HMBox 
                  float='right'
                  padding='0px'
                  display='flex'
                  bgColor={lightBlue}
                >
                  <HMButton 
                    type='button'
                    bgColor={white}
                    isResponsive={true}
                    text={<Typography color={darkBlue}>This Week</Typography>} 
                  />
                  <HMButton 
                    type='button'
                    bgColor={blue}
                    isResponsive={true}
                    text={<Typography color='white'>This Month</Typography>} 
                  />
                  <HMButton 
                    type='button'
                    bgColor={white}
                    isResponsive={true}
                    text={<Typography color={darkBlue}>This Year</Typography>} 
                  />
                </HMBox>
              </FullWidthContainer>
              <Box sx={{ width: '100%', height: '80%', mt: 8 }}>
                <HMLineChart data={LINE_CHART_DATA}  />
              </Box>
            </Section>
          </Grid>

          {/* User Distribution */}

          <Grid item xs={12} md={4}>
            <Box 
              display='flex'
              flexDirection='column'
            >
              <Section
                padding={1}
                bgColor={white}
                marginBottom={1}
              >
                <HMDonutChart title='Users distribution' data={DONUT_CHART_DATA} labels={DONUT_CHART_LABELS} />
                <Box 
                  display='flex'
                  justifyContent='space-between'
                >
                  <Box></Box>
                  <HMButton 
                    type='button'
                    bgColor={blue}
                    sx={{	ml:'auto', marginBottom:'30px'}}
                    margin='6px 10px'
                    padding='4px 20px'
                    isResponsive={true}
                    text={<Typography color='white'>View Analytics</Typography>} 
                  />
                </Box>
              </Section>
              {/* Upcomming Events Section */}
              <Section 
                padding={5}
                bgColor={white}
              >
                <Box>
                  <Title 
                    float='initial'
                    title='Upcomming Events' 
                    margin='10px'
                    textAlign='start'
                    fontSize={`${fontSize + 4}px`}
                    color= 'rgba(47, 56, 84, 0.7)'
                  />
                </Box>
                {
                  avatarData.map((item, index) => {
                    return (
                      <HMAvatar 
                        key={index}
                        day={item.day} 
                        date={item.date} 
                        event={item.event} 
                        location={item.location} 
                        backBadge={item.backbadge} 
                        colorBadge={item.backbadge} 
                        backAvatar={item.backcolor} 
                      />
                    )
                  })
                }
                <Box 
                  display='flex'
                  justifyContent='space-between'
                >
                  <Box></Box>
                  <Button>
                  <Box display='flex' gap={2}>
                    <HMText
                        text='7 more events'
                        color='rgba(47, 56, 84, 0.5)'
                        fontSize={`${fontSize - 1}px`}
                    />  
                    <ArrowForwardIcon sx={{...iconStyle, color: blue}} />
                  </Box>
                  </Button>
                </Box>
                <HMButton 
                  type='button'
                  bgColor={blue}
                  margin='10px 10px'
                  padding='4px 20px'
                  isResponsive={true}
                  icon={<AddIcon sx={iconStyle} />} 
                  text={<Typography sx={textStyle}>New Events</Typography>} 
                />
              </Section>
            </Box>
          </Grid>
        </Grid>
      </Box>  
      {/* end of second section */}

      {/* start of third section */}
        
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5.25}>
            <Section
              bgColor={white}
              padding={5}
            >
              <HMMultipleRadialBar title='Insurance activity' MultColor={Mult_Radial_Color} MultLabel={Mult_Radial_label} data={Mult_Radial_Data} />
              <Box display='flex'
                justifyContent='space-between'>
                <Box></Box>
                <HMButton 
                  type='button'
                  bgColor={blue}
                  sx={{	ml:'auto'}}
                  isResponsive={true}
                  margin='8px 10px'
                  padding='4px 20px'
                  text={<Typography color='white'>View Analytics</Typography>} 
                />
                </Box>
            </Section>
          </Grid>
          <Grid item xs={12} md={5.25}>
            <Section
              padding={1}
              bgColor={white}
            >
              <HMPieChart 
                title='Products categories'
                labels = {PIE_CHART_LABELS}
                data={PIE_CHART_DATA}
              />  
              <Box 
                display='flex'
                justifyContent='space-between'
              >
                <Box></Box>
                <HMButton 
                  type='button'
                  bgColor={blue}
                  sx={{	ml:'auto'}}
                  margin='10px 10px'
                  padding='4px 20px'
                  isResponsive={true}
                  text={<Typography color='white'>View Analytics</Typography>} 
                />
              </Box>
            </Section>  
          </Grid>
          <Grid item xs={8} md={1.5}>
            <Box display='flex'
              flexDirection='column'
            >
              {
                Detail_data.map((detail, index) => {
                  return (
                    <HMDetailCard 
                      key={index}
                      title={detail.title} 
                      value={detail.value} 
                      detailColor={detail.color} 
                    /> 
                  )
                })
              }
            </Box> 
          </Grid>
        </Grid>
      </Section>

      {/* !!!!!!!!!!!!!!!!!!!!!!!users bar chart!!!!!!!!!!!!!!!!!!!! */}

      <Section
        padding={5}
        bgColor={white}
        height='fit-content'
      >
        <FullWidthContainer>
          <Title 
            margin='10px'
            title='General insights' 
            fontSize={`${fontSize + 4}px`}
            color= 'rgba(47, 56, 84, 0.7)'
          />
          <HMBox 
            float='right'
            padding='0px'
            display='flex'
            bgColor={lightBlue}
          >
            <HMButton 
              type='button'
              bgColor={white}
              isResponsive={true}
              text={<Typography color={darkBlue}>Weekly</Typography>} 
            />
            <HMButton 
              type='button'
              bgColor={blue}
              isResponsive={true}
              text={<Typography color='white'>Monthly</Typography>} 
            />
            <HMButton 
              type='button'
              bgColor={white}
              isResponsive={true}
              text={<Typography color={darkBlue}>Yearly</Typography>} 
            />
          </HMBox>
        </FullWidthContainer>
        {/* !!!!!!!!!!!!!!!!!!main-line Graph start here ---->>>>>> !!!!!!!!!!!!!!!!! */}

        <Box sx={{ width: '100%', height: '60vh', mt: 8 }}>
          <HMBarChart data={BAR_CHART_DATA}/>
        </Box>
      </Section> 
    </Page>
  )
}

export default AdminDashboard
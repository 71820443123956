import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RemoveIcon from '@mui/icons-material/Remove'
import { 
  getMySupplier,
} from '../../../features/suppliers/supplierSlice'
import { 
  getProducts,
  resetProduct, 
} from '../../../features/products/productSlice'
import { 
  resetSupplierListing,
  createSupplierListing,
} from '../../../features/supplierListings/supplierListingSlice'
import { 
  getSupplierWithListings,
  resetSuppliersWithListings, 
} from '../../../features/suppliersWithListings/suppliersWithListingsSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMDatePicker from '../../../components/common/tools/HMDatePicker'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMButton from '../../../components/common/tools/HMButton'
import HMText from '../../../components/common/tools/HMText'
import { 
  textStyle, 
  addIconStyle,
  renoveIconStyle,
} from '../../../components/common/tools/Styles'
import { removeProductsThatAlreadyHaveSupplierListings } from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  white,
  green,
  lightBlue,
  lightOrange,
} from '../../../hooks/useColors'

function CreateListing() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { supplierId } = useParams()

    const { 
      isLoading,
      supplierWithListings,
      isSupplierWithListings,
    } = useSelector(state => state.suppliersWithListings)
    
    const { user } = useSelector((state) => state.auth)

    const supplierState = useSelector((state) => state.supplier)
    const { mySupplier, mySuppliers } = supplierState

    const productState = useSelector(state => state.product)
    const { products } = productState

    const supplierListingState = useSelector(state => state.supplierListing)  

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    const breadOptions = [
      { text: 'Products', link: `/supplier/${supplierId}/listings` },
      { text: 'Add a product', link: null }
    ]

    const minExpirationDate = new Date()
    minExpirationDate.setDate(minExpirationDate.getDate() + 16)

    const [stockCount, setStockCount] = useState(0)
    const [wrongStock, setWrongStock] = useState(false)
    const [areOptionsSet, setAreOptionsSet] = useState(false)
    const [availableOptions, setAvailableOptions] = useState([])
    const [formData, setFormData] = useState({
      price: '',
      product: '',
      alertLevel: '',
      onlinePrice: '',
      soldOnline: true,
      availableStock: [{
        quantity: 1,
        itemsPerBox: 12,
        expiresOn: dayjs(minExpirationDate),
      }],
    })
    const {
      price,
      product,
      alertLevel,
      soldOnline,
      onlinePrice,
      availableStock,
    } = formData
  
    useEffect(() => {
      if (user) {
        dispatch(getProducts())
        dispatch(getSupplierWithListings(supplierId))
        if (mySuppliers.length) {
          dispatch(getMySupplier(supplierId))
        }
      }
    }, [dispatch, user, supplierId, mySuppliers])
  
    useEffect(() => {
      if (supplierListingState.isError) {
        dispatch(resetSupplierListing())
        toast.error(supplierListingState.message)
      } else if (supplierListingState.isCreated) {
        dispatch(resetSupplierListing())
        toast.success('Product created successfully!')
        navigate(`/supplier/${supplierId}/listings/${supplierListingState.supplierListing._id}`)
      }

      let qty = 0
      availableStock.forEach(stock => {
        if (stock['quantity']) {
          qty += parseInt(stock['quantity'])
        }

        if (
          !stock || 
          !stock['quantity'] || 
          !stock['itemsPerBox'] ||
          !stock['expiresOn'] || 
          isNaN(new Date(stock['expiresOn']).valueOf())
        ) {
          setWrongStock(true)
        } else {
          setWrongStock(false)
        }
      })
      setStockCount(qty)
      
      if (productState.isSuccess) {
        dispatch(resetProduct())
      }
    }, [dispatch, navigate, supplierListingState, productState, availableStock, supplierId])

    useEffect(() => {
      if (isSupplierWithListings && products.length && Object.keys(mySupplier).length) {
        dispatch(resetSuppliersWithListings())
        const {
          options,
          doneSetting,
        } = removeProductsThatAlreadyHaveSupplierListings(mySupplier, products, supplierWithListings)
        setAvailableOptions(options)
        setAreOptionsSet(doneSetting)
      }
    }, [dispatch, products, mySupplier, supplierWithListings, isSupplierWithListings])

    const handleChange = (e) => {
      const value = e.target.value
      const name = e.target.name
  
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
    const handleProductChange = (val) => {
      setFormData((prevState) => ({
        ...prevState,
        product: val ? val : '',
      }))
    }
  
    const handleStocks = (index, e) => {
      const stockValues = [...availableStock]
      if (e['target']) {
        const name = e.target.name
        const value = e.target.value
        stockValues[index] = {
          ...stockValues[index],
          [name]: parseInt(value)
        }
      } else {
        stockValues[index] = {
          ...stockValues[index],
          expiresOn: e
        }
      }
  
      setFormData((prevState) => ({
        ...prevState,
        availableStock: stockValues
      }))
    }
  
    const handleRemoveStock = (index) => {
      const stockValues = [...availableStock]
      if (availableStock.length > 1) {
        stockValues.splice(index, 1)
        setFormData((prevState) => ({
            ...prevState,
            availableStock: stockValues
        }))
      }
    }
    const handleAddStock = (index) => {
      const stockValues = [...availableStock]
      stockValues.splice(index + 1, 0, {
        quantity: 1,
        itemsPerBox: 12,
        expiresOn: dayjs(minExpirationDate),
      })
      setFormData((prevState) => ({
          ...prevState,
          availableStock: stockValues
      }))
    }
    
    const disabled = (
      wrongStock === true ||
      price === '' ||
      (product === '' || (product['name'] && product['name'] === '')) 
    ) ? true : false
  
    const handleCreate = (e) => {
      e.preventDefault()
  
      const newSupplierLisiting = {
        supplier: supplierId,
        product: product._id,
        price: parseFloat(price),
        alertLevel: alertLevel && parseInt(alertLevel),
        onlinePrice: onlinePrice && parseFloat(onlinePrice),
        soldOnline: soldOnline === 'true' || soldOnline === true ? true : false,
        availableStock: availableStock.map(stock => ({
          ...stock,
          expiresOn: stock.expiresOn.$d
        })),
      }
  
      if (!newSupplierLisiting.alertLevel) {
        delete newSupplierLisiting['alertLevel']
      } 
  
      if (!newSupplierLisiting.onlinePrice) {
        delete newSupplierLisiting['onlinePrice']
      } 
  
      dispatch(createSupplierListing(newSupplierLisiting))
    }
  
    const sellOnline = {
      question: 'Sell online?',
      choices: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]
    }
  
    return user ? (
      <Page>
        {(supplierState.isLoading || supplierListingState.isLoading || isLoading) ? (
            <HMSpiner 
                size={60}
                zIndex={999}
                width='100%'
                height='80vh'
                margin='auto'
                position='absolute'
                bgColor='transparent'
            />
        ) : <></>}
        <FullWidthContainer>
          <HMBreadcrumbs 
            options={breadOptions}
          />
        </FullWidthContainer>
        <Section 
          padding={10} 
          bgColor={white}
        >
          <Grid 
            component='form'
            container spacing={2} 
            onSubmit={handleCreate}
          >
            <Grid item xs={12} sm={12} className='HM-dropdown listing'>
            {!areOptionsSet ? (
              <HMText 
                margin='10px 0'
                text='Loading...'
              />
            ) : (
              <DropdownFilter 
                  type='text'
                  field='name'
                  value={product}
                  required={true}
                  label='Product name'
                  prompt='Select product'
                  options={availableOptions}
                  onChange={handleProductChange}
                />
            )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{display : 'flex'}}>
              <HMRadioButton 
                name='soldOnline'
                padding='2px 5px'
                value={soldOnline}
                flexDirection='row'
                onChange={handleChange}
                label={sellOnline.question}
                options={sellOnline.choices}
                labelId='tax-applicable-radio-group'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HMTextField 
                width='100%'
                name='price'
                type='number'
                value={price}
                required={true}
                label='Listing price'
                onChange={handleChange}
                placeholder='Enter listing price'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HMTextField 
                width='100%'
                type='number'
                name='onlinePrice'
                value={onlinePrice}
                onChange={handleChange}
                label='Online listing price'
                placeholder='Enter online listing price'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HMTextField 
                width='100%'
                type='number'
                name='alertLevel'
                value={alertLevel}
                onChange={handleChange}
                label='Stock alert level'
                placeholder='Enter stock alert level'
              />
            </Grid>
            {availableStock.map((stock, index) => (
              <Grid key={index} item xs={12} sm={12} md={6}>
                <HMBox 
                  padding='0' 
                  width='100%' 
                  display='flex' 
                  position='relative'
                  margin={!isTabletScreen && '10px auto'}
                >
                  {(stockCount > 0 && index === 0) ? (
                    <HMBox
                      left='45%'
                      zIndex={999}
                      position='absolute'
                      bgColor={lightOrange}
                      marginTop={isTabletScreen ? '-22px' : '-14px'}
                    >
                      <HMText 
                        margin='auto'
                        fontWeight={500}
                        text={stockCount}
                      />
                    </HMBox>
                  ) : <></>}
                  <HMBox
                    width='100%'
                    margin='auto'
                    display='flex'
                    className='fade-in'
                    bgColor={lightBlue}
                    padding='0 10px 5px 10px'
                    flexDirection={isTabletScreen ? 'column' : 'row'}
                  >
                    <HMTextField
                      type='number'
                      name='quantity'
                      required={true}
                      bgColor={white}
                      label='Number of boxes'
                      placeholder='Enter number of boxes'
                      width={isTabletScreen ? '100%' : '50%'}
                      onChange={(e) => handleStocks(index, e)}
                      value={stock.quantity ? stock.quantity : ''}
                    /> 
                    <HMTextField
                      type='number'
                      required={true}
                      bgColor={white}
                      name='itemsPerBox'
                      label='Items per box'
                      width={isTabletScreen ? '100%' : '50%'}
                      onChange={(e) => handleStocks(index, e)}
                      placeholder='Enter number of items per box'
                      value={stock.itemsPerBox ? stock.itemsPerBox : ''}
                      margin={isTabletScreen ? '5px 0' : 'auto auto auto 5px'}
                    /> 
                    <HMBox
                      width='100%'
                      height='100%'
                      margin='auto'
                      display='flex'
                      className='stock-date'
                    >
                      <HMText 
                        minWidth='70px'
                        width='max-content'
                        text='Epiration date'
                        margin={isTabletScreen ? 'auto 0' : 'auto 5px auto auto'}
                      />
                      <HMDatePicker
                        margin='auto 0 auto auto'
                        minDate={dayjs(minExpirationDate)}
                        onChange={(e) => handleStocks(index, e)}
                        value={stock.expiresOn ? dayjs(stock.expiresOn) : dayjs(minExpirationDate)}
                      />
                    </HMBox>
                  </HMBox>
                  {availableStock.length > 1 && (
                    <IconButton 
                      onClick={() => handleRemoveStock(index)}
                      sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                    >
                      <RemoveIcon sx={renoveIconStyle} />
                    </IconButton>
                  )}
                  {availableStock.length < 10 && (
                    <IconButton   
                      onClick={() => handleAddStock(index)} 
                      sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                    >
                      <AddIcon sx={addIconStyle} />
                    </IconButton>
                  )}  
                </HMBox>
              </Grid>
            ))}
            <Grid item xs={12} sm={12}>
              <HMButton 
                width='100%'
                type='submit'
                float='right'
                padding='6px'
                bgColor={green}
                disabled={disabled}
                margin='20px 0 5px 0'
                paddingActive='5.5px'
                text={<Typography sx={textStyle}>Add Listing</Typography>}
              />
            </Grid> 
          </Grid>
        </Section>
      </Page>
    ) : (
      <Section 
        padding={10} 
        bgColor={white}
      >
        <HMLoginFirst 
          message='You need to login first before accessing supplier content'
        />
      </Section>
    )
}

export default CreateListing

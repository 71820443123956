import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import productService from './productService'

const initialState = {
    message: '',
    product: {},
    products: [],
    isOne: false,
    isError: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    areInserted: false,
    activeProducts: [],
    areActiveProducts: false,
}

// Create a new product
export const createProduct = createAsyncThunk(
    'products/create',
    async (newProduct, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.createProduct(newProduct, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Insert new products
export const insertProducts = createAsyncThunk(
    'products/insert',
    async (newProducts, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.insertProducts(newProducts, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update a product
export const updateProduct = createAsyncThunk(
    'products/update',
    async (product, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.updateProduct(product, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele a product
export const deleteProduct = createAsyncThunk(
    'products/delete',
    async (productID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.deleteProduct(productID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a product
export const getProduct = createAsyncThunk(
    'products/one',
    async (productID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.getProduct(productID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get products
export const getProducts = createAsyncThunk(
    'products/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.getProducts(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get server all active products
export const getActiveProducts = createAsyncThunk(
    'products/activeProducts',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await productService.getActiveProducts(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProduct: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.areInserted = false
            state.areActiveProducts = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createProduct.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.product = action.payload
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(insertProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areInserted = false
            })
            .addCase(insertProducts.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areInserted = true
                state.products = action.payload
            })
            .addCase(insertProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areInserted = false
                state.message = action.payload
            })
            
            .addCase(updateProduct.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.product = action.payload
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(deleteProduct.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getProduct.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.isOne = true
                state.isLoading = false
                state.product = action.payload
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getProducts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.products = action.payload
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(getActiveProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areActiveProducts = false
            })
            .addCase(getActiveProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.areActiveProducts = true
                state.activeProducts = action.payload
            })
            .addCase(getActiveProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areActiveProducts = false
                state.message = action.payload
            })
    }
})

export const { resetProduct } = productSlice.actions
export default productSlice.reducer
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
    getInsurers, 
    resetInsurer, 
    deleteInsurer, 
} from '../../../features/insurers/insurerSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
    iconStyle,
    textStyle,
    deleteTextStyle,
    deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useColors from '../../../hooks/useColors'

const { 
    red,
    gray,
    blue, 
    green,
    lightBlue,
    lightBlack,
} = useColors

function Insurers() {
    const dispatch = useDispatch() 
    const navigate = useNavigate()
    const {
        isError,
        message,
        insurers,
        isDeleted,
        isLoading,
        isSuccess,
    } = useSelector(state => state.insurer)

    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const [isFinal, setIsFinal] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [currentInsurer, setCurrentInsurer] = useState({})
    const [selectedInsurers, setSelectedInsurers] = useState([])

    useEffect(() => {
        if (isError || isDeleted || isSuccess) {
            dispatch(resetInsurer())
        }

        if (isSuccess) {
            setIsFinal(true)
        } else if (!isLoading && !isError) {
            setIsFinal(true)
        }
        
        if (isError) {
            toast.error(message)
            setIsDeleting(false)
        } else if (isDeleted) {
            setIsDeleting(false)
            toast.success(message)
            dispatch(getInsurers())
        }
    }, [dispatch, isLoading, isSuccess, isError, message, isDeleted])

    useEffect(() => {
        dispatch(getInsurers())
    }, [dispatch])

    const fields = []
    const columns = [
        { field: 'name', headerName: 'Name', sortable: true},
        { field: 'type', headerName: 'Type', sortable: true},
        { field: 'origin', headerName: 'Origin', sortable: true},
    ]

    columns.forEach((column) => {
        fields.push(column.field)
    })
    
    const getCurentInsurer = (id) => {
        return insurers.filter(insurer => insurer._id === id)[0]
    }
    const handleCreate = () => {
        navigate('/admin/insurers/create')
    }
    const handleEdit = (id) => {
        navigate(`/admin/insurers/edit/${id}`)
    }
    const handleDeleteModal = (id) => {
        setCurrentInsurer(getCurentInsurer(id))

        setOpen(true)
        setIsDelete(true)
        setTitle('Delete Insurer')
    }
    const handleBulkDeleteModal = (data) => {
        setSelectedInsurers(data)
        setOpen(true)
        setIsDelete(false)
        setTitle('Delete Insurers')
    }
    const handleDelete = () => {
        setIsDeleting(true)
        dispatch(deleteInsurer(currentInsurer._id))
        handleClose()
    }
    const handleBulkDelete = () => {
        // TODO
        handleClose()
    }
    const handleClose = () => {
        setTitle('')
        setOpen(false)
        setIsDelete(false)
        setCurrentInsurer({})
    }
    
    return (
        <Page>
            {isDeleting && isLoading ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <FullWidthContainer
                display='flex'
            >
                <Title 
                    title='Insurers' 
                    margin='auto auto auto 0'
                />
                <HMBox 
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={green}
                        isResponsive={true}
                        handleClick={handleCreate}
                        icon={<Typography sx={iconStyle}>+</Typography>} 
                        text={<Typography sx={textStyle}>Add Insurer</Typography>} 
                    />
                    <HMButton 
                        type='button'
                        bgColor={blue}
                        isResponsive={true}
                        icon={<UploadFileIcon sx={iconStyle} />} 
                        text={<Typography sx={textStyle}>Import Insurers</Typography>} 
                    />
                </HMBox>
            </FullWidthContainer>
            <HMModal 
                open={open} 
                title={title}
                handleClose={handleClose} 
                colors={{lightBlack, lightBlue}}
            >
                <Typography sx={deleteTextStyle}>
                    Are you sure your want to delete: 
                </Typography>
                {isDelete ? (
                    <Typography sx={deleteItemTextStyle}>
                        {currentInsurer.name} ?
                    </Typography>
                ) : !selectedInsurers.length ? (
                    <Typography sx={deleteItemTextStyle}>
                        No insurer selected. Select insurers and try again!
                    </Typography>
                ) : selectedInsurers.length > 10 ? (
                    <Typography sx={deleteItemTextStyle}>
                        {selectedInsurers.length} Insurers?
                    </Typography>
                ) : (
                    <Box sx={{marginBottom: '20px'}}>
                        {selectedInsurers.map((insurer, index) => (
                            <Typography 
                                sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                                key={index}
                            >
                                {insurer.name}
                            </Typography>
                        ))}
                    </Box>
                )}
                {(selectedInsurers.length || isDelete) && (
                    <HMButton 
                        type='button'
                        margin='auto'
                        float='right'
                        bgColor={red}
                        handleClick={isDelete ? handleDelete : handleBulkDelete}
                        text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                    />
                )}
                <HMButton 
                    type='button'
                    margin='auto'
                    bgColor={gray}
                    handleClick={handleClose}
                    float={(selectedInsurers.length || isDelete) && 'left'}
                    text={<Typography sx={textStyle}>No, Cancel!</Typography>}
                />
            </HMModal>
            {isFinal ? (
                <DataTable
                    data={insurers}
                    title='Insurers'
                    fields={fields}
                    columns={columns}
                    defaultRowsNum={20}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteModal}
                    handleBulkDelete={handleBulkDeleteModal}
                    emptyDataMessage='No insurers available!'
                />
            ) : <></>}
        </Page>
    )
}

export default Insurers
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
  deleteUser, 
  getUsers, 
  resetUser 
} from '../../../features/users/userSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
    iconStyle,
    textStyle,
    deleteTextStyle,
    deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useColors from '../../../hooks/useColors'

const { 
    red,
    gray,
    blue, 
    green,
    lightBlue,
    lightBlack,
} = useColors

function People() {
  const {
      users,
      isError,
      message,
      isLoading,
      isDeleted,
      isSuccess,
  } = useSelector(state => state.user)

  const dispatch = useDispatch() 
  const navigate = useNavigate()

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isFinal, setIsFinal] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
      if (isError || isDeleted || isSuccess) {
          dispatch(resetUser())
      }

      if (isSuccess) {
        setIsFinal(true)
      } else if (!isLoading && !isError) {
        setIsFinal(true)
      }

      if (isError) {
          toast.error(message)
      } else if (isDeleted) {
          toast.success(message)
          dispatch(getUsers())
      }
  }, [dispatch, isLoading, isSuccess, isError, message, isDeleted])

  useEffect(() => {
      dispatch(getUsers())
  }, [dispatch])

  const fields = []
  const columns = [
      { field: 'firstName', headerName: 'First Name', sortable: true},
      { field: 'lastName', headerName: 'Last Name', sortable: true},
      { field: 'email', headerName: 'Email', sortable: true},
      { field: 'accessLevel', headerName: 'Access Level', sortable: false},
      { field: 'status', headerName: 'Status', sortable: true},
  ]

  columns.forEach((column) => {
      fields.push(column.field)
  })

  const getCurentUser = (id) => {
      return users.filter(user => user._id === id)[0]
  }
  const handleCreate = () => {
      navigate('/admin/users/create')
  }
  const handleEdit = (id) => {
      navigate(`/admin/users/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
      setCurrentUser(getCurentUser(id))

      setOpen(true)
      setIsDelete(true)
      setTitle('Delete User')
  }
  const handleBulkDeleteModal = (data) => {
      setSelectedUsers(data)
      setOpen(true)
      setIsDelete(false)
      setTitle('Delete Users')
  }
  const handleDelete = () => {
      dispatch(deleteUser(currentUser._id))
      handleClose()
  }
  const handleBulkDelete = () => {
      // TODO
      handleClose()
  }
  const handleClose = () => {
      setTitle('')
      setOpen(false)
      setIsDelete(false)
      setCurrentUser({})
  }
  return (
    <Page>
      {isLoading ? (
        <HMSpiner 
            size={60}
            zIndex={999}
            width='100%'
            height='80vh'
            margin='auto'
            position='absolute'
            bgColor='transparent'
        />  
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <Title 
            title='Users' 
            margin='auto auto auto 0'
        />
        <HMBox 
            float='right'
            display='flex'
        >
            <HMButton 
                float='right'
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleCreate}
                icon={<Typography sx={iconStyle}>+</Typography>} 
                text={<Typography sx={textStyle}>Add User</Typography>} 
            />
            <HMButton 
                type='button'
                float='right'
                bgColor={blue}
                isResponsive={true}
                icon={<UploadFileIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Import Users</Typography>} 
            />
        </HMBox>
        <HMModal 
            handleClose={handleClose} 
            open={open} 
            colors={{lightBlack, lightBlue}}
            title={title}
        >
            <Typography sx={deleteTextStyle}>
                Are you sure your want to delete: 
            </Typography>
            {isDelete ? (
                <Typography sx={deleteItemTextStyle}>
                {currentUser.name} ?
                </Typography>
            ) : !selectedUsers.length ? (
                <Typography sx={deleteItemTextStyle}>
                No user selected. Select users and try again!
                </Typography>
            ) : selectedUsers.length > 10 ? (
                <Typography sx={deleteItemTextStyle}>
                {selectedUsers.length} Users?
                </Typography>
            ) : (
                <Box sx={{marginBottom: '20px'}}>
                {selectedUsers.map((user, index) => (
                    <Typography 
                        sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                        key={index}
                    >
                        {user.name}
                    </Typography>
                ))}
                </Box>
            )}
            {(selectedUsers.length || isDelete) && (
                <HMButton 
                type='button'
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                bgColor={red}
                handleClick={isDelete ? handleDelete : handleBulkDelete}
                float='right'
                margin='auto'
                />
            )}
            <HMButton 
                type='button'
                text={<Typography sx={textStyle}>No, Cancel!</Typography>}
                bgColor={gray}
                handleClick={handleClose}
                float={(selectedUsers.length || isDelete) && 'left'}
                margin='auto'
            />
        </HMModal>
      </FullWidthContainer>
      {isFinal ? (
          <DataTable
              data={users}
              title='Users'
              fields={fields}
              columns={columns}
              defaultRowsNum={20}
              handleEdit={handleEdit}
              handleDelete={handleDeleteModal}
              emptyDataMessage='No users available!'
              handleBulkDelete={handleBulkDeleteModal}
          />
      ) : <></>}
    </Page>
  )
}

export default People
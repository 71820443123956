import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { 
    getMyStore,
} from '../../../features/stores/storeSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { 
  currencies,
  storeOrderStatus,
  computateOrderData,
  computeInsuredSubtotal
} from '../../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  lightRed,
  darkBlue,
  lightBlue,
  lightBlack,
  lightOrange,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../../hooks/useColors'


function Settings() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const storeState = useSelector((state) => state.store)
  const { myStores } = storeState

  const [currency, setCurrency] = useState('')

  const {
    windowW,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isSmallScreen = windowW < 400 ? true : false

  useEffect(() => {
    if (user) {
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }
      setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
    }
  }, [dispatch, user, myStores, storeId])

  return user ? (
    <div>Settings</div>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default Settings
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
  getMySupplier,
} from '../../../features/suppliers/supplierSlice'
import { 
  resetSupplierListing,
  deleteSupplierListing,
} from '../../../features/supplierListings/supplierListingSlice'
import { 
  getSupplierWithListings,
  resetSuppliersWithListings,
} from '../../../features/suppliersWithListings/suppliersWithListingsSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import {
  red,
  blue,
  gray,
  white,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'
import { getReadableSuppplierListings } from '../../../hooks/helperFunctions'

function Listings() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { supplierId } = useParams()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const {
      isLoading,
      supplierWithListings,
      isSupplierWithListings,
  } = useSelector((state) => state.suppliersWithListings)
  const { mySuppliers } = useSelector((state) => state.supplier)
  const supplierListingState = useSelector((state) => state.supplierListing)
  const { supplierListing } = supplierListingState

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [areListingsSet, setAreListingsSet] = useState(false)
  const [currentSupplierListing, setCurrentSupplierListing] = useState({})
  const [selectedSupplierListings, setSelectedSupplierListings] = useState([])
  const [readableSupplierListings, setReadableSupplierListings] = useState([])

  useEffect(() => {
    if (user) {
      dispatch(getSupplierWithListings(supplierId))
      if (mySuppliers.length) {
        dispatch(getMySupplier(supplierId))
      }
    }
  }, [dispatch, user, supplierId])

  useEffect(() => {
    if (isSupplierWithListings) {
      dispatch(resetSuppliersWithListings())
      const {
        doneSetting,
        readableSupplierListings,
      } = getReadableSuppplierListings(supplierWithListings)
      setAreListingsSet(doneSetting)
      setReadableSupplierListings(readableSupplierListings)
    }

    if (supplierListingState.isError) {
      dispatch(resetSupplierListing())
    } else if (supplierListingState.isDeleted) {
      dispatch(resetSupplierListing())
    } else if (supplierListingState.isError) {
      dispatch(resetSupplierListing())
    }
  }, [dispatch, supplierListingState, isSupplierWithListings])

  const fields = []
  const columns = [
    { field: 'image', headerName: 'Image', sortable: false},
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'prodCategory', headerName: 'Category', sortable: true},
    { field: 'prodParentCategory', headerName: 'Class', sortable: true},
    { field: 'price', headerName: 'Price', sortable: false},
    { field: 'soldOnline', headerName: 'Sold Online', sortable: false},
    { field: 'onlinePrice', headerName: 'Online Price', sortable: false},
    { field: 'stockCount', headerName: 'Stock', sortable: false},
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const getCurentSupplierListing = (id) => {
    if (readableSupplierListings.length) {
      return readableSupplierListings.find(listing => listing._id === id)
    }
  }

  const handleCreate = () => {
    navigate(`/supplier/${supplierWithListings.supplier._id}/listings/create`)
  }
  const handleEdit = (id) => {
    navigate(`/supplier/${supplierWithListings.supplier._id}/listings/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Listing')
    setCurrentSupplierListing(getCurentSupplierListing(id))
  }
  const handleBulkDeleteModal = (data) => {
    setOpen(true)
    setIsDelete(false)
    setTitle('Delete Listings')
    setSelectedSupplierListings(data)
  }
  const handleDelete = () => {
    dispatch(deleteSupplierListing(currentSupplierListing._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    // TODO
    handleClose()
  }
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setCurrentSupplierListing({})
  }

  return user ? (
    <Page>
      {(supplierListingState.isLoading || isLoading) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
        className='fade-in'
      >
        <Title 
          title='Products' 
          margin='auto auto auto 0'
        />
        <HMBox 
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreate}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Product</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={blue}
            isResponsive={true}
            icon={<UploadFileIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Import Products</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        <Typography sx={deleteTextStyle}>
          Are you sure your want to delete the {selectedSupplierListings.length ? 'listings' : 'listing'} for: 
        </Typography>
        {isDelete ? (
          <Typography sx={deleteItemTextStyle}>
            {currentSupplierListing.name} ?
          </Typography>
        ) : !selectedSupplierListings.length ? (
          <Typography sx={deleteItemTextStyle}>
            No listing selected. Select listings and try again!
          </Typography>
        ) : selectedSupplierListings.length > 10 ? (
          <Typography sx={deleteItemTextStyle}>
            {selectedSupplierListings.length} listings?
          </Typography>
        ) : (
          <Box sx={{marginBottom: '20px'}}>
            {selectedSupplierListings.map((listing, index) => (
              <Typography 
                sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                key={index}
              >
                {listing.name}
              </Typography>
            ))}
          </Box>
        )}
        {(selectedSupplierListings.length || isDelete) && (
          <HMButton 
            type='button'
            float='right'
            margin='auto'
            bgColor={red}
            handleClick={isDelete ? handleDelete : handleBulkDelete}
            text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
          />
        )}
        <HMButton 
          type='button'
          margin='auto'
          bgColor={gray}
          handleClick={handleClose}
          float={(selectedSupplierListings.length || isDelete) && 'left'}
          text={<Typography sx={textStyle}>No, Cancel!</Typography>}
        />
      </HMModal>
      {areListingsSet ?  (
        <DataTable
          fields={fields}
          columns={columns}
          defaultRowsNum={20}
          title='Supplier Listings'
          handleEdit={handleEdit}
          data={readableSupplierListings}
          handleDelete={handleDeleteModal}
          emptyDataMessage='No Listings available!'
          handleBulkDelete={handleBulkDeleteModal}
        />
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing supplier content'
      />
    </Section>
  )
}

export default Listings
import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Title from '../tools/Title'
import HMText from '../tools/HMText'
import HMBox from '../layout/HMBox'
import {
  orange,
  lightBlue
} from '../../../hooks/useColors'

function HMAccordion({ title, label, price, listings, children, className, isOnlineTitle }) {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const headerStyle = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  }

  return (
      <Accordion 
        onChange={handleChange('panel1')} 
        elevation={0} expanded={expanded === 'panel1'} 
        className={`HM-accordion ${className} fade-in`}
      >
        <AccordionSummary
          sx={headerStyle}
          id='panel1bh-header'
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
        >
          <Grid container spacing={0} >
            <Grid item xs={isOnlineTitle ? 12 : 3}>
                <Title 
                    size={14}
                    width='100%'
                    title={title}
                    textAlign='left'
                    margin='0 0 5px 0'
                    className='accordion-title'
                />
            </Grid>
            {price && (
                <Grid item xs={6}>
                    <Title 
                        size={14}
                        width='100%'
                        color={orange}
                        margin='0 0 5px 0'
                        textAlign='center'
                        title={`${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Rfw`}
                    />
                </Grid>
            )}
            {listings ? (
                <Grid item xs={6}>
                  <HMBox
                    width='100%'
                    height='100%'
                    display='flex'
                  >
                    <HMText 
                        size={10}
                        margin='auto'
                        borderRadius={5}
                        padding='3px 5px'
                        textAlign='center'
                        bgColor={lightBlue}
                        text={`${listings} ${label ? label : 'listings'}`}
                    />
                  </HMBox>
                </Grid>
            ) : <></>}
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}} className='details'>
          {children}
        </AccordionDetails>
      </Accordion>
  )
}

export default HMAccordion
import React from 'react'
import HMBox from '../common/layout/HMBox'
import HMRadioButton from '../common/tools/HMRadioButton'
import HMTextField from '../common/tools/HMTextField'
import HMDropZone from '../common/tools/HMDropZone'
import HMText from '../common/tools/HMText'
import { fontSize } from '../common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { white, lightBlue } from '../../hooks/useColors'
import { cloudinary } from '../../hooks/helperFunctions'

function AddInfoForm(props) {
  const { windowW } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const supportedFiles = {
    'image/png': [],
    'image/jpg': [],
    'image/jpeg': [],
    'application/pdf': [],
  }

  const askForPresription = {
    question: 'Do you have a prescription?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  }
  const askIfPresription = {
    question: 'Is the patient pregnant?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  }
  const askIfFirstTimeUse = {
    question: 'Is it the first time using this medication?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  }
  const askIfCausedConditions = {
    question: 'Did this medication cause you any condition such as allergies?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  }
  const askPrexistignConditions = {
    question: 'Patient pre-existing conditions',
    choices: [
      { label: 'None', value: 'None' },
      { label: 'Other', value: 'Other' },
      { label: 'Allegies', value: 'Allegies' },
    ],
  }

  return (
    <>
      {props.classification.includes('otc') ? (
        <>
          <HMRadioButton
            margin='auto 0'
            bgColor={white}
            padding='2px 5px'
            name='hasPrescription'
            fontSize={`${fontSize}px`}
            onChange={props.handleChange}
            label={askForPresription.question}
            options={askForPresription.choices}
            labelId='ask-prescription-radio-group'
            value={props.formData.hasPrescription}
            flexDirection={isTabletScreen ? 'column' : 'row'}
          />
          {!props.formData.hasPrescription ? (
            <>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                className='fade-in'
                bgColor='transparent'
              >
                <HMText text="Patient's Age" margin='auto auto auto 5px' />
                <HMTextField
                  width='110px'
                  type='number'
                  required={true}
                  name='patientAge'
                  borderRadius='8px'
                  bgColor={lightBlue}
                  onChange={props.handleChange}
                  value={props.formData.patientAge}
                  margin={isTabletScreen ? '5px 0 10px 0' : 'auto 0 auto auto'}
                />
              </HMBox>
              <HMRadioButton
                margin='auto 0'
                bgColor={white}
                padding='2px 5px'
                className='fade-in'
                name='isPatientPregnant'
                fontSize={`${fontSize}px`}
                onChange={props.handleChange}
                label={askIfPresription.question}
                options={askIfPresription.choices}
                labelId='ask-prescription-radio-group'
                value={props.formData.isPatientPregnant}
                flexDirection={isTabletScreen ? 'column' : 'row'}
              />
              <HMRadioButton
                margin='auto 0'
                bgColor={white}
                padding='2px 5px'
                radioWidth='160px'
                name='firstTimeUse'
                className='fade-in'
                labelMargin='auto 0'
                labelWidth='max-content'
                fontSize={`${fontSize}px`}
                onChange={props.handleChange}
                label={askIfFirstTimeUse.question}
                value={props.formData.firstTimeUse}
                options={askIfFirstTimeUse.choices}
                labelId='ask-prescription-radio-group'
                flexDirection={isTabletScreen ? 'column' : 'row'}
                radioMargin={!isTabletScreen && 'auto 0 auto auto'}
              />
              {props.formData.firstTimeUse === false ||
              props.formData.firstTimeUse === 'false' ? (
                <HMRadioButton
                  margin='auto 0'
                  bgColor={white}
                  padding='2px 5px'
                  radioWidth='160px'
                  className='fade-in'
                  labelMargin='auto 0'
                  name='causedConditions'
                  labelWidth='max-content'
                  fontSize={`${fontSize}px`}
                  onChange={props.handleChange}
                  labelId='ask-prescription-radio-group'
                  label={askIfCausedConditions.question}
                  options={askIfCausedConditions.choices}
                  value={props.formData.causedConditions}
                  flexDirection={isTabletScreen ? 'column' : 'row'}
                  radioMargin={!isTabletScreen && 'auto 0 auto auto'}
                />
              ) : (
                <></>
              )}
              <HMRadioButton
                margin='auto 0'
                bgColor={white}
                padding='2px 5px'
                className='fade-in'
                fontSize={`${fontSize}px`}
                name='preExistingConditions'
                onChange={props.handleChange}
                labelId='ask-prescription-radio-group'
                label={askPrexistignConditions.question}
                options={askPrexistignConditions.choices}
                value={props.formData.preExistingConditions}
                flexDirection={isTabletScreen ? 'column' : 'row'}
              />
              {props.formData.preExistingConditions === 'Other' ? (
                <HMTextField
                  type='text'
                  width='100%'
                  name='other'
                  margin='5px 0'
                  borderRadius='8px'
                  className='fade-in'
                  bgColor={lightBlue}
                  label='Other conditions'
                  value={props.formData.other}
                  onChange={props.handleChange}
                  placeholder='Enter other pre-existing conditions'
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {props.formData.hasPrescription ||
      props.classification.includes('prescription') ? (
        <HMBox padding='0' width='100%' margin='10px 0' className='fade-in'>
          <HMDropZone
            allowedSizeInMB={1}
            className='drag-drop'
            supportedFiles={supportedFiles}
            endPoint={cloudinary.API_URL_PRESCRIPTION}
            cloudinaryFileUrl={props.prescriptionFileUrl}
            setCloudinaryFileUrl={props.setPrescriptionFileUrl}
          />
        </HMBox>
      ) : (
        <></>
      )}
    </>
  )
}

export default AddInfoForm

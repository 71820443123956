import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import deliveryService from './deliveryService'

const initialState = {
    message: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    deliveryAgents: [],
}

// Get nearest delivery agent
export const getNearestDeliveryAgent = createAsyncThunk(
    'delivery/nearestDeliveryAgent',
    async (obj, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await deliveryService.getNearestDeliveryAgent(obj, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {
        resetDelivery: (state) => {
            state.message = ''
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNearestDeliveryAgent.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getNearestDeliveryAgent.fulfilled, (state, action) => {
                state.isError = false
                state.isSuccess = true
                state.isLoading = false
                state.deliveryAgents = action.payload
            })
            .addCase(getNearestDeliveryAgent.rejected, (state, action) => {
                state.isError = true
                state.isSuccess = false
                state.isLoading = false
                state.message = action.payload
            })
    }
})

export const { resetDelivery } = deliverySlice.actions
export default deliverySlice.reducer
import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { orange, lightBlack, white, blue } from '../../hooks/useColors'
import { textStyle } from '../../components/common/tools/Styles'
import HMButton from '../../components/common/tools/HMButton'
import HMTextField from '../../components/common/tools/HMTextField'
import HMText from '../../components/common/tools/HMText'
import '../../index.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const StepperLabelStyles = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: 'orange',
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: 'grey.500',
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: 'orange',
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: 'orange',
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'white',
  },
}

const steps = [
  'Provide us your phone number',
  'Provide us a one time code we sent to your phone number',
  'Finish setting up your SMS 2fa',
]

const STEPS_TITLE = ['Phone', 'Verify', 'Finish']

const SmsModel = (props) => {
  const [activeStep, setActiveStep] = useState(0)

  // const handleClose = () => {
  //   props.onClose()
  // }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }


  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <HMButton
          float="right"
          margin="-20px -10px 0 0"
          handleClick={props.onClose}
          icon={<CloseIcon sx={{ color: lightBlack }} />}
        />
        {/* ++++++++++ stepper label+++++++++++ */}

        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ width: '100%', marginTop: '50px', padding: '100px 0 50px 0' }}
        >
          {steps.map((label) => {
            const stepProps = {}
            const labelProps = {}

            return (
              <Step key={label} {...stepProps} sx={StepperLabelStyles}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
       
          <>
            {activeStep === 0 && (
              <>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '30px 0',
                    margin: 'auto',
                  }}
                >
                  <Grid item md={6} xs={7}>
                    <HMTextField
                      value="0782620622"
                      name="phone"
                      label="Phone number"
                      placeholder="Enter phone number"
                    />
                  </Grid>

                  <Grid item md={3} xs={4}>
                    <HMButton
                      width="70%"
                      float="left"
                      margin="6px auto"
                      type="button"
                      bgColor={orange}
                      text={<Typography sx={textStyle}>Send SMS</Typography>}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '30px 0',
                    margin: 'auto',
                  }}
                >
                  <Grid item xs={7} md={6}>
                    <HMTextField
                      value="1    2    5    7"
                      name="code"
                      label="Code"
                      placeholder="Enter code we sent you"
                    />
                  </Grid>

                  <Grid item xs={4} md={2}>
                    <HMButton
                      width="100%"
                      float="left"
                      padding="6px 20px"
                      margin="6px auto"
                      type="button"
                      bgColor={orange}
                      text={<Typography sx={textStyle}>Verify</Typography>}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Box sx={{ marginTop: '20px' }}>
                  <HMText
                    text="Congratulations!"
                    fontSize="25px"
                    fontWeight="700"
                    textAlign="center"
                    float="center"
                  />

                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      textAlign: 'center',
                      float: 'center',
                      width: '40%',
                      margin: '5px auto',
                    }}
                  >
                    It’s a final step. If you are ready to start using SMS 2fa
                    click{' '}
                    <span className=" finish" styles={{ color: 'orange' }}>
                      Finish
                    </span>
                  </Typography>
                </Box>
              </>
            )}

            <Box sx={{ position: 'absolute', top: 40, left: '48.5%' }}>
              {activeStep === 0 && (
                <Typography
                  sx={{ mt: 2, mb: 1, fontWeight: '700', fontSize: '20px' }}
                >
                  {STEPS_TITLE[0]}
                </Typography>
              )}
              {activeStep === 1 && (
                <Typography
                  sx={{ mt: 2, mb: 1, fontWeight: '700', fontSize: '20px' }}
                >
                  {STEPS_TITLE[1]}
                </Typography>
              )}
              {activeStep === 2 && (
                <Typography
                  sx={{ mt: 2, mb: 1, fontWeight: '700', fontSize: '20px' }}
                >
                  {STEPS_TITLE[2]}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              {activeStep === steps.length - 1 ? (
                <HMButton              
                  handleClick = {props.onClose}
                  bgColor = {orange}
                  color = {white}
                  text={
                    <Typography sx={{  }}>
                      Finish{' '}
                    </Typography>
                  }
                />
              ) : (
                <Button onClick={handleNext} sx={{color: lightBlack}}>Next</Button>
              )}
            </Box>
          </>
        
      </Box>
    </Modal>
  )
}

export default SmsModel

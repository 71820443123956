import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { 
  getMe, 
  resetMe, 
  updateMe, 
} from '../../features/me/meSlice'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMRadioButton from '../../components/common/tools/HMRadioButton'
import HMDatePicker from '../../components/common/tools/HMDatePicker'
import HMTextField from '../../components/common/tools/HMTextField'
import HMDropZone from '../../components/common/tools/HMDropZone'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import { 
  blue, 
  white, 
  orange,
  lightBlue, 
} from '../../hooks/useColors'
import { 
  iconStyle,
  textStyle, 
} from '../../components/common/tools/Styles'
import {
  cloudinary,
} from '../../hooks/helperFunctions'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const minBirthdate = new Date()
minBirthdate.setDate(minBirthdate.getDate() - 4745)

const defaultFormData = (user) => {
  const checkData = user && Object.keys(user).length ? true : false

  return {
    phone: (checkData && user.phone) ? user.phone : '',
    email: (checkData && user.email) ? user.email : '', 
    gender: (checkData && user.gender) ? user.gender : '',
    image: (checkData && user.image) ? user.image : '',
    lastName: (checkData && user.lastName) ? user.lastName : '',
    firstName: (checkData && user.firstName) ? user.firstName : '',
    birthdate: (checkData && user.birthdate) ? new Date(user.birthdate) : dayjs(minBirthdate),
  }
}

function EditProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { 
    me, 
    isOne, 
    isUpdated, 
    isLoading, 
  } = useSelector(state => state.me)
  const { user } = useSelector(state => state.auth)
  const { visible } = useSelector(state => state.nav)
  const { isUploading } = useSelector(state => state.cloudinary)

  const path = window.location.pathname.toLowerCase()

  const [userImageUrl, setUserImageUrl] = useState('')
  const [wrongNumber, setWrongNumber] = useState(false)
  const [isFileUploading, setFileUploading] = useState(false)
  const [formData, setFormData] = useState(defaultFormData(me))
  const { 
    phone,
    email,
    image,
    gender,
    lastName,
    firstName,
    birthdate,
  } = formData

  const {
    windowW
  } = useWindowDimensions()
  
  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false

  useEffect(() => {
    if (user) {
      dispatch(getMe())
    }
    // eslint-disable-next-line
  }, [user])
  
  useEffect(() => {
    if (isOne || isUpdated) {
      dispatch(resetMe())
    }

    if (isOne || isUpdated) {
      setUserImageUrl(me.image)
      setFormData(defaultFormData(me))
    }
    // eslint-disable-next-line
  }, [isOne, isUpdated])

  useEffect(() => {
    if(userImageUrl) {
      setFileUploading(false)
      setFormData((prevState) => ({
        ...prevState,
        image: userImageUrl,
      }))
    }
  }, [userImageUrl])

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  const handleUpdate = (e) => {
    e.preventDefault()
    
    const updatedMe = {
      email,
      image,
      gender,
      lastName,
      firstName,
      _id: me._id,
      phone: parseInt(phone),
      birthdate: birthdate.$d,
    }

    for (let key in updatedMe) {
      if (!updatedMe[key]) {
        delete updatedMe[key]
      }
    } 

    dispatch(updateMe(updatedMe))
  }

  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Edit My Profile', link: null },
  ]

  const handleView = () => {
    navigate(
      path.includes('admin') ? '/admin/my-profile'
      : path.includes('store') ? '/store/my-profile'
      : path.includes('supplier') ? '/supplier/my-profile'
      : '/profile'
    )
  }
  
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    if (name === 'phone') {
      if (value.length <= 10) {
        const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value)
        if (!isValid && value.length) {
          e.target.classList.add('wrong-input')
          setWrongNumber(true)
        } else if (isValid || !value.length) {
          e.target.classList.remove('wrong-input')
          setWrongNumber(false)
        } 
  
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
      }
    } 
    
    if (name !== 'phone') {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  const handleBirthdateChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      birthdate: newValue
    }))
  }

  const genders = {
    question: 'Gender',
    choices: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' }
    ]
  }

  const disabled = (
    email === '' ||
    isFileUploading || 
    lastName === '' ||
    firstName === '' ||
    wrongNumber === true
  ) ? true : false

  return user ? (
    <Page>
      {isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer  
        display='flex' 
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton  
            type='button'
            bgColor={orange}
            margin='auto 10px'
            isResponsive={true}
            handleClick={handleView}
            icon={<RemoveRedEyeIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>View profile</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      {Object.keys(me).length ? (
        <Box
          spacing={1} 
          component='form'
          onSubmit={handleUpdate}
          sx={{ width: '100%' }}
        >
          <Section
            width='100%'
            padding={10}
            display='flex'
            bgColor={white}
          > 
            <HMBox
              margin='auto'
              display='flex'
              flexDirection='column'
              width={
                isTabletScreen ? '100%' 
                : (isSamllComputerScreen && visible) ? '100%'
                : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? '80%'
                : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? '80%' 
                : '60%'
              }
            >
              <HMBox
                width='100%'
                display='flex'
                padding='10px'
                bgColor={lightBlue}
                flexDirection='column'
                margin='20px auto 10px auto'
              >
                <HMText 
                  left='0'
                  top='-35px'
                  height='30px'
                  bgColor={white}
                  fontWeight={500}
                  width='max-content'
                  className='fade-in'
                  position='relative'
                  text='Upload your photo'
                  margin='10px 0 -25px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMDropZone 
                  bgColor={white}
                  allowedSizeInMB={1}
                  className='drag-drop' 
                  cloudinaryFileUrl={userImageUrl}
                  endPoint={cloudinary.API_URL_USERS}
                  setCloudinaryFileUrl={setUserImageUrl}
                  supportedFiles = {{
                    'image/png': [],
                    'image/jpg': [],
                    'image/jpeg': [],
                  }}
                />
              </HMBox>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='10px auto'
              >
                <HMTextField 
                  required={true}
                  name='firstName'
                  label='FirstName'
                  value={firstName}
                  onChange={handleChange}
                  margin='auto 10px auto 0'
                  placeholder='Enter fristname'
                />
                <HMTextField 
                  name='lastName'
                  required={true}
                  label='LastName'
                  value={lastName}
                  onChange={handleChange}
                  margin='auto 0 auto 10px'
                  placeholder='Enter lastName'
                />
              </HMBox>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='10px auto'
              >
                <HMTextField 
                  name='email'
                  label='Email'
                  value={email}
                  required={true}
                  onChange={handleChange}
                  margin='auto 10px auto 0'
                  placeholder='Enter email'
                />
                <HMTextField 
                  name='phone'
                  type='number'
                  value={phone}
                  label='Phone number'
                  margin='auto 0 auto 10px'
                  onChange={handleChange}
                  placeholder='Enter phone number'
                />
              </HMBox>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='10px auto'
              >
                <HMRadioButton 
                  name='gender'
                  value={gender}
                  padding='2px 5px'
                  flexDirection='row'
                  onChange={handleChange}
                  label={genders.question}
                  options={genders.choices}
                  labelId='require-prescription-radio-group'
                />
                <HMBox
                  width='100%'
                  display='flex'
                  margin='auto 0 auto 10px'
                >
                  <HMText 
                    width='100%'
                    text='Birthdate'
                    fontWeight={400}
                    margin='auto auto auto 5px'
                  />
                  <HMDatePicker
                    value={birthdate}
                    margin='auto 0 auto auto'
                    maxDate={dayjs(minBirthdate)}
                    onChange={handleBirthdateChange}
                  />
                </HMBox>
              </HMBox>
            </HMBox>
          </Section>

          <Section
            width='100%'
            padding={10}
            bgColor={white}
            marginBottom='40px'
          > 
            <HMBox
              float='none'
              margin='auto'
              display='flex'
              width={
                isTabletScreen ? '100%' 
                : (isSamllComputerScreen && visible) ? '100%'
                : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? '80%'
                : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? '80%' 
                : '60%'
              }
            >
              <HMButton 
                width='100%'
                type='submit'
                margin='auto'
                bgColor={blue}
                disabled={disabled}
                text={<Typography sx={{ ...textStyle, fontWeight: 500 }}>Update profile</Typography>}
              />
            </HMBox>
          </Section>     
        </Box>  
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing your profile'
      />
    </Section> 
  )
}

export default EditProfile

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { 
  getLatLng,
  geocodeByAddress, 
} from 'react-places-autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  getMe,
  resetMe, 
  updateMe, 
} from '../../features/me/meSlice'
import { 
  resetSupplier, 
  createSupplier, 
  updateSupplier, 
  getMySuppliers,
  getSuppliersForApplication
} from '../../features/suppliers/supplierSlice'
import ApplicationStatus from '../../components/supplier/ApplicationStatus'
import HMStepper from '../../components/supplier/HMStepper'
import LastStep from '../../components/supplier/LastStep'
import StepOne from '../../components/supplier/StepOne'
import StepTwo from '../../components/supplier/StepTwo'
import FDAStep from '../../components/supplier/FDAStep'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import Title from '../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {  
  supplierTypes, 
  removeDuplicates,
  supplierApplicationStatus
} from '../../hooks/helperFunctions'
import {
  white,
  orange,
  darkBlue,
  skeletonsLightBlue
} from '../../hooks/useColors'

const checkAvalaibleSupplier = (currentSupplier, suppliers, name) => {
  const supplier = suppliers.find(supplier => supplier.name.toLowerCase() === name.toLowerCase().trim())
    
  if (Object.keys(currentSupplier).length && supplier) {
    return supplier.name !== currentSupplier.name ? true : false
  } else if (!Object.keys(currentSupplier).length && supplier) {
    return true
  } else {
    return false
  }
}

const SupplierApplication = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { supplierId } = useParams()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const meState = useSelector((state) => state.me)
  const { me } = meState
  const supplierState = useSelector((state) => state.supplier)
  const { mySuppliers, suppliersForApplication } = supplierState
  const {isUploaded, isUploading, isUploadError, file} = useSelector((state) => state.cloudinary)

  const minExpirationDate = new Date()
  minExpirationDate.setDate(minExpirationDate.getDate() + 31)

  const isAnotherSupplierPath = window.location.pathname === '/supplier/application' ? true : false 

  const formDataSeckeleton = {
    cell: '',
    type: '',
    logo: '',
    email: '',
    street: '',
    sector: '',
    village: '',
    phone: [''],
    province: '',
    district: '',
    lastName: '',
    supplierName: '',
    firstName: '',
    mapAddress: '',
    licenseFile: 'this is file url from cloudinary',
    issueOn: dayjs(),
    useSameNameForPharmacist: false,
    expiresOn: dayjs(minExpirationDate),
    pharmacist: {
      firstName: '',
      lastName: '',
    }, 
    coordinates: {
      lng: null,
      lat: null,
    }
  }
  const supplierApplicationData = JSON.parse(localStorage.getItem('supplierApplicationData')) 
  const currentFormData = (supplierApplicationData && Object.keys(supplierApplicationData).length) ? {
    ...supplierApplicationData,
    issueOn: formDataSeckeleton.issueOn,
    expiresOn: formDataSeckeleton.expiresOn,
  } : formDataSeckeleton
  const [isPharmacy, setIsPharmacy] = useState(false)
  const [currentSupplier, setCurrentSupplier] = useState({})
  const [isAllLoaded, setIsAllLoaded] = useState(false)
  const [emptyNumber, setEmptyNumber] = useState(false)
  const [wrongNumber, setWrongNumber] = useState(false)
  const [isEmailEditable, setEmailEditable] = useState(false)
  const [isCreatingSupplier, setIsCreatingSupplier] = useState(false)
  const [isUpdatingSupplier, setIsUpdatingSupplier] = useState(false)
  const [isApplyingAgain, setIsApplyingAgain] = useState(false)
  const [isSupplierNameTaken, setIsSupplierNameTaken] = useState(false)
  const [isLastNameEditable, setLastNameEditable] = useState(false)
  const [isFirstNameEditable, setFirstNameEditable] = useState(false)
  const [isAcceptableLicense, setIsAcceptableLicense] = useState(false)

  const [formData, setFormData] = useState(currentFormData)
  const [supplierLogo, setSupplierLogo] = useState('')
  const [isFileUploading, setFileUploading] = useState(false)
  const [licenseFileUrl, setLicenseFileUrl] = useState('')

  const {
    cell,
    logo,
    type,
    phone,
    sector,
    street,
    issueOn,
    village,
    province,
    district,
    lastName,
    supplierName,
    firstName,
    expiresOn,
    mapAddress,
    pharmacist,
    coordinates,
    licenseFile,
  } = formData

  const {
    windowW,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const breadOptions = [
    { text: 'Home', link: (Object.keys(currentSupplier).length || isAnotherSupplierPath) ? '/supplier' : '/landing' },
    { text: 'Supplier Application', link: null },
  ]

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  useEffect(()=>{
    if(supplierLogo) setFileUploading(false)
  },[supplierLogo])

  useEffect(() => {
    if (supplierState.isError || supplierState.isSuccess || supplierState.isApplicationSuppliers) {
      dispatch(resetSupplier())
    } else if (supplierState.isCreated) {
      dispatch(resetSupplier())
      resetApplicationForm()
      dispatch(getMySuppliers())
      setIsCreatingSupplier(false)
      setCurrentSupplier(supplierState.supplier)
    } else if (supplierState.isUpdated) {
      dispatch(resetSupplier())
      resetApplicationForm()
      dispatch(getMySuppliers())
      setIsUpdatingSupplier(false)
      setIsApplyingAgain(false)
      setCurrentSupplier(supplierState.supplier)
    }

    if (meState.isOne) {
      dispatch(resetMe())
      setFormData((prevState) => ({
        ...prevState,
        email: me.email,
        lastName: me.lastName,
        firstName: me.firstName,
      }))
    } else if (meState.isUpdated) {
      dispatch(resetMe())
    }

    setTimeout(() => {
      if (!meState.isLoading && !supplierState.isLoading) {
        setIsAllLoaded(true)
      }
    }, 1000)
    // eslint-disable-next-line 
  }, [dispatch, navigate, supplierState, me, meState])

  useEffect(() => {
    if (user) {
      dispatch(getMe())
      dispatch(getSuppliersForApplication())
    }
  }, [dispatch, user])

  useEffect(() => {
    setIsPharmacy((type && type.name === supplierTypes[0]) ? true : false)
    let count = 0
    formData.phone.forEach(item => {
      if (!item.toString().length) {
        setEmptyNumber(true)
      } else {
        count++
      }
    })
    if (count === formData.phone.length) {
      setEmptyNumber(false)
    }

    localStorage.setItem('supplierApplicationData', JSON.stringify(formData))

    const today = new Date(Date.now())
    const diff = new Date(formData.expiresOn).getTime() - today.getTime() 
    const days = Math.floor(diff / 1000 / 60 / 60 / 24)

    setIsAcceptableLicense(days >= 30 ? true : false)

    if (suppliersForApplication.length) {
      const hasSupplier = suppliersForApplication.some(supplier => supplier.users.includes(user._id))
      let tempCurrentSupplier = {}
      if (hasSupplier && !supplierId && !isAnotherSupplierPath) {
          navigate('/supplier')
      } else if (supplierId && mySuppliers.length) {
        tempCurrentSupplier = mySuppliers.find(supplier => supplier._id === supplierId)
        if (tempCurrentSupplier) {
          setCurrentSupplier(tempCurrentSupplier)
        } else {
          navigate(-1)
        }
      }

      setIsSupplierNameTaken(checkAvalaibleSupplier(tempCurrentSupplier, suppliersForApplication, formData.supplierName))

    } 
  }, [navigate, mySuppliers, supplierId, formData, type, suppliersForApplication, isAnotherSupplierPath, user])

  const handleEnableChange = (name) => {
    if (name === 'firstName') {
      setFirstNameEditable(true)      
    } else if (name === 'lastName') {
      setLastNameEditable(true)
    } else if (name === 'email') {
      setEmailEditable(true)
    }
  }
  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'pharmacistFirstName') {
      setFormData((prevState) => ({
        ...prevState,
        pharmacist: {
          ...prevState.pharmacist,
          firstName: value,
        },
      }))
    } else if (name === 'pharmacistLastName') {
      setFormData((prevState) => ({
        ...prevState,
        pharmacist: {
          ...prevState.pharmacist,
          lastName: value,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(()=>{
    setFormData((prevState) => ({
      ...prevState,
      logo: supplierLogo,
    }))
  },[supplierLogo])

  useEffect(()=>{
    setFormData((prevState) => ({
      ...prevState,
      licenseFile: licenseFileUrl,
    }))
  },[licenseFileUrl])

  const handlePhones = (index, e) => {
    const phoneValues = [...phone]
    const value = e.target.value
    const stringValue = value.toString()
    if (stringValue.length <= 10) {

      const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value) 
      phoneValues[index] = value

      if (!isValid && stringValue.length) {
        e.target.classList.add('wrong-input')
        setWrongNumber(true)
      } else if (isValid || !stringValue.length) {
        e.target.classList.remove('wrong-input')
        setWrongNumber(false)
      }
    } 
    
    setFormData((prevState) => ({
      ...prevState,
      phone: phoneValues
    }))
  }
  const handleRemovePhone = (index) => {
    const phoneValues = [...phone]
    if (phone.length > 1) {
      phoneValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          phone: phoneValues
      }))
    }
  }
  const handleAddPhone = (index) => {
    const phoneValues = [...phone]
    phoneValues.splice(index + 1, 0, '')
    setFormData((prevState) => ({
        ...prevState,
        phone: phoneValues
    }))
  }
  
  const handleTypeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      type: val
    }))
  }

  const handleUseSameNameForPharmacist = (e) => {
    const value = e.target.checked
    setFormData((prevState) => ({
      ...prevState,
      useSameNameForPharmacist: value,
      pharmacist: value ?  {
        firstName: prevState.firstName,
        lastName: prevState.lastName,
      } : prevState.pharmacist
    }))
  }
  const handleIssueOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      issueOn: newValue,
    }))
  }
  const handleExpiresOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      expiresOn: newValue,
    }))
  }

  const handleProvinceChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      sector: '',
      village: '',
      district: '',
      province: val ? val : '',
    }))
  }
  const handleDistrictChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      sector: '',
      village: '',
      district: val ? val : '',
    }))
  }
  const handleSectorChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      village: '',
      sector: val ? val : '',
    }))
  }
  const handleCellChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      village: '',
      cell: val ? val : '',
    }))
  }
  const handleVillageChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      village: val ? val : '',
    }))
  }
  const handleChangeMapAddress = (address) => {
    setFormData((prevState) => ({
        ...prevState,
        mapAddress: address
    }))
  }
  const handleSelectMapAddress = async (address) => {
    const results = await geocodeByAddress(address)
    const ll = await getLatLng(results[0])

    handleChangeMapAddress(address)
    setFormData((prevState) => ({
        ...prevState,
        coordinates: ll
    }))
  }

  const resetApplicationForm = () => {
    setFormData({
      ...formDataSeckeleton,
      email: me.email,
      lastName: me.lastName,
      firstName: me.firstName,
    })
    localStorage.setItem('supplierApplicationData', JSON.stringify({})) 
  }

  const disableStepOne = (firstName === '' || lastName === '') ? true : false
  const disableStepTwo = (
    isFileUploading ||
      wrongNumber || 
      emptyNumber || 
      isSupplierNameTaken ||
      supplierName === '' ||
      (type === '' || (type !== null && type['name'] === ''))
    ) ? true : false
  const disableFDAStep = (
      (type !== null && type['name'] === supplierTypes[0]) && (
        licenseFile === '' || 
        !isAcceptableLicense ||
        pharmacist.lastName === '' ||
        pharmacist.firstName === '' 
      )
    ) ? true : false
  const disableLastStep = (
      !coordinates.lng ||
      !coordinates.lat ||
      mapAddress === '' ||
      (cell === '' || (cell['name'] && cell['name'] === '')) ||
      (user === '' || (user['email'] && user['email'] === '')) ||
      (sector === '' || (sector['name'] && sector['name'] === '')) ||
      (village === '' || (village['name'] && village['name'] === '')) ||
      (district === '' || (district['name'] && district['name'] === '')) ||
      (province === '' || (province['name'] && province['name'] === '')) 
    ) ? true : false

  const disableSubmit = (disableStepOne || disableStepTwo || disableFDAStep || disableLastStep) ? true : false  

  const steps = [
    {
      label: 'Tell us about yourself',
      description: (
        <StepOne 
          formData={formData}
          handleChange={handleChange}
          isEmailEditable={isEmailEditable}
          isLastNameEditable={isLastNameEditable}
          handleEnableChange={handleEnableChange}
          isFirstNameEditable={isFirstNameEditable}
        />
      ),
    },
    {
      label: 'Supplier Information',
      description: (
        <StepTwo 
          formData={formData}
          wrongNumber={wrongNumber}
          handleChange={handleChange}
          handlePhones={handlePhones}
          handleAddPhone={handleAddPhone}
          isSupplierNameTaken={isSupplierNameTaken}
          handleTypeChange={handleTypeChange}
          handleRemovePhone={handleRemovePhone}
          setSupplierLogo ={setSupplierLogo}
          supplierLogo = {supplierLogo}
        />
      ),
    },
    {
      label: 'Supplier Location',
      description: (
        <LastStep
          formData={formData}
          handleChange={handleChange}
          handleCellChange={handleCellChange}
          handleSectorChange={handleSectorChange}
          handleVillageChange={handleVillageChange}
          handleDistrictChange={handleDistrictChange}
          handleProvinceChange={handleProvinceChange}
          handleSelectMapAddress={handleSelectMapAddress}
          handleChangeMapAddress={handleChangeMapAddress}
        />
      ),
    },
  ]

  const fdaForm = {
    label: 'FDA License',
    description: (
      <FDAStep 
        formData={formData}
        handleChange={handleChange}
        minDate={dayjs(minExpirationDate)}
        isAcceptableLicense={isAcceptableLicense}
        handleIssueOnChange={handleIssueOnChange}
        handleExpiresOnChange={handleExpiresOnChange}
        handleUseSameNameForPharmacist={handleUseSameNameForPharmacist}
        setLicenseFileUrl={setLicenseFileUrl}
        licenseFileUrl={licenseFileUrl}
      />
    ),
  }

  if (isPharmacy) {
    steps.splice(2, 0, fdaForm)
  }
  const handleCreate = (e) => {
    e.preventDefault()
    if (
      (firstName.toLowerCase().trim() !== me.firstName.toLowerCase()) || 
      (lastName.toLowerCase().trim() !== me.lastName.toLowerCase())
    ) {
      const updatedMe = {
        lastName,
        firstName,
      }
      dispatch(updateMe(updatedMe))
    }

    const newSupplier = {
      logo,
      type: type.name,
      name: supplierName,
      phone: removeDuplicates(phone, 'number'),
      applicationStack: [
        {
          message: '',
          timestamp: new Date(),
          status: supplierApplicationStatus[0]
        }
      ],
      address: {
        street,
        mapAddress,
        cell: cell.name,
        sector: sector.name,
        village: village.name,
        district: district.name,
        province: province.name,
      },
      location: Object.keys(coordinates).length && {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      },
    }

    if (type.name.toLowerCase() === supplierTypes[0].toLowerCase()) {
      newSupplier['license'] = {
        pharmacist,
        image: licenseFile,
        issueOn: issueOn.$d,
        expiresOn: expiresOn.$d,
      }
    } 

    setIsCreatingSupplier(true)
    dispatch(createSupplier(newSupplier))
  }
  
  const handleViewSupplier = () => {
    navigate(`/supplier/${currentSupplier._id}/dashboard`)
  }
  const handleApplyAgain = () => {
    let tempFormData = {
      email: me.email,
      lastName: me.firstName,
      logo: currentSupplier.logo,
      firstName: me.firstName,
      phone: currentSupplier.phone,
      supplierName: currentSupplier.name,
      type: { name: currentSupplier.type },
      street: currentSupplier.address.street,
      cell: { name: currentSupplier.address.cell },
      mapAddress: currentSupplier.address.mapAddress, 
      sector: { name: currentSupplier.address.sector },
      village: { name: currentSupplier.address.village },
      province: { name: currentSupplier.address.province },
      district: { name: currentSupplier.address.district },
      coordinates: {
        lat: currentSupplier.location.latitude,
        lng: currentSupplier.location.longitude,
      }
    }

    if (currentSupplier.type.toLowerCase() === supplierTypes[0].toLowerCase()) {
      tempFormData = {
        ...tempFormData,
        licenseFile: currentSupplier.license.image,
        pharmacist: currentSupplier.license.pharmacist,
        issueOn: dayjs(currentSupplier.license.issueOn),
        expiresOn: dayjs(currentSupplier.license.expiresOn),
      }
    }
    setFormData(tempFormData)
    setIsApplyingAgain(true)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    if (
      (firstName.toLowerCase().trim() !== me.firstName.toLowerCase()) || 
      (lastName.toLowerCase().trim() !== me.lastName.toLowerCase())
    ) {
      const updatedMe = {
        lastName,
        firstName,
      }
      dispatch(updateMe(updatedMe))
    }

    const updatedSupplier = {
      logo,
      type: type.name,
      name: supplierName,
      _id: currentSupplier._id,
      phone: removeDuplicates(phone, 'number'),
      applicationStack: [
        ...currentSupplier.applicationStack,
        {
          message: '',
          timestamp: new Date(),
          status: supplierApplicationStatus[1]
        }
      ],
      address: {
        street,
        mapAddress,
        cell: cell.name,
        sector: sector.name,
        village: village.name,
        district: district.name,
        province: province.name,
      },
      location: Object.keys(coordinates).length && {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      },
    }

    if (type.name.toLowerCase() === supplierTypes[0].toLowerCase()) {
      updatedSupplier['license'] = {
        pharmacist,
        image: licenseFile,
        issueOn: issueOn.$d,
        expiresOn: expiresOn.$d,
      }
    } 

    setIsUpdatingSupplier(true)
    dispatch(updateSupplier(updatedSupplier))
  }

  return user ? (
    <Page>
      {(isCreatingSupplier || isUpdatingSupplier || !isAllLoaded) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <Section 
        padding={10}
        bgColor={white}
        paddingBottom={0}
      >
        {(Object.keys(currentSupplier).length && !isApplyingAgain && !isUpdatingSupplier) ? (
          <ApplicationStatus
            handleViewSupplier={handleViewSupplier}
            handleApplyAgain={handleApplyAgain}
            applicationStack={currentSupplier.applicationStack}
          />
        ) : (!Object.keys(currentSupplier).length && isAllLoaded) || isApplyingAgain ? (
          <Grid container spacing={2}>
            <Grid 
              item 
              sm={5} 
              xs={12} 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: skeletonsLightBlue
              }}
            >
              <HMBox
                display='flex'
                width={isTabletScreen ? '100%' : '80%'}
                margin={isTabletScreen ? 'auto auto 20px auto' : '10% auto 5% auto'}
              >
                <AddBusinessIcon 
                  sx={{
                    margin: 'auto 10px auto 0',
                    fontSize: `${fontSize + 14}px`
                  }}
                />
                <Title 
                  color={darkBlue}
                  size={fontSize + 4}
                  title='Add your supplier'
                  margin='auto auto auto 10px'
                />
              </HMBox>
              <Typography
                sx={{
                  ...textStyle, 
                  color: darkBlue,
                  margin: '0 auto',
                  textAlign: 'justify',
                  width: isTabletScreen ? '100%' : '80%'
                }}
              >
                <b>We will help you bring your supplier online.</b> It's easy! 
                <br/>
                Simply complete these few easy applicationsteps. 
                <br/>
                <br/>
                Our team processes your application within 2 working days.
              </Typography>
            </Grid>
            <Grid 
              item 
              sm={7} 
              xs={12} 
            >
              <HMStepper 
                steps={steps} 
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                disableSubmit={disableSubmit}
                disableStepOne={disableStepOne}
                disableStepTwo={disableStepTwo}
                disableFDAStep={disableFDAStep}
                isApplyingAgain={isApplyingAgain}
                disableLastStep={disableLastStep}
                resetApplicationForm={resetApplicationForm}
              />
            </Grid>
          </Grid>
        ) : <></>}
      </Section>
    </Page>
  ) : (
    <HMLoginFirst 
      message='You need to login first before applying to add your supplier'
    />
  )
}

export default SupplierApplication

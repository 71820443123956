import React from 'react'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from './HMBox'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText'
import {
    fontSize,
} from '../tools/Styles'
import PaymentMethodDetails from '../../checkout/PaymentMethodDetails'
import {
    paymentMethodDetails,
} from '../../../hooks/helperFunctions'
import {
    blue,
    lightGreen,
    seeThroughLightBlue,
} from '../../../hooks/useColors'

function HMPaymentOption(props) {

    const {
        value,
        method,
    } = props.paymentInfo
    
    const obj = {}
    paymentMethodDetails.forEach(item => {
        obj[item.type] = item
    })

    let key = ''
    let lowerText = ''
    let upperText = ''

    if (method.toLowerCase().includes('cash')) {
        key = 'COD'
        lowerText = 'Pay when you receive'
        upperText = 'Cash On Delivery'

    } else if (method.toLowerCase().includes('card')) {
        key = 'card'
        upperText = value
        lowerText = 'Credit/Debit Card'
    } else if (method.toLowerCase().includes('mtn')) {
        key = 'mtn'
        lowerText = 'MTN Mobile Money'
        upperText = value
    } else if (method.toLowerCase().includes('airtel')) {
        key = 'airtel'
        lowerText = 'Airtel Mobile Money'
        upperText = value
    }

    return (
        <PaymentMethodDetails 
            width={props.width}
            isBig={props.isBig}
            border={props.border}
            height={props.height}
            margin={props.margin}
            lowerText={lowerText}
            upperText={upperText}
            bgColor={props.bgColor || seeThroughLightBlue}
            hoverBgColor={props.hoverBgColor || lightGreen}
            handleClick={!props.isEdit ? () => props.handleSetPaymentInfo(props.paymentInfo) : () => {}}
            leftIcon={
                <HMImage
                    borderRadius={8}
                    src={obj[key].icon}
                    alt={obj[key].title}
                    margin='auto 10px auto 0'
                    width={obj[key].size.width}
                    height={obj[key].size.height}
                />
            }
            rightIcon={
                !props.isEdit ? (
                    <Radio
                        size='small'
                        value={value}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        onChange={props.handleSelectPaymentInfo}
                        checked={method === props.selectedPaymentInfo.method && value === props.selectedPaymentInfo.value}
                        className={`listing-radio-button ${(method === props.selectedPaymentInfo.method && value === props.selectedPaymentInfo.value) && 'checked'}`}
                        sx={{
                            margin: 'auto 0 auto auto',
                        }}
                    />
                ) : (
                    <HMBox
                        padding='0'
                        display='flex'
                        cursor='pointer'
                        className='fade-in'
                        margin='auto 0 auto auto'
                        handleClick={props.handleChangePaymentOption} 
                    >   
                        <HMText 
                            color={blue}
                            width='max-content'
                            margin='9px 5px auto 10px'
                            fontSize={`${fontSize - 2}px`}
                            text={props.changeText || 'Change payment method'}
                        />
                        <IconButton   
                            sx={{margin: 'auto 0 auto auto'}}
                            onClick={props.handleChangePaymentOption} 
                        >
                            <DriveFileRenameOutlineOutlinedIcon 
                                sx={{ fontSize: `${fontSize + 2}px` }}
                            />
                        </IconButton>
                    </HMBox>
                )
            }
        />
    )
}

export default HMPaymentOption
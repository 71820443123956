import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import {
    blue,
    seeThroughLightBlue
} from '../../../hooks/useColors'

function HMProgressBar(props) {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? props.color || blue  : seeThroughLightBlue,
        },
    }))

    return (
        <Box 
            sx={{ 
                flexGrow: 1,
                margin: props.margin || 'auto'
            }}
        >
            <BorderLinearProgress variant='determinate' value={props.value || 50} />
        </Box>
    )
}

export default HMProgressBar
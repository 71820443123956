import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
    useSelector,
    useDispatch
} from 'react-redux'
import { toast} from 'react-toastify'
import { 
    reset, 
    register, 
    gmailOauth 
} from '../../features/auth/authSlice'
import HMRegisterForm from '../../components/common/layout/HMRegisterForm'
import BeforeLogin from '../../components/common/layout/BeforeLogin'

function Register() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        user,
        message,
        isError,
        isLoading,
        isSuccess,
    } = useSelector(state => state.auth)

    const [formData, setFormData] = useState({
        email: '',
        lastName: '',
        password: '',
        firstName: '',
        passwordConfirmation: ''
    })

    const {
        email,
        lastName,
        password,
        firstName,
        passwordConfirmation
    } = formData
    
    useEffect(() => {
      if (isError) {
          toast.error(message)
      }
      
      // Redirect when logged in
      if (isSuccess || user) {
        navigate('/verify-otp')
      }

      dispatch(reset())
    }, [isError, isSuccess, user, message, navigate, dispatch])
    
    const onChange = (e) => {
        const id = e.target.id
        const value = e.target.value
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault() 
        if (password !== passwordConfirmation) {
            toast.error('Passwords do not match!')
        } else {
            const userData = {
                email,
                password,
                lastName,
                firstName,
            }
            dispatch(register(userData))
        }
    }

    const handleGoogleRegisterError = (response) => {
        dispatch(gmailOauth({ token: response.credential }))
    }
    const handleGoogleRegisterSuccess = (error) => {
        console.log('ERROR Login with Gmail' + error)
    }

    return (
        <BeforeLogin
            title='Register'
            isLoading={isLoading}
        >
            <HMRegisterForm 
                formData={formData}
                onChange={onChange}
                onSubmit={onSubmit}
                onError={(error) => handleGoogleRegisterError(error)}
                onSuccess={(response) => handleGoogleRegisterSuccess(response)}
            />
        </BeforeLogin>
    )
}

export default Register
import React from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { 
  DesktopDatePicker 
} from '@mui/x-date-pickers/DesktopDatePicker'
import { 
  LocalizationProvider 
} from '@mui/x-date-pickers/LocalizationProvider'
import { 
  AdapterDayjs 
} from '@mui/x-date-pickers/AdapterDayjs'

function HMDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <Stack spacing={3} className='HM-DatePicker' sx={{ margin: props.margin, width: props.width || '250px' }}>
        <DesktopDatePicker
          components={{
            OpenPickerIcon: EventNoteIcon,
          }}
          value={props.value}
          minDate={props.minDate}
          maxDate={props.maxDate}
          inputFormat='DD/MM/YYYY'
          onChange={props.onChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  )
}

export default HMDatePicker
import React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../tools/Styles'
import {
  currencies,
} from '../../../hooks/helperFunctions'
import {
  red,
  white,
  green,
  orange,
  darkBlue,
  skeletonsLightBlue,
} from '../../../hooks/useColors'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMOrderItem(props) {
    const {
        quantity,
        addedInfo,
        onlinePrice,
        productName,
        productImage,
        usedInsurance,
        insurancePrice,
    } = props.item

    return (
        <HMBox
            width='100%'
            display='flex'
            margin='0 0 5px 0'
            bgColor={skeletonsLightBlue}
        >
            <Link
                onClick={props.navegateToProductPage}
                sx={{ margin: 'auto 0', cursor: 'pointer' }}
            >
                <img 
                    alt={productName}
                    src={productImage}
                    className='small-image'
                />
            </Link>
            <HMBox
                width='100%'
                height='50px'
                display='flex'
                padding='0 5px'
                margin='auto 0'
                flexDirection='column'
            >
                <HMBox
                    padding='0'
                    display='flex'
                    margin='auto 0 2.5px 0'
                >
                    <HMText
                        color={darkBlue}
                        fontWeight={500}
                        text={productName}
                        margin='auto auto auto 0'
                        fontSize={`${fontSize - 1}px`}
                    />
                    {usedInsurance ? (
                        <>
                            <HMText
                                text='Applied Insurance'
                                margin='auto 5px auto auto'
                                fontSize={`${fontSize - 1}px`}
                            />
                            {(!props.approvedInsurance && props.validation === 'invalid') ? (
                                <CancelIcon 
                                    sx={{ 
                                        color: red,
                                        margin: 'auto 0 auto 5px', 
                                        fontSize: `${fontSize + 2}px` 
                                    }}
                                />
                            ) : (props.approvedInsurance && props.validation === 'valid') ? (
                                <CheckCircleRoundedIcon 
                                    sx={{ color: green, margin: 'auto 0 auto 5px', fontSize: `${fontSize + 2}px` }}
                                />
                            ) : (
                                <MoreHorizIcon 
                                    sx={{ color: orange, margin: 'auto 0 auto 5px', fontSize: `${fontSize + 2}px` }}
                                />
                            )}
                        </>
                    ) : <></>}
                </HMBox>
                <HMBox
                    padding='0'
                    display='flex'
                    margin='2.5px 0 2.5px 0'
                >
                    <HMText
                        margin='auto 2.5px auto 0'
                        fontSize={`${fontSize - 1}px`}
                        text={`${quantity} ${quantity > 1 ? 'items' : 'item'}`}
                    />
                    <HMText
                        text='x'
                        margin='auto 0'
                        padding='0 5px'
                        fontSize={`${fontSize - 1}px`}
                    />
                    <HMText
                        fontWeight={500}
                        margin='auto 0 auto 2.5px'
                        fontSize={`${fontSize - 1}px`}
                        text={
                            `${currency} ${
                                (props.approvedInsurance && usedInsurance) 
                                    ? insurancePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }`
                        }
                    />
                    <HMText
                        text='='
                        margin='auto 0'
                        padding='0 5px'
                        fontSize={`${fontSize - 1}px`}
                    />
                    <HMText
                        color={orange}
                        fontWeight={500}
                        margin='auto 0 auto 2.5px'
                        fontSize={`${fontSize - 1}px`}
                        text={
                            `${currency} ${
                                (props.approvedInsurance && usedInsurance) 
                                    ? (insurancePrice * quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : (onlinePrice * quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }`
                        }
                    />
                    {addedInfo && Object.keys(addedInfo).length ? (
                        <HMButton 
                            type='button'
                            color={green}
                            bgColor={white}
                            hoverColor={white}
                            padding='2.5px 10px'
                            hoverBgColor={green}
                            margin='auto 0 auto auto'
                            paddingActive='2px 9.5px'
                            icon={<RemoveRedEyeIcon sx={{ ...iconStyle, margin: 'auto 5px auto auto' }} />} 
                            handleClick={
                                addedInfo.uploadedPrescription 
                                ? props.handleViewPrescription
                                : props.handleViewAddedInfoModal
                            }
                            text={
                                <Typography 
                                    sx={{
                                        ...textStyle, 
                                        fontWeigh: 500,
                                        margin: 'auto 0',
                                        fontSize: `${fontSize - 1}px`
                                    }}
                                >
                                    {addedInfo.uploadedPrescription ? 'View prescription' : 'View additinal info'}
                                </Typography>
                            }
                        />
                    ) : <></>}
                </HMBox>
            </HMBox>
        </HMBox>
    )
}

export default HMOrderItem
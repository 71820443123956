import React from 'react'
import Typography from '@mui/material/Typography'

function Title(props) {
    const headerStyle = {
        width: props.width,
        color: props.color,
        height: props.height,
        border: props.border,
        display: props.display,
        padding: props.padding,
        overflow: props.overflow,
        float: props.float || 'left',
        margin: props.margin || 'auto',
        backgroundColor: props.bgColor,
        borderRadius: props.borderRadius,
        textTransform: props.textTransform,
        fontSize: `${props.size || '16'}px`,
        fontWeight: props.fontWeight || 'bold',
        textAlign: props.textAlign || 'center', 
    }

    return (
        <Typography component='p' sx={{...headerStyle}} className={props.className}>{props.title}</Typography>
    )
}

export default Title
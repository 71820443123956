import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Modal from '@mui/material/Modal'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    red,
    lightBlack
} from '../../../hooks/useColors'
import {
    fontSize
} from '../tools/Styles'

function HMModal(props) {

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    const x = windowW < 480 ? 50 : 50 
    const y = windowW < 480 ? 50 : 50
    const left = windowW < 480 ? 50 : 50
    const style = {
        top: '50%',
        padding: '20px',
        left: `${left}%`,
        color: lightBlack,
        position: 'absolute',
        minWidth: props.minWidth,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth || '95vw',
        transform: `translate(-${x}%, -${y}%)`,
        backgroundColor: props.bgColor || 'background.paper',
        border: props.border || '0px solid #ffffff !important',
        width: props.width || (isTabletScreen ? '95vw' : '550px'),    
    }

    const titleStyle = {
        color: 'black',
        textAlign: 'center',
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                border: '0px solid #ffffff !important'
            }}
        >
            <Paper sx={style} elevation={props.elevation || 5}>
                <Typography id='modal-modal-title' className='fade-in' variant='h6' component='h2' sx={titleStyle}>
                    {props.title}
                </Typography>
                {props.children}
                <IconButton   
                    onClick={props.handleClose} 
                    sx={{
                        top: '5px',
                        right: '5px',
                        position: 'absolute',
                    }}
                >
                    <CloseOutlinedIcon 
                        sx={{
                            margin: 'auto',
                            color: lightBlack,
                            fontSize: `${fontSize + 2}px`,
                            '&:hover': {
                                color: red,
                            }
                        }}
                    />
                </IconButton>
            </Paper>
        </Modal>
    )
}

export default HMModal
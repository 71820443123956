import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import suppliersWithListingsService from './suppliersWithListingsService'

const initialState = {
    message: '',
    isError: false,
    isLoading: false,
    supplierWithListings: {},
    suppliersWithListings: [],
    isSupplierWithListings: false,
    areSuppliersWithListings: false,
}

// Get all suppliers with supplier listings
export const getSupplierWithListings = createAsyncThunk(
    'suppliersWithListings/one',
    async (supplierId, thunkAPI) => {
        try {
            return await suppliersWithListingsService.getSupplierWithListings(supplierId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a supplier with supplier listings
export const getAllSuppliersWithListings = createAsyncThunk(
    'suppliersWithListings/all',
    async (_, thunkAPI) => {
        try {
            return await suppliersWithListingsService.getAllSuppliersWithListings()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const suppliersWithListingsSlice = createSlice({
    name: 'suppliersWithListings',
    initialState,
    reducers: {
        resetSuppliersWithListings: (state) => {
            state.message = ''
            state.isError = false
            state.isLoading = false
            state.isSupplierWithListings = false
            state.areSuppliersWithListings = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSupplierWithListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isSupplierWithListings = false
            })
            .addCase(getSupplierWithListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isSupplierWithListings = true
                state.supplierWithListings = action.payload
            })
            .addCase(getSupplierWithListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isSupplierWithListings = false
            })

            .addCase(getAllSuppliersWithListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areSuppliersWithListings = false
            })
            .addCase(getAllSuppliersWithListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areSuppliersWithListings = true
                state.suppliersWithListings = action.payload
            })
            .addCase(getAllSuppliersWithListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areSuppliersWithListings = false
            })
    }
})

export const { resetSuppliersWithListings } = suppliersWithListingsSlice.actions
export default suppliersWithListingsSlice.reducer
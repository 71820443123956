import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { 
  getStore,
  resetStore
} from '../../../features/stores/storeSlice'
import { 
  getProducts,
  resetProduct, 
} from '../../../features/products/productSlice'
import { 
  resetStoreListing, 
  createStoreListing,
} from '../../../features/storeListings/storeListingSlice'
import { 
  getStoreWithListings,
  resetStoresWithListings, 
} from '../../../features/storesWithListings/storesWithListingsSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import HMListingCreationFields from '../../../components/common/layout/HMListingCreationFields'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import Page from '../../../components/common/layout/Page'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import { removeProductsThatAlreadyHaveStoreListings } from '../../../hooks/helperFunctions'
import {
  white,
} from '../../../hooks/useColors'

function CreateStoreListing() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { 
    storeWithListings,
    isStoreWithListings,
  } = useSelector(state => state.storesWithListings)

  const storeState = useSelector(state => state.store)  
  const { 
    store
  } = storeState

  const productState = useSelector(state => state.product)
  const { 
    products
  } = productState

  const listingState = useSelector(state => state.storeListing)  
  const { 
    storeListing
  } = listingState

  const breadOptions = [
    { text: 'Stores', link: '/admin/stores' },
    { text: store.name && store.name, link: `/admin/stores/${id}` },
    { text: 'listings', link: `/admin/stores/${id}/listings` },
    { text: 'Add a listing', link: null }
  ]

  const minExpirationDate = new Date()
  minExpirationDate.setDate(minExpirationDate.getDate() + 16)

  const [stockCount, setStockCount] = useState(0)
  const [wrongStock, setWrongStock] = useState(false)
  const [areOptionsSet, setAreOptionsSet] = useState(false)
  const [availableOptions, setAvailableOptions] = useState([])
  const [formData, setFormData] = useState({
    price: '',
    product: '',
    alertLevel: '',
    onlinePrice: '',
    soldOnline: true,
    availableStock: [{
      quantity: 1,
      expiresOn: dayjs(minExpirationDate),
    }],
  })
  const {
    price,
    product,
    alertLevel,
    soldOnline,
    onlinePrice,
    availableStock,
  } = formData

  useEffect(() => {
    dispatch(getStore(id))
    dispatch(getProducts())
    dispatch(getStoreWithListings(id))
    // eslint-disable-next-line
  }, [id])
  
  useEffect(() => {
    const {
      isError,
      message,
      isCreated,
    } = listingState

    if (storeState.isOne  || storeState.isSuccess) {
      dispatch(resetStore())
    }

    if (isError) {
      dispatch(resetStoreListing())
      toast.error(message)
    } else if (isCreated) {
      dispatch(resetStoreListing())
      toast.success('Store listing created successfully!')
      navigate(`/admin/stores/${id}/listings/${storeListing._id}`)
    }

    let qty = 0
    availableStock.forEach(stock => {
      if (stock['quantity']) {
        qty += parseInt(stock['quantity'])
      }

      if (
        !stock || 
        !stock['quantity'] || 
        !stock['expiresOn'] || 
        isNaN(new Date(stock['expiresOn']).valueOf())
      ) {
        setWrongStock(true)
      } else {
        setWrongStock(false)
      }
    })
    setStockCount(qty)

    if (productState.isSuccess) {
      dispatch(resetProduct())
    }
    // eslint-disable-next-line
  }, [productState, storeState, listingState, availableStock, id, storeListing])

  useEffect(() => {
    if (isStoreWithListings && products.length && Object.keys(store).length) {
      dispatch(resetStoresWithListings())
      const {
        options,
        doneSetting,
      } = removeProductsThatAlreadyHaveStoreListings(store, products, storeWithListings)
      setAvailableOptions(options)
      setAreOptionsSet(doneSetting)
    }
    // eslint-disable-next-line
  }, [products, store, storeWithListings, isStoreWithListings])

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }))
  }
  const handleProductChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      product: val ? val : '',
    }))
  }

  const handleStocks = (index, e) => {
    const stockValues = [...availableStock]
    if (e['target']) {
      const name = e.target.name
      const value = e.target.value
      stockValues[index] = {
        ...stockValues[index],
        [name]: parseInt(value)
      }
    } else {
      stockValues[index] = {
        ...stockValues[index],
        expiresOn: e
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      availableStock: stockValues
    }))
  }

  const handleRemoveStock = (index) => {
    const stockValues = [...availableStock]
    if (availableStock.length > 1) {
      stockValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          availableStock: stockValues
      }))
    }
  }
  const handleAddStock = (index) => {
    const stockValues = [...availableStock]
    stockValues.splice(index + 1, 0, {
      quantity: 1,
      expiresOn: dayjs(minExpirationDate),
    })
    setFormData((prevState) => ({
        ...prevState,
        availableStock: stockValues
    }))
  }
  
  const disabled = (
    wrongStock === true ||
    price === '' ||
    (product === '' || (product['name'] && product['name'] === '')) 
  ) ? true : false

  const handleCreate = (e) => {
    e.preventDefault()

    const newStoreLisiting = {
      store: id,
      product: product._id,
      price: parseFloat(price),
      alertLevel: alertLevel && parseInt(alertLevel),
      onlinePrice: onlinePrice && parseFloat(onlinePrice),
      soldOnline: soldOnline === 'true' || soldOnline === true ? true : false,
      availableStock: availableStock.map(stock => ({
        ...stock,
        expiresOn: stock.expiresOn.$d
      })),
    }

    if (!newStoreLisiting.alertLevel) {
      delete newStoreLisiting['alertLevel']
    } 

    if (!newStoreLisiting.onlinePrice) {
      delete newStoreLisiting['onlinePrice']
    } 

    dispatch(createStoreListing(newStoreLisiting))
  }

  return (
    <Page>
      {listingState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer>
        <HMBreadcrumbs 
          options={breadOptions}
        />
      </FullWidthContainer>
      <Section
        padding={10}
        bgColor={white}
      >
        <HMListingCreationFields 
          formData={formData}
          disabled={disabled}
          isCreateLising={true}
          stockCount={stockCount}
          handleChange={handleChange}
          handleSubmit={handleCreate}
          areOptionsSet={areOptionsSet}
          availableOptions={availableOptions}
          handleProductChange={handleProductChange}
          handleAddStock={(index) => handleAddStock(index)}
          handleStocks={(index, e) => handleStocks(index, e)}
          handleRemoveStock={(index) => handleRemoveStock(index)}
        />
      </Section>
    </Page>
  )
}

export default CreateStoreListing
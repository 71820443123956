import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { 
  getMyStore,
} from '../../features/stores/storeSlice'
import Section from '../../components/common/layout/Section'
import Page from '../../components/common/layout/Page'
import HMBox from '../../components/common/layout/HMBox'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMSpiner from '../../components/common/tools/HMSpiner'
import {
    white
} from '../../hooks/useColors'

function Analytics() {
    const dispatch = useDispatch()
    const { storeId } = useParams()
    const { user } = useSelector((state) => state.auth)
    const storeState = useSelector((state) => state.store)
    const { myStores } = storeState

    useEffect(() => {
        if (user) {
            if (myStores.length) {
                dispatch(getMyStore(storeId))
            }
        }
    }, [dispatch, user, myStores, storeId])

    return user ? (
        <Page>
            {storeState.isLoading ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <Section 
                padding={10} 
                bgColor={white}
            >
                Analytics
            </Section>
        </Page>
    ) : (
        <Section 
          padding={10} 
          bgColor={white}
        >
          <HMLoginFirst 
            message='You need to login first before accessing store content'
          />
        </Section>
    )
}

export default Analytics
import axios from 'axios'

const API_URL_STORES = '/api/v1/stores'

// Get all stores
const getStores = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_STORES, config)
    
    const data = response.data
    
    return data
}

// Get my stores
const getMyStores = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_STORES}/mine`, config)
    
    const data = response.data
    
    return data
}

// Get all stores for public
const getPublicStores = async () => {
    const response = await axios.get(`${API_URL_STORES}/public`)
    
    const data = response.data
    
    return data
}

// Get all store names for application
const getStoresForApplication = async () => {
    const response = await axios.get(`${API_URL_STORES}/application`)
    
    const data = response.data
    
    return data
}

// Get a store
const getStore = async (storeID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_STORES}/${storeID}`, config)

    const data = response.data
    
    return data
}

// Create a new store
const createStore = async (newStore, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL_STORES, newStore, config)

    const data = response.data
    
    return data
}

// Update a store
const updateStore = async (updatedStore, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const storeId = updatedStore._id
    delete updatedStore['_id']
    const response = await axios.post(`${API_URL_STORES}/${storeId}`, updatedStore, config)

    const data = response.data

    return data
}

// Delete a store
const deleteStore = async (storeID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_STORES}/${storeID}`, config)

    const data = response.data

    return data
}

const storeService = {
    getStoresForApplication,
    getPublicStores,
    getMyStores,
    createStore,
    updateStore,
    deleteStore,
    getStores,
    getStore
}
export default storeService
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import logService from './logService'

const initialState = {
    log: {},
    logs: [],
    myLog: {},
    myLogs: [],
    userLog: {},
    userLogs: [],
    areLogs: false,
    isError: false,
    isMyLog: false,
    isUserLog: false,
    areMyLogs: false,
    isLoading: false,
    isLogAdded: false,
    areUserLogs: false,
}

// Create log
export const addLog = createAsyncThunk(
    'log/add',
    async (log, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.addLog(log, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get logs
export const getLogs = createAsyncThunk(
    'log/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.getLogs(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get all user's logs
export const getUserLogs = createAsyncThunk(
    'log/all_user_logs',
    async (userId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.getUserLogs(userId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get all my logs
export const getMyLogs = createAsyncThunk(
    'log/all_my_logs',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.getMyLogs(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get user latest log
export const getUserLatestLog = createAsyncThunk(
    'log/user_latest_log',
    async (userId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.getUserLatestLog(userId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get user latest log
export const getMyLatestLog = createAsyncThunk(
    'log/my_latest_log',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await logService.getMyLatestLog(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        resetLog: (state) => {
            state.areLogs = false
            state.isError = false
            state.isMyLog = false
            state.areMyLogs = false
            state.isUserLog = false
            state.isLoading = false
            state.isLogAdded = false
            state.areUserLogs = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLog.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isLogAdded = false
            })
            .addCase(addLog.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isLogAdded = true
                state.myLog = action.payload
            })
            .addCase(addLog.rejected, (state) => {
                state.isError = true
                state.isLoading = false
                state.isLogAdded = false
            })
        
            .addCase(getLogs.pending, (state) => {
                state.isError = false
                state.areLogs = false
                state.isLoading = true
            })
            .addCase(getLogs.fulfilled, (state, action) => {
                state.areLogs = true
                state.isError = false
                state.isLoading = false
                state.logs = action.payload
            })
            .addCase(getLogs.rejected, (state) => {
                state.isError = true
                state.areLogs = false
                state.isLoading = false
            })

            .addCase(getUserLogs.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areUserLogs = false
            })
            .addCase(getUserLogs.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areUserLogs = true
                state.userLogs = action.payload
            })
            .addCase(getUserLogs.rejected, (state) => {
                state.isError = true
                state.isLoading = false
                state.areUserLogs = false
            })

            .addCase(getMyLogs.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areMyLogs = false
            })
            .addCase(getMyLogs.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areMyLogs = true
                state.myLogs = action.payload
            })
            .addCase(getMyLogs.rejected, (state) => {
                state.isError = true
                state.isLoading = false
                state.areMyLogs = false
            })

            .addCase(getUserLatestLog.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isUserLog = false
            })
            .addCase(getUserLatestLog.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isUserLog = true
                state.userLog = action.payload
            })
            .addCase(getUserLatestLog.rejected, (state) => {
                state.isError = true
                state.isLoading = false
                state.isUserLog = false
            })

            .addCase(getMyLatestLog.pending, (state) => {
                state.isError = false
                state.isMyLog = false
                state.isLoading = true
            })
            .addCase(getMyLatestLog.fulfilled, (state, action) => {
                state.isError = false
                state.isMyLog = true
                state.isLoading = false
                state.myLog = action.payload
            })
            .addCase(getMyLatestLog.rejected, (state) => {
                state.isError = true
                state.isMyLog = false
                state.isLoading = false
            })
    }
})

export const { resetLog } = logSlice.actions
export default logSlice.reducer
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import insurerService from './insurerService'

// Get insurers from local storage
const initialState = {
    message: '',
    insurer: {},
    insurers: [],
    isOne: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
}

// Create a new insurer
export const createInsurer = createAsyncThunk(
    'insurers/create',
    async (newInsurer, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await insurerService.createInsurer(newInsurer, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an insurer
export const updateInsurer = createAsyncThunk(
    'insurers/update',
    async (insurer, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await insurerService.updateInsurer(insurer, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an insurer
export const deleteInsurer = createAsyncThunk(
    'insurers/delete',
    async (insurerID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await insurerService.deleteInsurer(insurerID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get insurers
export const getInsurers = createAsyncThunk(
    'insurers/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await insurerService.getInsurers(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get insurer
export const getInsurer = createAsyncThunk(
    'insurer/one',
    async (insurerID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await insurerService.getInsurer(insurerID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const insurerSlice = createSlice({
    name: 'insurer',
    initialState,
    reducers: {
        resetInsurer: (state) => {
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createInsurer.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createInsurer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.insurer = action.payload
            })
            .addCase(createInsurer.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateInsurer.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateInsurer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.insurer = action.payload
            })
            .addCase(updateInsurer.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteInsurer.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteInsurer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteInsurer.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getInsurer.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInsurer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isOne = true
                state.insurer = action.payload
            })
            .addCase(getInsurer.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getInsurers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInsurers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.insurers = action.payload
            })
            .addCase(getInsurers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetInsurer } = insurerSlice.actions
export default insurerSlice.reducer
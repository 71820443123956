import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Box,
  Grid,
  Typography
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AddIcon from '@mui/icons-material/Add'
import { 
  getFaq, 
  resetFaq, 
  updateFaq
} from '../../../features/faqs/faqsSlice'
import { 
  getFaqCategories,  
} from '../../../features/faqCategories/faqCategorySlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMButton from '../../../components/common/tools/HMButton'
import HMEditor from '../../../components/common/tools/HMEditor'
import Spinner from '../../../components/common/tools/Spinner'
import HMText from '../../../components/common/tools/HMText'
import { 
  textStyle, 
  iconStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  blue,
  white,
  orange,
  green,
  lightBlue,
} from '../../../hooks/useColors'

function EditFaq() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedRole, setRole] = useState('')
  const [selectedCategoryId, setCategoryId] = useState('')
  const {publiFaqCategories} = useSelector((state) => state.faqCategory)

  const faqState = useSelector((state) => state.faq)
  const { faq } = faqState
  
  const breadOptions = [
    { text: 'Faqs', link: '/admin/faqs' },
    { text: `Edit - ${faq['question'] && faq.question}`, link: null }
  ]
  const accessLevels = [
    { role: 'Stores', accessLevel: [50, 51, 55] },
    { role: 'Suppliers', accessLevel: [40, 41, 45] },
    { role: 'Customers', accessLevel: [100] },
    { role: 'Admins', accessLevel: [0, 1, 5, 20] },
  ]

  const categories = [
    { category: 'Account' },
    { category: 'Add supplier' },
    { category: 'User Store' },
    { category: 'payments' },
    { category: 'product' },
  ]

  const categoryByName = (categoryName) =>{
    const faqCategory = publiFaqCategories.filter(category => category.name === categoryName)
    return faqCategory[0]._id
   }

   const faqAccessLevel = (selectedRole) => {
    const index = accessLevels.findIndex((object) => {
      return object.role === selectedRole.role
    })
    return accessLevels[index].accessLevel
  }

 

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false
  const isMobileScreen = windowW < 480 ? true : false

  const defaultFormData = (faq, faqCategories) => {
    const checkFaq = faq && Object.keys(faq).length ? true : false
    const checkFaqCategories = faqCategories && Object.keys(faqCategories).length ? true : false

    return {
      question: checkFaq ?  faq.question : '',
      answer: checkFaq ?  faq.answer : '',
      role: checkFaq ?  faq.role : '',
      category: checkFaq ?  faq.category : '',
    }
  }
  useEffect(() => {
    dispatch(getFaqCategories())
  }, [dispatch])

  useEffect(() => {
    if (faqState.isError) {
      dispatch(resetFaq())
      toast.error(faqState.message)
    } else if (faqState.isOne) {
      dispatch(resetFaq())
    } else if (faqState.isUpdated) {
      toast.success('Faq updated successfully')
      dispatch(resetFaq())
    }

    if (!Object.keys(faq).length || (faq['_id'] && faq._id !== id)) {
      dispatch(getFaq(id))
    } 

  }, [dispatch, faqState.isOne, faqState.isError, faqState.message, faqState.isUpdated, faq, id])

  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState(defaultFormData(faq))
  
  const {
    question,
    answer,
    role,
    category
  } = formData

  const handleChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    }))
  }
  
  const handleEditorChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      answer: content
    }))
  }

  const handleAdd = () => {
    navigate('/admin/faqs/create')
  }
  const handleView = () => {
    navigate(`/admin/faqs/${faq._id}`)
  }
  const handleUpdate = (e) => {
    e.preventDefault()
    const updatedFaq = {
      _id: id,
      question: question,
      answer: answer,
      role: role,
      category: category
    }

    dispatch(updateFaq(updatedFaq))
    setOpen(false)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleCategoryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      category: val,
    }))

    setCategoryId(categoryByName(val.category))
  
  }


  const handleAccessLevelChangeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      role: val,
    }))
   setRole(faqAccessLevel(val))
  
  }

  return (
    <Page>

      {!Object.keys(faq).length ? (
        <Spinner marginTop={25} />
      ) : (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMButton 
                type='button'
                bgColor={orange}
                isResponsive={true}
                handleClick={handleView}
                icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>View</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add Product</Typography>} 
              />
            </HMBox>
          </FullWidthContainer>
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid 
              component='form'
              container spacing={2} 
              onSubmit={handleUpdate}
            > 
              <Grid item xs={12}>
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={lightBlue}
                  padding='10px'
                  margin='30px 0px'
                  flexDirection='column'
                  maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                  <HMText 
                    left='0'
                    top='-35px'
                    height='30px'
                    bgColor={white}
                    fontWeight={500}
                    width='max-content'
                    className='fade-in'
                    position='relative'
                    margin='10px 0 -25px 20px'
                    padding='5px 10px 0px 10px'
                    text='Upload product image'
                    border={`1px solid ${lightBlue}`}
                  />
                </HMBox>
              </Grid> 
              <Grid item xs={12} sm={4}>
                <HMTextField 
                  value={question}
                  name='question'
                  label='Question'
                  placeholder='Enter Faq Question'
                  required={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
            <DropdownFilter
              width="100%"
              field="category"
              label="Category"
              bgColor={white}
              value={category}
              prompt="Select category"
              mainMargin="auto"
              options={categories}
               onChange={handleCategoryChange}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ display: 'flex' }}>
            <DropdownFilter
              width="100%"
              field="role"
              label="Role"
              bgColor={white}
              value={role}
              prompt="Select role"
              mainMargin="auto"
              options={accessLevels}
              onChange={handleAccessLevelChangeChange}
            />
          </Grid>
              <Grid item xs={12}> 
                <HMEditor 
                  value={answer}
                  onBlur={handleEditorChange}
                  placeholder='Enter answer here...'
                />         
              </Grid>
              <Grid 
                item 
              >
                <HMButton 
                  width='100%'
                  type='submit'
                  margin='5px 0'
                  bgColor={blue}
                  text={<Typography sx={textStyle}>Update Faq</Typography>}
                />
              </Grid> 
              
            </Grid>
          </Section>
        </>
      )}
    </Page>
  )
}

export default EditFaq
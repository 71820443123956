import React from 'react'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

function HMToogleButton(props) {
    const style = {
        float: props.float || 'right',
        margin: `${props.margin || 'auto 15px'} !important`,
    }
    return (props.text !== undefined && props.status !== undefined) && (
        <FormControlLabel
            sx={style}
            label={props.text}
            className={`toogle-button ${props.status ? 'active' : 'inactive' }`}
            control={
                <Switch
                    name='loading'
                    color='primary'
                    checked={props.status}
                    onChange={props.handleChange}
                />
            }
        />
    )
}

export default HMToogleButton
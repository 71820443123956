import React from 'react'
import Typography from '@mui/material/Typography'
import { textStyle, fontSize } from './Styles'

function HMText(props) {
    const pStyle = {
        ...textStyle,
        top: props.top,
        left: props.left,
        width: props.width,
        color: props.color,
        cursor: props.cursor,
        border: props.border,
        margin: props.margin,
        height: props.height,
        zIndex: props.zIndex,
        padding: props.padding,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        position: props.position,
        maxHeight: props.maxHeight,
        minHeight: props.minHeight,
        textAlign: props.textAlign,
        float: props.float || 'left',
        fontWeight: props.fontWeight,
        backgroundColor: props.bgColor,
        textTransform: props.textTransform,
        textDecoration: props.textDecoration,
        fontSize: props.fontSize || `${fontSize}px`,
        borderRadius: `${props.borderRadius || '8'}px`,
        borderTopLeftRadius: props.borderTopLeftRadius,
        borderTopRightRadius: props.borderTopRightRadius,
        borderBottomLeftRadius: props.borderBottomLeftRadius,
        borderBottomRightRadius: props.borderBottomRightRadius,
        '&:hover': {
            color: props.hoverColor,
            backgroundColor: props.hoverBgColor,
        },
        '&:active': {
            color: props.activeColor,
            backgroundColor: props.activeBgColor,
        }
    }
    return (
        <Typography 
            sx={pStyle} 
            onClick={props.handleClick}
            className={props.className}
        >
            {props.text}
        </Typography>
    )
}

export default HMText
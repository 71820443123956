import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'

const initialState = {
    importData: []
}

export const loadDataToImport = createAsyncThunk(
    'import/load',
    async (data) => {
        return data
    }
)

export const importSlice = createSlice({
    name: 'import',
    initialState,
    reducers: {
        reset: (state) => {
            state.importData = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDataToImport.fulfilled, (state, action) => {
                state.importData = action.payload
            })
    }
})

export const { reset } = importSlice.actions
export default importSlice.reducer
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import relationProductsInsurersService from './relationProductsInsurersService'

const initialState = {
    isError: false,
    isLoading: false,
    insuredProducts: [],
    productInsurers: {},
    insurerProducts: {},
    insurersWithProducts: [],
    isProductInsurers: false,
    isInsurerProducts: false,
    areInsuredProducts: false,
    onlyInsurersWithProducts: [],
    areInsurersWithProducts: false,
    areOnlyInsurersWithProducts: false,
}

// Get all insured products
export const getAllInsuredProducts = createAsyncThunk(
    'relationProductsInsurers/insuredProducts',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await relationProductsInsurersService.getAllInsuredProducts(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all insurers with products
export const getAllInsurersWithProducts = createAsyncThunk(
    'relationProductsInsurers/insurersWithProducts',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await relationProductsInsurersService.getAllInsurersWithProducts(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all insurers with products - excluding products details
export const getOnlyAllInsurersWithProducts = createAsyncThunk(
    'relationProductsInsurers/insurersOnly',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await relationProductsInsurersService.getOnlyAllInsurersWithProducts(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// Get product insurers
export const getProductInsurers = createAsyncThunk(
    'relationProductsInsurers/productInsurers',
    async (productId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await relationProductsInsurersService.getProductInsurers(productId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get an insurer with products
export const getInsurerWithProducts = createAsyncThunk(
    'relationProductsInsurers/insurerProducts',
    async (insurerId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await relationProductsInsurersService.getInsurerWithProducts(insurerId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const relationProductsInsurersSlice = createSlice({
    initialState,
    name: 'relationProductsInsurers',
    reducers: {
        resetRelationProductsInsurers: (state) => {
            state.isError = false
            state.isLoading = false
            state.isProductInsurers = false
            state.isInsurerProducts = false
            state.areInsuredProducts = false
            state.areInsurersWithProducts = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllInsuredProducts.pending, (state) => {
                state.isError = true
                state.isLoading = false
                state.areInsuredProducts = false
            })
            .addCase(getAllInsuredProducts.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areInsuredProducts = true
                state.insuredProducts = action.payload
            })
            .addCase(getAllInsuredProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areInsuredProducts = false
                state.message = action.payload
            })

            .addCase(getAllInsurersWithProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areInsurersWithProducts = false
            })
            .addCase(getAllInsurersWithProducts.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areInsurersWithProducts = true
                state.insurersWithProducts = action.payload
            })
            .addCase(getAllInsurersWithProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areInsurersWithProducts = false
            })

            .addCase(getOnlyAllInsurersWithProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areOnlyInsurersWithProducts = false
            })
            .addCase(getOnlyAllInsurersWithProducts.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areOnlyInsurersWithProducts = true
                state.onlyInsurersWithProducts = action.payload
            })
            .addCase(getOnlyAllInsurersWithProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areOnlyInsurersWithProducts = false
            })

            .addCase(getProductInsurers.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isProductInsurers = false
            })
            .addCase(getProductInsurers.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isProductInsurers = true
                state.productInsurers = action.payload
            })
            .addCase(getProductInsurers.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isProductInsurers = false
            })

            .addCase(getInsurerWithProducts.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isInsurerProducts = false
            })
            .addCase(getInsurerWithProducts.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isInsurerProducts = true
                state.insurerProducts = action.payload
            })
            .addCase(getInsurerWithProducts.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isInsurerProducts = false
            })
    }
})

export const { resetRelationProductsInsurers } = relationProductsInsurersSlice.actions
export default relationProductsInsurersSlice.reducer
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
  deleteSupplier, 
  getSuppliers, 
  resetSupplier 
} from '../../../features/suppliers/supplierSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import Spinner from '../../../components/common/tools/Spinner'
import Title from '../../../components/common/tools/Title'
import {
    iconStyle,
    textStyle,
    deleteTextStyle,
    deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useColors from '../../../hooks/useColors'

const { 
    red,
    gray,
    blue, 
    green,
    lightBlue,
    lightBlack,
} = useColors

function Suppliers() {
  const {
      isError,
      message,
      suppliers,
      isDeleted,
      isSuccess,
  } = useSelector(state => state.supplier)

  const dispatch = useDispatch() 
  const navigate = useNavigate()

  useEffect(() => {
      if (isError || isDeleted || isSuccess) {
          dispatch(resetSupplier())
      }

      if (isError) {
          toast.error(message)
      } else if (isDeleted) {
          toast.success(message)
          dispatch(getSuppliers())
      }
  }, [dispatch, isSuccess, isError, message, isDeleted])

  useEffect(() => {
      dispatch(getSuppliers())
  }, [dispatch])

  const fields = []
  const columns = [
      { field: 'name', headerName: 'Name', sortable: true},
      { field: 'type', headerName: 'Type', sortable: true},
      { field: 'supplierListings', headerName: 'Listings', sortable: false},
      { field: 'onlineMarkUp', headerName: 'Online Mark Up', sortable: false},
      { field: 'supplierMarkUp', headerName: 'Supplier Mark Up', sortable: false}
  ]

  columns.forEach((column) => {
      fields.push(column.field)
  })

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [currentSupplier, setCurrentSupplier] = useState({})
  const [selectedSuppliers, setSelectedSuppliers] = useState([])

  const getCurentSupplier = (id) => {
      return suppliers.filter(supplier => supplier._id === id)[0]
  }
  const handleCreate = () => {
      navigate('/admin/suppliers/create')
  }
  const handleEdit = (id) => {
      navigate(`/admin/suppliers/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
      setCurrentSupplier(getCurentSupplier(id))

      setOpen(true)
      setIsDelete(true)
      setTitle('Delete Supplier')
  }
  const handleBulkDeleteModal = (data) => {
      setSelectedSuppliers(data)
      setOpen(true)
      setIsDelete(false)
      setTitle('Delete Suppliers')
  }
  const handleDelete = () => {
      dispatch(deleteSupplier(currentSupplier._id))
      handleClose()
  }
  const handleBulkDelete = () => {
      // TODO
      handleClose()
  }
  const handleClose = () => {
      setTitle('')
      setOpen(false)
      setIsDelete(false)
      setCurrentSupplier({})
  }

  return (
    <Page>
      <FullWidthContainer
        display='flex'
      >
        <Title 
            title='Suppliers' 
            margin='auto auto auto 0'
        />
        <HMBox 
            float='right'
            display='flex'
        >
            <HMButton 
              type='button'
              bgColor={green}
              isResponsive={true}
              handleClick={handleCreate}
              icon={<AddIcon sx={ iconStyle } />} 
              text={<Typography sx={textStyle}>Add Supplier</Typography>} 
            />
            <HMButton 
              type='button'
              bgColor={blue}
              isResponsive={true}
              icon={<UploadFileIcon sx={iconStyle} />} 
              text={<Typography sx={textStyle}>Import Suppliers</Typography>} 
            />
        </HMBox>
        <HMModal 
            open={open} 
            title={title}
            handleClose={handleClose} 
            colors={{lightBlack, lightBlue}}
        >
            <Typography sx={deleteTextStyle}>
                Are you sure your want to delete: 
            </Typography>
            {isDelete ? (
                <Typography sx={deleteItemTextStyle}>
                {currentSupplier.name} ?
                </Typography>
            ) : !selectedSuppliers.length ? (
                <Typography sx={deleteItemTextStyle}>
                No supplier selected. Select suppliers and try again!
                </Typography>
            ) : selectedSuppliers.length > 10 ? (
                <Typography sx={deleteItemTextStyle}>
                {selectedSuppliers.length} Suppliers?
                </Typography>
            ) : (
                <Box sx={{marginBottom: '20px'}}>
                {selectedSuppliers.map((supplier, index) => (
                    <Typography 
                        sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                        key={index}
                    >
                        {supplier.name}
                    </Typography>
                ))}
                </Box>
            )}
            {(selectedSuppliers.length || isDelete) && (
                <HMButton 
                type='button'
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                bgColor={red}
                handleClick={isDelete ? handleDelete : handleBulkDelete}
                float='right'
                margin='auto'
                />
            )}
            <HMButton 
                type='button'
                text={<Typography sx={textStyle}>No, Cancel!</Typography>}
                bgColor={gray}
                handleClick={handleClose}
                float={(selectedSuppliers.length || isDelete) && 'left'}
                margin='auto'
            />
        </HMModal>
      </FullWidthContainer>
      {!suppliers.length ? (<Spinner marginTop={25} />) : (
          <DataTable
              data={suppliers}
              columns={columns}
              fields={fields}
              handleEdit={handleEdit}
              handleDelete={handleDeleteModal}
              handleBulkDelete={handleBulkDeleteModal}
              title='Suppliers'
              defaultRowsNum={20}
          />
      )}
    </Page>
  )
}

export default Suppliers
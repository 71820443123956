import React from 'react'
import Link from '@mui/material/Link'
import HMBox from '../layout/HMBox'
import HMImage from './HMImage'
import instagram from '../../../assests/socials/instagram.png'
import facebook from '../../../assests/socials/facebook.png'
import linkedin from '../../../assests/socials/linkedin.png'
import youtube from '../../../assests/socials/youtube.png'
import twitter from '../../../assests/socials/twitter.png'

function HMSocials(props) {
  return (
    <HMBox
        left='0'
        width='100%'
        height='50px'
        display='flex'
        bottom={props.bottom}
        margin='5% auto 1% auto'
        position={props.position}
    >
        <Link 
            target='_blank' 
            href='https://www.facebook.com/harakameds' 
            sx={{ cursor: 'pointer', margin: 'auto' }}
        >
        <HMImage 
            width='auto'
            height='20px'
            src={facebook}
            borderRadius='4px'
        />
        </Link>
        <Link 
            target='_blank' 
            sx={{ cursor: 'pointer', margin: 'auto' }}
            href='https://www.instagram.com/harakameds/' 
        >
            <HMImage 
                width='auto'
                height='20px'
                src={instagram}
                borderRadius='4px'
            />
        </Link>
        <Link 
            target='_blank' 
            href='https://twitter.com/harakameds/' 
            sx={{ cursor: 'pointer', margin: 'auto' }}
        >
            <HMImage 
                width='auto'
                height='20px'
                src={twitter}
                borderRadius='4px'
            />
        </Link>
        <Link 
            target='_blank' 
            sx={{ cursor: 'pointer', margin: 'auto' }}
            href='https://www.linkedin.com/company/harakameds-ltd/' 
        >
            <HMImage 
                width='auto'
                height='20px'
                src={linkedin}
                borderRadius='4px'
            />
        </Link>
        <Link 
            target='_blank' 
            sx={{ cursor: 'pointer', margin: 'auto' }}
            href='https://www.youtube.com/channel/UCQgJiITN3MFEB3vXj6JYyUQ' 
        >
            <HMImage 
                width='auto'
                height='20px'
                src={youtube}
                borderRadius='4px'
            />
        </Link>
    </HMBox>
  )
}

export default HMSocials
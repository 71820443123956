import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import {
  Grid,
  Typography
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  getProduct,
  resetProduct,
  updateProduct,
  deleteProduct
} from '../../../features/products/productSlice'
import {
  getProductInsurers,
  resetRelationProductsInsurers
} from '../../../features/relationProductsInsurers/relationProductsInsurersSlice'
import {
  getProductWithStoreListings,
  resetProductsWithStoreListings
} from '../../../features/productsWithStoreListings/productsWithStoreListingsSlice'
import {
  getProductWithSupplierListings,
  resetProductsWithSupplierListings
} from '../../../features/productsWithSupplierListings/productsWithSupplierListingsSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMAccordion from '../../../components/common/layout/HMAccordion'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import ProductDetail from '../../../components/common/tools/ProductDetail'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import {
  fontSize,
  textStyle,
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { isInTitle, currencies } from '../../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  lightRed,
  darkBlue,
  lightBlue,
  lightGreen,
  lightBlack,
  lightOrange,
  lightDarkBlue,
  getRandomColor,
} from '../../../hooks/useColors'

function Product() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState({})
  const [currency, setCurrency] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [archiveState, setArchiveState] = useState(false)
  const [publishState, setPublishState] = useState(false)
  
  const productState = useSelector((state) => state.product)
  const storeRelationsState = useSelector(state => state.productsWithStoreListings)
  const {
    productWithStoreListings,
    isProductWithStoreListings
  } = storeRelationsState
  const insurerRelationsState = useSelector(state => state.relationProductsInsurers)
  const {
    productInsurers,
    isProductInsurers
  } = insurerRelationsState
  const supplierRelationsState = useSelector(state => state.productsWithSupplierListings)
  const {
    productWithSupplierListings,
    isProductWithSupplierListings
  } = supplierRelationsState

  const {
    windowW
  } = useWindowDimensions()

  const isMobileScreen = windowW < 520 ? true : false
  const isTabletScreen = windowW < 720 ? true : false

  const containerStyle = {
    borderRadius: '8px',
    padding: isMobileScreen ? '15px 5px' : '5px'
  }

  useEffect(() => {
    dispatch(getProduct(id))
    dispatch(getProductInsurers(id))
    dispatch(getProductWithStoreListings(id))
    dispatch(getProductWithSupplierListings(id))
    setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
    // eslint-disable-next-line
  }, [id, currencies])

  useEffect(() => {
    const {
      isOne,
      message,
      isError,
      isUpdated,
      isDeleted,
    } = productState

    if ((isOne || isUpdated) && Object.keys(productState.product).length) {
      setProduct(productState.product)
    }

    if (isError || isUpdated || isOne) {
      dispatch(resetProduct())
    }
  
    if (isError) {
      toast.error(message)
    } else if (isOne) {
      dispatch(resetProduct())
      setArchiveState(productState.product.archive)
      setPublishState(productState.product.published)
    } else if (isUpdated && isInTitle(title, 'unpublish', 'publish')) {
      toast.success(`Product is ${productState.product.published ? 'published' : 'unpublished'} successfully!`)
    } else if (isUpdated && isInTitle(title, 'unarchive', 'archive')) {
      toast.success(`Product is ${productState.product.archive ? 'archived' : 'unarchived'} successfully!`)
    } else if (isDeleted) {
      dispatch(resetProduct())
      toast.success(message)
      navigate(`/admin/products`)
    }
    // eslint-disable-next-line
  }, [productState, title])
  
  useEffect(() => {
    if (isProductInsurers) {
      dispatch(resetRelationProductsInsurers())
    }

    if (isProductWithStoreListings) {
      dispatch(resetProductsWithStoreListings())
    }

    if (isProductWithSupplierListings) {
      dispatch(resetProductsWithSupplierListings())
    }
    // eslint-disable-next-line
  }, [isProductInsurers, isProductWithStoreListings, isProductWithSupplierListings])

  const breadOptions = [
    { text: 'Products', link: '/admin/products' },
    { text: Object.keys(product).length && product.name, link: null }
  ]

  const handleEdit = () => {
    navigate(`/admin/products/edit/${product._id}`)
  }
  const handleAdd = () => {
    navigate('/admin/products/create')
  }

  const handlePublishModal = () => {
    setOpen(true)
    setPublishState(!publishState)
    setTitle(`${!product.published ? 'Publish' : 'Unpublish'} Product`)
  }

  const handlePublish = () => {
    const updatedProduct = {
      _id: id,
      published: !product.published,
    }
    dispatch(updateProduct(updatedProduct))
    setOpen(false)
  }

  const handleArchiveModal = () => {
    setOpen(true)
    setArchiveState(!archiveState)
    setTitle(`${!product.archive ? 'Archive' : 'Unarchive'} Product`)
  }
  const handleArchive = () => {
    const updatedProduct = {
      _id: id,
      archive: !product.archive,
    }
    dispatch(updateProduct(updatedProduct))
    setOpen(false)
  }

  const handleDeleteModal = () => {
    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Product')
  }
  const handleDelete = () => {
    dispatch(deleteProduct(id))
    handleClose() 
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setArchiveState(product.archive)
    setPublishState(product.published)
  }

  return (
    <Page>
      {productState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(product).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              {(
                Object.keys(productInsurers).length &&
                Object.keys(productWithStoreListings).length &&
                Object.keys(productWithSupplierListings).length
                ) ? (
                <HMToogleButton 
                  status={!archiveState}
                  handleChange={handleArchiveModal}
                  text={!archiveState ? 'Active' : 'Archived'}
                />
              ) : <></>}
              {(
                !Object.keys(productInsurers).length &&
                !Object.keys(productWithStoreListings).length &&
                !Object.keys(productWithSupplierListings).length
              ) && (
                <HMToogleButton 
                  status={publishState}
                  handleChange={handlePublishModal}
                  text={publishState ? 'Published' : 'Unpublished'}
                />
              )}
              <HMButton
                type='button'
                bgColor={blue}
                isResponsive={true}
                handleClick={handleEdit}
                icon={<EditIcon sx={iconStyle} />}
                text={<Typography sx={textStyle}>Edit</Typography>}
              />
              <HMButton
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />}
                text={<Typography sx={textStyle}>Add Product</Typography>}
              />
              {(
                !product.published && 
                !Object.keys(productInsurers).length &&
                !Object.keys(productWithStoreListings).length &&
                !Object.keys(productWithSupplierListings).length 
                ) ? (
                <HMButton
                  type='button'
                  bgColor={red}
                  isResponsive={true}
                  icon={<Delete sx={iconStyle} />}
                  handleClick={handleDeleteModal}
                  text={<Typography sx={textStyle}>Delete</Typography>}
                />
              ) : <></>}
            </HMBox>
          </FullWidthContainer>
          <HMModal
            open={open}
            title={title}
            handleClose={handleClose}
            colors={{ lightBlack, lightBlue }}
          >
            {isDelete ? (
              <Typography sx={deleteTextStyle}>
                Are you sure your want to delete:
              </Typography>
            ) : isInTitle(title, 'unpublish', 'publish') ? (
              <Typography sx={deleteTextStyle}>
                Are you sure your want to {product.published ? 'unpublish' : 'publish'}:
              </Typography>
            ) : isInTitle(title, 'unarchive', 'archive') ? (
              <Typography sx={deleteTextStyle}>
                Are you sure your want to {product.archive ? 'unarchive' : 'archive'}:
              </Typography>
            ) : <></>}
            <Typography sx={deleteItemTextStyle}>
              {product.name} ?
            </Typography>
            <HMButton
              float='left'
              margin='auto'
              type='button'
              bgColor={gray}
              handleClick={handleClose}
              text={<Typography sx={textStyle}>No, Cancel!</Typography>}
            />
            <HMButton
              type='button'
              margin='auto'
              float='right'
              bgColor={
                isDelete ? red 
                : isInTitle(title, 'unpublish', 'publish') && product.published ? red 
                : isInTitle(title, 'unpublish', 'publish') && !product.published ? green 
                : isInTitle(title, 'unarchive', 'archive') && product.archive ? green
                : isInTitle(title, 'unarchive', 'archive') && !product.archive && red
              }
              handleClick={
                isDelete ? handleDelete 
                : isInTitle(title, 'unpublish', 'publish') ? handlePublish
                : isInTitle(title, 'unarchive', 'archive') && handleArchive
              }
              text={
                <Typography sx={textStyle}>
                  {
                    isDelete ? 'Yes, Delete!' 
                    : isInTitle(title, 'unpublish', 'publish') ? `Yes, ${!product.published ? 'Publish' : 'Unpublish'}!`
                    : isInTitle(title, 'unarchive', 'archive') && `Yes, ${!product.archive ? 'Archive' : 'Unarchive'}!`
                  }
                </Typography>
              }
            />
          </HMModal>
          
          <Section
            padding={10}
            bgColor={white}
          >
            <Grid
              container spacing={2}
            >
              <Grid item sm={6} xs={12}>
                <HMImage
                  borderRadius='8px'
                  alt={product.name}
                  src={product.image}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Title
                  size={14}
                  width='100%'
                  textAlign='left'
                  margin='0 0 5px 0'
                  title={product.name}
                />
                <ProductDetail 
                  title='Brand'
                  text={product.brand}
                />
                <HMBox
                  padding='0'
                  width='100%'
                  display='flex'
                  flexDirection={isTabletScreen ? 'column' : 'row'}
                >
                  <ProductDetail 
                    title='Category'
                    text={product.category.id.parent.name}
                    margin={isTabletScreen ? '5px auto' : '5px 5px 5px auto'}
                  />
                  <ProductDetail 
                    title='Classification'
                    text={product.category.id.name}
                    margin={isTabletScreen ? '5px auto' : '5px auto 5px 5px'}
                  />
                </HMBox>
                <HMBox
                  padding='0'
                  width='100%'
                  display='flex'
                  flexDirection={isTabletScreen ? 'column' : 'row'}
                >
                  <ProductDetail 
                    title='Strength'
                    text={product.strength}
                    margin={isTabletScreen ? '5px auto' : '5px 5px 5px auto'}
                  />
                  <ProductDetail 
                    title='Dosage'
                    text={product.dosage}
                    margin={isTabletScreen ? '5px auto' : '5px 5px 5px auto'}
                  />
                </HMBox>
                <ProductDetail 
                  title='Side Effects'
                  text={product.sideEffects}
                />
                <ProductDetail 
                  title='Description'
                  text={product.description}
                />
              </Grid>
            </Grid>
          </Section>

          <Section
            padding={10}
            bgColor={white}
          >
            <Grid
              container spacing={2}
            >
              <Grid item sm={6} xs={12}>
                {product.needsPrescription ? (
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightGreen}
                  >
                    <HMText
                      margin='auto'
                      fontWeight={500}
                      text='Prescription is required'
                    />
                  </HMBox>
                ) : (
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightRed}
                  >
                    <HMText
                      color={red}
                      margin='auto'
                      fontWeight={500}
                      text='No prescription required'
                    />
                  </HMBox>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                {product.hasTax ? (
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightGreen}
                  >
                    <HMText
                      margin='auto'
                      fontWeight={500}
                      text='Tax is applicable'
                    />
                  </HMBox>
                ) : (
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightOrange}
                  >
                    <HMText
                      margin='auto'
                      color={orange}
                      fontWeight={500}
                      text='Tax is not applicable'
                    />
                  </HMBox>
                )}
              </Grid>
            </Grid>
          </Section>

          {/* INSURES */}
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid>
              {!Object.keys(productInsurers).length && !insurerRelationsState.isLoading ? (
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={lightRed}
                >
                  <HMText
                    margin='auto'
                    text='This product has no assigned insurers'
                  />
                </HMBox>
              ) : Object.keys(productInsurers).length ? (
                <HMAccordion
                  title='Insurers'
                  price={product.insurancePrice}
                >
                  {productInsurers.insurers.map((insurer, index) => (
                    <Grid
                      key={index}
                      container spacing={0}
                      sx={{
                        ...containerStyle,
                        backgroundColor: index % 2 === 0 ? lightBlue : white
                      }}
                    >
                      <Grid item sm={3} xs={12}>
                        <HMBox
                          width='100%'
                          height='100%'
                          display='flex'
                        >
                          {insurer['logo'] ? (
                            <Link to={`/admin/insurers/${insurer._id}`} style={{ color: darkBlue, margin: 'auto 0' }}>
                              <img
                                alt={insurer.name}
                                src={insurer.logo}
                                className='mini-image'
                                style={{ margin: 'auto' }}
                              />
                            </Link>
                          ) : (
                            <Link
                              to={`/admin/insurers/${insurer._id}`}
                              style={{ color: darkBlue, margin: 'auto 0' }}
                            >
                              <Grid className='mini-logo'>
                                <HMText
                                  float='none'
                                  width='100%'
                                  margin='auto'
                                  height='100%'
                                  color={white}
                                  fontSize='22px'
                                  fontWeight={700}
                                  textAlign='center'
                                  padding='0 10px 0 8px'
                                  bgColor={getRandomColor()}
                                  text={insurer.name.substr(0, 1).toUpperCase()}
                                />
                              </Grid>
                            </Link>
                          )}
                          <HMBox
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            margin='auto auto auto 5px'
                          >
                            <Link
                              to={`/admin/insurers/${insurer._id}`}
                              style={{ color: darkBlue, margin: 'auto 0' }}
                            >
                              <Title
                                width='100%'
                                margin='auto'
                                textAlign='left'
                                size={fontSize - 2}
                                title={insurer.name}
                              />
                            </Link>
                            <HMBox 
                              margin='0'
                              padding='0'
                              width='100%'
                              height='auto'
                              display='flex'
                              flexDirection='row'
                            >
                              <HMText 
                                margin='auto 0'
                                borderRadius={5}
                                fontWeight={500}
                                text={insurer.type}
                                fontSize={`${fontSize - 2}px`}
                                color={insurer.type.toLowerCase() === 'private' ? orange : blue}
                              />
                              &nbsp; | &nbsp;
                              <HMText 
                                borderRadius={5}
                                fontWeight={500}
                                text={insurer.origin}
                                margin='auto 0 auto 5px'
                                fontSize={`${fontSize - 2}px`}
                                color={insurer.origin.toLowerCase() === 'international' ? orange : blue}
                              />
                            </HMBox>
                          </HMBox>
                        </HMBox>
                      </Grid>
                      <Grid item sm={6} xs={12} sx={{
                        minHeight: '60px',
                        borderLeft: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                        borderRight: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                      }} >
                        <HMBox
                          width='100%'
                          display='flex'
                          height='100%'
                        >
                          <div style={{
                            margin: 'auto auto auto 0',
                            fontSize: `${fontSize - 1}px`
                          }}>
                            {parse(insurer.description)}
                          </div>
                        </HMBox>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <HMBox
                          width='100%'
                          padding='0 0 0 10px'
                        >
                          <Title
                            width='100%'
                            textAlign='left'
                            size={fontSize - 2}
                            title='Requirements'
                            margin={isTabletScreen ? '5px 0 10px 0' : '0'}
                          />
                          <HMBox 
                            padding='0'
                            width='100%'
                            display='flex'
                            bgColor='inherit'
                            flexDirection='column'
                          >
                            <HMBox 
                              width='100%'
                              height='auto'
                              margin='1px 0'
                              display='flex'
                              padding='0 0 0 4px'
                              flexDirection='row'
                            >
                              <HMText 
                                margin='auto 0'
                                text='Fingerprint'
                                fontSize={`${fontSize - 2}px`}
                              />
                              <HMText 
                                borderRadius={5}
                                padding='1px 5px'
                                margin='auto 0 auto auto'
                                fontSize={`${fontSize - 2}px`}
                                text={insurer.needsFingerPrints ? 'Yes' : 'No'}
                                color={!insurer.needsFingerPrints ? red : green}
                                bgColor={!insurer.needsFingerPrints ? lightRed : lightGreen}
                              />
                            </HMBox>
                            <HMBox 
                              width='100%'
                              height='auto'
                              margin='1px 0'
                              display='flex'
                              padding='0 0 0 4px'
                              flexDirection='row'
                              borderTop={`1px solid ${index % 2 === 0 ? white : lightBlue}`}
                              borderBottom={`1px solid ${index % 2 === 0 ? white : lightBlue}`}
                            >
                              <HMText 
                                margin='auto 0'
                                text='Insurace Card'
                                fontSize={`${fontSize - 2}px`}
                              />
                              <HMText 
                                text='Yes'
                                color={green}
                                padding='1px 5px'
                                borderRadius={5}
                                bgColor={lightGreen}
                                margin='auto 0 auto auto'
                                fontSize={`${fontSize - 2}px`}
                              />
                            </HMBox>
                            <HMBox 
                              width='100%'
                              height='auto'
                              margin='1px 0'
                              display='flex' 
                              padding='0 0 0 4px'
                              flexDirection='row'
                            >
                              <HMText 
                                margin='auto 0'
                                text='Prescription'
                                fontSize={`${fontSize - 2}px`}
                              />
                              <HMText 
                                text='Yes'
                                color={green}
                                padding='1px 5px'
                                borderRadius={5}
                                bgColor={lightGreen}
                                margin='auto 0 auto auto'
                                fontSize={`${fontSize - 2}px`}
                              />
                            </HMBox>
                          </HMBox>
                        </HMBox>
                      </Grid>
                    </Grid>
                  ))}
                </HMAccordion>
              ) : <></>}
            </Grid>
          </Section>

          {/* STORE LISTINGS */}
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid>
              {(!Object.keys(productWithStoreListings).length && !storeRelationsState.isLoading) ? (
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={lightBlue}
                >
                  <HMText
                    margin='auto'
                    text='This product has no listings from any store'
                  />
                </HMBox>
              ) : Object.keys(productWithStoreListings).length ? (
                <HMAccordion
                  title='Store Listings'
                  listings={productWithStoreListings.storesAndListings.length}
                >
                  {productWithStoreListings.storesAndListings.map((data, index) => (
                    <Grid
                      key={index}
                      container spacing={0}
                      sx={{
                        ...containerStyle,
                        backgroundColor: index % 2 === 0 ? lightBlue : white
                      }}
                    >
                      <Grid item sm={4} xs={12}>
                        <HMBox
                          width='100%'
                          height='100%'
                          display='flex'
                        >
                          {data.store.logo ? (
                            <Link to={`/admin/stores/${data.store._id}/listings/${data.storeListing._id}`} style={{ color: darkBlue, margin: 'auto 0' }}>
                              <img
                                alt={data.store.name}
                                src={data.store.logo}
                                className='mini-image'
                                style={{ margin: 'auto' }}
                              />
                            </Link>
                          ) : (
                            <Link
                              to={`/admin/stores/${data.store._id}/listings/${data.storeListing._id}`}
                              style={{ color: darkBlue, margin: 'auto 0' }}
                            >
                              <Grid className='mini-logo'>
                                <HMText
                                  float='none'
                                  width='100%'
                                  margin='auto'
                                  height='100%'
                                  color={white}
                                  fontSize='22px'
                                  fontWeight={700}
                                  textAlign='center'
                                  padding='0 10px 0 8px'
                                  bgColor={getRandomColor()}
                                  text={data.store.name.substr(0, 1).toUpperCase()}
                                />
                              </Grid>
                            </Link>
                          )}
                          <HMBox
                            width='100%'
                            height='100%'
                            margin='auto auto auto 5px'
                          >
                            <Link to={`/admin/stores/${data.store._id}/${data._id}`} style={{ color: darkBlue }}>
                              <Title
                                width='100%'
                                textAlign='left'
                                margin='2px 0 0 0'
                                size={fontSize - 2}
                                title={data.store.name}
                              />
                            </Link>
                            <HMText
                              margin='auto'
                              padding='0 3px'
                              borderRadius={5}
                              text={data.store.type}
                              fontSize={`${fontSize - 1}px`}
                              bgColor={index % 2 !== 0 ? lightBlue : white}
                            />
                          </HMBox>
                        </HMBox>
                      </Grid>
                      <Grid item sm={4} xs={12} sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderLeft: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                          borderRight: `2px solid ${index % 2 !== 0 ? lightBlue : white}`
                        }}
                      >
                        {data.storeListing.soldOnline && (
                          <HMBox
                            width='100%'
                            margin='auto'
                            display='flex'
                          >
                            <HMText
                              text='Online price :'
                              margin='auto 3px auto 3px'
                              fontSize={`${fontSize - 1}px`}
                            />
                            <HMText
                              float='left'
                              padding='0 3px'
                              borderRadius={5}
                              fontWeight='500'
                              color={darkBlue}
                              bgColor={lightDarkBlue}
                              margin='auto auto auto 3px'
                              fontSize={`${fontSize - 1}px`}
                              text={`${currency} ${data.storeListing.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            />
                          </HMBox>
                        )}
                        <HMBox
                          width='100%'
                          margin='auto'
                          display='flex'
                        >
                          <HMText
                            text='In store price :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            padding='0 3px'
                            color={orange}
                            borderRadius={5}
                            fontWeight='500'
                            bgColor={lightOrange}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            text={`${currency} ${data.storeListing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          />
                        </HMBox>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='Orders to date :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          {/* TO DO */}
                          {/* Improve the orders functionalities */}
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={3}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            text={data.storeListing.orders.length}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                          />
                        </HMBox>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='Stock :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={3}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                            text={data.storeListing.stockCount - data.storeListing.transitStockCount}
                          />
                        </HMBox>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='SKU :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={5}
                            margin='auto auto auto 3px'
                            text={data.storeListing.sku}
                            fontSize={`${fontSize - 1}px`}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                          />
                        </HMBox>
                      </Grid>
                    </Grid>
                  ))}
                </HMAccordion>
              ) : <></>}
            </Grid>
          </Section>

          {/* SUPPLIER LISTINGS  */}
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid>
              {(!Object.keys(productWithSupplierListings).length && !supplierRelationsState.isLoading) ? (
                <HMBox
                  width='100%'
                  display='flex'
                  bgColor={lightBlue}
                >
                  <HMText
                    margin='auto'
                    text='This product has no listings from any supplier'
                  />
                </HMBox>
              ) : Object.keys(productWithSupplierListings).length ? (
                <HMAccordion
                  title='Supplier Listings'
                  listings={productWithSupplierListings.suppliersAndListings.length}
                >
                  {productWithSupplierListings.suppliersAndListings.map((data, index) => (
                    <Grid
                      key={index}
                      container spacing={0}
                      sx={{
                        ...containerStyle,
                        backgroundColor: index % 2 === 0 ? lightBlue : white
                      }}
                    >
                      <Grid item sm={4} xs={12}>
                        <HMBox
                          width='100%'
                          height='100%'
                          display='flex'
                        >
                          {data.supplier.logo ? (
                            <Link to={`/admin/suppliers/${data.supplier._id}/listings/${data.supplierListing._id}`} style={{ color: darkBlue, margin: 'auto 0' }}>
                              <img
                                className='mini-image'
                                style={{ margin: 'auto' }}
                                alt={data.supplier.name}
                                src={data.supplier.logo}
                              />
                            </Link>
                          ) : (
                            <Link to={`/admin/suppliers/${data.supplier._id}/listings/${data.supplierListing._id}`} style={{ color: darkBlue, margin: 'auto 0' }}>
                              <Grid className='mini-logo'>
                                <HMText
                                  float='none'
                                  width='100%'
                                  height='100%'
                                  margin='auto'
                                  color={white}
                                  fontSize='22px'
                                  fontWeight={700}
                                  textAlign='center'
                                  padding='0 10px 0 8px'
                                  bgColor={getRandomColor()}
                                  text={data.supplier.name.substr(0, 1).toUpperCase()}
                                />
                              </Grid>
                            </Link>
                          )}
                          <Link
                            style={{ color: darkBlue, margin: 'auto auto auto 10px' }}
                            to={`/admin/supplier/${data.supplier._id}/${data.supplierListing._id}`}
                          >
                            <Title
                              width='100%'
                              textAlign='left'
                              margin='auto 10px'
                              size={fontSize - 2}
                              title={data.supplier.name}
                            />
                          </Link>
                        </HMBox>
                      </Grid>
                      <Grid item sm={4} xs={12} sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderLeft: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                          borderRight: `2px solid ${index % 2 !== 0 ? lightBlue : white}`
                        }}
                      >
                        {data.supplierListing.soldOnline && (
                          <HMBox
                            width='100%'
                            margin='auto'
                            display='flex'
                          >
                            <HMText
                              text='Online price :'
                              margin='auto 3px auto 3px'
                              fontSize={`${fontSize - 1}px`}
                            />
                            <HMText
                              float='left'
                              padding='0 3px'
                              borderRadius={5}
                              fontWeight='500'
                              color={darkBlue}
                              bgColor={lightDarkBlue}
                              margin='auto auto auto 3px'
                              fontSize={`${fontSize - 1}px`}
                              text={`${currency} ${data.supplierListing.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            />
                          </HMBox>
                        )}
                        <HMBox
                          width='100%'
                          margin='auto'
                          display='flex'
                        >
                          <HMText
                            text='In stock price :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            color={orange}
                            padding='0 3px'
                            borderRadius={5}
                            fontWeight='500'
                            bgColor={lightOrange}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            text={`${currency} ${data.supplierListing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          />
                        </HMBox>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='Orders to date :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          {/* TO DO */}
                          {/* Improve the orders functionalities */}
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={3}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            text={data.supplierListing.orders.length}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                          />
                        </HMBox>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='Stock :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={3}
                            margin='auto auto auto 3px'
                            fontSize={`${fontSize - 1}px`}
                            text={data.supplierListing.stockCount}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                          />
                        </HMBox>
                        <HMBox
                          width='100%'
                          height='18px'
                          display='flex'
                        >
                          <HMText
                            text='SKU :'
                            margin='auto 3px auto 3px'
                            fontSize={`${fontSize - 1}px`}
                          />
                          <HMText
                            float='left'
                            padding='0 4px'
                            borderRadius={5}
                            margin='auto auto auto 3px'
                            text={data.supplierListing.sku}
                            fontSize={`${fontSize - 1}px`}
                            bgColor={index % 2 !== 0 ? lightBlue : white}
                          />
                        </HMBox>
                      </Grid>
                    </Grid>
                  ))}
                </HMAccordion>
              ) : <></>}
            </Grid>
          </Section>
        </>
      ) : <></>}
    </Page>
  )
}

export default Product
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import storeService from './storeService'

const initialState = {
    store: {},
    stores: [],
    message: '',
    myStore: {},
    isOne: false,
    myStores: [],
    storeSales: {},
    isError: false,
    otherStores: [],
    publicStores: [],
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isMyStore: false,
    isDeleted: false,
    areMyStores: false,
    favoriteStores: [],
    isPublicStores: false,
    storesForApplication: [],
    isApplicationStores: false,
}

// Create a new store
export const createStore = createAsyncThunk(
    'stores/create',
    async (newStore, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.createStore(newStore, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an store
export const updateStore = createAsyncThunk(
    'stores/update',
    async (store, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.updateStore(store, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete an store
export const deleteStore = createAsyncThunk(
    'stores/delete',
    async (storeID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.deleteStore(storeID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get stores
export const getStores = createAsyncThunk(
    'stores/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.getStores(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my stores
export const getMyStores = createAsyncThunk(
    'stores/myStores',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.getMyStores(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get my store
export const getMyStore = createAsyncThunk(
    'stores/myStore',
    async (storeId, thunkAPI) => {
        try {
            const myStores = thunkAPI.getState().store.myStores
            return myStores.find(store => store._id === storeId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get stores for public
export const getPublicStores = createAsyncThunk(
    'stores/publicAll',
    async (_, thunkAPI) => {
        try {
            return await storeService.getPublicStores()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get store names for application
export const getStoresForApplication = createAsyncThunk(
    'stores/applicationStores',
    async (_, thunkAPI) => {
        try {
            return await storeService.getStoresForApplication()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get store
export const getStore = createAsyncThunk(
    'store/one',
    async (storeID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storeService.getStore(storeID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Set other stores
export const setOtherStores = createAsyncThunk(
    'store/otherStores',
    async (stores) => {
        return stores
    }
    
)

// Set favorite stores
export const setFavoriteStores = createAsyncThunk(
    'store/favoriteStores',
    async (stores) => {
        return stores
    }
)

// Set favorite stores
export const setStoreSales = createAsyncThunk(
    'store/storeSales',
    async (storeSales) => {
        return storeSales
    }
)

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        resetStore: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isMyStore = false
            state.areMyStores = false
            state.isPublicStores = false
            state.isApplicationStores = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createStore.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.store = action.payload
            })
            .addCase(createStore.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(updateStore.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.store = action.payload
            })
            .addCase(updateStore.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(deleteStore.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteStore.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getStore.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isOne = true
                state.store = action.payload
            })
            .addCase(getStore.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getStores.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getStores.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.stores = action.payload
            })
            .addCase(getStores.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getMyStores.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areMyStores = false
            })
            .addCase(getMyStores.fulfilled, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areMyStores = true
                state.myStores = action.payload
            })
            .addCase(getMyStores.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.areMyStores = false
                state.message = action.payload
            })

            .addCase(getMyStore.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isMyStore = false
            })
            .addCase(getMyStore.fulfilled, (state, action) => {
                state.isError = true
                state.isMyStore = true
                state.isLoading = false
                state.myStore = action.payload
            })
            .addCase(getMyStore.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isMyStore = false
                state.message = action.payload
            })

            .addCase(getPublicStores.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isPublicStores = false
            })
            .addCase(getPublicStores.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isPublicStores = true
                state.publicStores = action.payload
            })
            .addCase(getPublicStores.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isPublicStores = false
                state.message = action.payload
            })

            .addCase(getStoresForApplication.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isApplicationStores = false
            })
            .addCase(getStoresForApplication.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isApplicationStores = true
                state.storesForApplication = action.payload
            })
            .addCase(getStoresForApplication.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isApplicationStores = false
                state.message = action.payload
            })

            .addCase(setOtherStores.fulfilled, (state, action) => {
                state.otherStores = action.payload
            })
            .addCase(setFavoriteStores.fulfilled, (state, action) => {
                state.favoriteStores = action.payload
            })
            .addCase(setStoreSales.fulfilled, (state, action) => {
                state.storeSales = action.payload
            })
    }
})

export const { resetStore } = storeSlice.actions
export default storeSlice.reducer
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import faqCategoryService from './faqCategoryService'

// Get categories from local storage
const publiFaqCategories = JSON.parse(localStorage.getItem('publiFaqCategories')) || []

const initialState = {
    message: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isBulkDeleted: false,
    isPublicSuccess: false,
    publiFaqCategories: publiFaqCategories.length ? publiFaqCategories : [],
}

// Create a new faq category
export const createFaqCategory = createAsyncThunk(
    'faqcategories/create',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqCategoryService.createFaqCategory(data, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update a faq category
export const updateFaqCategory = createAsyncThunk(
    'faqcategories/update',
    async (faqcategory, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqCategoryService.updateFaqCategory(faqcategory, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele a faq category
export const deleteFaqCategory = createAsyncThunk(
    'faqcategories/delete',
    async (faqCategoryID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqCategoryService.deleteFaqCategory(faqCategoryID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get faq one categories
export const getFaqOneCategories = createAsyncThunk(
    'faqCategories/one',
    async (catId, thunkAPI) => {
        try {
            return await faqCategoryService.getFaqOneCategories(catId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get faq categories
export const getFaqCategories = createAsyncThunk(
    'faqCategories/all',
    async (_, thunkAPI) => {
        try {
            return await faqCategoryService.getFaqCategories()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const faqCategorySlice = createSlice({
    name: 'faqCategory',
    initialState,
    reducers: {
        resetFaqCategory: (state) => {
            state.message = ''
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isBulkDeleted = false
            state.isPublicSuccess= false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createFaqCategory.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createFaqCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(createFaqCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateFaqCategory.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateFaqCategory.fulfilled, (state) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
            })
            .addCase(updateFaqCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteFaqCategory.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteFaqCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteFaqCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getFaqOneCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaqOneCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isPublicSuccess = true
                state.publiFaqCategories = action.payload
            })
            .addCase(getFaqOneCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getFaqCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaqCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isPublicSuccess = true
                state.publiFaqCategories = action.payload
            })
            .addCase(getFaqCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetFaqCategory } = faqCategorySlice.actions
export default faqCategorySlice.reducer
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMProductCard from '../../components/common/layout/HMProductCard'
import Section from '../../components/common/layout/Section'
import Page from '../../components/common/layout/Page'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  getMatchingCategory
} from '../../hooks/helperFunctions'
import {
  fontSize
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  white,
  darkBlue,
} from '../../hooks/useColors'

function PublicCategory() {
  const { parent, categoryId } = useParams()
  const navigate = useNavigate()
  const {
    visible
  } = useSelector((state) => state.nav)
  const { 
    publicCategories,
  } = useSelector((state) => state.category)
  const { 
    publicProducts,
  } = useSelector((state) => state.productsWithStoreListings)
    
  const {
      windowW
  } = useWindowDimensions()

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false
  
  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: publicCategories.length && `${getMatchingCategory(categoryId, publicCategories).parent.name}`, 
      link: publicCategories.length && `/categories/${getMatchingCategory(categoryId, publicCategories).parent.id}` 
    },
    { text: publicCategories.length && `${getMatchingCategory(categoryId, publicCategories).name}`, 
      link: null 
    },
  ]

  const handleProductClick = (product) => {
    navigate(`/categories/${product.category.id.parent._id}/${product.category.id._id}/${product._id}`)
  }

  return (
    <Page>
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
      </FullWidthContainer>
      <Section
        padding={10}
        bgColor={white}
      >
        <Title 
          width='100%'
          size={fontSize}
          color={darkBlue}
          margin='20px auto'
          title={publicCategories.length && getMatchingCategory(categoryId, publicCategories).name}
        />
        {publicProducts.length ? (
          <Grid container spacing={2} sx={{marginBottom: '20px'}}>
            {publicProducts.filter(data => data.product.category.id._id === categoryId).map((data, index) => (
              <Grid 
                item 
                xs={6} 
                key={index}
                sx={{display: 'flex'}}
                sm={
                    isSmallTabletScreen ? 4 
                    : isTabletScreen ? 3  
                    : (isSamllComputerScreen && visible) ? 4
                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                    : 1
                }
              >
                <HMProductCard 
                  className='fade-in'
                  isStockAvailable={true} 
                  title={data.product.name}
                  image={data.product.image}
                  handleClick={() => handleProductClick(data.product)}
                  price={data.storesAndListings[0].storeListing.onlinePrice}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <HMText 
            width='100%'
            text='loading...'
          />
        )}
      </Section>
    </Page>
  )
}

export default PublicCategory
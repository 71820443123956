import React from 'react'
import Paper from '@mui/material/Paper'

function HMAppBar(props) {

    const appBarStyle = {
        width: props.width,
        zIndex: props.zIndex,
        display: props.display,
        height: props.height || 40,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        padding: props.padding || '5px',
        display: props.display || 'flex',
        flexWrap: props.flexWrap || 'wrap',
        marginTop: props.marginTop || '15px',
        marginBottom: props.marginBottom || '15px',
    }

    return (
        <Paper
            sx={appBarStyle}
            component='header' 
            elevation={props.elevation || 0} 
            className={`HM-appbar ${props.className}`} 
        >
            {props.children}
        </Paper>
    )
}

export default HMAppBar
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
  getMyStore,
} from '../../../features/stores/storeSlice'
import { 
  resetStoreListing,
  deleteStoreListing,
} from '../../../features/storeListings/storeListingSlice'
import { 
  getStoreWithListings,
  resetStoresWithListings,
} from '../../../features/storesWithListings/storesWithListingsSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import {
  red,
  blue,
  gray,
  white,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'
import { getReadableListings } from '../../../hooks/helperFunctions'

function Listings() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const {
      isLoading,
      storeWithListings,
      isStoreWithListings,
  } = useSelector((state) => state.storesWithListings)
  const { myStores } = useSelector((state) => state.store)
  const storeListingState = useSelector((state) => state.storeListing)

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [areListingsSet, setAreListingsSet] = useState(false)
  const [currentStoreListing, setCurrentStoreListing] = useState({})
  const [selectedStoreListings, setSelectedStoreListings] = useState([])
  const [readableStoreListings, setReadableStoreListings] = useState([])

  useEffect(() => {
    if (user) {
      dispatch(getStoreWithListings(storeId))
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }
    }
    // eslint-disable-next-line
  }, [user, storeId, myStores])

  useEffect(() => {
    if (isStoreWithListings) {
      dispatch(resetStoresWithListings())
      const {
        doneSetting,
        readableStoreListings,
      } = getReadableListings(storeWithListings)
      setAreListingsSet(doneSetting)
      setReadableStoreListings(readableStoreListings)
    }

    if (storeListingState.isError) {
      dispatch(resetStoreListing())
    } else if (storeListingState.isDeleted) {
      dispatch(resetStoreListing())
    } else if (storeListingState.isError) {
      dispatch(resetStoreListing())
    }
    // eslint-disable-next-line
  }, [storeListingState, isStoreWithListings, storeWithListings])

  const fields = []
  const columns = [
    { field: 'image', headerName: 'Image', sortable: false},
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'prodCategory', headerName: 'Category', sortable: true},
    { field: 'prodParentCategory', headerName: 'Class', sortable: true},
    { field: 'price', headerName: 'Price', sortable: false},
    { field: 'soldOnline', headerName: 'Sold Online', sortable: false},
    { field: 'onlinePrice', headerName: 'Online Price', sortable: false},
    { field: 'stockCount', headerName: 'Stock', sortable: true},
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const getCurentStoreListing = (id) => {
    if (readableStoreListings.length) {
      return readableStoreListings.find(listing => listing._id === id)
    }
  }

  const handleCreate = () => {
    navigate(`/store/${storeId}/listings/create`)
  }
  const handleEdit = (id) => {
    navigate(`/store/${storeId}/listings/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
    setOpen(true)
    setIsDelete(true)
    setTitle('Delete Listing')
    setCurrentStoreListing(getCurentStoreListing(id))
  }
  const handleBulkDeleteModal = (data) => {
    setOpen(true)
    setIsDelete(false)
    setTitle('Delete Listings')
    setSelectedStoreListings(data)
  }
  const handleDelete = () => {
    dispatch(deleteStoreListing(currentStoreListing._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    // TODO
    handleClose()
  }
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setCurrentStoreListing({})
  }

  return user ? (
    <Page>
      {(storeListingState.isLoading || isLoading) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
        className='fade-in'
      >
        <Title 
          title='Products' 
          margin='auto auto auto 0'
        />
        <HMBox 
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreate}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Product</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={blue}
            isResponsive={true}
            icon={<UploadFileIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Import Products</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        <Typography sx={deleteTextStyle}>
          Are you sure your want to delete the {selectedStoreListings.length ? 'listings' : 'listing'} for: 
        </Typography>
        {isDelete ? (
          <Typography sx={deleteItemTextStyle}>
            {currentStoreListing.name} ?
          </Typography>
        ) : !selectedStoreListings.length ? (
          <Typography sx={deleteItemTextStyle}>
            No listing selected. Select listings and try again!
          </Typography>
        ) : selectedStoreListings.length > 10 ? (
          <Typography sx={deleteItemTextStyle}>
            {selectedStoreListings.length} listings?
          </Typography>
        ) : (
          <Box sx={{marginBottom: '20px'}}>
            {selectedStoreListings.map((listing, index) => (
              <Typography 
                sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                key={index}
              >
                {listing.name}
              </Typography>
            ))}
          </Box>
        )}
        {(selectedStoreListings.length || isDelete) && (
          <HMButton 
            type='button'
            float='right'
            margin='auto'
            bgColor={red}
            handleClick={isDelete ? handleDelete : handleBulkDelete}
            text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
          />
        )}
        <HMButton 
          type='button'
          margin='auto'
          bgColor={gray}
          handleClick={handleClose}
          float={(selectedStoreListings.length || isDelete) && 'left'}
          text={<Typography sx={textStyle}>No, Cancel!</Typography>}
        />
      </HMModal>
      {areListingsSet ?  (
        <DataTable
          fields={fields}
          columns={columns}
          defaultRowsNum={20}
          title='Store Listings'
          handleEdit={handleEdit}
          data={readableStoreListings}
          handleDelete={handleDeleteModal}
          emptyDataMessage='No Listings available!'
          handleBulkDelete={handleBulkDeleteModal}
        />
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default Listings
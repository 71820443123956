import axios from 'axios'

const API_URL_FAQS = '/api/v1/faqs'
const API_URL_FAQS_PRIVATE = '/api/v1/faqs/private'
const API_URL_FAQS_ROLE = '/api/v1/faqs/customer'
// Get all faqs
const getFaqs = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_FAQS, config)
    
    const data = response.data

    return data
}
// Get all faqs
const getFaqsByRole = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_FAQS_ROLE , config)
    
    const data = response.data

    return data
}

// Get a faq
const getFaq = async (faqID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_FAQS}/${faqID}`, config)

    const data = response.data
 
    return data
}


// Create a new Faq
const createFaq = async (newFaq, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL_FAQS, newFaq, config)

    const data = response.data
 
    return data
}

// Update a faq
const updateFaq = async (updateFaq, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_FAQS_PRIVATE}/${updateFaq._id}`, updateFaq, config)

    const data = response.data
    
    return data
}

// Delete a faq
const deleteFaq = async (faqID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_FAQS_PRIVATE}/${faqID}`, config)

    const data = response.data

    return data
}


const faqService = {
    createFaq,
    getFaq,
    getFaqs,
    updateFaq,
    deleteFaq,
    getFaqsByRole,
}
export default faqService
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import userService from './userService'

// Get users from local storage
const users = JSON.parse(localStorage.getItem('users')) || []

const initialState = {
    user: {},
    message: '',
    users: users,
    isOne: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isOneByEmail: false,
}

// Create a new user
export const createUser = createAsyncThunk(
    'users/create',
    async (newUser, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await userService.createUser(newUser, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an user
export const updateUser = createAsyncThunk(
    'users/update',
    async (user, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await userService.updateUser(user, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an user
export const deleteUser = createAsyncThunk(
    'users/delete',
    async (userID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await userService.deleteUser(userID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get users
export const getUsers = createAsyncThunk(
    'users/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await userService.getUsers(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get user
export const getUser = createAsyncThunk(
    'user/one',
    async (userID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await userService.getUser(userID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get user by email
export const getUserByEmail = createAsyncThunk(
    'user/oneByEmail',
    async (emailObj, thunkAPI) => {
        try {
            return await userService.getUserByEmail(emailObj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isOneByEmail = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteUser.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getUserByEmail.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserByEmail.fulfilled, (state, action) => {
                state.isLoading = false
                state.isOneByEmail = true
                state.user = action.payload
            })
            .addCase(getUserByEmail.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isOne = true
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users = action.payload
    
                const data = action.payload
                if (data.length) {
                    localStorage.setItem('users', JSON.stringify(data))
                }
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetUser } = userSlice.actions
export default userSlice.reducer
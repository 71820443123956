import React from 'react'
import IconButton from '@mui/material/IconButton'
import PaymentsIcon from '@mui/icons-material/Payments'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import PaymentMethodDetails from '../../checkout/PaymentMethodDetails'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText'
import {
  fontSize,
} from '../tools/Styles'
import HMBox from './HMBox'
import mtn from '../../../assests/mtn.png'
import visa from '../../../assests/visa.png'
import airtel from '../../../assests/airtel.png'
import mastercard from '../../../assests/mastercard.png'
import {
  white,
  orange,
  lightGreen,
  seeThroughLightBlue,
} from '../../../hooks/useColors'

function HMPaymentMethods({
  handleBack,
  isShowBackIcon,
  handleAddPayment,
}) {
  return (
    <>
      <HMBox
        width='100%'
        display='flex'
        margin='10px 0'
        className='fade-in'
        flexDirection='column'
      >
        <PaymentMethodDetails 
          hoverBgColor={lightGreen}
          upperText='Cash On Delivery'
          bgColor={seeThroughLightBlue}
          lowerText='Pay when you receive'
          handleClick={() => handleAddPayment('COD')}
          leftIcon={
            <PaymentsIcon 
              sx={{
                margin: 'auto 10px auto 0',
              }} 
            />
          }
          rightIcon={
            <ChevronRightOutlinedIcon 
              sx={{
                margin: 'auto 0 auto auto'
              }} 
            />
          }
        />
        <PaymentMethodDetails 
          hoverBgColor={lightGreen}
          lowerText='Oline payment'
          bgColor={seeThroughLightBlue}
          upperText='Credit/Debit Card'
          handleClick={() => handleAddPayment('card')}
          leftIcon={
            <CreditCardIcon 
              sx={{
                margin: 'auto 10px auto 0',
              }} 
            />
          }
          rightIcon={
            <ChevronRightOutlinedIcon 
              sx={{
                margin: 'auto 0 auto auto'
              }} 
            />
          }
          beforeRightIcon={
            <HMBox
              padding='0'
              display='flex'
              margin='auto auto auto 10px'
            >
              <img 
                alt='visa'
                src={visa}
                style={{
                  height: '10px',
                  margin: 'auto 5px',
                  borderRadius: '8px',
                }}
              />
              <img 
                alt='mastercard'
                src={mastercard}
                style={{
                  height: '12px',
                  margin: 'auto 5px',
                  borderRadius: '8px',
                }}
              />
              <HMText 
                text='...'
                margin='auto 5px'
              />
            </HMBox>
          }
        />
        <PaymentMethodDetails 
          color='black'
          lowerText='MoMo'
          bgColor='#ffc907'
          hoverBgColor='#ffc907cf'
          upperText='MTN Mobile Money'
          handleClick={() => handleAddPayment('mtn')}
          leftIcon={
            <HMImage 
              src={mtn}
              width='30px'
              margin='auto 10px auto 0'
            />
          }
          rightIcon={
            <ChevronRightOutlinedIcon 
              sx={{
                color: 'black',
                margin: 'auto 0 auto auto'
              }} 
            />
          }
        />
        <PaymentMethodDetails 
          color='white'
          bgColor='#ff1f1f'
          hoverBgColor='#ff1f1fed'
          upperText='Airtel Money'
          lowerText='Airtel Money'
          handleClick={() => handleAddPayment('airtel')}
          leftIcon={
            <img 
              alt='airtel'
              src={airtel}
              style={{
                width: '30px',
                borderRadius: '8px',
                margin: 'auto 10px auto 0'
              }}
            />
          }
          rightIcon={
            <ChevronRightOutlinedIcon 
              sx={{
                color: 'white',
                margin: 'auto 0 auto auto'
              }} 
            />
          }
        />
      </HMBox>
      {isShowBackIcon ? (
        <IconButton   
          onClick={handleBack} 
          sx={{
            top: '5px',
            left: '5px',
            background: orange,
            position: 'absolute',
            '&:hover': {
              background: orange,
            }
          }}
        >
          <ArrowBackIosNewTwoToneIcon 
            sx={{
              color: white,
              margin: 'auto',
              fontSize: `${fontSize + 2}px`
            }}
          />
        </IconButton>
      ) : <></>}
    </>
  )
}

export default HMPaymentMethods
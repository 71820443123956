import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PaymentsIcon from '@mui/icons-material/Payments'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import HMToogleButton from '../tools/HMToogleButton'
import HMPhoneNumber from '../tools/HMPhoneNumber'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import HMText from '../tools/HMText'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../tools/Styles'
import HMBox from './HMBox'
import visa from '../../../assests/visa.png'
import mastercard from '../../../assests/mastercard.png'
import {
    red,
    green,
    orange,
    lightBlack,
} from '../../../hooks/useColors'

function HMPaymentMethod({
    paymentType,
    mtnFormData,
    cardFormData,
    airtelFormData,
    handleMtnDataChange,
    handleCardDataChange,
    handleAirtelDataChange,
    disabledPaymentMethod,
    handleSavePaymentMethod,
    handleAddPaymentMethodModal,
    handleAddPaymentMethodConfirm
}) {
    return (
        <HMBox
            width='100%'
        >
            {paymentType === 'COD' ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='10px 0'
                    className='fade-in'
                >
                    <PaymentsIcon 
                        sx={{
                            margin: '0 5px auto 0',
                        }} 
                    />
                    <p style={{margin: 'auto 0 20px 5px', textAlign: 'justify'}}>
                    <b>Security Advisory: </b> HarakaMeds stores or pharmacies are <b>NOT</b> allowed to
                    ask you to order online and transact your payment outside the platform. 
                    <br />
                    <br />
                    When using <b>Cash On Delivery</b> service, payment is given to our official 
                    delivery service partner/employee upon receipt of your order.
                    </p>
                </HMBox>
            ) : paymentType === 'card' ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='10px 0'
                    className='fade-in'
                    flexDirection='column'
                >
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                    >
                        <HMText 
                            margin='auto 0'
                            text='Add a new card'
                            fontSize={`${fontSize - 2}px`}
                        />
                        <GppGoodOutlinedIcon 
                            sx={{
                                color: green,
                                margin: 'auto 5px auto auto',
                                fontSize: `${fontSize + 6}px`
                            }}
                        />
                        <p
                            style={{
                                color: green,
                                margin: 'auto 0 auto 0',
                                textTransform: 'uppercase',
                                fontSize: `${fontSize - 6}px`
                            }}
                        >
                            Security<br/>Guaranteed
                        </p>
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        className='checkout-payment-method'
                        margin='20px auto auto auto'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMTextField 
                                type='text'
                                width='100%'
                                margin='auto'
                                required={true}
                                name='cardNumber'
                                label='Card number'
                                onChange={handleCardDataChange}
                                placeholder='Enter card number'
                                value={cardFormData.cardNumber}
                            />
                            {!cardFormData.cardNumber ? (
                                <HMBox
                                    right='0'
                                    padding='0'
                                    display='flex'
                                    margin='8px 0 0 0'
                                    position='absolute'
                                >
                                    <img 
                                        alt='visa'
                                        src={visa}
                                        style={{
                                            height: '10px',
                                            margin: 'auto 5px',
                                            borderRadius: '8px',
                                        }}
                                    />
                                    <img 
                                        alt='mastercard'
                                        src={mastercard}
                                        style={{
                                            height: '12px',
                                            margin: 'auto 5px',
                                            borderRadius: '8px',
                                        }}
                                    />
                                    <HMText 
                                        text='...'
                                        margin='auto 5px'
                                    />
                                </HMBox>
                            ) : <></>}
                        </HMBox>
                        <HMText 
                            color={red}
                            className='error'
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Please input the right value!'
                        />
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        className='checkout-payment-method'
                        margin='20px auto auto auto'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMTextField 
                                type='text'
                                width='100%'
                                margin='auto'
                                required={true}
                                name='nameOnCard'
                                label='Name on card'
                                onChange={handleCardDataChange}
                                placeholder='Enter name on card'
                                value={cardFormData.nameOnCard}
                            />
                        </HMBox>
                        <HMText 
                            color={red}
                            className='error'
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Please input the right value!'
                        />
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        className='checkout-payment-method'
                        margin='20px auto auto auto'
                    >
                        <HMBox
                            padding='0'
                            width='65%'
                            display='flex'
                            flexDirection='column'
                            margin='auto 25px auto 0'
                        >
                            <HMTextField 
                                type='text'
                                width='100%'
                                margin='auto 0'
                                required={true}
                                name='cardExpirationDate'
                                label='Expiration (MM/YY)'
                                onChange={handleCardDataChange}
                                placeholder='Enter expiration day'
                                value={cardFormData.cardExpirationDate}
                            />
                            <HMText 
                                color={red}
                                className='error'
                                margin='5px auto auto 0'
                                fontSize={`${fontSize - 2}px`}
                                text='Please input the right value!'
                            />
                        </HMBox>
                        <HMBox
                            padding='0'
                            display='flex'
                            flexDirection='column'
                            margin='auto 0 auto auto'
                        >
                            <HMTextField 
                                name='cvv'
                                label='CVV'
                                type='text'
                                width='100%'
                                margin='auto 0'
                                required={true}
                                placeholder='Enter CVV'
                                value={cardFormData.cvv}
                                onChange={handleCardDataChange}
                            />
                            <HMText 
                                color={red}
                                className='error'
                                margin='5px auto auto 0'
                                text='Please enter CVV!'
                                fontSize={`${fontSize - 2}px`}
                            />
                            <Tooltip 
                                arrow
                                placement='left' 
                                sx={{ margin: 'auto 0' }}
                                title='The CVV is the 3 digit number on the back of your card.' 
                            >
                                <HelpOutlineTwoToneIcon 
                                sx={{
                                    right: 0,
                                    cursor: 'pointer',
                                    color: lightBlack,
                                    position: 'absolute',
                                    margin: '7px 5px 0 0',
                                    fontSize: `${fontSize + 2}px`
                                }} 
                                />
                            </Tooltip>
                        </HMBox>        
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        margin='40px auto auto auto'
                        className='checkout-payment-method'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMText 
                                color='black'
                                margin='auto 0'
                                text='Save Card'
                                fontSize={`${fontSize - 1}px`}
                            />
                            <HMToogleButton 
                                text=''
                                margin='auto 0 auto auto'
                                status={cardFormData.save}
                                handleChange={handleSavePaymentMethod}
                            />
                        </HMBox>
                        <HMText 
                            color={lightBlack}
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Information is encrypted and secury stored.'
                        />
                    </HMBox>
                </HMBox>
            ) : paymentType === 'mtn' ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='10px 0'
                    className='fade-in'
                    flexDirection='column'
                >
                    <HMBox
                        width='100%'
                        display='flex'
                        padding='10px 0'
                    >
                        <HMText 
                            margin='auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Add a new MTN Mobile Money'
                        />
                        <GppGoodOutlinedIcon 
                            sx={{
                                color: green,
                                margin: 'auto 5px auto auto',
                                fontSize: `${fontSize + 6}px`
                            }}
                        />
                        <p
                            style={{
                                color: green,
                                margin: 'auto 0 auto 0',
                                textTransform: 'uppercase',
                                fontSize: `${fontSize - 6}px`
                            }}
                        >
                            Security<br/>Guaranteed
                        </p>
                    </HMBox>
                    <HMPhoneNumber 
                        name='mtnNumber'
                        label='MTN number'
                        margin='20px auto auto auto'
                        value={mtnFormData.mtnNumber}
                        onChange={handleMtnDataChange}
                        displayError={(mtnFormData.mtnNumber.length && !mtnFormData.isValid) ? true : false}
                    />
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        margin='20px auto auto auto'
                        className='checkout-payment-method'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMTextField 
                                type='text'
                                name='name'
                                label='Name'
                                width='100%'
                                margin='auto'
                                required={true}
                                value={mtnFormData.name}
                                onChange={handleMtnDataChange}
                                placeholder='Enter name registered with number'
                            />
                        </HMBox>
                        <HMText 
                            color={red}
                            className='error'
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Please input the right value!'
                        />
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        margin='40px auto auto auto'
                        className='checkout-payment-method'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMText 
                                color='black'
                                margin='auto 0'
                                text='Save MTN Mobile Money'
                                fontSize={`${fontSize - 1}px`}
                            />
                            <HMToogleButton 
                                text=''
                                margin='auto 0 auto auto'
                                status={mtnFormData.save}
                                handleChange={handleSavePaymentMethod}
                            />
                        </HMBox>
                        <HMText 
                            color={lightBlack}
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Information is encrypted and secury stored.'
                        />
                    </HMBox>
                </HMBox>
            )  : paymentType === 'airtel' ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='10px 0'
                    className='fade-in'
                    flexDirection='column'
                >
                    <HMBox
                        width='100%'
                        display='flex'
                        padding='10px 0'
                    >
                        <HMText 
                            margin='auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Add a new Airtel Mobile Money'
                        />
                        <GppGoodOutlinedIcon 
                            sx={{
                                color: green,
                                margin: 'auto 5px auto auto',
                                fontSize: `${fontSize + 6}px`
                            }}
                        />
                        <p
                            style={{
                                color: green,
                                margin: 'auto 0 auto 0',
                                textTransform: 'uppercase',
                                fontSize: `${fontSize - 6}px`
                            }}
                        >
                            Security<br/>Guaranteed
                        </p>
                    </HMBox>
                    <HMPhoneNumber 
                        name='airtelNumber'
                        label='Airtel number'
                        margin='20px auto auto auto'
                        onChange={handleAirtelDataChange}
                        value={airtelFormData.airtelNumber}
                        displayError={(airtelFormData.airtelNumber.length && !airtelFormData.isValid) ? true : false}
                    />
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        margin='20px auto auto auto'
                        className='checkout-payment-method'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMTextField 
                                type='text'
                                name='name'
                                label='Name'
                                width='100%'
                                margin='auto'
                                required={true}
                                value={airtelFormData.name}
                                onChange={handleAirtelDataChange}
                                placeholder='Enter name registered with number'
                            />
                        </HMBox>
                        <HMText 
                            color={red}
                            className='error'
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Please input the right value!'
                        />
                    </HMBox>
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        margin='40px auto auto auto'
                        className='checkout-payment-method'
                    >
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                        >
                            <HMText 
                                color='black'
                                margin='auto 0'
                                fontSize={`${fontSize - 1}px`}
                                text='Save Airtel Mobile Money'
                            />
                            <HMToogleButton 
                                text=''
                                margin='auto 0 auto auto'
                                status={airtelFormData.save}
                                handleChange={handleSavePaymentMethod}
                            />
                        </HMBox>
                        <HMText 
                            color={lightBlack}
                            margin='5px auto auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text='Information is encrypted and secury stored.'
                        />
                    </HMBox>
                </HMBox>
            ) : <></>}
            <HMBox
                width='100%'
                display='flex'
                margin='15px auto 0 auto'
                className='change-store-button fade-in'
            >
                <HMButton 
                    type='button'
                    width='100px'
                    bgColor={orange}
                    fontWeight={500}
                    margin='auto 10px auto 0'
                    handleClick={handleAddPaymentMethodModal}
                    icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    text={
                        <Typography sx={textStyle}>
                            Back
                        </Typography>
                    }
                />
                <HMButton 
                    type='button'
                    width='100px'
                    bgColor={green}
                    margin='auto 0 auto auto'
                    disabled={disabledPaymentMethod}
                    handleClick={handleAddPaymentMethodConfirm}
                    text={
                        <Typography sx={textStyle}>
                            Confirm
                        </Typography>
                    }
                />
            </HMBox>
        </HMBox>
    )
}

export default HMPaymentMethod
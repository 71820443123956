import React from 'react'
import { Button } from '@mui/material'
import { fontSize } from './Styles'
import { 
    darkBlue,
    white
} from '../../../hooks/useColors'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

function HMButton (props) {
    const {
        windowW
    } = useWindowDimensions()

    const isMobileScreen = windowW < 520 ? true : false

    const buttonStyle = {
        top: props.top,
        display: 'flex',
        flexWrap: 'wrap', 
        left: props.left,
        right: props.right,
        float: props.float,
        width: props.width,
        height: props.height,
        bottom: props.bottom,
        border: props.border,
        minWidth: props.minWidth,
        position: props.position,
        color: props.color || white,
        borderColor: props.borderColor,
        backgroundColor: props.bgColor,
        flexDirection: props.flexDirection,
        margin: props.margin || '5px 10px',
        padding: props.padding || '4px 6px',
        borderRadius: `${props.borderRadius}px`,
        fontSize: props.fontSize || `${fontSize}px`,
        textTransform: props.textTransform || 'capitalize',
        '&:hover': {
            opacity: props.opacity || 0.8,
            color: props.hoverColor || props.color,
            backgroundColor: props.hoverBgColor || props.bgColor,
        },
        '&:active': {
            padding: props.paddingActive || '3.5px 5.5px',
            borderRight: props.borderRightActive || '0.5px solid',
            borderBottom: props.borderBottomActive || '0.5px solid',
            backgroundColor: darkBlue
        }
    }
    return (
        <Button 
            sx={buttonStyle} 
            type={props.type} 
            disabled={props.disabled}
            onClick={props.handleClick} 
            className={`${props.className} ${props.isResponsive && 'responsive'}`}
        >
            {props.icon}
            {(!isMobileScreen || !props.isResponsive) && props.text}
            {props.rightIcon}
        </Button>
    )
}

export default HMButton
import ReactApexChart from 'react-apexcharts'


const HMBarChart = (props) => {
  
    const options = {
      chart: {
        type: 'bar',
        height: 400,
        toolbar: {
          show: false,
        },
      },
      colors: props.colors || ['#00AEEF', '#16D39A','#FF7588', '#F78800',],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          borderRadius: 8
        },
      },
      stroke: {
        show: true,
            width: 2,
            colors: ['transparent']
      },
      grid: {
        show: true,
        borderColor: '#e7e7e7',
        xaxis: {
          lines: {
            show: true,
          },
        },

      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.7,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 50, 100],
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val + " RF"
          }
        }
      },
      legend: {
        position: 'bottom',
        width: '100%',
      },
    }
  
    return (
      <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
        type = 'bar'
      />
    )
  }

  export default HMBarChart;
  
import React from 'react'
import Grid from '@mui/material/Grid'
import { white } from '../../../hooks/useColors'

function ButtonIcon(props) {
    const gridStyle = {
        cursor: 'pointer',
        float: props.float,
        border_radius_top_left: '3px',
        color: props.color || white,
        backgroundColor: props.bgColor,
        margin: props.margin || 'auto 2px',
        padding: props.padding || '2px 3px',
        borderRadius: props.borderRadius || '5px',
        '&:hover': {
            opacity: 0.7,
        },
        '&:active': {
            margin: 'auto 1.5px',
            padding:  props.activePadding || '2px 3.5px',
        }
    }

    return (
        <Grid sx={gridStyle} onClick={props.handleClick}>
            {props.icon}
            {props.secondIcon}
        </Grid>
    )
}

export default ButtonIcon
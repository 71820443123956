import axios from 'axios'

const API_URL_USERS = '/api/v1/users'

// Get all users
const getUsers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_USERS, config)
    
    const data = response.data
    
    return data
}

// Get a users
const getUser = async (userID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_USERS}/${userID}`, config)

    const data = response.data

    return data
}

// Get a user by email
const getUserByEmail = async (emailObj) => {
    const response = await axios.post(`${API_URL_USERS}/search-email`, emailObj)

    const data = response.data

    return data
}

// Create a new user
const createUser = async (newUser, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_USERS}/create`, newUser, config)

    const data = response.data

    return data
}

// Update a user
const updateUser = async (user, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_USERS}/${user._id}`, user, config)

    const data = response.data
    
    return data
}

// Update a user
const deleteUser = async (userID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_USERS}/${userID}`, config)

    const data = response.data
    
    return data
}

const userService = {
    getUserByEmail,
    createUser,
    updateUser,
    deleteUser,
    getUsers,
    getUser
}
export default userService
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Box,
  Grid,
  Typography,
  TextareaAutosize
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AddIcon from '@mui/icons-material/Add'
import { 
  getProduct, 
  resetProduct, 
  updateProduct
} from '../../../features/products/productSlice'
import { 
  getCategories, 
  resetCategory 
} from '../../../features/categories/categorySlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import HMProductCreationFileds from '../../../components/common/layout/HMProductCreationFileds'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import HMEditor from '../../../components/common/tools/HMEditor'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMText from '../../../components/common/tools/HMText'
import { 
  textStyle, 
  iconStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  red,
  blue,
  white,
  orange,
  green,
  lightBlue,
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'

function EditProduct() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const categoryState = useSelector((state) => state.category)
  const { categories } = categoryState
  const productState = useSelector((state) => state.product)
  const { product } = productState
  
  const breadOptions = [
    { text: 'Products', link: '/admin/products' },
    { text: `Edit - ${product['name'] && product.name}`, link: null }
  ]

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false

  const defaultFormData = (product, categories) => {
    const checkCategories = categories.length ? true : false
    const checkProduct = product && Object.keys(product).length ? true : false

    return {
      name: checkProduct ?  product.name : '',
      brand: checkProduct ?  product.brand : '',
      image: checkProduct ?  product.image : '',
      dosage: checkProduct ?  product.dosage : '',
      strength: checkProduct ?  product.strength : '',
      published: checkProduct ?  product.published : false,
      sideEffects: checkProduct ?  product.sideEffects : '',
      description: checkProduct ?  product.description : '',
      parent: checkProduct ?  product.category.id.parent.name : '',
      insurancePrice: (checkProduct && product['insurancePrice']) ?  product['insurancePrice'] : 0,
      category: (checkProduct && checkCategories) ? categories.find(cat => cat.name === product.category.id.name) : null
    }
  }

  useEffect(() => {
    dispatch(getProduct(id))
  }, [dispatch, id])

  useEffect(() => {
    const {
      isOne,
      isError,
      message,
      isUpdated
    } = productState
    if (isError) {
      dispatch(resetProduct())
      toast.error(message)
    } else if (isOne) {
      dispatch(resetProduct())
      setFormData(defaultFormData(product, categories))
    } else if (isUpdated) {
      toast.success('Product updated successfully')
      dispatch(resetProduct())
      setFormData(defaultFormData(product, categories))
    }
  }, [dispatch, productState, product, categories, id])

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories())
    }

    if (categoryState.isSuccess) {
      dispatch(resetCategory())
      if (Object.keys(product).length) {
        setFormData((prevState) => ({
          ...prevState,
          category: categories.find(cat => cat.name === product.category.id.name)
        }))
      }
    } else if (categoryState.isError) {
      dispatch(resetCategory())
      toast.error(categoryState.message)
    }
  }, [dispatch, categories, categoryState.isSuccess, categoryState.isError, categoryState.message, product])

  const supportedFiles = {
    'text/jpeg': [],
    'image/png': []
  }

  const [formData, setFormData] = useState(defaultFormData(product, categories))
  
  const {
    name,
    image,
    brand,
    dosage,
    parent,
    category,
    strength,
    description,
    sideEffects,
    insurancePrice,
  } = formData
  const [productImageUrl, setProductImageUrl] = useState(image)

  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      image: productImageUrl,
    }))
  },[productImageUrl])
  const handleChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    }))
  }
  const handleDrowdownChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      category: val,
      parent: val && val.parent ? val.parent : ''
    }))
  }
  const handleEditorChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      description: content
    }))
  }

  const handleAdd = () => {
    navigate('/admin/products/create')
  }
  const handleView = () => {
    navigate(`/admin/products/${product._id}`)
  }
  const disabled = (
    image === '' ||
    name === '' 
  ) ? true : false
  
  const handleUpdate = (e) => {
    e.preventDefault()
    const mainCat = parent.toLowerCase()
    const hasTax = mainCat.includes('health') ? true : false
    const needsPrescription = mainCat.includes('prescription') ? true : false
    const updatedProduct = {
      name,
      image,
      brand,
      hasTax,
      dosage,
      _id: id,
      strength,
      sideEffects,
      description,
      needsPrescription,
      category: {
        id: category._id
      },
    }

    if (insurancePrice && insurancePrice > 0) {
      updatedProduct.insurancePrice = insurancePrice
    }

    dispatch(updateProduct(updatedProduct))
  }

  return (
    <Page>
      {productState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(product).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMButton 
                type='button'
                bgColor={orange}
                isResponsive={true}
                handleClick={handleView}
                icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>View</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add Product</Typography>} 
              />
            </HMBox>
          </FullWidthContainer>
          <Section
            bgColor={white}
            padding={10}
          >
            <HMProductCreationFileds 
              buttonColor={blue}
              formData={formData}
              disabled={disabled}
              buttonText='Update product'
              handleChange={handleChange}
              handleSubmit={handleUpdate}
              supportedFiles={supportedFiles}
              productImageUrl={productImageUrl}
              setProductImageUrl={setProductImageUrl}
              handleEditorChange={handleEditorChange}
              handleDrowdownChange={handleDrowdownChange}
            />
          </Section>
        </>
      ) : <></>}
    </Page>
  )
}

export default EditProduct
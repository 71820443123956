import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import faqService from './faqsService'

// Get faqs from local storage
const initialState = {
    faqs: [],
    faq: {},
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isError: false,
    isOne: false,
    message: ''
}

// Create a new faq
export const createFaq = createAsyncThunk(
    'faqs/create',
    async (newFaq, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.createFaq(newFaq, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get faqs
export const getFaqs = createAsyncThunk(
    'faqs/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.getFaqs(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get faqs
export const getFaqsByRole = createAsyncThunk(
    'faqs/role',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.getFaqsByRole(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update an faq
export const updateFaq = createAsyncThunk(
    'faqs/update',
    async (faq, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.updateFaq(faq, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele an faq
export const deleteFaq = createAsyncThunk(
    'insurers/delete',
    async (faqID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.deleteFaq(faqID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get Faq
export const getFaq = createAsyncThunk(
    'faq/one',
    async (faqID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await faqService.getFaq(faqID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        resetFaq: (state) => {
            state.isOne = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createFaq.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.faq = action.payload
            })
            .addCase(createFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getFaqs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaqs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faqs = action.payload
            })
            .addCase(getFaqs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateFaq.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
                state.faq = action.payload
            })
            .addCase(updateFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteFaq.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isOne = true
                state.faq = action.payload
            })
            .addCase(getFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getFaqsByRole.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaqsByRole.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faqs = action.payload
            })
            .addCase(getFaqsByRole.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { resetFaq } = faqSlice.actions
export default faqSlice.reducer
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { 
  getMySupplier,
} from '../../../features/suppliers/supplierSlice'
import { 
  getSupplierListing,
  resetSupplierListing,
  deleteSupplierListing,
  updateSupplierListing,
} from '../../../features/supplierListings/supplierListingSlice'
import { 
  getProductInsurers,
  resetRelationProductsInsurers
} from '../../../features/relationProductsInsurers/relationProductsInsurersSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import HMAccordion from '../../../components/common/layout/HMAccordion'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { 
  isInTitle,
  currencies 
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  lightRed,
  darkBlue,
  lightBlue,
  lightGreen,
  lightBlack,
  lightOrange,
  getRandomColor,
} from '../../../hooks/useColors'

function Listing() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { supplierId, listingId } = useParams()

    const { user } = useSelector((state) => state.auth)
    const { mySuppliers } = useSelector((state) => state.supplier)
    const {
      isLoading,
      productInsurers,
      isProductInsurers
    } = useSelector(state => state.relationProductsInsurers)
    const supplierListingState = useSelector(state => state.supplierListing)

    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const [currency, setCurrency] = useState('')
    const [isDelete, setIsDelete] = useState(false)
    const [supplierListing, setSupplierListing] = useState({})
    const [activeState, setActiveState] = useState(false)
    const [archiveState, setArchiveState] = useState(false)
    const [sellOnlineState, setSellOnlineState] = useState(false)

    const {
      windowW
    } = useWindowDimensions()

    const isMobileScreen = windowW < 520 ? true : false
    const isTabletScreen = windowW < 720 ? true : false

    const containerStyle = {
      borderRadius: '8px',
      padding: isMobileScreen ? '15px 5px' : '5px'
    }
    
    useEffect(() => {
      if (user) {
        dispatch(getSupplierListing(listingId))
        if (mySuppliers.length) {
          dispatch(getMySupplier(supplierId))
        }
        setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
      }
    }, [dispatch, user, mySuppliers, supplierId, listingId])
  
    useEffect(() => {
      const {
        isOne,
        isError,
        message,
        isDeleted,
        isUpdated
      } = supplierListingState

      if ((isOne || isUpdated) && Object.keys(supplierListingState.supplierListing).length) {
        setSupplierListing(supplierListingState.supplierListing)
      }
  
      if (isError) {
        toast.error(message)
      } else if (isOne) {
        setActiveState(supplierListingState.supplierListing.active)
        setArchiveState(supplierListingState.supplierListing.archive)
        setSellOnlineState(supplierListingState.supplierListing.soldOnline)
      } else if (isDeleted) {
        toast.success(message)
        navigate(`/supplier/${supplierId}/listings`)
      } else if (isUpdated) {
        if (isInTitle(title, 'deactivate', 'activate')) {
          setActiveState(supplierListingState.supplierListing.active)
          toast.success(`Supplier listing is ${supplierListingState.supplierListing.active ? 'activated' : 'deactivated'} successfuly!`)
        } else if (isInTitle(title, 'start', 'stop')) {
          setSellOnlineState(supplierListingState.supplierListing.soldOnline)
          toast.success(`Supplier listing is ${supplierListingState.supplierListing.soldOnline ? 'set to sell' : 'stopped from selling'} online!`)
        } else if (isInTitle(title, 'unarchive', 'archive')) {
          setArchiveState(supplierListingState.supplierListing.archive)
          toast.success(`Supplier listing is ${supplierListingState.supplierListing.archive ? 'archived' : 'unarchived'} successfully!`)
        }
      }

      if (isDeleted || isUpdated || isError || isOne) {
        dispatch(resetSupplierListing())
      } 
      
    }, [dispatch, navigate,  supplierListingState, supplierId, title])

    useEffect(() => {
      if (Object.keys(supplierListing).length) {
        dispatch(getProductInsurers(supplierListing.product._id))
      }
    }, [dispatch, supplierListing])

    useEffect(() => {
      if (isProductInsurers) {
        dispatch(resetRelationProductsInsurers())
      }
    }, [dispatch, isProductInsurers])

    const breadOptions = [
      { text: 'Products', link: `/supplier/${supplierId}/listings` },
      { text: Object.keys(supplierListing).length && supplierListing.product.name, link: null }
    ]

    const handleEdit = () => {
      navigate(`/supplier/${supplierId}/listings/edit/${listingId}`)
    }
    const handleAdd = () => {
      navigate(`/supplier/${supplierId}/listings/create`)
    }

    const handleArchiveStateModal = () => {
      setOpen(true)
      setArchiveState(!archiveState)
      setTitle(`${!supplierListing.archive ? 'Archive' : 'Unarchive'} Listing`)
    }
    const handleArchiveState = () => {
      const updatedListing = {
        _id: supplierListing._id,
        supplier: supplierListing.supplier._id,
        archive: !supplierListing.archive,
        product: supplierListing.product._id,
      }
      dispatch(updateSupplierListing(updatedListing))
      setOpen(false)
    }
  
    const handleSellOnlineStateModal = () => {
      setOpen(true)
      setSellOnlineState(!sellOnlineState)
      setTitle(`${!supplierListing.soldOnline ? 'Start' : 'Stop'} selling online`)
    }
    const handleSellOnlineState = () => {
      const updatedListing = {
        _id: supplierListing._id,
        supplier: supplierListing.supplier._id,
        product: supplierListing.product._id,
        soldOnline: !supplierListing.soldOnline,
      }
      dispatch(updateSupplierListing(updatedListing))
      setOpen(false)
    }
  
    const handleActiveStateModal = () => {
      setOpen(true)
      setActiveState(!activeState)
      setTitle(`${!supplierListing.active ? 'Activate' : 'Deactivate'} Listing`)
    }
    const handleActiveState = () => {
      const updatedListing = {
        _id: supplierListing._id,
        active: !supplierListing.active,
        supplier: supplierListing.supplier._id,
        product: supplierListing.product._id,
      }
      dispatch(updateSupplierListing(updatedListing))
      setOpen(false)
    }
  
    const handleDeleteModal = () => {
      setOpen(true)
      setIsDelete(true)
      setTitle('Delete Listing')
    }
    const handleDelete = () => {
      dispatch(deleteSupplierListing(supplierListing._id))
      handleClose()
    }
    const handleClose = () => {
      setTitle('')
      setOpen(false)
      setIsDelete(false)
      setActiveState(supplierListing.active)
      setArchiveState(supplierListing.archive)
      setSellOnlineState(supplierListing.soldOnline)
    }
  
    return user ? (
      <Page>
        {(supplierListingState.isLoading || isLoading) ? (
            <HMSpiner 
                size={60}
                zIndex={999}
                width='100%'
                height='80vh'
                margin='auto'
                position='absolute'
                bgColor='transparent'
            />
        ) : <></>}
        {Object.keys(supplierListing).length ? (
          <>
            <FullWidthContainer
              display='flex'
              flexDirection={isTabletScreen ? 'column' : 'row'}
            >
              <HMBreadcrumbs 
                options={breadOptions}
                margin='auto auto auto 0'
                padding={isTabletScreen && '10px 0 0 10px'}
              />
              <HMBox 
                float='right'
                display='flex'
                alignSelf='flex-start'
                padding={isTabletScreen && '10px 0'}
                flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
              >
                {supplierListing.orders.length ? (
                  <HMToogleButton 
                    status={!archiveState}
                    handleChange={handleArchiveStateModal}
                    text={!archiveState ? 'Active' : 'Archived'}
                  />
                ) : <></>}
                {!supplierListing.orders.length ? (
                  <HMToogleButton 
                    status={activeState}
                    handleChange={handleActiveStateModal}
                    text={activeState ? 'Active' : 'Inactive'}
                  />
                ) : <></>}
                <HMButton 
                  type='button'
                  bgColor={blue}
                  isResponsive={true}
                  handleClick={handleEdit}
                  icon={<EditIcon sx={iconStyle} />} 
                  text={<Typography sx={textStyle}>Edit</Typography>} 
                />
                <HMButton 
                  type='button'
                  bgColor={green}
                  isResponsive={true}
                  handleClick={handleAdd}
                  icon={<AddIcon sx={iconStyle} />} 
                  text={<Typography sx={textStyle}>Add Listing</Typography>} 
                />
                {(!supplierListing.active && !supplierListing.orders.length) ? (
                  <HMButton 
                    type='button'
                    bgColor={red}
                    isResponsive={true}
                    handleClick={handleDeleteModal}
                    icon={<DeleteIcon sx={iconStyle} />} 
                    text={<Typography sx={textStyle}>Delete</Typography>} 
                  />
                ) : <></>}
              </HMBox>
            </FullWidthContainer>
            <HMModal 
                open={open} 
                title={title}
                handleClose={handleClose} 
                colors={{lightBlack, lightBlue}}
            >
              {isDelete ? (
                <Typography sx={deleteTextStyle}>
                  Are you sure your want to delete the listing for: 
                </Typography>
              ) : isInTitle(title, 'activate', 'deactivate') ? (
                <Typography sx={deleteTextStyle}>
                  Are you sure your want to {supplierListing.active ? 'deactivate' : 'activate'} the listing for: 
                </Typography>
              ) : isInTitle(title, 'start', 'stop') ? (
                <Typography sx={deleteTextStyle}>
                  Are you sure your want to {supplierListing.active ? 'stop' : 'start'} selling online the listing for: 
                </Typography>
              ) : isInTitle(title, 'unarchive', 'archive') ? (
                <Typography sx={deleteTextStyle}>
                  Are you sure your want to {supplierListing.archive ? 'unarchive' : 'archive'} the listing for: 
                </Typography>
              ) : <></>}
              <Typography sx={deleteItemTextStyle}>
                {supplierListing.product.name} ?
              </Typography>
              <HMButton 
                type='button'
                float='right'
                margin='auto'
                bgColor={
                  isDelete || 
                  (isInTitle(title, 'activate', 'deactivate') && supplierListing.active) || 
                  (isInTitle(title, 'start', 'stop') && supplierListing.soldOnline) || 
                  (isInTitle(title, 'unarchive', 'archive') && !supplierListing.archive) ? red : green}
                handleClick={
                  isDelete ? handleDelete 
                  : isInTitle(title, 'activate', 'deactivate') ? handleActiveState 
                  : isInTitle(title, 'start', 'stop') ? handleSellOnlineState
                  : handleArchiveState
                }
                text={<Typography sx={textStyle}>Yes, {
                    isDelete ? 'Delete' 
                    : isInTitle(title, 'activate', 'deactivate') && supplierListing.active ? 'Deactivate' 
                    : isInTitle(title, 'activate', 'deactivate') && !supplierListing.active ? 'Activate'
                    : isInTitle(title, 'start', 'stop') && supplierListing.soldOnline ? 'Stop'
                    : isInTitle(title, 'start', 'stop') && !supplierListing.soldOnline ? 'Start' 
                    : isInTitle(title, 'unarchive', 'archive') && !supplierListing.archive ? 'Archive'
                    : 'Unarchive'
                  }!</Typography>}
              />
              <HMButton 
                float='left'
                type='button'
                margin='auto'
                bgColor={gray}
                handleClick={handleClose}
                text={<Typography sx={textStyle}>No, Cancel!</Typography>}
              />
            </HMModal>

            <Section
              padding={10}
              bgColor={white}
              className='fade-in'
            >
              <Grid
                container spacing={2}
              >
                <Grid item sm={6} xs={12}>
                  <HMImage
                    borderRadius='8px'
                    alt={supplierListing.product.name}
                    src={supplierListing.product.image}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <HMBox 
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    padding='0 0 5px 0'
                  >
                    <Title
                      width='100%'
                      margin='auto 0'
                      size={fontSize}
                      textAlign='left'
                      title={supplierListing.product.name}
                    />
                    <HMToogleButton 
                      margin='auto 0'
                      status={sellOnlineState}
                      handleChange={handleSellOnlineStateModal}
                      text={sellOnlineState ? 'Online' : 'Offline'}
                    />
                  </HMBox>
                  <HMBox 
                    width='100%'
                    display='flex'
                    padding='5px 0'
                    margin='0 0 10px 0'
                    flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
                  >
                    <HMBox 
                      width='100%'
                      height='100%'
                      display='flex'
                      flexDirection='row'
                      border={`1px solid ${lightBlue}`}
                      margin={`${isTabletScreen ? '5px 0' : '0 2.5px 0 0'}`}
                    >
                      <HMText 
                        text='Insupplier price'
                        margin='auto 0'
                      />
                      <Title 
                        color={orange}
                        padding='1px 5px'
                        borderRadius='5px'
                        size={fontSize - 2}
                        bgColor={lightOrange}
                        margin='auto 0 auto auto'
                        title={`${currency} ${supplierListing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      />
                    </HMBox>
                    {supplierListing.soldOnline ? (
                      <HMBox 
                        width='100%'
                        height='100%'
                        display='flex'
                        flexDirection='row'
                        border={`1px solid ${lightBlue}`}
                        margin={`${isTabletScreen ? '5px 0' : '0 0 0 2.5px'}`}
                      >
                        <HMText 
                          text='Online price'
                          margin='auto 0'
                        />
                        <Title 
                          color={orange}
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          bgColor={lightOrange}
                          margin='auto 0 auto auto'
                          title={`${currency} ${supplierListing.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                      </HMBox>
                    ) : (
                      <></>
                    )}
                  </HMBox>
                  <HMBox
                    width='100%'
                    display='flex'
                    minHeight='100px'
                    bgColor={lightBlue}
                    className='description'
                  >
                    <div>
                      {parse(supplierListing.product.description)}
                    </div>
                  </HMBox>
                  <Title
                    width='100%'
                    title='Category'
                    size={fontSize}
                    textAlign='left'
                    margin='10px 0 5px 0'
                  />
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightBlue}
                  >
                    <HMText
                      margin='auto 0'
                      text={supplierListing.product.category.id.parent.name}
                    />
                  </HMBox>
                  <Title
                    width='100%'
                    size={fontSize}
                    textAlign='left'
                    margin='10px 0 5px 0'
                    title='Classification'
                  />
                  <HMBox
                    width='100%'
                    display='flex'
                    bgColor={lightBlue}
                  >
                    <HMText
                      margin='auto 0'
                      text={supplierListing.product.category.id.name}
                    />
                  </HMBox>
                </Grid>
                <Grid item sm={12} xs={12}>
                  
                </Grid>
              </Grid>
              <HMText 
                height='30px'
                zIndex={999}
                bgColor={white}
                fontWeight={700}
                className='fade-in'
                text='Skock Information'
                margin='10px 0 -15px 20px'
                padding='5px 10px 0px 10px'
                border={`1px solid ${lightBlue}`}
              />
              <HMBox 
                width='100%'
                height='auto'
                bgColor={lightBlue}
                padding='30px 10px 0 10px'
              >
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>                    
                    <HMBox 
                      width='100%'
                      height='100%'
                      display='flex'
                      padding='5px 0'
                      margin='0 0 10px 0'
                      flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
                    >
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                        margin={`${isTabletScreen ? 'auto 0 5px 0' : 'auto 2.5px auto 0'}`}
                      >
                        <HMText 
                          margin='auto 0'
                          text='Stock count'
                        />
                        <Title 
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          margin='auto 0 auto auto'
                          title={supplierListing.stockCount}
                          color={supplierListing.alertLevel >= (supplierListing.stockCount - supplierListing.transitStockCount) ? red : blue}
                          bgColor={supplierListing.alertLevel >= (supplierListing.stockCount - supplierListing.transitStockCount) ? lightRed : lightBlue}
                        />
                      </HMBox>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                        margin={`${isTabletScreen ? 'auto 0' : 'auto 0 auto 2.5px'}`}
                      >
                        <HMText 
                          margin='auto 0'
                          text='Alert level'
                        />
                        <Title 
                          color={blue}
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          bgColor={lightBlue}
                          margin='auto 0 auto auto'
                          title={supplierListing.alertLevel}
                        />
                      </HMBox>
                    </HMBox>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    {Object.values(supplierListing.availableStock).length ? (
                      <HMBox
                        width='100%'
                        display='flex'
                        padding='10px'
                        className='fade-in'
                        bgColor={white}
                        margin='10px 0 10px 0'
                        flexDirection='column'
                        maxWidth={`${isTabletScreen && '100% !important'}`}
                      >
                        <HMText 
                          left='0'
                          top='-35px'
                          width='115px'
                          height='30px'
                          bgColor={lightBlue}
                          fontWeight={500}
                          className='fade-in'
                          position='relative'
                          margin='10px 0 -25px 10px'
                          padding='5px 10px 0px 10px'
                          border={`1px solid ${white}`}
                          text={`Stock ${Object.values(supplierListing.availableStock).length > 1 ? 'batches' : 'batch'}`}
                        />
                        {Object.values(supplierListing.availableStock).map((stock, index) => (
                          <Grid
                            key={index}
                            container spacing={0}
                          >
                            {index === 0 ? (
                              <>
                                <Grid item xs={2}>
                                  <Typography
                                    sx={textStyle}
                                  >Batch</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography
                                    sx={{...textStyle, textAlign: 'center'}}
                                  >Expiration Date</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    sx={{...textStyle, textAlign: 'center'}}
                                  >Quantity</Typography>
                                </Grid>
                              </>
                            ) : <></>}
                            <Grid item xs={2} sx={{borderTop: `1px solid ${lightBlue}`}}>
                              <Typography sx={{...textStyle, marginLeft: '15px', padding: '5px 0'}}>
                                {stock.batch}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{borderTop: `1px solid ${lightBlue}`}}>
                              <Typography 
                                sx={{
                                  ...textStyle, 
                                  padding: '5px 0',
                                  marginLeft: '15px',
                                  textAlign: 'center',
                                  color: new Date(stock.expiresOn).getTime() - Date.now() > 0 ? blue : red,
                                }}
                              >
                                {moment(stock.expiresOn).utc().format('MMM DD, YYYY')}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{borderTop: `1px solid ${lightBlue}`}}>
                              <Typography sx={{...textStyle, textAlign: 'center', padding: '5px 0'}}>
                                {stock.quantity}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </HMBox>
                    ) : <></>}
                  </Grid>
                </Grid>
              </HMBox>
            </Section>

            {/* INSURES */}
              <Section
                bgColor={white}
                padding={10}
              >
                <Grid>
                  {(Object.keys(productInsurers).length) ? (
                    <HMAccordion
                      title='Insurers'
                      price={supplierListing.product.insurancePrice}
                    >
                      {productInsurers.insurers.map((insurer, index) => (
                        <Grid
                          key={index}
                          container spacing={0}
                          sx={{
                            ...containerStyle,
                            backgroundColor: index % 2 === 0 ? lightBlue : white
                          }}
                        >
                          <Grid item sm={3} xs={12}>
                            <HMBox
                              width='100%'
                              height='100%'
                              display='flex'
                            >
                              {insurer['logo'] ? (
                                <Link to={`/admin/insurers/${insurer._id}`} style={{ color: darkBlue, margin: 'auto 0' }}>
                                  <img
                                    alt={insurer.name}
                                    src={insurer.logo}
                                    className='mini-image'
                                    style={{ margin: 'auto' }}
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to={`/admin/insurers/${insurer._id}`}
                                  style={{ color: darkBlue, margin: 'auto 0' }}
                                >
                                  <Grid className='mini-logo'>
                                    <HMText
                                      float='none'
                                      width='100%'
                                      margin='auto'
                                      height='100%'
                                      color={white}
                                      fontSize='22px'
                                      fontWeight={700}
                                      textAlign='center'
                                      padding='0 10px 0 8px'
                                      bgColor={getRandomColor()}
                                      text={insurer.name.substr(0, 1).toUpperCase()}
                                    />
                                  </Grid>
                                </Link>
                              )}
                              <HMBox
                                width='100%'
                                display='flex'
                                flexDirection='column'
                                margin='auto auto auto 5px'
                              >
                                <Link
                                  to={`/admin/insurers/${insurer._id}`}
                                  style={{ color: darkBlue, margin: 'auto 0' }}
                                >
                                  <Title
                                    width='100%'
                                    margin='auto'
                                    textAlign='left'
                                    size={fontSize - 2}
                                    title={insurer.name}
                                  />
                                </Link>
                                <HMBox 
                                  margin='0'
                                  padding='0'
                                  width='100%'
                                  height='auto'
                                  display='flex'
                                  flexDirection='row'
                                >
                                  <HMText 
                                    margin='auto 0'
                                    borderRadius={5}
                                    fontWeight={500}
                                    text={insurer.type}
                                    fontSize={`${fontSize - 2}px`}
                                    color={insurer.type.toLowerCase() === 'private' ? orange : blue}
                                  />
                                  &nbsp; | &nbsp;
                                  <HMText 
                                    borderRadius={5}
                                    fontWeight={500}
                                    text={insurer.origin}
                                    margin='auto 0 auto 5px'
                                    fontSize={`${fontSize - 2}px`}
                                    color={insurer.origin.toLowerCase() === 'international' ? orange : blue}
                                  />
                                </HMBox>
                              </HMBox>
                            </HMBox>
                          </Grid>
                          <Grid item sm={6} xs={12} sx={{
                            minHeight: '60px',
                            borderLeft: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                            borderRight: `2px solid ${index % 2 !== 0 ? lightBlue : white}`,
                          }} >
                            <HMBox
                              width='100%'
                              display='flex'
                              height='100%'
                            >
                              <div style={{
                                margin: 'auto auto auto 0',
                                fontSize: `${fontSize - 1}px`
                              }}>
                                {parse(insurer.description)}
                              </div>
                            </HMBox>
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <HMBox
                              width='100%'
                              padding='0 0 0 10px'
                            >
                              <Title
                                width='100%'
                                textAlign='left'
                                size={fontSize - 2}
                                title='Requirements'
                                margin={isTabletScreen ? '5px 0 10px 0' : '0'}
                              />
                              <HMBox 
                                padding='0'
                                width='100%'
                                display='flex'
                                bgColor='inherit'
                                flexDirection='column'
                              >
                                <HMBox 
                                  width='100%'
                                  height='auto'
                                  margin='1px 0'
                                  display='flex'
                                  padding='0 0 0 4px'
                                  flexDirection='row'
                                >
                                  <HMText 
                                    margin='auto 0'
                                    text='Fingerprint'
                                    fontSize={`${fontSize - 2}px`}
                                  />
                                  <HMText 
                                    borderRadius={5}
                                    padding='1px 5px'
                                    margin='auto 0 auto auto'
                                    fontSize={`${fontSize - 2}px`}
                                    text={insurer.needsFingerPrints ? 'Yes' : 'No'}
                                    color={!insurer.needsFingerPrints ? red : green}
                                    bgColor={!insurer.needsFingerPrints ? lightRed : lightGreen}
                                  />
                                </HMBox>
                                <HMBox 
                                  width='100%'
                                  height='auto'
                                  margin='1px 0'
                                  display='flex'
                                  padding='0 0 0 4px'
                                  flexDirection='row'
                                  borderTop={`1px solid ${index % 2 === 0 ? white : lightBlue}`}
                                  borderBottom={`1px solid ${index % 2 === 0 ? white : lightBlue}`}
                                >
                                  <HMText 
                                    margin='auto 0'
                                    text='Insurace Card'
                                    fontSize={`${fontSize - 2}px`}
                                  />
                                  <HMText 
                                    text='Yes'
                                    color={green}
                                    padding='1px 5px'
                                    borderRadius={5}
                                    bgColor={lightGreen}
                                    margin='auto 0 auto auto'
                                    fontSize={`${fontSize - 2}px`}
                                  />
                                </HMBox>
                                <HMBox 
                                  width='100%'
                                  height='auto'
                                  margin='1px 0'
                                  display='flex' 
                                  padding='0 0 0 4px'
                                  flexDirection='row'
                                >
                                  <HMText 
                                    margin='auto 0'
                                    text='Prescription'
                                    fontSize={`${fontSize - 2}px`}
                                  />
                                  <HMText 
                                    text='Yes'
                                    color={green}
                                    padding='1px 5px'
                                    borderRadius={5}
                                    bgColor={lightGreen}
                                    margin='auto 0 auto auto'
                                    fontSize={`${fontSize - 2}px`}
                                  />
                                </HMBox>
                              </HMBox>
                            </HMBox>
                          </Grid>
                        </Grid>
                      ))}
                    </HMAccordion>
                  ) : !isLoading ? (
                    <HMBox
                      width='100%'
                      display='flex'
                      bgColor={lightRed}  
                    >
                      <HMText
                        margin='auto'
                        text='This product has no assigned insurers'
                      />
                    </HMBox>
                  ) : <></>}
                </Grid>                  
            </Section> 
          </>
        ) : <></>}
      </Page>
    ) : (
      <Section 
        padding={10} 
        bgColor={white}
      >
        <HMLoginFirst 
          message='You need to login first before accessing supplier content'
        />
      </Section>
    )
}

export default Listing
import React from 'react'
import { useSelector } from 'react-redux'
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HMBox from './HMBox'
import Title from '../tools/Title'
import HMText from '../tools/HMText'
import HMImage from '../tools/HMImage'
import {
    fontSize
} from '../tools/Styles'
import {
    red,
    green,
    orange,
    darkBlue,
    lightBlue,
    skeletonsLightBlue
} from '../../../hooks/useColors'
import {
    currencies
} from '../../../hooks/helperFunctions'

function HMProductCard(props) {
    const { 
        user 
    } = useSelector((state) => state.auth)
    const currency = currencies.find(curr => curr.country === 'Rwanda').currency

    return (
        <HMBox
            padding='0'
            width='100%'
            margin='auto'
            display='flex'
            minWidth='150px'
            maxWidth='200px'
            cursor='pointer'
            borderRadius='10px'
            bgColor={lightBlue}
            flexDirection='column'
            className={props.className}
            handleClick={props.handleClick}
        >
            <HMImage 
                borderRadius='0'
                minHeight='135px'
                src={props.image}
                borderTopLeftRadius='10px'
                borderTopRightRadius='10px'
                bgColor={skeletonsLightBlue}
            />
            <HMText 
                fontWeight={500}
                margin='5px 0 0 5px'
                fontSize={`${fontSize - 2}px`}
                color={props.isStockAvailable ? green : red}
                text={props.isStockAvailable ? 'in stock' : 'sold out'}
            />
            <Title 
                size={fontSize}
                textAlign='left'
                color={darkBlue}
                overflow='hidden'
                title={props.title}
                margin='0px 5px 0 5px'
                className='product-card-title'
            />
            {(props.minPrice || props.maxPrice) ? (
                <HMBox 
                    width='100%'
                    display='flex'
                    padding='0 5px'
                    margin='5px 0 10px 0'
                >
                    {props.minPrice ? (
                        <HMText 
                            color={orange}
                            fontWeight={500}
                            margin='auto 0 auto 0'
                            fontSize={`${fontSize}px`}
                            text={`${currency} ${props.minPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    ) : <></>}
                    {(props.minPrice && props.maxPrice) ? (
                        <Title 
                            title='-'
                            margin='auto'
                            size={fontSize}
                            color={darkBlue}
                            textAlign='center'
                        />
                    ) : <></>}
                    {props.maxPrice ? (
                        <HMText 
                            color={orange}
                            fontWeight={500}
                            fontSize={`${fontSize}px`}
                            margin={props.minPrice ? 'auto 0 auto 0' : 'auto 0 auto 0'}
                            text={`${currency} ${props.maxPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    ) : <></>}
                </HMBox>
            ) : (
                <Title 
                    color={orange}
                    size={fontSize}
                    textAlign='left'
                    margin='5px 5px 10px 5px'
                    title={`${currency} ${props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
            )} 
            {(props.addToCart && user && Object.keys(user).length) ? (
                <HMBox
                    right={0}
                    bottom={0}
                    zIndex={999}
                    display='flex'
                    position='absolute'
                >
                    <Tooltip 
                      arrow
                      placement='top' 
                      title='Add To Cart' 
                      sx={{ margin: 'auto' }}
                    >
                        <IconButton   
                            sx={{margin: 'auto'}}
                            onClick={props.handleAddToCart} 
                        >
                            <AddShoppingCartTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                </HMBox>
            ) : <></>}
        </HMBox>
    )
}

export default HMProductCard
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Grid,
  Typography,
  TextareaAutosize
} from '@mui/material'
import { toast } from 'react-toastify'
import { 
  getInsurers,
  resetInsurer, 
  createInsurer, 
} from '../../../features/insurers/insurerSlice'
import { 
  resetProduct, 
  getActiveProducts, 
} from '../../../features/products/productSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMSelectMultiple from '../../../components/common/tools/HMSelectMultiple'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMButton from '../../../components/common/tools/HMButton'
import HMText from '../../../components/common/tools/HMText'
import {
  textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  insurerTypes,
  insurerOrigins,
} from '../../../hooks/helperFunctions'
import {
  white,
  green,
  lightBlue
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'

function CreateInsurer() {
  const [insurerLogoUrl, setInsurerLogoUrl] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productState = useSelector(state => state.product)
  const { activeProducts } = productState
  const insurerState = useSelector(state => state.insurer)

  // const {isUploaded, isUploading, isUploadError, file} = useSelector((state) => state.cloudinary)

  const breadOptions = [
    { text: 'Insurers', link: '/admin/insurers' },
    { text: 'Add an insurer', link: null }
  ]

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false

  const [productsWithInsurancePrice, setProductsWithInsurancePrice] = useState([])
  const [isFileUploading, setFileUploading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    logo: '',
    origin: '',
    description: '',
    insuringProducts: [],
    needsFingerPrints: false,
  })
  const { 
    name,
    type,
    logo,
    origin,
    description,
    insuringProducts,
    needsFingerPrints,
  } = formData

  // useEffect(() =>{
  //   if(isUploading) setFileUploading(true)
  // }, [isUploading])

  useEffect(()=>{
    if(insurerLogoUrl) setFileUploading(false)
  },[insurerLogoUrl])

  useEffect(() => {
    dispatch(getActiveProducts())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (insurerState.isError || insurerState.isCreated || insurerState.isSuccess) {
      dispatch(resetInsurer())
    }

    if (productState.areActiveProducts) {
      dispatch(resetProduct())
    }

    if (insurerState.isError) {
      toast.error(insurerState.message) 
    } else if (insurerState.isCreated) {
      toast.success('Insurer created successfully')
      dispatch(getInsurers())

      Object.keys(insurerState.insurer).length && navigate(`/admin/insurers/${insurerState.insurer._id}`)
    }
    // eslint-disable-next-line
  }, [productState, insurerState])

  useEffect(() => {
    if (activeProducts.length) {
      const tempProductsWithInsurancePrice = []
      activeProducts.forEach((product) => {
        if (product['insurancePrice'] && product.published) {
          tempProductsWithInsurancePrice.push({
            id: product._id,
            name: product.name,
          })
        }
      })
      setProductsWithInsurancePrice(tempProductsWithInsurancePrice)
    }
  }, [activeProducts])

  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      logo: insurerLogoUrl,
    }))
  },[insurerLogoUrl])

  const handleChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
    }))
  }

  const handleTypeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      type: val
    }))
  }

  const handleOriginChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      origin: val
    }))
  }

  const handleAddInsuringProduct = (selectedList, removedItem) => {
    const productIds = selectedList.map(product => product.id)
    setFormData((prevState) => ({
      ...prevState,
      insuringProducts: productIds
    }))
  }
  const handleRemoveInsuringProduct = (selectedList, selectedItem) => {
    const productIds = selectedList.map(product => product.id)
    setFormData((prevState) => ({
      ...prevState,
      insuringProducts: productIds
    }))
  }

  const fingerprint = {
    question: 'Requires fingerprint?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }


  const disabled = (
    name === '' || isFileUploading || 
    description === '' || 
    (type.name && type.name === '') ||
    (type.origin && type.origin === '') 
  ) ? true : false
    
  const handleCreate = (e) => {
    e.preventDefault()

    const newInsurer = {
      name,
      logo,
      description,
      type: type.name,
      origin: origin.name,
      needsFingerPrints: (needsFingerPrints === true || needsFingerPrints === 'true') ? true : false,
    }

    if (insuringProducts.length) {
      newInsurer['insuringProducts'] = insuringProducts
    }

    dispatch(createInsurer(newInsurer))
  }
  
  return (
    <Page>
      {insurerState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <HMBreadcrumbs 
              options={breadOptions}
            />
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
      </FullWidthContainer>
      <Section
        bgColor={white}
        padding={10}
      >
        <Grid 
          component='form'
          container spacing={2} 
          onSubmit={handleCreate}
        >
          <Grid item xs={12} sm={12}>
            <HMBox
              width='100%'
              display='flex'
              padding='10px'
              margin='30px 0 0 0'
              bgColor={lightBlue}
              flexDirection='column'
              maxWidth={`${isTabletScreen && '100% !important'}`}
            >
              <HMText 
                left='0'
                top='-35px'
                height='30px'
                bgColor={white}
                fontWeight={500}
                width='max-content'
                className='fade-in'
                position='relative'
                text='Upload insurer logo'
                margin='10px 0 -25px 20px'
                padding='5px 10px 0px 10px'
                border={`1px solid ${lightBlue}`}
              />
              <HMDropZone 
               setCloudinaryFileUrl = {setInsurerLogoUrl}
               cloudinaryFileUrl={insurerLogoUrl}
               endPoint = {cloudinary.API_URL_LOGOS}
                bgColor={white}
                className='drag-drop' 
                supportedFiles = {{
                  'image/jpeg': [],
                  'image/jpg': [],
                  'image/png': []
                }}
                allowedSizeInMB={1}
              />
            </HMBox>
          </Grid>
          <Grid item xs={12}>
            <HMTextField 
              margin='0'
              name='name'
              label='Name'
              value={name}
              required={true}
              onChange={handleChange}
              placeholder='Enter insurer name'
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{display : 'flex'}}>
            <HMRadioButton 
              padding='2px 5px'
              flexDirection='row'
              onChange={handleChange}
              name='needsFingerPrints'
              value={needsFingerPrints}
              options={fingerprint.choices}
              label={fingerprint.question}
              labelId='require-prescription-radio-group'
            />
          </Grid>
          <Grid item xs={12} sm={4} className='HM-dropdown'>
            <DropdownFilter 
              type='text'
              field='name'
              value={type}
              required={true}
              label='Insurer type'
              prompt='Select insurer type'
              onChange={handleTypeChange}
              options={insurerTypes.map(type => ({name: type}))}
            />
          </Grid>
          <Grid item xs={12} sm={4} className='HM-dropdown'>
            <DropdownFilter 
              type='text'
              field='name'
              value={origin}
              required={true}
              label='Insurer origin'
              onChange={handleOriginChange}
              prompt='Select insurer origin'
              options={insurerOrigins.map(type => ({name: type}))}
            />
          </Grid>
          <Grid item xs={12} sx={{marginBottom: '3%'}}>
            <HMSelectMultiple 
              placeholder='Select products'
              options={productsWithInsurancePrice}
              handleAdd={handleAddInsuringProduct}
              handleRemove={handleRemoveInsuringProduct}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <HMBox
              margin='0'
              width='100%'
              display='flex'
              padding='10px'
              bgColor={lightBlue}
              flexDirection='column'
              maxWidth={`${isTabletScreen && '100% !important'}`}
            >
              <HMText 
                left='0'
                top='-35px'
                width='135px'
                height='30px'
                bgColor={white}
                fontWeight={500}
                className='fade-in'
                position='relative'
                text='About Insurer'
                margin='10px 0 -25px 20px'
                padding='5px 10px 0px 10px'
                border={`1px solid ${lightBlue}`}
              />
              <TextareaAutosize 
                required={true}
                name='description'
                label='Description'
                value={description}
                onChange={handleChange}
                aria-label='empty textarea'
                placeholder='Additional information about the insurer'
                style={{ 
                  width: '100%', 
                  border: '0px',
                  padding: '10px', 
                  maxWidth: '100%', 
                  margin: '10px auto',
                  borderRadius: '8px'
                }}
              />
            </HMBox>
          </Grid>
          <Grid item xs={12}>
            <HMButton 
              text={<Typography sx={textStyle}>Add insurer</Typography>}
              bgColor={green}
              type='submit'
              width='100%'
              float='right'
              margin='20px 0 5px 0'
              disabled={disabled}
            />
          </Grid> 
        </Grid>
      </Section>
    </Page>
  )
}

export default CreateInsurer
import { configureStore } from '@reduxjs/toolkit'
import meReducer from '../features/me/meSlice'
import logReducer from '../features/log/logSlice'
import posReducer from '../features/pos/posSlice'
import navReducer from '../features/nav/navSlice'
import cartReducer from '../features/cart/cartSlice'
import authReducer from '../features/auth/authSlice'
import faqsReducer from '../features/faqs/faqsSlice'
import userReducer from '../features/users/userSlice'
import orderReducer from '../features/orders/orderSlice'
import storeReducer from '../features/stores/storeSlice'
import searchReducer from '../features/search/searchSlice'
import basketReducer from '../features/basket/basketSlice'
import importReducer from '../features/import/importSlice'
import productReducer from '../features/products/productSlice'
import insurerReducer from '../features/insurers/insurerSlice'
import deliveryReducer from '../features/delivery/deliverySlice'
import locationReducer from '../features/location/locationSlice'
import checkoutReducer from '../features/checkout/checkoutSlice'
import supplierReducer from '../features/suppliers/supplierSlice'
import categoryReducer from '../features/categories/categorySlice'
import cloudinaryReducer from '../features/cloudinary/cloudinarySlice' 
import storeListingReducer from '../features/storeListings/storeListingSlice'
import faqCategoriesReducer from '../features/faqCategories/faqCategorySlice'
import supplierListingReducer from '../features/supplierListings/supplierListingSlice'
import storesWithListingsReducer from '../features/storesWithListings/storesWithListingsSlice'
import suppliersWithListingsReducer from '../features/suppliersWithListings/suppliersWithListingsSlice'
import relationProductsInsurersReducer from '../features/relationProductsInsurers/relationProductsInsurersSlice'
import productsWithStoreListingsReducer from '../features/productsWithStoreListings/productsWithStoreListingsSlice'
import productsWithSupplierListingsReducer from '../features/productsWithSupplierListings/productsWithSupplierListingsSlice'

export const store = configureStore({
  reducer: {
    me: meReducer,
    log: logReducer,
    nav: navReducer,
    pos: posReducer,
    faq: faqsReducer,
    cart: cartReducer,
    user: userReducer,
    auth: authReducer,
    order: orderReducer,
    store: storeReducer,
    search: searchReducer,
    basket: basketReducer,
    import: importReducer,
    insurer: insurerReducer,
    product: productReducer,
    delivery: deliveryReducer,
    checkout: checkoutReducer,
    location: locationReducer,
    category: categoryReducer,
    supplier: supplierReducer,
    cloudinary: cloudinaryReducer,
    storeListing: storeListingReducer,
    faqCategory: faqCategoriesReducer,
    supplierListing: supplierListingReducer,
    storesWithListings: storesWithListingsReducer,
    suppliersWithListings: suppliersWithListingsReducer,
    relationProductsInsurers: relationProductsInsurersReducer,
    productsWithStoreListings: productsWithStoreListingsReducer,
    productsWithSupplierListings: productsWithSupplierListingsReducer,
  },
})

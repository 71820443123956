import axios from 'axios'

const API_URL_LOGS = '/api/v1/logs'
const BASE_USERS_URL = '/api/v1/users'
const API_URL_LOGIN = '/api/v1/users/login'
const API_URL_REGISTER = '/api/v1/users/register'
const API_URL_GMAIL_OAUTH = '/api/v1/users/gmail-oauth'
const API_URL_RESET_PASSWORD = '/api/v1/users/password-reset'

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL_REGISTER, userData)

  if (response.data) {
    localStorage.setItem('userData', JSON.stringify(response.data))
  }

  return response.data
}

// Login a user
const login = async (userData) => {
  const response = await axios.post(API_URL_LOGIN, userData)
  if (response.data) {
    localStorage.setItem('userData', JSON.stringify(response.data))
  }
  return response.data
}

// Gmail Oauth a user
const gmailOauth = async (userData) => {
  const response = await axios.post(API_URL_GMAIL_OAUTH, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Forgot password
const forgotPassword = async (userData) => {
  const response = await axios.post(API_URL_RESET_PASSWORD, userData)

  return response.data
}

// Forgot password
const resetPassword = async (obj) => {
  const { id, token, password } = obj
  const response = await axios.post(`${API_URL_RESET_PASSWORD}/${id}/${token}`, {
    password: password,
  })

  return response.data
}

// OTP Resend
const otpResend = async (userData) => {
  const response = await axios.post(`${BASE_USERS_URL}/otp-resend`, userData)

  const data = response.data 

  return data
}

// OTP Expiration
const getOPTExpiration = async (userData) => {
  const response = await axios.post(`${BASE_USERS_URL}/otp-expiration`, userData)

  const data = response.data 

  return data
}

// Verify OTP
const verifyOtp = async (userData) => {
  const { email } = JSON.parse(localStorage.getItem('userData'))
  const response = await axios.post(`${API_URL_LOGIN}/verify`, {
    ...userData,
    email: email,
  })

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.removeItem('userData')
  }

  return response.data
}

// Logout a user
const logout = async () => {
  const user = JSON.parse(localStorage.getItem('user'))
  localStorage.removeItem('user')

  const config = {
    headers: {
        Authorization: `Bearer ${user.token}`
    }
  }

  await axios.post(API_URL_LOGS, { status: 'logout' }, config)
}

const authService = {
  login,
  logout,
  register,
  verifyOtp,
  otpResend,
  gmailOauth,
  resetPassword,
  forgotPassword,
  getOPTExpiration,
}

export default authService

import React from 'react'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import {
  red
} from '../../../hooks/useColors'

function DeleteIcon({ handleDelete, margin }) {
  const iconStyle = {
    color: red,
    margin: 'auto',
    float: 'right',
    fontSize: '16px',
    marginLeft: '5px',
    cursor: 'pointer',
    marginRight: '5px',
    margin: margin || 'auto',
    '&:hover': {

    },
    '&:active': {
        fontSize: '15px',
        marginRight: '6px'
    }
  }

  return (
    <DeleteForeverTwoToneIcon sx={iconStyle} onClick={handleDelete} />
  )
}

export default DeleteIcon
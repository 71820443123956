import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {  
    useSelector,
    useDispatch
} from 'react-redux'
import { toast} from 'react-toastify'
import { 
    login, 
    reset,
    register,
    gmailOauth 
} from '../../../features/auth/authSlice'
import HMBox from './HMBox'
import HMModal from './HMModal'
import OTPForm from './OTPForm'
import HMLoginForm from './HMLoginForm'
import HMRegisterForm from './HMRegisterForm'
import HMForgotPasswordForm from './HMForgotPasswordForm'
import HMSpiner from '../tools/HMSpiner'
import {
    lightBlue,
    lightBlack,
} from '../../../hooks/useColors'

function HMLoginModal(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        message,
        isError,
        isLoading,
        isGmailOauth,
        isTransition,
    } = useSelector(state => state.auth)
    
    const path = window.location.pathname
    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(true)
    const [isOTP, setIsOTP] = useState(false)
    const [isLogin, setIsLogin] = useState(true)
    const [isRegister, setIsRegister] = useState(false)
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [formDataLogin, setFormDataLogin] = useState({
        email: '',
        password: '',
        rememberMe: false
    })
    const [formDataRegister, setFormDataRegister] = useState({
        email: '',
        lastName: '',
        password: '',
        firstName: '',
        passwordConfirmation: ''
    })
    
    useEffect(() => {
        if (isLogin) {
            setTitle(
                path.includes('admin') ? 'Admin Login'
                    : path.includes('store/') ? 'Store Login'
                    : path.includes('supplier') ? 'Supplier Login'
                    : 'User Login'
            ) 
        } else if (isOTP) {
            setTitle('OTP (One Time Password)')
        } else if (isRegister) {
            setTitle('Register')
        } else if (isForgotPassword) {
            setTitle('Forgot Password')
        }
        // eslint-disable-next-line
    }, [isOTP, isLogin, isRegister, isForgotPassword])
    
    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        
        if (isTransition) {
            setIsOTP(true)
            setIsLogin(false)
            setIsRegister(false)
            setIsForgotPassword(false)
        }
        
        if (isGmailOauth) {
            navigate(
            path.includes('admin') ? '/admin'
            : path.includes('store/') ? '/store'
            : path.includes('supplier') ? 'supplier'
            : '/'
            )
        }
        
        dispatch(reset())
        // eslint-disable-next-line
    }, [isError, isTransition, isGmailOauth])
    
    const handleGoogleLoginSuccess = (response) => {
        dispatch(gmailOauth({ token: response.credential }))
    }
    const handleGoogleLoginError = (error) => {
        console.log('ERROR Login with Gmail' + error)
    }
    
    const handleGoogleRegisterSuccess = (response) => {
        dispatch(gmailOauth({ token: response.credential }))
    }
    const handleGoogleRegisterError = (error) => {
        console.log('ERROR Login with Gmail' + error)
    }
    
    const onChangeLogin = (e) => {
        const name = e.target.name
        const value = e.target.value
        const isChecked = e.target.checked
        
        if (name === 'rememberMe') {
            setFormDataLogin((prevState) => ({
            ...prevState,
            rememberMe: isChecked
            }))
        } else {
            setFormDataLogin((prevState) => ({
            ...prevState,
            [name]: value
            }))
        }
    }
    
    const onSubmitLogin = (e) => {
        e.preventDefault() 
        const userData = {
            email: formDataLogin.email,
            password: formDataLogin.password
        }
        dispatch(login(userData))
    }
    
    const onSubmitRegister = (e) => {
        e.preventDefault() 
        if (formDataRegister.password !== formDataRegister.passwordConfirmation) {
            toast.error('Passwords do not match!')
        } else {
            const userData = {
                email: formDataRegister.email,
                password: formDataRegister.password,
                lastName: formDataRegister.lastName,
                firstName: formDataRegister.firstName,
            }
            dispatch(register(userData))
        }
    }
    const onChangeRegister = (e) => {
        const id = e.target.id
        const value = e.target.value
        setFormDataRegister((prevState) => ({
            ...prevState,
            [id]: value
        }))
    }
    
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <HMModal 
            title={title}
            maxWidth='450px'
            maxHeight='95vh'
            open={props.open || open} 
            colors={{lightBlack, lightBlue}}
            handleClose={props.handleClose || handleClose} 
        >
            {isLoading ? (
                <HMSpiner 
                    size={60}
                    width='100%'
                    zIndex={999}
                    height='35vh'
                    position='absolute'
                    bgColor='transparent'
                    margin='auto auto auto -20px'
                />
            ) : <></>}
            {isLogin ? (
                <HMBox 
                    margin='0'
                    width='100%'
                    display='flex'
                    className='HM-Login'
                    flexDirection='column'
                >
                    <HMLoginForm 
                        path={path}
                        isModalLogin={true}
                        setIsOTP={setIsOTP}
                        setIsLogin={setIsLogin}
                        formData={formDataLogin}
                        onChange={onChangeLogin}
                        onSubmit={onSubmitLogin}
                        setIsRegister={setIsRegister}
                        setIsForgotPassword={setIsForgotPassword}
                        onError={(error) => handleGoogleLoginError(error)}
                        onSuccess={(response) => handleGoogleLoginSuccess(response)}
                    />
                </HMBox>
            ) : isOTP ? (
                <HMBox 
                    margin='0'
                    width='100%'
                    display='flex'
                    className='HM-Login'
                    flexDirection='column'
                >
                    <OTPForm 
                        isModalLogin={true}
                        setIsOTP={setIsOTP}
                        setIsLogin={setIsLogin}
                        setIsRegister={setIsRegister}
                        setIsForgotPassword={setIsForgotPassword}
                    />
                </HMBox>
            ) : isRegister ? (
                <HMBox 
                    margin='0'
                    width='100%'
                    display='flex'
                    className='HM-Login'
                    flexDirection='column'
                >
                    <HMRegisterForm 
                        isModalLogin={true}
                        setIsOTP={setIsOTP}
                        setIsLogin={setIsLogin}
                        formData={formDataRegister}
                        onChange={onChangeRegister}
                        onSubmit={onSubmitRegister}
                        setIsRegister={setIsRegister}
                        setIsForgotPassword={setIsForgotPassword}
                        onError={(error) => handleGoogleRegisterError(error)}
                        onSuccess={(response) => handleGoogleRegisterSuccess(response)}
                    />
                </HMBox>
            ) : isForgotPassword ? (
                <HMBox 
                    margin='0'
                    width='100%'
                    display='flex'
                    className='HM-Login'
                    flexDirection='column'
                >
                    <HMForgotPasswordForm 
                        isModalLogin={true}
                        setIsOTP={setIsOTP}
                        setIsLogin={setIsLogin}
                        setIsRegister={setIsRegister}
                        setIsForgotPassword={setIsForgotPassword}
                    />
                </HMBox>
            ) : <></>}
        </HMModal>
    )
}

export default HMLoginModal
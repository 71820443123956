import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import {
    resetMe,
    updateMe 
} from '../../features/me/meSlice'
import { 
    getAllPublicProducts,
} from '../../features/productsWithStoreListings/productsWithStoreListingsSlice'
import ProductCardSkeleton from'../../components/skeletons/ProductCardSkeleton'
import HMProductCard from '../../components/common/layout/HMProductCard'
import HMStoreCard from '../../components/common/layout/HMStoreCard'
import HMPartners from '../../components/common/layout/HMPartners'
import HMSlider from '../../components/common/layout/HMSlider'
import HMSpiner from '../../components/common/tools/HMSpiner'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMImage from '../../components/common/tools/HMImage'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
    fontSize,
} from '../../components/common/tools/Styles'
import {
    blue,
    white,
    orange,
    darkBlue,
    lightBlue,
    lightOrange,
    skeletonsLightBlue,
    seeThroughLightBlue,
} from '../../hooks/useColors'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import prescriptionIcon from '../../assests/prescription.png'

function Landing() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { 
        me,
        isOne,
        isUpdated,
        isLoading,
    } = useSelector((state) => state.me)
    const {
        visible
    } = useSelector((state) => state.nav)
    const { 
        otherStores,
        favoriteStores
    } = useSelector((state) => state.store)
    const { 
        publicCategories,
    } = useSelector((state) => state.category)
    const { 
        publicProducts,
    } = useSelector((state) => state.productsWithStoreListings)

    const {
        windowW,
    } = useWindowDimensions()

    const isBiggerComputerScreen = windowW < 2100 ? true : false
    const isBigComputerScreen = windowW < 1900 ? true : false
    const isMediumComputerScreen = windowW < 1170 ? true : false
    const isSamllComputerScreen = windowW < 1000 ? true : false
    const isTabletScreen = windowW < 768 ? true : false
    const isSmallTabletScreen = windowW < 670 ? true : false


    const [OTCProducts, setOTCProducts] = useState([])
    const [OTCCaterories, setOTCCaterories] = useState([])
    const [allOTCProducts, setAllOTCProducts] = useState([])

    const [healthProducts, setHealthProducts] = useState([])
    const [allHealthProducts, setAllHealthProducts] = useState([])
    const [healthCareCaterories, setHealthCareCaterories] = useState([])

    const [prescriptionProducts, setPrescriptionProducts] = useState([])
    const [prescriptionCaterories, setPrescriptionCaterories] = useState([])
    const [allPrescriptionProducts, setAllPrescriptionProducts] = useState([])

    
    useEffect(() => {
        dispatch(getAllPublicProducts())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (publicProducts.length && publicCategories.length) {
            const prescriptionCaterories = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('prescription'))
            const prescriptionObjIds = {}
            for (let item of prescriptionCaterories.children) {
                prescriptionObjIds[item._id.toString()] = 1
            }
            setPrescriptionCaterories(prescriptionCaterories.children)

            const remainingProducts = []
            const prescriptionProducts = []

            publicProducts.forEach(data => {
                if (prescriptionObjIds[data.product.category.id._id.toString()]) {
                    prescriptionProducts.push(data)
                } else {
                    remainingProducts.push(data)
                }
            })
            setPrescriptionProducts(prescriptionProducts)
            setAllPrescriptionProducts(prescriptionProducts)


            const octCat = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('otc'))
            const OTCObjIds = {}
            for (let item of octCat.children) {
                OTCObjIds[item._id.toString()] = 1
            }
            setOTCCaterories(octCat.children)

            const OTCProducts = []
            const healthCareProducts = []

            remainingProducts.forEach(data => {
                if (OTCObjIds[data.product.category.id._id.toString()]) {
                    OTCProducts.push(data)
                } else {
                    healthCareProducts.push(data)
                }
            })

            setOTCProducts(OTCProducts)
            setAllOTCProducts(OTCProducts)
            
            const healthCat = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('health'))
            setHealthCareCaterories(healthCat.children)
            setHealthProducts(healthCareProducts)
            setAllHealthProducts(healthCareProducts)
        }
    }, [publicCategories, publicProducts])

    useEffect(() => {
        if (isOne || isUpdated) {
            dispatch(resetMe())
        } 
        // eslint-disable-next-line
    }, [isUpdated, isOne])

    const handleProductClick = (product) => {
        navigate(`/categories/${product.category.id.parent._id}/${product.category.id._id}/${product._id}`)
    }

    const handleChoosedOTCCategory = (e, id) => {
        if (id === null) {
            setPrescriptionProducts(allOTCProducts)
        } else {
            setOTCProducts(allOTCProducts.filter(data => data.product.category.id._id.toString() === id))
        }
        const otcChoices = document.getElementsByClassName('otc-choice')
        for (let i = 0; i < otcChoices.length; i++) {
            otcChoices[i].classList.remove('active')
        } 
        e.target.classList.add('active')
    }
    const handleChoosedHealthCategory = (e, id) => {
        if (id === null) {
            setPrescriptionProducts(allHealthProducts)
        } else {
            setHealthProducts(allHealthProducts.filter(data => data.product.category.id._id.toString() === id))
        }
        const healthChoices = document.getElementsByClassName('health-choice')
        for (let i = 0; i < healthChoices.length; i++) {
            healthChoices[i].classList.remove('active')
        } 
        e.target.classList.add('active')
    }
    const handleChoosedPrescriptionCategory = (e, id) => {
        if (id === null) {
            setPrescriptionProducts(allPrescriptionProducts)
        } else {
            setPrescriptionProducts(allPrescriptionProducts.filter(data => data.product.category.id._id.toString() === id))
        }
        const prescriptionChoices = document.getElementsByClassName('prescription-choice')
        for (let i = 0; i < prescriptionChoices.length; i++) {
            prescriptionChoices[i].classList.remove('active')
        } 
        e.target.classList.add('active')
    }

    const handleStoreClick = (storeId) => {
        navigate(`/stores/${storeId}`)
    }
    const handleFavorite = (e, storeId) => {
        e.stopPropagation()
        const updatedUser = {}
        const favoriteStoreIds = me.favoriteStores.length ? me.favoriteStores.map(a => a.toString()) : []
        if (favoriteStoreIds.includes(storeId)) {
          const tempFavoriteStores = favoriteStoreIds.filter(id => id !== storeId)
          updatedUser['favoriteStores'] = tempFavoriteStores
        } else {
          favoriteStoreIds.push(storeId)
          updatedUser['favoriteStores'] = favoriteStoreIds
        }
        dispatch(updateMe(updatedUser))
    }

    const navigateToMainCategory = (mainCategory) => {
        const mainCat = publicCategories.find(cat => cat.name.toLowerCase().includes(mainCategory))
        if (mainCat) {
            navigate(`/categories/${mainCat._id}`)
        }
    }

    const placeOrderWithPrescription = () => {
        navigate('/buy-with-prescription')
    }
    
    return (
        <Page>
            {isLoading ? (
                <HMSpiner 
                size={60}
                zIndex={999}
                width='100%'
                height='80vh'
                margin='auto'
                position='absolute'
                bgColor='transparent'
                />
            ) : <></>}

            <Section
                height='60vh'
                className='landing top-section'
            >
                <Grid 
                    container 
                    spacing={0}
                    sx={{ height: 'inherit' }}
                >
                    <Grid item xs={12} sm={12} sx={{ className: 'fade-in', height: 'inherit' }}>
                        <HMBox
                            width='100%'
                            height='100%'
                            display='flex'
                            padding='0 0 0 5vw'
                            flexDirection='column'
                        >
                            <Title 
                                color={blue}
                                textAlign='left'
                                padding='5px 10px'
                                borderRadius='8px'
                                width='max-content'
                                margin='10vh 0 0 0'
                                bgColor={seeThroughLightBlue}
                                size={isTabletScreen ? 14 : 16}
                                title='Fast Delivery Within Kigali City'
                            />
                            <Typography
                                component='h1'
                                sx={{
                                    lineHeight: 1.3,
                                    fontWeight: 700,
                                    color: darkBlue,
                                    margin: 'auto 0',
                                    borderRadius: '8px',
                                    padding: '5px 10px',
                                    width: 'max-content',
                                    backgroundColor: seeThroughLightBlue,
                                    fontSize: isTabletScreen ? '25px' : '30px',
                                }}
                            >
                                Essential Health Care Products <br />
                                Are Delivered To Your <br />
                                Doorsteps.
                            </Typography>
                            <Title 
                                color={orange}
                                textAlign='left'
                                padding='5px 10px'
                                borderRadius='8px'
                                margin='0 0 15vh 0'
                                width='max-content'
                                bgColor={seeThroughLightBlue}
                                size={isTabletScreen ? 14 : 16}
                                title='With HarakaMeds Your Meds Are One Click Away.'
                            />
                            <HMButton 
                                bottom='0'
                                color={white}
                                bgColor={blue}
                                borderRadius={25}
                                width='max-content'
                                position='absolute'
                                padding='10px 20px' 
                                hoverBgColor={orange}    
                                paddingActive='9.5px 19.5px'
                                fontSize={`${fontSize + 2}px`}
                                text='Place Order with your Prescription'
                                handleClick={placeOrderWithPrescription}
                                rightIcon={
                                    <HMImage
                                        width='27px'
                                        src={prescriptionIcon}
                                        margin='auto auto auto 10px'
                                    />
                                }
                            />
                        </HMBox>
                    </Grid>
                </Grid>
            </Section>

            {(favoriteStores.length || otherStores.length) ? (                    
                <Section 
                    padding={10}
                    bgColor={white}
                    paddingBottom={30}
                >
                    <HMBox
                        width='100%'
                        display='flex'
                        margin='5px 0 20px 0'
                        flexDirection='column'
                    >
                        <Title 
                            margin='auto 0'
                            textAlign='left'
                            fontSize={`${fontSize}px`}
                            title='Favorite Stores & Nearby Stores'
                        />
                        <Divider color={orange} width='220px' sx={{ borderBottomWidth: '3px' }} />
                    </HMBox>
                    <HMSlider 
                        iconColor={blue}
                        padding='auto 10px'
                        height='max-content'
                    >
                        {favoriteStores.length ? favoriteStores.map((data, index) => (
                            <HMStoreCard
                                key={index}
                                width='300px'
                                margin='auto 5px'
                                isFavorite={true}
                                store={data.store}
                                showAddress={false}
                                handleClick={() => handleStoreClick(data.store._id)}
                                handleFavorite={(e) => handleFavorite(e, data.store._id.toString())}
                            />
                        )) : <></>}
                        {favoriteStores.length < 3 ? otherStores.slice(0, 3).map((data, index) => (
                            <HMStoreCard
                                key={index}
                                width='300px'
                                margin='auto 5px'
                                isFavorite={false}
                                store={data.store}
                                showAddress={false} 
                                handleClick={() => handleStoreClick(data.store._id)}
                                handleFavorite={(e) => handleFavorite(e, data.store._id.toString())} 
                            />
                        )) : <></>}
                    </HMSlider>
                </Section>
            ) : <></>}
            
            <Section 
                padding={10}
                paddingBottom={30}
                bg={`linear-gradient(${white}, 30%, ${skeletonsLightBlue})`}
            >
                <HMBox
                    width='100%'
                    display='flex'
                    margin='5px 0 20px 0'
                    flexDirection='column'
                >
                    <Title 
                        margin='auto 0'
                        textAlign='left'
                        fontSize={`${fontSize}px`}
                        title='OTC (Over The Counter) Medicines'
                    />
                    <Divider color={orange} width='220px' sx={{ borderBottomWidth: '3px' }} />
                </HMBox>
                {OTCCaterories.length ? (
                    <HMSlider 
                        height='40px'
                        iconColor={orange}
                        padding='auto 10px'
                    >
                        <HMText 
                            text='All'
                            color={blue}
                            height='auto'
                            cursor='pointer'
                            margin='auto 5px'
                            padding='1px 10px'
                            hoverColor={white}
                            hoverBgColor={blue}
                            bgColor={lightBlue}
                            activeColor={white}
                            activeBgColor={orange}
                            className='category otc-choice active'
                            handleClick={(e) => handleChoosedOTCCategory(e, null)}
                        />
                        {OTCCaterories.map((category, index) => (
                            <Box key={index} sx={{margin: 'auto'}}>
                                <HMText 
                                    color={blue}
                                    height='auto'
                                    cursor='pointer'
                                    margin='auto 5px'
                                    padding='1px 10px'
                                    hoverColor={white}
                                    hoverBgColor={blue}
                                    bgColor={lightBlue}
                                    activeColor={white}
                                    text={category.name}
                                    activeBgColor={orange}
                                    className='category otc-choice'
                                    handleClick={(e) => handleChoosedOTCCategory(e, category._id)}
                                />
                            </Box>
                        ))}
                    </HMSlider>
                ) : (
                    <HMBox width='100%'>
                        <Skeleton />
                    </HMBox>
                )}
                {OTCProducts.length ? (
                    <Grid container spacing={2}>
                        {OTCProducts.map((data, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <HMProductCard 
                                    className='fade-in'
                                    isStockAvailable={true}
                                    title={data.product.name}
                                    image={data.product.image}
                                    handleClick={() => handleProductClick(data.product)}
                                    price={data.storesAndListings[0].storeListing.onlinePrice}
                                />
                            </Grid>
                        ))}
                        {OTCProducts.length > 12 ? (
                            <Grid item xs={12} sm={12}>
                                <HMButton 
                                    margin='auto'
                                    color={orange}
                                    borderRadius={25}
                                    hoverColor={white}
                                    padding='5px 10px' 
                                    hoverBgColor={blue}    
                                    text='View more...'
                                    bgColor={lightOrange}
                                    borderColor={lightOrange}
                                    paddingActive='4.5px 9.5px'
                                    fontSize={`${fontSize - 2}px`}
                                    handleClick={() => navigateToMainCategory('otc')}
                                />
                            </Grid>
                        ) : <></>}
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {(new Array(6).fill('')).map((item, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <ProductCardSkeleton />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Section>

            <Section 
                padding={10}
                paddingBottom={30}
                bg={`linear-gradient(${white}, 30%, ${skeletonsLightBlue})`}
            >
                <HMBox
                    width='100%'
                    display='flex'
                    margin='5px 0 20px 0'
                    flexDirection='column'
                >
                    <Title 
                        margin='auto 0'
                        textAlign='left'
                        fontSize={`${fontSize}px`}
                        title='Prescription Medicines'
                    />
                    <Divider color={orange} width='140px' sx={{ borderBottomWidth: '3px' }} />
                </HMBox>
                {prescriptionCaterories.length ? (
                    <HMSlider 
                        height='40px'
                        iconColor={orange}
                        padding='auto 10px'
                    >
                        <HMText 
                            text='All'
                            color={blue}
                            height='auto'
                            cursor='pointer'
                            margin='auto 5px'
                            padding='1px 5px'
                            hoverColor={white}
                            hoverBgColor={blue}
                            bgColor={lightBlue}
                            activeColor={white}
                            activeBgColor={orange}
                            className='category prescription-choice active'     
                            handleClick={(e) => handleChoosedPrescriptionCategory(e, null)}
                        />
                        {prescriptionCaterories.map((category, index) => (
                            <Box key={index} sx={{margin: 'auto'}}>
                                <HMText 
                                    color={blue}
                                    height='auto'
                                    cursor='pointer'
                                    margin='auto 5px'
                                    padding='1px 5px'
                                    hoverColor={white}
                                    hoverBgColor={blue}
                                    bgColor={lightBlue}
                                    activeColor={white}
                                    text={category.name}
                                    activeBgColor={orange}
                                    className='category prescription-choice' 
                                    handleClick={(e) => handleChoosedPrescriptionCategory(e, category._id)}
                                />
                            </Box>
                        ))}
                    </HMSlider>
                ) : (
                    <HMBox width='100%'>
                        <Skeleton />
                    </HMBox>
                )}
                {prescriptionProducts.length ? (
                    <Grid container spacing={2}>
                        {prescriptionProducts.slice(0, 12).map((data, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <HMProductCard 
                                    className='fade-in'
                                    isStockAvailable={true}
                                    title={data.product.name}
                                    image={data.product.image}
                                    handleClick={() => handleProductClick(data.product)}
                                    price={data.storesAndListings[0].storeListing.onlinePrice}
                                />
                            </Grid>
                        ))}
                        {prescriptionProducts.length > 12 ? (
                            <Grid item xs={12} sm={12}>
                                <HMButton 
                                    margin='auto'
                                    color={orange}
                                    borderRadius={25}
                                    hoverColor={white}
                                    padding='5px 10px' 
                                    hoverBgColor={blue}    
                                    text='View more...'
                                    bgColor={lightOrange}
                                    borderColor={lightOrange}
                                    paddingActive='4.5px 9.5px'
                                    fontSize={`${fontSize - 2}px`}
                                    handleClick={() => navigateToMainCategory('prescription')}
                                />
                            </Grid>
                        ) : <></>}
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {(new Array(6).fill('')).map((item, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <ProductCardSkeleton />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Section>

            <Section 
                padding={10}
                paddingBottom={30}
                bg={`linear-gradient(${white}, 30%, ${skeletonsLightBlue})`}
            >
                <HMBox
                    width='100%'
                    display='flex'
                    margin='5px 0 20px 0'
                    flexDirection='column'
                >
                    <Title 
                        margin='auto 0'
                        textAlign='left'
                        fontSize={`${fontSize}px`}
                        title='Health Care Products'
                    />
                    <Divider color={orange} width='120px' sx={{ borderBottomWidth: '3px' }} />
                </HMBox>
                {(healthCareCaterories.length) ? (
                    <HMSlider 
                        height='40px'
                        iconColor={orange}
                        padding='auto 10px'
                    >
                        <HMText 
                            text='All'
                            color={blue}
                            height='auto'
                            cursor='pointer'
                            margin='auto 5px'
                            padding='1px 5px'
                            hoverColor={white}
                            hoverBgColor={blue}
                            bgColor={lightBlue}
                            activeColor={white}
                            activeBgColor={orange}
                            className='category health-choice active'
                            handleClick={(e) => handleChoosedHealthCategory(e, null)}
                        />
                        {healthCareCaterories.map((category, index) => (
                            <Box key={index} sx={{margin: 'auto'}}>
                                <HMText 
                                    color={blue}
                                    height='auto'
                                    cursor='pointer'
                                    margin='auto 5px'
                                    padding='1px 5px'
                                    hoverColor={white}
                                    hoverBgColor={blue}
                                    bgColor={lightBlue}
                                    activeColor={white}
                                    text={category.name}
                                    activeBgColor={orange}
                                    className='category health-choice'
                                    handleClick={(e) => handleChoosedHealthCategory(e, category._id)}
                                />
                            </Box>
                        ))}
                    </HMSlider>
                ) : (
                    <HMBox width='100%'>
                        <Skeleton />
                    </HMBox>
                )}
                {healthProducts.length ? (
                    <Grid container spacing={2}>
                        {healthProducts.slice(0, 12).map((data, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <HMProductCard 
                                    className='fade-in'
                                    isStockAvailable={true}
                                    title={data.product.name}
                                    image={data.product.image}
                                    handleClick={() => handleProductClick(data.product)}
                                    price={data.storesAndListings[0].storeListing.onlinePrice}
                                />
                            </Grid>
                        ))}
                        {healthProducts.length > 12 ? (
                            <Grid item xs={12} sm={12}>
                                <HMButton 
                                    margin='auto'
                                    color={orange}
                                    borderRadius={25}
                                    hoverColor={white}
                                    padding='5px 10px' 
                                    hoverBgColor={blue}    
                                    text='View more...'
                                    bgColor={lightOrange}
                                    borderColor={lightOrange}
                                    paddingActive='4.5px 9.5px'
                                    fontSize={`${fontSize - 2}px`}
                                    handleClick={() => navigateToMainCategory('health')}
                                />
                            </Grid>
                        ) : <></>}
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {(new Array(6).fill('')).map((item, index) => (
                            <Grid 
                                item 
                                xs={6} 
                                key={index}
                                sx={{display: 'flex'}}
                                sm={
                                    isSmallTabletScreen ? 4 
                                    : isTabletScreen ? 3  
                                    : (isSamllComputerScreen && visible) ? 4
                                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                    : 1
                                }
                            >
                                <ProductCardSkeleton />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Section>

            <Section 
                padding={10}
                bgColor={white}
                paddingBottom={30}
                overflow='hidden'
            >
                <HMPartners />
            </Section>
        </Page>
    )
}

export default Landing
import React from 'react'
import HMBox from './HMBox'
import HMText from '../tools/HMText'
import {
    fontSize,
} from '../tools/Styles'
import {
    red,
    blue,
    lightRed,
    lightBlack,
    seeThroughLightBlue,
} from '../../../hooks/useColors'

function HMViewAddedInfo(props) {
    const {
        other,
        patientAge,
        firstTimeUse,
        causedConditions,
        isPatientPregnant,
        preExistingConditions,
    } = props.addedInfo
    
    return (
        <HMBox
            width='100%'
            display='flex'
            color={lightBlack}
            flexDirection='column'
        >
            <HMBox
                width='100%'
                display='flex'
                margin='10px auto 5px auto'
            >
                <HMText
                    text="Patient's Age"
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 1}px`} 
                />
                <HMText
                    color={blue}
                    padding='0 10px'
                    fontWeight={500}
                    text={patientAge}
                    margin='auto 0 auto 5px'
                    bgColor={seeThroughLightBlue}
                    fontSize={`${fontSize - 1}px`} 
                />
            </HMBox>
            <HMBox
                width='100%'
                display='flex'
                margin='5px auto'
            >
                <HMText
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 1}px`} 
                    text='Is the patient pregnant?'
                />
                <HMText
                    padding='0 10px'
                    fontWeight={500}
                    margin='auto 0 auto 5px'
                    fontSize={`${fontSize - 1}px`} 
                    color={isPatientPregnant ? blue : red}
                    text={isPatientPregnant ? 'Yes' : 'No'}
                    bgColor={isPatientPregnant ? seeThroughLightBlue : lightRed}
                />
            </HMBox>
            <HMBox
                width='100%'
                display='flex'
                margin='5px auto'
            >
                <HMText
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 1}px`} 
                    text='Is it the first time using this medication?'
                />
                <HMText
                    padding='0 10px'
                    fontWeight={500}
                    margin='auto 0 auto 5px'
                    fontSize={`${fontSize - 1}px`} 
                    color={firstTimeUse ? blue : red}
                    text={firstTimeUse ? 'Yes' : 'No'}
                    bgColor={firstTimeUse ? seeThroughLightBlue : lightRed}
                />
            </HMBox>
            {!firstTimeUse ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='5px auto'
                >
                    <HMText
                        margin='auto auto auto 0'
                        fontSize={`${fontSize + 1}px`} 
                        text='Did this medication cause you any condition such as allergies?'
                    />
                    <HMText
                        padding='0 10px'
                        fontWeight={500}
                        margin='auto 0 auto 5px'
                        fontSize={`${fontSize - 1}px`} 
                        color={causedConditions ? blue : red}
                        text={causedConditions ? 'Yes' : 'No'}
                        bgColor={causedConditions ? seeThroughLightBlue : lightRed}
                    />
                </HMBox>
            ) : <></>}
            <HMBox
                width='100%'
                display='flex'
                margin='5px auto'
            >
                <HMText
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 1}px`} 
                    text='Patient pre-existing conditions'
                />
                <HMText
                    color={blue}
                    padding='0 10px'
                    fontWeight={500}
                    margin='auto 0 auto 5px'
                    bgColor={seeThroughLightBlue}
                    fontSize={`${fontSize - 1}px`} 
                    text={preExistingConditions}
                />
            </HMBox>
            {(preExistingConditions === 'Other' && other.length) ? (
                <HMBox
                    width='100%'
                    display='flex'
                    margin='5px auto'
                >
                    <HMText
                        text='Other'
                        margin='auto auto auto 0'
                        fontSize={`${fontSize + 1}px`} 
                    />
                    <HMText
                        color={blue}
                        padding='0 10px'
                        fontWeight={500}
                        margin='auto 0 auto 5px'
                        text={other}
                        bgColor={seeThroughLightBlue}
                        fontSize={`${fontSize - 1}px`} 
                    />
                </HMBox>
            ) : <></>}
        </HMBox>
    )
}

export default HMViewAddedInfo
import React from 'react'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'

function EditIcon({ handleEdit, margin }) {
    const iconStyle = {
        float: 'right',
        fontSize: '16px',
        cursor: 'pointer',
        marginLeft: '5px',
        marginRight: '5px',
        margin: margin || 'auto',
        '&:hover': {
    
        },
        '&:active': {
            fontSize: '15px',
            marginRight: '5px'
        }
    }

    return (
        <EditTwoToneIcon sx={iconStyle} onClick={ handleEdit } />
    )
}

export default EditIcon
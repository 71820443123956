import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import authService from './authService'

// Get user from local storage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    message: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    transition: null,
    isTransition: false,
    isGmailOauth: false,
    isForgotPassword: false,
    user: user ? user : null,
    forgotPasswordResponse: {}
}

// Register new user
export const register = createAsyncThunk(
    'auth/register',
    async (user, thunkAPI) => {
        try {
            return await authService.register(user)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Login user
export const login = createAsyncThunk(
    'auth/login',
    async (user, thunkAPI) => {
        try {
            return await authService.login(user)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Gmail Oauth a user
export const gmailOauth = createAsyncThunk(
    'auth/gmail',
    async (user, thunkAPI) => {
        try {
            return await authService.gmailOauth(user)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Forgot password
export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (userEmail, thunkAPI) => {
        try {
            return await authService.forgotPassword(userEmail)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Reset password
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (obj, thunkAPI) => {
        try {
            return await authService.resetPassword(obj)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// OTP Resend
export const otpResend = createAsyncThunk(
    'auth/resend',
    async (userData, thunkAPI) => {
        try {
            return await authService.otpResend(userData)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// OTP Expiration
export const getOPTExpiration = createAsyncThunk(
    'auth/expiration',
    async (userData, thunkAPI) => {
        try {
            return await authService.getOPTExpiration(userData)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Verify OTP
export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async (userData, thunkAPI) => {
        try {
            return await authService.verifyOtp(userData)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Log out user 
export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        await authService.logout()
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.message = ''
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.isGmailOauth = false
            state.isTransition = false
            state.isForgotPassword = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isTransition = true 
                state.transition = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isError = true 
                state.isLoading = false
                state.transition = null
                state.isTransition = false
                state.message = action.payload 
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isTransition = true 
                state.transition = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isError = true 
                state.isLoading = false
                state.transition = null
                state.isTransition = false
                state.message = action.payload 
            })
            .addCase(gmailOauth.pending, (state) => {
                state.isLoading = true
            })
            .addCase(gmailOauth.fulfilled, (state, action) => {
                state.isLoading = false
                state.isGmailOauth = true 
                state.user = action.payload
            })
            .addCase(gmailOauth.rejected, (state, action) => {
                state.isError = true 
                state.isLoading = false
                state.isGmailOauth = false
                state.message = action.payload 
            })
            .addCase(forgotPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isForgotPassword = true 
                state.forgotPasswordResponse = action.payload
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.user = null
                state.isError = true 
                state.isLoading = false
                state.message = action.payload 
            })
            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true 
                state.user = null
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.user = null
                state.isError = true 
                state.isLoading = false
                state.message = action.payload 
            })

            .addCase(otpResend.pending, (state) => {
                state.isLoading = true
            })
            .addCase(otpResend.fulfilled, (state, action) => {
                state.isLoading = false
                state.isTransition = true 
                state.transition = action.payload
            })
            .addCase(otpResend.rejected, (state, action) => {
                state.isError = true 
                state.transition = null
                state.isLoading = false
                state.message = action.payload 
            })

            .addCase(getOPTExpiration.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOPTExpiration.fulfilled, (state, action) => {
                state.isLoading = false
                state.isTransition = true 
                state.transition = action.payload
            })
            .addCase(getOPTExpiration.rejected, (state, action) => {
                state.isError = true 
                state.transition = null
                state.isLoading = false
                state.message = action.payload 
            })

            .addCase(verifyOtp.pending, (state) => {
                state.isLoading = true
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.isSuccess = true 
                state.isLoading = false
                state.user = action.payload
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.user = null
                state.isError = true 
                state.isLoading = false
                state.message = action.payload 
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
    }
})

export const { reset } = authSlice.actions
export default authSlice.reducer
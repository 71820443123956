import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { fontSize } from '../../../components/common/tools/Styles'

 const HMDonutChart = (props) => {
    const options = {
      title: {
        text: props.title || 'Users distribution',
        align: 'left',
        offsetX: 20,
        offsetY: -7,
        style: {
          fontSize:  fontSize + 2,
          fontWeight:  '700',
          color:  'rgba(47, 56, 84, 0.7)',
        },
      },
      chart: {
        width: '100%',
        type: 'donut',
        offsetY: 20,
      },
      colors:props.colors || ['#2F385480', '#00AEEF','#F78800','#16D39A'],
      labels: props.labels || ['Admins', 'Customer','Operators','Store & Supplier staffs'],
      legend: {
        position: 'right',
        verticalAlign: 'center',
        //  offsetX: 1,
        offsetY: 40,
      },
      plotOptions: {
        pie: {
          dataLabels: {
              offset: -3,
          }, 
          customScale: 1.1,
          donut: {
            size: props.donutSize || '70%',
            labels: {
              show: true,
              total: {
                show: true,
                fontSize: '13px',
                display: 'none',
              },
              name: {
                show: false,
              },
              value: {
                formatter: function (val) {
                  return '+'+ val + 'K'
                },
              }
            },
          },
        },
      },
      dataLabels: {
        enabled: props.showDataLabels,
      },
      responsive: [
        {
          breakpoint: 180,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    return (
      <ReactApexChart
        width="100%"
        height="100%"
        options={options}
        series={props.data}
        type="donut"
      />
    )
  }

  export default HMDonutChart;
import React from 'react'
import parse from 'html-react-parser'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import WarningIcon from '@mui/icons-material/Warning'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from './HMBox'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize,
    iconStyle,
} from '../tools/Styles'
import {
    currencies,
    getStockAvailability
} from '../../../hooks/helperFunctions'
import {
    red,
    blue,
    white,
    orange,
    darkBlue,
    lightRed,
    getRandomColor,
    skeletonsLightBlue,
    seeThroughLightBlue,
} from '../../../hooks/useColors'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMProduct(props) {
    const {
        name,
        image,
        description
    } = props.product
    const {
        onlinePrice,
        availableStock,
        transitStockCount,
    } = props.listing

    const stockDetail = getStockAvailability(availableStock, transitStockCount)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <HMImage 
                    alt={name}
                    src={image}
                    borderRadius='8px'
                    bgColor={skeletonsLightBlue}
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
                <HMText 
                    text={name}
                    width='100%'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    fontSize={`${fontSize + 2}px`}
                />
                <HMText 
                    width='100%'
                    margin='5px 0'
                    color={orange}
                    fontWeight={500}
                    className='fade-in'
                    fontSize={`${fontSize + 2}px`}
                    text={`${currency} ${onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
                <HMText 
                    width='100%'
                    text='Store:'
                    margin='5px 0'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    fontSize={`${fontSize}px`}
                />
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                    bgColor={seeThroughLightBlue}
                >
                    <HMBox
                        padding='0'
                        display='flex'
                        margin='auto 0'
                    >
                        {props.store.logo ? (
                            <Link 
                                sx={{ margin: 'auto 0', cursor: 'pointer' }}
                                onClick={props.navegateToStorePage} 
                            >
                                <img
                                    alt={props.store.name}
                                    src={props.store.logo}
                                    className='mini-image'
                                    style={{ margin: 'auto' }}
                                />
                            </Link>
                        ) : (
                            <Link 
                                onClick={props.navegateToStorePage} 
                                sx={{ color: darkBlue, margin: 'auto 0', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Grid className='mini-logo'>
                                    <HMText
                                        float='none'
                                        width='100%'
                                        margin='auto'
                                        height='100%'
                                        color={white}
                                        fontWeight={700}
                                        textAlign='center'
                                        padding='0 10px 0 8px'
                                        bgColor={getRandomColor()}
                                        text={props.store.name.substr(0, 1).toUpperCase()}
                                    />
                                </Grid>
                            </Link>
                        )}
                        <HMBox
                            width='100%'
                            margin='auto auto auto 5px'
                        >
                            <Link 
                                onClick={props.navegateToStorePage} 
                                sx={{ color: darkBlue, textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Title
                                    width='100%'
                                    textAlign='left'
                                    margin='2px 0 0 0'
                                    size={fontSize - 2}
                                    title={props.store.name}
                                />
                            </Link>
                            <HMBox
                                padding='0'
                                margin='auto'
                                display='flex'
                            >
                                <HMText
                                    margin='auto'
                                    padding='0 5px'
                                    bgColor={white}
                                    borderRadius={5}
                                    text={props.store.type}
                                    fontSize={`${fontSize - 1}px`}
                                />
                                &nbsp; | &nbsp;
                                <LocationOnOutlinedIcon sx={{...iconStyle, color: red}} />
                                <HMText
                                    margin='auto 0'
                                    text={props.store.address.sector}
                                    fontSize={`${fontSize - 1}px`}
                                />
                                &nbsp; - &nbsp;
                                <HMText
                                    margin='auto 0'
                                    fontSize={`${fontSize - 1}px`}
                                    text={props.store.address.district}
                                />
                            </HMBox>
                        </HMBox>
                    </HMBox>
                    {props.canChangeStore ? (
                        <HMBox
                            padding='0'
                            display='flex'
                            cursor='pointer'
                            className='fade-in'
                            margin='auto 0 auto auto'
                            handleClick={props.handleChangeStoreModal} 
                        >   
                            <HMText 
                                color={blue}
                                text='Change store'
                                width='max-content'
                                fontSize={`${fontSize}px`}
                                margin='10px 10px auto 10px'
                            />
                            <IconButton   
                                sx={{margin: 'auto 0 auto auto'}}
                                onClick={props.handleChangeStoreModal} 
                            >
                                <DriveFileRenameOutlineOutlinedIcon />
                            </IconButton>
                        </HMBox>
                    ) : <></>}
                </HMBox>
                <HMText 
                    width='100%'
                    text='Quantity:'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    margin='20px 0 5px 0'
                    fontSize={`${fontSize}px`}
                />
                <HMBox 
                    padding='5px 0'
                    display='flex'
                    className='fade-in'
                >
                    <IconButton   
                        sx={{margin: 'auto 0'}}
                        onClick={props.handleQuantityReduce} 
                    >
                        <RemoveIcon />
                    </IconButton>
                    <HMTextField 
                        width='70px'
                        type='number'
                        name='quantity'
                        margin='auto 5px'
                        value={props.quantity}
                        className='public-product quantity'
                        onChange={props.handleChangeQuantity}
                    />
                    <IconButton   
                        sx={{margin: 'auto 0'}}
                        onClick={() => props.handleQuantityIncrease(stockDetail.stockCount)} 
                    >
                        <AddIcon />
                    </IconButton>
                    {stockDetail.stockCount === props.quantity ? (
                        <HMBox
                            margin='auto'
                            display='flex'
                            bgColor={lightRed}
                            className='fade-in'
                        >
                            <WarningIcon 
                                sx={{
                                    color: red,
                                    padding: '0',
                                    margin: 'auto 5px auto auto',
                                    fontSize: `${fontSize + 2}px`,
                                }}
                            />
                            <HMText
                                color={red}
                                margin='auto 0'
                                fontWeight={500}
                                borderRadius={5}
                                fontSize={`${fontSize - 1}px`}
                                text='Maximum stock reached! Try another store'
                            />
                        </HMBox>
                    ) : <></>}
                </HMBox>
                <HMBox 
                    width='100%'
                    display='flex'
                    padding='5px 0'
                    className='fade-in'
                    position='relative'
                    margin='auto auto 0 auto'
                >
                    <HMButton 
                        width='50%'
                        color={white}
                        bgColor={blue}
                        text='Add to cart'
                        handleClick={props.handleAddProductToCart}
                    />
                    <HMButton 
                        width='50%'
                        color={white}
                        text='Buy now'
                        bgColor={orange}
                        handleClick={props.handleBuyNow}
                    />
                </HMBox>
            </Grid>
            <Grid item xs={12} sm={12}>
                <HMText 
                    width='100%'
                    fontWeight={500}
                    color={darkBlue}
                    margin='10px 0 20px 0'
                    text='Product Details'
                    fontSize={`${fontSize + 2}px`}
                />
                <Box className='product-details' sx={{ marginBottom: '20px', fontSize: `${fontSize}px`}}>
                    {parse(description)}
                </Box>
            </Grid>
        </Grid>
    )
}

export default HMProduct
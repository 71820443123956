import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
    getOrders,
    resetOrder,
} from '../../../features/orders/orderSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import DataTable from '../../../components/common/layout/DataTable'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMButton from '../../../components/common/tools/HMButton'
import Title from '../../../components/common/tools/Title'
import {
    iconStyle,
    textStyle,
} from '../../../components/common/tools/Styles'
import {
    white,
    orange,
    lightBlue,
} from '../../../hooks/useColors'

function Orders({ socket }) {
    const dispatch = useDispatch()
    const navigate = useNavigate() 
    const { user } = useSelector((state) => state.auth)
    const {
        orders,
        areOrders,
        isLoading,
    } = useSelector((state) => state.order)

    const [allOrders, setAllOrders] = useState([])
    const [doneSetting, setDoneSetting] = useState(false)

    useEffect(() => {
        if (user && user.accessLevel <= 5) {
            dispatch(getOrders()) 
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on('new_order', () => {
            if (user && user.accessLevel <= 5) {
                dispatch(getOrders()) 
            }
        })
    }, [socket])

    useEffect(() => {
        if (areOrders) {
            const tempOrders = []
            dispatch(resetOrder())
            orders.forEach(order => {
                const tempSale = {
                    _id: order._id,
                    date: order.createdAt,
                    total: order.orderTotal,
                    orderNumber: order.orderNumber,
                    paymentMethod: order.paymentInfo.method,
                    status: order['orderStatus'] || 'Pending',
                    client: `${order.user.firstName} ${order.user.lastName}`,
                    paymentStatus: order.paymentInfo.paid ? 'Paid' : 'Unpaid',
                }

                tempOrders.push(tempSale)
            })
            setDoneSetting(true)
            setAllOrders(tempOrders)
        }
        // eslint-disable-next-line
    }, [areOrders])

    const fields = []
    const columns = [
        { field: 'orderNumber', headerName: 'Sales Number', sortable: true },
        { field: 'date', headerName: 'Sale Date', sortable: false },
        { field: 'client', headerName: 'Client', sortable: true },
        { field: 'total', headerName: 'Order Total', sortable: true },
        { field: 'paymentMethod', headerName: 'Pay Method', sortable: true },
        { field: 'paymentStatus', headerName: 'Pay Status', sortable: true },
        { field: 'status', headerName: 'Status', sortable: true },
    ]

    columns.forEach((column) => {
        fields.push(column.field)
    })

    return user ? (
        <Page>
            {(isLoading || !doneSetting) ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <FullWidthContainer
                display='flex'
            >
                <Title 
                    title='Orders' 
                    margin='auto auto auto 0'
                />
                <HMBox 
                    padding='0'
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={orange}
                        isResponsive={true}
                        margin='auto 0 auto auto'
                        handleClick={() => navigate(-1)}
                        text={<Typography sx={textStyle}>Back</Typography>} 
                        icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    />
                </HMBox>
            </FullWidthContainer>
            <Section 
                padding={10} 
                bgColor={white}
            >
                {doneSetting ? (
                    <DataTable
                        title='Orders'
                        addStyle={true}
                        viewOnly={true}
                        fields={fields}
                        data={allOrders}
                        columns={columns}
                        disableEdit={true}
                        defaultRowsNum={20}
                        searchBgColor={lightBlue}
                        emptyDataMessage='No orders available!'
                    />
                ) : <></>}
            </Section>
        </Page>
    ) : (
        <Section 
            padding={10} 
            bgColor={white}
        >
            <HMLoginFirst 
                message='You need to login first before accessing admin content'
            />
        </Section>
    )
}

export default Orders
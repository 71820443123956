import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import {
    getMe,
    resetMe,
    updateMe
} from '../../features/me/meSlice'
import { 
    resetOrder,
    createOrder, 
} from '../../features/orders/orderSlice'
import { 
    resetRelationProductsInsurers,
    getOnlyAllInsurersWithProducts,
} from '../../features/relationProductsInsurers/relationProductsInsurersSlice'
import PaymentMethodDetails from '../../components/checkout/PaymentMethodDetails'
import HMInsuranceCardForm from '../../components/checkout/HMInsuranceCardForm'
import EditDeliveryInfo from '../../components/checkout/EditDeliveryInfo'
import AddressDetails from '../../components/checkout/AddressDetails'
import BuyWithPrescriptionStepper from '../../components/common/layout/BuyWithPrescriptionStepper'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import HMModal from '../../components/common/layout/HMModal'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMRadioButton from '../../components/common/tools/HMRadioButton'
import HMDropZone from '../../components/common/tools/HMDropZone'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMImage from '../../components/common/tools/HMImage'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
    fontSize,
    iconStyle,
    textStyle,
    deleteTextStyle,
} from '../../components/common/tools/Styles'
import {
    red,
    gray,
    blue,
    green,
    white,
    orange,
    darkBlue,
    lightBlue,
    lightBlack,
    lightGreen,
    lightWarning,
    getRandomColor,
    skeletonsLightBlue,
    seeThroughLightBlue,
} from '../../hooks/useColors'
import { 
    cloudinary,
    storeDelivery,
    validatePhoneNumber,
    paymentMethodDetails 
} from '../../hooks/helperFunctions' 
import useWindowDimensions from '../../hooks/useWindowDimensions'
import prescriptionIcon from '../../assests/prescription.png'

const deliveryInfoSekeleton = {
    phone: '',
    mapAddress: '',
    default: false,
    category: 'Home',
    recipientName: '',
    coordinates: {
      lng: null,
      lat: null,
    }
}
const orderSkeleton = {
    orderTotal: 0,
    prescription: '',
    orderDetails: [],
    copaymentInfo: null,
    insuranceInfo: null,
    deliveryInfo: deliveryInfoSekeleton,
    paymentInfo: {
        paid: false,
        method: null,
        paymentStatus: null,
    }, 
}
const insuranceInfoSekeleton = {
    save: true,
    insurer: '',
    cardImage: '',
    default: false,
    nameOnCard: '',
    cardNumber: '',
}

function ByWithPrescription() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { 
        me,
        isOne,
        isUpdated,
        isLoading,
    } = useSelector((state) => state.me)
    const { 
        user,
    } = useSelector((state) => state.auth)
    const orderState = useSelector((state) => state.order)
    const {
        onlyInsurersWithProducts,
        areOnlyInsurersWithProducts
    } = useSelector(state => state.relationProductsInsurers)

    const {
        windowW,
        windowH
    } = useWindowDimensions()

    const isTabletScreen = windowW < 768 ? true : false
    const isBigHeightScreen = windowH < 1000 ? true : false
    const isNormalHeightScreen = windowH < 650 ? true : false
    const isSmallHeightScreen = windowH < 400 ? true : false
    const isVerySmallHeightScreen = windowH < 300 ? true : false
    
    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false) 
    const [infoTop, setInfoTop] = useState(0)
    const [insurers, setInsurers] = useState([]) 
    const [paymethod, setPaymethod] = useState('COD')
    const [order, setOrder] = useState(orderSkeleton)
    const [cardImageUrl, setCardImageUrl] = useState('')
    const [wrongNumber, setWrongNumber] = useState(false)
    const [isAddInsurer, setIsAddInsurer] = useState(false)
    const [isAddAddress, setIsAddAddress] = useState(false)
    const [isEditAddress, setIsEditAddress] = useState(false)
    const [isEditInsurer, setIsEditInsurer] = useState(false)
    const [specificInsurer, setSpecificInsurer] = useState(null)
    const [isSelectAddress, setIsSelectAddress] = useState(false)
    const [isDeleteAddress, setIsDeleteAddress] = useState(false)
    const [chosenAddressInfo, setChosenAddressInfo] = useState({})
    const [chosenInsuranceInfo, setChosenInsuranceInfo] = useState({})
    const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
    const [currentAddressIndex, setCurrentAddressIndex] = useState(null)
    const [isAddSpecificInsurer, setIsAddSpecificInsurer] = useState(false)
    const [currentInsuranceIndex, setCurrentInsuranceIndex] = useState(false)
    const [insurerFormData, setInsurerFormData] = useState(insuranceInfoSekeleton)
    
    const {
        paymentInfo,
        prescription,
        deliveryInfo,
    } = order
    
    useEffect(() => {
        if (user) {
            dispatch(getMe())
            dispatch(getOnlyAllInsurersWithProducts())
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        setOrder({
            ...order,
            prescription: prescriptionFileUrl
        })

        if (cardImageUrl) {
            setInsurerFormData({
                ...insurerFormData,
                cardImage: cardImageUrl
            })
        }
        // eslint-disable-next-line
    }, [prescriptionFileUrl, cardImageUrl])

    useEffect(() => {
        if (paymethod) {
            setOrder({
                ...order,
                paymentInfo: {
                    ...paymentInfo,
                    method: paymentMethodDetails.find(item => item.type === paymethod).title
                }
            })
        }
        // eslint-disable-next-line
    }, [paymethod])

    useEffect(() => {
        if (isOne) {
            if (me.insuranceInfo && me.insuranceInfo.length) {
                for (let i = 0; i < me.insuranceInfo.length; i++) {
                    if (me.insuranceInfo[i].default) {
                        setChosenInsuranceInfo(me.insuranceInfo[i])
                        break
                    }
                }
            }
        } 
        
        if (isOne || isUpdated) {
            if (me.deliveryInfo && me.deliveryInfo.length) {
                if (me.deliveryInfo.length === 1) {
                  setChosenAddressInfo(me.deliveryInfo[0])
                } else {
                  setChosenAddressInfo(me.deliveryInfo.find(data => data.default))
                } 
              } else {
                setChosenAddressInfo({})
              }
            dispatch(resetMe())
        } 

        if (areOnlyInsurersWithProducts) {
            dispatch(resetRelationProductsInsurers())
            const tempInsurers = [ ...onlyInsurersWithProducts ]
            setInsurers(tempInsurers.sort((a, b) => {
                const nameA = a.name.toLowerCase()
                const nameB = b.name.toLowerCase()
                
                if (nameA < nameB) {
                  return -1
                }
                
                if (nameA > nameB) {
                  return 1
                }
                
                return 0
              }))
        }

        if (orderState.isOrderCreated) {
            dispatch(resetOrder())
            navigate(`/myorders/${orderState.myOrder._id.toString()}`)
        }
        // eslint-disable-next-line
    }, [isOne, isUpdated, areOnlyInsurersWithProducts, orderState])

    const breadOptions = [
        { text: 'Home', link: '/landing' },
        { text: 'By With Prescription', link: null },
    ]
    
    const handleSelectInsuranceInfo = (insuranceData) => {
        setChosenInsuranceInfo(insuranceData)
    }
    const handleEditInsurerModal = (e, insuranceData, insurer, insuranceIndex) => {
        e.stopPropagation()
        setOpen(true)
        setIsEditInsurer(true)
        setIsAddInsurer(false)
        setIsAddSpecificInsurer(false)
        setTitle(`Edit - ${insurer.name}`)
        setCurrentInsuranceIndex(insuranceIndex)
        setCardImageUrl(insuranceData.cardImage)
        setInsurerFormData({
          ...insuranceData,
          save: insurerFormData.save,
        })
    }

    const handleAddInsurerModal = () => {
        setOpen(true)
        setIsAddInsurer(true)
        setIsEditInsurer(false)
        setIsAddSpecificInsurer(false)
        setTitle('Add Insurance Information')
    }
    const handleSaveInsurer = () => {
        setInsurerFormData((prevState) => ({
          ...prevState,
          save: !insurerFormData.save,
        }))
    }
    const handleInsurerDataChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setInsurerFormData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleEditInsurerConfirm = () => {
      let updatedInsuranceData = {
        default: insurerFormData.default,
        cardImage: insurerFormData.cardImage,
        cardNumber: insurerFormData.cardNumber,
        nameOnCard: insurerFormData.nameOnCard,
      }
      const updatedInsuranceInfo = me.insuranceInfo.map((data, index) => {
        if (index === currentInsuranceIndex) {
          updatedInsuranceData = {
            ...updatedInsuranceData,
            insurer: data.insurer,
          }
          return updatedInsuranceData
        } else {
          if (insurerFormData.default) {
            return {
              ...data,
              default: false
            }
          } else {
            return data
          }
        }
      })
      const updatedMe = {
        insuranceInfo: updatedInsuranceInfo
      }
      dispatch(updateMe(updatedMe))
      
      setChosenInsuranceInfo(updatedInsuranceData)
      
      handleClose()
    }
    const handleAddInsurerConfirm = () => {
        let updatedInsuranceInfo = []
        const newInsuranceData = {
          insurer: specificInsurer._id,
          default: insurerFormData.default,
          cardImage: insurerFormData.cardImage,
          cardNumber: insurerFormData.cardNumber,
          nameOnCard: insurerFormData.nameOnCard,
        }
        if (me.insuranceInfo && me.insuranceInfo.length) {
          if (newInsuranceData.default) {
            updatedInsuranceInfo = me.insuranceInfo.map(data => ({
              ...data,
              default: false,
            }))
          } else {
            updatedInsuranceInfo = me.insuranceInfo.map(data => data)
          }
        } 
        updatedInsuranceInfo.push(newInsuranceData)
    
        const updatedMe = {
          insuranceInfo: updatedInsuranceInfo
        }
    
        if (insurerFormData.save) {
          dispatch(updateMe(updatedMe))
        }
    
        setChosenInsuranceInfo({
          ...newInsuranceData,
          insurer: specificInsurer
        })
        
        handleClose()
    }
    const handleAddSpecificInsurerModal = (insurer) => {
      setOpen(true)
      setCardImageUrl('')
      setTitle(insurer.name)
      setIsAddInsurer(false)
      setSpecificInsurer(insurer)
      setIsAddSpecificInsurer(true)
      setInsurerFormData(insuranceInfoSekeleton)
    }

    const handleChangeMapAddress = (address) => {
        setOrder((prevState) => ({
          ...prevState,
          deliveryInfo: {
            ...deliveryInfo,
            mapAddress: address
          }
        }))
    }

    const handleSelectMapAddress = async (address) => {
        const results = await geocodeByAddress(address)
        const ll = await getLatLng(results[0])
        setOrder((prevState) => ({
            ...prevState,
            deliveryInfo: {
              ...deliveryInfo,
              coordinates: ll,
              mapAddress: address
            }
        }))
    }

    const handleChange = (e) => {
        let value = e.target.value
        const name = e.target.name

        if (name === 'paymethod') {
            setPaymethod(value)
        } else if (name === 'phone' && validatePhoneNumber(value) !== null) {
            const {
                isValid,
                phoneNumber
            } = validatePhoneNumber(value)

            if (!isValid && phoneNumber.length) {
                e.target.classList.add('wrong-input')
                setWrongNumber(true)
            } else if (isValid || !phoneNumber.length) {
                e.target.classList.remove('wrong-input')
                setWrongNumber(false)
            } 

            setOrder((prevState) => ({
                ...prevState,
                deliveryInfo: {
                  ...deliveryInfo,
                  [name]: phoneNumber
                }
            }))
        } else {
            setOrder((prevState) => ({
                ...prevState,
                deliveryInfo: {
                  ...deliveryInfo,
                  [name]: value
                }
            }))
        }
    }

    const handleEditAddressModal = (e, addressInfo, addressIndex) => {
        e.stopPropagation()
        setOpen(true)
        setIsEditAddress(true)
        setIsSelectAddress(false)
        setTitle('Edit Delivery Address')
        setCurrentAddressIndex(addressIndex)
        setOrder({
            ...order,
            deliveryInfo: addressInfo
        })
    }
    const handleEditAddressSave = () => {
        const updatedDeliveryInfo = me.deliveryInfo.map((data, index) => {
          if (index === currentAddressIndex) {
            return deliveryInfo
          } else {
            if (deliveryInfo.default) {
              return {
                ...data,
                default: false
              }
            } else {
              return data
            }
          }
        })
        const updatedMe = {
          deliveryInfo: updatedDeliveryInfo
        }
        dispatch(updateMe(updatedMe))
        handleSelectAddressModal()
    }
    
    const handleDeleteAddressModal = (e, addressInfo, addressIndex) => {
        e.stopPropagation()
        setOpen(true)
        setIsAddAddress(false)
        setIsEditAddress(false)
        setIsDeleteAddress(true)
        setIsSelectAddress(false)
        setTitle('Delete Delivery Address')
        setCurrentAddressIndex(addressIndex)
        setOrder({
            ...order,
            deliveryInfo: addressInfo
        })
    }
    const handleDeleteAddress = () => {
        const updatedDeliveryInfo = me.deliveryInfo.filter(addressInfo => addressInfo._id !== deliveryInfo._id)
    
        if (deliveryInfo.default && updatedDeliveryInfo.length) {
          updatedDeliveryInfo[0] = {
            ...updatedDeliveryInfo[0],
            default: true
          }
        }
        
        const updatedMe = {
          deliveryInfo: updatedDeliveryInfo
        }
    
        dispatch(updateMe(updatedMe))
    
        if (updatedDeliveryInfo.length) {
          handleSelectAddressModal()
        } else {
          handleClose()
        }
    }
    
    const handleSelectAddressModal = () => {
        setOpen(true)
        setIsEditAddress(false)
        setIsSelectAddress(true)
        setTitle('Select Delivery Address')
        setOrder({
            ...order,
            deliveryInfo: deliveryInfoSekeleton
        })
    }
    const handleAddAddressModal = () => {
        setOpen(true)
        setIsAddAddress(true)
        setIsEditAddress(false)
        setIsSelectAddress(false)
        setTitle('Add Delivery Address')
        setOrder({
            ...order,
            deliveryInfo: deliveryInfoSekeleton
        })
    }
    const handleAddAddressSave = () => {
        let updatedDeliveryInfo = []
        let isNewAddressInfo = false
        if (me.deliveryInfo && me.deliveryInfo.length) {
          if (deliveryInfo.default) {
            updatedDeliveryInfo = me.deliveryInfo.map(data => ({
              ...data,
              default: false,
            }))
          } else {
            updatedDeliveryInfo = me.deliveryInfo.map(data => data)
          }
          isNewAddressInfo = false
          updatedDeliveryInfo.push(deliveryInfo)
        } else {
          isNewAddressInfo = true
          updatedDeliveryInfo.push({
            ...deliveryInfo,
            default: true,
          })
        }
    
        const updatedMe = {
          deliveryInfo: updatedDeliveryInfo
        }
        dispatch(updateMe(updatedMe))
        
        if (isNewAddressInfo) {
          handleClose()
        } else {
          handleSelectAddressModal()
        }
    }

    const handleChooseAddress = (addressInfo) => {
        setChosenAddressInfo(addressInfo)
        handleClose()
    }

    const handleClose = () => {
        setOpen(false)
        setCardImageUrl('')
        setIsAddInsurer(false)
        setIsAddAddress(false)
        setIsEditInsurer(false)
        setIsEditAddress(false)
        setIsDeleteAddress(false)
        setIsSelectAddress(false)
        setIsAddSpecificInsurer(false)
    }

    const supportedFiles = {
        'image/png': [],
        'image/jpg': [],
        'image/jpeg': [],
        'application/pdf': [],
    }

    const paymentMethodData = {
        question: 'How would you like to pay?',
        choices: paymentMethodDetails.map(item => ({
            label: (
                <HMBox
                    width='100%'
                    margin='auto'
                    display='flex'
                    minWidth='300px'
                >
                    <HMImage
                        src={item.icon}
                        borderRadius={8}
                        width={item.size.width}
                        margin='auto 5px auto 0'
                        height={item.size.height}
                    />
                    <HMText 
                        text={item.title}
                        margin='auto auto auto 5px'
                    />
                </HMBox>
            ), 
            value: item.type
        }))
    }
    
    const disableStepOne = !prescription ? true : false
    const disableStepTwo = !paymentInfo.method ? true : false
    const disableLastStep = !Object.keys(chosenAddressInfo).length ? true : false
    const disableInsuranceStep = !Object.keys(chosenInsuranceInfo).length ? true : false

    const disableSubmit = (disableStepOne || disableStepTwo || disableInsuranceStep || disableLastStep) ? true : false  

    const steps = [
        {
            label: 'Upload your prescription',
            description: (
                <HMBox 
                    padding='0'
                    width='100%'
                    margin='10px 0'
                    maxWidth='500px'
                >
                    <HMDropZone 
                        allowedSizeInMB={1}
                        className='drag-drop' 
                        supportedFiles={supportedFiles} 
                        cloudinaryFileUrl={prescriptionFileUrl}
                        endPoint={cloudinary.API_URL_PRESCRIPTION}
                        setCloudinaryFileUrl={setPrescriptionFileUrl}
                    />
                </HMBox>
            ),
        },
        {
            label: 'Payment Information',
            description: (
                <HMBox 
                    padding='0'
                    width='100%'
                    margin='10px 0'
                >
                    <HMRadioButton 
                        margin='auto 0'
                        bgColor={white}
                        name='paymethod'
                        padding='2px 5px'
                        value={paymethod}
                        radioWidth='200px'
                        className='fade-in'
                        labelMargin='auto 0'
                        radioMargin='auto 0'
                        onChange={handleChange}
                        labelWidth='max-content'
                        fontSize={`${fontSize}px`}
                        label={paymentMethodData.question}
                        options={paymentMethodData.choices}
                        labelId='ask-prescription-radio-group'
                    />
                </HMBox>
            ),
        },
        {
            label: 'Delivery Information',
            description: (
                <HMBox
                    padding='0'
                    width='100%'
                    maxWidth='550px'
                >
                    {Object.keys(chosenAddressInfo).length ? (
                        <AddressDetails 
                            bgColor={skeletonsLightBlue}
                            isTabletScreen={isTabletScreen}
                            addressInfo={chosenAddressInfo}
                            handleChangeClick={handleSelectAddressModal}
                        />
                    ) : (!isLoading && ((('deliveryInfo' in me) && !me.deliveryInfo.length) || !('deliveryInfo' in me))) ? (
                        <HMBox
                            width='100%'
                            display='flex'
                            className='fade-in'
                            margin='auto auto 10px auto'
                        >
                            <HMButton 
                                width='100%'
                                type='button'
                                color={blue}
                                margin='auto'
                                padding='10px'
                                bgColor='inherit'
                                borderRadius={10}
                                paddingActive='10px'
                                border={`1px solid ${blue}`}
                                handleClick={handleAddAddressModal}
                                icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                                text={
                                    <Typography  ypography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                                        Add delivery address
                                    </Typography>
                                } 
                            />
                        </HMBox>
                    ) : <></>}
                </HMBox>
            ),
        }
    ]

    const insuranceInfoStep = {
        label: 'Health Insurance Information',
        description: (
            <HMBox
                width='100%'
                display='flex'
                maxWidth='500px'
                className='fade-in'
                flexDirection='column'
            >
                <HMAlert 
                    warning={true}
                    margin='0 0 10px 0'
                    bgColor={lightWarning}
                    fontSize={fontSize - 2}
                    text='Note that your insurance is not applicable until it is validated by a Pharmacist!.'
                />
                <HMButton 
                    width='100%'
                    color={blue}
                    type='button'
                    padding='10px'
                    bgColor='inherit'
                    borderRadius={10}
                    paddingActive='10px'
                    border={`1px solid ${blue}`}
                    margin='20px auto 10px auto'
                    handleClick={handleAddInsurerModal}
                    icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                    text={
                        <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                            Add insurer information
                        </Typography>
                    } 
                />
                {(me.insuranceInfo && me.insuranceInfo.length) ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        bgColor={skeletonsLightBlue}
                        flexDirection='column'
                    >
                        {me.insuranceInfo.map((data, index) => {
                            let chosenInsId = null
                            if ('insuranceInfo' in me && me.insuranceInfo.length > 1 && Object.keys(chosenInsuranceInfo).length) {
                                chosenInsId = chosenInsuranceInfo.insurer._id
                            }
                            const isMatch = chosenInsId === data.insurer._id
                            return (
                                <HMBox
                                    padding='0'
                                    key={index}
                                    width='100%'
                                    display='flex'
                                    flexDirection='column'
                                >
                                    <PaymentMethodDetails 
                                        lowerText={data.nameOnCard}
                                        color={isMatch && white}
                                        bgColor={isMatch ? darkBlue : seeThroughLightBlue}
                                        handleClick={() => handleSelectInsuranceInfo(data)}
                                        upperText={`${data.insurer.name} - ${data.cardNumber}`}
                                        leftIcon={
                                            data.insurer.logo ? (
                                                <img
                                                    alt={data.insurer.name}
                                                    src={data.insurer.logo}
                                                    className='super-mini-image'
                                                    style={{ margin: 'auto 10px auto 0' }}
                                                />
                                            ) : (
                                                <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                                                    <HMText
                                                        float='none'
                                                        width='100%'
                                                        margin='auto'
                                                        height='100%'
                                                        color={white}
                                                        fontWeight={700}
                                                        textAlign='center'
                                                        bgColor={getRandomColor()}
                                                        text={data.insurer.name.substr(0, 1).toUpperCase()}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        rightIcon={
                                            <>
                                                {data.default ? (
                                                    <HMText
                                                        color={white}
                                                        padding='0 5px'
                                                        bgColor={orange}
                                                        text='Dafault insurance'
                                                        margin='auto 10px auto auto'
                                                        fontSize={`${fontSize - 2}px`}
                                                    />
                                                ) : <></>}
                                                <IconButton   
                                                    onClick={(e) => handleEditInsurerModal(e, data, data.insurer, index)} 
                                                    sx={{margin: data.default ? 'auto 0 auto 5px' : 'auto 0 auto auto'}}
                                                >
                                                    <DriveFileRenameOutlineOutlinedIcon 
                                                        sx={{
                                                            margin: 'auto',
                                                            color: isMatch && white,
                                                            fontSize: `${fontSize + 4}px`,
                                                        }}
                                                    />
                                                </IconButton>
                                            </>
                                        }
                                    />
                                </HMBox>
                            )
                        })}
                    </HMBox>
                ) : <></>}
            </HMBox>
        ),
    }

    if (paymentInfo['method'] && paymentInfo.method.toLowerCase().includes('insurance')) {
        steps.splice(2, 0, insuranceInfoStep)
    }

    const disabledDilivery = (
        deliveryInfo.category === '' ||
        deliveryInfo.mapAddress === '' ||
        deliveryInfo.recipientName === '' ||
        wrongNumber === true 
      ) ? true : false

    const handleSubmit = () => {
        const newOrder = { ...order }
        const tempDeliveryInfo = { ...chosenAddressInfo }
        delete tempDeliveryInfo.default

        const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[0].type)

        if (Object.keys(chosenInsuranceInfo).length && paymentInfo.method.toLowerCase() === 'insurance') {
            newOrder['insuranceInfo'] = {
              ...chosenInsuranceInfo,
              storeValidation: {},
              insurer: {
                id: chosenInsuranceInfo.insurer._id,
                name: chosenInsuranceInfo.insurer.name,
                logo: chosenInsuranceInfo.insurer.logo,
                type: chosenInsuranceInfo.insurer.type,
                origin: chosenInsuranceInfo.insurer.origin,
              }
            }
        } else {
            newOrder['insuranceInfo'] = null
        }

        newOrder['deliveryInfo'] = tempDeliveryInfo
        newOrder['deliveryOption'] = deliveryOption

        dispatch(createOrder(newOrder))
    }

    const scrollListener = () => {
        setInfoTop(window.scrollY)
    }
      
    window.addEventListener('scroll', scrollListener)

    return user ? (
        <Page>
            {(orderState.isLoading || isLoading) ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <FullWidthContainer
                display='flex'
            >
                <HMBreadcrumbs 
                    options={breadOptions}
                    margin='auto auto auto 0'
                />
                <HMBox 
                    padding='0'
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={orange}
                        isResponsive={true}
                        margin='auto 0 auto auto'
                        handleClick={() => navigate(-1)}
                        text={<Typography sx={textStyle}>Back</Typography>} 
                        icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    />
                </HMBox>
            </FullWidthContainer>
            <HMModal 
                open={open} 
                title={title}
                maxWidth='700px'
                maxHeight='95vh'
                handleClose={handleClose} 
                colors={{lightBlack, lightBlue}}
            >
                {isAddInsurer ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        overflowY='auto'
                        color={darkBlue}
                        flexDirection='column'
                        maxHeight={
                            isVerySmallHeightScreen ? '50vh' 
                            : isSmallHeightScreen ? '60vh' 
                            : isNormalHeightScreen ? '70vh' 
                            : isBigHeightScreen ? '80vh' 
                            : '80vh'
                        }
                    >
                        {insurers.length ? insurers.map((insurer, index) => (
                            <HMBox
                                padding='0'
                                key={index}
                                width='100%'
                                display='flex'
                                className='fade-in'
                                flexDirection='column'
                            >
                            <PaymentMethodDetails 
                                hoverBgColor={lightGreen}
                                upperText={insurer.name}
                                bgColor={seeThroughLightBlue}
                                lowerText={`${insurer.type} | ${insurer.origin}`}
                                handleClick={() => handleAddSpecificInsurerModal(insurer)}
                                leftIcon={
                                    insurer.logo ? (
                                        <img
                                            alt={insurer.name}
                                            src={insurer.logo}
                                            className='super-mini-image'
                                            style={{ margin: 'auto 10px auto 0' }}
                                        />
                                    ) : (
                                        <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                                            <HMText
                                                float='none'
                                                width='100%'
                                                margin='auto'
                                                height='100%'
                                                color={white}
                                                fontWeight={700}
                                                textAlign='center'
                                                bgColor={getRandomColor()}
                                                text={insurer.name.substr(0, 1).toUpperCase()}
                                            />
                                        </Grid>
                                    )
                                }
                                rightIcon={
                                    <ChevronRightOutlinedIcon 
                                        sx={{ margin: 'auto 0 auto auto' }} 
                                    />
                                }
                            />
                            </HMBox>
                        )) : <></>}
                    </HMBox>
                ) : (isAddSpecificInsurer || isEditInsurer) ? (
                    <HMInsuranceCardForm 
                        cardImageUrl={cardImageUrl}
                        isTabletScreen={isTabletScreen}
                        setCardImageUrl={setCardImageUrl}
                        insurerFormData={insurerFormData}
                        handleSaveInsurer={handleSaveInsurer}
                        isAddSpecificInsurer={isAddSpecificInsurer}
                        handleInsurerDataChange={handleInsurerDataChange}
                        backOrCancelText={isAddSpecificInsurer ? 'Back' : 'Cancel'}
                        handleBackOrCancel={isEditInsurer ? handleClose : handleAddInsurerModal}
                        handleConfirm={isEditInsurer ? handleEditInsurerConfirm : handleAddInsurerConfirm}
                    />
                )  : isSelectAddress ? (
                    <HMBox
                    width='100%'
                    display='flex' 
                    overflowY='auto'
                    color={darkBlue}
                    flexDirection='column'
                    minWidth={!isTabletScreen && '500px'}
                    maxHeight={
                        isVerySmallHeightScreen ? '50vh' 
                        : isSmallHeightScreen ? '60vh' 
                        : isNormalHeightScreen ? '70vh' 
                        : isBigHeightScreen ? '80vh' 
                        : '80vh'
                    }
                    >
                    <HMButton 
                        width='100%'
                        type='button'
                        color={blue}
                        padding='20px'
                        bgColor='inherit'
                        borderRadius={10}
                        paddingActive='20px'
                        margin='10px auto 15px auto'
                        border={`1px solid ${blue}`}
                        handleClick={handleAddAddressModal}
                        icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                        text={
                        <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                            Add delivery address
                        </Typography>
                        } 
                    />
                    {me.deliveryInfo.map((addressInfo, index) => (
                        <AddressDetails 
                        key={index}
                        margin='5px 0'
                        isColumn={true}
                        canDelete={true}
                        activeCursor={true}
                        bgColor={lightBlue}
                        addressInfo={addressInfo}
                        hoverBgColor={lightGreen}
                        isDefault={addressInfo.default}
                        isTabletScreen={isTabletScreen}
                        handleChooseClick={() => handleChooseAddress(addressInfo)}
                        handleChangeClick={(e) => handleEditAddressModal(e, addressInfo, index)}
                        handleDeleteClick={(e) => handleDeleteAddressModal(e, addressInfo, index)}
                        />
                    ))}
                    </HMBox>
                ) : (isAddAddress || isEditAddress) ? (
                    <HMBox
                    padding='0'
                    width='100%'
                    minWidth={!isTabletScreen && '500px'}
                    > 
                    <HMBox
                        padding='0'
                        margin='15px 0'
                        className='fade-in'
                        width={isTabletScreen ? '80vw' : '100%'}
                    >
                        <EditDeliveryInfo 
                        handleChange={handleChange}
                        deliveryInfoItem={deliveryInfo}
                        isTabletScreen={isTabletScreen}
                        handleChangeMapAddress={handleChangeMapAddress}
                        handleSelectMapAddress={handleSelectMapAddress}
                        />
                    </HMBox> 
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        margin='15px auto 0 auto'
                        className='change-store-button fade-in'
                    >
                        <HMButton 
                        type='button'
                        width='100px'
                        fontWeight={500}
                        margin='auto 10px auto 0'
                        bgColor={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? orange : gray}
                        handleClick={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? handleSelectAddressModal : handleClose}
                        icon={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
                        text={
                            <Typography sx={textStyle}>
                            {isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? 'Back' : 'Cancel'}
                            </Typography>
                        }
                        />
                        <HMButton 
                        type='button'
                        width='100px'
                        bgColor={green}
                        margin='auto 0 auto auto'
                        disabled={disabledDilivery}
                        handleClick={isEditAddress ? handleEditAddressSave : handleAddAddressSave}
                        text={
                            <Typography sx={textStyle}>
                            Save
                            </Typography>
                        }
                        />
                    </HMBox>
                    </HMBox>
                ) : isDeleteAddress ? (
                    <HMBox
                        padding='0'
                        width='100%'
                        minWidth={!isTabletScreen && '500px'}
                    > 
                        <Typography sx={deleteTextStyle}>
                            Are you sure your want to delete the address? Once you click on yes, it cannot be undone! 
                        </Typography>
                        <HMBox
                            padding='0'
                            width='100%'
                            display='flex'
                            margin='15px auto 0 auto'
                            className='change-store-button fade-in'
                        >
                            <HMButton 
                                type='button'
                                width='100px'
                                fontWeight={500}
                                bgColor={orange}
                                margin='auto 10px auto 0'
                                handleClick={handleSelectAddressModal}
                                text={<Typography sx={textStyle}>No, Back!</Typography>}
                                icon={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
                            />
                            <HMButton 
                                type='button'
                                width='100px'
                                color={white}
                                bgColor={red}
                                margin='auto 0 auto auto'
                                handleClick={handleDeleteAddress}
                                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                            />
                        </HMBox>
                    </HMBox>
                ) : <></>}
            </HMModal>
            <Section 
                padding={10}
                bgColor={white}
                paddingBottom={0}
            >
                <Grid container spacing={2}>
                    <Grid 
                        item 
                        sm={5} 
                        xs={12} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: skeletonsLightBlue
                        }}
                    >
                        <HMBox
                            padding='0'
                            display='flex'
                            width={isTabletScreen ? '100%' : '80%'}
                            margin={
                                isTabletScreen ? '10px auto 20px auto' 
                                : infoTop > 50 ? `${infoTop}px auto 5% auto` 
                                :'10% auto 5% auto'
                            }
                        >
                            <HMImage
                                width='40px'
                                src={prescriptionIcon}
                                margin='auto 10px auto 0'
                            />
                            <Title 
                                color={darkBlue}
                                size={fontSize + 4}
                                margin='auto auto auto 10px'
                                title='Place Your Order With Prescription'
                            />
                        </HMBox>
                        <Typography
                            sx={{
                                ...textStyle, 
                                color: darkBlue,
                                margin: '0 auto',
                                textAlign: 'justify',
                                width: isTabletScreen ? '100%' : '80%'
                            }}
                        >
                            <b>Upload your prescription</b>, tell us how you want to pay and where you want us to deliver your order.
                            <br/>
                            <br/>
                            Seat back and simply wait for your order to arrive 😀 
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        sm={7} 
                        xs={12} 
                    >
                        <BuyWithPrescriptionStepper 
                            steps={steps} 
                            handleSubmit={handleSubmit}
                            disableSubmit={disableSubmit}
                            disableStepOne={disableStepOne}
                            disableStepTwo={disableStepTwo}
                            disableLastStep={disableLastStep}
                            disableInsuranceStep={disableInsuranceStep}
                        />
                    </Grid>
                </Grid>
            </Section>
        </Page>
    ) : (
        <HMLoginFirst 
            message='You need to login first before applying to add your store'
        />
    ) 
}

export default ByWithPrescription
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { 
  getLatLng,
  geocodeByAddress, 
} from 'react-places-autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  getMe,
  resetMe, 
  updateMe, 
} from '../../features/me/meSlice'
import { 
  resetStore, 
  createStore, 
  updateStore, 
  getMyStores,
  getStoresForApplication
} from '../../features/stores/storeSlice'
import ApplicationStatus from '../../components/store/ApplicationStatus'
import HMStepper from '../../components/store/HMStepper'
import LastStep from '../../components/store/LastStep'
import StepOne from '../../components/store/StepOne'
import StepTwo from '../../components/store/StepTwo'
import FDAStep from '../../components/store/FDAStep'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import Title from '../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {  
  storeTypes, 
  removeDuplicates,
  storeApplicationStatus
} from '../../hooks/helperFunctions'
import {
  white,
  orange,
  darkBlue,
  skeletonsLightBlue
} from '../../hooks/useColors'

const checkAvalaibleStore = (currentStore, stores, name) => {
  const store = stores.find(store => store.name.toLowerCase() === name.toLowerCase().trim())
    
  if (Object.keys(currentStore).length && store) {
    return store.name !== currentStore.name ? true : false
  } else if (!Object.keys(currentStore).length && store) {
    return true
  } else {
    return false
  }
}

const StoreApplication = ({ socket }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const meState = useSelector((state) => state.me)
  const { me } = meState
  const storeState = useSelector((state) => state.store)
  const { myStores, storesForApplication } = storeState

  const minExpirationDate = new Date()
  minExpirationDate.setDate(minExpirationDate.getDate() + 31)

  const isAnotherStorePath = window.location.pathname === '/store/application' ? true : false

  const formDataSeckeleton = {
    cell: '',
    type: '',
    logo: '',
    email: '',
    street: '',
    sector: '',
    village: '',
    phone: [''],
    province: '',
    district: '',
    lastName: '',
    storeName: '',
    firstName: '',
    mapAddress: '',
    licenseFile: '',
    issueOn: dayjs(),
    useSameNameForPharmacist: false,
    expiresOn: dayjs(minExpirationDate),
    pharmacist: {
      firstName: '',
      lastName: '',
    }, 
    coordinates: {
      lng: null,
      lat: null,
    }
  }
  const storeApplicationData = JSON.parse(localStorage.getItem('storeApplicationData')) 

  const [storeLogo, setStoreLogo] = useState('')
  const [isPharmacy, setIsPharmacy] = useState(false)
  const [currentStore, setCurrentStore] = useState({})
  const [isAllLoaded, setIsAllLoaded] = useState(false)
  const [emptyNumber, setEmptyNumber] = useState(false)
  const [wrongNumber, setWrongNumber] = useState(false)
  const [licenseFileUrl, setLicenseFileUrl] = useState('')
  const [isEmailEditable, setEmailEditable] = useState(false)
  const [formData, setFormData] = useState(formDataSeckeleton)
  const [isCreatingStore, setIsCreatingStore] = useState(false)
  const [isUpdatingStore, setIsUpdatingStore] = useState(false)
  const [isApplyingAgain, setIsApplyingAgain] = useState(false)
  const [isStoreNameTaken, setIsStoreNameTaken] = useState(false)
  const [isLastNameEditable, setLastNameEditable] = useState(false)
  const [isFirstNameEditable, setFirstNameEditable] = useState(false)
  const [isAcceptableLicense, setIsAcceptableLicense] = useState(false)

  const {
    cell,
    logo,
    type,
    phone,
    sector,
    street,
    issueOn,
    village,
    province,
    district,
    lastName,
    storeName,
    firstName,
    expiresOn,
    mapAddress,
    pharmacist,
    coordinates,
    licenseFile,
  } = formData

  const {
    windowW,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const breadOptions = [
    { text: 'Home', link: (Object.keys(currentStore).length || isAnotherStorePath) ? '/store' : '/landing' },
    { text: 'Store Application', link: null },
  ]

  useEffect(() => {
    if (storeId && storeId.length) {
      socket.on('store_updated', (data) => {
        if (data._id === storeId) {
          dispatch(getMyStores())
        }
      })
    }
    // eslint-disable-next-line 
  }, [socket, storeId])

  useEffect(() => {
    if ((storeApplicationData && Object.keys(storeApplicationData).length)) {
      setFormData({
        ...storeApplicationData,
        useSameNameForPharmacist: false,
        issueOn: formDataSeckeleton.issueOn,
        expiresOn: formDataSeckeleton.expiresOn,
        pharmacist: formDataSeckeleton.pharmacist,
      })
      setStoreLogo(storeApplicationData.logo)
      setLicenseFileUrl(storeApplicationData.licenseFile)
    } else {
      setFormData(formDataSeckeleton)
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (storeState.isError || storeState.isSuccess || storeState.isApplicationStores) {
      dispatch(resetStore())
    } else if (storeState.isCreated) {
      dispatch(resetStore())
      resetApplicationForm()
      dispatch(getMyStores())
      setIsCreatingStore(false)
      setCurrentStore(storeState.store)
    } else if (storeState.isUpdated) {
      dispatch(resetStore())
      resetApplicationForm()
      dispatch(getMyStores())
      setIsUpdatingStore(false)
      setIsApplyingAgain(false)
      setCurrentStore(storeState.store)
    }

    if (meState.isOne) {
      dispatch(resetMe())
      setFormData((prevState) => ({
        ...prevState,
        email: me.email,
        lastName: me.lastName,
        firstName: me.firstName,
      }))
    } else if (meState.isUpdated) {
      dispatch(resetMe())
    }

    setTimeout(() => {
      if (!meState.isLoading && !storeState.isLoading) {
        setIsAllLoaded(true)
      }
    }, 1000)
    // eslint-disable-next-line 
  }, [storeState, meState])

  useEffect(() => {
    if (user) {
      dispatch(getMe())
      dispatch(getStoresForApplication())
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (isApplyingAgain && Object.keys(currentStore).length) {
      let tempFormData = {
        email: me.email,
        lastName: me.firstName,
        logo: currentStore.logo,
        firstName: me.firstName,
        type: currentStore.type,
        phone: currentStore.phone,
        storeName: currentStore.name,
        street: currentStore.address.street,
        cell: { name: currentStore.address.cell },
        mapAddress: currentStore.address.mapAddress, 
        sector: { name: currentStore.address.sector },
        village: { name: currentStore.address.village },
        province: { name: currentStore.address.province },
        district: { name: currentStore.address.district },
        coordinates: {
          lat: currentStore.location.latitude,
          lng: currentStore.location.longitude,
        },
        issueOn: formDataSeckeleton.issueOn,
        expiresOn: formDataSeckeleton.expiresOn,
        pharmacist: formDataSeckeleton.pharmacist,
        licenseFile: formDataSeckeleton.licenseFile,
      }
  
      if (currentStore.type.toLowerCase() === storeTypes[0].toLowerCase()) {
        tempFormData = {
          ...tempFormData,
          licenseFile: currentStore.license.image,
          pharmacist: currentStore.license.pharmacist,
          issueOn: dayjs(currentStore.license.issueOn),
          expiresOn: dayjs(currentStore.license.expiresOn),
        }
        setLicenseFileUrl(currentStore.license.image)
      }
      setFormData(tempFormData)
      setStoreLogo(currentStore.logo)
    }
    // eslint-disable-next-line
  }, [isApplyingAgain, currentStore])

  useEffect(() => {
    setIsPharmacy((formData.type === storeTypes[0]) ? true : false)
    let count = 0
    formData.phone.forEach(item => {
      if (!item.toString().length) {
        setEmptyNumber(true)
      } else {
        count++
      }
    })
    if (count === formData.phone.length) {
      setEmptyNumber(false)
    }

    localStorage.setItem('storeApplicationData', JSON.stringify(formData))

    const today = new Date(Date.now())
    const diff = new Date(formData.expiresOn).getTime() - today.getTime() 
    const days = Math.floor(diff / 1000 / 60 / 60 / 24)

    setIsAcceptableLicense(days >= 30 ? true : false)

    if (storesForApplication.length) {
      const hasStore = storesForApplication.some(store => store.users.includes(user._id))
      let tempCurrentStore = {}
      if (hasStore && !storeId && !isAnotherStorePath) {
          navigate('/store')
      } else if (storeId && myStores.length) {
        tempCurrentStore = myStores.find(store => store._id === storeId)
        if (tempCurrentStore) {
          setCurrentStore(tempCurrentStore)
        } else {
          navigate(-1)
        }
      }

      setIsStoreNameTaken(checkAvalaibleStore(tempCurrentStore, storesForApplication, formData.storeName))
    } 
    // eslint-disable-next-line
  }, [myStores, storeId, formData, storesForApplication, isAnotherStorePath, user])

  const handleEnableChange = (name) => {
    if (name === 'firstName') {
      setFirstNameEditable(true)      
    } else if (name === 'lastName') {
      setLastNameEditable(true)
    } else if (name === 'email') {
      setEmailEditable(true)
    }
  }
  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'pharmacistFirstName') {
      setFormData((prevState) => ({
        ...prevState,
        pharmacist: {
          ...prevState.pharmacist,
          firstName: value,
        },
      }))
    } else if (name === 'pharmacistLastName') {
      setFormData((prevState) => ({
        ...prevState,
        pharmacist: {
          ...prevState.pharmacist,
          lastName: value,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(()=>{
    if(storeLogo) {
      setFormData((prevState) => ({
        ...prevState,
        logo: storeLogo,
      }))
    }
  },[storeLogo])

  useEffect(()=>{
    setFormData((prevState) => ({
      ...prevState,
      licenseFile: licenseFileUrl,
    }))
  },[licenseFileUrl])

  const handlePhones = (index, e) => {
    const phoneValues = [...phone]
    const value = e.target.value
    const stringValue = value.toString()
    if (stringValue.length <= 10) {

      const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value) 
      phoneValues[index] = value

      if (!isValid && stringValue.length) {
        e.target.classList.add('wrong-input')
        setWrongNumber(true)
      } else if (isValid || !stringValue.length) {
        e.target.classList.remove('wrong-input')
        setWrongNumber(false)
      }
    } 
    

    setFormData((prevState) => ({
      ...prevState,
      phone: phoneValues
    }))
  }
  const handleRemovePhone = (index) => {
    const phoneValues = [...phone]
    if (phone.length > 1) {
      phoneValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          phone: phoneValues
      }))
    }
  }
  const handleAddPhone = (index) => {
    const phoneValues = [...phone]
    phoneValues.splice(index + 1, 0, '')
    setFormData((prevState) => ({
        ...prevState,
        phone: phoneValues
    }))
  }
  
  const handleTypeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      type: e.target.value
    }))
  }

  const handleUseSameNameForPharmacist = (e) => {
    const value = e.target.checked
    setFormData((prevState) => ({
      ...prevState,
      useSameNameForPharmacist: value,
      pharmacist: value ?  {
        firstName: prevState.firstName,
        lastName: prevState.lastName,
      } : prevState.pharmacist
    }))
  }
  const handleIssueOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      issueOn: newValue,
    }))
  }
  const handleExpiresOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      expiresOn: newValue,
    }))
  }

  const handleProvinceChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      sector: '',
      village: '',
      district: '',
      province: val ? val : '',
    }))
  }
  const handleDistrictChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      sector: '',
      village: '',
      district: val ? val : '',
    }))
  }
  const handleSectorChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: '',
      village: '',
      sector: val ? val : '',
    }))
  }
  const handleCellChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      village: '',
      cell: val ? val : '',
    }))
  }
  const handleVillageChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      village: val ? val : '',
    }))
  }
  const handleChangeMapAddress = (address) => {
    setFormData((prevState) => ({
        ...prevState,
        mapAddress: address
    }))
  }
  const handleSelectMapAddress = async (address) => {
    const results = await geocodeByAddress(address)
    const ll = await getLatLng(results[0])

    handleChangeMapAddress(address)
    setFormData((prevState) => ({
        ...prevState,
        coordinates: ll
    }))
  } 

  const resetApplicationForm = () => {
    setFormData({
      ...formDataSeckeleton,
      email: me.email,
      lastName: me.lastName,
      firstName: me.firstName,
    })
    localStorage.setItem('storeApplicationData', JSON.stringify({})) 
  }

  const disableStepOne = (firstName === '' || lastName === '') ? true : false
  const disableStepTwo = (
      wrongNumber || 
      emptyNumber || 
      storeLogo === '' ||
      isStoreNameTaken ||
      storeName === '' ||
      storeName.length < 4 ||
      (type === '' || (type !== null && type['name'] === ''))
    ) ? true : false
  const disableFDAStep = (
      (type === storeTypes[0]) && (
        licenseFileUrl === '' || 
        !isAcceptableLicense ||
        pharmacist.lastName === '' ||
        pharmacist.firstName === '' 
      )
    ) ? true : false
  const disableLastStep = (
      !coordinates.lng ||
      !coordinates.lat ||
      mapAddress === '' ||
      (cell === '' || (cell['name'] && cell['name'] === '')) ||
      (user === '' || (user['email'] && user['email'] === '')) ||
      (sector === '' || (sector['name'] && sector['name'] === '')) ||
      (village === '' || (village['name'] && village['name'] === '')) ||
      (district === '' || (district['name'] && district['name'] === '')) ||
      (province === '' || (province['name'] && province['name'] === '')) 
    ) ? true : false

  const disableSubmit = (disableStepOne || disableStepTwo || disableFDAStep || disableLastStep) ? true : false  

  const steps = [
    {
      label: 'Tell us about yourself',
      description: (
        <StepOne 
          formData={formData}
          handleChange={handleChange}
          isEmailEditable={isEmailEditable}
          isLastNameEditable={isLastNameEditable}
          handleEnableChange={handleEnableChange}
          isFirstNameEditable={isFirstNameEditable}
        />
      ),
    },
    {
      label: 'Store Information',
      description: (
        <StepTwo 
          formData={formData}
          wrongNumber={wrongNumber}
          storeLogoUrl = {storeLogo}
          handleChange={handleChange}
          handlePhones={handlePhones}
          setStoreLogo ={setStoreLogo}
          handleAddPhone={handleAddPhone}
          isStoreNameTaken={isStoreNameTaken}
          handleTypeChange={handleTypeChange}
          handleRemovePhone={handleRemovePhone}
        />
      ),
    },
    {
      label: 'Store Location',
      description: (
        <LastStep
          formData={formData}
          handleChange={handleChange}
          handleCellChange={handleCellChange}
          handleSectorChange={handleSectorChange}
          handleVillageChange={handleVillageChange}
          handleDistrictChange={handleDistrictChange}
          handleProvinceChange={handleProvinceChange}
          handleSelectMapAddress={handleSelectMapAddress}
          handleChangeMapAddress={handleChangeMapAddress}
        />
      ),
    },
  ]

  const fdaForm = {
    label: 'FDA License',
    description: (
      <FDAStep 
        formData={formData}
        handleChange={handleChange}
        licenseFileUrl={licenseFileUrl}
        minDate={dayjs(minExpirationDate)}
        setLicenseFileUrl={setLicenseFileUrl}
        isAcceptableLicense={isAcceptableLicense}
        handleIssueOnChange={handleIssueOnChange}
        handleExpiresOnChange={handleExpiresOnChange}
        handleUseSameNameForPharmacist={handleUseSameNameForPharmacist}
      />
    ),
  }

  if (isPharmacy) {
    steps.splice(2, 0, fdaForm)
  }
  const handleCreate = (e) => {
    e.preventDefault()
    if (
      (firstName.toLowerCase().trim() !== me.firstName.toLowerCase()) || 
      (lastName.toLowerCase().trim() !== me.lastName.toLowerCase())
    ) {
      const updatedMe = {
        lastName,
        firstName,
      }
      dispatch(updateMe(updatedMe))
    }

    const newStore = {
      logo,
      type: type,
      name: storeName,
      phone: removeDuplicates(phone, 'number'),
      applicationStack: [
        {
          message: '',
          timestamp: new Date(),
          status: storeApplicationStatus[0]
        }
      ],
      address: {
        street,
        mapAddress,
        cell: cell.name,
        sector: sector.name,
        village: village.name,
        district: district.name,
        province: province.name,
      },
      location: Object.keys(coordinates).length && {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      },
    }

    if (type === storeTypes[0]) {
      newStore['license'] = {
        pharmacist,
        image: licenseFile,
        issueOn: issueOn.$d,
        expiresOn: expiresOn.$d,
      }
    } 

    setIsCreatingStore(true)
    dispatch(createStore(newStore))
  }
  
  const handleViewStore = () => {
    navigate(`/store/${currentStore._id}/dashboard`)
  }
  const handleApplyAgain = () => {
    setIsApplyingAgain(true)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    if (
      (firstName.toLowerCase().trim() !== me.firstName.toLowerCase()) || 
      (lastName.toLowerCase().trim() !== me.lastName.toLowerCase())
    ) {
      const updatedMe = {
        lastName,
        firstName,
      }
      dispatch(updateMe(updatedMe))
    }

    const updatedStore = {
      logo,
      type,
      name: storeName,
      _id: currentStore._id,
      phone: removeDuplicates(phone, 'number'),
      applicationStack: [
        ...currentStore.applicationStack,
        {
          message: '',
          timestamp: new Date(),
          status: storeApplicationStatus[1]
        }
      ],
      address: {
        street,
        mapAddress,
        cell: cell.name,
        sector: sector.name,
        village: village.name,
        district: district.name,
        province: province.name,
      },
      location: Object.keys(coordinates).length && {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      },
    }

    if (type === storeTypes[0]) {
      updatedStore['license'] = {
        pharmacist,
        image: licenseFile,
        issueOn: issueOn.$d,
        expiresOn: expiresOn.$d,
      }
    } 

    setIsUpdatingStore(true)
    dispatch(updateStore(updatedStore))
  }

  return user ? (
    <Page>
      {(isCreatingStore || isUpdatingStore || !isAllLoaded) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <Section 
        padding={10}
        bgColor={white}
        paddingBottom={0}
      >
        {(Object.keys(currentStore).length && !isApplyingAgain && !isUpdatingStore) ? (
          <ApplicationStatus
            handleViewStore={handleViewStore}
            handleApplyAgain={handleApplyAgain}
            applicationStack={currentStore.applicationStack}
          />
        ) : (!Object.keys(currentStore).length && isAllLoaded) || isApplyingAgain ? (
          <Grid container spacing={2}>
            <Grid 
              item 
              sm={5} 
              xs={12} 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: skeletonsLightBlue
              }}
            >
              <HMBox
                display='flex'
                width={isTabletScreen ? '100%' : '80%'}
                margin={isTabletScreen ? 'auto auto 20px auto' : '10% auto 5% auto'}
              >
                <AddBusinessIcon 
                  sx={{
                    margin: 'auto 10px auto 0',
                    fontSize: `${fontSize + 14}px`
                  }}
                />
                <Title 
                  color={darkBlue}
                  size={fontSize + 4}
                  title='Add your store'
                  margin='auto auto auto 10px'
                />
              </HMBox>
              <Typography
                sx={{
                  ...textStyle, 
                  color: darkBlue,
                  margin: '0 auto',
                  textAlign: 'justify',
                  width: isTabletScreen ? '100%' : '80%'
                }}
              >
                <b>We will help you bring your store online.</b> It's easy! 
                <br/>
                Simply complete these few easy applicationsteps. 
                <br/>
                <br/>
                Our team processes your application within 2 working days.
              </Typography>
            </Grid>
            <Grid 
              item 
              sm={7} 
              xs={12} 
            >
              <HMStepper 
                steps={steps} 
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                disableSubmit={disableSubmit}
                disableStepOne={disableStepOne}
                disableStepTwo={disableStepTwo}
                disableFDAStep={disableFDAStep}
                isApplyingAgain={isApplyingAgain}
                disableLastStep={disableLastStep}
                resetApplicationForm={resetApplicationForm}
              />
            </Grid>
          </Grid>
        ) : <></>}
      </Section>
    </Page>
  ) : (
    <HMLoginFirst 
      message='You need to login first before applying to add your store'
    />
  )
}

export default StoreApplication

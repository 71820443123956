import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Grid, Typography } from '@mui/material'
import parse from 'html-react-parser'

import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Section from '../../components/common/layout/Section'
import HMText from '../../components/common/tools/HMText'
import Page from '../../components/common/layout/Page'
import Title from '../../components/common/tools/Title'
import HMSearchField from '../../components/common/tools/HMSearchField'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import HMBox from '../../components/common/layout/HMBox'
import {
  white,
  blue,
  darkBlue,
  lightBlue,
  lightBlack,
  red,
  lightRed
} from '../../hooks/useColors'
import { getFaqs, getFaqsByRole } from '../../features/faqs/faqsSlice'
import { getFaqCategories } from '../../features/faqCategories/faqCategorySlice'

export default function CustomerFaqs() {
  const { faqs,isLoading } = useSelector(
    (state) => state.faq
  )

  const [text, setText] = useState('')
  const [initialData, setInitialData] = useState([])
  const [faqList, setFaqList] = useState([])
  const [foundMatch, setFoundMatch] = useState(true)
  
  const { publiFaqCategories } = useSelector((state) => state.faqCategory)
  const [selectedCategory, setCategory] = useState(publiFaqCategories[0]._id)
  const [selectedFaqs, setSelectedFaqs] = useState(faqs)

  const {
    windowW
} = useWindowDimensions()

  const isTabletScreen = windowW < 768 ? true : false

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFaqsByRole())
    dispatch(getFaqs())
    dispatch(getFaqCategories())
  }, [dispatch])

  useEffect(()=> {
    if(faqs.length) {
      const tempFaqs = faqs.map(faqDet => {
        return {
          ...faqDet
        }
      })
      setInitialData(tempFaqs)
      setFaqList(tempFaqs)
    }
    
  },[faqs])

  

  useEffect(() => {
    if (!text.length && initialData.length) {
      setFaqList(initialData)
    }
  }, [text, initialData])

  const handleSearchClear = () => {
    setText('')
    setFoundMatch(true)
    setFaqList(initialData)
  }

  const handleSearchChange = (e) => {
    setText(e.target.value)
    dynamicSearch()
  }
  
  const handleSearchSubmit = (e) => {
    e.preventDefault()
    dynamicSearch()
  }

  const dynamicSearch = () => {
    if (initialData.length && text.length) {
      const result = initialData.filter(faqDet => (
          faqDet.question.toLowerCase().includes(text.toLowerCase())
        ))
      const resultAns =  initialData.filter(faqDet => (
        faqDet.answer.toLowerCase().includes(text.toLowerCase())
      ))
      if (resultAns.length){
        setSelectedFaqs(resultAns)
      }
      if (result.length) {
        setSelectedFaqs(result)
      }
      
      if (result.length) {
        setFoundMatch(true)
      } else {
        setFoundMatch(false)
      }
    
    } 
  }

  const handleClick = (categoryId) => {
    setCategory(categoryId)
  }

  const handleDisplayFaqs = () => {
    const faqsByCategory = faqs.filter((faq) => {
      return faq.category.includes(selectedCategory)
    })

    setSelectedFaqs(faqsByCategory)
  }

  useEffect(() => {
    handleDisplayFaqs()
  }, [selectedCategory, faqs])

  return (
    <Page>
      <FullWidthContainer>
      <Section bgcolor={white} padding={10}>
        <Grid bgcolor={white} margin="inherit" container spacing={2}>
          <Section bgcolor={white} padding={10}>
            <Box
              width="100%"
              display="flex"
              marginBottom={6}
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <Box width="80%" sx={{ justifyContent: 'center' }}>
                <Title
                  title="Support and FAQs"
                  margin="revert"
                  fontSize="16px"
                  color={blue}
                  float="initial"
                />
                <HMText
                  text="Frequently Asked Questions"
                  margin="revert"
                  fontSize="24px"
                  fontWeight="800"
                  float="initial"
                  color={darkBlue}
                />
                <Typography sx={{ color: '#7D7D7D' }}>
                  Feel like having something unclear about us? HarakaMeds has
                  got you covered. Search, or check our list of frequently asked
                  questions.
                </Typography>
                <Box marginTop={5}>
                {initialData.length ? (
                <HMSearchField 
                  text={text}
                  padding='20px 0px'
                  borderRadius='10px'
                  bgColor={lightBlue}
                  className='data-table'
                  title='Search in FAQS'
                  handleClick={handleSearchClear}
                  closeMargin='auto auto auto 5px'
                  handleChange={handleSearchChange}
                  handleSubmit={handleSearchSubmit}
                  width={isTabletScreen ? '100%' : '40%'}
                  marginLeft={(faqList.length > 1 && foundMatch) ? 'auto' : 0}
                  margin={(faqList.length > 1 && foundMatch) ? 'auto 0 auto auto' : '20px 0'}
                />
              ) : <></>}
                </Box>
              </Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={10} md={4} sm={10}>
                  <Grid padding="20px" container spacing={2}>
                    {publiFaqCategories &&
                      publiFaqCategories.map((faqCategory, index) => (
                        <Grid
                          borderRadius={2}
                          // sx={{ boxShadow: 2 }}
                          padding="12px"
                          marginLeft="auto"
                          marginRight="auto"
                          marginBottom={4}
                          bgcolor={lightBlue}
                          item
                          xs={12}
                          md={10}
                          sm={5}
                        >
                          <Box
                            className="category-card"
                            onClick={() => {
                              handleClick(faqCategory._id)
                            }}
                            sx={{ width: '60%', height: '3vh', margin: 'auto' }}
                          >
                            <Title title={faqCategory.name} float="initial" />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs={10} md={8} sm={8}>
                  <Box sx={{ width: '100%', height: 'auto' }}>
                    {
                    (selectedFaqs.length) ?  
                      selectedFaqs.map((faq)=> {
                        return (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{parse(faq.question)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{parse(faq.answer)}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })
                      : isLoading ? (
                        <HMText 
                          width='100%'
                          margin='20px 0'
                          text='Loading...'
                        />
                      ) : (initialData.length && !foundMatch) ? (
                        <HMBox
                          width='100%'
                          display='flex'
                          bgColor={lightRed}
                          padding='20px 10px'
                          flexDirection={isTabletScreen ? 'column' : 'row'}
                        >
                          <HMText 
                            width='max-content'
                            margin='auto 5px auto 0'
                            text='This FAQS has no related Question And Answer your search:'
                          />
                          {text.length ? (
                            <HMText 
                              color={red}
                              text={text}
                              fontWeight={500}
                              margin='auto 5px'
                              padding='5px 10px'
                              width='max-content'
                            />
                          ) : <></>}
                          
                        </HMBox>
                      ) : !initialData.length ? (
                        <HMText 
                          width='100%'
                          margin='20px 0'
                          text='There are no available Faqs yet!'
                        />
                      ) : <></>}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              width="100%"
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  backgroundColor: '#BCEEFF',
                  padding: '50px',
                  marginTop: '100px',
                  borderRadius: '16px',
                  justifyContent: 'center',
                }}
              >
                <HMText
                  text="Not answered yet?"
                  fontSize="14px"
                  fontWeight="800"
                  float="initial"
                  color={darkBlue}
                />
                <Typography color={lightBlack} marginBottom={5}>
                  We understand that it is possible for your questions to be new
                  to us! Please chat to our friendly team
                </Typography>

                <Button
                  type="button"
                  float="initial"
                  marginTop={10}
                  sx={{
                    width: 'auto',
                    color: '#FFFFFF',
                    bgcolor: '#00aeef',
                    '&:hover': {
                      opacity: 0.8,
                      bgcolor: '#00aeef',
                    },
                  }}
                >
                  Get in touch
                </Button>
              </Box>
            </Box>
          </Section>
        </Grid>
        </Section>
      </FullWidthContainer>
    </Page>
  )
}

import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import checkoutService from './checkoutService'

const initialState = {
    message: '',
    checkout: {},
    isOne: false,
    isError: false,
    isAdded: false,
    isLoading: false,
    isDeleted: false,
}

// Create checkout
export const addCheckout = createAsyncThunk(
    'checkout/create',
    async (checkoutDetails, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await checkoutService.addCheckout(checkoutDetails, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get checkout
export const getCheckout = createAsyncThunk(
    'checkout/get',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await checkoutService.getCheckout(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }  
)

// Delete checkout
export const deleteCheckout = createAsyncThunk(
    'checkout/delete',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await checkoutService.deleteCheckout(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }  
)

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        resetCheckout: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isAdded = false
            state.isLoading = false
            state.isDeleted = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCheckout.pending, (state) => {
                state.isError = false
                state.isAdded = false
                state.isLoading = true
            })
            .addCase(addCheckout.fulfilled, (state, action) => {
                state.isAdded = true
                state.isError = false
                state.isLoading = false
                state.checkout = action.payload
            })
            .addCase(addCheckout.rejected, (state, action) => {
                state.isError = true
                state.isAdded = false
                state.isLoading = false
                state.message = action.payload
            })
        
            .addCase(getCheckout.pending, (state) => {
                state.isOne = false
                state.isError = false
                state.isLoading = true
            })
            .addCase(getCheckout.fulfilled, (state, action) => {
                state.isOne = true
                state.isError = false
                state.isLoading = false
                state.checkout = action.payload
            })
            .addCase(getCheckout.rejected, (state, action) => {
                state.isOne = false
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(deleteCheckout.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isDeleted = false
            })
            .addCase(deleteCheckout.fulfilled, (state, action) => {
                state.checkout = null
                state.isError = false
                state.isDeleted = true
                state.isLoading = false
                state.message = action.payload.message
            })
            .addCase(deleteCheckout.rejected, (state, action) => {
                state.isError = true
                state.isDeleted = false
                state.isLoading = false
                state.message = action.payload
            })
    }
})

export const { resetCheckout } = checkoutSlice.actions
export default checkoutSlice.reducer

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  resetOrder,
  getMyOrders, 
} from '../../features/orders/orderSlice'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import DataTable from '../../components/common/layout/DataTable'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import HMSpiner from '../../components/common/tools/HMSpiner'
import {
  iconStyle,
  textStyle,
} from '../../components/common/tools/Styles'
import {
  white,
  orange,
  lightBlue,
} from '../../hooks/useColors'

function MyOrders () {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { 
        user 
    } = useSelector((state) => state.auth)
    const { 
        myOrders,
        isLoading,
        areMyOrders
    } = useSelector((state) => state.order)

    const [isOrderEmpty, setIsOrderEmpty] = useState(false)

    const breadOptions = [
        { text: 'Home', link: '/landing' },
        { text: 'My Orders', link: null },
    ]

    useEffect(() => {
        if (user) {
            dispatch(getMyOrders())
        }
    }, [dispatch, user])

    useEffect(() => {
        if (areMyOrders) {
            dispatch(resetOrder())
            setIsOrderEmpty(myOrders.length ? false : true)
        }
    }, [dispatch, areMyOrders, myOrders])

    const fields = []
    const columns = [
        { field: 'orderNumber', headerName: 'Order Number', sortable: true },
        { field: 'createdAt', headerName: 'Order Date', sortable: false },
        { field: 'orderTotal', headerName: 'Order Total', sortable: true },
        { field: 'orderDetails', headerName: 'Store(s)' },
    ]

    columns.forEach((column) => {
        fields.push(column.field)
    })

    return (
        <Page>
            <FullWidthContainer
                display='flex'
            >
                <HMBreadcrumbs 
                    options={breadOptions}
                    margin='auto auto auto 0'
                />
                <HMBox 
                    padding='0'
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={orange}
                        isResponsive={true}
                        margin='auto 0 auto auto'
                        handleClick={() => navigate(-1)}
                        text={<Typography sx={textStyle}>Back</Typography>} 
                        icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    />
                </HMBox>
            </FullWidthContainer>
            <Section
                padding={10}
                bgColor={white}
            >
                {(user && isLoading) ? (
                    <HMSpiner 
                        size={50}
                        width='100%'
                        height='60vh'
                        bgColor='inherit'
                    />
                ) : (user && isOrderEmpty && !isLoading) ? (
                    <HMBox
                        width='100%'
                        height='70vh'
                        display='flex'
                    >
                        <HMText
                            margin='auto'
                            text='You do not have orders yet!'
                        />
                    </HMBox>
                ) : (user && myOrders.length && !isLoading) ? (
                    <DataTable
                        data={myOrders}
                        viewOnly={true}
                        fields={fields}
                        title='My Orders'
                        columns={columns}
                        disableEdit={true}
                        defaultRowsNum={20}
                        viewLink='/myorders'
                        searchBgColor={lightBlue}
                    />
                ) : !user ? (
                    <HMLoginFirst 
                        message='You need to login first before accessing your orders'
                    />
                ) : <></>}
            </Section>
        </Page>
    )
}

export default MyOrders
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { 
  deleteStore, 
  getStores, 
  resetStore 
} from '../../../features/stores/storeSlice'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Title from '../../../components/common/tools/Title'
import {
    iconStyle,
    textStyle,
    deleteTextStyle,
    deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import useColors from '../../../hooks/useColors'

const { 
    red,
    gray,
    blue, 
    green,
    lightBlue,
    lightBlack,
} = useColors

function Stores() {
  const dispatch = useDispatch() 
  const navigate = useNavigate()
  const {
      stores,
      isError,
      message,
      isLoading,
      isDeleted,
      isSuccess,
  } = useSelector(state => state.store)


  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isFinal, setIsFinal] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [currentStore, setCurrentStore] = useState({})
  const [selectedStores, setSelectedStores] = useState([])

  useEffect(() => {
      if (isError || isDeleted || isSuccess) {
          dispatch(resetStore())
      }

      if (isSuccess) {
        setIsFinal(true)
      } else if (!isLoading && !isError) {
        setIsFinal(true)
      }

      if (isError) {
          toast.error(message)
          setIsDeleting(false)
      } else if (isDeleted) {
          setIsDeleting(false)
          toast.success(message)
          dispatch(getStores())
      }
  }, [dispatch, isLoading, isSuccess, isError, message, isDeleted])

  useEffect(() => {
      dispatch(getStores())
  }, [dispatch])

  const fields = []
  const columns = [
      { field: 'name', headerName: 'Name', sortable: true},
      { field: 'type', headerName: 'Type', sortable: true},
      { field: 'onlineMarkUp', headerName: 'Online Mark Up', sortable: false},
      { field: 'storeMarkUp', headerName: 'Store Mark Up', sortable: false}
  ]

  columns.forEach((column) => {
      fields.push(column.field)
  })

  const getCurentStore = (id) => {
      return stores.filter(store => store._id === id)[0]
  }
  const handleCreate = () => {
      navigate('/admin/stores/create')
  }
  const handleEdit = (id) => {
      navigate(`/admin/stores/edit/${id}`)
  }
  const handleDeleteModal = (id) => {
      setCurrentStore(getCurentStore(id))

      setOpen(true)
      setIsDelete(true)
      setTitle('Delete Store')
  }
  const handleBulkDeleteModal = (data) => {
      setSelectedStores(data)
      setOpen(true)
      setIsDelete(false)
      setTitle('Delete Stores')
  }
  const handleDelete = () => {
      setIsDeleting(true)
      dispatch(deleteStore(currentStore._id))
      handleClose()
  }
  const handleBulkDelete = () => {
      // TODO
      handleClose()
      setIsDeleting(true)
  }
  const handleClose = () => {
      setTitle('')
      setOpen(false)
      setIsDelete(false)
      setCurrentStore({})
  }

  return (
    <Page>
        {isDeleting && isLoading ? (
            <HMSpiner 
                size={60}
                zIndex={999}
                width='100%'
                height='80vh'
                margin='auto'
                position='absolute'
                bgColor='transparent'
            />
        ) : <></>}
        <FullWidthContainer
            display='flex'
        >
            <Title 
                title='Stores' 
                margin='auto auto auto 0'
            />
            <HMBox 
                float='right'
                display='flex'
            >
                <HMButton 
                    type='button'
                    bgColor={green}
                    isResponsive={true}
                    handleClick={handleCreate}
                    icon={<AddIcon sx={iconStyle} />} 
                    text={<Typography sx={textStyle}>Add Store</Typography>} 
                />
                <HMButton 
                    type='button'
                    bgColor={blue}
                    isResponsive={true}
                    icon={<UploadFileIcon sx={iconStyle} />} 
                    text={<Typography sx={textStyle}>Import Stores</Typography>} 
                />
            </HMBox>
            <HMModal 
                handleClose={handleClose} 
                open={open} 
                colors={{lightBlack, lightBlue}}
                title={title}
            >
                <Typography sx={deleteTextStyle}>
                    Are you sure your want to delete: 
                </Typography>
                {isDelete ? (
                    <Typography sx={deleteItemTextStyle}>
                    {currentStore.name} ?
                    </Typography>
                ) : !selectedStores.length ? (
                    <Typography sx={deleteItemTextStyle}>
                    No store selected. Select stores and try again!
                    </Typography>
                ) : selectedStores.length > 10 ? (
                    <Typography sx={deleteItemTextStyle}>
                    {selectedStores.length} Stores?
                    </Typography>
                ) : (
                    <Box sx={{marginBottom: '20px'}}>
                    {selectedStores.map((store, index) => (
                        <Typography 
                            sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                            key={index}
                        >
                            {store.name}
                        </Typography>
                    ))}
                    </Box>
                )}
                {(selectedStores.length || isDelete) && (
                    <HMButton 
                    type='button'
                    text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                    bgColor={red}
                    handleClick={isDelete ? handleDelete : handleBulkDelete}
                    float='right'
                    margin='auto'
                    />
                )}
                <HMButton 
                    type='button'
                    text={<Typography sx={textStyle}>No, Cancel!</Typography>}
                    bgColor={gray}
                    handleClick={handleClose}
                    float={(selectedStores.length || isDelete) && 'left'}
                    margin='auto'
                />
            </HMModal>
        </FullWidthContainer>
        {isFinal && stores.length ? (
            <DataTable
                data={stores}
                title='Stores'
                fields={fields}
                columns={columns}
                defaultRowsNum={20}
                handleEdit={handleEdit}
                handleDelete={handleDeleteModal}
                emptyDataMessage='No stores available!'
                handleBulkDelete={handleBulkDeleteModal}
            />
        ) : <></>}
    </Page>
  )
}

export default Stores
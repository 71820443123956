import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import HMBox from '../../components/common/layout/HMBox'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import { 
    fontSize,
    textStyle
} from '../../components/common/tools/Styles'
import {
    green,
    white,
    orange,
    darkBlue,
    lightBlue,
} from '../../hooks/useColors'
import { 
  currencies
} from '../../hooks/helperFunctions'

function POSComputations({ computeObj, isDisabled, handleClick, isSummary }) {
    const {
        tax, 
        total, 
        basket,
        coverage, 
        subtotal, 
        discount, 
        insCoverage,
        insurancePayment
    } = computeObj
    const currency = currencies.find(curr => curr.country === 'Rwanda').currency

    return (
        <>
            <HMBox
                width='100%'
                display='flex'
                margin='5px auto'
                className='fade-in'
                bgColor={lightBlue}
                flexDirection='column'
            >
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <Title
                        margin='auto 0'
                        size={fontSize}
                        title='Subtotal'
                        color={darkBlue}
                        className='fade-in'
                    />
                    <Title
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        title={`${currency} ${subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <HMText
                        margin='auto 0'
                        text='Discount'
                        color={darkBlue}
                        className='fade-in'
                        fontSize={`${fontSize - 2}px`}
                    />
                    <HMText
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 2}px`}
                        text={`${currency} ${discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                    padding={insCoverage > 0 ? '0 5px 5px 5px' : '0 5px'}
                >
                    <HMText
                        text='Tax'
                        margin='auto 0'
                        color={darkBlue}
                        className='fade-in'
                        fontSize={`${fontSize - 2}px`}
                    />
                    <HMText
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 2}px`}
                        text={`${currency} ${tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                {insurancePayment > 0 ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        className='fade-in'
                    >
                    <HMText
                        margin='auto 0'
                        color={darkBlue}
                        className='fade-in'
                        fontSize={`${fontSize - 2}px`}
                        text={`Insurance Coverage ( ${basket.paymentInfo.method.toLowerCase() === 'insurance' ? coverage : 0}% )`} 
                    />
                    <HMText
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 2}px`}
                        text={`${currency} ${insurancePayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                    </HMBox>
                ) : <></>}
                <Box sx={{
                    opacity: 0.3,
                    height: '1px',
                    margin: '5px 0',
                    backgroundImage: 'repeating-linear-gradient(to right, transparent, transparent 5px, #000 5px, #000 10px)'
                }}></Box>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <Title
                        title='Total'
                        margin='auto 0'
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                    />
                    <Title
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        title={`${currency} ${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
            </HMBox>
            <HMButton 
                width='100%'
                type='button'
                color={white}
                disabled={isDisabled}
                margin='10px 0 auto auto'
                handleClick={handleClick}
                className='button fade-in'
                bgColor={isSummary ? orange : green}
                text={
                    <Typography 
                        sx={{ 
                            ...textStyle, 
                            fontWeight: 500, 
                            margin: isSummary ? '10px auto' : '5px auto', 
                            fontSize: `${isSummary ? fontSize + 2  : fontSize + 2}px`, 
                        }}
                    >
                        {isSummary ? 'View Summary' : 'Make Sale'}
                    </Typography>
                }
            />
        </>
    )
}

export default POSComputations
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import dayjs from 'dayjs'
import {
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import { 
  getSupplierListing, 
  resetSupplierListing, 
  updateSupplierListing,
} from '../../../features/supplierListings/supplierListingSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMDatePicker from '../../../components/common/tools/HMDatePicker'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMText from '../../../components/common/tools/HMText'
import { 
  iconStyle,
  textStyle, 
  addIconStyle,
  renoveIconStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  blue,
  white,
  green,
  orange,
  lightBlue,
  lightOrange,
} from '../../../hooks/useColors'

function EditSupplierListing() {
  const { listingId, id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const supplierListingState = useSelector(state => state.supplierListing)  
  const { 
    supplierListing
  } = supplierListingState

  const {
      windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  const breadOptions = [
    { text: 'Suppliers', link: '/admin/suppliers' },
    { text: Object.keys(supplierListing).length && supplierListing.supplier.name, link: `/admin/suppliers/${id}` },
    { text: 'listings', link: `/admin/suppliers/${id}/listings` },
    { text: 'Edit listing', link: null }
  ]

  const handleAdd = () => {
    navigate(`/admin/suppliers/${id}/listings/create`)
  }
  const handleView = () => {
    navigate(`/admin/suppliers/${id}/listings/${listingId}`)
  }
  
  const minExpirationDate = new Date()
  minExpirationDate.setDate(minExpirationDate.getDate() + 16)

  const defaultFormData = (listing) => {
    const checkData = listing && Object.keys(listing).length ? true : false
    const stockValues = []
    if (checkData) {
      for (let stock of listing.availableStock) {
        stockValues.push({
          quantity: stock.quantity,
          itemsPerBox: stock.itemsPerBox,
          expiresOn: dayjs(stock.expiresOn)
        })
      }
    }
    return {
      price: checkData ? listing.price : '',
      product: checkData ? listing.product : '',
      alertLevel: checkData ? listing.alertLevel : '',
      onlinePrice: checkData ? listing.onlinePrice : '',
      soldOnline: checkData ? listing.soldOnline : false,
      availableStock: checkData ? stockValues : [{
        quantity: 1,
        itemsPerBox: 12,
        expiresOn: dayjs(minExpirationDate),
      }],
    }
  }

  const [wrongStock, setWrongStock] = useState(false)
  const [stockCount, setStockCount] = useState(0)

  useEffect(() => {
    dispatch(getSupplierListing(listingId))
  }, [dispatch, listingId])

  useEffect(() => {
    const {
      isOne,
      isError,
      message,
      isUpdated,
    } = supplierListingState


    if (isError) {
      toast.error(message)
    } else if (isOne) {
      setFormData(defaultFormData(supplierListing))
    } else if (isUpdated) {
      toast.success('Supplier listing is updated successfuly!')
    }

    if (isUpdated || isError || isOne) {
      dispatch(resetSupplierListing())
    } 
  }, [dispatch, navigate, supplierListingState, supplierListing, listingId])

  const [formData, setFormData] = useState(defaultFormData(supplierListing))
  const {
    price,
    product,
    alertLevel,
    soldOnline,
    onlinePrice,
    availableStock,
  } = formData

  useEffect(() => {
    let qty = 0
    availableStock.forEach(stock => {
      if (stock['quantity']) {
        qty += parseInt(stock['quantity'])
      }

      if (
        !stock || 
        !stock['quantity'] || 
        !stock['expiresOn'] || 
        !stock['itemsPerBox'] || 
        isNaN(new Date(stock['expiresOn']).valueOf())
      ) {
        setWrongStock(true)
      } else {
        setWrongStock(false)
      }
    })
    setStockCount(qty)
  }, [availableStock])

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'soldOnline') {
      setFormData((prevState) => ({
          ...prevState,
          [name]: (value === 'true' || value === true) ? true : false,
      }))
    } else {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }

  const handleStocks = (index, e) => {
    const stockValues = [...availableStock]
    if (e['target']) {
      const value = e.target.value
      const name = e.target.name
      stockValues[index] = {
        ...stockValues[index],
        [name]: parseInt(value)
      }
    } else {
      stockValues[index] = {
        ...stockValues[index],
        expiresOn: e
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      availableStock: stockValues
    }))
  }

  const handleRemoveStock = (index) => {
    const stockValues = [...availableStock]
    if (availableStock.length > 1) {
      stockValues.splice(index, 1)
      setFormData((prevState) => ({
          ...prevState,
          availableStock: stockValues
      }))
    }
  }
  const handleAddStock = (index) => {
    const stockValues = [...availableStock]
    stockValues.splice(index + 1, 0, {
      quantity: 1,
      itemsPerBox: 12,
      expiresOn: dayjs(minExpirationDate),
    })
    setFormData((prevState) => ({
        ...prevState,
        availableStock: stockValues
    }))
  }
  
  const disabled = (
    wrongStock === true ||
    price === '' ||
    (product === '' || (product['name'] && product['name'] === '')) 
  ) ? true : false

  const handleUpdate = (e) => {
    e.preventDefault()

    const updatedSupplierLisiting = {
      soldOnline,
      supplier: id,
      _id: listingId,
      product: product._id,
      price: parseFloat(price),
      alertLevel: alertLevel && parseInt(alertLevel),
      onlinePrice: onlinePrice && parseFloat(onlinePrice),
      availableStock: availableStock.map(stock => ({
        ...stock,
        expiresOn: stock.expiresOn.$d
      })),
    }

    if (!updatedSupplierLisiting.alertLevel) {
      delete updatedSupplierLisiting['alertLevel']
    } 

    if (!updatedSupplierLisiting.onlinePrice) {
      delete updatedSupplierLisiting['onlinePrice']
    } 

    dispatch(updateSupplierListing(updatedSupplierLisiting))
  }

  const sellOnline = {
    question: 'Sell online?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }

  return (
    <Page>
      {supplierListingState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      {Object.keys(supplierListing).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMButton 
                type='button'
                bgColor={orange}
                isResponsive={true}
                handleClick={handleView}
                icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>View</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add Listing</Typography>} 
              />
            </HMBox>
          </FullWidthContainer>
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid 
              component='form'
              container spacing={2} 
              onSubmit={handleUpdate}
            >
              <Grid item xs={12} sm={12} className='HM-dropdown listing'>
                  <DropdownFilter 
                    type='text'
                    field='name'
                    options={[]}
                    disabled={true}
                    value={product}
                    required={true}
                    label='Product name'
                    prompt='Select product'
                  />
              </Grid>
              <Grid item xs={12} sm={3} sx={{display : 'flex'}}>
                <HMRadioButton 
                  name='soldOnline'
                  padding='2px 5px'
                  value={soldOnline}
                  flexDirection='row'
                  onChange={handleChange}
                  label={sellOnline.question}
                  options={sellOnline.choices}
                  labelId='tax-applicable-radio-group'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <HMTextField 
                  width='100%'
                  name='price'
                  type='number'
                  value={price}
                  required={true}
                  label='Listing price'
                  onChange={handleChange}
                  placeholder='Enter listing price'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <HMTextField 
                  width='100%'
                  type='number'
                  name='onlinePrice'
                  value={onlinePrice}
                  onChange={handleChange}
                  label='Online listing price'
                  placeholder='Enter online listing price'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <HMTextField 
                  width='100%'
                  type='number'
                  name='alertLevel'
                  value={alertLevel}
                  onChange={handleChange}
                  label='Stock alert level'
                  placeholder='Enter stock alert level'
                />
              </Grid>
              {availableStock.map((stock, index) => (
                <Grid key={index} item xs={12} sm={10}>
                  <HMBox 
                    padding='0' 
                    width='100%' 
                    display='flex' 
                    margin={!isTabletScreen && '10px auto'}
                  >
                    {(stockCount > 0 && index === 0) ? (
                    <HMBox
                      position='absolute'
                      bgColor={lightOrange}
                      left={isTabletScreen ? '45%' : '48%'}
                      marginTop={isTabletScreen ? '-22px' : '-14px'}
                    >
                      <HMText 
                        margin='auto'
                        fontWeight={500}
                        text={stockCount}
                      />
                    </HMBox>
                    ) : <></>}
                    <HMBox
                      width='100%'
                      margin='auto'
                      display='flex'
                      bgColor={lightBlue}
                      padding='5px 10px 5px 10px'
                      flexDirection={isTabletScreen ? 'column' : 'row'}
                    >
                      <HMTextField
                        type='number'
                        name='quantity'
                        required={true}
                        bgColor={white}
                        label='Number of boxes'
                        placeholder='Enter number of boxes'
                        width={isTabletScreen ? '100%' : '50%'}
                        onChange={(e) => handleStocks(index, e)}
                        value={stock.quantity ? stock.quantity : ''}
                        margin={isTabletScreen ? '5px 0' : 'auto 5px auto auto'}
                      /> 
                      <HMTextField
                        type='number'
                        required={true}
                        bgColor={white}
                        name='itemsPerBox'
                        label='Items per box'
                        width={isTabletScreen ? '100%' : '50%'}
                        onChange={(e) => handleStocks(index, e)}
                        placeholder='Enter number of items per box'
                        value={stock.itemsPerBox ? stock.itemsPerBox : ''}
                        margin={isTabletScreen ? '5px 0' : 'auto auto auto 5px'}
                      /> 
                      <HMBox
                        width='100%'
                        height='100%'
                        margin='auto'
                        display='flex'
                        padding='5px 0'
                      >
                        <HMText 
                          width='100%'
                          margin='auto'
                          text='Epiration date'
                          textAlign={isTabletScreen ? 'left' : 'center'}
                        />
                        <HMDatePicker
                          margin='auto 0 auto auto'
                          minDate={dayjs(minExpirationDate)}
                          onChange={(e) => handleStocks(index, e)}
                          value={stock.expiresOn ? dayjs(stock.expiresOn) : dayjs(minExpirationDate)}
                        />
                      </HMBox>
                    </HMBox>
                    {availableStock.length > 1 && (
                      <IconButton 
                        onClick={() => handleRemoveStock(index)}
                        sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                      >
                        <RemoveIcon sx={renoveIconStyle} />
                      </IconButton>
                    )}
                    {availableStock.length < 10 && (
                      <IconButton   
                        onClick={() => handleAddStock(index)} 
                        sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                      >
                        <AddIcon sx={addIconStyle} />
                      </IconButton>
                    )}  
                  </HMBox>
                </Grid>
              ))}
              <Grid item xs={12} sm={12}>
                <HMButton 
                  width='100%'
                  type='submit'
                  float='right'
                  padding='6px'
                  bgColor={blue}
                  disabled={disabled}
                  margin='20px 0 5px 0'
                  paddingActive='5.5px'
                  text={<Typography sx={textStyle}>Update Listing</Typography>}
                />
              </Grid> 
            </Grid>
          </Section>
        </>
      ) : <></>}
    </Page>
  )
}

export default EditSupplierListing
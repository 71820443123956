import React from 'react'
import Container from '@mui/material/Container'
import {
  containerStyle,
} from '../tools/Styles'

function HMContainer(props) {
    const stylingContainer = {
        ...containerStyle,
        width: props.width,
        marginTop: props.marginTop,
        marginLeft: props.marginLeft,
        backgroundColor: props.bgColor,
    }

    return (
        <Container 
            sx={stylingContainer}
            className={`HM-container ${props.className}`}
        >
            {props.children}
        </Container>
    )
}

export default HMContainer
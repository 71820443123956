import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { 
  resetSearch,
  searchOverallProducts,
} from '../../features/search/searchSlice'
import HMProductCard from '../../components/common/layout/HMProductCard'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import { 
  fontSize,  
  textStyle,
  deleteItemTextStyle
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  blue,
  white,
  orange,
  lightOrange
} from '../../hooks/useColors'

function Search() {
  const { searchText } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    visible
  } = useSelector((state) => state.nav)
  const { 
    searchLoading,
    foundOverallProducts,
    areFoundOverallProducts,
  } = useSelector((state) => state.search)

  const {
    windowW
  } = useWindowDimensions()

  const defaultLimit = 12

  const [limit, setLimit] = useState(defaultLimit)

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false

  useEffect(() => {
    if (searchText) {
      dispatch(searchOverallProducts({searchText, limit}))
    }
  }, [dispatch, searchText, limit])

  useEffect(() => {
    if (areFoundOverallProducts) {
      dispatch(resetSearch())
    }
  }, [dispatch, areFoundOverallProducts])
  
  const handleProductClick = (product) => {
    navigate(`/categories/${product.category.id.parent._id}/${product.category.id._id}/${product._id}`)
  }

  const textStyling = {
    ...textStyle,
    textAlign: 'center',
    fontSize: `${fontSize + 4}px`
  }

  const loadmore = () => {
    setLimit(limit + defaultLimit)
  }

  return (
    <Page>
      {searchLoading && foundOverallProducts.length ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <Section 
          padding={10}
          bgColor={white}
          paddingBottom={30}
      >
          {searchLoading && !foundOverallProducts.length ? (
            <HMSpiner 
              size={60}
              zIndex={999}
              width='100%'
              height='80vh'
              margin='auto'
              bgColor='transparent'
            />
          ) : foundOverallProducts.length ? (
              <>
                <HMBox
                  width='100%'
                  display='flex'
                >
                  <HMBox
                    margin='auto'
                    padding='30px 10px'
                  >
                    <Typography sx={textStyling} component='span'>
                      Resutlts for your search&nbsp;&nbsp;
                    </Typography>
                    <Typography sx={deleteItemTextStyle} component='span'>
                      {searchText}
                    </Typography>
                    <Typography sx={textStyling} component='span'>
                      &nbsp;&nbsp; {foundOverallProducts.length > 1 ? 'are ' : 'is '} displayed below!
                    </Typography>
                  </HMBox>
                </HMBox>
                <Grid container spacing={2}>
                    {foundOverallProducts.map((data, index) => (
                      <Grid 
                        item 
                        xs={6} 
                        key={index}
                        sx={{display: 'flex'}}
                        sm={
                          isSmallTabletScreen ? 4 
                          : isTabletScreen ? 3  
                          : (isSamllComputerScreen && visible) ? 4
                          : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                          : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                          : 1
                        }
                      >
                        <HMProductCard 
                          className='fade-in'
                          isStockAvailable={true}
                          title={data.product.name}
                          image={data.product.image}
                          handleClick={() => handleProductClick(data.product)}
                          price={data.storesAndListings[0].storeListing.onlinePrice}
                        />
                      </Grid>
                    ))}
                    {limit === foundOverallProducts.length ? (
                      <Grid item xs={12} sm={12}>
                        <HMButton 
                          color={orange}
                          borderRadius={25}
                          margin='20px auto'
                          hoverColor={white}
                          padding='5px 10px' 
                          hoverBgColor={blue}    
                          text='Load more...'
                          bgColor={lightOrange}
                          handleClick={loadmore}
                          borderColor={lightOrange}
                          paddingActive='4.5px 9.5px'
                          fontSize={`${fontSize - 2}px`}
                        />
                      </Grid>
                    ) : <></>}
                </Grid>
              </>
          ) : (
            <HMBox
              width='100%'
              display='flex'
            >
              <HMBox
                margin='auto'
                padding='30px 10px'
              >
                <Typography sx={textStyling} component='span'>
                  So sorry! We looked everywhere but your search&nbsp;&nbsp;
                </Typography>
                <Typography sx={deleteItemTextStyle} component='span'>
                  {searchText}
                </Typography>
                <Typography sx={textStyling} component='span'>
                  &nbsp;&nbsp;doesn't much any product or medicine!
                </Typography>
              </HMBox>
            </HMBox>
          )}
      </Section>
    </Page>
  )
}

export default Search
import React, { useState } from 'react'
import { Typography, Box, Grid, Checkbox } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMText from '../../components/common/tools/HMText'
import HMButton from '../../components/common/tools/HMButton'
import { textStyle } from '../../components/common/tools/Styles'
import {
  lightBlack,
  orange,
  darkBlue,
  lightGreen,
  lightBlue
} from '../../hooks/useColors'
import HMImage from '../../components/common/tools/HMImage'
import DataTable from '../../components/common/layout/DataTable'

function Billing() {
  const cardStyle = {
    '&:hover': {
      opacity: 0.8,
      backgroundColor: lightBlue,
    },
    widows: '80%',
    border: `1px solid #D9D9D9`,
    padding: '20px',
    borderRadius: '15px',
    margin: '0 auto 10px',
  }

  const activeStyle = { ...cardStyle, backgroundColor: lightBlue }

  const styles = {
    radio: {
      '&$checked': {
        back: '#4B8DF8',
      },
    },
  }

  const [visaChecked, setVisaChecked] = useState(false)
  const [mastercardChecked, setMastercardChecked] = useState(false)
  const [mtnChecked, setMtnChecked] = useState(false)
  const [airtelChecked, setAirtelChecked] = useState(false)

  const handleChangeVisa = (e) => {
    setVisaChecked(e.target.checked)
    setMastercardChecked(false)
    setMtnChecked(false)
    setAirtelChecked(false)
  }

  const handleChangeMastercard = (e) => {
    setMastercardChecked(e.target.checked)
    setVisaChecked(false)
    setMtnChecked(false)
    setAirtelChecked(false)
  }

  const handleChangeMtn = (e) => {
    setMtnChecked(e.target.checked)
    setVisaChecked(false)
    setMastercardChecked(false)
    setAirtelChecked(false)
  }

  const handleChangeAirtel = (e) => {
    setAirtelChecked(e.target.checked)
    setVisaChecked(false)
    setMastercardChecked(false)
    setMtnChecked(false)
  }
  const fields = []
  const columns = [
    { field: 'invoice', headerName: 'Invoice', sortable: true },
    { field: 'method', headerName: 'Method', sortable: true },
    { field: 'amount', headerName: 'Amount', sortable: true },
    { field: 'date', headerName: 'Date', sortable: false },
    { field: 'status', headerName: 'Status', sortable: true },
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  const bills = [
    {
      invoice: 'CART-INV-007212022',
      method: 'MTN Mobile Money',
      amount: '100000fr',
      date: '12/jun/2022',
      status: 'pending',
    },
    {
      invoice: 'CART-INV-007212022',
      method: 'VISA',
      amount: '100000fr',
      date: '12/jun/2022',
      status: 'Paid',
    },
    {
      invoice: 'CART-INV-007212022',
      method: 'Mastercard',
      amount: '100000fr',
      date: '12/jun/2022',
      status: 'Paid',
    },
  ]
  return (
    <Page>
      <FullWidthContainer>
        <Grid container sx={{ margin: 'auto' }}>
          <Grid item sm={10} sx={{ marginBottom: '50px' }}>
            <HMText
              text="Payment method"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Update your billing details. From here, you can add or remove your existing one"
              padding="12px 12px"
              size="16px"
            />
            {/* <Divider width="100%" /> */}
          </Grid>
          <Grid
            item
            container
            md={7}
            xs={12}
            sx={visaChecked ? activeStyle : cardStyle}
          >
            <Grid item sm={3}>
              <HMImage
                src="https://res.cloudinary.com/harakameds/image/upload/s--qGM-H6R0--/v1660064773/HMLogo/Visa_btkjgw.png"
                alt="Visa image"
                width="10vh"
                height="10vh"
              />
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <HMText
                color={ darkBlue}
                fontWeight="700"
                float="initial"
                text="Visa ending in 1234"
              />
              <HMText
                color={ lightBlack}
                fontWeight="700"
                float="initial"
                text="Expirery 07/2024"
              />
              <Box>
                <HMText
                  margin="12px auto"
                  color={lightBlack}
                  text="Set as default"
                />
                <HMButton
                  float="initial"
                  fontSize="16px"
                  borderRadius="10px"
                  margin="6px "
                  type="button"
                  color={orange}
                  text={<Typography sx={textStyle}>Edit </Typography>}
                />
              </Box>
            </Grid>
            <Grid item sm={1}>
              <Checkbox
                icon={<RadioButtonUncheckedIcon sx={{ color: '#D9D9D9' }} />}
                checkedIcon={<CheckCircleIcon sx={{ color: orange }} />}
                checked={visaChecked}
                onChange={handleChangeVisa}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0.2}
            md={7}
            xs={12}
            sx={mastercardChecked ? activeStyle : cardStyle}
          >
            <Grid item sm={3}>
              <HMImage
                src="https://res.cloudinary.com/harakameds/image/upload/s--WgAZpqke--/v1662632327/HMLogo/mastercard-logo-removebg-preview_ds3twh.png"
                alt="Mastercard image"
                width="9vh"
                height="8vh"
              />
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <HMText
                color={darkBlue}
                fontWeight="700"
                float="initial"
                text="Mastercard ending in 1234"
              />
              <HMText
                color={lightBlack}
                fontWeight="700"
                float="initial"
                text="Expirery 07/2024"
              />
              <Box>
                <HMText
                  margin="12px auto"
                  color={lightBlack}
                  text="Set as default"
                />
                <HMButton
                  float="initial"
                  fontSize="16px"
                  borderRadius="10px"
                  margin="6px "
                  type="button"
                  color={orange}
                  text={<Typography sx={textStyle}>Edit </Typography>}
                />
              </Box>
            </Grid>
            <Grid item sm={1}>
              <Checkbox
               icon={<RadioButtonUncheckedIcon sx={{ color: '#D9D9D9' }} />}
               checkedIcon={<CheckCircleIcon sx={{ color: orange }} />}
                checked={mastercardChecked}
                onChange={handleChangeMastercard}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0.2}
            md={7}
            xs={12}
            sx={mtnChecked ? activeStyle : cardStyle}
          >
            <Grid item sm={3}>
              <HMImage
                src="https://res.cloudinary.com/harakameds/image/upload/s--g_f7U1pQ--/v1659545578/HMLogo/mtn-image_zetpdb.jpg"
                alt="MTN image"
                width="10vh"
                height="10vh"
              />
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <HMText
                color={ darkBlue}
                fontWeight="700"
                float="initial"
                text="MTN Mobile Money"
              />
              <HMText
                color={lightBlack}
                fontWeight="700"
                float="initial"
                text="Expirery 07/2024"
              />
              <Box>
                <HMText
                  margin="12px auto"
                  color={lightBlack}
                  text="Set as default"
                />
                <HMButton
                  float="initial"
                  fontSize="16px"
                  borderRadius="10px"
                  margin="6px "
                  type="button"
                  color={orange}
                  text={<Typography sx={textStyle}>Edit </Typography>}
                />
              </Box>
            </Grid>
            <Grid item sm={1}>
              <Checkbox
               icon={<RadioButtonUncheckedIcon sx={{ color: '#D9D9D9' }} />}
               checkedIcon={<CheckCircleIcon sx={{ color: orange }} />}
                checked={mtnChecked}
                onChange={handleChangeMtn}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0.2}
            md={7}
            xs={12}
            sx={airtelChecked ? activeStyle : cardStyle}
          >
            <Grid item sm={3}>
              <HMImage
                src="https://res.cloudinary.com/harakameds/image/upload/s--uKeE5RBO--/v1659545578/HMLogo/airtel-image_jqepqb.jpg"
                alt="Airtel image"
                width="10vh"
                height="10vh"
              />
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <HMText
                color={darkBlue}
                fontWeight="700"
                float="initial"
                text="Airtel Money"
              />
              <HMText
                color={lightBlack}
                fontWeight="700"
                float="initial"
                text="Expirery 07/2024"
              />
              <Box>
                <HMText
                  margin="12px auto"
                  color={lightBlack}
                  text="Set as default"
                />
                <HMButton
                  float="initial"
                  fontSize="16px"
                  borderRadius="10px"
                  margin="6px "
                  type="button"
                  color={orange}
                  text={<Typography sx={textStyle}>Edit </Typography>}
                />
              </Box>
            </Grid>
            <Grid item sm={1}>
              <Checkbox
               icon={<RadioButtonUncheckedIcon sx={{ color: '#D9D9D9' }} />}
               checkedIcon={<CheckCircleIcon sx={{ color: orange }} />}
                sx={styles}
                checked={airtelChecked}
                onChange={handleChangeAirtel}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            md={7}
            xs={10}
            sx={{
              widows: '80%',
              padding: '20px',
              borderRadius: '15px',
              margin: '0 auto 10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <HMButton
              float="initial"
              color={lightBlack}
              fontSize="16px"
              padding="0 auto"
              margin="0"
              type="button"
              icon={<AddIcon sx={{ color: lightBlack }} />}
              text={
                <Typography sx={textStyle}>Add new payment method </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item container>
            <Grid item sm={9} xs={12}>
              <HMText
                float="initial"
                size="20px"
                fontWeight="700"
                text="Billing history"
              />
              <HMText
                float="initial"
                text="Access all you previous invoices."
              />
            </Grid>
          </Grid>
        </Grid>
      </FullWidthContainer>

      <DataTable
        data={bills}
        columns={columns}
        fields={fields}
        title="Bills History"
        defaultRowsNum={20}
      />
    </Page>
  )
}

export default Billing

import _ from 'lodash'
import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import cartService from './cartService'

const cart = JSON.parse(localStorage.getItem('cart')) || {}

const initialState = {
    cart: cart,
    counter: 0,
    message: '',
    cartData: [],
    isError: false,
    isAdded: false,
    isSuccess: false,
    isLoading: false,
    isUpdated: false,
}

// Add cart
export const addCart = createAsyncThunk(
    'cart/add',
    async (cartItem, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await cartService.addCart(cartItem, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update cart
export const updateCart = createAsyncThunk(
    'cart/update',
    async (updatedCart, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await cartService.updateCart(updatedCart, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get cart
export const getCart = createAsyncThunk(
    'cart/get',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await cartService.getCart(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
                ) || error.message || error.toString()
                return thunkAPI.rejectWithValue(message)
            }
        }
)

// Set cart data
export const setCartData = createAsyncThunk(
    'cart/setData',
    async (cartData) => {
        return cartData
    }
)

// set counter 
export const setCounter = createAsyncThunk(
    'cart/setCounter',
    async (count) => {
        return count
    }
)

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        resetCart: (state) => {
            state.message = ''
            state.isAdded = false
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateCart.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isUpdated = false
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isUpdated = true
                state.cart = action.payload

                const data = action.payload
                const cart = JSON.parse(localStorage.getItem('cart'))

                if (!(_.isEqual(data, cart))) {
                    localStorage.setItem('cart', JSON.stringify(data))
                }
            })
            .addCase(updateCart.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isUpdated = false
                state.message = action.payload
            })

            .addCase(addCart.pending, (state) => {
                state.isError = false
                state.isAdded = false
                state.isLoading = true
            })
            .addCase(addCart.fulfilled, (state, action) => {
                state.isAdded = true
                state.isLoading = false
                state.cart = action.payload

                const data = action.payload
                const cart = JSON.parse(localStorage.getItem('cart'))

                if (!(_.isEqual(data, cart))) {
                    localStorage.setItem('cart', JSON.stringify(data))
                }
            })
            .addCase(addCart.rejected, (state, action) => {
                state.isError = true
                state.isAdded = false
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(getCart.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getCart.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isLoading = false
                state.cart = action.payload

                const data = action.payload
                const cart = JSON.parse(localStorage.getItem('cart'))

                if (!(_.isEqual(data, cart))) {
                    localStorage.setItem('cart', JSON.stringify(data))
                }
            })
            .addCase(getCart.rejected, (state, action) => {
                state.isError = true
                state.isSuccess = false
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(setCartData.fulfilled, (state, action) => {
                state.cartData = action.payload
            })

            .addCase(setCounter.fulfilled, (state, action) => {
                state.counter = action.payload
            })
    }
})

export const { resetCart } = cartSlice.actions
export default cartSlice.reducer
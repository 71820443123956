import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {
    white,
    orange
} from '../../../hooks/useColors'

export default function HMSpiner(props) {

    const style = {
        top: props.top,
        left: props.left,
        width: props.width,
        right: props.right,
        bottom: props.bottom,
        zIndex: props.zIndex,
        height: props.height,
        margin: props.margin,
        padding: props.padding,
        position: props.position,
        marginLeft: props.marginLeft,
        display: props.display || 'flex',
        backgroundColor: props.bgColor || white,
        borderRadius: props.borderRadius || '50%',
    }

    return (
        <Box sx={style} className={`HM-spinner ${props.className}`}>
            <CircularProgress 
                size={props.size || 30} 
                thickness={props.thickness || 3} 
                sx={{ margin: 'auto', color: `${orange} !important` }} />
        </Box>
    )
}

import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import HMPaymentOption from './HMPaymentOption'
import HMBox from './HMBox'
import HMButton from '../tools/HMButton'
import {
    iconStyle,
    textStyle,
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions' 
import {
    gray,
    blue,
    green,
    orange,
    darkBlue,
} from '../../../hooks/useColors'


function HMPaymentOptions(props) {
    const {
        windowH,
    } = useWindowDimensions()

    const isBigHeightScreen = windowH < 1000 ? true : false
    const isNormalHeightScreen = windowH < 650 ? true : false
    const isSmallHeightScreen = windowH < 400 ? true : false
    const isVerySmallHeightScreen = windowH < 300 ? true : false
    
    return (
        <HMBox
            width='100%'
            display='flex'
            overflowY='auto'
            color={darkBlue}
            flexDirection='column'
            maxHeight={
                isVerySmallHeightScreen ? '50vh' 
                : isSmallHeightScreen ? '60vh' 
                : isNormalHeightScreen ? '70vh' 
                : isBigHeightScreen ? '80vh' 
                : '80vh'
            }
          >
            <HMButton 
              width='100%'
              type='button'
              color={blue}
              padding='20px'
              bgColor='inherit'
              borderRadius={10}
              paddingActive='20px'
              margin='10px auto 15px auto'
              border={`1px solid ${blue}`}
              handleClick={props.handleAddPaymentMethodModal}
              icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
              text={
                <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                    Add payment method
                </Typography>
              } 
            />
            <HMPaymentOption 
                selectedPaymentInfo={props.selectedPaymentInfo}
                handleSelectPaymentInfo={props.handleSelectPaymentInfo}
                paymentInfo={{ value: 'cash', method: 'Cash On Delivery' }}
                handleSetPaymentInfo={(info) => props.handleSetPaymentInfo(info)}
            />
            {props.paymentInfo.map((info, index) => (
                <HMPaymentOption 
                    key={index}
                    paymentInfo={info}
                    selectedPaymentInfo={props.selectedPaymentInfo}
                    handleSelectPaymentInfo={props.handleSelectPaymentInfo}
                    handleSetPaymentInfo={(info) => props.handleSetPaymentInfo(info)}
                />
            ))}
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='15px auto 0 auto'
                className='change-store-button fade-in'
            >
                <HMButton 
                    type='button'
                    width='100px'
                    fontWeight={500}
                    margin='auto 10px auto 0'
                    handleClick={props.handleExit}
                    bgColor={props.isBack ? orange : gray}
                    icon={props.isBack ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
                    text={
                        <Typography sx={textStyle}>
                            {props.isBack ? 'Back' : 'Cancel'}
                        </Typography>
                    }
                />
                <HMButton 
                    type='button'
                    width='100px'
                    bgColor={green}
                    margin='auto 0 auto auto'
                    disabled={props.disabledPaymentMethod}
                    handleClick={props.handleConfirmPaymentMethod}
                    text={
                        <Typography sx={textStyle}>
                            Confirm
                        </Typography>
                    }
                />
            </HMBox>
        </HMBox>
    )
}

export default HMPaymentOptions
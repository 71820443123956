import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import Page from '../../../components/common/layout/Page'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMButton from '../../../components/common/tools/HMButton'
import { fontSize, textStyle } from '../../../components/common/tools/Styles'
import {
  blue,
  green,
  lightBlue,
  red,
  white,
} from '../../../hooks/useColors'
import HMBox from '../../../components/common/layout/HMBox'
import Title from '../../../components/common/tools/Title'
import analyticsGreenIcon from '../../../assests/analyticsGreenIcon.png'
import analyticsRedIcon from '../../../assests/analyticsRedIcon.png'

import HMPieChart from '../../../components/common/tools/HMPieChart'
import HMDashboardCard from '../../../components/common/tools/HMDashboardCard'
import DashboardBottomCard from '../../../components/common/tools/DashboardBottomCard'
import HMLineChart from '../../../components/common/tools/HMLineChart'
import HMAreaChart from '../../../components/common/tools/HMAreaChart'
import HMDonutChart from '../../../components/common/tools/HMDonutChart'
import HMBarChart from '../../../components/common/tools/HMBarChart'


const PIE_CHART_DATA = [45, 9, 46];
const PIE_CHART_LABELS = ['Pending', 'Cancelled', 'Successful'];
const PIE_CHART_COLORS = ['#FFA500', '#FF7588', '#16D39A'];

const LINE_CHART_DATA =[
  {
    name: 'Cancelled',
    data: [60, 95, 60, 100, 42, 109, 80],
  },
  {
    name: 'Successful ',
    data: [20, 70, 50, 80, 34, 75, 41],
  },
  {
    name: 'Pending',
    data: [4, 40, 12, 50, 12, 52, 9],
  },
];
const LINE_CHART_COLORS = ['#FF7588', '#16D39A', '#F78800'];

const AREA_CHART_DATA = [
  {
    name: 'Cancelled',
    data: [11, 32, 45, 32, 34, 52, 41],
    type: 'area',
  },
  {
    name: 'Successful',
    data: [31, 40, 28, 51, 42, 109, 100],
    type: 'area',
  },
];
const AREA_CHART_COLORS = ['#FF7588','#16D39A'];

 const DONUT_CHART_DATA = [30, 39]
 const DONUT_CHART_LABELS = ['One-Time', 'Recurring'];
 const DONUT_CHART_COLORS = ['#FF7588','#16D39A'];

 const BAR_CHART_DATA = [
  {
    name: 'Successful Orders',
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  },
  {
    name: 'Cancelled Orders',
    data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
  },

];
const BAR_CHART_COLORS = [ '#16D39A', '#FF7588',]


const Stores = ()=> {
  return (
    <Page>
      <FullWidthContainer>
        <HMButton
          type="button"
          isResponsive={true}
          float="right"
          className="report-btn"
          //  handleClick={handleMakeReport}
          text={<Typography sx={textStyle}>Make report</Typography>}
        />
        <Grid container md={12} gap={1.5}>
          <Grid
            item
            xs={12}
            md={4.8}
            sx={{
              background: white,
              height: '270px',
              borderRadius: '20px',
              pt: 2,
            }}
          >
            <HMPieChart 
            title="Stores distribution"
            labels = {PIE_CHART_LABELS}
            data={PIE_CHART_DATA}
            colors= {PIE_CHART_COLORS}
            />
    
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              background: white,
              height: '270px',
              p: 2,
              borderRadius: '20px',
            }}
          >

            <Title title="Stores" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!HM-main-cards!!!!!!!!!!!!!!!!! */}
            <Box sx={{ mt: 8, padding: '0 5px', display: 'flex', gap: 1 }}>
              <HMDashboardCard
                 bgColor="#FFA500"
                text="Pending Stores"
                money={80}
              />

              <HMDashboardCard
                bgColor={red}
                text="Cancelled Stores"
                money={10}
              />

              <HMDashboardCard
                bgColor={green}
                text="Successful Stores"
                money={400}
              />
            </Box>
          </Grid>


          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: '500px',
              p: '30px 20px',
              borderRadius: '20px',
            }}
          >
            <Title title="Stores vs Value" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)'/>
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            {/* !!!!!!!!!!!!!!!!!!main-line Graph start here ---->>>>>> !!!!!!!!!!!!!!!!! */}

            <Box sx={{ width: '100%', height: '80%', mt: 8 }}>
              <HMLineChart data={LINE_CHART_DATA} colors={LINE_CHART_COLORS}  />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: '800px',
              p: '30px 33px',
              borderRadius: '20px',
            }}
          >

            <Title title="Most bought Stores vs Income" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            <Grid container sx={{ mt: 8 }} gap={2}>
              <Grid item xs={12} md={8} sx={{ background: white }}>
                <HMAreaChart data={AREA_CHART_DATA} colors={AREA_CHART_COLORS}  />
              </Grid>

              <Grid item xs={12} md={3.7} sx={{ background: white, p: 3 }}>

                <DashboardBottomCard
                 bgColor="#9DEDD5"
                  src={analyticsGreenIcon}
                  text="One-Time Customers"
                  money={60}
                />

                <DashboardBottomCard
                  bgColor="#FF75886B"
                  src={analyticsRedIcon}
                  text="Recurring Customers"
                  money={80}
                  mt = {4}
                />


                {/* !!!!!!!!!!!!!Donut-chart-here!!!!!!!!!!!!! */}
                <Box
                  sx={{
                    // background: '#9DEDD5',
                    padding: '20px 0 0',
                    mt: 2,
                  }}
                >
                  <Box sx={{ m: 'auto' }}>
                    <HMDonutChart data={DONUT_CHART_DATA} labels={DONUT_CHART_LABELS} colors={DONUT_CHART_COLORS} />
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: white,
              height: '500px',
              p: '30px 20px',
              borderRadius: '20px',
            }}
          >
            <Title title="Stores" size={fontSize + 4} fontWeight="700" color='rgba(47, 56, 84, 0.7)' />
            <HMBox
              float="right"
              bgColor={lightBlue}
              padding="0px"
              display="flex"
            >
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Week</Typography>}
              />
              <HMButton
                type="button"
                bgColor={blue}
                isResponsive={true}
                text={<Typography color="white">This Month</Typography>}
              />
              <HMButton
                type="button"
                bgColor={white}
                isResponsive={true}
                text={<Typography color="rgba(47, 56, 84, 0.5)">This Year</Typography>}
              />
            </HMBox>

            <Box sx={{ width: '100%', height: '80%', mt: 8 }}>
              <HMBarChart data={BAR_CHART_DATA} colors={BAR_CHART_COLORS}/>
            </Box>
          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  )
}

export default Stores


import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { 
    getMyStore,
} from '../../../features/stores/storeSlice'
import PaymentChannels from '../../../components/pos/wallet/PaymentChannels'
import Insurances from '../../../components/pos/wallet/Insurances'
import Overview from '../../../components/pos/wallet/Overview'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import Section from '../../../components/common/layout/Section' 
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import {
    fontSize,
    iconStyle,
    textStyle,
} from '../../../components/common/tools/Styles'

import {
  black,
  white,
  orange,
  darkBlue,
  lightBlue,
} from '../../../hooks/useColors'

const tabStyles = {
  mr: 0.2,
  color: black,
  background: white,
  borderRadius: '20px 20px 0 0',
  fontSize: { xs: '9px', sm: '9px' },
}

const tabsStyles = { 
  '& .MuiTabs-indicator':{ display: 'none'}, 
  "& .MuiTab-root.Mui-selected": {
    // color: white,
    // border: '0px solid black',
  }, 
  "& .MuiTab-root": {
    minWidth: 72,
    color: darkBlue,
    fontWeight: 600,
    fontSize: fontSize + 1,
    textTransform: 'initial',
    border: `2px solid ${lightBlue}`,
    fontFamily: [ 'Roboto',].join(',')
  }
}

const selectedTabStyles = {
  ...tabStyles,
  background: lightBlue,
}

function Wallet() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { storeId } = useParams()
    const { user } = useSelector((state) => state.auth)
    const storeState = useSelector((state) => state.store)
    const { myStores } = storeState

    const [tabValue, setTabValue] = useState(1)

    const breadOptions = [
      { text: 'Home', link: `/store-pos/${storeId}/landing` },
      { text: 'Wallet', link: null }
    ]

    useEffect(() => {
      if (user) {
        if (myStores.length) {
          dispatch(getMyStore(storeId))
        }
      }
      // eslint-disable-next-line
    }, [user, myStores, storeId])

    const handleTabChange = (e, value) => {
      setTabValue(value)
    }

    return user ? (
      <Page>
        <FullWidthContainer display='flex'>
          <HMBreadcrumbs 
            options={breadOptions}
            margin='auto auto auto 0'
          />
          <HMBox 
            padding='0'
            display='flex'
            margin='auto 0 auto auto'
          >
            <HMButton 
              type='button'
              bgColor={orange}
              isResponsive={true}
              margin='auto 0 auto auto'
              handleClick={() => navigate(-1)}
              text={<Typography sx={textStyle}>Back</Typography>} 
              icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
            />
          </HMBox>
        </FullWidthContainer>
        <Section 
          padding={10} 
          bgColor={white}
        >
          <Tabs
            sx={tabsStyles}
            value={tabValue}
            textColor='primary'
            variant='fullWidth'
            onChange={handleTabChange}
            aria-label='analytics tabs'
          >
            <Tab value={0} label='Overview' sx={tabValue === 0 ? selectedTabStyles : tabStyles} />
            <Tab value={1} label='Payment Channels' sx={tabValue === 1 ? selectedTabStyles : tabStyles} />
            <Tab value={2} label='Insurances' sx={tabValue === 2 ? selectedTabStyles : tabStyles} />
          </Tabs>
          {tabValue === 0 ? (
            <Overview />            
          ) : tabValue === 1 ? (
            <PaymentChannels />
          ) : tabValue === 2 ? (
            <Insurances />
          ) : <></>}
        </Section>
      </Page>
    ) : (
      <Section 
        padding={10} 
        bgColor={white}
      >
        <HMLoginFirst 
          message='You need to login first before accessing store content'
        />
      </Section>
    )
}

export default Wallet
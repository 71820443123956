import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ProgressBarWithData from '../../analytics/ProgressBarWithData'
import CreditDetailsCard from '../../analytics/CreditDetailsCard'
import RecentActivities from '../../analytics/RecentActivities'
import HMDonutChart from '../../analytics/HMDonutChart'
import DotAndLabel from '../../analytics/DotAndLabel'
import HMDataRange from '../../analytics/HMDataRange'
import HMPieChart from '../../analytics/HMPieChart'
import SimpleCard from '../../analytics/SimpleCard'
import HMBox from '../../common/layout/HMBox'
import HMButton from '../../common/tools/HMButton'
import HMText from '../../common/tools/HMText'
import Title from '../../common/tools/Title'
import { 
  fontSize,
  textStyle
} from '../../common/tools/Styles'
import {
  blue,
  white,
  darkBlue,
  lightBlue,
  lightBlack,
  middleOrange,
  superLightBlue,
  getRandomColor,
} from '../../../hooks/useColors'
import { currencies } from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

const money_sample = 1000000

function Overview() {
  const { storeId } = useParams()
  
  const { visible } = useSelector((state) => state.nav)
  const { storeSales } = useSelector((state) => state.store)

  const [endDate, setEndDate] = useState(dayjs())
  const [isFilter, setIsFilter] = useState(false)
  const [isAllTime, setIsAllTime] = useState(false)
  const [startDate, setStartDate] = useState(dayjs())
  const [isThisWeek, setIsThisWeek] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [isThisYear, setIsThisYear] = useState(false)
  const [isThisMonth, setIsThisMonth] = useState(false)
  const [isSmallWidth, setIsSmallWidth] = useState(false)

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 768 ? true : false
  const isCloseTabletScreen = windowW < 810 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false

  useEffect(() => {
    setIsSmallWidth(
      isSmallTabletScreen ? true 
      : (isTabletScreen || isCloseTabletScreen) ? true  
      : (isSamllComputerScreen && visible) ? true
      : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? false
      : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? false 
      : false
    )
    // eslint-disable-next-line
  }, [windowW, visible])
    
  const DONUT_CHART_DATA = [20, 10, 15, 15, 5, 10, 15, 10]
  const LABELS = ['MMI', 'RAMA', 'UAP', 'RADIANT', 'UNR', 'SORAS', 'CORAR', 'BRITAM']
  const DONUT_CHART_LABELS = LABELS.map((label, index) => ({ label, color: getRandomColor(), value: DONUT_CHART_DATA[index]}))

  const SparkLineGreen = [0, 40, 20, 60]
  const SparkLineRed = [60, 30, 40, 20]
  let IncreaseGreen = 0
  let IncreaseRed = 0

  const conditionalColors1 = () => {
    const [secondLast, last] = SparkLineGreen.slice(-2) // get the last two elements of data1 and store them in separate variables
    const color = last > secondLast ? ['#16D39A', '#16D39A'] : ['#FF7588', '#FF7588'] // use ternary operator to set color based on comparison
    IncreaseGreen += (last - secondLast)
    return [IncreaseGreen,color]
  }
  const [change1, color1] = conditionalColors1()
  const conditionalColors2 = () => {
    const [secondLast, last] = SparkLineRed.slice(-2) // get the last two elements of data1 and store them in separate variables
    const color = last > secondLast ? ['#16D39A', '#16D39A'] : ['#FF7588', '#FF7588'] // use ternary operator to set color based on comparison
    IncreaseRed += (last - secondLast)
    return [IncreaseRed,color]
  }
  const [change2, color2] = conditionalColors2()
  

  const onAllTimeClick = () => {
    setIsFilter(false)
    setIsAllTime(true)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(false)
  }
  const onThisWeekClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisWeek(true)
    setIsThisYear(false)
    setIsThisMonth(false)
  }
  const onThisMonthClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(true)
  }
  const onThisYearClick = () => {
    setIsFilter(false)
    setIsAllTime(false)
    setIsThisYear(true)
    setIsThisWeek(false)
    setIsThisMonth(false)
  }
  const onFilter = () => {
    setIsFilter(true)
    setIsAllTime(false)
    setOpenFilter(true)
    setIsThisWeek(false)
    setIsThisYear(false)
    setIsThisMonth(false)
  }

  const handleCloseFilter = () => {
    setOpenFilter(false)
  }
  
  const handleSartDateChange = (newValue) => {
    setStartDate(newValue)
  }
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue)
  }
  const handleCustomFilter = () => {
    const end_date_timestamp = Math.round(new Date(endDate.$d).getTime() / 1000)
    const start_date_timestamp = Math.round(new Date(startDate.$d).getTime() / 1000)
    
    // FILTER LOGIC GOES HERE 

    handleCloseFilter()
  }

  return (   
    <HMBox
      width='100%'
      padding='10px'
      display='flex'
      className='fed-in'
      bgColor={lightBlue}
      flexDirection='column'
      borderRadius='0 0 8px 8px'
    >
      <Grid 
        container 
        spacing={0}
        width='100%'
        sx={{ 
          padding: '10px',
          borderRadius: '8px',
          margin: '20px auto',
          backgroundColor: white 
        }}
      >
        <Grid 
          item xs={12} md={12} 
          sx={{ paddingBottom: '40px', display: 'flex', flexDirection: 'column' }} 
        >
          <HMDataRange 
            endDate={endDate}
            isFilter={isFilter}
            onFilter={onFilter}
            startDate={startDate}
            isAllTime={isAllTime}
            openFilter={openFilter}
            isThisWeek={isThisWeek}
            isThisYear={isThisYear}
            isThisMonth={isThisMonth}
            onAllTimeClick={onAllTimeClick}
            onThisYearClick={onThisYearClick}
            onThisWeekClick={onThisWeekClick}
            onThisMonthClick={onThisMonthClick}
            handleCloseFilter={handleCloseFilter}
            handleCustomFilter={handleCustomFilter}
            handleEndDateChange={handleEndDateChange}
            handleSartDateChange={handleSartDateChange}
          />
        </Grid> 
        <Grid 
          item xs={12} md={isSmallWidth ? 12 : 7}
          sx={{ display: 'flex', flexDirection: 'column' }} 
        >
          <HMBox
            padding='0'
            width='100%'
            margin='auto'
            display='flex' 
          >
            <HMDonutChart 
              labels={LABELS}  
              name_show={true}
              total_show={true}
              margin='0 auto auto 0'
              data={DONUT_CHART_DATA} 
              colors={DONUT_CHART_LABELS.map(item => item.color)}
            />
            <HMBox 
              width='100%'
              display='flex'
              flexDirection='column'
              margin='auto auto auto 0'
            >
              <HMText 
                fontWeight={400} 
                color={lightBlack}
                text='Combined Total' 
                margin='auto auto -5px 0'
                fontSize={`${fontSize + 4}px`}
              />
              <Title 
                margin='0'
                textAlign='left'
                color={darkBlue}
                size={isSmallTabletScreen ? fontSize + 10 : fontSize + 26}
                title={`${currency} ${money_sample.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
              />
              <HMBox 
                padding='0'
                width='100%'
                display='flex'
                margin='-5px auto auto 0'
              >
                {DONUT_CHART_LABELS.slice(0, 6).map((item, index) => (
                  <DotAndLabel
                    key={index}
                    title={item.label}
                    color={lightBlack}
                    dotColor={item.color}
                    fontSize={fontSize - 2}
                    margin={index === 0 ? 'auto 5px auto 0' : 'auto 5px'}
                  />
                ))}
              </HMBox>
              {DONUT_CHART_LABELS.length > 6 ? (
                <HMBox 
                  padding='0'
                  width='100%'
                  display='flex'
                  margin='0px auto auto 0'
                >
                  {DONUT_CHART_LABELS.slice(6, DONUT_CHART_LABELS.length).map((item, index) => (
                    <DotAndLabel
                      key={index}
                      title={item.label}
                      color={lightBlack}
                      dotColor={item.color}
                      fontSize={fontSize - 2}
                      margin={index === 0 ? 'auto 5px auto 0' : 'auto 5px'}
                    />
                  ))}
                </HMBox>
              ) : <></>}
            </HMBox>
          </HMBox>
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='20px auto 10px auto'
          >
            <Title 
              height='auto'
              color={darkBlue}
              fontWeight={500}
              size={fontSize + 2}
              margin='auto auto auto 0'
              title='Daily Sales with Insurances'
            />
          </HMBox>  
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
          >
            <SimpleCard 
              firstText='Today'
              margin='auto 5px auto 0'
              secondText={`${currency} ${money_sample.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            />
            <SimpleCard 
              firstText='Yesterday'
              bgColor={middleOrange}
              margin='auto 0 auto 5px'
              secondText={`${currency} ${money_sample.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            />
          </HMBox> 
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
          >
            <HMPieChart 
              width='100%'
              data={[ 50, 50 ]}
              bgColor={superLightBlue}
              labels={[ 'Paid', 'Unpaid' ]}
              title='Paid Insurances vs Unpaid Insurances'
              margin={isSmallWidth ? '5px auto' : 'auto 0'}
            />
          </HMBox> 
        </Grid>
        <Grid 
          item xs={12} md={isSmallWidth ? 12 : 5} 
          sx={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 10px' }} 
        > 
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='0 auto 10px auto'
          >
            <Title 
              height='auto'
              margin='10px 0'
              color={darkBlue}
              fontWeight={500}
              size={fontSize + 4}
              title='Insurances Distribution'
            />
          </HMBox> 
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='5px auto'
            flexDirection='column'
          >
            {DONUT_CHART_LABELS.slice(0, 4).map((item, index) => (
              <ProgressBarWithData 
                key={index}
                value={item.value}
                title='Online Sales'
                amount={money_sample}
                strokeColor={item.color}
              />        
            ))}
            {DONUT_CHART_LABELS.length > 4 ? (
              <HMButton 
                color={blue}
                margin='10px'
                borderRadius={25}
                padding='5px 10px'
                text='View more...'
                hoverColor={white}
                hoverBgColor={blue}
                bgColor={lightBlue}
                handleClick={() => {}}
                paddingActive='4.5px 9.5px'
                fontSize={`${fontSize - 2}px`}
              />
            ) : <></>}
          </HMBox>
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='20px auto 10px auto'
          >
            <Title 
              height='auto'
              color={darkBlue}
              fontWeight={500}
              size={fontSize + 4}
              margin='auto auto auto 0'
              title='Recent Activities'
            />
          </HMBox>  
          <RecentActivities 
            margin='0'
            limit={5}
            // activities={}
            // map={{
            //   type: 'type',
            //   date: 'createdAt',
            //   amount: 'orderTotal',
            //   paymentMethod: 'paymentInfo'
            // }}
            activities={new Array(20).fill({
              amount: 10000,
              type: 'Online',
              date: new Date(),
              paymentMethod: 'MTN Mobile Money'
            })}
          />          
        </Grid>
      </Grid>
     
      <Grid 
        container 
        spacing={0}
        width='100%'
        sx={{ 
          margin: '10px auto',
          borderRadius: '8px',
          backgroundColor: lightBlue 
        }}
      >
        {DONUT_CHART_LABELS.map((item, index) => (
          <Grid 
            item xs={12} md={6} key={index} 
          > 
            <HMBox
              width='100%'
              padding='10px'
              display='flex'
              bgColor={lightBlue} 
            >
              <HMBox
                width='100%'
                padding='10px'
                display='flex'
                bgColor={white} 
                flexDirection='column'
              >
                <HMBox
                  width='100%'
                  display='flex'
                  margin='10px auto'
                >
                  <DotAndLabel
                    fontWeight={600}
                    color={lightBlack}
                    title={item.label}
                    dotColor={item.color}
                    fontSize={fontSize + 4}
                    dotMargin='0 2.5px auto auto'
                  />
                  <HMText 
                    text='-'
                    fontWeight={600}
                    color={lightBlack}
                    margin='auto 10px'
                  />
                  <HMText 
                    fontWeight={600}
                    color={lightBlack}
                    margin='auto auto auto 0'
                    fontSize={`${fontSize + 4}px`}
                    text={`${currency} ${money_sample.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  />
                  <HMButton 
                    color={blue}
                    type='button'
                    bgColor='inherit'
                    borderRadius={10}
                    hoverColor={white}
                    hoverBgColor={blue}
                    handleClick={() => {}}
                    margin='auto 0 auto auto' 
                    border={`1px solid ${blue}`}
                    text={<Typography sx={textStyle} >View More</Typography>} 
                  /> 
                </HMBox>
                <HMBox
                  width='100%'
                  display='flex'
                >
                  <CreditDetailsCard 
                    colors={color1}
                    amount={'100k'} 
                    change={change1} 
                    title={'Received'} 
                    data={SparkLineGreen} 
                    margin='auto 5px auto 0'
                  />
                  <CreditDetailsCard 
                    amount={'300k'} 
                    colors={color2}
                    change={change2} 
                    data={SparkLineRed} 
                    title={'Receivable'} 
                    margin='auto 0 auto 5px'
                  />
                </HMBox>
              </HMBox>
            </HMBox>
          </Grid> 
        ))}
      </Grid>
    </HMBox>
  )
}

export default Overview

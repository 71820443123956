import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import meService from './meService'

// Get me from local storage
const me = JSON.parse(localStorage.getItem('me')) || {}

const initialState = {
    me: {},
    isOne: false,
    isError: false,
    isLoading: false,
    isUpdated: false,
    isDeleted: false,
    message: ''
}

// Update me
export const updateMe = createAsyncThunk(
    'me/update',
    async (updatedMe, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await meService.updateMe(updatedMe, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get me
export const getMe = createAsyncThunk(
    'me/one',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await meService.getMe(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
                ) || error.message || error.toString()
                return thunkAPI.rejectWithValue(message)
            }
        }
)

// Delete me
export const deleteMe = createAsyncThunk(
    'me/delete',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await meService.deleteMe(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        resetMe: (state) => {
            state.isOne = false
            state.isError = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateMe.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isUpdated = false
            })
            .addCase(updateMe.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isUpdated = true
                state.me = action.payload
            })
            .addCase(updateMe.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isUpdated = false
                state.message = action.payload
            })

            .addCase(deleteMe.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isDeleted = false
            })
            .addCase(deleteMe.fulfilled, (state, action) => {
                state.me = {}
                state.isError = false
                state.isDeleted = true
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(deleteMe.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.isDeleted = false
                state.message = action.payload
            })
            
            .addCase(getMe.pending, (state) => {
                state.isOne = false
                state.isError = false
                state.isLoading = true
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.isOne = true
                state.isError = false
                state.isLoading = false
                state.me = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })
    }
})

export const { resetMe } = meSlice.actions
export default meSlice.reducer

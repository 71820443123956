import React from 'react'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import HMBox from '../layout/HMBox'
import HMText from './HMText'

function DialItem(props) {
  return (
    <HMBox 
        display='flex'
        cursor={props.cursor}
        width={props.width || '100%'}
        onClick={props.handleMainClick}
        margin={props.margin || 'auto'}
    >
        <Paper 
            elevation={1} 
            sx={{
                margin: 'auto 10px auto auto'
            }}
        >
            <HMText 
                text={props.text}
                padding='5px 10px'
            />
        </Paper>
        <Paper 
            elevation={2} 
            sx={{
                borderRadius: '50%',
                margin: 'auto 20px auto 10px'
            }} 
        >
            <IconButton
                onClick={ props.handleClick }
            >
                { props.icon }  
            </IconButton>
        </Paper>
    </HMBox>
  )
}

export default DialItem
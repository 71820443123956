import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import basketService from './basketService'

const initialState = {
    basket: {},
    message: '',
    isOne: false,
    isError: false,
    isAdded: false,
    isLoading: false,
    isDeleted: false,
}

// Create basket
export const addBasket = createAsyncThunk(
    'basket/create',
    async (basket, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await basketService.addBasket(basket, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }    
)

// Get basket
export const getBasket = createAsyncThunk(
    'basket/get',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await basketService.getBasket(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }  
)

// Delete basket
export const deleteBasket = createAsyncThunk(
    'basket/delete',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await basketService.deleteBasket(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }  
)

export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        resetBasket: (state) => {
            state.message = ''
            state.isOne = false
            state.isError = false
            state.isAdded = false
            state.isLoading = false
            state.isDeleted = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBasket.pending, (state) => {
                state.isError = false
                state.isAdded = false
                state.isLoading = true
            })
            .addCase(addBasket.fulfilled, (state, action) => {
                state.isAdded = true
                state.isError = false
                state.isLoading = false
                state.basket = action.payload
            })
            .addCase(addBasket.rejected, (state, action) => {
                state.isError = true
                state.isAdded = false
                state.isLoading = false
                state.message = action.payload
            })
        
            .addCase(getBasket.pending, (state) => {
                state.isOne = false
                state.isError = false
                state.isLoading = true
            })
            .addCase(getBasket.fulfilled, (state, action) => {
                state.isOne = true
                state.isError = false
                state.isLoading = false
                state.basket = action.payload
            })
            .addCase(getBasket.rejected, (state, action) => {
                state.isOne = false
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })

            .addCase(deleteBasket.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isDeleted = false
            })
            .addCase(deleteBasket.fulfilled, (state, action) => {
                state.basket = null
                state.isError = false
                state.isDeleted = true
                state.isLoading = false
                state.message = action.payload.message
            })
            .addCase(deleteBasket.rejected, (state, action) => {
                state.isError = true
                state.isDeleted = false
                state.isLoading = false
                state.message = action.payload
            })
    }
})

export const { resetBasket } = basketSlice.actions
export default basketSlice.reducer

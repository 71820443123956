import React from 'react'
import Input from '@mui/material/Input'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import HMBox from '../../components/common/layout/HMBox'
import DropdownFilter from '../../components/common/tools/DropdownFilter'
import HMTextField from '../../components/common/tools/HMTextField'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import { 
  fontSize,
  iconStyle
} from '../../components/common/tools/Styles'
import {  
  supplierTypes, 
} from '../../hooks/helperFunctions'
import {
  white,
  lightRed,
  lightBlue,
} from '../../hooks/useColors'
import { cloudinary } from '../../hooks/helperFunctions'
import HMDropZone from '../common/tools/HMDropZone'

function StepTwo(props) {
  const {
    type,
    logo,
    phone,
    supplierName,
  } = props.formData 

  return (
    <>
      <HMBox
        padding='0'
        width='100%'
        display='flex'
        margin='5px auto'
        className='fade-in'
      >
        <HMText
          width='90px'
          text='Supplier Logo'
          margin='0 5px 5px 0'
        />
        <HMDropZone
           cloudinaryFileUrl={props.supplierLogo}
          setCloudinaryFileUrl={props.setSupplierLogo}
           endPoint={cloudinary.API_URL_LOGOS}
          bgColor={lightBlue}
          className="drag-drop"
          supportedFiles={{
            "image/jpeg": [],
            "image/jpg": [],
            "image/png": [],
          }}
          allowedSizeInMB={1}
        />
      </HMBox>
      {props.isSupplierNameTaken ? (
        <HMAlert 
          error={true}
          margin='10px auto'
          bgColor={lightRed}
          fontSize={fontSize - 2}
          text={`"${supplierName}" is already taken`}
        />
      ) : <></>}
      <HMTextField
        required={true}
        name='supplierName'
        value={supplierName}
        label='Supplier name'
        className='fade-in'
        onChange={props.handleChange}
        placeholder='Enter supplier name'
      />
      {props.wrongNumber ? (
        <HMAlert 
          error={true}
          margin='10px auto'
          bgColor={lightRed}
          fontSize={fontSize - 2}
          text={`Input a correct phone number`}
        />
      ) : <></>}
      {phone.map((item, index) => {
          const n = index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 ? '3rd' : index + 1 + 'th'
          return (
            <HMBox 
              padding='0' 
              key={index}
              width='100%' 
              display='flex' 
              className='fade-in'
            >
              <HMTextField
                value={item}
                name='phone'
                type='number'
                margin='5px 0'
                required={true}
                bgColor={white}
                placeholder='07XXXXXXXX'
                label={`${n} Phone number`}
                onChange={(e) => props.handlePhones(index, e)}
              /> 
              {phone.length > 1 && (
                <IconButton 
                  onClick={() => props.handleRemovePhone(index)}
                  sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                >
                  <RemoveIcon sx={iconStyle} />
                </IconButton>
              )}
              {phone.length < 3 && (
                <IconButton   
                  onClick={() => props.handleAddPhone(index)} 
                  sx={{padding: '5px', margin: 'auto 5px', height: '30px'}}
                >
                  <AddIcon sx={iconStyle} />
                </IconButton>
              )}  
            </HMBox>
          )
      })}
      <DropdownFilter 
        type='text'
        field='name'
        value={type}
        required={true}
        label='Supplier type'
        className='fade-in'
        prompt='Select supplier type'
        onChange={props.handleTypeChange}
        options={supplierTypes.map(type => ({name: type}))}
      />
    </>
  )
}

export default StepTwo
import React from 'react'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ForwardIcon from '@mui/icons-material/Forward'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import HMBox from '../../components/common/layout/HMBox'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  red,
  blue,
  green,
  orange,
  darkBlue,
  lightBlack,
  middleOrange,
} from '../../hooks/useColors'

function ApplicationStatus(props) {
    const ln = props.applicationStack.length
    const isApproved = props.applicationStack[ln - 1].status === 'Approved' ? true : false
    const isRejected = props.applicationStack[ln - 1].status === 'Rejected' ? true : false
    const {
        windowW,
    } = useWindowDimensions()
    
    const isTabletScreen = windowW < 600 ? true : false

    const tempApplicationStack = [ ...props.applicationStack ]

    return (
        <HMBox
            width='100%'
            display='flex'
            position='relative'
            className='fade-in'
            flexDirection='column'
        >
            {(isApproved || isRejected) ? (
                <HMBox
                    position='absolute'
                    width='max-content'
                    className='fade-in'
                    right={isTabletScreen ? 0 : '5%'}
                    margin={isTabletScreen ? '2.5% auto' : '20px auto auto auto'}
                >
                    <HMButton 
                        type='button'
                        margin='auto'
                        padding='5px 10px'
                        className='fade-in'
                        bgColor={
                            isApproved ? green : isRejected && orange
                        }
                        handleClick={
                            isApproved ? props.handleViewStore : isRejected && props.handleApplyAgain
                        }
                        rightIcon={
                            isApproved && <ForwardIcon sx={{...iconStyle, margin: 'auto auto auto 10px'}} />
                        } 
                        text={
                            <Typography sx={textStyle}>
                                {isApproved ? 'View store' : isRejected && 'Apply Again'}
                            </Typography>
                        } 
                        icon={
                            isRejected && <ForwardIcon sx={{...iconStyle, margin: 'auto 10px auto auto', transform: 'rotate(180deg)'}} />
                        } 
                    />
                </HMBox>
            ) : <></>}
            <HMBox
                margin='5% auto 10% auto'
                width={isTabletScreen ? '100%' : '500px'}
            >
                <Title
                    color={darkBlue}
                    margin='auto auto 50px auto'
                    title='Store Application Progress'
                    float={isTabletScreen ? 'left' : 'initial'}
                    size={isTabletScreen ? fontSize : fontSize + 2}
                />
                {tempApplicationStack.reverse().map((stage, index) => (
                    <HMBox
                        key={index}
                        padding='0'
                        width='100%'
                        display='flex'
                        className='fade-in'
                        flexDirection='column'
                    >
                        {index !== 0 ? (
                            <Grid 
                                container 
                                spacing={0}
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <Grid item sm={4} xs={4}></Grid>
                                <Grid 
                                    item 
                                    sm={2} 
                                    xs={2} 
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Divider 
                                        orientation='vertical' 
                                        sx={{ 
                                            height: 70,
                                            margin: 'auto', 
                                        }} 
                                    />
                                </Grid>
                                <Grid item sm={6} xs={6}></Grid>
                            </Grid>
                        ) : <></>}
                        <Grid 
                            container 
                            spacing={0}
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Grid 
                                item 
                                sm={4} 
                                xs={4} 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <HMText 
                                    className='fade-in'
                                    margin='auto 0 0 auto'
                                    fontSize={`${fontSize - 1}px`}
                                    fontWeight={index === 0 ? 500 : 400}
                                    color={index === 0 ? darkBlue : lightBlack}
                                    text={moment(stage.timestamp).utc().format('MMM DD, YYYY')}
                                />
                                <HMText 
                                    className='fade-in'
                                    margin='0 0 auto auto'
                                    fontSize={`${fontSize - 3}px`}
                                    fontWeight={index === 0 ? 500 : 400}
                                    color={index === 0 ? darkBlue : lightBlack}
                                    text={moment(stage.timestamp).utc().format('LT')}
                                />
                            </Grid>
                            <Grid 
                                item 
                                sm={2} 
                                xs={2} 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Brightness1Icon 
                                    sx={{ 
                                        margin: 'auto', 
                                        color: stage.status === 'Submitted' ? orange 
                                                : stage.status === 'Received' ? blue
                                                : stage.status === 'Rejected' ? red   
                                                : stage.status === 'Approved' ? green 
                                                : stage.status === 'Re-Applied' && middleOrange 
                                    }} 
                                />
                            </Grid>
                            <Grid 
                                item 
                                sm={6} 
                                xs={6} 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <HMText 
                                    className='fade-in'
                                    margin='auto auto 0 0'
                                    fontSize={`${fontSize - 1}px`}
                                    fontWeight={index === 0 ? 600 : 500}
                                    color={index === 0 ? darkBlue : lightBlack}
                                    text={
                                        stage.status === 'Submitted' ? 'Application submitted successfully' 
                                        : stage.status === 'Received' ? 'Application received successfully'
                                        : stage.status === 'Rejected' ? 'Application is rejected'   
                                        : stage.status === 'Approved' ? 'Application is approved' 
                                        : stage.status === 'Re-Applied' && 'Re-Application submitted successfully'   
                                    }
                                />
                                <HMText 
                                    className='fade-in'
                                    margin='0 auto auto 0'
                                    fontSize={`${fontSize - 3}px`}
                                    fontWeight={index === 0 ? 500 : 400}
                                    color={index === 0 ? darkBlue : lightBlack}
                                    text={
                                        stage.status === 'Submitted' ? 'Thank you for submitting the application' 
                                        : stage.status === 'Received' ? 'Our team is currently proccessing your application'
                                        : stage.status === 'Rejected' ?  stage.message  
                                        : stage.status === 'Approved' ? 'Congratulations! You can now start configuring your new store'   
                                        : stage.status === 'Re-Applied' && 'Thank you for submitting the application again'   
                                    }
                                />
                            </Grid>
                        </Grid>
                    </HMBox>
                ))}
            </HMBox>
        </HMBox>
    )
}

export default ApplicationStatus
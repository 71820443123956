import React from 'react'
import Typography from '@mui/material/Typography'
import HMModal from '../common/layout/HMModal'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import HMButton from '../common/tools/HMButton'
import HMDatePicker from '../common/tools/HMDatePicker'
import {
  textStyle
} from '../common/tools/Styles'
import {
  blue,
  gray,
  green,
  white,
  darkBlue,
  lightBlue,
  lightBlack
} from '../../hooks/useColors'
import useWindowDimensions from '../../hooks/useWindowDimensions'

function HMDataRange(props) {
  const {
    windowW
  } = useWindowDimensions()
  
  const isTabletScreen = windowW < 768 ? true : false

  const end_date_timestamp = Math.round(new Date(props.endDate.$d).getTime() / 1000)
  const start_date_timestamp = Math.round(new Date(props.startDate.$d).getTime() / 1000)

  const isFilterDisabled = start_date_timestamp >= end_date_timestamp

  return (
    <HMBox 
      display='flex'
      bgColor={lightBlue} 
      padding={props.padding || '0px'} 
      margin={props.margin || 'auto 0 auto auto'} 
    >
      <HMModal 
        maxHeight='95vh'
        open={props.openFilter} 
        colors={{lightBlack, lightBlue}}
        title='Filter with Custom Dates'
        handleClose={props.handleCloseFilter} 
      >
        <HMBox
          display='flex'
          flexDirection='column'
          margin='20px auto auto auto'
          width={isTabletScreen ? '100%' : '100%'}
        >
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            margin='5px auto 10px auto'
          >
            <HMText width='100px' text='Start Date' margin='auto 5px auto 0' />
            <HMDatePicker
              margin='auto 0 auto auto'
              maxDate={props.max || props.endDate}
              onChange={props.handleSartDateChange}
              value={props.startDate || new Date()}
            />
          </HMBox>
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            margin='5px auto 10px auto'
          >
            <HMText width='100px' text='End Date' margin='auto 5px auto 0' />
            <HMDatePicker
              minDate={props.minDate}
              margin='auto 0 auto auto'
              maxDate={props.max || new Date()}
              value={props.endDate || new Date()}
              onChange={props.handleEndDateChange}
            />
          </HMBox>
          <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={props.handleCloseFilter}
                text={<Typography sx={{ ...textStyle, color: darkBlue }}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                color={white}
                bgColor={green}
                margin='auto 0 auto auto'
                disabled={isFilterDisabled}
                handleClick={props.handleCustomFilter}
                text={<Typography sx={textStyle}>Submit</Typography>}
              />
          </HMBox>
        </HMBox>
      </HMModal>
      <HMButton
        type='button'
        handleClick={props.onAllTimeClick}
        bgColor={props.isAllTime ? blue : white}
        text={<Typography color={props.isAllTime ? white : darkBlue}>All Time</Typography>}
      />
      <HMButton
        type='button'
        handleClick={props.onThisWeekClick}
        bgColor={props.isThisWeek ? blue : white}
        text={<Typography color={props.isThisWeek ? white : darkBlue}>This Week</Typography>}
      />
      <HMButton
        type='button'
        handleClick={props.onThisMonthClick}
        bgColor={props.isThisMonth ? blue : white}
        text={<Typography color={props.isThisMonth ? white : darkBlue}>This Month</Typography>}
      />
      <HMButton
        type='button'
        handleClick={props.onThisYearClick}
        bgColor={props.isThisYear ? blue : white}
        text={<Typography color={props.isThisYear ? white : darkBlue}>This Year</Typography>}
      />
      <HMButton
        type='button'
        handleClick ={props.onFilter}
        bgColor={props.isFilter ? blue : white}
        text={<Typography color={props.isFilter ? white : darkBlue}>Filter</Typography>}
      />
    </HMBox>
  )
}

export default HMDataRange
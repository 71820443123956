import React from 'react'
import BackIcon from '@mui/icons-material/ArrowBack'
import Typography from '@mui/material/Typography'
import HMBox from '../common/layout/HMBox'
import HMButton from '../common/tools/HMButton'
import HMImage from '../common/tools/HMImage'
import Title from '../common/tools/Title'
import emptyCart from '../../assests/emptyCart.png'
import {
    fontSize,
    iconStyle,
    textStyle
} from '../common/tools/Styles'
import {
    blue,
    white,
    orange,
    darkBlue,
} from '../../hooks/useColors'

function EmptyCart(props) {
  return (
    <HMBox 
        width='100%'
        height='100%'
        display='flex'
        className='fade-in'
        flexDirection='column'
    >
        <HMImage 
            width='150px'
            src={emptyCart}
            className='fade-in'
            margin='auto auto 10px auto'
        />
        <Title
            color={darkBlue}
            title='Empty Cart'
            className='fade-in'
            size={fontSize + 20}
            margin='10px auto 10px auto'
        />
        <HMBox 
            width='100%'
            display='flex'
            className='fade-in'
            margin='10px auto auto auto'
        >
            <HMButton 
                width='130px'
                type='button'
                color={white}
                bgColor={orange}
                className='fade-in'
                margin='auto 10px auto auto'
                handleClick={props.handleBack}
                icon={<BackIcon sx={{...iconStyle, fontWeight: 500}} />} 
                text={<Typography sx={{...textStyle, fontWeight: 500}} > Go back </Typography>} 
            />
            <HMButton 
                width='130px'
                type='button'
                color={white}
                bgColor={blue}
                className='fade-in'
                margin='auto auto auto 10px'
                handleClick={props.handleAddProducts}
                text={<Typography sx={{...textStyle, fontWeight: 500 }} > Add products </Typography>} 
            />
        </HMBox>
    </HMBox>
  )
}

export default EmptyCart
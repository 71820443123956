import React, { useState } from 'react'
import { Box, Grid, Checkbox, Divider, Switch } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import Title from '../../components/common/tools/Title'
import HMText from '../../components/common/tools/HMText'
import { lightBlack, orange, lightOrange } from '../../hooks/useColors'

const FullRadioCheckBox = (props) => {
  return (
    <Box sx={{ marginLeft: '40px' }}>
      <Box sx={{ display: 'flex' }}>
        <Checkbox
          icon={<RadioButtonUncheckedIcon sx={{ color: lightBlack }} />}
          checkedIcon={<RadioButtonCheckedIcon sx={{ color: orange }} />}
          checked={props.checked}
          onChange={props.onChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box sx={{ marginTop: '15px' }}>
          <Title
            textAlign="initila"
            float="initila"
            size="16px"
            title={props.title}
          />
          <HMText
            color={lightBlack}
            fontSize="13px"
            fontWeight="600"
            margin="3px 0"
            float="initila"
            text={props.text}
          />
        </Box>
      </Box>
    </Box>
  )
}
function Notifications() {
  const [emailChecked, setEmailChecked] = useState(false)
  const [appChecked, setAppChecked] = useState(false)
  const [smsChecked, setSmsChecked] = useState(false)
  const [updateChecked, setUpdateChecked] = useState(false)
  const [tipsChecked, setTipsChecked] = useState(false)
  const [eventChecked, setEventChecked] = useState(false)
  const [researchChecked, setResearchChecked] = useState(false)

  const [notifyMeChecked, setNotifyMeChecked] = useState(false)
  const [importantRemChecked, setImportantRemChecked] = useState(false)
  const [allRemChecked, setAllRemChecked] = useState(false)

  const [activityNotifyMeChecked, setActivityNotifyMeChecked] = useState(false)
  const [activityImportantRemChecked, setActivityImportantRemChecked] = useState(false)
  const [activityAllRemChecked, setActivityAllRemChecked] = useState(false)

  const handleEmailChange = (e) => {
    setEmailChecked(e.target.checked)
  }

  const handleAppChange = (e) => {
    setAppChecked(e.target.checked)
  }

  const handleSmsChange = (e) => {
    setSmsChecked(e.target.checked)
  }

  const handleUpdateChange = (e) => {
    setUpdateChecked(e.target.checked)
  }

  const handleTipsChange = (e) => {
    setTipsChecked(e.target.checked)
  }

  const handleEventChange = (e) => {
    setEventChecked(e.target.checked)
  }

  const handleResearchChange = (e) => {
    setResearchChecked(e.target.checked)
  }

  const handleNotifyMeChange = (e) => {
    setNotifyMeChecked(e.target.checked)
    setImportantRemChecked(false)
    setAllRemChecked(false)
  }
  const handleImportantRemChange = (e) => {
    setImportantRemChecked(e.target.checked)
    setNotifyMeChecked(false)
    setAllRemChecked(false)
  }
  const handleAllRemChange = (e) => {
    setAllRemChecked(e.target.checked)
    setNotifyMeChecked(false)
    setImportantRemChecked(false)
  }

  const handleActivityNotifyMeChange = (e) => {
    setActivityNotifyMeChecked(e.target.checked)
    setActivityImportantRemChecked(false)
    setActivityAllRemChecked(false)
  }
  const handleActivityImportantRemChange = (e) => {
    setActivityImportantRemChecked(e.target.checked)
    setActivityNotifyMeChecked(false)
    setActivityAllRemChecked(false)
  }
  const handleActivityAllRemChange = (e) => {
    setActivityAllRemChecked(e.target.checked)
    setActivityNotifyMeChecked(false)
    setActivityImportantRemChecked(false)
  }

  return (
    <Page>
      <FullWidthContainer>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
            marginBottom: '70px',
          }}
        >
          <Grid item sm={10} sx={{ marginBottom: '50px' }}>
            <HMText
              text="Notification settings"
              size="20px"
              fontWeight="700"
              margin="auto auto auto 0"
              padding="12px 12px"
              width="100%"
              textAlign="start"
              fontSize="20px"
            />
            <HMText
              text="Choose when and how we contact you for different information"
              padding="12px 12px"
              size="16px"
            />
            <Divider width="100%" />
          </Grid>

          <Grid item container spacing={10} sm={10}>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  background: lightOrange,
                  borderRadius: '50%',
                  marginRight: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  height: '30px',
                }}
              >
                <TipsAndUpdatesIcon
                  sx={{ color: orange, width: '20px', margin: '2px 0 0 5px' }}
                />
              </Box>

              <Box>
                <Title
                  size="20px"
                  title="How would you like us to notify you?"
                />
                <HMText
                  color={lightBlack}
                  fontSize="16px"
                  fontWeight="600"
                  margin="10px 0"
                  text="Choose one or more notification methods from the available ones"
                />

                <Box sx={{ paddingTop: '9rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <CircleIcon
                      sx={{
                        color: orange,
                        background: lightOrange,
                        width: '20px',
                        margin: '2px 0 0 5px',
                      }}
                    />
                    <HMText
                      text="Email"
                      fontWeight="700"
                      fontSize="14"
                      padding="0 14px"
                    />
                    <Switch
                      checked={emailChecked}
                      onChange={handleEmailChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon
                      sx={{
                        color: orange,
                        background: lightOrange,
                        width: '20px',
                        margin: '2px 0 0 5px',
                      }}
                    />
                    <HMText
                      text="In-app"
                      fontWeight="700"
                      fontSize="14"
                      padding="0 12px 0 10px"
                    />
                    <Switch
                      checked={appChecked}
                      onChange={handleAppChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon
                      sx={{
                        color: orange,
                        background: lightOrange,
                        width: '20px',
                        margin: '2px 0 0 5px',
                      }}
                    />
                    <HMText
                      text="SMS"
                      fontWeight="700"
                      fontSize="14"
                      padding="0 23px 0 10px"
                    />
                    <Switch
                      checked={smsChecked}
                      onChange={handleSmsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    background: lightOrange,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                >
                  <HMText
                    float="initial"
                    color={orange}
                    textAlign="center"
                    fontWeight="700"
                    text="1"
                  />
                </Box>

                <Box>
                  <Title size="16px" textAlign='start' float="initial" title="Notifications from us" />
                  <HMText
                    color={lightBlack}
                    fontSize="13px"
                    fontWeight="600"
                    margin="5px 0"
                    text="Choose when and how we contact you for different information"
                  />
                </Box>
              </Box>

              {/* +++++++++++++++++++= */}

              <FullRadioCheckBox
                checked={updateChecked}
                onChange={handleUpdateChange}
                title="News and updates"
                text="News about product and feature updates"
              />
              <FullRadioCheckBox
                checked={tipsChecked}
                onChange={handleTipsChange}
                title="Tips and tutorials"
                text="Tips on getting more out of Harakameds"
              />

              <FullRadioCheckBox
                checked={eventChecked}
                onChange={handleEventChange}
                title="Harakameds events"
                text="Be the first one to be informed about all events that Harakameds prepares for its clients"
              />
              <FullRadioCheckBox
                checked={researchChecked}
                onChange={handleResearchChange}
                title="User research"
                text="Get involved in our beta testing program or participate in paid product user research"
              />
              <Divider sx={{ marginTop: '15px' }} />
              {/* +++++++++++++++++++= */}

              <Box sx={{ display: 'flex', marginTop: '20px' }}>
                <Box
                  sx={{
                    background: lightOrange,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                >
                  <HMText
                    float="initial"
                    color={orange}
                    textAlign="center"
                    fontWeight="700"
                    text="2"
                  />
                </Box>

                <Box>
                  <Title size="16px" textAlign='start' float="initial" title="Reminders" />
                  <HMText
                    color={lightBlack}
                    fontSize="13px"
                    fontWeight="600"
                    margin="5px 0"
                    text="These are notifications to remind you of different activities that you might have missed"
                  />
                </Box>
              </Box>

              <FullRadioCheckBox
                checked={notifyMeChecked}
                onChange={handleNotifyMeChange}
                title="Do not notify me"
                text="News about product and feature updates"
              />
              <FullRadioCheckBox
                checked={importantRemChecked}
                onChange={handleImportantRemChange}
                title="Important reminders only"
                text="Only remind me if the reminder is tagged as important"
              />
              <FullRadioCheckBox
                checked={allRemChecked}
                onChange={handleAllRemChange}
                title="All reminders"
                text="Notify me for all reminders"
              />

              <Divider sx={{ marginTop: '15px' }} />

              <Box sx={{ display: 'flex', marginTop: '20px' }}>
                <Box
                  sx={{
                    background: lightOrange,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                >
                  <HMText
                    float="initial"
                    color={orange}
                    textAlign="center"
                    fontWeight="700"
                    text="3"
                  />
                </Box>

                <Box>
                  <Title size="16px" textAlign='start' float="initial" title="More activity about you" />
                  <HMText
                    color={lightBlack}
                    fontSize="13px"
                    fontWeight="600"
                    margin="5px 0"
                    text="These are notifications to remind you of different activities that you might have missed"
                  />
                </Box>
              </Box>

              <FullRadioCheckBox
                checked={activityNotifyMeChecked}
                onChange={handleActivityNotifyMeChange}
                title="Do not notify me"
                text="News about product and feature updates"
              />
              <FullRadioCheckBox
                checked={activityImportantRemChecked}
                onChange={handleActivityImportantRemChange}
                title="Important reminders only"
                text="Only remind me if the reminder is tagged as important"
              />

              <FullRadioCheckBox
                checked={activityAllRemChecked}
                onChange={handleActivityAllRemChange}
                title="All reminders"
                text="Notify me for all reminders"
              />
            </Grid>
          </Grid>
        </Grid>
      </FullWidthContainer>
    </Page>
  )
}

export default Notifications

import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveIcon from '@mui/icons-material/Remove'
import WarningIcon from '@mui/icons-material/Warning'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import {
  resetPOS,
  createPOSSale,
  setPOSPayMethod
} from '../../../features/pos/posSlice'
import { 
  getUsers, 
  resetUser, 
} from '../../../features/users/userSlice'
import { 
  addBasket,
  getBasket,
  resetBasket,
  deleteBasket,
} from '../../../features/basket/basketSlice'
import { 
  getMyStore,
} from '../../../features/stores/storeSlice'
import { 
  resetCategory,
  getSubCategories
} from '../../../features/categories/categorySlice'
import { 
  getAllInsuredProducts,
  getInsurerWithProducts,
  resetRelationProductsInsurers
} from '../../../features/relationProductsInsurers/relationProductsInsurersSlice'
import ProductCard from '../../../components/pos/ProductCard'
import POSComputations from '../../../components/pos/POSComputations'
import PaymentOptionCard from '../../../components/pos/PaymentOptionCard'
import PaymentMethodDetails from '../../../components/checkout/PaymentMethodDetails'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import HMModal from '../../../components/common/layout/HMModal'
import HMSlider from '../../../components/common/layout/HMSlider'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMCheckbox from '../../../components/common/tools/HMCheckbox'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import emptyCart from '../../../assests/emptyCart.png' 
import airtel from '../../../assests/airtel.png'
import mtn from '../../../assests/mtn.png'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { 
  currencies,
  getStockAvailability,
  excludeKeysFromObject,
} from '../../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  darkBlue,
  lightGray,
  lightBlue,
  lightGreen,
  lightBlack,
  lightOrange,
  getRandomColor,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../../hooks/useColors'

const setData = (productsData, subCategories, showingCategory) => {
  const subCategoriesObj = {}
  const tempDefaultProducts = []
  const tempShowingProducts = []
  const tempAvailableCategories = []

  if (productsData.length && subCategories.length) {
    for (let cat of subCategories) {
      subCategoriesObj[cat._id] = cat
    }

    productsData.forEach(data => {
      const catId = data.product.category.id._id
      const item = {
        quantity: 0,
        selected: false,
        usedInsurance: false,
        product: data.product._id,
        price: data.storeListing.price,
        productName: data.product.name,
        listing: data.storeListing._id,
        productImage: data.product.image,
        category: data.product.category.id.name,
        categoryId: data.product.category.id._id,
        insurancePrice: data.product.insurancePrice || 0,
        needsPrescription: data.product.needsPrescription,
        classification: data.product.category.id.parent.name,
        availableStock: getStockAvailability(data.storeListing.availableStock, data.storeListing.transitStockCount),
        addedInfo: {
          upploadedPrescription: false 
        }
      }

      if (catId === showingCategory) {
        tempShowingProducts.push(item)
      } 

      tempDefaultProducts.push(item)

      if ((catId in subCategoriesObj)) {
        tempAvailableCategories.push(subCategoriesObj[catId])
        delete subCategoriesObj[catId]
      }
    })
  }

  return {
    tempDefaultProducts,
    tempShowingProducts,
    tempAvailableCategories
  }
}

const findInsuredItemsData = (items, insuredProducts) => {
  const insuredItemsObj = {}
  const supportedInsurers = []
  const insuredProductsObj = {}

  let supportedInsurersObj = {}
  let tempSupportedInsurers = []

  for (let data of  insuredProducts) {
    if (data.product) {
      insuredProductsObj[data.product._id] = data.insurers 
    }
  }

  for (let item of items) {
    if (
        item.needsPrescription && 
        insuredProductsObj[item.product] &&
        item.classification.toLowerCase().includes('prescription')
      ) {
      insuredItemsObj[item.product] = item
      tempSupportedInsurers = tempSupportedInsurers.concat(insuredProductsObj[item.product])
    }
  } 

  tempSupportedInsurers.sort((a, b) => {
    if ( a.name.toLowerCase() < b.name.toLowerCase() ){
      return -1
    }
    if ( a.name.toLowerCase() > b.name.toLowerCase() ){
      return 1
    }
    return 0
  })

  for (let insurer of tempSupportedInsurers) {
    if (!supportedInsurersObj[insurer._id] && insurer.status.toLowerCase() === 'active') {
      supportedInsurersObj[insurer._id] = insurer
      supportedInsurers.push(insurer)
    }
  }

  return {
    insuredItemsObj,
    supportedInsurers,
    supportedInsurersObj
  }
}

const computeInsurancePayment = (posSale) => {
  let tempInsurancePayment = 0

  if (posSale.insuranceInfo && Object.keys(posSale.insuranceInfo).length) {
    const isInsPayMethod = posSale.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
    posSale.items.forEach(item => {
      const isValidItem = (item.usedInsurance && isInsPayMethod) ? true : false
  
      if (isValidItem) {
        tempInsurancePayment += (((posSale.insuranceInfo.storeValidation.coverage * item.insurancePrice) / 100) * item.quantity)
      }
    })
  }

  return tempInsurancePayment
}

const stylingIcon = {
  margin: 'auto',
  color: 'inherit',
  margin: 'auto auto 0 auto',
  fontSize: `${fontSize + 10}px`,
  '&:hover': {
      opacity: 1,
  }
}

function Landing() {
  const proformaPrint = useRef()
  const receiptPrint = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const { visible } = useSelector((state) => state.nav)
  const storeState = useSelector((state) => state.store)
  const { myStores } = storeState
  const { 
    posSale,
    isPOSLoading,
    posPayMethod,
    isCreatedPOSSale
  } = useSelector((state) => state.pos)
  const { 
    users,
    isSuccess
  } = useSelector((state) => state.user)
  const { 
    searchText,
    foundProductsInStore,
  } = useSelector((state) => state.search)
  const { 
    isSubSuccess,
    subCategories, 
  } = useSelector((state) => state.category)
  const { 
    publicStore,
  } = useSelector((state) => state.storesWithListings)
  const {
    insuredProducts,
    insurerProducts,
    isInsurerProducts,
    areInsuredProducts
  } = useSelector(state => state.relationProductsInsurers)
  const basketState = useSelector((state) => state.basket)

  const basketSkeleton = {
    items: [],
    discount: 0,
    subtotal: 0,
    customer: null,
    paymentInfo: {
      method: '',
      paid: false
    },
    insuranceInfo: null
  }

  const [tax, setTax] = useState(0)
  const [total, setTotal] = useState(0)
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [client, setClient] = useState('')
  const [discount, setDiscount] = useState(0)
  const [subtotal, setSubtotal] = useState(0) 
  const [coverage, setCoverage] = useState(0)
  const [currency, setCurrency] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [newPOSSale, setNewPOSSale] = useState({})
  const [insCoverage, setInsCoverage] = useState(0)
  const [isSummary, setIsSummary] = useState(false)
  const [basket, setBasket] = useState(basketSkeleton)
  const [chosenInsurer, setChosenInsurer] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [isAddInsurer, setIsAddInsurer] = useState(false)
  const [isUseInsurer, setIsUseInsurer] = useState(false)
  const [currentInsurer, setCurrentInsurer] = useState({})
  const [isAddCoverage, setIsAddCoverage] = useState(false)
  const [copaymentMethod, setCopaymentMethod] = useState('')
  const [showingCategory, setShowingCategory] = useState('')
  const [showingProducts, setShowingProducts] = useState([])
  const [insurancePayment, setInsurancePayment] = useState(0)
  const [isUpdateBasket, setIsUpdateBasket] = useState(false)
  const [currentInsurance, setCurrentInsurance] = useState({})
  const [isSelectInsurer, setIsSelectInsurer] = useState(false)
  const [insuredProductObj, setInsuredProductObj] = useState('')
  const [isAnyItemSelected, setIsAnyItemSelected] = useState(false)
  const [isReadyForInsurers, setIsReadyForInsurers] = useState(false)
  const [availableInsurersObj, setAvailableInsurersObj] = useState('')
  const [avaiblableCategories, setAvaiblableCategories] = useState([])
  const [itemsSupportedByChosenInsurerObj, setItemsSupportedByChosenInsurerObj] = useState({})
  const [isAddCoverageWithUsavedInsurance, setIsAddCoverageWithUsavedInsurance] = useState(false)

  const {
    windowW,
    windowH
  } = useWindowDimensions()

  const isSmallScreen = windowW < 400 ? true : false

  const isTabletScreen = windowW < 600 ? true : false
  const isMediumScreen = windowW < 1110 ? true : false
  const isNormalTabletScreen = windowW < 880 ? true : false
  const isBigHeightScreen = windowH < 1000 ? true : false
  const isNormalHeightScreen = windowH < 650 ? true : false
  const isSmallHeightScreen = windowH < 400 ? true : false
  const isVerySmallHeightScreen = windowH < 300 ? true : false

  

  useEffect(() => {
    if (basketState.basket && Object.keys(basketState.basket).length && basket.items.length && posPayMethod.length) {
      setBasket({
        ...basketState.basket,
        paymentInfo: {
          ...basketState.basket.paymentInfo,
          method: posPayMethod
        }
      })
      setIsUpdateBasket(true)
    
      let tempInsurancePayment = 0
      basketState.basket.items.forEach(item => {
        const isInsPayMethod = posPayMethod.toLowerCase() === 'insurance' ? true : false
        const isValidItem = (itemsSupportedByChosenInsurerObj[item.product] && isInsPayMethod) ? true : false

        if (isValidItem) {
          tempInsurancePayment += (((coverage * item.insurancePrice) / 100) * item.quantity)
        }
      })
      setInsurancePayment(tempInsurancePayment)
    }
    // eslint-disable-next-line
  }, [posPayMethod])

  useEffect(() => {
    if (Object.keys(currentInsurer).length && Object.keys(availableInsurersObj).length) {
      const tempChosenInsurer = availableInsurersObj[currentInsurer._id]
      if (tempChosenInsurer) {
        dispatch(getInsurerWithProducts(tempChosenInsurer._id))
      }
    }
    // eslint-disable-next-line
  }, [currentInsurer, availableInsurersObj])

  useEffect(() => {
    if (areInsuredProducts || isInsurerProducts) {
      dispatch(resetRelationProductsInsurers())
    }
    // eslint-disable-next-line
  }, [isInsurerProducts, areInsuredProducts])

  useEffect(() => {
    if (user) {
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }
      dispatch(getUsers())
      dispatch(getBasket(storeId))
      dispatch(getSubCategories())
      dispatch(getAllInsuredProducts())
      setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
    }
    // eslint-disable-next-line
  }, [user, myStores, storeId])

  useEffect(() => {
    if (isUpdateBasket) {
      dispatch(addBasket({
        ...basket,
        storeId,
        subtotal,
        customer: client && Object.keys(client).length ? client._id : null
      }))
      setIsUpdateBasket(false)
    }
    // eslint-disable-next-line
  }, [isUpdateBasket])

  useEffect(() => {
    if (isReadyForInsurers && insuredProducts.length) {
      setIsReadyForInsurers(false)
      const {
        insuredItemsObj,
        supportedInsurersObj
      } = findInsuredItemsData(basket.items, insuredProducts)

      setInsuredProductObj(insuredItemsObj)
      setAvailableInsurersObj(supportedInsurersObj)
    }
    // eslint-disable-next-line
  }, [basket, isReadyForInsurers, insuredProducts])
  
  useEffect(() => {
    const { 
      isOne,
      isAdded,
      isError,
      isDeleted
    } = basketState

    const tempBasket = basketState.basket

    if (isOne || isAdded || isError || isDeleted) {
      dispatch(resetBasket())
    }

    if (isOne && Object.keys(tempBasket).length) {
      setBasket(tempBasket)
      setIsReadyForInsurers(true)
      if (Object.keys(tempBasket).length && tempBasket.paymentInfo.method) {
        dispatch(setPOSPayMethod(tempBasket.paymentInfo.method))
      }

      if (Object.keys(tempBasket).length && tempBasket.insuranceInfo) {
        if (Object.keys(availableInsurersObj).length) {
          const tempChosenInsurer = availableInsurersObj[tempBasket.insuranceInfo.insurer.id]
          if (tempChosenInsurer) {
            dispatch(getInsurerWithProducts(tempChosenInsurer._id))
          }
          setChosenInsurer({
            ...tempChosenInsurer,
            copaymentMethod: tempBasket.paymentInfo.copaymentMethod,
            coverage: tempBasket.insuranceInfo.storeValidation.coverage
          })
          setCurrentInsurance(tempBasket.insuranceInfo)
        }
        setIsUseInsurer(true)
      }
      
      if (users.length) {
        setClient(users.find(user => user._id === tempBasket.customer))
      }
    } else if (isDeleted) {
      clearInsuranceData()
    }
    // eslint-disable-next-line
  }, [basketState])

  useEffect(() => {
    if (Object.keys(publicStore).length) {
      if (searchText.length) {
        const {
          tempShowingProducts,
          tempDefaultProducts,
          tempAvailableCategories
        } = setData(foundProductsInStore, subCategories, showingCategory)
        setAvaiblableCategories(tempAvailableCategories)
        setShowingProducts(tempShowingProducts.length ? tempShowingProducts : tempDefaultProducts)
      } else {
        const {
          tempShowingProducts,
          tempDefaultProducts,
          tempAvailableCategories
        } = setData(publicStore.productsAndListings, subCategories, showingCategory)
        setAvaiblableCategories(tempAvailableCategories)
        setShowingProducts(tempShowingProducts.length ? tempShowingProducts : tempDefaultProducts)
      }
    }
    // eslint-disable-next-line
  }, [publicStore, showingCategory, subCategories, searchText, foundProductsInStore])

  useEffect(() => {
    if (isSubSuccess) {
      dispatch(resetCategory())
    }

    if (isSuccess) {
      dispatch(resetUser())
    }
    // eslint-disable-next-line
  }, [isSubSuccess, isSuccess])

  useEffect(() => {
    const tempPayMethod = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
    setSubtotal(basket.items.reduce((total, item) => total + (
      (
        (item.usedInsurance && tempPayMethod && basket.insuranceInfo) 
          ? item.insurancePrice 
          : item.price
      ) * item.quantity
    ), 0))
    
    if (!tempPayMethod) {
      setInsCoverage(0)
    }
    // eslint-disable-next-line
  }, [basket])

  useEffect(() => {
    setTotal(subtotal - discount - insurancePayment + tax)
  }, [subtotal, discount, insurancePayment, tax])

  useEffect(() => {
    if (
      (Object.keys(currentInsurer).length || Object.keys(chosenInsurer).length) && 
      Object.keys(insurerProducts).length &&
      Object.keys(insuredProductObj).length
    ) {
      const insuredItemsObj = {}
      insurerProducts.products.forEach(product => {
        if (insuredProductObj[product._id]) {
          insuredItemsObj[product._id] = insuredProductObj[product._id]
        }
      })
      setItemsSupportedByChosenInsurerObj(insuredItemsObj)
    } else {
      setItemsSupportedByChosenInsurerObj({})
    }
  }, [insurerProducts, currentInsurer, chosenInsurer, insuredProductObj])

  useEffect(() => {
    const isChosenInsurer = (isUseInsurer && chosenInsurer && Object.keys(chosenInsurer).length) ? true : false
    const tempCoverage = isChosenInsurer ? chosenInsurer.coverage : insCoverage
    const tempCopaymentMethod = isChosenInsurer ? chosenInsurer.copaymentMethod : copaymentMethod 
    setCoverage(parseInt(tempCoverage))
    if (Object.keys(itemsSupportedByChosenInsurerObj).length && tempCoverage >= 0) {
      let tempInsurancePayment = 0
      const ins = Object.keys(currentInsurer).length ? currentInsurer : chosenInsurer
      const tempInsurer = {
        id: ins._id,
        name: ins.name,
        logo: ins.logo,
        type: ins.type,
        origin: ins.origin,
      }

      const tempInsuranceInfo = Object.keys(currentInsurance) ? {
          insurer: tempInsurer,
          cardImage: currentInsurance.cardImage,
          cardNumber: currentInsurance.cardNumber,
          nameOnCard: currentInsurance.nameOnCard,
        } : {
          insurer: tempInsurer
        }
      
      setBasket({
        ...basket,
        copaymentInfo: {
          paid: false,
          copaymentMethod: tempCopaymentMethod
        },
        insuranceInfo: {
          ...tempInsuranceInfo,
          storeValidation: {
            reason: '',
            paid: false,
            state: 'Valid',
            coverage: tempCoverage,
          },
        },
        items: basket.items.map(item => {
          const isInsPayMethod = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
          const isValidItem = (itemsSupportedByChosenInsurerObj[item.product] && isInsPayMethod) ? true : false
          if (isValidItem) {
            tempInsurancePayment += (((tempCoverage * item.insurancePrice) / 100) * item.quantity)
          }

          return {
            ...item,
            usedInsurance: isValidItem ? true : false,
          }
        })
      })
      setInsurancePayment(tempInsurancePayment)
    } else {
      setBasket({
        ...basket,
        insuranceInfo: null,
        copaymentInfo: null,
        items: basket.items.map(item => ({
          ...item,
          usedInsurance: false,
        }))
      })
      setCopaymentMethod('')
    }
  }, [itemsSupportedByChosenInsurerObj, insCoverage, chosenInsurer])

  useEffect(() => {
    if (isCreatedPOSSale) {
      setCoverage(0)
      setClient(null)
      setInsCoverage(0)
      setChosenInsurer({})
      dispatch(resetPOS())
      setCurrentInsurer({})
      setInsurancePayment(0)
      setCurrentInsurance({})
      setBasket(basketSkeleton)
      dispatch(deleteBasket(storeId))

      handlePrintRecipt()
    }
    // eslint-disable-next-line
  }, [isCreatedPOSSale])

  const handleChoosedCategory = (e, id) => {
    if (id === null) {
      setShowingCategory('')
    } else {
      setShowingCategory(id)
    }
    const otcChoices = document.getElementsByClassName('otc-choice')
    for (let i = 0; i < otcChoices.length; i++) {
        otcChoices[i].classList.remove('active')
    } 
    e.target.classList.add('active')
  }

  const navegateToProductPage = () => {

  }

  const handleCheckAll = (e) => {
    const checked = e.target.checked
    const tempItems = basket.items.map(item => {
      return {
        ...item,
        selected: checked
      }
    })

    setBasket({
      ...basket,
      items: tempItems
    })
    setIsAnyItemSelected(checked ? true : false)
    setSelectedItems(tempItems.length ? tempItems : [])
  }

  const handleBasktDeletionModal = () => {
    setOpen(true)
    setIsDelete(true)
  }

  const handleBasktDeletion = () => {
    setBasket({
      ...basket,
      items: basket.items.filter(item => !item.selected) 
    })
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setSelectedItems([])
    setIsUpdateBasket(true)
    setIsAnyItemSelected(false)
  }

  const addToBasket = (data) => {
    const index = basket.items.findIndex(item => item.product === data.product)
    if (index === -1 && data.availableStock.stockCount >= 1) {
      const tempItems = [
        ...basket.items,
        {
          ...data,
          quantity: 1,
        }
      ]
      setBasket({
        ...basket,
        items: tempItems
      })
      setIsUpdateBasket(true)
    } 
  }

  const handleItemSelect = (e, data) => {
    const tempSelectedItems = []
    const checked = e.target.checked

    setBasket({
      ...basket,
      items: basket.items.map(item => {
        if (item.product === data.product) {
          if (checked) {
            tempSelectedItems.push(item)
          }
          return {
            ...item,
            selected: checked
          }
        }
        if (item.selected) {
          tempSelectedItems.push(item)
        }
        return item
      })
    })

    setSelectedItems(tempSelectedItems)
    setIsAnyItemSelected(tempSelectedItems.length ? true : false)
  }

  const handleIncreaseQuantity = (data) => {
    const tempSelectedItems = []
    let tempInsurancePayment = 0
    setBasket({
      ...basket,
      items: basket.items.map(i => {
        const item = { ...i }
        const isInsPayMethod = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
        const isValidItem = (itemsSupportedByChosenInsurerObj[item.product] && isInsPayMethod) ? true : false

        if (item.product === data.product) {
          item.quantity = item.quantity + 1 <= data.availableStock.stockCount ? item.quantity + 1 : item.quantity
          if (item.selected) {
            tempSelectedItems.push(item)
          }
          if (item.quantity <= data.availableStock.stockCount) {
            setIsUpdateBasket(true)
          }
        } 

        if (isValidItem) {
          tempInsurancePayment += (((coverage * item.insurancePrice) / 100) * item.quantity)
        }

        if (item.selected) {
          tempSelectedItems.push(item)
        }
        
        return item
      })
    })
      
    setSelectedItems(tempSelectedItems)
    setInsurancePayment(tempInsurancePayment)
    setIsAnyItemSelected(tempSelectedItems.length ? true : false)
  }

  const handleReduceQuantity = (data) => {
    const tempItems = []
    const tempSelectedItems = []
    let tempInsurancePayment = 0
    basket.items.forEach((i) => {
      const item = { ...i }
      const isInsPayMethod = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
      const isValidItem = (itemsSupportedByChosenInsurerObj[item.product] && isInsPayMethod) ? true : false

      if ((item.product === data.product) && (data.quantity - 1 > 0)) {
        item.quantity = item.quantity - 1
        setIsUpdateBasket(true)
      }

      if (isValidItem) {
        tempInsurancePayment += (((coverage * item.insurancePrice) / 100) * item.quantity)
      }

      if (item.selected) {
        tempSelectedItems.push(item)
      }
      tempItems.push(item)
    })

    setBasket({
      ...basket,
      items: tempItems
    })
    setSelectedItems(tempSelectedItems)
    setInsurancePayment(tempInsurancePayment)
    setIsAnyItemSelected(tempSelectedItems.length ? true : false)
  }

  const handleQuantityChange = (e, data) => {
    const tempSelectedItems = []
    let tempInsurancePayment = 0
    const value = parseInt(e.target.value)

    setBasket((prevState) => ({
      ...prevState,
      items: prevState.items.map(i => {
        const item = { ...i }
        const isInsPayMethod = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
        const isValidItem = (itemsSupportedByChosenInsurerObj[item.product] && isInsPayMethod) ? true : false

        if (item.product === data.product) {
          item.quantity = (value <= item.availableStock.stockCount && value > 0) ? value : item.quantity 
          
          if (item.quantity + 1 <= data.availableStock.stockCount) {
            setIsUpdateBasket(true)
          }
        }

        if (item.selected) {
          tempSelectedItems.push(item)
        }

        if (isValidItem) {
          tempInsurancePayment += (((coverage * item.insurancePrice) / 100) * item.quantity)
        }

        return item
      })
    }))

    setIsUpdateBasket(true)
    setSelectedItems(tempSelectedItems)
    setInsurancePayment(tempInsurancePayment)
    setIsAnyItemSelected(tempSelectedItems.length ? true : false)
  }

  const handleAddInsurerModal = () => {
    setOpen(true)
    setIsAddInsurer(true)
    setIsUseInsurer(false)
    setIsAddCoverage(false)
    setIsSelectInsurer(false)
    setTitle('Add Insurance Information')
    setIsAddCoverageWithUsavedInsurance(false)
  }
  const handleSelectInsurerModal = () => {
    setOpen(true)
    setIsAddInsurer(false)
    setIsUseInsurer(false)
    setIsAddCoverage(false)
    setIsSelectInsurer(true)
    setTitle('Select Insurance Card')
  }

  const handleAddCoverageWithUsavedInsuranceModal = (insurer) => {
    setOpen(true)
    setIsAddInsurer(false)
    setIsAddCoverage(false)
    setCurrentInsurance({})
    setIsSelectInsurer(false)
    setCurrentInsurer(insurer)
    setIsAddCoverageWithUsavedInsurance(true)
    setCurrentInsurance({
      insurer,
      cardImage: '',
      cardNumber: '',
      nameOnCard: '',
    })
    setTitle('Add Insurance Coverage')
  }

  const handleAddCoverageMadal = (insuranceData) => {
    setOpen(true)
    setIsAddInsurer(false)
    setIsAddCoverage(true)
    setIsSelectInsurer(false)
    setTitle('Add Insurance Coverage')
    setCurrentInsurance(insuranceData)
    setCurrentInsurer(insuranceData.insurer)
    setIsAddCoverageWithUsavedInsurance(false)
  }

  const handleChooseInsurance = () => {
    if (Object.keys(currentInsurer).length && Object.keys(availableInsurersObj).length) {
      const tempChosenInsurer = availableInsurersObj[currentInsurer._id]
      if (tempChosenInsurer) {
        setChosenInsurer({
          ...tempChosenInsurer,
          copaymentMethod,
          coverage: insCoverage,
        })
        setIsUseInsurer(true)
        setIsUpdateBasket(true)
      }
    }
    setTitle('')
    setOpen(false)
    setIsAddInsurer(false)
    setIsAddCoverage(false)
    setIsSelectInsurer(false)
    setIsAddCoverageWithUsavedInsurance(false)
  }

  const handleViewSummaryModal = () => { 
    const tempNewPOSSale = {
      ...basket,
      tax,
      paymentInfo: {
        ...basket.paymentInfo,
        paid: basket.paymentInfo.method.toLowerCase() === 'credit' ? false : true
      },
      items: basket.items.map(item => ({
        ...excludeKeysFromObject(item, ['availableStock', 'selected']),
      }))
    }

    setOpen(true)
    setIsSummary(true)
    setTitle('Sale Summary')
    setNewPOSSale(tempNewPOSSale)
  }

  const handleMakeSale = () => {
    dispatch(createPOSSale(newPOSSale))
    handleClose()
    clearClient()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setNewPOSSale({})
    setIsDelete(false)
    setIsSummary(false)
    setIsUseInsurer(true)
    setIsAddInsurer(false)
    setIsAddCoverage(false)
    setIsSelectInsurer(false)
    setIsAddCoverageWithUsavedInsurance(false)

    const tempBasket = basketState.basket
    if (Object.keys(tempBasket).length && tempBasket.insuranceInfo) {
      if (Object.keys(availableInsurersObj).length) {
        const tempChosenInsurer = availableInsurersObj[tempBasket.insuranceInfo.insurer.id]
        if (tempChosenInsurer) {
          dispatch(getInsurerWithProducts(tempChosenInsurer._id))
        }
        setChosenInsurer({
          ...tempChosenInsurer,
          copaymentMethod: tempBasket.paymentInfo.copaymentMethod,
          coverage: tempBasket.insuranceInfo.storeValidation.coverage
        })
        setCurrentInsurance(tempBasket.insuranceInfo)
        setInsCoverage(tempBasket.insuranceInfo.storeValidation.coverage)
      }
    } else {
      clearInsuranceData()
    }
  }

  const clearInsuranceData = (customer) => {
    const tempPayMethod = {
      paid: basket.paymentInfo.paid,
      method: basket.paymentInfo.method,
    }
    setBasket({
      ...basket,
      insuranceInfo: null,
      paymentInfo: tempPayMethod,
      customer: (customer || null),
      items: basket.items.map(item => ({
        ...item,
        usedInsurance: false,
      }))
    })
    setCoverage(0)
    setInsCoverage(0)
    setChosenInsurer({})
    setCurrentInsurer({})
    setInsurancePayment(0)
    setCurrentInsurance({})
    setIsUpdateBasket(true)
  }

  const handleClientChange = (val) => {
    if (val && Object.keys(val).length && val._id !== basket.customer) {
      setClient(val)
      clearInsuranceData(val._id)
    }
  }

  const clearClient = () => {
    setClient(null) 
    clearInsuranceData(null)
  }

  const handleInsCoverageChange = (e) => {
    const value = e.target.value
    if (value >= 0 && value <= 100) {
      setInsCoverage(parseInt(value))
    } 
  }

  const handleCurrentInsuranceChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setCurrentInsurance(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  // console.log(basket)

  const isSummaryDisabled = (
      !basket.paymentInfo.method.length || 
      basket.paymentInfo.method !== posPayMethod ||
      (basket.paymentInfo.method.toLowerCase() === 'insurance' && !basket.insuranceInfo) 
    ) ? true : false

  const pageStyle = `{ 
    padding: '20px',
    size: '2.5in 4in',
    backgroundColor: white
  }`
  const handlePrintProformaInvoice = useReactToPrint({
    pageStyle: pageStyle,
    bodyClass: 'print-performa',
    content: () => proformaPrint.current
  })

  const handlePrintRecipt = useReactToPrint({
    pageStyle: pageStyle,
    bodyClass: 'print-receipt',
    content: () => receiptPrint.current
  })

  return user ? (
    <Page>
      {isPOSLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      
      <HMModal 
        open={open} 
        title={title}
        maxHeight='95vh'
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isDelete ? (
          <HMBox
            display='flex'
            flexDirection='column'
            width={isTabletScreen ? '100%' : '100%'}
          >
            <HMBox
              width='100%'
              display='flex'
              margin='10px 0'
              className='fade-in'
            >
              <WarningIcon 
                sx={{
                  padding: '0',
                  color: orange,
                  margin: 'auto 0 auto auto',
                }}
              />
              <Typography 
                sx={{
                  ...textStyle, 
                  fontSize: `${fontSize}px`,
                  margin: 'auto auto auto 10px',
                  textAlign: isTabletScreen && 'justify', 
                }}
              >
                Are your sure you want to remove the following&nbsp;
                {selectedItems.length > 1 ? 'items' : 'item'}
                &nbsp;from your basket?
              </Typography>
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              overflowY='auto'
              className='fade-in'
              maxHeight={
                isVerySmallHeightScreen ? '30vh' 
                : isSmallHeightScreen ? '40vh' 
                : isNormalHeightScreen ? '50vh' 
                : isBigHeightScreen ? '60vh' 
                : '70vh'
              }
            >
              {selectedItems.map((item, index) => (
                <ProductCard
                  key={index}
                  item={item}
                />
              ))}
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={red}
                color={white}
                margin='auto 0 auto auto'
                handleClick={handleBasktDeletion}
                text={<Typography sx={textStyle}>Remove</Typography>}
              />
            </HMBox>
          </HMBox>
        ) : isAddInsurer ? (
          <>
            <HMBox
              width='100%'
              display='flex'
              overflowY='auto'
              color={darkBlue}
              flexDirection='column'
              maxHeight={
                isVerySmallHeightScreen ? '50vh' 
                : isSmallHeightScreen ? '60vh' 
                : isNormalHeightScreen ? '70vh' 
                : isBigHeightScreen ? '80vh' 
                : '80vh'
              }
            >
              {Object.keys(availableInsurersObj).length ? Object.keys(availableInsurersObj).map((key, index) => {
                const insurer = availableInsurersObj[key]
                return (
                  <HMBox
                    padding='0'
                    key={index}
                    width='100%'
                    display='flex'
                    className='fade-in'
                    flexDirection='column'
                  >
                    <PaymentMethodDetails 
                      upperText={insurer.name}
                      hoverBgColor={lightGreen}
                      bgColor={seeThroughLightBlue}
                      lowerText={`${insurer.type} | ${insurer.origin}`}
                      handleClick={() => handleAddCoverageWithUsavedInsuranceModal(insurer)}
                      leftIcon={
                        insurer.logo ? (
                          <img
                            alt={insurer.name}
                            src={insurer.logo}
                            className='super-mini-image'
                            style={{ margin: 'auto 10px auto 0' }}
                          />
                        ) : (
                          <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                            <HMText
                              float='none'
                              width='100%'
                              margin='auto'
                              height='100%'
                              color={white}
                              fontWeight={700}
                              textAlign='center'
                              bgColor={getRandomColor()}
                              text={insurer.name.substr(0, 1).toUpperCase()}
                            />
                          </Grid>
                        )
                      }
                      rightIcon={
                        <ChevronRightOutlinedIcon 
                          sx={{
                            margin: 'auto 0 auto auto'
                          }} 
                        />
                      }
                    />
                  </HMBox>
                )  
              }) : <></>}
            </HMBox>
            {(client && Object.keys(client).length && client.insuranceInfo.length) ? (
              <IconButton   
                onClick={handleSelectInsurerModal} 
                sx={{
                  top: '5px',
                  left: '5px',
                  background: orange,
                  position: 'absolute',
                  '&:hover': {
                    background: orange,
                  }
                }}
              >
                <ArrowBackIosNewTwoToneIcon 
                  sx={{
                    color: white,
                    margin: 'auto',
                    fontSize: `${fontSize + 2}px`
                  }}
                />
              </IconButton>
            ) : <></>}
          </>
        ) : isSelectInsurer ? (
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            flexDirection='column'
          >
            <HMButton 
              width='100%'
              color={blue}
              type='button'
              padding='10px'
              bgColor='inherit'
              borderRadius={10}
              paddingActive='10px'
              border={`1px solid ${blue}`}
              margin='20px auto 10px auto'
              handleClick={handleAddInsurerModal}
              minWidth={!isTabletScreen && '400px'}
              icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
              text={
                <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                  Add insurer information
                </Typography>
              } 
            />
            {(client && Object.keys(client).length && client.insuranceInfo.length) ? (
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                flexDirection='column'
              >
                {client.insuranceInfo.map((data, index) => (
                  <HMBox
                    padding='0'
                    key={index}
                    width='100%'
                    display='flex'
                    flexDirection='column'
                  >
                    <PaymentMethodDetails 
                      hoverBgColor={lightGreen}
                      lowerText={data.nameOnCard}
                      bgColor={seeThroughLightBlue}
                      handleClick={() => handleAddCoverageMadal(data)}
                      upperText={`${data.insurer.name} - ${data.cardNumber}`}
                      notClickable={!availableInsurersObj[data.insurer._id] ? true : false}
                      leftIcon={
                        data.insurer.logo ? (
                          <img
                            alt={data.insurer.name}
                            src={data.insurer.logo}
                            className='super-mini-image'
                            style={{ margin: 'auto 10px auto 0' }}
                          />
                        ) : (
                          <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                            <HMText
                              float='none'
                              width='100%'
                              margin='auto'
                              height='100%'
                              color={white}
                              fontWeight={700}
                              textAlign='center'
                              bgColor={getRandomColor()}
                              text={data.insurer.name.substr(0, 1).toUpperCase()}
                            />
                          </Grid>
                        )
                      }
                      rightIcon={
                        <>
                          {data.default ? (
                            <HMText
                              color={white}
                              padding='0 5px'
                              bgColor={orange}
                              text='Dafault insurance'
                              margin='auto 10px auto auto'
                              fontSize={`${fontSize - 2}px`}
                            />
                          ) : <></>}
                        </>
                      }
                    />
                  </HMBox>
                ))}
              </HMBox>
            ) : <></>}
          </HMBox>
        ) : (isAddCoverage || isAddCoverageWithUsavedInsurance) ? (
          <HMBox
            width='100%'
            display='flex'
            flexDirection='column'
          >
            <HMBox
              width='100%'
              display='flex'
              minWidth='400px'
              margin='20px auto'
              className='fade-in'
              flexDirection='column'
              bgColor={seeThroughLightBlue}
            >
              <HMBox
                width='100%'
                display='flex'
              >
                {currentInsurance.insurer.logo ? (
                  <img
                    className='medium-image'
                    alt={currentInsurance.insurer.name}
                    src={currentInsurance.insurer.logo}
                    style={{ margin: 'auto 10px auto 0' }}
                  />
                ) : (
                  <Grid 
                    sx={{
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      margin: 'auto 0',
                      borderRadius: '50%',
                      backgroundColor: getRandomColor(),
                    }}
                  >
                    <HMText
                      margin='auto'
                      color={white}
                      fontWeight={700}
                      textAlign='center'
                      fontSize={`${fontSize + 26}px`}
                      text={currentInsurance.insurer.name.substr(0, 1).toUpperCase()}
                    />
                  </Grid>
                )}
                <HMText
                  color={darkBlue}
                  fontWeight={500}
                  textAlign='center'
                  margin='auto auto auto 10px'
                  fontSize={`${fontSize + 2}px`}
                  text={currentInsurance.insurer.name}
                /> 
                {isAddCoverage ? (
                  <HMButton 
                    top='2px'
                    right='0'
                    type='button'
                    bgColor={blue}
                    fontWeight={500}
                    position='absolute'
                    rightIcon={<OpenInNewOutlinedIcon sx={{ ...iconStyle, margin: 'auto auto auto 5px'}} />} 
                    text={<Typography sx={{ ...textStyle, fontSize: `${fontSize - 2}px` }}>View</Typography>}
                    handleClick={() => {
                      window.open(currentInsurance.cardImage, '_blank', 'noopener,noreferrer')
                    }}
                  />
                ) : <></>} 
              </HMBox>
              {isAddCoverage ? (
                <>
                  <HMBox
                    width='100%'
                    display='flex'
                    padding='0 5px'
                    margin='10px auto auto auto' 
                  >
                    <HMText 
                      margin='auto 0'
                      width='max-content'
                      className='fade-in'
                      text='Card Holder:'
                    />
                    <HMText 
                      color={darkBlue}
                      fontWeight={500}
                      width='max-content'
                      className='fade-in'
                      fontSize={`${fontSize}px`}
                      margin='auto auto auto 5px'
                      text={currentInsurance.nameOnCard}
                    />
                  </HMBox>
                  <HMBox
                    width='100%'
                    display='flex'
                    padding='0 5px'
                  >
                    <HMText 
                      text='Card #:'
                      margin='auto 0'
                      width='max-content'
                      className='fade-in'
                    />
                    <HMText 
                      color={darkBlue}
                      fontWeight={500}
                      width='max-content'
                      className='fade-in'
                      fontSize={`${fontSize}px`}
                      margin='auto auto auto 5px'
                      text={currentInsurance.cardNumber}
                    />
                  </HMBox>
                </>
              ) : <></>}
            </HMBox>
            {isAddCoverageWithUsavedInsurance ? (
              <HMTextField 
                type='text'
                width='100%'
                margin='auto 0'
                required={true}
                name='nameOnCard'
                className='fade-in'
                label='Name On Card'
                value={currentInsurance.nameOnCard}
                onChange={handleCurrentInsuranceChange}
                placeholder='Enter the name on the card'
              />
            ) : <></>}
            <HMBox
              width='100%'
              display='flex'
              margin='20px 0'
              padding='5px 0'
              minWidth='400px'
              className='fade-in'
            >
              {isAddCoverageWithUsavedInsurance ? (
                <HMTextField 
                  width='50%'
                  type='text'
                  margin='auto 0'
                  required={true}
                  name='cardNumber'
                  label='Card Number'
                  className='fade-in'
                  placeholder='Enter the card ID'
                  value={currentInsurance.cardNumber}
                  onChange={handleCurrentInsuranceChange}
                />
              ) : <></>}
              <HMTextField 
                type='number'
                name='coverage'
                required={true}
                label='Coverage'
                className='fade-in'
                value={insCoverage}
                onChange={handleInsCoverageChange}
                width={isAddCoverage ? '50%' : '25%'}
                placeholder='Enter inurance coverage [1 - 100]%'
                margin={isAddCoverageWithUsavedInsurance ? 'auto 10px' : 'auto 0'}
              />
              <Title 
                color={blue}
                margin='auto'
                padding='1px 5px'
                borderRadius='5px'
                size={fontSize - 2}
                bgColor={lightBlue}
                title={`${insCoverage} %`}
              />
            </HMBox> 
            <HMBox 
              width='100%'
              display='flex'
              padding='5px 0'
              className='fade-in'
              margin='0 0 10px 0'
              flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
            >
              <HMBox 
                width='100%'
                height='100%'
                display='flex'
                flexDirection='row'
                border={`1px solid ${lightBlue}`}
                margin={`${isTabletScreen ? '5px 0' : '0 2.5px 0 0'}`}
              >
                <HMText 
                  margin='auto 0'
                  text='Customer pays :'
                />
                <Title 
                  color={blue}
                  padding='1px 5px'
                  borderRadius='5px'
                  size={fontSize - 2}
                  bgColor={lightBlue}
                  margin='auto 0 auto auto'
                  title={`${currency} ${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
              </HMBox>
              {insCoverage > 0 ? (
                <HMBox 
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='row'
                  border={`1px solid ${lightBlue}`}
                  margin={`${isTabletScreen ? '5px 0' : '0 0 0 2.5px'}`}
                >
                  <HMText 
                    margin='auto 0'
                    text={`${currentInsurer.name} pays :`}
                  />
                  <Title 
                    color={orange}
                    padding='1px 5px'
                    borderRadius='5px'
                    size={fontSize - 2}
                    bgColor={lightOrange}
                    margin='auto 0 auto auto'
                    title={`${currency} ${insurancePayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  />
                </HMBox>
              ) : (
                <></>
              )}
            </HMBox>
            <HMText 
              padding='5px'
              margin='10px 0'
              color={darkBlue}
              fontWeight={500}
              className='fade-in'
              text='Copayment Method'
            />
            <HMBox
              width='100%'
              display='flex'
              className='fade-in'
              margin='auto auto 10px auto'
            >
              <PaymentOptionCard 
                text='Cash'
                name='Cash'
                padding='5px 15px'
                fontSize={fontSize - 2}
                hoverPadding='4.5px 14.5px'
                margin='auto 5px auto auto'
                icon={<MoneyOutlinedIcon sx={stylingIcon} />}
                handleClick={() => { setCopaymentMethod('Cash')}}
                color={copaymentMethod === 'Cash' ? white : lightBlack}
                bgColor={copaymentMethod === 'Cash' ? blue : lightBlue}
              />
              <PaymentOptionCard 
                name='MTN'
                text='MTN MoMo'
                margin='auto 5px'
                padding='5px 15px'
                hoverColor={darkBlue}
                fontSize={fontSize - 2}
                hoverBgColor='#ffc907cf'
                hoverPadding='4.5px 14.5px'
                handleClick={() => { setCopaymentMethod('MTN')}}
                color={copaymentMethod === 'MTN' ? darkBlue : lightBlack}
                bgColor={copaymentMethod === 'MTN' ? '#ffc907cf' : lightBlue}
                icon={
                  <img 
                    alt='mtn'
                    src={mtn}
                    style={{
                      width: '25px',
                      height: '10px',
                      margin: '7px auto',
                      borderRadius: '8px',
                    }}
                  />
                }
              />
              <PaymentOptionCard 
                name='Airtel'
                margin='auto 5px'
                padding='5px 15px'
                text='Airtel MoMo'
                fontSize={fontSize - 2}
                hoverBgColor='#ff1f1fed'
                hoverPadding='4.5px 14.5px'
                handleClick={() => { setCopaymentMethod('Airtel')}}
                color={copaymentMethod === 'Airtel' ? white : lightBlack}
                bgColor={copaymentMethod === 'Airtel' ? '#ff1f1fed' : lightBlue}
                icon={
                  <img 
                    alt='airtel'
                    src={airtel}
                    style={{
                      width: '30px',
                      height: '10px',
                      margin: '7px auto',
                      borderRadius: '8px',
                    }}
                  />
                }
              />
              <PaymentOptionCard 
                text='Card'
                name='Card'
                margin='auto 5px'
                padding='5px 15px'
                fontSize={fontSize - 2}
                hoverPadding='4.5px 14.5px'
                handleClick={() => { setCopaymentMethod('Card')}}
                icon={<CreditScoreOutlinedIcon sx={stylingIcon} />}
                color={copaymentMethod === 'Card' ? white : lightBlack}
                bgColor={copaymentMethod === 'Card' ? blue : lightBlue}
              />
              <PaymentOptionCard 
                text='Credit'
                name='Credit'
                padding='5px 15px'
                fontSize={fontSize - 2}
                hoverPadding='4.5px 14.5px'
                margin='auto auto auto 5px'
                handleClick={() => { setCopaymentMethod('Credit')}}
                icon={<CreditCardOffOutlinedIcon sx={stylingIcon} />}
                color={copaymentMethod === 'Credit' ? white : lightBlack}
                bgColor={copaymentMethod === 'Credit' ? blue : lightBlue}
              />
            </HMBox>
            <HMBox
              width='100%'
              display='flex'
              className='fade-in'
              margin='15px auto 0 auto'
            >
              <HMButton 
                type='button'
                width='100px'
                fontWeight={500}
                color={lightBlack}
                bgColor={lightGray}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={
                  <Typography sx={textStyle}>
                    Cancel
                  </Typography>
                }
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={green}
                margin='auto 0 auto auto'
                handleClick={handleChooseInsurance}
                disabled={
                  (
                    insCoverage === 0 || 
                    !copaymentMethod.length ||
                    (
                      isAddCoverageWithUsavedInsurance && 
                      (!currentInsurance.cardNumber || !currentInsurance.nameOnCard) 
                    )
                  ) ? true : false
                }
                text={
                  <Typography sx={textStyle}>
                    Confirm
                  </Typography>
                }
              />
            </HMBox>
            <IconButton   
              onClick={isAddCoverage ? handleSelectInsurerModal : handleAddInsurerModal} 
              sx={{
                top: '5px',
                left: '5px',
                background: orange,
                position: 'absolute',
                '&:hover': {
                  background: orange,
                }
              }}
            >
              <ArrowBackIosNewTwoToneIcon 
                sx={{
                  color: white,
                  margin: 'auto',
                  fontSize: `${fontSize + 2}px`
                }}
              />
            </IconButton>
          </HMBox>
        ) : isSummary ? (
          <Box 
            ref={proformaPrint}
            sx={{
              width: '100%',
              display: 'flex',
              backgroundColor: white,
              flexDirection: 'column',
              minWidth: isTabletScreen ? 'auto' : '400px'
            }}
          >
            <Title 
              display='none'
              margin='10px auto'
              size={fontSize + 6}
              title='Proforma Invoice' 
              className='print-performa-title'
            />
            {Object.keys(newPOSSale).length ? (
              <HMBox
                width='100%'
                padding='10px 0'
                overflowY='auto'
                className='fade-in'
                maxHeight={
                  isVerySmallHeightScreen ? '30vh' 
                  : isSmallHeightScreen ? '40vh' 
                  : isNormalHeightScreen ? '50vh' 
                  : isBigHeightScreen ? '60vh' 
                  : '70vh'
                }
              >
                {(() => {
                  const isInsurance = newPOSSale.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
                  return newPOSSale.items.map((item, index) => (
                    <ProductCard
                      key={index}
                      item={item}
                      isSummary={true} 
                      isValidatedInsurance={(isInsurance && item.usedInsurance && newPOSSale.insuranceInfo) ? true : false}
                    />
                  ))
                })()}
              </HMBox>
            ) : <></>}
            <POSComputations 
              computeObj={{
                tax, 
                total, 
                basket,
                coverage, 
                subtotal, 
                discount, 
                insCoverage,
                insurancePayment
              }}
              handleClick={handleMakeSale}
            />
            <IconButton   
              className='print'
              onClick={handlePrintProformaInvoice} 
              sx={{
                top: '20px',
                left: '20px',
                background: orange,
                position: 'absolute',
                '&:hover': {
                  background: orange,
                }
              }}
            >
              <LocalPrintshopIcon 
                sx={{
                  color: white,
                  margin: 'auto',
                  fontSize: `${fontSize + 2}px`
                }}
              />
            </IconButton>
          </Box>
        ) : <></>}
      </HMModal>
      
      <Section 
        padding={10} 
        marginTop='0'
        bgColor={white}
        marginBottom='0'
        minHeight='100vh'
      >
        <Grid container spacing={2}> 
          <Grid 
            item 
            xs={12} 
            sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 8} 
            sx={{ display: 'flex', flexDirection: 'column', color: 'red', position: 'relative' }}
          >
            <Grid container spacing={2}> 
              <Grid item xs={6} sm={6}> 
                <DropdownFilter 
                  width='50% !important'
                  type='email'
                  field='email'
                  value={client}
                  required={true}
                  mainMargin='auto 0'
                  label='Select Customer'
                  onChange={handleClientChange}
                  prompt="Search using customer's email..."
                  options={users.filter(user => user.status === 'active')}
                />
              </Grid>
              <Grid item xs={6} sm={6} sx={{ display:'flex' }}> 
                {(client && Object.keys(client).length) ? (
                  <HMText 
                    text='Customer:'
                    fontWeight={400}
                    color={darkBlue}
                    width='max-content'
                    margin='auto 0 auto 20px'
                  />
                ) : <></>}
                <HMBox
                  width='100%'
                  height='40px'
                  display='flex'
                  position='relative'
                  bgColor={lightBlue}
                  margin={(client && Object.keys(client).length) ? 'auto 0 auto 10px' : 'auto'}
                >
                  <HMText 
                    margin='auto'
                    fontWeight={500}
                    color={darkBlue}
                    padding='5px 10px'
                    text={(client && Object.keys(client).length) ? `${client.firstName}  ${client.lastName}` : 'Unknown Customer'}
                  />
                  {(client && Object.keys(client).length) ? (
                    <IconButton  
                      sx={{
                        ...iconStyle, 
                        color: red,
                        padding: '0',
                        right: '2.5px',
                        cursor: 'pointer',
                        position: 'absolute',
                        '&:hover': {
                          color: white,
                          fontWeight: 500,
                          background: red,
                        }
                      }}  
                      onClick={clearClient} 
                    >
                    <CloseIcon 
                      sx={{
                        fontSize: '12px',
                        color: 'inherit',
                        fontWeight: 'inherit',
                      }} 
                    />
                  </IconButton>
                  ) : <></>}
                </HMBox>
              </Grid>
            </Grid>
            {(avaiblableCategories.length) ? (
              <HMSlider 
                height='40px'
                iconColor={orange}
                padding='auto 10px'
                hideIcons={avaiblableCategories.length < 5 ? true : false}
              >
                <HMText 
                  text='All'
                  color={blue}
                  height='auto'
                  cursor='pointer'
                  margin='auto 5px'
                  padding='1px 10px'
                  hoverColor={white}
                  hoverBgColor={blue}
                  bgColor={lightBlue}
                  activeColor={white}
                  activeBgColor={orange}
                  className='category otc-choice active'
                  handleClick={(e) => handleChoosedCategory(e, null)}
                />
                  {avaiblableCategories.map((category, index) => (
                    <Box key={index} sx={{margin: avaiblableCategories.length < 5 ? 'auto 0' : 'auto'}}>
                      <HMText 
                        color={blue}
                        height='auto'
                        cursor='pointer'
                        margin='auto 5px'
                        padding='1px 10px'
                        hoverColor={white}
                        hoverBgColor={blue}
                        bgColor={lightBlue}
                        activeColor={white}
                        text={category.name}
                        activeBgColor={orange}
                        className='category otc-choice'
                        handleClick={(e) => handleChoosedCategory(e, category._id)}
                      />
                    </Box>
                  ))}
              </HMSlider>
            ) : <></>}
            {showingProducts.length ? showingProducts.map((item, index) => (
              <ProductCard
                key={index}
                item={item}
                showStock={true}
                handleCardClick={() => addToBasket(item)} 
              />
            )) : !isPOSLoading ? (
              <HMText 
                margin='auto'
                fontWeight={600}
                color={lightBlack}
                text='No products to show...'
              />
            ) : <></>}
          </Grid>
          <Grid 
            item 
            xs={12} 
            sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 4}
          >
            <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              bgColor={skeletonsLightBlue}
              minHeight={isTabletScreen ? 'auto' : '70vh'}
            >
              {basket.items.length ? (
                <>
                  <HMBox 
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    className='fade-in'
                    margin='5px 5px 5px 2.5px'
                  >
                    <HMCheckbox 
                      margin='auto 0'
                      handleChange={handleCheckAll} 
                      checked={!basket.items.find(item => !item.selected)} 
                    />
                    <HMText
                      float='none'
                      text='Select all'
                      color={lightBlack}
                      width='max-content'
                      margin='auto auto auto 2.5px'
                      fontSize={`${fontSize - 2}px`}
                    />
                    {posPayMethod.toLowerCase() === 'insurance' ? (
                      <HMButton 
                        type='button'
                        margin='auto'
                        color={white}  
                        fontWeight={500}
                        bgColor={orange}
                        padding='1px 5px'
                        className='fade-in'
                        paddingActive='0.5px 4.5px'
                        handleClick={
                          (client && Object.keys(client).length && client.insuranceInfo.length) 
                            ? handleSelectInsurerModal
                            : handleAddInsurerModal
                        }
                        text={
                          <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                            Add Insurance
                          </Typography>
                        }
                      />
                    ) : <></>}
                    <HMButton 
                      type='button'
                      fontWeight={500}
                      padding='1px 5px'
                      margin='auto 0 auto auto'
                      paddingActive='0.5px 4.5px'
                      className='cart-prescription-button'
                      handleClick={handleBasktDeletionModal}
                      disabled={isAnyItemSelected ? false : true}
                      bgColor={isAnyItemSelected ? red : 'inherit'}
                      color={isAnyItemSelected ? white : lightBlack}
                      icon={
                        <DeleteIcon 
                          sx={{
                            ...iconStyle, 
                            marginRight: '5px',
                            fontSize: `${fontSize}px`, 
                          }} 
                        /> 
                      } 
                      text={
                        <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                          {basket.items.find(item => item.selected === true) ? 'Remove' : 'Select item'}
                        </Typography>
                      }
                    />
                  </HMBox>
                  <HMBox
                    width='100%'
                    bgColor={white}
                    maxHeight='60vh'
                    overflowY='auto'
                    className='fade-in'
                  >
                    {basket.items.map((item, index) => {
                      const isInsurance = basket.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
                      const isValidatedInsurance = (isInsurance && item.usedInsurance && basket.insuranceInfo) ? true : false
                      const price = isValidatedInsurance ? item.insurancePrice : item.price
                      return (
                        <HMBox
                          key={index}
                          width='100%'
                          display='flex'
                          margin='2.5px 0'
                          className='fade-in'
                          padding='7.5px 0 5px 2.5px'
                          bgColor={isValidatedInsurance ? darkBlue : lightBlue}
                        >
                          <HMCheckbox 
                            checked={item.selected}
                            margin='auto 5px auto 0'
                            handleChange={(e) => handleItemSelect(e, item)} 
                          />
                          <Link
                            sx={{margin: 'auto 0', cursor: 'pointer'}}
                            onClick={() => navegateToProductPage(item)}
                          >
                            <img 
                              alt={item.productName}
                              src={item.productImage}
                              className='small-image'
                            />
                          </Link>
                          <HMBox
                            width='100%'
                            height='100%'
                            display='flex'
                            margin='auto 0'
                            padding='0 10px'
                            flexDirection='column'
                          >
                            <HMText
                              fontWeight={500}
                              margin='auto auto 0 0'
                              text={item.productName}
                              fontSize={`${fontSize - 1}px`}
                              color={isValidatedInsurance ? white : lightBlack}
                            />
                            <HMBox
                              padding='0'
                              width='100%'
                              height='100%'
                              display='flex'
                              margin='5px auto auto 0'
                            >
                              <HMText
                                color={orange}
                                fontWeight={500}
                                margin='0 auto auto 0'
                                fontSize={`${fontSize - 1}px`}
                                text={`${currency} ${(price * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                              />
                              <HMBox 
                                display='flex'
                                bgColor={white}
                                padding='1px 5px'
                                width='max-content'
                                margin='auto 0 auto auto'
                              >
                                <IconButton   
                                  sx={{margin: 'auto 0', padding: '3px'}}
                                  onClick={() => handleReduceQuantity(item)} 
                                >
                                  <RemoveIcon sx={{width: '15px', height: '15px'}} />
                                </IconButton>
                                <HMTextField 
                                  width='30px'
                                  type='number'
                                  name='quantity'
                                  margin='auto 5px'
                                  value={item.quantity}
                                  className='pos-item quantity'
                                  onChange={(e) => handleQuantityChange(e, item)}
                                />
                                <IconButton   
                                  sx={{margin: 'auto 0', padding: '3px'}}
                                  onClick={() => handleIncreaseQuantity(item)} 
                                >
                                  <AddIcon sx={{width: '15px', height: '15px'}} />
                                </IconButton>
                              </HMBox>
                            </HMBox>
                          </HMBox>
                        </HMBox>
                      )
                    })}
                  </HMBox>
                  <POSComputations 
                    computeObj={{
                      tax, 
                      total, 
                      basket,
                      coverage, 
                      subtotal, 
                      discount, 
                      insCoverage,
                      insurancePayment
                    }}
                    isSummary={true}
                    isDisabled={isSummaryDisabled}
                    handleClick={handleViewSummaryModal}
                  />
                </>
              ) : (
                <HMBox 
                  width='100%'
                  margin='auto'
                  height='100%'
                  display='flex'
                  className='fade-in'
                  flexDirection='column'
                >
                  <HMImage 
                    width='80px'
                    src={emptyCart}
                    className='fade-in'
                    margin='auto auto 10px auto'
                  />
                  <Title
                    color={darkBlue}
                    className='fade-in'
                    title='Empty Basket'
                    size={fontSize + 10}
                    margin='10px auto auto auto'
                  />
                </HMBox>
              )}
            </HMBox>
          </Grid>
        </Grid>
      </Section>

      <Box 
        ref={receiptPrint}
        sx={{
          width: '100%',
          display: 'flex',
          backgroundColor: white,
          flexDirection: 'column',
          minWidth: isTabletScreen ? 'auto' : '400px'
        }}
      >
        <Title 
          display='none'
          margin='10px auto'
          size={fontSize + 6}
          title='Offical Reiceit' 
          className='print-receipt-title'
        />
        {Object.keys(posSale).length ? (
          <HMBox
            width='100%'
            display='none'
            padding='10px 0'
            className='print-receipt-body'
          >
            {(() => {
              const isInsurance = posSale.paymentInfo.method.toLowerCase() === 'insurance' ? true : false
              return posSale.items.map((item, index) => (
                <ProductCard
                  key={index}
                  item={item}
                  isSummary={true} 
                  isValidatedInsurance={(isInsurance && item.usedInsurance && posSale.insuranceInfo) ? true : false}
                />
              ))
            })()}
            <POSComputations 
              computeObj={{
                basket: posSale,
                tax: (posSale.tax || 0), 
                subtotal: posSale.subtotal, 
                discount: posSale.discount, 
                insurancePayment: computeInsurancePayment(posSale),
                total: (posSale.subtotal - posSale.discount - insurancePayment), 
                coverage: posSale.insuranceInfo ? posSale.insuranceInfo.storeValidation.coverage : 0, 
                insCoverage: posSale.insuranceInfo ? posSale.insuranceInfo.storeValidation.coverage : 0,
              }}
            />
          </HMBox>
        ) : <></>}
      </Box>
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default Landing
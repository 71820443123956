import React from 'react'

function HMImage(props) {

    const imgStyle = {
        border: props.border,
        margin: props.margin,
        cursor: props.cursor,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        minHeight: props.minHeight,
        width: props.width || '100%',
        height: props.height || 'auto',
        backgroundColor: props.bgColor,
        borderRadius: props.borderRadius || '8px',
        borderTopLeftRadius: props.borderTopLeftRadius || props.borderRadius,
        borderTopRightRadius: props.borderTopRightRadius || props.borderRadius,
        borderBottomLeftRadius: props.borderBottomLeftRadius || props.borderRadius,
        borderBottomRightRadius: props.borderBottomRightRadius || props.borderRadius,
    }

    return (
        <img 
            alt={props.alt}
            src={props.src}
            style={imgStyle}
            onClick={props.handleClick}
            className={`HM-image ${props.className}`}
        />
    )
}

export default HMImage
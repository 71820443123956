import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/ArrowBack'
import Lock from '../../assests/Lock.svg'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMImage from '../../components/common/tools/HMImage'
import HMText from '../../components/common/tools/HMText'
import { 
  fontSize,
  textStyle, 
  iconStyle 
} from '../../components/common/tools/Styles'
import {
  blue,
  black,
  orange,
  lightBlack,
} from '../../hooks/useColors'
import HMButton from '../../components/common/tools/HMButton'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const Forbidden = () => {
  const navigate = useNavigate()
  const { 
    user 
  } = useSelector((state) => state.auth)

  const { windowW } = useWindowDimensions()
  const isTabletScreen = windowW < 720 ? true : false

  return (
    <Page 
      width='100vw'
      height='100vh'
    >
      <HMBox
        margin='auto'
        display='flex'
        flexDirection='column'
      >
          <HMImage 
            src={Lock} 
            margin='auto'
            width={isTabletScreen ? '100px' : '150px' }
          />
          <HMText
            color={black}
            text='Forbidden'
            fontWeight={800}
            fontSize={fontSize * 2}
            margin='20px auto 10px auto'
          />
          <HMText
            margin='auto'
            color={lightBlack}
            fontSize={`${fontSize + 2}px`}
            text="We are sorry to say that you don't have access to this page."
          />
          {!user ? (
            <Typography
              sx={{
                color: lightBlack,
                margin: 'auto auto 10px auto'
              }}
            >
              If you aren't logged in, make sure to&nbsp;
              <Link 
                sx={{
                  color: blue,
                  cursor: 'pointer',
                  textDecoration: 'none',
                  '&:hover': {
                    color: orange
                  }
                }}
                onClick={() => navigate('/login')}
              >
                login
              </Link> 
              &nbsp;first and try again
            </Typography>
          ) : <></>}
          <HMBox
            display='flex'
            margin='10px auto'
          >
            <HMButton
              width='130px'
              type='button'
              bgColor={orange}
              handleClick={() => navigate(-1)}
              text={<Typography sx={textStyle}>Go back</Typography>}
              icon={<BackIcon sx={{ iconStyle, color: 'inherit', fontSize: '16px' }} />}
            />
            <HMButton
              width='130px'
              type='button'
              bgColor={blue}
              handleClick={() => navigate('/')}
              text={<Typography sx={textStyle}>Take me home</Typography>}
            />
          </HMBox>
      </HMBox>
    </Page>
  )
}

export default Forbidden

import React from 'react'
import Paper from '@mui/material/Paper'
import HMBox from './HMBox'
import HMSpiner from '../tools/HMSpiner'
import HMLogo from '../tools/HMLogo'
import Title from '../tools/Title'
import {
    fontSize
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

function BeforeLogin(props) {
    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 768 ? true : false

    return (
        <>
            {props.isLoading ? (
                <HMSpiner 
                    size={60}
                    width='100%'
                    zIndex={999}
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <HMBox
                width='100vw'
                display='flex'
                height='100vh'
                flexDirection='column'
                className='customer fade-in'
            >
                <HMLogo 
                    margin='auto auto 10px auto'
                />
                <Paper 
                    elevation={2} 
                    style={{
                        padding: '20px', 
                        display: 'flex',
                        borderRadius: '8px',
                        flexDirection: 'column',
                        margin: '10px auto auto auto',
                        width: isTabletScreen ? 'auto' : '350px',
                    }}
                >
                    <Title 
                        margin='15px auto'
                        size={fontSize + 2}
                        title={props.title}
                    />
                    {props.children}
                </Paper>
            </HMBox>
        </>
    )
}

export default BeforeLogin
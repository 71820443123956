import React from 'react'
import ReactApexChart from 'react-apexcharts'

const HMRadialBar = (props) => {
    const options = {
      chart: {
        height: 280,
        type: "radialBar"
      },
      
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%',
            background: props.backColor || "#D6FFF3",
            image: undefined,
            position: 'front',
          },
          track: {  
            background: props.backColor || '#D6FFF3',
            strokeWidth: '57%',
            margin: 0,
          },
      
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 1,
              show: false,
              color: "#D6FFF3",
              fontSize: "13px"
            },
            value: {
              formatter: function (val) {
                return '+'+ val + 'K'
              },
              offsetY: 1,
              fontSize: "16px",
              show: true,
              color: props.fillColor || '#16D39A',
            }
          }
        }
      },
      fill: {
        colors: props.fillColor || ["#16D39A"]
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["progress"],
      responsive: [
        {
        breakpoint: 290,
        options: {
            chart: {
            width: 300,
            },
        },
        },
    ],
    };
  
    return (
      <ReactApexChart
        width="100%"
        height="80%"
        options={options}
        series={props.data}
        type="radialBar"

      />
    )
  }

  export default HMRadialBar;
import axios from 'axios'

const API_URL_SUPPLIERS = '/api/v1/suppliers'

// Get all suppliers
const getSuppliers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(API_URL_SUPPLIERS, config)
    
    const data = response.data
    
    return data
}

// Get all suppliers
const getMySuppliers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_SUPPLIERS}/mine`, config)
    
    const data = response.data
    
    return data
}

// Get all supplier names for application
const getSuppliersForApplication = async () => {
    const response = await axios.get(`${API_URL_SUPPLIERS}/application`)
    
    const data = response.data
    
    return data
}

// Get a suppliers
const getSupplier = async (supplierID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${API_URL_SUPPLIERS}/${supplierID}`, config)

    const data = response.data
    
    return data
}
// Create a new supplier
const createSupplier = async (newSupplier, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(API_URL_SUPPLIERS, newSupplier, config)
    
    const data = response.data
    return data
}

// Update a supplier
const updateSupplier = async (updatedSupplier, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${API_URL_SUPPLIERS}/${updatedSupplier._id}`, updatedSupplier, config)

    const data = response.data
    
    return data
}

// Update a supplier
const deleteSupplier = async (supplierID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(`${API_URL_SUPPLIERS}/${supplierID}`, config)

    return response.data
}

const supplierService = {
    createSupplier,
    updateSupplier,
    deleteSupplier,
    getMySuppliers,
    getSuppliersForApplication,
    getSuppliers,
    getSupplier
}
export default supplierService
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import VerifiedIcon from '@mui/icons-material/Verified'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import {
  resetMe,
  updateMe
} from '../../features/me/meSlice'
import { 
  addCart,
  resetCart,
} from '../../features/cart/cartSlice'
import { 
  resetSearch,
  searchProductsInStore,
} from '../../features/search/searchSlice'
import { 
  getPublicStore,
  resetStoresWithListings,
} from '../../features/storesWithListings/storesWithListingsSlice'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMProductCard from '../../components/common/layout/HMProductCard'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMSearchField from '../../components/common/tools/HMSearchField'
import HMPriceFilter from '../../components/common/tools/HMPriceFilter'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMImage from '../../components/common/tools/HMImage'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  fontSize
} from '../../components/common/tools/Styles'
import logo from '../../assests/logo.svg'
import healthCarelogo from '../../assests/hearthcare.svg'
import {
  currencies,
  storeTypes,
} from '../../hooks/helperFunctions'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  red,
  blue,
  gray,
  white,
  orange,
  darkBlue,
  lightRed,
  lightBlue,
  lightOrange,
  getRandomColor,
} from '../../hooks/useColors'

function PublicStore () {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { 
    me,
    isUpdated,
    isLoading,
  } = useSelector((state) => state.me)
  const {
    visible
  } = useSelector((state) => state.nav)
  const { 
    user
  } = useSelector((state) => state.auth)
  const {
    isAdded
  } = useSelector((state) => state.cart)
  const { 
    searchLoading,
    foundProductsInStore,
    areFoundProductsInStore,
  } = useSelector((state) => state.search)
  const { 
    publicCategories,
  } = useSelector((state) => state.category)
  const { 
    publicStore,
    isPublicStore,
  } = useSelector((state) => state.storesWithListings)

  const minDifference = 1000
  const [maxPrice, setMaxPrice] = useState(0)
  const [minPrice, setMinPrice] = useState(0)
  const [currency, setCurrency] = useState('')
  const [searchText, setSearchText] = useState('')
  const [logoColor, setLogoColor] = useState(gray)
  const [priceRange, setPriceRange] = useState([])
  const [foundMatch, setFoundMatch] = useState(true)
  const [initialData, setInitialData] = useState([])
  const [showingCategories, setShowingCategories] = useState([])
  const [currentStoreListings, setCurrentStoreListings] = useState([])
  const [showingParentCategories, setShowingParentCategories] = useState([])

  const {
      windowW
  } = useWindowDimensions()

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false
  
  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Stores', link: '/stores' },
    { text: Object.keys(publicStore).length && publicStore.store.name, link: null },
  ]

  useEffect(() => {
    setLogoColor(getRandomColor())
    dispatch(getPublicStore(storeId))
    setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
  }, [dispatch, storeId])

  useEffect(() => {
    if (searchText.length) {
      const limit = 20
      dispatch(searchProductsInStore({searchText, storeId, limit}))
    }
  }, [dispatch, searchText, storeId])
  
  useEffect(() => {
    if (Object.keys(publicStore).length) {
      const listingPrices = publicStore.productsAndListings.map(data => data.storeListing.onlinePrice)
      const tempMinPrice = listingPrices.reduce((a, b) => Math.min(a, b), Infinity)
      const tempMaxPrice = listingPrices.reduce((a, b) => Math.max(a, b), -Infinity)
      setMinPrice(tempMinPrice)
      setMaxPrice(tempMaxPrice)
      setPriceRange([tempMinPrice, tempMaxPrice])
      setInitialData([...publicStore.productsAndListings])
      setCurrentStoreListings([...publicStore.productsAndListings])
    }
  }, [publicStore])

  useEffect(() => {
    if (publicCategories.length && currentStoreListings.length) {
      setShowingParentCategories(publicCategories.filter(parent => {
        return parent.children.find(category => {
          return currentStoreListings.find(data => data.product.category.id._id === category._id)
        })
      }))
    }
  }, [publicCategories, currentStoreListings])

  useEffect(() => {
    if (showingParentCategories.length) {
      setShowingCategories(showingParentCategories[0].children)
    }
  }, [showingParentCategories])

  useEffect(() => {
    if (!searchText.length && initialData.length) {
      setCurrentStoreListings(initialData)
    } else if (searchText.length) {
      setCurrentStoreListings(foundProductsInStore)
      if (foundProductsInStore.length) {
        setFoundMatch(true)
      } else {
        setFoundMatch(false)
      }
    }
  }, [searchText, initialData, foundProductsInStore])

  useEffect(() => {
    if (isAdded) {
      dispatch(resetCart())
    }

    if (isUpdated) {
      dispatch(resetMe)
    } 

    if (isPublicStore) {
      dispatch(resetStoresWithListings())
    }

    if (areFoundProductsInStore) {
      dispatch(resetSearch())
    }
  }, [dispatch, isAdded, isUpdated, isPublicStore, areFoundProductsInStore])

  const handleChoosedCategory = (e, category) => {
    setShowingCategories(category.children)
    const categoryClass = document.getElementsByClassName('class')
    for (let i = 0; i < categoryClass.length; i++) {
        categoryClass[i].classList.remove('active')
    } 
    e.target.classList.add('active')
  }

  const handleProductClick = (productId, listingId) => {
    navigate(`/stores/${productId}/${listingId}`)
  }

  const handleSearchClear = () => {
    setSearchText('')
    setFoundMatch(true)
    setCurrentStoreListings(initialData)
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
  }
  const handleSearchSubmit = (e) => {
    e.preventDefault()
  }

  const filterBasedPrices = (listings) => {
    if (listings.length && (minPrice !== priceRange[0] || maxPrice !== priceRange[1])) {
      const result = listings.filter(data => (
          data.storeListing.onlinePrice >= priceRange[0] &&
          data.storeListing.onlinePrice <= priceRange[1] 
        ))
      setCurrentStoreListings(result)
      if (result.length) {
        setFoundMatch(true)
      } else {
        setFoundMatch(false)
      }
    } else if (listings.length && minPrice === priceRange[0] && maxPrice === priceRange[1]) {
      setCurrentStoreListings(listings)
    }
  }

  const dynamicSearch = () => {
    if (!searchText.length) {
      filterBasedPrices(initialData)
    } else if (searchText.length && foundProductsInStore.length) {
      filterBasedPrices(currentStoreListings)
    }
  }

  useEffect(() => {
    dynamicSearch()
    // eslint-disable-next-line
  }, [priceRange])

  const handlePriceRangeChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDifference) {
      if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], maxPrice - minDifference)
          setPriceRange([clamped, clamped + minDifference])
      } else {
          const clamped = Math.max(newValue[1], minPrice + minDifference)
          setPriceRange([clamped - minDifference, clamped])
      }
    } else {
      setPriceRange(newValue)
    }
  }

  const handleMinChange = (e) => {
    const minValue = Number(e.target.value)
    if (minValue < maxPrice - minDifference) {
        setPriceRange([minValue, priceRange[1]])
    }
  }
  const handleMaxChange = (e) => {
    const maxValue = Number(e.target.value)
    if (maxValue <= maxPrice) {
        setPriceRange([priceRange[0], maxValue])
    }
  }

  const handleFavorite = () => {
    const updatedUser = {}
    const storeId = publicStore.store._id
    const favoriteStoreIds = ('favoriteStores' in me && me.favoriteStores) ? [...me.favoriteStores] : []
    if (favoriteStoreIds.includes(storeId)) {
      const tempFavoriteStores = favoriteStoreIds.filter(id => id !== storeId)
      updatedUser['favoriteStores'] = tempFavoriteStores
    } else {
      favoriteStoreIds.push(storeId)
      updatedUser['favoriteStores'] = favoriteStoreIds
    }
    dispatch(updateMe(updatedUser))
  }
  
  const handleAddToCart = (e, listingId) => {
    e.stopPropagation()
    const cartItem = {
      quantity: 1,
      storeListing: listingId,
    }
    dispatch(addCart({cartItem}))
  }

  return (
    <Page>
      {!Object.keys(publicStore).length ? (
        <HMSpiner 
          size={50}
          left='48%'
          bgColor='inherit'
          position='absolute'
          margin={isTabletScreen ? '30% 0 0 0' : '15% 0 0 0'}
        />
      ) : (
        <>
          {isLoading ? (
            <HMSpiner 
              size={60}
              zIndex={999}
              width='100%'
              height='80vh'
              margin='auto'
              position='absolute'
              bgColor='transparent'
            />
          ) : <></>}
          <FullWidthContainer
            display='flex'
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
            />
          </FullWidthContainer>
          <Section
            padding={10}
            bgColor={white}
            position='relative'
          >
            {(user && Object.keys(user).length && Object.keys(me).length) ? (
              <IconButton   
                onClick={handleFavorite} 
                sx={{ 
                  top: '15px',
                  right: '15px',
                  position: 'absolute',
                }} 
              >
                <FavoriteOutlinedIcon 
                  sx={{
                    color: 'favoriteStores' in me && me.favoriteStores.find(id => id === publicStore.store._id) ? orange : gray,
                    '&:hover': {
                        color: orange
                    }
                  }}
                />
              </IconButton>
            ) : <></>}
            <HMImage 
              width='100%'
              alt={publicStore.store.name}
              src={ publicStore.store.type === storeTypes[0] ? logo : healthCarelogo } 
            />
            <HMBox
              zIndex={99}
              display='flex'
              position='relative'
              className='late-display'
              margin={isTabletScreen ? '-55px auto auto 5%' : '-75px auto auto 5%'}
            >
              {publicStore.store.logo ? (
                <HMImage
                  margin='auto'
                  borderRadius='50%'
                  alt={publicStore.store.name}
                  src={publicStore.store.logo}
                  border={`3px solid ${white}`}
                  width={isTabletScreen ? '100px' : '150px'}
                  height={isTabletScreen ? '100px' : '150px'}
                />
                ) : (
                  <Box 
                    sx={{
                      margin: 'auto',
                      display: 'flex',
                      borderRadius: '50%',
                      backgroundColor: logoColor,
                      border: `3px solid ${white}`,
                      width: isTabletScreen ? '100px' : '150px',
                      height: isTabletScreen ? '100px' : '150px',
                    }}
                  >
                    <HMText
                      float='none'
                      margin='auto'
                      color={white}
                      fontWeight={700}
                      textAlign='center'
                      fontSize={isTabletScreen ? '60px' : '95px'}
                      text={publicStore.store.name.substr(0, 1).toUpperCase()}
                    />
                  </Box>
                )}
                <HMBox
                    display='flex'
                    margin='auto 0'
                    bgColor={white}
                    flexDirection='column'
                >
                  <HMBox
                    padding='0'
                    width='100%'
                    display='flex'
                    margin='auto auto 5px auto'
                  >
                    <Title
                      size={fontSize}
                      title={publicStore.store.name}
                      margin='auto auto auto 5px'
                    />
                    <VerifiedIcon sx={{ margin: 'auto 0 auto auto', fontSize: `${fontSize}px`}} />
                  </HMBox>
                  <HMBox 
                      padding='0'
                      width='100%'
                      margin='auto'
                      display='flex'
                  >
                      <FmdGoodIcon
                        sx={{ 
                            mr: '5px',
                            ml: '-4px', 
                            color: red, 
                            float: 'left', 
                            margin: 'auto 5px auto 0' 
                        }}
                      />
                      <HMText 
                        margin='auto 0'
                        fontSize={`${fontSize - 2}px`}
                        text={publicStore.store.address.mapAddress} 
                      />
                  </HMBox>
                </HMBox>
            </HMBox>

            <HMBox 
              width='100%'
              display='flex'
              padding='20px 0px'
              flexDirection={isTabletScreen ? 'column' : 'row'}
            >
              {initialData.length ? (
                <HMSearchField 
                  boxPadding='5px'
                  title='in store'
                  padding='20px 0px'
                  borderRadius='10px'
                  bgColor={lightBlue}
                  className='data-table'
                  searchText={searchText}
                  searching={searchLoading}
                  handleClick={handleSearchClear}
                  closeMargin='auto auto auto 5px'
                  handleChange={handleSearchChange}
                  handleSubmit={handleSearchSubmit}
                  width={isTabletScreen ? '100%' : '40%'}
                  margin='auto 0'
                />
              ) : <></>}
            </HMBox>
            <HMBox 
              width='100%'
              display='flex'
              padding={isTabletScreen ? '0' : '20px 0px'}
              flexDirection={isTabletScreen ? 'column' : 'row'}
            >
              {(initialData.length && maxPrice - minPrice > minDifference) ? (
                <HMBox 
                  display='flex'
                  padding='0 0 20px 0'
                  width={ isTabletScreen ? '100%' : '400px'}
                >
                  {!isTabletScreen ? (
                    <HMText 
                      fontWeight={500}
                      textAlign='right'
                      width='max-content'
                      margin='auto 5px auto auto'
                      fontSize={`${fontSize - 1}px`}
                      text='Filter&nbsp;by&nbsp;price&nbsp;:'
                    />
                  ) : <></>}
                  <HMPriceFilter 
                    isPrice={true}
                    min={minPrice}
                    max={maxPrice}
                    priceRange={priceRange}
                    handleMinChange={handleMinChange}
                    handleMaxChange={handleMaxChange}
                    handlePriceRangeChange={handlePriceRangeChange}
                  />
                </HMBox>
              ) : <></>}   
              {(showingParentCategories.length > 1 && foundMatch) ? (            
                <HMBox 
                  margin='auto'
                  display='flex'
                  className='fade-in'
                  padding='0 0 20px 0'
                  width={isTabletScreen ? '100%' : 'max-content'}
                >
                  {showingParentCategories.map((category, index) => (
                      <HMText 
                        key={index}
                        color={blue}
                        cursor='pointer'
                        borderRadius='20'
                        padding='5px 10px'
                        hoverColor={white}
                        width='max-content'
                        hoverBgColor={blue}
                        bgColor={lightBlue}
                        activeColor={white}
                        margin={
                          isTabletScreen && index === 0 ? 'auto auto auto 0'
                          : isTabletScreen && index + 1 === showingParentCategories.length ? 'auto 0 auto auto' 
                          : isTabletScreen ? 'auto' 
                          : 'auto 5px'
                        }
                        text={
                          isMediumComputerScreen && category.name.toLowerCase().includes('otc') ? 'OTC' 
                          : isMediumComputerScreen && category.name.toLowerCase().includes('health') ? 'Health Products' 
                          : isMediumComputerScreen && category.name.toLowerCase().includes('prescription') ? 'Prescription Meds' 
                          : category.name
                        }
                        activeBgColor={orange}
                        className={`category class ${index === 0 && 'active'}`}
                        handleClick={(e) => handleChoosedCategory(e, category)}
                      />
                  ))}
                </HMBox>
              ) : (
                <></>
              )}    
            </HMBox>

            {(showingCategories.length && initialData.length && foundMatch) ? 
              showingCategories.map((category, catIndex) => (
                <Box 
                  key={catIndex}
                  sx={{width: '100%', marginBottom: '20px'}}
                >
                  {currentStoreListings.find(data => data.product.category.id._id === category._id) ? (
                    <>
                      <Title 
                        width='100%'
                        size={fontSize}
                        textAlign='left'
                        fontWeight={500}
                        color={darkBlue}
                        margin='5px auto'
                        title={category.name}
                      />
                      <Divider sx={{ margin: '20px auto', width: '100%', borderBottomWidth: '1px', color: gray, opacity: 0.5 }} />
                      <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                        {currentStoreListings.filter(data => data.product.category.id._id === category._id).map((data, listIndex) => {
                          return listIndex < 13 ? (
                            <Grid 
                              item 
                              xs={6} 
                              key={listIndex}
                              sx={{display: 'flex'}}
                              sm={
                                  isSmallTabletScreen ? 4 
                                  : isTabletScreen ? 3  
                                  : (isSamllComputerScreen && visible) ? 4
                                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                                  : 1
                              }
                            >
                              <HMProductCard 
                                addToCart={true}
                                className='fade-in'
                                isStockAvailable={true}
                                title={data.product.name}
                                image={data.product.image}
                                price={data.storeListing.onlinePrice}
                                handleAddToCart={(e) => handleAddToCart(e, data.storeListing._id)}
                                handleClick={() => handleProductClick(data.product._id, data.storeListing._id)}
                              />
                            </Grid>
                          ) : listIndex === 13 ? (
                            <Grid item key={listIndex} xs={12} sm={12}>
                              <HMButton 
                                margin='auto'
                                color={orange}
                                borderRadius={25}
                                padding='5px 10px'
                                text='View more...'
                                bgColor={lightOrange}
                                borderColor={lightOrange}
                                paddingActive='4.5px 9.5px'
                                fontSize={`${fontSize - 2}px`}
                                // handleClick={() => navigateToCategoryPage(category)}
                              />
                            </Grid>
                          ) : <></>
                        })}
                      </Grid>
                    </>
                  ) : <></>}
                </Box>
              ))
            : (initialData.length && !foundMatch) ? (
              <HMBox
                width='100%'
                display='flex'
                bgColor={lightRed}
                padding='20px 10px'
                flexDirection={isTabletScreen ? 'column' : 'row'}
              >
                <HMText 
                  width='max-content'
                  margin='auto 5px auto 0'
                  text='This store has no listing matching your search:'
                />
                {searchText.length ? (
                  <HMText 
                    color={red}
                    fontWeight={500}
                    text={searchText}
                    margin='auto 5px'
                    padding='5px 10px'
                    width='max-content'
                  />
                ) : <></>}
                
                <HMBox
                  display='flex'
                  margin='auto 0'
                  padding='5px 0'
                  width='max-content'
                >
                  <HMText 
                    margin='auto 0'
                    width='max-content'
                    text='Min Price:'
                  />
                  <HMText 
                    color={red}
                    fontWeight={500}
                    margin='auto 5px'
                    width='max-content'
                    text={`${currency} ${priceRange[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  />
                </HMBox>
                
                <HMBox
                  display='flex'
                  margin='auto 0'
                  padding='5px 0'
                  width='max-content'
                >
                  <HMText 
                    margin='auto 0'
                    text='Max Price:'
                    width='max-content'
                  />
                  <HMText 
                    color={red}
                    fontWeight={500}
                    margin='auto 5px'
                    width='max-content'
                    text={`${currency} ${priceRange[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  />
                </HMBox>
              </HMBox>
            ) : !initialData.length ? (
              <HMText 
                width='100%'
                margin='20px 0'
                text='There are no available listings yet!'
              />
            ) : <></>}
          </Section>
        </>
      )}
    </Page>
  )
}

export default PublicStore
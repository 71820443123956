import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone'
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import SettingsOverscanTwoToneIcon from '@mui/icons-material/SettingsOverscanTwoTone'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone'
import AddModeratorTwoToneIcon from '@mui/icons-material/AddModeratorTwoTone'
import LeaderboardTwoToneIcon from '@mui/icons-material/LeaderboardTwoTone'
import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone'
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import VaccinesTwoToneIcon from '@mui/icons-material/VaccinesTwoTone'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone'
import LiveHelp from '@mui/icons-material/LiveHelp'
import HMUserNavigation from '../../components/common/navigation/HMUserNavigation'
import SidebarItemLink from '../../components/common/navigation/SidebarItemLink'
import SidebarItem from '../../components/common/navigation/SidebarItem'
import HMAppBar from '../../components/common/navigation/HMAppBar'
import SideBar from '../../components/common/navigation/SideBar'
import HMContainer from '../../components/common/layout/HMContainer'
import Forbidden from '../common/Forbidden'
import HMBox from '../../components/common/layout/HMBox'
import HMNotification from '../../components/common/tools/HMNotification'
import HMMenuIcon from '../../components/common/tools/HMMenuIcon'
import HMSocials from '../../components/common/tools/HMSocials'
import HMButton from '../../components/common/tools/HMButton'
import {
  fontSize,
  textStyle,
  iconStyle,
  appBarIcon,
  closeOpenStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  blue,
  white,
  orange,
  lightBlack,
} from '../../hooks/useColors'

function AdminHome() {
  const navigate = useNavigate()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const {
    sidebarWidth,
  } = useSelector((state) => state.nav)
    
  const {
    windowW,
    windowH
  } = useWindowDimensions()

  const isTabletScreen = windowW < 768 ? true : false
  const isSideBarHeight = windowH < 610 ? true : false

  const [isAllowed, setIsAllowed] = useState(false)
  const [isForbiden, setIsForbiden] = useState(false)
  const [activeColor, setActiveColor] = useState(null)
  
  useEffect(() => {
    if (user) {
      const accessLevel = user.accessLevel
      if (accessLevel === 5 || accessLevel === 1 || accessLevel === 0) {
        setIsAllowed(true)
        if (window.location.pathname === '/admin') {
          navigate('/admin/dashboard')
        }
      } else {
        setIsForbiden(true)
      }
    } else {
      navigate('/admin/login')
    }
  }, [navigate, user])


  const width = isTabletScreen ? '100vw' : `${windowW - sidebarWidth - 12}px` 
  const marginLeft = isTabletScreen ? '0px' : `${sidebarWidth}px`
  
  const stylingIcon = {
    ...iconStyle,
    color: blue,
    margin: 'auto 10px auto 0',
    fontSize: `${fontSize + 4}px`,
  }
    
  const navigateUser = (e) => {
      navigate(e.target.name)
      if (activeColor) {
          activeColor.color = lightBlack
      }
      setActiveColor(e.target.style)
      e.target.style.color = orange
  }

  const handlePOS = () => { 
    
  }

  return user && isAllowed ? (
    <>
      <SideBar>
        <HMBox
          width='100%'
          padding={isTabletScreen ? '0 0 0 4.5%' : '0 0 0 10%'}
        >
          <SidebarItem 
            name='Dashboard'
            navigateUser={navigateUser} 
            leftIcon={<DashboardCustomizeTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Analytics'
            navigateUser={navigateUser} 
            leftIcon={<LeaderboardTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Orders'
            navigateUser={navigateUser} 
            leftIcon={<ListAltTwoToneIcon sx={stylingIcon} />} 
          />
          <SidebarItem 
            name='Product' 
            leftIcon={<VaccinesTwoToneIcon sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='Category' page='categories' navigateUser={navigateUser} />
            <SidebarItemLink text='Product List' page='products' navigateUser={navigateUser} />
            <SidebarItemLink text='Add Product' page='products/create' navigateUser={navigateUser} />
          </SidebarItem>
          <SidebarItem 
            name='Insurer' 
            leftIcon={<AddModeratorTwoToneIcon sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='Insurer List' page='insurers' navigateUser={navigateUser} />
            <SidebarItemLink text='Add Insurer' page='insurers/create' navigateUser={navigateUser} />
          </SidebarItem>
          <SidebarItem 
            name='Store' 
            leftIcon={<AddBusinessTwoToneIcon sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='Stores' page='stores' navigateUser={navigateUser} />
            <SidebarItemLink text='Add a store' page='stores/create' navigateUser={navigateUser} />
          </SidebarItem>
          <SidebarItem 
            name='Supplier' 
            leftIcon={<InventoryTwoToneIcon sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='Suppliers' page='suppliers' navigateUser={navigateUser} />
            <SidebarItemLink text='Add supplier' page='suppliers/create' navigateUser={navigateUser} />
          </SidebarItem>
          <SidebarItem 
            name='Faqs' 
            leftIcon={<LiveHelp sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='Faqs' page='faqs' navigateUser={navigateUser} />
            <SidebarItemLink text='Add Faq' page='faqs/create' navigateUser={navigateUser} />
            <SidebarItemLink text='Faqs Categories' page='faqsCategories' navigateUser={navigateUser} />
          </SidebarItem>
          <SidebarItem 
            name='People' 
            leftIcon={<PeopleOutlineTwoToneIcon sx={stylingIcon} />} 
            rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
          >
            <SidebarItemLink text='users' page='users' navigateUser={navigateUser} />
            <SidebarItemLink text='add a user' page='users/create' navigateUser={navigateUser} />
          </SidebarItem>
        </HMBox>
        {/* TO DO  */}
        {/* Adjust the socials according to the current height */}
        {/* <HMSocials 
          bottom={!isSideBarHeight && 0}
          position={!isSideBarHeight && 'absolute'}
        /> */}
      </SideBar>
      <HMContainer
        width={width}
        className='admin'
        marginLeft={marginLeft}
      >
        <HMAppBar>
          <HMMenuIcon />
          <HMBox
            padding='0'
            display='flex'
            margin='auto 0 auto auto'
          >
            <HMButton 
              color={white}
              bgColor={blue}
              margin={isTabletScreen ? 'auto 5px' : 'auto 10px'}
              padding='2px 5px'
              handleClick={handlePOS}
              paddingActive='1.5px 4.5px'
              text={<Typography sx={textStyle}>POS</Typography>} 
              icon={<ShoppingBasketOutlinedIcon sx={{
                ...iconStyle, 
                fontSize: `${fontSize + 2}px`,
                '&:active' : {
                  fontSize: `${fontSize + 1}px`,
                }
              }} />} 
            />
            <WbSunnyTwoToneIcon sx={appBarIcon} />
            <SettingsOverscanTwoToneIcon sx={appBarIcon} />
            <AccountBalanceWalletTwoToneIcon sx={appBarIcon} />
            <HMNotification />
            <HMUserNavigation />
          </HMBox>
        </HMAppBar>
        <Outlet />
      </HMContainer>
    </>
  ) : isForbiden ? (
    <Forbidden />
  ) : <></>
}

export default AdminHome
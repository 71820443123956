import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import ChangePassword from '../settings/ChangePassword'
import Notifications from '../settings/Notifications'
import TwoFactor from '../settings/TwoFactor'
import Billing from '../settings/Billing'
import Team from '../settings/Team'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import { 
  fontSize,
  textStyle, 
  iconStyle 
} from '../../components/common/tools/Styles'
import { 
  white, 
  black,
  orange, 
  darkBlue,
  lightBlue,
  lightBlack,
} from '../../hooks/useColors'

const tabStyles = {
  mr: 0.2,
  color: black,
  background: white,
  borderRadius: '20px 20px 0 0',
  fontSize: { xs: '9px', sm: '9px' },
}

const tabsStyles = { 
  '& .MuiTabs-indicator': { display: 'none'}, 
  "& .MuiTab-root.Mui-selected": {
    color: darkBlue,
    borderBottom: `3px solid ${orange}`,
  }, 
  "& .MuiTab-root": {
    minWidth: 72,
    fontWeight: 600,
    color: lightBlack,
    fontSize: fontSize + 1,
    textTransform: 'initial',
    border: `2px solid ${lightBlue}`,
    fontFamily: [ 'Roboto',].join(',')
  }
}

const selectedTabStyles = {
  ...tabStyles,
  background: lightBlue,
}

function Settings() {
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const [tabValue, setTabValue] = useState(0)

  const path = window.location.pathname.toLowerCase()

  const handleTabChange = (e, value) => {
    setTabValue(value)
  }

  const breadOptions = [
    { text: 'Home', link: `${
        path.includes('store') ? '/store'
        : path.includes('supplier') ? '/supplier'
        : path.includes('admin') ? '/admin/dashboard' 
        : '/landing'
      }`  
    },
    { text: 'Settings', link: null },
  ]

  return user ? (
    <Page>
      <FullWidthContainer display='flex'>
        <HMBreadcrumbs options={breadOptions} margin='auto auto auto 0' />
        <HMBox padding='0' display='flex' margin='auto 0 auto auto'>
          <HMButton
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>}
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />}
          />
        </HMBox>
      </FullWidthContainer>

      <Section 
        padding={10} 
        bgColor={white}
      >
        <Tabs
          sx={tabsStyles}
          value={tabValue}
          variant='fullWidth'
          textColor='primary'
          onChange={handleTabChange}
          aria-label='analytics tabs'
        >
          <Tab value={0} label='Notifications' sx={tabValue === 0 ? selectedTabStyles : tabStyles} />
          <Tab value={1} label='Password' sx={tabValue === 1 ? selectedTabStyles : tabStyles} />
          <Tab value={2} label='Billing' sx={tabValue === 2 ? selectedTabStyles : tabStyles} />
          {(
            user.accessLevel === 0 || 
            user.accessLevel === 1 || 
            user.accessLevel === 20 || 
            user.accessLevel === 40 || 
            user.accessLevel === 41 || 
            user.accessLevel === 50 || 
            user.accessLevel === 51
          ) ? (
            <Tab value={3} label='Team' sx={tabValue === 3 ? selectedTabStyles : tabStyles} />
          ) : <></>}
          <Tab value={4} label='2FA' sx={tabValue === 4 ? selectedTabStyles : tabStyles} />
        </Tabs>

        {tabValue === 0 ? (
          <Notifications />    
        ) : tabValue === 1 ? (
          <ChangePassword />
        ) : tabValue === 2 ? (
          <Billing />
        ) : tabValue === 3 ? (
          <Team />
        ) : tabValue === 4 ? (
          <TwoFactor />
        ) : <></>}
      </Section>
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing your settings'
      />
    </Section> 
  )
}

export default Settings

import React from 'react'
import HMBox  from '../common/layout/HMBox'
import HMTextField  from '../common/tools/HMTextField'
import HMPhoneNumber  from '../common/tools/HMPhoneNumber'
import HMRadioButton  from '../common/tools/HMRadioButton'
import HMPlacesAutocomplete  from '../common/tools/HMPlacesAutocomplete'

function EditDeliveryInfo(porps) {
    const {
        phone,
        category,
        mapAddress,
        coordinates,
        recipientName,
    } = porps.deliveryInfoItem

    const deliveryCategory = {
        question: 'Address category',
        choices: [
          { label: 'Home', value: 'Home' },
          { label: 'Office', value: 'Office' }
        ]
    }
    const deliveryDefault = {
        question: 'Default delivery address',
        choices: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]
    }

    return (
        <HMBox
            padding='0'
            margin='15px 0'
            className='fade-in'
            width={porps.isTabletScreen ? '80vw' : '100%'}
        >
            <HMPlacesAutocomplete 
                value={mapAddress}
                coordinates={coordinates}
                handleChange={porps.handleChangeMapAddress}
                handleSelect={porps.handleSelectMapAddress}
            />
            <HMBox
                width='100%'
                display='flex'
                padding='10px 0'
                margin='10px auto'
                flexDirection={porps.isTabletScreen ? 'column' : 'row'}
            >
                <HMTextField 
                    type='text'
                    width='100%'
                    required={true}
                    name='recipientName'
                    value={recipientName}
                    label='Recipient name'
                    onChange={porps.handleChange}
                    placeholder='Enter recipient name'
                    margin={porps.isTabletScreen ? 'auto auto 5px auto' : 'auto 5px auto auto'}
                />
                <HMPhoneNumber 
                  name='phone'
                  value={phone}
                  label="Recipient's phone"
                  onChange={porps.handleChange}
                />
            </HMBox>
            <HMBox
                padding='0'
                width='100%'
                margin='auto'
                display='flex'
                className='radio-buttons'
                flexDirection={porps.isTabletScreen ? 'column' : 'row'}
            >
                <HMRadioButton 
                    name='category'
                    value={category}
                    padding='2px 5px'
                    onChange={porps.handleChange}
                    label={deliveryCategory.question}
                    options={deliveryCategory.choices}
                    labelId='tax-applicable-radio-group'
                    margin={porps.isTabletScreen ? 'auto auto 5px auto' : 'auto 5px auto auto'}
                />
                <HMRadioButton 
                    name='default'
                    padding='2px 5px'
                    onChange={porps.handleChange}
                    label={deliveryDefault.question}
                    options={deliveryDefault.choices}
                    labelId='tax-applicable-radio-group'
                    value={porps.deliveryInfoItem.default}
                    margin={porps.categoryisTabletScreen ? '5px auto auto auto' : 'auto auto auto 5px'}
                />
            </HMBox>
        </HMBox>
    )
}

export default EditDeliveryInfo
import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import VerifiedIcon from '@mui/icons-material/Verified'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize
} from '../tools/Styles'
import HMBox from './HMBox'
import {
    red,
    gray,
    blue,
    white,
    orange,
    lightGreen,
    superLightBlue,
    getRandomColor,
} from '../../../hooks/useColors'
import { 
    storeTypes
} from '../../../hooks/helperFunctions'

function HMStoreCard(props) {
    const { 
        user 
    } = useSelector((state) => state.auth)

    return (
        <HMBox
            height='100%'
            display='flex'
            padding='10px'
            cursor='pointer'
            position='relative'
            borderRadius='10px'
            margin={props.margin}
            bgColor={superLightBlue}
            hoverBgColor={lightGreen}
            width={props.width || '100%'}
            handleClick={props.handleClick}
        > 
            {(user && Object.keys(user).length && !props.hideHeart) ? (
                <IconButton   
                    onClick={props.handleFavorite} 
                    sx={{ 
                        zIndex: 999,
                        top: '2.5px',
                        right: '2.5px',
                        padding: '5px',
                        position: 'absolute',
                    }} 
                >
                    <FavoriteOutlinedIcon 
                        sx={{
                            color: props.isFavorite ? orange : gray,
                            '&:hover': {
                                color: orange
                            }
                        }}
                    />
                </IconButton>
            ) : <></>}
            <HMBox
                width='25%'
                display='flex'
                margin='auto 0'
                minWidth='80px'
                flexDirection='column'
            >
                {props.store.logo ? (
                    <HMImage
                        width='70px'
                        height='70px'
                        margin='auto'
                        borderRadius='50%'
                        alt={props.store.name}
                        src={props.store.logo}
                    />
                    ) : (
                        <Box 
                        sx={{
                            width: '70px',
                            height: '70px',
                            margin: 'auto',
                            display: 'flex',
                            borderRadius: '50%',
                            backgroundColor: getRandomColor(),
                        }}
                        >
                        <HMText
                            float='none'
                            margin='auto'
                            color={white}
                            fontWeight={700}
                            textAlign='center'
                            fontSize={`${fontSize + 14}px`}
                            text={props.store.name.substr(0, 1).toUpperCase()}
                        />
                        </Box>
                    )}
            </HMBox>
            <HMBox
                width='75%'
                display='flex'
                flexDirection='column'
            >
                <HMBox
                    padding='0'
                    width='100%'
                    display='flex'
                    margin='0 auto'
                >
                    <Title
                        size={fontSize}
                        fontWeight='700'
                        textAlign='left'
                        margin='auto 5px auto 0'
                        title={props.store.name}
                    />
                    {((props.hideHeart && props.store.status === 'active') || !props.hideHeart) ? (
                        <VerifiedIcon 
                            sx={{ 
                                margin: 'auto auto auto 5px',
                                fontSize: `${fontSize + 4}px`,
                            }} 
                        />
                    ) : <></>} 
                </HMBox>
                <Title
                    margin='5px 0'
                    bgColor={white}
                    textAlign='left'
                    fontWeight={500}
                    padding='0px 5px'
                    borderRadius='8px'
                    width='max-content'
                    size={fontSize - 1}
                    title={props.store.type}
                    color={props.store.type === storeTypes[0] ? blue : orange}
                />
                {props.showAddress ? (
                    <HMBox 
                        padding='0'
                        display='flex'
                    >
                        <FmdGoodIcon
                            sx={{ 
                                mr: '5px',
                                ml: '-4px', 
                                color: red, 
                                float: 'left', 
                                margin: 'auto 5px auto 0' 
                            }}
                        />
                        <HMText 
                            margin='auto 0'
                            fontSize={`${fontSize - 2}px`}
                            text={props.store.address.mapAddress} 
                        />
                    </HMBox>
                ) : <></>}
            </HMBox>
        </HMBox>
    )
}

export default HMStoreCard
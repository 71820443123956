import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import useColors from '../../../hooks/useColors'

const {
    lightBlack,
    lightBlue,
} = useColors
let initailStyle = {
    fontSize: '15px',
    color: lightBlack,
    float: 'right',
    cursor: 'pointer',
    backgroundColor: lightBlue,
    padding: '3px',
    borderRadius: '50px',
    margin: 0,
    transform: 'rotate(0deg)',
    '&:hover': {
        opacity: 0.8
    },
    '&:active': {
        fontSize: '14.5px',
        marginBottom: '0.5px'
    }
  }

const ArrowUpDown = ({ field, isUpward, handleClick}) => {

    const sortingTools = {
        direction: isUpward ? 'desc' : 'asc',
        field: field
    }
    
    return (
        <ArrowUpwardIcon 
            sx={initailStyle} 
            onClick={(e) => handleClick(sortingTools, e)} 
            className='up-down-arrow'
        />
    )
}

export default ArrowUpDown
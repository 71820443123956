import React from 'react'
import IconButton from '@mui/material/IconButton'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined'
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import HMToogleButton from '../common/tools/HMToogleButton'
import HMProgressBar from '../common/tools/HMProgressBar'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'
import {
    blue,
    green,
    orange,
    lightRed,
    lightBlue,
    strongRed,
    lightGreen,
    lightOrange,
} from '../../hooks/useColors'

function HMOrderStatus(props) {

    const status = props.status.toLowerCase()

    return (
        <>
            <HMProgressBar 
                margin={props.margin || 'auto 20px'}
                color={
                    status === 'pending' ? orange 
                    : status === 'accepted' ? orange 
                    : status === 'completed' ? blue 
                    : status === 'processing' ? blue 
                    : status === 'delivering' ? blue 
                    : status === 'received' ? green 
                    : status === 'delivered' ? green 
                    : status === 'refunded' ? strongRed 
                    : status === 'returned' ? strongRed 
                    : status === 'canceled' && strongRed
                }
                value={
                    status === 'pending' ? 10 
                    : status === 'received' ? 100 
                    : status === 'processing' ? 40 
                    : status === 'completed' ? 60 
                    : status === 'accepted' ? 70 
                    : status === 'delivering' ? 80 
                    : status === 'delivered' ? 100 
                    : status === 'refunded' ? 0 
                    : status === 'returned' ? 0 
                    : status === 'canceled' && 0
                }
            />                   
            <HMBox
                display='flex'
                borderRadius={0}
                className='store-sale'
                margin='auto 0 auto auto'
                padding='5px 10px 5px 7.5px'
                color={
                    status === 'pending' ? orange 
                    : status === 'accepted' ? orange 
                    : status === 'confirmed' ? blue 
                    : status === 'completed' ? blue 
                    : status === 'processing' ? blue 
                    : status === 'delivering' ? blue 
                    : status === 'received' ? green 
                    : status === 'delivered' ? green 
                    : status === 'refunded' ? strongRed 
                    : status === 'returned' ? strongRed 
                    : status === 'canceled' && strongRed 
                }
                bgColor={
                    status === 'pending' ? lightOrange 
                    : status === 'accepted' ? lightOrange 
                    : status === 'confirmed' ? lightBlue 
                    : status === 'completed' ? lightBlue 
                    : status === 'processing' ? lightBlue 
                    : status === 'delivering' ? lightBlue 
                    : status === 'received' ? lightGreen
                    : status === 'delivered' ? lightGreen 
                    : status === 'refunded' ? lightRed 
                    : status === 'returned' ? lightRed 
                    : status === 'canceled' && lightRed 
                }
            >
                {status === 'pending' ? (
                    <MoreHorizIcon 
                        sx={{
                            margin: 'auto',
                            color: 'inherit',
                            fontSize: `${fontSize}px`,
                        }}
                    />
                ) : status === 'processing' ? (
                    <TuneRoundedIcon 
                        sx={{
                            color: 'inherit',
                            margin: 'auto 5px auto auto',
                            fontSize: `${fontSize + 1}px`,
                        }}
                    />
                ) : (status === 'delivering' || status === 'accepted' )? (
                    <>
                        <BarChartRoundedIcon 
                            sx={{
                                color: 'inherit',
                                transform: 'rotate(-90deg)',
                                margin: 'auto -1px 4px auto',
                                fontSize: `${fontSize - 4}px`,
                            }}
                        />
                        <DeliveryDiningRoundedIcon 
                            sx={{
                                color: 'inherit',
                                margin: 'auto 5px auto 0',
                                fontSize: `${fontSize + 2}px`,
                            }}
                        />
                    </>
                ) : (status === 'canceled' || status === 'refunded') ? (
                    <PhonelinkEraseOutlinedIcon 
                        sx={{
                            color: 'inherit',
                            margin: 'auto 5px auto auto',
                            fontSize: `${fontSize + 1}px`,
                        }}
                    />
                ) : status === 'returned' ? (
                    <KeyboardReturnIcon 
                        sx={{
                            color: 'inherit',
                            margin: 'auto 5px auto auto',
                            fontSize: `${fontSize + 1}px`,
                        }}
                    />
                ) : status === 'completed' ? (
                    <InventoryOutlinedIcon 
                        sx={{
                            color: 'inherit',
                            margin: 'auto 5px auto auto',
                            fontSize: `${fontSize + 1}px`,
                        }}
                    />
                ) : (status === 'received' || status === 'delivered') ? (
                    <TaskAltIcon 
                        sx={{
                            color: 'inherit',
                            margin: 'auto 5px auto auto',
                            fontSize: `${fontSize + 1}px`,
                        }}
                    />
                ) : <></>}
                <HMText
                    color='inherit'
                    fontWeight={500}
                    text={props.status}
                    fontSize={`${fontSize}px`}
                    margin='auto auto auto 5px'
                />
                {props.canChangeOrderStatus && status === 'pending' ? (
                  <HMToogleButton 
                    status={props.isStatusChanging}
                    handleChange={props.handleChangingStatus}
                    text={
                        status === 'pending' 
                        ? 'Start processing' 
                        : ''
                    }
                  /> 
                ) : <></>} 
            </HMBox>
            {props.canChangeOrderStatus && status !== 'pending' && status !== 'canceled' ? (
                <IconButton   
                    sx={{margin: 'auto 0 auto 5px'}}
                    onClick={props.handleStatusChange} 
                >
                    <DriveFileRenameOutlineOutlinedIcon 
                        sx={{
                            margin: 'auto',
                            fontSize: `${fontSize}px`,
                        }}
                    />
                </IconButton>
            ) : <></>}
        </>
    )
}

export default HMOrderStatus
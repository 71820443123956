import React, { useEffect } from 'react'
import {  
    useSelector,
    useDispatch
} from 'react-redux'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import HMLogo from '../tools/HMLogo'
import {
    closeStyle,
    mainContainer,
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    white
} from '../../../hooks/useColors'
import { hideSidebarForSmall } from '../../../features/nav/navSlice'

function Sidebar (props) {
    const { windowW, windowH } = useWindowDimensions()
    const isTabletScreen = windowW < 768 ? true : false
    const { visible, sidebarWidth } = useSelector((state) => state.nav)
    const dispatch = useDispatch()
    const hideSidebar = () => {
        dispatch(hideSidebarForSmall())
    }

    useEffect(() => {
        if (isTabletScreen) {
            dispatch(hideSidebarForSmall())
        }
        // eslint-disable-next-line
    }, [])

    let elevation = 0
    let boxStyle = {
        ...mainContainer,
        top: 0,
        width: sidebarWidth,
        height: `${windowH}px`,
        backgroundColor: props.bgColor || white
    }
    const gridLogoStyle = {
        display: 'flex',
        flexWrap: 'wrap'
    }
    let xStyle = {
        ...closeStyle,
    }
    
    if (!visible) {
        boxStyle = {
            ...boxStyle,
            width: '0px',
            visibility: 'hidden',
        }
    } else if (visible && isTabletScreen) {
        boxStyle = {
            ...boxStyle,
            display: 'block',
        }
        elevation = 2
    }

    if (!isTabletScreen) {
        xStyle = {
            display: 'none'
        }
    }
    
    return (
        <Paper 
            sx={boxStyle} 
            elevation={elevation}
            className={`HM-sidebar ${props.className}`} 
            style={
                visible ? {
                    width: sidebarWidth
                } : {
                    width: '0px'
                }
            }
        >
            <Grid>
                <Grid sx={gridLogoStyle}>
                    <HMLogo 
                        logo={props.logo}
                        leftColor={props.leftColor}
                        rightColor={props.rightColor}
                        isOtherLogo={props.isOtherLogo}
                    />
                    <CloseTwoToneIcon sx={xStyle} onClick={hideSidebar} className={(visible && isTabletScreen) ? 'close-x-button' : ''} />
                </Grid>
                <Grid sx={{ marginTop: '20px', width: '100%' }}>
                    { props.children }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Sidebar




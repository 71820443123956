import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import storesWithListingsService from './storesWithListingsService'

const initialState = {
    message: '',
    isError: false,
    isLoading: false,
    publicStore: {},
    publicStores: [],
    isPublicStore: false,
    storeWithListings: {},
    arePublicStores: false,
    storesWithListings: [],
    isStoreWithListings: false,
    areStoresWithListings: false,
}

// Get a store with store listings for public use
export const getPublicStore = createAsyncThunk(
    'storesWithListings/publicOne',
    async (storeId, thunkAPI) => {
        try {
            return await storesWithListingsService.getPublicStore(storeId)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all stores with store listings for public use
export const getAllPublicStores = createAsyncThunk(
    'storesWithListings/publicAll',
    async (_, thunkAPI) => {
        try {
            return await storesWithListingsService.getAllPublicStores()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get a store with store listings
export const getStoreWithListings = createAsyncThunk(
    'storesWithListings/one',
    async (storeId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storesWithListingsService.getStoreWithListings(storeId, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all stores with store listings
export const getAllStoresWithListings = createAsyncThunk(
    'storesWithListings/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await storesWithListingsService.getAllStoresWithListings(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const storesWithListingsSlice = createSlice({
    name: 'storesWithListings',
    initialState,
    reducers: {
        resetStoresWithListings: (state) => {
            state.message = ''
            state.isError = false
            state.isLoading = false
            state.isPublicStore = false
            state.arePublicStores = false
            state.isStoreWithListings = false
            state.areStoresWithListings = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPublicStore.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isPublicStore = false
            })
            .addCase(getPublicStore.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isPublicStore = true
                state.publicStore = action.payload
            })
            .addCase(getPublicStore.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isPublicStore = false
            })

            .addCase(getAllPublicStores.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.arePublicStores = false
            })
            .addCase(getAllPublicStores.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.arePublicStores = true
                state.publicStores = action.payload
            })
            .addCase(getAllPublicStores.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.arePublicStores = false
                state.message = action.payload
            })
            
            .addCase(getStoreWithListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.isStoreWithListings = false
            })
            .addCase(getStoreWithListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.isStoreWithListings = true
                state.storeWithListings = action.payload
            })
            .addCase(getStoreWithListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.isStoreWithListings = false
            })

            .addCase(getAllStoresWithListings.pending, (state) => {
                state.isError = false
                state.isLoading = true
                state.areStoresWithListings = false
            })
            .addCase(getAllStoresWithListings.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.areStoresWithListings = true
                state.storesWithListings = action.payload
            })
            .addCase(getAllStoresWithListings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
                state.areStoresWithListings = false
            })
    }
})

export const { resetStoresWithListings } = storesWithListingsSlice.actions
export default storesWithListingsSlice.reducer
const useColors = {
    red: '#ff7588',
    gray: '#c3c3c3',
    blue: '#00aeef',
    green: '#16d39a',
    white: '#ffffff',
    black: '#000000',
    orange: '#f78800',
    warning: '#fff545',
    lightRed: '#ffedf0',
    darkBlue: '#2f3854',
    lightGray: '#f1f1f1',
    lightBlue: '#effbff',
    lightGreen: '#d6fff3',
    darkOrange: '#e38008',
    lightBlack: '#7d7d7d',
    lightOrange: '#fff3e4',
    middleOrange: '#ffa500',
    lightDarkBlue: '#f1f1f1',
    lightWarning: '#fff42b5c',
    superLightBlue: '#f4fcff',
    superLightGray: '#f1f1f163',
    skeletonsLightBlue: '#f4fcff5e',
    seeThroughLightBlue: '#f4fcffdb',
    strongRed: 'rgba(255, 0, 0, 0.8)',
    bgGreen: 'rgba(22, 211, 154, 0.42)',
    bgOrange: 'rgba(255, 165, 0, 0.42)',
}

export const {
    red,
    blue,
    gray,
    white,
    black,
    green,
    orange,
    warning,
    bgGreen,
    lightRed,
    bgOrange,
    darkBlue,
    lightGray,
    strongRed,
    lightBlue,
    lightBlack,
    lightGreen,
    darkOrange,
    lightOrange,
    middleOrange,
    lightWarning,
    lightDarkBlue,
    superLightBlue,
    superLightGray,
    skeletonsLightBlue,
    seeThroughLightBlue,
} = useColors

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export default useColors
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { getFaqs, createFaq, resetFaq } from '../../../features/faqs/faqsSlice'
import {
  getFaqCategories,
} from '../../../features/faqCategories/faqCategorySlice'
import { accessLevels } from '../../../hooks/helperFunctions'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMButton from '../../../components/common/tools/HMButton'
import HMEditor from '../../../components/common/tools/HMEditor'

import { textStyle } from '../../../components/common/tools/Styles'
import { white, green, lightBlue } from '../../../hooks/useColors'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function CreateFaq() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {publiFaqCategories} = useSelector((state) => state.faqCategory)

  const [categoryName, setCategoryName] = React.useState([])
  const [selectedRole, setRole] = useState('')
  const [selectedCategoryId, setCategoryId] = useState('')

  const breadOptions = [
    { text: 'Faqs', link: '/admin/faqs' },
    { text: 'Add an faq', link: null },
  ]

  const accessLevels = [
    { role: 'Stores', accessLevel: [50, 51, 55] },
    { role: 'Suppliers', accessLevel: [40, 41, 45] },
    { role: 'Customers', accessLevel: [100] },
    { role: 'Admins', accessLevel: [0, 1, 5, 20] },
  ]

  const categories = [
    { category: 'Account' },
    { category: 'Add supplier' },
    { category: 'User Store' },
    { category: 'payments' },
    { category: 'product' },
  ]



  const categoryByName = (categoryName) =>{
   const faqCategory = publiFaqCategories.filter(category => category.name === categoryName)
   return faqCategory[0]._id
  }


  const faqAccessLevel = (selectedRole) => {
    const index = accessLevels.findIndex((object) => {
      return object.role === selectedRole.role
    })
    return accessLevels[index].accessLevel
  }

  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    role: '',
    category: '',
  })
  const { question, answer, role, category } = formData

  useEffect(() => {
    dispatch(getFaqCategories())
  }, [dispatch])

  const handleEditorChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      answer: content,
    }))
  }
  
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleCategoryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      category: val,
    }))

    setCategoryId(categoryByName(val.category))
  
  }


  const handleAccessLevelChangeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      role: val,
    }))
   setRole(faqAccessLevel(val))
  
  }

  const disabled =
    question === ''  || role === '' || answer=== '' || category === ''
      ? true
      : false

  const handleCreate = (e) => {
    e.preventDefault()

    const newFaq = {
      question: question,
      answer: answer,
      role: selectedRole,
      category: [`${selectedCategoryId}`]
    }

   dispatch(createFaq(newFaq))
   navigate("/admin/faqs")
  }
  useEffect(() => {
    if (question.trim() && answer.trim() && category) {
    }
  }, [question, answer, role, category])

  return (
    <Page>
      <FullWidthContainer>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <HMBreadcrumbs options={breadOptions} />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </FullWidthContainer>
      <Section bgColor={white} padding={10}>
        <Grid
          component="form"
          container
          spacing={2}
          onSubmit={handleCreate}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} sm={6}>
            <HMTextField
              margin="0"
              name="question"
              label="Question"
              value={question}
              required={true}
              onChange={handleChange}
              placeholder="Enter question"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DropdownFilter
              width="100%"
              field="category"
              label="Category"
              bgColor={white}
              value={category}
              prompt="Select category"
              mainMargin="auto"
              options={categories}
               onChange={handleCategoryChange}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ display: 'flex' }}>
            <DropdownFilter
              width="100%"
              field="role"
              label="Role"
              bgColor={white}
              value={role}
              prompt="Select role"
              mainMargin="auto"
              options={accessLevels}
              onChange={handleAccessLevelChangeChange}
            />
          </Grid>

          <Grid item xs={12}>
            <HMEditor
              value={answer}
              onBlur={handleEditorChange}
              placeholder="Enter answer here..."
            />
          </Grid>
          <Grid item xs={12}>
            <HMButton
              text={<Typography sx={textStyle}>Add Faqs</Typography>}
              bgColor={green}
              type="submit"
              width="100%"
              float="right"
              margin="20px 0 5px 0"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
}

export default CreateFaq

import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Grid,
  Typography,
  TextareaAutosize
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AddIcon from '@mui/icons-material/Add'
import { 
  getInsurer,
  resetInsurer, 
  updateInsurer,
} from '../../../features/insurers/insurerSlice'
import { 
  getProducts, 
  resetProduct, 
} from '../../../features/products/productSlice'
import { 
  getInsurerWithProducts,
  resetRelationProductsInsurers
} from '../../../features/relationProductsInsurers/relationProductsInsurersSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMSelectMultiple from '../../../components/common/tools/HMSelectMultiple'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../../components/common/tools/HMRadioButton'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import Spinner from '../../../components/common/tools/Spinner'
import HMText from '../../../components/common/tools/HMText'
import {
  iconStyle,
  textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  insurerTypes,
  insurerOrigins,
} from '../../../hooks/helperFunctions'
import {
  blue,
  white,
  green,
  orange,
  lightBlue
} from '../../../hooks/useColors'
import { cloudinary } from '../../../hooks/helperFunctions'

function EditInsurer() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const productState = useSelector(state => state.product)
  const { products } = productState
  const {
    isOne,
    insurer,
    isError,
    message,
    isLoading,
    isUpdated,
  } = useSelector(state => state.insurer)
  const relationsState = useSelector(state => state.relationProductsInsurers)
  const {
    insurerProducts,
    isInsurerProducts
  } = relationsState
  const {isUploaded, isUploading, isUploadError, file} = useSelector((state) => state.cloudinary)

  const [selectedOptions, setSelectedOptions] = useState([])
  const [areExistingRelations, setAreExistingRelations] = useState(false)
  const [productsWithInsurancePrice, setProductsWithInsurancePrice] = useState([])

  const breadOptions = [
    { text: 'Insurers', link: '/admin/insurers' },
    { text: insurer['name'] && `Edit - ${insurer.name}`, link: null }
  ]

  const handleAdd = () => {
    navigate('/admin/insurers/create')
  }
  const handleView = () => {
    navigate(`/admin/insurers/${insurer._id}`)
  }

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false

  const getProductIds = (products) => {
    return products.map(product => product._id)
  } 

  const defaultFormData = (insurer) => {

    const checkData = insurer && Object.keys(insurer).length ? true : false

    return {
      name: checkData ? insurer.name : '',
      logo: checkData ? insurer.logo : '',
      type: checkData ? {name: insurer.type} : '',
      origin: checkData ? {name: insurer.origin} : '',
      description: checkData ? insurer.description : '',
      needsFingerPrints: checkData ? insurer.needsFingerPrints : false,
      insuringProducts: checkData && Object.keys(insurerProducts).length ? getProductIds(insurerProducts.products) : [],
    }
  }

  useEffect(() => {
    dispatch(getProducts())
    dispatch(getInsurerWithProducts(id))
  }, [dispatch, id])

  useEffect(() => {
    if (isError || isUpdated || isOne) {
      dispatch(resetInsurer())
    }

    if (productState.isSuccess) {
      dispatch(resetProduct())
    }

    if (isError) {
      toast.error(message) 
    } else if (isOne) {
      setFormData(defaultFormData(insurer))
    } else if (isUpdated) {
      toast.success('Insurer updated successfully')
      setFormData(defaultFormData(insurer))
      dispatch(getInsurerWithProducts(id))
    }

    if (!Object.keys(insurer).length || (insurer['_id'] && insurer._id !== id)) {
      dispatch(getInsurer(id))
    }  

    if (isInsurerProducts) {
      setFormData(defaultFormData(insurer))
      dispatch(resetRelationProductsInsurers())
    }

  }, [dispatch, insurer, productState, id, isError, isUpdated, isOne, message, isInsurerProducts])

  useEffect(() => {
    if (products.length) {
      const tempProductsWithInsurancePrice = []
      products.forEach((product) => {
        if (product['insurancePrice'] && product.published) {
          tempProductsWithInsurancePrice.push({
            id: product._id,
            name: product.name,
          })
        }
      })
      setProductsWithInsurancePrice(tempProductsWithInsurancePrice)
    }

    if (Object.keys(insurerProducts).length && insurerProducts.products.length) {
      setAreExistingRelations(true)
      setSelectedOptions(insurerProducts.products.map(product => ({
        id: product._id,
        name: product.name,
      })))
    } else {
      setAreExistingRelations(false)
    }
  }, [products, insurerProducts])

  const [formData, setFormData] = useState(defaultFormData(insurer))
  
  const { 
    name,
    type,
    logo,
    origin,
    description,
    insuringProducts,
    needsFingerPrints,
  } = formData

  const [insurerLogoUrl, setInsurerLogoUrl] = useState(logo)
  const [isFileUploading, setFileUploading] = useState(false)

  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      logo: insurerLogoUrl,
    }))
  },[insurerLogoUrl])

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  useEffect(()=>{
    if(insurerLogoUrl) setFileUploading(false)
  },[insurerLogoUrl])
  
  const handleChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
    }))
  }
  const handleAddInsuringProduct = (selectedList) => {
    const productIds = selectedList.map(product => product.id)
    setFormData((prevState) => ({
      ...prevState,
      insuringProducts: productIds
    }))
  }
  const handleRemoveInsuringProduct = (selectedList) => {
    const productIds = selectedList.map(product => product.id)
    setFormData((prevState) => ({
      ...prevState,
      insuringProducts: productIds
    }))
  }
  
  const handleTypeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      type: val
    }))
  }

  const handleOriginChange = (val) => {
    if (val && (val.name.toLowerCase() === 'international')) {
      setFormData((prevState) => ({
        ...prevState,
        origin: val,
        type: {name: 'Private'}
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        origin: val
      }))
    }
  }

  const fingerprint = {
    question: 'Requires fingerprint?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }

  const disabled = (
    name === '' || 
    isFileUploading || 
    description === '' || 
    (type.name && type.name === '') ||
    (type.origin && type.origin === '') 
  ) ? true : false

  const handleUpdate = (e) => {
    e.preventDefault()
    
    let addProductIds = []
    const removeProductIds = []

    if (areExistingRelations) {
      const db_productIdsObj = {}
      const curr_productIdsObj = {}

      for (let prodId of insuringProducts) {
        curr_productIdsObj[prodId] = 1 
      }

      for (let prodId of insurerProducts.products) {
        const strId = prodId._id.toString()
        
        db_productIdsObj[strId] = 1 

        if (!curr_productIdsObj[strId]) {
          removeProductIds.push(strId)
        }
      }
    
      for (let prodId of insuringProducts) {
        if (!db_productIdsObj[prodId]) {
          addProductIds.push(prodId)
        } 
      }
    } else {
      addProductIds = addProductIds.concat(insuringProducts)
    }

    const updatedInsurer = {
      name,
      logo,
      _id: id,
      description,
      type: type.name,
      origin: origin.name,
      needsFingerPrints: (needsFingerPrints === true || needsFingerPrints === 'true') ? true : false,
    }

    if (addProductIds.length) {
      updatedInsurer['addProductIds'] = addProductIds
    }

    if (removeProductIds.length) {
      updatedInsurer['removeProductIds'] = removeProductIds
    }

    dispatch(updateInsurer(updatedInsurer))
  }

  return (
    <Page>
      {isLoading || relationsState.isLoading ? (
        <HMSpiner 
          size={60}
          width='100%'
          zIndex={999}
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            handleClick={handleView}
            icon={<RemoveRedEyeIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>View</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleAdd}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Product</Typography>} 
          />
        </HMBox>
      </FullWidthContainer>
      {Object.keys(insurer).length ? (
        <Section
          bgColor={white}
          padding={10}
        >
          <Grid 
            component='form'
            container spacing={2} 
            onSubmit={handleUpdate}
          >
            <Grid item xs={12} sm={12}>
              <HMBox
                width='100%'
                display='flex'
                padding='10px'
                margin='30px 0 0 0'
                bgColor={lightBlue}
                flexDirection='column'
                maxWidth={`${isTabletScreen && '100% !important'}`}
              >
                <HMText 
                  left='0'
                  top='-35px'
                  height='30px'
                  bgColor={white}
                  fontWeight={500}
                  width='max-content'
                  className='fade-in'
                  position='relative'
                  text='Upload insurer logo'
                  margin='10px 0 -25px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMDropZone 
                 cloudinaryFileUrl={insurerLogoUrl}
                     setCloudinaryFileUrl = {setInsurerLogoUrl}
                     endPoint = {cloudinary.API_URL_LOGOS}
                      bgColor={white}
                      className='drag-drop' 
                      supportedFiles = {{
                        'image/jpeg': [],
                        'image/jpg': [],
                        'image/png': []
                      }}
                      allowedSizeInMB={1}
                />
              </HMBox>
            </Grid>
            <Grid item xs={12}>
              <HMTextField 
                margin='0'
                name='name'
                label='Name'
                value={name}
                required={true}
                onChange={handleChange}
                placeholder='Enter insurer name'
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{display : 'flex'}}>
              <HMRadioButton 
                padding='2px 5px'
                flexDirection='row'
                onChange={handleChange}
                name='needsFingerPrints'
                value={needsFingerPrints}
                options={fingerprint.choices}
                label={fingerprint.question}
                labelId='require-prescription-radio-group'
              />
            </Grid>
            <Grid item xs={12} sm={4} className='HM-dropdown'>
              <DropdownFilter 
                type='text'
                field='name'
                value={type}
                required={true}
                label='Insurer type'
                prompt='Select insurer type'
                onChange={handleTypeChange}
                options={
                  (origin.name && origin.name.toLowerCase() === 'international') ? [{name: 'Private'}]
                  : insurerTypes.map(type => ({name: type}))
                }
                disabled={(origin.name && origin.name.toLowerCase() === 'international') ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={4} className='HM-dropdown'>
              <DropdownFilter 
                type='text'
                field='name'
                value={origin}
                required={true}
                label='Insurer origin'
                onChange={handleOriginChange}
                prompt='Select insurer origin'
                options={insurerOrigins.map(type => ({name: type}))}
              />
            </Grid>
            <Grid item xs={12} sx={{marginBottom: '3%'}}>
              <HMSelectMultiple 
                placeholder='Select products'
                selectedValues={selectedOptions}
                handleAdd={handleAddInsuringProduct}
                options={productsWithInsurancePrice}
                handleRemove={handleRemoveInsuringProduct}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <HMBox
                margin='0'
                width='100%'
                display='flex'
                padding='10px'
                bgColor={lightBlue}
                flexDirection='column'
                maxWidth={`${isTabletScreen && '100% !important'}`}
              >
                <HMText 
                  left='0'
                  top='-35px'
                  width='135px'
                  height='30px'
                  bgColor={white}
                  fontWeight={500}
                  className='fade-in'
                  position='relative'
                  text='About Insurer'
                  margin='10px 0 -25px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <TextareaAutosize 
                  required={true}
                  name='description'
                  label='Description'
                  value={description}
                  onChange={handleChange}
                  aria-label='empty textarea'
                  placeholder='Additional information about the insurer'
                  style={{ 
                    width: '100%', 
                    border: '0px',
                    padding: '10px', 
                    maxWidth: '100%', 
                    margin: '10px auto',
                    borderRadius: '8px'
                  }}
                />
              </HMBox>
            </Grid>
            <Grid item xs={12}>
              <HMButton 
                text={<Typography sx={textStyle}>Update insurer</Typography>}
                bgColor={blue}
                type='submit'
                width='100%'
                float='right'
                margin='20px 0 5px 0'
                disabled={disabled}
              />
            </Grid> 
          </Grid>
        </Section>
      ) : <></>}
    </Page>
  )
}

export default EditInsurer
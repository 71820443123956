import React from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import HMBox from '../common/layout/HMBox'
import HMRadioButton from '../common/tools/HMRadioButton'
import {
  fontSize,
} from '../common/tools/Styles'
import {
    white,
} from '../../hooks/useColors'

function ChangeStatusForm(props) {
    const applicationStatus = props.applicationStatus

    const confirmReception = {
        question: 'Do you confirm to have received the application and currently processing it?',
        choices: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]
    }
    const confirmRejection = {
        question: 'Do you confirm to have processed the application and find it INVALID?',
        choices: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]
    }
    const confirmApproval = {
        question: 'Do you confirm to have thoroughly reviewed the application and find it VALID?',
        choices: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]
    }
    
    return (
        <HMBox
            width='100%'
            margin='10px auto auto auto'
        >
            {(applicationStatus === 'Submitted' || applicationStatus === 'Re-Applied') ? (
                <HMRadioButton 
                    margin='auto'
                    bgColor={white}
                    padding='2px 5px'
                    className='fade-in'
                    name='confirmReception'
                    radioMargin='10px auto'
                    fontSize={`${fontSize}px`}
                    onChange={props.handleChange}
                    label={confirmReception.question}
                    options={confirmReception.choices}
                    value={props.formData.confirmReception}
                />
            ) : applicationStatus === 'Received' ? (
                <>
                    <HMRadioButton 
                        margin='auto'
                        bgColor={white}
                        padding='2px 5px'
                        radioWidth='100%'
                        className='fade-in'
                        name='confirmApproval'
                        radioMargin='10px auto'
                        labelWidth='max-content'
                        fontSize={`${fontSize}px`}
                        onChange={props.handleChange}
                        label={confirmApproval.question}
                        options={confirmApproval.choices}
                        value={props.formData.confirmApproval}
                    />
                    {props.formData.confirmApproval === false ? (
                        <>
                            <HMRadioButton 
                                margin='auto'
                                bgColor={white}
                                padding='2px 5px'
                                className='fade-in'
                                name='confirmRejection'
                                radioMargin='10px auto'
                                fontSize={`${fontSize}px`}
                                onChange={props.handleChange}
                                label={confirmRejection.question}
                                options={confirmRejection.choices}
                                value={props.formData.confirmRejection}
                            />
                            {props.formData.confirmRejection ? (
                                <TextareaAutosize 
                                    className='fade-in'
                                    name='rejectionMessage'
                                    label='Rejection Message'
                                    aria-label='empty textarea'
                                    onChange={props.handleChange}
                                    value={props.formData.rejectionMessage}
                                    placeholder='Reason for the application rejection'
                                    style={{ width: '100%', maxWidth: '100%', padding: '10px', margin: '10px auto'}}
                                />
                            ) : <></>}
                        </> 
                    ) : <></>}
                </>
            ) : <></>}
        </HMBox>
    )
}

export default ChangeStatusForm
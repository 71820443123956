import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import LeaderboardTwoToneIcon from '@mui/icons-material/LeaderboardTwoTone'
// import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
// import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
// import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone'
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone'
// import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
// import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
// import PrivacyTipTwoToneIcon from '@mui/icons-material/PrivacyTipTwoTone'
// import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone'
// import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone'
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone'
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import SettingsOverscanTwoToneIcon from '@mui/icons-material/SettingsOverscanTwoTone'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
// import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone'
// import AddModeratorTwoToneIcon from '@mui/icons-material/AddModeratorTwoTone'
// import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone'
// import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import VaccinesTwoToneIcon from '@mui/icons-material/VaccinesTwoTone'
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone'
import { 
  resetSupplier,
  getMySuppliers,
} from '../../features/suppliers/supplierSlice'
import HMUserNavigation from '../../components/common/navigation/HMUserNavigation'
import SidebarItemLink from '../../components/common/navigation/SidebarItemLink'
import SidebarItem from '../../components/common/navigation/SidebarItem'
import HMAppBar from '../../components/common/navigation/HMAppBar'
import SideBar from '../../components/common/navigation/SideBar'
import HMSupplierCard from '../../components/common/layout/HMSupplierCard'
import Section from '../../components/common/layout/Section'
import HMNotification from '../../components/common/tools/HMNotification'
import HMMenuIcon from '../../components/common/tools/HMMenuIcon'
import HMSocials from '../../components/common/tools/HMSocials' 
import HMButton from '../../components/common/tools/HMButton'
import HMContainer from '../../components/common/layout/HMContainer'
import HMBox from '../../components/common/layout/HMBox'
import {
  fontSize,
  textStyle,
  iconStyle,
  appBarIcon,
  closeOpenStyle
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  supplierApplicationStatus
} from '../../hooks/helperFunctions'
import {
  blue,
  white,
  orange,
  lightBlack,
} from '../../hooks/useColors'

const stylingIcon = {
  ...iconStyle,
  color: blue,
  margin: 'auto 10px auto 0',
  fontSize: `${fontSize + 4}px`,
}

function Supplier() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    sidebarWidth
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const { 
    mySupplier,
    mySuppliers,
    isMySupplier,
    areMySuppliers,
  } = useSelector((state) => state.supplier)

  const {
    windowW,
    windowH
  } = useWindowDimensions()

  const [activeColor, setActiveColor] = useState(null)

  useEffect(() => {
    if (user) {
      dispatch(getMySuppliers())
    } else {
      navigate('/forbiden')
    }
  }, [dispatch, navigate, user])

  useEffect(() => {
    if (areMySuppliers) {
      dispatch(resetSupplier())
      if(!mySuppliers.length) {
        navigate('/supplier-application')
      }
    } else if (isMySupplier) {
      dispatch(resetSupplier())
    }
  }, [dispatch, navigate, areMySuppliers, isMySupplier, mySuppliers])


  const isTabletScreen = windowW < 768 ? true : false
  const isSideBarHeight = windowH < 350 ? true : false
  
  const width = isTabletScreen ? '100vw' : `${windowW - sidebarWidth - 12}px` 
  const marginLeft = isTabletScreen ? '0px' : `${sidebarWidth}px`

  const navigateUser = (e) => {
    navigate(`${mySupplier._id}/${e.target.name}`)
    if (activeColor) {
        activeColor.color = lightBlack
    }
    setActiveColor(e.target.style)
    e.target.style.color = orange
  }

  const handleSupplierClick = (supplier) => {
    const statsLn = supplierApplicationStatus.length
    const ln = supplier.applicationStack.length

    if (!ln || (ln && supplier.applicationStack[ln - 1].status === supplierApplicationStatus[statsLn -1])) {
      navigate(`${supplier._id}/dashboard`)
    } else {
      navigate(`application/${supplier._id}`)
    }
  }

  const handleApplySupplier = () => {
    navigate('application')
  }

  const handlePOS = () => { 
    
  }

  const path = window.location.pathname
  const isHide = path === '/supplier' || path.includes('application') ? true : false

  return user ? (
    <>
      <SideBar>
        {!isHide ? (
          <HMBox
            width='100%'
            padding={isTabletScreen ? '0 0 0 4.5%' : '0 0 0 10%'}
          >
            <SidebarItem 
              name='Home'
              textFontWeight={500}
              textFontSize={`${fontSize + 1}px`}
              navigateUser={() => { navigate('') }} 
              leftIcon={<CottageTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Dashboard'
              textFontWeight={500}
              navigateUser={navigateUser} 
              textFontSize={`${fontSize + 1}px`}
              leftIcon={<DashboardCustomizeTwoToneIcon sx={stylingIcon} />}
            />
            <SidebarItem 
              name='Analytics'
              textFontWeight={500}
              navigateUser={navigateUser} 
              textFontSize={`${fontSize + 1}px`}
              leftIcon={<LeaderboardTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Sales'
              textFontWeight={500}
              navigateUser={navigateUser} 
              textFontSize={`${fontSize + 1}px`}
              leftIcon={<ListAltTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Product' 
              textFontWeight={500}
              textFontSize={`${fontSize + 1}px`}
              leftIcon={<VaccinesTwoToneIcon sx={stylingIcon} />} 
              rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
            >
              <SidebarItemLink 
                page='listings' 
                text='Products List' 
                textFontWeight={500}
                navigateUser={navigateUser} 
                textFontSize={`${fontSize}px`}
              />
              <SidebarItemLink 
                text='Add Product' 
                textFontWeight={500}
                page='listings/create' 
                navigateUser={navigateUser} 
                textFontSize={`${fontSize}px`}
              />
            </SidebarItem>
          </HMBox>
        ) : <></>}
        <HMSocials 
          bottom={!isSideBarHeight && 0}
          position={!isSideBarHeight && 'absolute'}
        />
      </SideBar>
      <HMContainer
        width={width}
        className='admin'
        marginLeft={marginLeft}
      >
        <HMAppBar>
          <HMMenuIcon />
          <HMBox
            padding='0'
            display='flex'
            margin='auto 0 auto auto'
          >
            <HMButton 
              color={white}
              bgColor={blue}
              margin={isTabletScreen ? 'auto 5px' : 'auto 10px'}
              padding='2px 5px'
              handleClick={handlePOS}
              paddingActive='1.5px 4.5px'
              text={<Typography sx={textStyle}>POS</Typography>} 
              icon={<ShoppingBasketOutlinedIcon sx={{
                ...iconStyle, 
                fontSize: `${fontSize + 2}px`,
                '&:active' : {
                  fontSize: `${fontSize + 1}px`,
                }
              }} />} 
            />
            <WbSunnyTwoToneIcon sx={appBarIcon} />
            <SettingsOverscanTwoToneIcon sx={appBarIcon} />
            <AccountBalanceWalletTwoToneIcon sx={appBarIcon} />
            <HMNotification />
            <HMUserNavigation />
          </HMBox>
        </HMAppBar>
        {window.location.pathname !== '/supplier' ? (
          <Outlet />
        ) : (
          <Section 
            padding={10} 
            height='85%'
            bgColor={white}
          >
            <Grid 
              container 
              spacing={2}
            >
              {mySuppliers.map((supplier, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <HMSupplierCard
                    key={index}
                    supplier={supplier}
                    hideHeart={true}
                    showAddress={true}
                    handleClick={() => handleSupplierClick(supplier)}
                  />
                </Grid>
              ))}
              <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex' }}>
                <HMButton 
                  width='100%'
                  color={blue}
                  type='button'
                  padding='10px'
                  bgColor='inherit'
                  borderRadius={10}
                  className='fade-in'
                  paddingActive='10px'
                  height='match-content'
                  border={`1px solid ${blue}`}
                  handleClick={handleApplySupplier}
                  margin={isTabletScreen ? '5px auto' : '0 0 0 5px'}
                  icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                  text={
                    <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                      Apply for a new supplier
                    </Typography>
                  } 
                />
              </Grid>
            </Grid>
        </Section>
        )}      
      </HMContainer>
    </>
  ) : <></>
}

export default Supplier
import CircleIcon from '@mui/icons-material/Circle'
import HMBox from '../common/layout/HMBox'
import HMText from '../common/tools/HMText'
import {
    fontSize
} from '../common/tools/Styles'

function DotAndLabel(props) {
    return ( 
        <HMBox
            padding='0'
            display='flex'
            width='max-content'
            margin={props.margin || 'auto 0'}
        >
            <CircleIcon 
                sx={{ 
                    color: props.dotColor, 
                    width: `${props.dotSize || 10}px`, 
                    margin: props.dotMargin || '-2px 2.5px auto auto', 
                }} 
            />
            <HMText 
                text={props.title} 
                color={props.color}
                margin='auto auto auto 2.5px'
                fontWeight={props.fontWeight || 500}
                fontSize={`${props.fontSize || fontSize}px`}
            />
        </HMBox>
     )
}

export default DotAndLabel
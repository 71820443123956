import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {
    white,
    orange,
} from '../../../hooks/useColors'


function HMScrollTop() {
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        if (window.scrollY > 300) {
            setVisible(true)
        } else if (window.scrollY <= 300) {
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    window.addEventListener('scroll', toggleVisible)

    return (
        <IconButton
            onClick={scrollToTop} 
            className='HM-scroll-top fade-in'
            sx={{ 
                zIndex: 1, 
                left: 2000, 
                bottom: 100, 
                padding: '3px',
                position: 'sticky', 
                display: visible ? 'flex' : 'none' 
            }}
        >
            <ArrowUpwardIcon
                sx={{
                    p: '5px',
                    color: white,
                    margin: 'auto',
                    background: orange,
                    borderRadius: '50%',
                    width: '30px', height: '30px',
                }}
            />
        </IconButton>
    )
}

export default HMScrollTop
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Box from '@mui/material/Box'

function ProductCardSkeleton() {
  return (
    <Box
        sx={{
            // width: '100%',
            margin: 'auto',
            diplay: 'flex',
            minWidth: '150px',
            maxWidth: '200px',
            flexDirection: 'column',
        }}
    >
        <Box 
            sx={{
                width: '100%',
                margin: 'auto'
            }}
        >
            <Skeleton height='135px' width={100} />
        </Box>
        <Skeleton height='20px' width={40} />
        <Skeleton height='20px' width={100} />
    </Box>
  )
}

export default ProductCardSkeleton
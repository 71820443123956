import React from 'react'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import HMPaymentOption from './HMPaymentOption'
import HMBox from './HMBox'
import HMSwitchButton from '../tools/HMSwitchButton'
import HMButton from '../tools/HMButton'
import HMAlert from '../tools/HMAlert'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize,
    textStyle,
} from '../tools/Styles'
import {
    currencies,
    storeDelivery,
} from '../../../hooks/helperFunctions'
import {
    blue,
    white,
    orange,
    darkBlue,
    lightRed,
    strongRed,
    lightBlue,
    lightGray,
    lightGreen,
    lightBlack,
    lightOrange,
} from '../../../hooks/useColors'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMInsuranceConfirmation(props) {

    const {
        store,
        storeName,
        storeType,
    } = props.orderDetail

    const {
        insurer,
        storeValidation
    } = props.insuranceInfo

    const {
        coverage,
    } = storeValidation[store]

    const {
        windowW,
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    const isInsValid = props.insuranceInfo.storeValidation[store].state.toLowerCase() === 'valid'

    const leftText = `${storeDelivery[0].type} delivery : ${currency} ${storeDelivery[0].cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    const rightText = `${storeDelivery[1].type} delivery : ${currency}  ${storeDelivery[1].cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    
    return (
        <HMBox
            width='100%'
            display='flex'
            color={darkBlue}
            className='fade-in'
            margin={props.margin}
            flexDirection='column'
        >
            <HMSwitchButton 
                width={350}
                btnBgColor={blue}
                bgColor={lightGray}
                leftText={leftText}
                rightText={rightText}
                btnColor={lightBlack}
                btnActiveColor={white}
                margin='20px auto 0 auto'
                leftClick={props.handleInsuranceNormalDelivery}
                rightClick={props.handleInsuranceUrgentDelivery}
                isLeft={props.insuranceDeliveryType === storeDelivery[0].type}
            />
            <HMBox
                padding='0'
                display='flex'
                margin='10px auto'        
            >
                <AccessTimeTwoToneIcon sx={{fontSize: `${fontSize + 6}px` , margin: 'auto 5px auto auto'}} />
                <HMText
                    color={blue}
                    fontWeight={500}
                    margin='auto auto auto 0'
                    fontSize={`${fontSize - 2}px`} 
                    text={storeDelivery.find(data => data.type === props.insuranceDeliveryType).duration}
                />
            </HMBox>
            <Divider width='90%' sx={{ borderBottom: `2px solid ${lightGray}`, margin: '10px auto 20px auto' }} />
            <HMBox
                width='100%'
                display='flex'
            >
                <Link
                    onClick={props.navegateToStorePage}
                    sx={{ margin: 'auto 0', cursor: 'pointer' }}
                >
                    <HMText
                        color={blue}
                        margin='auto 0'
                        text={storeName}
                        fontSize={`${fontSize - 1}px`}
                    />
                </Link>
                <HMText
                    padding='0 10px'
                    text={storeType}
                    bgColor={lightBlue}
                    margin='auto 10px auto 5px'
                    fontSize={`${fontSize - 1}px`}
                />
            </HMBox>
            {!isInsValid ? (
                <HMAlert 
                    error={true}
                    bgColor={lightRed}
                    text={
                        <>
                            <Typography 
                                component='span'
                                sx={{ fontSize: 'inherit' }}
                            >
                                The store found your insurance invalid. Reason:
                            </Typography>
                            <Typography
                                component='span'
                                sx={{ 
                                    fontWeight: 500, 
                                    marginLeft: '5px', 
                                    fontSize: 'inherit',
                                }}
                            >
                                {props.insuranceInfo.storeValidation[store].reason}
                            </Typography>
                        </>
                    }
                />
            ) : <></>}
            <HMBox
                width='100%'
                padding='5px'
                display='flex'
                margin='10px 0'
                className='fade-in'
                border={`1px solid ${lightBlue}`}
            >
                <HMText 
                    text='Insurance Coverage'
                    margin='auto 10px auto 0'
                    fontSize={`${fontSize}px`}
                />
                <Title 
                    color={blue}
                    padding='1px 5px'
                    borderRadius='5px'
                    size={fontSize - 2}
                    bgColor={lightBlue}
                    title={`${coverage} %`}
                    margin='auto 0 auto auto'
                />
            </HMBox> 
            <HMBox 
                width='100%'
                display='flex'
                padding='5px 0'
                margin='0 0 10px 0'
                className='fade-in'
                flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
            >
                <HMBox 
                    width='100%'
                    height='100%'
                    display='flex'
                    className='fade-in'
                    flexDirection='row'
                    border={`1px solid ${lightBlue}`}
                    margin={`${isTabletScreen ? '5px 0' : 'auto 2.5px auto auto'}`}
                >
                    <HMText 
                        margin='auto 0'
                        text='You will pay :'
                    />
                    <Title 
                        padding='1px 5px'
                        borderRadius='5px'
                        size={fontSize + 2}
                        margin='auto 0 auto auto'
                        color={!isInsValid ? orange : blue}
                        bgColor={!isInsValid ? lightOrange : lightBlue}
                        title={`${currency} ${props.customerPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                {isInsValid ? (
                    <HMBox 
                        width='100%'
                        height='100%'
                        display='flex'
                        className='fade-in'
                        flexDirection='row'
                        border={`1px solid ${lightBlue}`}
                        margin={`${isTabletScreen ? '5px 0' : 'auto auto auto 2.5px'}`}
                    >
                        <HMText 
                            margin='auto 0'
                            text={`${insurer.name} pays :`}
                        />
                        <Title 
                            color={orange}
                            padding='1px 5px'
                            borderRadius='5px'
                            size={fontSize - 2}
                            bgColor={lightOrange}
                            margin='auto 0 auto auto'
                            title={`${currency} ${props.insurancePayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    </HMBox>
                ) : <></>}
            </HMBox>
            <HMPaymentOption 
                isEdit={true}
                margin='20px 0 0 0'
                bgColor={lightGreen}
                hoverBgColor={lightGreen}
                paymentInfo={props.confirmedCopaymentInfo}
                handleChangePaymentOption={props.handleChangePaymentOption}
                changeText={!isInsValid ? 'Change payment method' : 'Change copayment method'}
            />
            <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='20px auto'        
                flexDirection='column'
            >
                {!isInsValid ? (
                    <HMButton 
                        width='100%'
                        type='button'
                        margin='auto'
                        padding='7.5px'
                        bgColor={white}
                        color={strongRed}
                        hoverColor={white}
                        paddingActive='7px'
                        hoverBgColor={strongRed}
                        disabled={props.isDisabled}
                        border={`1px solid ${strongRed}`}
                        handleClick={props.handleCancelOrder}
                        text={<Typography sx={{...textStyle, fontWeight: 500}}>Cancel this order</Typography>} 
                    />
                ) : <></>}
                <HMButton 
                    width='100%'
                    type='button'
                    padding='7.5px'
                    bgColor={orange}
                    paddingActive='7px'
                    disabled={props.isDisabled}
                    margin='15px auto auto auto'
                    handleClick={props.handleProceed}
                    text={
                        <Typography sx={{...textStyle, fontWeight: 500}}>
                            {!isInsValid ? 'Proceed with this order' : props.actionMessage}
                        </Typography>
                    } 
                />
            </HMBox>  
        </HMBox>
    )
}

export default HMInsuranceConfirmation
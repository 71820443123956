import { 
    red,
    blue, 
    white,
    green,
    orange,
    lightRed,
    lightBlue,
    lightBlack
} from '../../../hooks/useColors'

export const fontSize = 14

export const iconStyle = {
    color: 'inherit',
    margin: 'auto 2px',
    fontSize: `${fontSize}px`,
}
export const renoveIconStyle = {
    color: red, 
    margin: 'auto',
    fontSize: `${fontSize + 2}px !important`
}
export const addIconStyle = {
    color: blue, 
    margin: 'auto',
    fontSize: `${fontSize + 2}px !important`
}
export const appBarIcon = {
    color: blue,
    cursor: 'pointer',
    fontSize: 'medium',
    margin: 'auto 1.2vw',
    '&:hover': {
        opacity: 0.8
    }
}
export const textStyle = {
    margin: '2px auto',
    fontSize: `${fontSize}px`,
}
export const deleteTextStyle = {
    ...textStyle,
    marginTop: '20px',
    textAlign: 'center',
    fontSize: `${fontSize}px`,
}
export const deleteItemTextStyle = {
    ...deleteTextStyle,
    color: blue,
    marginTop: '5px',
    borderRadius: '5px',
    marginBottom: '20px',
    backgroundColor: lightBlue,
    padding: '2px 10px !important',
}
export const errorStyle = {
    ...deleteTextStyle,
    color: red,
    width: '100%',
    marginTop: '5px', 
    borderRadius: '5px',
    backgroundColor: lightRed,
}
export const tableStyle = {
    width: '100%',
    height: 'auto',
    fontSize: `${fontSize}px`,
    color: lightBlack
}
export const headerStyle = {
    textAlign: 'left',
    padding: '12px 5px',
    margin: 'auto',
}
export const tbodyStyle = {
}
export const trStyle = {
    padding: '8px 5px',
}
export const loadMoreStyle = {
    fontSize: `${fontSize - 2}px`,
    backgroundColor: lightBlue,
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '3px 5px',
    color: blue,
    margin: 'auto',
    '&:hover': {
        opacity: 0.8
    },
    '&:active': {
        fontSize: `${fontSize - 2.2}px` 
    }
}
export const iconsContainerStyle = {
    display: 'flex'
}
export const linkStyle = {
    cursor: 'pointer',
    color: blue,
    textDecoration: 'none',
}
export const paperStyle = {
    margin: '15px 0',
    height: 40,
    padding: '5px',
    display: 'flex',
    flexWrap: 'wrap'
}
export const gridStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 0
}
export const notificationSytle = {
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer',
    marginRight: '1.2vw',
}
export const bellStyle = {
    fontSize: 'medium',
    color: blue,
    margin: 'auto 0 auto 5px',
    zIndex: 1,
    '&:hover': {
        opacity: 0.8
    }
}
export const circleStyle = {
    backgroundColor: red,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 0 auto -9px',
    borderRadius: '50%',
    zIndex: 999,
    '&:hover': {
        backgroundColor: '#f9558e'
    }
}
export const countStyle = {
    margin: '1.5px 2.5px',
    fontSize: '9px',
    color: white,
    opacity: 'inherit'
}
export const userStyle = {
    ...notificationSytle,
    marginRight: 0,
    borderRadius: '50%',
}
export const imageStyle = {
    margin: 'auto',
    width: 'inherit',
    height:'inherit',
    borderRadius: '50%',
    border: `2px solid ${green}`
}
export const dialGridStyle = {
    zIndex: 999,
    right: '10px',
    borderRadius: '5px',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
} 
export const dialIconStyle = {
    margin: 'auto',
    display: 'inline-block',
    fontSize: `${fontSize}px`,
    color: blue,
}
export const containerStyle = {
    margin: 'auto',
    height: '100vh',
    maxWidth: '100vw !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
}
export const closeOpenStyle = {
    color: 'inherit',
    fontSize: 'inherit',
    margin: 'auto 0 auto auto',
    transform: 'rotate(90deg)',
}
export const childLinkStyle = {
    display: 'block',
    color: lightBlack,
    fontSize: `${fontSize}px`,
    padding: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        color: `${orange} !important`,
        marginLeft: '2px',
    },
    '&:active': {
        fontSize: `${fontSize - 0.2}px`,
        marginRight: '0.2px',
    }
}
export const linksHodlerStyle = {
    backgroundColor: lightBlue,
    padding: '10px',
    borderRadius: '5px',
}
export const dialItemStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    width: '120px',
}
export const dialTextContainerStyle = {
    margin: 'auto 0 auto auto',
    background: 'rgba(255,255,255,0.5)',
}
export const dialIconContainerStyle = {
    zIndex: 9999,
    borderRadius: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '6px',
    cursor: 'pointer',
    border: `1.5px solid ${white}`,
    marginLeft: 'auto',
    marginRight: 0,
    '&:hover': {
        borderColor: blue,
    },
    '&:active': {
        borderWidth: '1px',
        marginTop: '0.5px',
        marginBottom: '0.5px',
        marginRight: '0.5px',
    }
}
export const searcFieldStyle = { 
    width: '100%',
    margin: 'auto',
    color: lightBlack,
    fontSize: `${fontSize}px`
}
export const searchButtonStyle = { 
    padding: '3px',
    marginRight: '5px'
}
export const searchIconStyle = {
    color: blue,
    fontSize: '15px'
}
export const cancelStyle = {
    color: white,
    margin: 'auto', 
    fontSize: '5px',
    borderRadius: '50%',
    backgroundColor: red, 
    padding: '1.5px 0 0 0',
    '&:hover': {
        opacity: 0.8,
    },
    '&:active': {
        padding: '1.7px 0.2px 0.2px 0.2px',
    }
}
export const registerPaperStyle = {
    padding: 20, 
    width: 280,
    margin: 'auto',
    borderRadius: '8px'
}
export const fieldsStyle = {
    padding: '10% 0 0 0'
}
export const fieldStyle = {
    margin: '3% 0'
}
export const eyeStyle = {
    fontSize: '1rem',
}
export const closeStyle = {
    margin: 'auto',
    marginLeft: '7px',
    cursor: 'pointer',
    fontSize: `${fontSize}px`,
    '&:hover': {
        opacity: 0.8
    },
    '&:active': {
        fontSize: `${fontSize - 1}px`,
    }
}
export const mainContainer = {
    left: 0,
    zIndex: 999,
    padding: '10px',
    overflow: 'auto',
    position: 'fixed',
    backgroundColor: white,
    display: 'inline-block',
}
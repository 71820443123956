import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import moment from 'moment'
import { 
  getUser,
  resetUser, 
  updateUser,
  deleteUser,
} from '../../../features/users/userSlice'
import AddressDetails from '../../../components/checkout/AddressDetails'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../../components/common/layout/HMLoginFirst'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMPasswordGenerator from '../../../components/common/tools/HMPasswordGenerator'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMImage from '../../../components/common/tools/HMImage'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle,
} from '../../../components/common/tools/Styles'
import { 
  isInTitle,
  generatePassword,
  stringifyPhoneNumber, 
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  red,
  gray,
  blue,
  white,
  green,
  orange,
  darkBlue,
  lightBlue,
  lightBlack,
  lightOrange,
} from '../../../hooks/useColors'

function User() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { user } = useSelector(state => state.auth)
  const { visible } = useSelector(state => state.nav)
  const userState = useSelector(state => state.user)
  const { 
    isOne,
    isError,
    message,
    isUpdated,
    isDeleted,
    isLoading,
  } = userState

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isRemove, setIsRemove] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [activeState, setActiveState] = useState(false)
  const [isPasswordChange, setIsPasswordChange] = useState(false)
  
  const [formData, setFormData] = useState({
    password: '',
    hasNumber: false,
    hasSymbols: false,
    passwordLength: 5,
    hasUpperCase: false,
    hasLowerCase: false,
  })

  const handleGeneratePassword = () => {
    const { 
      hasNumber,
      hasSymbols,
      hasUpperCase,
      hasLowerCase,
      passwordLength,
    } = formData

    setFormData((prevState) => ({
      ...prevState,
      password: generatePassword(hasNumber, hasSymbols, hasUpperCase, hasLowerCase, passwordLength)
    }))
  }

  const {
    windowW
  } = useWindowDimensions()

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false

  useEffect(() => {
    if (!Object.keys(currentUser).length || (currentUser['_id'] && currentUser._id !== id)) {
      dispatch(getUser(id))
    } 
    
    if (Object.keys(currentUser).length) {
      setActiveState(currentUser.status.toLowerCase() === 'active' ? true : false)
    }
    // eslint-disable-next-line 
  }, [currentUser, id])

  useEffect(() => {
    if (isError) {
      setCurrentUser({})
      toast.error(message)
      dispatch(resetUser())
    } else if (isOne) {
      dispatch(resetUser())
      setCurrentUser(userState.user) 
    } else if (isDeleted) {
      dispatch(resetUser())
      toast.success(message)
      navigate('/admin/users')
    } else if (isUpdated) {
      dispatch(resetUser())
      setCurrentUser(userState.user)
      if (isInTitle(title, 'delete', 'activate')) {
        toast.success(`User status is successfully changed to ${userState.user.status}`)
      } else if (isInTitle(title, 'password')) {
        toast.success('User password is successfully updated!')
      }
    } 
    // eslint-disable-next-line 
  }, [isOne, isError, isUpdated, isDeleted])

  const breadOptions = [
    { text: 'Users', link: '/admin/users' },
    { text: currentUser['firstName'] && `${currentUser.firstName} ${currentUser.lastName}`, link: null }
  ]

  const handleEdit = () => {
    navigate(`/admin/users/edit/${currentUser._id}`)
  }
  const handleAdd = () => {
    navigate('/admin/users/create')
  }

  const handlePasswordChangeModal = () => {
    setOpen(true)
    setIsPasswordChange(true)
    setTitle('Change Password') 
  }
  const handleFieldsChange = (e) => {
    const id = e.target.id
    let value = null
    if (id === 'passwordLength') {
      value = (
        parseInt(e.target.value) >= 5 && parseInt(e.target.value) <= 15
        ) ? e.target.value : formData.passwordLength
    } else if (id === 'password') {
      value = e.target.value
    } else {
      value = e.target.checked
    }
    
    if (value !== null) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: value
      }))
    }
  }
  const handlePasswordSubmit = (e) => {
    e.preventDefault()

    const updatedUser = {
      _id: currentUser._id,
      password: formData.password
    }
    dispatch(updateUser(updatedUser))
    setOpen(false)
  }

  const handleActiveStateModal = () => {
    setOpen(true)
    setActiveState(!activeState)
    setTitle(`${currentUser.status.toLowerCase() !== 'active' ? 'Activate' : 'Delete'} User`)
  }
  const handleActiveState = () => {
    const updatedUser = {
      _id: currentUser._id,
      status: currentUser.status === 'active' ? 'deleted' : 'active'
    }
    dispatch(updateUser(updatedUser))
    setOpen(false)
  }

  const handleRemoveModal = () => {
    setOpen(true)
    setIsRemove(true)
    setTitle('Remove User')
  }
  const handleRemove = () => {
    dispatch(deleteUser(currentUser._id))
    handleClose()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsRemove(false)
    setActiveState(currentUser.status.toLowerCase() === 'active' ? true : false)
  }

  return user ? (
    <Page>
      {isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
        flexDirection={isTabletScreen ? 'column' : 'row'}
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
          padding={isTabletScreen && '10px 0 0 10px'}
        />
        <HMBox 
          float='right'
          display='flex'
          alignSelf='flex-start'
          padding={isTabletScreen && '10px 0'}
          flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
        >
          <HMToogleButton 
            status={activeState}
            handleChange={handleActiveStateModal}
            text={activeState ? 'Active' : 'Deleted'}
          />
          <HMButton 
            type='button'
            bgColor={blue}
            isResponsive={true}
            handleClick={handleEdit}
            icon={<EditIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Edit</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleAdd}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add user</Typography>} 
          />
          {currentUser.status === 'deleted' ? (
            <HMButton 
              type='button'
              bgColor={red}
              isResponsive={true}
              handleClick={handleRemoveModal}
              icon={<DeleteIcon sx={iconStyle} />} 
              text={<Typography sx={textStyle}>Remove</Typography>} 
            />
          ) : <></>}
        </HMBox>
      </FullWidthContainer>
      <HMModal
        open={open} 
        title={title}
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isRemove ? (
          <>
            <Typography sx={deleteTextStyle}>
              Are you sure your want to remove: 
            </Typography>
            <Typography sx={deleteItemTextStyle}>
              {currentUser.firstName + ' ' + currentUser.lastName} ?
            </Typography>
          </>
        ) : isInTitle(title, 'activate', 'delete') ? (
          <>
            <Typography sx={deleteTextStyle}>
              Are you sure your want to {activeState ? 'delete' : 'activate'}: 
            </Typography>
            <Typography sx={deleteItemTextStyle}>
              {currentUser.firstName + ' ' + currentUser.lastName} ?
            </Typography>
          </>
        ) : isPasswordChange ? (
          <Box 
            component='form' 
            sx={{margin: '10px 0'}}
            onSubmit={handlePasswordSubmit}
          >
            <HMPasswordGenerator 
              password={formData.password}
              hasNumber={formData.hasNumber}
              hasSymbols={formData.hasSymbols}
              hasUpperCase={formData.hasUpperCase}
              hasLowerCase={formData.hasLowerCase}
              handleFieldsChange={handleFieldsChange}
              passwordLength={formData.passwordLength}
              handleGeneratePassword={handleGeneratePassword}
            />
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='20px 0 10px 0'
            >
              <HMButton 
                type='button'
                bgColor={gray}
                handleClick={handleClose}
                margin='auto auto auto 0'
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='submit'
                bgColor={blue}
                margin='auto 0 auto auto'
                text={<Typography sx={textStyle}>Submit</Typography>}
                disabled={(formData.password.length < 5 || formData.password.length > 15) ? true : false}
              />
            </HMBox>
          </Box>
        ) : <></>}
        {(isRemove || isInTitle(title, 'activate', 'delete')) ? (
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            margin='10px 0'
          > 
            <HMButton 
              type='button'
              bgColor={gray}
              handleClick={handleClose}
              margin='auto auto auto 0'
              text={<Typography sx={textStyle}>Cancel</Typography>}
            />
            <HMButton 
              type='button'
              margin='auto 0 auto auto'
              bgColor={
                isRemove || (
                  isInTitle(title, 'activate', 'delete') && 
                  currentUser.status.toLowerCase() === 'active'
                ) ? red : green
              }
              handleClick={
                isRemove ? handleRemove 
                : isInTitle(title, 'activate', 'delete') && handleActiveState
              }
              text={<Typography sx={textStyle}>Yes, {
                isRemove ? 'Remove' 
                : (isInTitle(title, 'activate', 'delete') && currentUser.status.toLowerCase() === 'active') ? 'Delete' 
                : (isInTitle(title, 'activate', 'delete') && currentUser.status.toLowerCase() !== 'active') && 'Activate'
              }!</Typography>}
            />
          </HMBox>
        ) : <></>}
      </HMModal>
      {Object.keys(currentUser).length ? (
        <>
          <Section
            padding={10}
            bgColor={white}
          >
            <Grid
              container spacing={0} 
            >
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMBox
                  padding='0'
                  display='flex'
                  height='20vh'
                  minHeight='110px'
                  margin='auto auto auto 0'
                >
                  {currentUser['image'] ? (
                    <HMImage
                      width='20vh'
                      height='20vh'
                      margin='auto 0'
                      borderRadius='50%'
                      src={currentUser.image}
                      alt={currentUser.firstName}
                      border={`4px solid ${lightBlue}`}
                    />
                  ) : (
                    <HMBox 
                      padding='0'
                      display='flex'
                      margin='auto 0'
                      borderRadius='50%'
                      bgColor={lightBlue}
                    >
                      <AccountCircleIcon sx={{ width: '20vh', height: '20vh', borderRadius: '50%' }} />
                    </HMBox>
                  )}
                  <HMBox
                    width='100%'
                    display='flex'
                    margin='auto 0'
                    height='max-content'
                    bgColor={lightBlue}
                    border={`4px solid ${lightBlue}`}
                  >
                    <Grid
                    container spacing={1} 
                    sx={{margin: 'auto'}}
                    >
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          color={darkBlue}
                          fontWeight={500}
                          text={`${currentUser.firstName} ${currentUser.lastName}`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          bgColor={white}
                          padding='0 3px'
                          borderRadius={5}
                          fontWeight={500}
                          color={darkBlue}
                          text={currentUser.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <HMText 
                          fontWeight={500}
                          color={lightBlack}
                          text={stringifyPhoneNumber(currentUser.phone)}
                        />
                      </Grid>
                    </Grid>
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid 
                item 
                xs={12} 
                sx={{ padding: '10px', display: 'flex' }}
                sm={
                  isSmallTabletScreen ? 12 
                  : isTabletScreen ? 12  
                  : (isSamllComputerScreen && visible) ? 12
                  : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                  : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                  : 6
                }
              >
                <HMBox 
                  width='98%'
                  display='flex'
                  bgColor={lightBlue}
                  flexDirection='column'
                  height={isTabletScreen ? '210px' : '20vh'}
                  margin={isTabletScreen ? 'auto' : 'auto auto auto 0'}
                >
                  <Grid container spacing={1} sx={{margin: 'auto', paddingRight: '10px'}}>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        margin='5px 0'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                      >
                        <HMText 
                          text='Gender'
                          margin='auto 0'
                        />
                        <Title 
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          margin='auto 0 auto auto'
                          color={!currentUser.gender ? orange : blue}
                          bgColor={!currentUser.gender ? lightOrange : lightBlue}
                          title={currentUser.gender ? currentUser.gender : 'not set'}
                        />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        margin='5px 0'
                        bgColor={white}
                        flexDirection='row'
                      >
                        <HMText 
                          margin='auto 0'
                          text='Birthday'
                        />
                        <Title 
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          margin='auto 0 auto auto'
                          color={!currentUser.birthdate ? orange : blue}
                          bgColor={!currentUser.birthdate ? lightOrange : lightBlue}
                          title={currentUser.birthdate ? moment(currentUser.birthdate).utc().format('MMM DD, YYYY') : 'not set'}
                        />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        bgColor={white}
                        flexDirection='row'
                        margin='5px 0'
                      >
                        <HMText 
                          margin='auto 0'
                          text='Access Level'
                        />
                        <Title 
                          color={blue}
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          bgColor={lightBlue}
                          margin='auto 0 auto auto'
                          title={currentUser.accessLevel}
                        />
                      </HMBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <HMBox 
                        width='100%'
                        height='auto'
                        display='flex'
                        margin='5px 0'
                        flexDirection='row'
                      >
                        <HMButton 
                          type='button'
                          padding='0 5px'
                          bgColor={blue}
                          paddingActive='0 4.5px'
                          margin='auto 0 auto auto'
                          icon={<EditIcon sx={iconStyle} />} 
                          handleClick={handlePasswordChangeModal}
                          text={<Typography sx={textStyle}>Reset password</Typography>} 
                        />
                      </HMBox>
                    </Grid>
                  </Grid>
                </HMBox>  
              </Grid>
            </Grid>
          </Section>

          <Section
            padding={10}
            display='flex'
            bgColor={white}
            flexDirection='column'
          >
            <HMText 
              left='0'
              top='-35px'
              height='30px'
              fontWeight={500}
              width='max-content'
              className='fade-in'
              position='relative'
              bgColor={lightBlue}
              margin='10px 0 -25px 20px'
              padding='5px 10px 0px 10px'
              border={`1px solid ${white}`}
              text='Delivery Address Information'
            />
            <Grid
              container spacing={0} 
            >
              {currentUser.deliveryInfo.map((info, index) => (
                <Grid 
                  item 
                  xs={12} 
                  key={index}
                  sx={{ padding: '10px', display: 'flex' }}
                  sm={
                    isSmallTabletScreen ? 12 
                    : isTabletScreen ? 12  
                    : (isSamllComputerScreen && visible) ? 12
                    : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 6
                    : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 6 
                    : 6
                  }
                >
                  <AddressDetails 
                    width='100%'
                    margin='auto'
                    isColumn={true} 
                    addressInfo={info}
                    bgColor={lightBlue}
                    isDefault={info.default}
                    desableChnageAddress={true}
                    isTabletScreen={isTabletScreen}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </>
      ) : <></>}
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing this page'
      />
    </Section>
  )
}

export default User
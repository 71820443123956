import React from 'react'
import { 
    Box,
    Radio,
    FormLabel,
    FormControl,
    RadioGroup,
    FormControlLabel
} from '@mui/material'
import { 
    lightBlack,
    lightBlue
} from '../../../hooks/useColors'


function HMRadioButton(props) {
    const boxStyle = {
        width: '100%',
        margin: props.margin || 'auto',
        padding: props.padding || '10px',
        borderRadius: props.borderRadius || '8px',
        backgroundColor: props.bgColor || lightBlue,
    }
    const formControlStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: props.flexDirection || 'column',

    }
    const labelStyle = {
        color: lightBlack,
        fontSize: props.fontSize,
        width: props.lableWidth || '100%',
        margin: props.lableMargin || 'auto !important',  
    }
    const radioGroupStyle = {
        margin: props.radioMargin,  
        width: props.radioWidth || '100%',
        flexDirection: props.radioflexDirection,
        justifyContent: props.flexDirection === 'row' && 'flex-end', 
        flexBasis: props.radioflexDirection === 'column' && 'content',
    }
    
    return (
        <Box sx={boxStyle} className={`HM-radio-buttons ${props.className}`}>
            <FormControl
                sx={formControlStyle}
            >
                <FormLabel id={props.labelId} sx={labelStyle}>
                    {props.label}
                </FormLabel>
                <RadioGroup 
                    row
                    name={props.name}
                    value={props.value}
                    sx={radioGroupStyle}
                    onChange={props.onChange}
                    aria-labelledby={props.labelId}
                >
                    {props.options.map((option, index) => (
                        <FormControlLabel 
                            key={index}
                            label={option.label} 
                            value={option.value} 
                            control={<Radio size='small' sx={{color: 'red'}} />} 
                            className={`HM-radio-button ${
                                (
                                    option.value === true || 
                                    option.value === 'true' || 
                                    (typeof(option.value) === 'string' && option.value.toLowerCase() === 'valid')
                                ) 
                                ? 'positive' 
                                : (
                                    option.value === false || 
                                    option.value === 'false' || 
                                    (typeof(option.value) === 'string' && option.value.toLowerCase() === 'invalid')
                                ) 
                                ? 'negative' 
                                : 'positive'
                            }`}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default HMRadioButton
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { 
  useSelector, 
  useDispatch 
} from 'react-redux'
import { 
  Grid, 
  IconButton, 
  Typography, 
  TextareaAutosize 
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import { 
  Provinces, 
  Districts, 
  Villages, 
  Sectors, 
  Cells 
} from 'rwanda'
import { 
  geocodeByAddress, 
  getLatLng 
} from 'react-places-autocomplete'

import {
  updateSupplier,
  resetSupplier,
  getSuppliers,
  getSupplier,
} from '../../../features/suppliers/supplierSlice'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import HMTextField from '../../../components/common/tools/HMTextField'
import HMDatePcker from '../../../components/common/tools/HMDatePcker'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import HMButton from '../../../components/common/tools/HMButton'
import Spinner from '../../../components/common/tools/Spinner'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../../components/common/tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { 
  supplierTypes, 
  removeDuplicates 
} from '../../../hooks/helperFunctions'
import { 
  blue, 
  white, 
  green, 
  orange, 
  lightBlue 
} from '../../../hooks/useColors'
import HMPlacesAutocomplete from '../../../components/common/tools/HMPlacesAutocomplete'
import { cloudinary } from '../../../hooks/helperFunctions'

function EditSupplier() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    supplier,
    isOne,
    message,
    isError,
    isUpdated,
    isSuccess,
  } = useSelector((state) => state.supplier)
  const {isUploaded, isUploading, isUploadError, file} = useSelector((state) => state.cloudinary)

  const breadOptions = [
    { text: 'Suppliers', link: '/admin/suppliers' },
    { text: `Edit - ${supplier.name}`, link: null },
  ]
  const handleAdd = () => {
    navigate('/admin/suppliers/create')
  }
  const handleView = () => {
    navigate(`/admin/suppliers/${supplier._id}`)
  }

  const [wrongNumber, setWrongNumber] = useState(false)
  const [coordinates, setCoordinates] = useState({
    lng: null,
    lat: null,
  })

  const { windowW } = useWindowDimensions()

  const isTabletScreen = windowW < 720 ? true : false

  const defaultFormData = (supplier) => {
    const file =
      'https://harakameds.com/wp-content/uploads/2021/02/HARAKAMEDS-LTD-LICENSE.pdf'
    const logoImage =
      'https://drive.google.com/uc?export=view&id=1mlSnFoNKT8xSJgjI3HN2kP3f-aYFKXzT'

    const checkData = supplier && Object.keys(supplier).length ? true : false

    return {
      name: checkData ? supplier.name : '',
      about: checkData ? supplier.about : '',
      phone: checkData && supplier.phone.length ? supplier.phone : [''],
      logo: checkData ? supplier.logo : logoImage,
      type: checkData ? { name: supplier.type } : '',
      supplierMarkUp: checkData ? supplier.supplierMarkUp : 0,
      onlineMarkUp: checkData ? supplier.onlineMarkUp : 0,
      street: checkData && supplier.address ? supplier.address.street : '',
      cell: checkData && supplier.address ? { name: supplier.address.cell } : '',
      sector: checkData && supplier.address ? { name: supplier.address.sector } : '',
      village: checkData && supplier.address ? { name: supplier.address.village } : '',
      district: checkData && supplier.address ? { name: supplier.address.district } : '',
      province: checkData && supplier.address ? { name: supplier.address.province } : '',
      licenseFile: checkData && supplier.license ? supplier.license.image : file,
      issueOn: checkData && supplier.license ? new Date(supplier.license.issueOn) : null,
      lastName: checkData && supplier.license ? supplier.license.pharmacist.lastName  : '',
      firstName: checkData && supplier.license ? supplier.license.pharmacist.firstName : '',
      expiresOn: checkData && supplier.license ? new Date(supplier.license.expiresOn) : null,
      mapAddress: checkData && supplier.address ? supplier.address.mapAddress : '',
    }
  }

  useEffect(() => {
    if (isError || isUpdated || isSuccess || isOne) {
      dispatch(resetSupplier())
    }

    if (isError) {
      toast.error(message)
    } else if (isOne) {
      setFormData(defaultFormData(supplier))
    } else if (isUpdated) {
      toast.success('Supplier updated successfully')
      setFormData(defaultFormData(supplier))
    }

    if (!Object.keys(supplier).length || (supplier['_id'] && supplier._id !== id)) {
      dispatch(getSupplier(id))
    } else {
      if (supplier.location && Object.keys(supplier.location).length) {
        setCoordinates({
          lng: supplier.location.longitude,
          lat: supplier.location.latitude,
        })
      }
    } 
  }, [
    dispatch,
    navigate,
    id,
    supplier,
    message,
    isError,
    isOne,
    isUpdated,
    isSuccess,
  ])

  const [formData, setFormData] = useState(defaultFormData(supplier))

  const {
    logo,
    name,
    cell,
    type,
    phone,
    about,
    street,
    sector,
    issueOn,
    village,
    province,
    lastName,
    district,
    firstName,
    expiresOn,
    licenseFile,
    onlineMarkUp,
    supplierMarkUp,
    mapAddress,
  } = formData

  const [supplierLogoUrl, setSupplierLogoUrl] = useState(logo)
  const [licenseFileUrl, setLicenseFileUrl] = useState(licenseFile)
  const [isFileUploading, setFileUploading] = useState(false)

  useEffect(() =>{
    if(isUploading) setFileUploading(true)
  }, [isUploading])

  useEffect(()=>{
    if(supplierLogoUrl) setFileUploading(false)
  },[supplierLogoUrl])
  
  useEffect(()=>{
    setFormData((prevState) =>({
      ...prevState,
      logo: supplierLogoUrl,
      licenseFile: licenseFileUrl
    }))
  },[supplierLogoUrl, licenseFileUrl])

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'onlineMarkUp' || name === 'supplierMarkUp') {
      if (name === 'onlineMarkUp' && value >= 0 && value < 16) {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
      } else if (name === 'supplierMarkUp' && value > 0) {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
      }
    } else {
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }
  const handleIssueOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      issueOn: newValue,
    }))
  }
  const handleExpiresOnChange = (newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      expiresOn: newValue,
    }))
  }
  const handleTypeChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      type: val,
    }))
  }

  const handleProvinceChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      province: val ? val : '',
      cell: '',
      sector: '',
      village: '',
      district: '',
    }))
  }
  const handleDistrictChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      district: val ? val : '',
      cell: '',
      sector: '',
      village: '',
    }))
  }
  const handleSectorChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      sector: val ? val : '',
      cell: '',
      village: '',
    }))
  }
  const handleCellChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      cell: val ? val : '',
      village: '',
    }))
  }
  const handleVillageChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      village: val ? val : '',
    }))
  }
  const handlePhones = (index, e) => {
    const phoneValues = [...phone]
    const value = e.target.value
    const stringValue = value.toString()
    if (stringValue.length <= 10) {
      const isValid = /^07[2-9]{1}[0-9]{7}$/.test(value)
      phoneValues[index] = value

      if (!isValid) {
        e.target.classList.add('wrong-input')
        setWrongNumber(true)
      } else {
        e.target.classList.remove('wrong-input')
        setWrongNumber(false)
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      phone: phoneValues,
    }))
  }
  const handleRemovePhone = (index) => {
    const phoneValues = [...phone]
    if (phone.length > 1) {
      phoneValues.splice(index, 1)
      setFormData((prevState) => ({
        ...prevState,
        phone: phoneValues,
      }))
    }
  }
  const handleAddPhone = (index) => {
    const phoneValues = [...phone]
    phoneValues.splice(index + 1, 0, '')
    setFormData((prevState) => ({
      ...prevState,
      phone: phoneValues,
    }))
  }

  const handleChangeMapAddress = (address) => {
    setFormData((prevState) => ({
      ...prevState,
      mapAddress: address,
    }))
  }

  const handleSelectMapAddress = async (address) => {
    const results = await geocodeByAddress(address)
    const ll = await getLatLng(results[0])

    handleChangeMapAddress(address)
    setCoordinates(ll)
  }

  const disabled =
    name === '' ||
    isFileUploading ||
    about === '' ||
    street === '' ||
    !coordinates.lng ||
    !coordinates.lat ||
    mapAddress === '' ||
    wrongNumber === true ||
    type === '' ||
    (type['name'] && type['name'] === '') ||
    cell === '' ||
    (cell['name'] && cell['name'] === '') ||
    sector === '' ||
    (sector['name'] && sector['name'] === '') ||
    village === '' ||
    (village['name'] && village['name'] === '') ||
    district === '' ||
    (district['name'] && district['name'] === '') ||
    province === '' ||
    (province['name'] && province['name'] === '') ||
    (type['name'] && type['name'] === supplierTypes[0] && issueOn === '') ||
    (type['name'] && type['name'] === supplierTypes[0] && expiresOn === null) ||
    (type['name'] && type['name'] === supplierTypes[0] && firstName === null) ||
    (type['name'] && type['name'] === supplierTypes[0] && lastName === '') ||
    (type['name'] && type['name'] === supplierTypes[0] && licenseFile === '')
      ? true
      : false

  const handleUpdate = (e) => {
    e.preventDefault()

    const updatedSupplier = {
      _id: id,
      name,
      about,
      supplierMarkUp: parseInt(supplierMarkUp),
      onlineMarkUp: parseInt(onlineMarkUp),
      type: type.name,
      logo: logo && logo,
      address: {
        street,
        mapAddress,
        cell: cell.name,
        sector: sector.name,
        village: village.name,
        district: district.name,
        province: province.name,
      },
      license: {
        issueOn: issueOn && issueOn,
        expiresOn: expiresOn && expiresOn,
        image: licenseFile && licenseFile,
        pharmacist: {
          firstName: firstName && firstName,
          lastName: lastName && lastName,
        },
      },
      phone: removeDuplicates(phone, 'number'),
      location: Object.keys(coordinates).length && {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      },
    }

    if (type.name.toLowerCase() !== supplierTypes[0].toLowerCase()) {
      delete updatedSupplier['license']
    }

    dispatch(updateSupplier(updatedSupplier))
  }

  return (
    <Page>
      {!Object.keys(supplier).length ? (
        <Spinner marginTop={25} />
      ) : (
        <>
          <FullWidthContainer
            display="flex"
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs
              options={breadOptions}
              margin="auto auto auto 0"
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox
              float="right"
              display="flex"
              alignSelf="flex-start"
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              <HMButton
                type="button"
                bgColor={orange}
                isResponsive={true}
                handleClick={handleView}
                icon={<RemoveRedEyeIcon sx={iconStyle} />}
                text={<Typography sx={textStyle}>View</Typography>}
              />
              <HMButton
                type="button"
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />}
                text={<Typography sx={textStyle}>Add Supplier</Typography>}
              />
            </HMBox>
          </FullWidthContainer>
          <Section bgColor={white} padding={10}>
            <Grid
              component="form"
              container
              spacing={1}
              onSubmit={handleUpdate}
              className="supplier-creation-form"
            >
              <Grid item xs={12} sm={12}>
                <HMBox
                  width="100%"
                  display="flex"
                  bgColor={lightBlue}
                  padding="10px"
                  margin="30px 0px"
                  flexDirection="column"
                  maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                  <HMText
                    left="0"
                    top="-35px"
                    width="155px"
                    height="30px"
                    fontWeight={500}
                    className="fade-in"
                    position="relative"
                    bgColor={white}
                    margin="10px 0 -25px 20px"
                    padding="5px 10px 0px 10px"
                    text="Upload supplier logo"
                    border={`1px solid ${lightBlue}`}
                  />
                  <HMDropZone
                   cloudinaryFileUrl={supplierLogoUrl}
                   setCloudinaryFileUrl = {setSupplierLogoUrl}
                   endPoint = {cloudinary.API_URL_LOGOS}
                   bgColor={white}
                   className="drag-drop"
                   supportedFiles={{
                     'image/jpeg': [],
                     'image/jpg': [],
                     'image/png': [],
                   }}
                   allowedSizeInMB={1}
                  />
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ display: 'flex' }}>
                <HMTextField
                  name="name"
                  value={name}
                  margin="auto"
                  required={true}
                  label="Supplier name"
                  onChange={handleChange}
                  placeholder="Enter supplier name"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="HM-dropdown">
                <DropdownFilter
                  type="text"
                  field="name"
                  value={type}
                  required={true}
                  label="Supplier type"
                  prompt="Select supplier type"
                  onChange={handleTypeChange}
                  options={supplierTypes.map((type) => ({ name: type }))}
                />
              </Grid>
              <Grid item xs={12} sm={3} sx={{display: 'flex'}}>
                <HMTextField 
                  width='50%'
                  type='number'
                  margin='auto 0'
                  required={true}
                  name='supplierMarkUp'
                  value={supplierMarkUp}
                  label='Supplier markup'
                  onChange={handleChange}
                  placeholder='Enter supplier markup'
                />
                <Title 
                  color={blue}
                  margin='auto'
                  padding='1px 5px'
                  borderRadius='5px'
                  size={fontSize - 2}
                  bgColor={lightBlue}
                  title={`${supplierMarkUp} %`}
                />
              </Grid>
              <Grid item xs={12} sm={3} sx={{ display: 'flex' }}>
                <HMTextField
                  width="50%"
                  type="number"
                  margin="auto 0"
                  required={true}
                  name="onlineMarkUp"
                  value={onlineMarkUp}
                  label="online markup"
                  onChange={handleChange}
                  placeholder="Enter online markup [0 - 15]"
                />
                <Title
                  color={blue}
                  margin="auto"
                  padding="1px 5px"
                  borderRadius="5px"
                  size={fontSize - 2}
                  bgColor={lightBlue}
                  title={`${onlineMarkUp} %`}
                />
              </Grid>
              {type && type.name === supplierTypes[0] && (
                <>
                  <HMText
                    height="30px"
                    zIndex={999}
                    bgColor={white}
                    fontWeight={700}
                    className="fade-in"
                    margin="25px 0 -40px 20px"
                    padding="5px 10px 0px 10px"
                    text="License Information"
                    border={`1px solid ${lightBlue}`}
                  />
                  <Grid
                    className="fade-in"
                    container
                    spacing={2}
                    sx={{
                      backgroundColor: lightBlue,
                      padding: '10px 10px 30px 10px',
                      margin: '25px 10px 50px 10px',
                      borderRadius: '8px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <HMBox
                        width="100%"
                        display="flex"
                        bgColor={white}
                        padding="5px 10px"
                        margin="30px 0px 0 0px"
                        flexDirection="column"
                        maxWidth={`${isTabletScreen && '100% !important'}`}
                      >
                        <HMText
                          left="0"
                          top="-25px"
                          width="125px"
                          height="30px"
                          fontWeight={500}
                          className="fade-in"
                          position="relative"
                          bgColor={lightBlue}
                          margin="10px 0 -25px 20px"
                          padding="5px 10px 0px 10px"
                          text="Upload license"
                          border={`1px solid ${white}`}
                        />
                        <HMDropZone
                         cloudinaryFileUrl={licenseFileUrl}
                         setCloudinaryFileUrl = {setLicenseFileUrl}
                         endPoint = {cloudinary.API_URL_FDA}
                         bgColor={lightBlue}
                         className="drag-drop"
                         supportedFiles={{
                           'image/jpeg': [],
                           'image/jpg': [],
                           'application/pdf': [],
                         }}
                         allowedSizeInMB={1}
                        />
                      </HMBox>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <HMBox
                        width="100%"
                        margin="auto"
                        display="flex"
                        flexDirection="column"
                      >
                        <HMText
                          width="100%"
                          textAlign="center"
                          text="Date issued on"
                          margin="auto auto 0 auto"
                        />
                        <HMDatePcker
                          value={issueOn}
                          maxDate={new Date()}
                          onChange={handleIssueOnChange}
                        />
                      </HMBox>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <HMBox
                        width="100%"
                        margin="auto"
                        display="flex"
                        flexDirection="column"
                      >
                        <HMText
                          width="100%"
                          textAlign="center"
                          text="Valid until"
                          margin="auto auto 0 auto"
                        />
                        <HMDatePcker
                          value={expiresOn}
                          minDate={new Date()}
                          onChange={handleExpiresOnChange}
                        />
                      </HMBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <HMBox
                        width="100%"
                        display="flex"
                        bgColor={white}
                        padding="5px 10px"
                        margin="30px 0px 0 0px"
                        flexDirection="column"
                        maxWidth={`${isTabletScreen && '100% !important'}`}
                      >
                        <HMText
                          left="0"
                          top="-25px"
                          width="125px"
                          height="30px"
                          bgColor={lightBlue}
                          fontWeight={500}
                          className="fade-in"
                          position="relative"
                          margin="10px 0 -25px 20px"
                          padding="5px 10px 0px 10px"
                          text="The pharmacist"
                          border={`1px solid ${white}`}
                        />
                        <HMTextField
                          width="100%"
                          name="firstName"
                          required={true}
                          label="Firstname"
                          value={firstName}
                          onChange={handleChange}
                          placeholder="Enter firstname"
                        />
                        <HMTextField
                          width="100%"
                          name="lastName"
                          label="Lastname"
                          required={true}
                          value={lastName}
                          onChange={handleChange}
                          placeholder="Enter lastname"
                        />
                      </HMBox>
                    </Grid>
                  </Grid>
                </>
              )}
              <HMText
                height="30px"
                zIndex={999}
                bgColor={white}
                fontWeight={700}
                className="fade-in"
                margin="10px 0 -40px 20px"
                padding="5px 10px 0px 10px"
                text="Supplier Address &#38; Phone"
                border={`1px solid ${lightBlue}`}
              />
              <Grid
                className="supplier-address fade-in"
                container
                spacing={2}
                sx={{
                  backgroundColor: lightBlue,
                  padding: '10px 10px 30px 10px',
                  margin: '25px 10px',
                  borderRadius: '8px',
                }}
              >
                <Grid item xs={12} sm={6}>
                  <DropdownFilter
                    field="name"
                    required={true}
                    bgColor={white}
                    label="Province"
                    value={province}
                    margin="10px 0 5px 0"
                    prompt="Select province..."
                    onChange={handleProvinceChange}
                    options={Provinces().map((item) => ({ name: item }))}
                  />
                  {province.name && (
                    <DropdownFilter
                      field="name"
                      margin="5px 0"
                      bgColor={white}
                      required={true}
                      label="District"
                      value={district}
                      prompt="Select disctrict..."
                      onChange={handleDistrictChange}
                      options={Districts(province.name).map((item) => ({
                        name: item,
                      }))}
                    />
                  )}
                  {district.name && (
                    <DropdownFilter
                      field="name"
                      label="Sector"
                      margin="5px 0"
                      value={sector}
                      required={true}
                      bgColor={white}
                      prompt="Select sector..."
                      onChange={handleSectorChange}
                      options={Sectors(
                        province.name,
                        district.name,
                      ).map((item) => ({ name: item }))}
                    />
                  )}
                  {sector.name && (
                    <DropdownFilter
                      field="name"
                      label="Cell"
                      value={cell}
                      margin="5px 0"
                      required={true}
                      bgColor={white}
                      prompt="Select cell..."
                      onChange={handleCellChange}
                      options={Cells(
                        province.name,
                        district.name,
                        sector.name,
                      ).map((item) => ({ name: item }))}
                    />
                  )}
                  {cell.name && (
                    <DropdownFilter
                      field="name"
                      margin="5px 0"
                      label="Village"
                      value={village}
                      required={true}
                      bgColor={white}
                      prompt="Select village..."
                      onChange={handleVillageChange}
                      options={Villages(
                        province.name,
                        district.name,
                        sector.name,
                        cell.name,
                      ).map((item) => ({ name: item }))}
                    />
                  )}
                  {village.name && (
                    <>
                      <HMTextField
                        name="street"
                        margin="5px 0"
                        value={street}
                        bgColor={white}
                        required={true}
                        onChange={handleChange}
                        label="Street and house number"
                        placeholder="Enter street and house number"
                      />
                      <HMPlacesAutocomplete
                        value={mapAddress}
                        coordinates={coordinates}
                        handleChange={handleChangeMapAddress}
                        handleSelect={handleSelectMapAddress}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ marginTop: '5px' }}>
                  {phone.map((item, index) => {
                    const n =
                      index === 0
                        ? '1st'
                        : index === 1
                        ? '2nd'
                        : index === 2
                        ? '3rd'
                        : index + 1 + 'th'
                    return (
                      <HMBox
                        display="flex"
                        padding="0"
                        width="100%"
                        key={index}
                      >
                        <HMTextField
                          value={item}
                          name="phone"
                          type="number"
                          margin="5px 0"
                          required={true}
                          bgColor={white}
                          label={`${n} Phone number`}
                          placeholder="Enter a phone number"
                          onChange={(e) => handlePhones(index, e)}
                        />
                        {phone.length > 1 && (
                          <IconButton
                            onClick={() => handleRemovePhone(index)}
                            sx={{
                              padding: '5px',
                              margin: 'auto 5px',
                              height: '30px',
                            }}
                          >
                            <RemoveIcon sx={iconStyle} />
                          </IconButton>
                        )}
                        {phone.length < 3 && (
                          <IconButton
                            onClick={() => handleAddPhone(index)}
                            sx={{
                              padding: '5px',
                              margin: 'auto 5px',
                              height: '30px',
                            }}
                          >
                            <AddIcon sx={iconStyle} />
                          </IconButton>
                        )}
                      </HMBox>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <HMBox
                  width="100%"
                  display="flex"
                  bgColor={lightBlue}
                  padding="10px"
                  margin="30px 0px"
                  flexDirection="column"
                  maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                  <HMText
                    left="0"
                    top="-35px"
                    width="135px"
                    height="30px"
                    bgColor={white}
                    fontWeight={500}
                    text="About Supplier"
                    className="fade-in"
                    position="relative"
                    margin="10px 0 -25px 20px"
                    padding="5px 10px 0px 10px"
                    border={`1px solid ${lightBlue}`}
                  />
                  <TextareaAutosize
                    name="about"
                    value={about}
                    required={true}
                    label="Province"
                    onChange={handleChange}
                    aria-label="empty textarea"
                    placeholder="Additional information about the supplier"
                    style={{
                      width: '100%',
                      border: '0px',
                      padding: '10px',
                      maxWidth: '100%',
                      margin: '10px auto',
                      borderRadius: '8px',
                    }}
                  />
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <HMButton
                  width="100%"
                  type="submit"
                  float="right"
                  padding="6px"
                  bgColor={blue}
                  disabled={disabled}
                  margin="20px 0 5px 0"
                  paddingActive="5.5px"
                  text={<Typography sx={textStyle}>Update supplier</Typography>}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}
    </Page>
  )
}

export default EditSupplier

import React from 'react'
import ReactApexChart from 'react-apexcharts'


export const HMAreaChart = (props) => {
  
    const options = {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      colors: props.colors ,
 
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        labels: {
          format: 'MM',
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
  
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.7,
          //gradientToColors: blue,
          inverseColors: true,
          opacityFrom: 0.4,
          opacityTo: 0.4,
          stops: [0, 50, 100],
          // colorStops: []
        },
      },
      legend: {
        position: 'bottom',
        width: '100%',
      },
    }
  
    return <ReactApexChart options={options} series={props.data} />
  }

  export default HMAreaChart;
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import PublishIcon from '@mui/icons-material/Publish'
import AddIcon from '@mui/icons-material/Add'
import { reset } from '../../../features/import/importSlice'
import { 
  resetCategory,
  getCategories, 
  createCategory, 
  updateCategory, 
  deleteCategory, 
  deleteCategories, 
} from '../../../features/categories/categorySlice'
import { resetProduct, getProducts } from '../../../features/products/productSlice'
import CategoryForm from '../../../components/category/CategoryForm'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMDropZone from '../../../components/common/tools/HMDropZone'
import ReactCSV from '../../../components/common/tools/ReactCSV'
import HMButton from '../../../components/common/tools/HMButton'
import Spinner from '../../../components/common/tools/Spinner'
import Title from '../../../components/common/tools/Title'
import {
  iconStyle,
  textStyle,
  errorStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { 
  red,
  blue, 
  gray,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'

function Category() {
  const {
    message,
    isError,
    isCreated,
    isUpdated,
    isDeleted,
    isSuccess,
    categories,
    isBulkDeleted,
  } = useSelector(state => state.category)
  const productState = useSelector(state => state.product)
  const { products } = productState

  const { importData } = useSelector(state => state.import)
  
  const dispatch = useDispatch() 
  
  useEffect(() => {
    if (isSuccess || isError || isCreated || isUpdated || isDeleted || isBulkDeleted) {
      dispatch(resetCategory())
    }
    
    if (isError) {
      toast.error(message)
    } else if (isCreated) {
      toast.success(message)
      dispatch(getCategories())
    } else if (isUpdated) {
      dispatch(getCategories())
      toast.success('Category updated successfully')
    } else if (isDeleted) {
      toast.success('Category deleted successfully')
      dispatch(getCategories())
    } else if (isBulkDeleted) {
      toast.success(message)
      dispatch(getCategories())
    }
    
  }, [dispatch, isSuccess, isError, message, isCreated, isUpdated, isDeleted, isBulkDeleted])
  
  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    if (productState.isSuccess) {
      dispatch(resetProduct())
    }
    if (!products.length) {
      dispatch(getProducts())
    }
  }, [dispatch, products, productState.isSuccess])
  
  const fields = []

  const columns = [
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'parent', headerName: 'Parent', sortable: true },
  ] 
  
  columns.forEach((column) => {
    fields.push(column.field)
  })

  const sampleData = [
    {name: 'Category 1', parent: 'Prescription Medication'},
    {name: 'Category 2', parent: 'Prescription Medication'},
    {name: 'Category 3', parent: 'OTC (Over The Counter Medication)'},
    {name: 'Category 5'},
    {name: 'Category 6', parent: 'Health & Wellbeing'},
    {name: 'Category 7'},
  ]

  const supportedFiles = {
    'text/csv': [],
  }

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const [isBulkDelete, setIsBulkDelete] = useState(false)
  const [currentCategory, setCurrentCategory] = useState({})
  const [validForDeletion, setValidForDeletion] = useState([])
  const [selectedCateories, setSelectedCateories] = useState([])
  const [currentParentCategory, setCurrentParentCategory] = useState({})

  const getCurentCategory = (id) => {
    return categories.filter(cat => cat._id === id)[0]
  }
  const handleImportModal = () => {
    setOpen(true)
    setIsEdit(false)
    setIsImport(true)
    setIsDelete(false)
    setIsBulkDelete(false)
    setTitle('Import Categories')
  }
  const handleCreateModal = () => {
    setOpen(true)
    setIsEdit(false)
    setIsImport(false)
    setIsDelete(false)
    setIsBulkDelete(false)
    setTitle('Add Category')
  }
  const handleEditModal = (id) => {
    const clickedCategory = getCurentCategory(id)
    setCurrentCategory(clickedCategory)
    
    if (clickedCategory.parent) { 
      const parentCategory = categories.filter(cat => cat.name === clickedCategory.parent)[0]
      setCurrentParentCategory(parentCategory)
    }

    setOpen(true)
    setIsEdit(true)
    setIsImport(false)
    setIsDelete(false)
    setIsBulkDelete(false)
    setTitle('Edit Category')
  }
  const handleDeleteModal = (id) => {
    setCurrentCategory(getCurentCategory(id))

    setOpen(true)
    setIsEdit(false)
    setIsDelete(true)
    setIsImport(false)
    setIsBulkDelete(false)
    setTitle('Delete Category')
  }
  const handleBulkDeleteModal = (data) => {
    data.forEach(cat => {
      if (!categories.find(category => category.parent === cat.name) && 
          products.length && 
          !products.find(product => product.category.name === cat.name)
      ) {
        validForDeletion.push(cat)
      }
    })
    setValidForDeletion(validForDeletion)
   
    setSelectedCateories(data)
    setOpen(true)
    setIsEdit(false)
    setIsImport(false)
    setIsDelete(false)
    setIsBulkDelete(true)
    setTitle('Delete Categories')
  }
  const handleDelete = () => {
    dispatch(deleteCategory(currentCategory._id))
    handleClose()
  }
  const handleBulkDelete = () => {
    const categoryIds = validForDeletion.map(cat => cat._id)
    dispatch(deleteCategories(categoryIds))
    handleClose()
  }
  const handleBulkCreate = () => {
    const readyCategories = validateImportedData(importData)
    dispatch(createCategory(readyCategories))
    handleClose()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsEdit(false)
    dispatch(reset())
    setIsDelete(false)
    setIsImport(false)
    setIsBulkDelete(false)
    setCurrentCategory({})
    setValidForDeletion([])
    setCurrentParentCategory({})
  }
  const handleEdit = (e, formData) => {
    const {  
      name,
      parent
    } = formData
    e.preventDefault()
    const category  = parent === '' ? { name } : { name, parent }
    const updatedCategory = {
      ...category,
      _id: currentCategory._id
    }
    dispatch(updateCategory(updatedCategory))
    handleClose()
  }
  const handleCreate = (e, formData) => {
    const {  
      name,
      parent
    } = formData
    e.preventDefault()
    const newCategory = parent === '' ? { name } : { name, parent }
    dispatch(createCategory(newCategory))
    handleClose()
  }
  
  const validateImportedData = (data) => {
    const validData = []
    data.forEach(item => {
      for (let key in item) {
        if ((key !== 'name' && key !== 'parent') || typeof(item[key]) !== 'string') {
          return null
        }
      }

      if (!categories.find(cat => cat.name.toLowerCase() === item.name.toLowerCase())) {
        if (categories.find(cat => cat.name.toLowerCase() === item.parent.toLowerCase())) {
          validData.push({
            name: item.name,
            parent: categories.find(cat => cat.name === item.parent)._id
          })
        } else {
          validData.push({name: item.name})
        }
      }
    })
    return validData
  }

  return (
    <Page>
      <FullWidthContainer
        display='flex'
      >
        <Title 
          title='Categories' 
          margin='auto auto auto 0'
        />
        <HMBox 
            float='right'
            display='flex'
        >
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreateModal}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Category</Typography>} 
          />
          <HMButton 
            type='button'
            bgColor={blue}
            isResponsive={true}
            handleClick={handleImportModal}
            icon={<UploadFileIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Import Category</Typography>} 
          />
        </HMBox>
        <HMModal 
          open={open} 
          title={title}
          minWidth='250px'
          handleClose={handleClose} 
          colors={{lightBlack, lightBlue}}
        >
          {isImport ? (
            <HMBox
              width='100%'
              className='fade-in'
            > 
              <Typography sx={{...textStyle, fontStyle: 'italic', textAlign: 'center'}}>
                The field labels marked with <span style={{ color: red }}>*</span> are required input fields.  
              </Typography>
              <Typography sx={{...textStyle, textAlign: 'center'}}>
                The correct column order is &nbsp;
                  <span style={deleteItemTextStyle}>&nbsp;name&nbsp;<span style={{ color: red }}>*</span></span>&nbsp;
                  and &nbsp;
                  <span style={deleteItemTextStyle}>&nbsp;parent&nbsp;</span>   
              </Typography>
              <ReactCSV 
                data={sampleData}
                filename='Categories'
              >
                <HMButton 
                  width='100%'
                  type='button'
                  bgColor={green}
                  margin='10px 0px'
                  icon={<FileDownloadTwoToneIcon sx={{...iconStyle, margin: 'auto 5px auto auto'}} />} 
                  text={<Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>Download CSV sample</Typography>} 
                />
              </ReactCSV>
              <HMDropZone 
                allowedSizeInMB={1}
                className='drag-drop' 
                supportedFiles={supportedFiles} 
                cloudinaryFileUrl = ''
              />
              {importData.length  && validateImportedData(importData).length ? (
              <HMBox 
                float='none'
                width='100%'
                margin='5px 0'  
                className='fade-in'
                bgColor={lightBlue}
              >
                <Typography sx={{...textStyle, textAlign: 'center'}}>
                  Received {importData.length} categories.
                </Typography>
                <Typography sx={{...textStyle, textAlign: 'center'}}>
                  Only {validateImportedData(importData).length} categories are valid for creation.
                </Typography>
              </HMBox>
              ) : importData.length ? (
                <Typography sx={errorStyle}>The file conntent is not supported / valid</Typography>
              ) : <></>}
              <HMButton 
                width='100%'
                type='button'
                bgColor={blue}
                margin='15px 0 0 0'
                handleClick={handleBulkCreate}
                icon={<PublishIcon sx={{...iconStyle, margin: 'auto 5px auto auto'}} />} 
                disabled={importData.length && validateImportedData(importData).length ? false : true}
                text={<Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>Submit</Typography>} 
              />
            </HMBox>
          ) : !isDelete && !isBulkDelete ? (
            <CategoryForm 
              className='fade-in'
              categories={categories}
              handleClick={handleClose}
              currentCategory={currentCategory}
              currentParentCategory={currentParentCategory}
              onSubmit={isEdit ? handleEdit : handleCreate}
            />
          ) : (
            <HMBox
              width='100%'
              className='fade-in'
            >
              {(selectedCateories.length && !isDelete) ? (
                <HMBox 
                  width='100%'
                  float='none'
                  margin='5px 0'  
                  bgColor={lightBlue}
                >
                  <Typography sx={{...textStyle, textAlign: 'center'}}>
                    You selected {selectedCateories.length} categories.
                  </Typography>
                  <Typography sx={{...textStyle, textAlign: 'center'}}>
                    {`${validForDeletion.length === 1 && selectedCateories.length === validForDeletion.length
                        ? 'Category is ' : validForDeletion.length > 1 && selectedCateories.length === validForDeletion.length
                        ? validForDeletion.length + ' categories are' : selectedCateories.length > validForDeletion.length
                        ? 'Only ' + validForDeletion.length + ' categories are' : 'The selected categories are not'}` 
                    } valid for deletion!
                  </Typography>
                </HMBox>
              ) : (<></>)}
              {validForDeletion.length ? (
                <Typography sx={deleteTextStyle}>
                  Are you sure your want to delete: 
                </Typography>
              ) : (<></>)}
              {isDelete ? (
                <Typography sx={deleteItemTextStyle}>
                  {currentCategory.name} ?
                </Typography>
              ) : !selectedCateories.length ? (
                <Typography sx={deleteItemTextStyle}>
                  No category selected. <br/>Select categories and try again!
                </Typography>
              ) : validForDeletion.length > 10 ? (
                <Typography sx={deleteItemTextStyle}>
                  {validForDeletion.length} Categories?
                </Typography>
              ) : (
                <Box sx={{marginBottom: '20px'}}>
                  {validForDeletion.map((cat, index) => (
                      <Typography 
                        sx={{...deleteItemTextStyle, marginBottom: '0px'}} 
                        key={index}
                      >
                        {cat.name}
                      </Typography>
                  ))}
                </Box>
              )} 
              {(validForDeletion.length || isDelete) && (
                <HMButton 
                  type='button'
                  bgColor={red}
                  margin='auto'
                  float='right'
                  handleClick={isDelete ? handleDelete : handleBulkDelete}
                  text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
                />
              )}
              <HMButton 
                  type='button'
                  margin='auto'
                  bgColor={gray}
                  handleClick={handleClose}
                  text={<Typography sx={textStyle}>No, Cancel!</Typography>}
                  float={(validForDeletion.length || isDelete) && 'left'}
              />
            </HMBox>
          )}
        </HMModal>
      </FullWidthContainer>
      {!categories.length ? (<Spinner marginTop={25} />) : (
        <DataTable
          fields={fields}
          columns={columns}
          title='Categories'
          defaultRowsNum={20}
          handleEdit={handleEditModal}
          handleDelete={handleDeleteModal}
          handleBulkDelete={handleBulkDeleteModal}
          data={categories.filter(category => category.parent)}
        />
      )}
    </Page>
  )
}

export default Category
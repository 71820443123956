import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import {  
  useSelector,
  useDispatch
} from 'react-redux'
import {
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import moment from 'moment'
import { 
  getUsers,
  resetUser, 
} from '../../../features/users/userSlice'
import { 
  getSupplier,
  resetSupplier, 
  updateSupplier,
  deleteSupplier,
} from '../../../features/suppliers/supplierSlice'
import { 
  getSupplierWithListings,
  resetSuppliersWithListings, 
} from '../../../features/suppliersWithListings/suppliersWithListingsSlice'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ChangeStatusForm from '../../../components/supplier/ChangeStatusForm'
import HMBreadcrumbs from '../../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import HMModal from '../../../components/common/layout/HMModal'
import Section from '../../../components/common/layout/Section'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMToogleButton from '../../../components/common/tools/HMToogleButton'
import DropdownFilter from '../../../components/common/tools/DropdownFilter'
import EditIconButton from '../../../components/common/tools/EditIcon'
import DeleteIcon from '../../../components/common/tools/DeleteIcon'
import HMButton from '../../../components/common/tools/HMButton'
import HMSpiner from '../../../components/common/tools/HMSpiner'
import HMText from '../../../components/common/tools/HMText'
import Title from '../../../components/common/tools/Title'
import { 
  fontSize,
  textStyle, 
  iconStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { 
  userRole,
  isInTitle,
  supplierTypes, 
  stringifyPhoneNumber, 
  supplierApplicationStatus,
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  darkBlue,
  lightBlue,
  lightBlack,
  middleOrange,
  getRandomColor
} from '../../../hooks/useColors'

function Supplier() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { 
    users
  } = useSelector(state => state.user)
  const supplierState = useSelector(state => state.supplier)
  const relationState = useSelector(state => state.suppliersWithListings)
  const { 
    supplierWithListings,
    isSupplierWithListings,
  } = relationState

  const applicationFormDataSekeleton = {
    rejectionMessage: '',
    confirmApproval: null,
    confirmReception: null,
    confirmRejection: null,
  }
  const [supplier, setSupplier] = useState({})
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState('')
  const [isAdd, setIsAdd] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [toUdatedUser, setToUdatedUser] = useState({})
  const [currentUser , setCurrentUser ] = useState({})
  const [isDeleteSupplier, setIsDeleteSupplier] = useState(false)
  const [applicationStatus, setApplicationStatus] = useState('')
  const [isApplicationStatus, setIsApplicationStatus] = useState(false)
  const [applicationFormData, setApplicationFormData] = useState(applicationFormDataSekeleton)
  const [formData, setFormData] = useState({
    role: '',
    user: ''
  })
  const [activeState, setActiveState] = useState(() => {
    if (Object.keys(supplier).length) {
      return supplier.status.toLowerCase() === 'active' ? true : false
    } 
  })

  const {
    role,
    user
  } = formData
  const {
    confirmApproval,
    rejectionMessage,
    confirmReception,
    confirmRejection,
  } = applicationFormData

  const roles = [
    {name: 'Admin'},
    {name: 'Manager'},
    {name: 'Operator'},
  ]

  const breadOptions = [
    { text: 'Suppliers', link: '/admin/suppliers' },
    { text: supplier['name'] && supplier.name, link: null }
  ]

  const handleListings = () => {
    navigate(`/admin/suppliers/${supplier._id}/listings`)
  }
  const handleEdit = () => {
    navigate(`/admin/suppliers/edit/${supplier._id}`)
  }
  const handleAdd = () => {
    navigate('/admin/suppliers/create')
  }

  const {
    windowW
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getSupplier(id))
    dispatch(getSupplierWithListings(id))
    return () => {
      dispatch(resetUser())
    } 
  }, [dispatch, id])

  useEffect(() => {
    const { 
      isOne,
      message,
      isError,
      isDeleted,
      isUpdated,
    } = supplierState

    if ((isOne || isUpdated) && Object.keys(supplierState.supplier).length) {
      setSupplier(supplierState.supplier)
    }

    if (isError || isOne || isUpdated || isDeleted) {
      dispatch(resetSupplier())
    }

    if (isError) {
      toast.error(message)
    } else if (isOne) {
      setActiveState(supplierState.supplier.status.toLowerCase() === 'active' ? true : false)
      if (supplierState.supplier.applicationStack.length) {
        setApplicationStatus(supplierState.supplier.applicationStack[supplierState.supplier.applicationStack.length - 1].status)
      }
    } else if (isDeleted) {
      toast.success(message)
      navigate(`/admin/suppliers`)
    } else if (isUpdated) {
      setActiveState(supplierState.supplier.status.toLowerCase() === 'active' ? true : false)
      if (!isApplicationStatus &&!isInTitle(title, 'activate', 'deactivate')) {
        toast.success(`Supplier ${toUdatedUser.role} ${action === 'remove' ? 'remov' : action}ed successfully`)
      } else if (!isApplicationStatus && isInTitle(title, 'activate', 'deactivate')) {
        toast.success(`Supplier status is successfully changed to ${supplierState.supplier.status}`)
      } else if (isApplicationStatus) {
        const tempStatus = supplierState.supplier.applicationStack[supplierState.supplier.applicationStack.length - 1].status
        handleClose()
        setApplicationStatus(tempStatus)
        setApplicationFormData(applicationFormDataSekeleton)
        toast.success(`Supplier application status is successfully updated to ${tempStatus}`)
      }
    } 

    if (isSupplierWithListings) {
      dispatch(resetSuppliersWithListings())
    }
  }, [
    title,
    action, 
    dispatch, 
    navigate, 
    supplierState, 
    toUdatedUser, 
    isSupplierWithListings,
    applicationFormDataSekeleton
  ])

  const handleAddUserModal = () => {
    setOpen(true)
    setIsAdd(true)
    setIsEdit(false)
    setAction('add')
    setIsDelete(false)      
    setTitle('Add User')
  }
  const handleAddUser = () => {
    const updatedSupplier = {
      action: action,
      _id: supplier._id,
      role: role.name,
      email: user.email,
    }
    setToUdatedUser(updatedSupplier)
    dispatch(updateSupplier(updatedSupplier))
    handleClose()
  }

  const handleEditUserModal = (user) => {
    setOpen(true)
    setIsAdd(false)
    setIsEdit(true)
    setAction('edit')   
    setIsDelete(false)  
    setCurrentUser(user) 
    setTitle('Edit User')

    setFormData({
      user: users.find(item => item.email === user.email),
      role: user.role && roles.find(item => item.name.toLowerCase() === user.role.toLowerCase())
    })
  }
  const handleChangeRole = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      role: val
    }))
  }
  const handleChangeUser = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      user: val
    }))
  }
  const handleEditUser = () => {
    const updatedSupplier = {
      action: action,
      _id: supplier._id,
      role: role.name,
      email: user.email,
    }   
    setToUdatedUser(updatedSupplier)
    dispatch(updateSupplier(updatedSupplier))
    handleClose()
  }

  const handleDeleteUserModal = (user) => {
    setOpen(true)
    setIsAdd(false)
    setIsEdit(false)
    setIsDelete(true)        
    setAction('remove')
    setCurrentUser(user)       
    setTitle('Delete User')
  }
  const handleDeleteUser = () => {
    const updatedSupplier = {
      _id: supplier._id,
      action: action,
      email: currentUser.email
    }
    setToUdatedUser(updatedSupplier)
    dispatch(updateSupplier(updatedSupplier))
    handleClose()
  }

  const handleApplicationStatusChange = (e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'confirmReception' || name === 'confirmRejection' || name === 'confirmApproval') {
      value = (value === true || value === 'true') ? true : false
    } 

    setApplicationFormData((prevState) => ({
        ...prevState,
        [name]: value,
    })) 
  } 
  const handleApplicationStatus = () => {
    const updatedSupplier = {
      _id: supplier._id,
      applicationStack: [
        ...supplier.applicationStack,
        {
          timestamp: new Date(),
          message: confirmRejection ? rejectionMessage : '',
          status: confirmReception ? 'Received' 
            : confirmApproval ? 'Approved' 
            : (!confirmApproval && confirmRejection) && 'Rejected' 
        }
      ]
    } 
    if (confirmApproval) {
      updatedSupplier['status'] = 'active'
    }
    dispatch(updateSupplier(updatedSupplier))
    setOpen(false)
  }

  const handleActiveStateModal = () => {
    setOpen(true)
    setActiveState(!activeState)
    setTitle(`${supplier.status.toLowerCase() !== 'active' ? 'Activate' : 'Deactivate'} Supplier`)
  }
  const handleActiveState = () => {
    const updatedSupplier = {
      _id: supplier._id,
      status: supplier.status === 'active' ? 'inactive' : 'active'
    }
    dispatch(updateSupplier(updatedSupplier))
    handleCloseExeptTitle()
  }
  
  const handleDeleteSupplierModal = () => {
    setOpen(true)
    setIsDeleteSupplier(true)
    setTitle('Delete Supplier')
  }
  const handleDeleteSupplier = () => {
    dispatch(deleteSupplier(supplier._id))
    handleClose()
  }

  const handleCloseExeptTitle = () => {
    setOpen(false)
    setIsAdd(false)
    setIsEdit(false)
    setIsDelete(false)
    setIsDeleteSupplier(false)
    setIsApplicationStatus(false)
    setActiveState(supplier.status.toLowerCase() === 'active' ? true : false)
    setFormData({
      user: '',
      role: ''
    }) 
  }

  const handleApplicationStatusMaodal = () => {
    setOpen(true)
    setIsApplicationStatus(true)
    setTitle('Application Status')
  }
  const handleClose = () => {
    setTitle('')
    handleCloseExeptTitle()
  }
 
  const usersSortedByEmails = users.map(user => user)
  if (users.length > 0) {
    usersSortedByEmails.sort((a,b) => (a['email'].toLowerCase() > b['email'].toLowerCase()) ? 1 : ((b['email'].toLowerCase() > a['email'].toLowerCase()) ? -1 : 0))
  }

  const disableApplicationStatusChange = (
      (applicationStatus === 'Submitted' || applicationStatus === 'Re-Applied') && !confirmReception
    ) || (
      applicationStatus === 'Received' && !confirmApproval && (
        (!confirmRejection) ? true 
        : (confirmRejection && rejectionMessage.length >= 10) ? false
        : (confirmRejection && rejectionMessage.length < 10) && true
      ) 
    ) ? true : false

  return (
    <Page>
      {supplierState.isLoading ? (
          <HMSpiner 
              size={60}
              zIndex={999}
              width='100%'
              height='80vh'
              margin='auto'
              position='absolute'
              bgColor='transparent'
          />
      ) : <></>}
      {Object.keys(supplier).length ? (
        <>
          <FullWidthContainer
            display='flex'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
              padding={isTabletScreen && '10px 0 0 10px'}
            />
            <HMBox 
              float='right'
              display='flex'
              alignSelf='flex-start'
              padding={isTabletScreen && '10px 0'}
              flexDirection={isTabletScreen ? 'row-reverse' : 'row'}
            >
              {(() => {
                const stack = supplier.applicationStack
                const ln = stack.length
                const approved = supplierApplicationStatus.find(s => s === 'Approved')
                
                return !ln || (ln && applicationStatus === approved) ? (
                  <>
                    <HMToogleButton 
                      status={activeState}
                      handleChange={handleActiveStateModal}
                      text={activeState ? 'Active' : 'Inactive'}
                    />
                    <HMButton 
                      type='button'
                      bgColor={orange}
                      isResponsive={true}
                      handleClick={handleListings}
                      icon={<FormatListBulletedIcon sx={iconStyle} />} 
                      text={<Typography sx={textStyle}>Listings</Typography>} 
                    />
                  </>    
                ) : (
                  <HMBox
                    display='flex'
                    borderRadius={25}
                    padding='2px 2px 2px 10px'
                    margin='auto 5px auto auto'
                    bgColor = {
                      applicationStatus === 'Submitted' ? orange 
                        : applicationStatus === 'Received' ? blue
                        : applicationStatus === 'Rejected' ? red   
                        : applicationStatus === 'Approved' ? green 
                        : applicationStatus === 'Re-Applied' && middleOrange 
                    }
                  >
                    <HMText
                      color={white}
                      fontWeight={500}
                      fontSize={`${fontSize}px`}
                      margin='auto 5px auto auto'
                      text={applicationStatus === 'Received' ? 'Processing' : applicationStatus}
                    />
                    {applicationStatus !== 'Rejected' ? (
                      <IconButton   
                        sx={{
                          opacity: 0.9,
                          backgroundColor: white,
                          margin: 'auto 0 auto 5px',
                          '&:hover': {
                            opacity: 1,
                            backgroundColor: white,
                          }
                        }}
                        onClick={handleApplicationStatusMaodal} 
                      >
                        <EditIcon 
                            sx={{
                              margin: 'auto',
                              fontSize: `${fontSize}px`,
                              color: applicationStatus === 'Submitted' ? orange 
                                : applicationStatus === 'Received' ? blue
                                : applicationStatus === 'Rejected' ? red   
                                : applicationStatus === 'Approved' ? green 
                                : applicationStatus === 'Re-Applied' && middleOrange 
                            }}
                        />
                      </IconButton>
                    ) : <></>}
                  </HMBox>
                )
              })()}
              <HMButton 
                type='button'
                bgColor={blue}
                isResponsive={true}
                handleClick={handleEdit}
                icon={<EditIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Edit</Typography>} 
              />
              <HMButton 
                type='button'
                bgColor={green}
                isResponsive={true}
                handleClick={handleAdd}
                icon={<AddIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>Add Supplier</Typography>} 
              />
              {(
                !relationState.isLoading && 
                !Object.keys(supplierWithListings).length && 
                supplier.status.toLowerCase() === 'inactive'
              ) ? (
                <HMButton 
                  type='button'
                  bgColor={red}
                  isResponsive={true}
                  icon={<Delete sx={iconStyle} />} 
                  handleClick={handleDeleteSupplierModal}
                  text={<Typography sx={textStyle}>Delete</Typography>} 
                />
              ) : <></>}
            </HMBox>
          </FullWidthContainer>
          <HMModal 
            open={open} 
            title={title}
            handleClose={handleClose} 
            colors={{lightBlack, lightBlue}}
          >
            <HMBox
              width='100%'
              className='fade-in'
            >
              {isApplicationStatus ? (
                <ChangeStatusForm 
                  formData={applicationFormData}
                  applicationStatus={applicationStatus}
                  handleChange={(e) => handleApplicationStatusChange(e)}
                />
              ) : (isDelete || isDeleteSupplier || isInTitle(title, 'activate', 'deactivate')) ? (
                <>
                  <Typography sx={deleteTextStyle}>
                    Are you sure your want to {
                      isDelete ? 'remove' 
                      : isDeleteSupplier ? 'delete'
                      : (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() === 'active') ? 'Deactivate' 
                      : (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() !== 'active') && 'Activate'
                      }: 
                  </Typography>
                  <Typography sx={deleteItemTextStyle}>
                    {isDelete ? currentUser.email : supplier.name} ?
                  </Typography>
                </>
              ) : (isAdd || isEdit) ? (
                <>
                  <DropdownFilter 
                    field='email'
                    label='User'
                    value={user}
                    prompt='Select email...'
                    onChange={handleChangeUser}
                    disabled={action === 'edit' ? true : false}
                    options={action === 'edit' ? [] : usersSortedByEmails}
                  />
                  <DropdownFilter 
                    field='name'
                    label='Role'
                    value={role}
                    options={roles}
                    prompt='Select role...'
                    onChange={handleChangeRole}
                  />
                </>
              ) : <></>}
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='15px auto 0 auto'
              >
                <HMButton 
                  type='button'
                  bgColor={gray}
                  margin='auto 10px auto 0'
                  handleClick={handleClose}
                  text={<Typography sx={textStyle}>Cancel!</Typography>}
                />
                <HMButton 
                  type='button'
                  color={white}
                  margin='auto 0 auto auto'
                  bgColor={
                    isApplicationStatus && confirmApproval === false ? red
                    : isApplicationStatus ? green
                    : (isAdd || (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() !== 'active')) ? green 
                    : isEdit ? blue 
                    : (isDelete || isDeleteSupplier || (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() === 'active')) && red
                  }
                  disabled={ 
                    (isApplicationStatus && disableApplicationStatusChange) ? true 
                    : (
                      !isDelete && 
                      !isDeleteSupplier && 
                      !isApplicationStatus && 
                      !isInTitle(title, 'activate', 'deactivate'
                      ) && (!role || !user)) ? true 
                    :  false
                  }
                  handleClick={
                    isAdd ? handleAddUser 
                    : isEdit ? handleEditUser 
                    : isApplicationStatus ? handleApplicationStatus
                    : isInTitle(title, 'activate', 'deactivate') ? handleActiveState
                    : isDeleteSupplier ? handleDeleteSupplier
                    : isDelete && handleDeleteUser
                  }
                  text={
                    <Typography sx={textStyle}>
                      {
                        isAdd ? 'Add' 
                        : isEdit ? 'Submit' 
                        : isApplicationStatus && confirmApproval === false ? 'Reject' 
                        : isApplicationStatus ? 'Confirm'
                        : (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() !== 'active') ? 'Yes, Activate!'
                        : (isInTitle(title, 'activate', 'deactivate') && supplier.status.toLowerCase() === 'active') ? 'Yes, Deactivate!'
                        : (isDelete || isDeleteSupplier) && 'Delete'
                      }
                    </Typography>
                  }
                />
              </HMBox>
            </HMBox>
          </HMModal>
          <Section
            bgColor={white}
            padding={10}
          >
            <Grid 
              container spacing={2} 
            >
              <Grid item xs={12} sm={3}>
                <HMBox 
                  width='100%'
                  display='flex'
                >
                  {supplier['logo'] ? (
                    <img 
                      alt={supplier.name} 
                      src={supplier.logo}
                      className='small-image'  
                      style={{margin: 'auto'}}
                    />
                  ) : (
                    <Grid className='small-logo'>
                      <HMText 
                        float='none'
                        width='100%'
                        margin='auto'
                        height='100%'
                        color={white}
                        fontSize='34px'
                        fontWeight={700}
                        textAlign='center'
                        padding='0 10px 0 8px'
                        bgColor={getRandomColor()}
                        text={supplier.name.substr(0,1).toUpperCase()}
                      />
                    </Grid>
                  )}
                  <HMBox 
                    width='100%'
                    height='100%'
                    padding='0px'
                    margin='auto auto auto 10px'
                  >
                    <Title 
                      width='100%'
                      textAlign='left'
                      margin='2px 0 0 0'
                      title={supplier.name}
                      size={fontSize - 2}
                    />
                    <HMText 
                      margin='auto'
                      padding='0 3px'
                      borderRadius={5}
                      text={supplier.type}
                      bgColor={lightBlue}
                      fontSize={`${fontSize - 2}px`}
                    />
                    <HMText 
                      margin='auto'
                      width='100%'
                      fontWeight={500}
                      padding='0 3px'
                      borderRadius={5}
                      fontSize={`${fontSize - 2}px`}
                      text={supplier.phone.length && stringifyPhoneNumber(supplier.phone[0])}
                    />
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <HMBox 
                  width='100%'
                  height='50px'
                  display='flex'
                >
                  <HMBox 
                    width='100%'
                    height='100%'
                    display='flex'
                    flexDirection={`${isTabletScreen ? 'row' : 'column'}`}
                    border={`${isTabletScreen && `1px solid ${lightBlue}`}`}
                  >
                    <HMText 
                      text='Supplier Markup'
                      margin={`${isTabletScreen ? 'auto 0' : 'auto'}`}
                    />
                    <Title 
                      color={blue}
                      padding='1px 5px'
                      borderRadius='5px'
                      bgColor={lightBlue}
                      size={fontSize - 2}
                      title={`${supplier.supplierMarkUp}%`}
                      margin={`${isTabletScreen ? 'auto 0 auto auto' : 'auto'}`}
                    /> 
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <HMBox 
                  width='100%'
                  height='50px'
                  display='flex'
                >
                  <HMBox 
                    height='100%'
                    display='flex'
                    margin='auto 0 auto auto'
                    width={isTabletScreen ? '100%' : 'auto'}
                    flexDirection={`${isTabletScreen ? 'row' : 'column'}`}
                    border={`${isTabletScreen && `1px solid ${lightBlue}`}`}
                  >
                    <HMText 
                      text='Online Markup'
                      margin={`${isTabletScreen ? 'auto 0' : 'auto'}`}
                    />
                    <Title 
                      color={blue}
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      bgColor={lightBlue}
                      title={`${supplier.onlineMarkUp}%`}
                      margin={`${isTabletScreen ? 'auto 0 auto auto' : 'auto'}`}
                    />
                  </HMBox>
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <HMBox
                  width='100%'
                  height='90%'
                  display='flex'
                  padding='10px'
                  className='fade-in'
                  bgColor={lightBlue}
                  margin='25px 0 10px 0'
                  flexDirection='column'
                  maxWidth={`${isTabletScreen && '100% !important'}`}
                >
                  <HMText 
                    left='0'
                    top='-35px'
                    width='100px'
                    height='30px'
                    bgColor={white}
                    fontWeight={500}
                    text='About Supplier'
                    className='fade-in'
                    position='relative'
                    margin='10px 0 -25px 10px'
                    padding='5px 10px 0px 10px'
                    border={`1px solid ${lightBlue}`}
                  />
                  <HMText
                    margin='auto 0'
                    text={supplier.about ? parse(supplier.about) : 'Your supplier description is not set!'}
                  />
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <HMText 
                  text='Users'
                  height='30px'
                  zIndex={999}
                  bgColor={white}
                  fontWeight={700}
                  className='fade-in'
                  margin='10px 0 -15px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMBox 
                  width='100%'
                  height='90%'
                  bgColor={lightBlue}
                  padding='20px 10px 0 10px'
                >
                  {supplier.users.map((user, index) => (
                    <Grid
                      key={index}
                      container spacing={0}
                    >
                      {index === 0 ? (
                        <>
                          <Grid item xs={8}>
                            <Typography
                              sx={textStyle}
                            >Name</Typography>
                          </Grid>
                          <Grid item xs={supplier.users.length > 1 ? 2 : 4}>
                            <Typography
                              sx={{...textStyle, textAlign: 'center'}}
                            >Role</Typography>
                          </Grid>
                          {supplier.users.length > 1 && (
                            <Grid item xs={2}>
                              <Typography
                                sx={{...textStyle, textAlign: 'right'}}
                              >Action</Typography>
                            </Grid>
                          )}
                        </>
                      ) : <></>}
                      <Grid item xs={8} sx={{borderTop: `1px solid ${white}`}}>
                        <HMBox 
                          width='100%'
                          height='100%'
                          display='flex'
                        >
                          {user.image ? (
                            <Link  to={`/admin/users/${user._id}`} style={{ color: darkBlue, margin: 'auto 0'}}>
                              <img 
                                src={user.image}
                                className='mini-image'  
                                style={{margin: 'auto'}}
                                alt={`${user.firstName} ${user.lastName}`}
                              />
                            </Link>
                          ) : (
                            <Link  to={`/admin/users/${user._id}`} style={{ color: darkBlue, margin: 'auto 0'}}>
                              <Grid className='mini-logo'>
                                <HMText 
                                  float='none'
                                  width='100%'
                                  margin='auto'
                                  height='100%'
                                  color={white}
                                  fontSize='22px'
                                  fontWeight={700}
                                  textAlign='center'
                                  padding='0 10px 0 8px'
                                  bgColor={getRandomColor()}
                                  text={user.lastName.substr(0,1).toUpperCase()}
                                />
                              </Grid>
                            </Link>
                          )}
                          <HMBox 
                            width='100%'
                            height='100%'
                            margin='auto'
                          >
                            <Link  to={`/admin/users/${user._id}`} style={{ color: darkBlue}}>
                              <Title 
                                width='100%'
                                textAlign='left'
                                margin='2px 0 0 0'
                                size={fontSize - 2}
                                title={`${user.firstName} ${user.lastName}`}
                              />
                            </Link>
                            <HMText 
                              margin='auto'
                              padding='0 3px'
                              bgColor={white}
                              borderRadius={5}
                              text={user.email}
                              fontSize={`${fontSize - 2}px`}
                            />
                          </HMBox>
                        </HMBox>
                      </Grid>
                      <Grid item xs={supplier.users.length > 1 ? 2 : 4} sx={{borderTop: `1px solid ${white}`}}>
                        <HMBox
                          padding='0'
                          width='100%'
                          height='100%'
                          display='flex'
                        >
                          <Typography sx={{...textStyle, margin: 'auto', textAlign: 'centre'}}>
                            {userRole(user.accessLevel)}
                          </Typography>
                        </HMBox>
                      </Grid>
                      {supplier.users.length > 1 && (
                        <Grid item xs={2} sx={{borderTop: `1px solid ${white}`}}>
                          <HMBox
                            padding='0'
                            width='100%'
                            height='100%'
                            display='flex'
                            flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
                          >
                            <EditIconButton 
                              margin={`${isTabletScreen ? 'auto 10px auto auto !important' : 'auto 0 auto auto !important'}`}
                              handleEdit = {() => handleEditUserModal(user)} 
                            />
                            <DeleteIcon 
                              margin={`${isTabletScreen ? 'auto 10px auto auto !important' : 'auto 0 auto 10px !important'}`}
                              handleDelete = {() => handleDeleteUserModal(user)} 
                            />
                          </HMBox>
                        </Grid>
                      )}
                      {(index === supplier.users.length -1 && (
                        !supplier.applicationStack.length || (supplier.applicationStack.length && applicationStatus === 'Approved')
                        )) ? (
                        <Grid item xs={12} sx={{borderTop: `1px solid ${white}`}}>
                          <HMBox 
                            width='100%' 
                            display='flex' 
                          >
                            <IconButton   
                              onClick={handleAddUserModal} 
                              sx={{margin: 'auto auto 5px auto'}}
                            >
                              <AddIcon sx={{color: green}} />
                            </IconButton>
                          </HMBox>
                        </Grid>
                      ) : <></>}
                    </Grid>
                  ))}
                </HMBox>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginBottom: '30px' }}>
                <HMText 
                  height='30px'
                  zIndex={999}
                  bgColor={white}
                  fontWeight={700}
                  className='fade-in'
                  text='Supplier Address'
                  margin='10px 0 -15px 20px'
                  padding='5px 10px 0px 10px'
                  border={`1px solid ${lightBlue}`}
                />
                <HMBox 
                  width='100%'
                  height='100%'
                  display='flex'
                  bgColor={lightBlue}
                  padding='30px 10px 0 10px'
                >
                  {(supplier.address && Object.keys(supplier.address).length) ? (
                    <Grid
                      container spacing={0.5} 
                    >
                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >Province</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.province }</Typography>
                      </Grid>

                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >District</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.district }</Typography>
                      </Grid>

                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >Sector</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.sector }</Typography>
                      </Grid>

                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >Cell</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.cell }</Typography>
                      </Grid>
                      
                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >Village</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.village }</Typography>
                      </Grid>

                      <Grid item xs={8} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={textStyle}
                        >Street &#38; House No.</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.street }</Typography>
                      </Grid>
                      <Grid item xs={12} sx={{borderBottom: `1px solid ${white}`}}>
                        <Typography
                          sx={{...textStyle, textAlign: 'left'}}
                        >{ supplier.address.mapAddress }</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <HMText
                      margin='auto 0'
                      text='No address set'
                    />
                  )}
                </HMBox>
              </Grid>
              {(supplier.type && supplier.type.toLowerCase() === supplierTypes[0].toLowerCase() && supplier.license) ? (
                <Grid item xs={12} sm={6} sx={{ marginBottom: '30px' }}>
                  <HMText 
                    height='30px'
                    zIndex={999}
                    bgColor={white}
                    fontWeight={700}
                    className='fade-in'
                    text='License Info'
                    margin='10px 0 -15px 20px'
                    padding='5px 10px 0px 10px'
                    border={`1px solid ${lightBlue}`}
                  />
                  <HMBox
                    width='100%'
                    height='100%'
                    display='flex'
                    bgColor={lightBlue}
                    flexDirection='column'
                  >
                    <HMBox
                      width='100%'
                      margin='20px auto auto auto'
                      display='flex'
                      flexDirection='row'
                    >
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text='Issue Date:'
                      />
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text={moment(supplier.license.issueOn).utc().format('MMM DD, YYYY')}
                      />
                    </HMBox>
                    <HMBox
                      width='100%'
                      margin='auto'
                      display='flex'
                      flexDirection='row'
                    >
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text='Expiration Date:'
                      />
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text={moment(supplier.license.expiresOn).utc().format('MMM DD, YYYY')}
                      />
                    </HMBox>
                    <HMBox
                      width='100%'
                      margin='auto'
                      display='flex'
                      flexDirection='row'
                    >
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text='Pharmacist:'
                      />
                      <HMText
                        width='50%'
                        margin='auto auto auto 0'
                        text={`${supplier.license.pharmacist.firstName} ${supplier.license.pharmacist.lastName}`}
                      />
                    </HMBox>
                    <HMBox
                      width='100%'
                      margin='auto'
                      display='flex'
                      flexDirection='row'
                    >
                      <a href={supplier.license.image} target='_blank' rel='noreferrer' style={{width: '100%'}}>
                        <HMButton 
                          width='100%'
                          type='button'
                          padding='6px'
                          margin='auto'
                          bgColor={orange}
                          paddingActive='5.5px'
                          text={<Typography sx={textStyle}>View Certificate</Typography>}
                        />
                      </a>
                    </HMBox>
                  </HMBox>
                </Grid>
              ) : <></>}
              {supplier.phone.length > 1 ? (
                <Grid item xs={12} sm={6} sx={{ marginBottom: '30px' }}>
                  <HMBox
                    width='100%'
                    height='90%'
                    display='flex'
                    padding='10px'
                    className='fade-in'
                    bgColor={lightBlue}
                    margin='25px 0 10px 0'
                    flexDirection='column'
                    maxWidth={`${isTabletScreen && '100% !important'}`}
                  >
                    <HMText 
                      left='0'
                      top='-35px'
                      width='130px'
                      height='30px'
                      bgColor={white}
                      fontWeight={500}
                      text='Additonal phone'
                      className='fade-in'
                      position='relative'
                      margin='10px 0 -25px 10px'
                      padding='5px 10px 0px 10px'
                      border={`1px solid ${lightBlue}`}
                    />
                    {supplier.phone.map((phone, index) => {
                      return index > 0 && (
                        <HMText
                          key={index}
                          margin='auto 0'
                          text={stringifyPhoneNumber(phone)}
                        />
                      )
                    })}
                  </HMBox>
                </Grid>
              ) : <></>}
            </Grid>
          </Section>
        </>
      ) : <></>}
    </Page>
  )
}

export default Supplier
import React from 'react'
import Slider from '@mui/material/Slider'
import HMTextField from './HMTextField'
import HMText from './HMText'
import HMBox from '../layout/HMBox'
import {
    white
} from '../../../hooks/useColors'

function HMPriceFilter(props) {
    return (
        <HMBox
            display='flex'
            margin='auto 0 auto auto'
            width={props.width || '100%'}
            className='HM-price-filter'
        >
            <HMTextField 
                type='number'
                margin='auto'
                name='minPrice'
                className='min'
                width={
                    (props.priceRange[0].toString().length === 1  || 
                    props.priceRange[0].toString().length === 1 ) ? '120px'
                    : props.priceRange[0].toString().length === 2 ? '140px'
                    : props.priceRange[0].toString().length === 3 ? '160px'
                    : props.priceRange[0].toString().length === 4 ? '180px'
                    : props.priceRange[0].toString().length === 5 ? '200px'
                    : props.priceRange[0].toString().length === 6 ? '220px'
                    : props.priceRange[0].toString().length === 7 ? '240px'
                    : props.priceRange[0].toString().length === 8 ? '260px'
                    : props.priceRange[0].toString().length === 9 ? '280px'
                    : props.priceRange[0].toString().length === 10 ? '300px'
                    : 'max-content'
                }
                value={props.priceRange[0]}
                onChange={props.handleMinChange}
            />
            <HMText 
                text='Rwf'
                bgColor={white}
                fontWeight={500}
                padding='6.5px 5px'
                position='relative'
                borderTopLeftRadius='0px'
                borderBottomLeftRadius='0px'
                margin='auto auto auto -35px'
                borderTopRightRadius='inherit'
                borderBottomRightRadius='inherit'
            />
            <HMBox 
                padding='0'
                width='100%'
                margin='auto 15px'
                zIndex={999}
            >
                <Slider
                    disableSwap
                    min={props.min}
                    max={props.max}
                    className='slider'
                    value={props.priceRange}
                    onChange={props.handlePriceRangeChange}
                />
            </HMBox>
            <HMTextField 
                type='number'
                margin='auto'
                name='maxPrice'
                className='max'
                width={
                    (props.priceRange[1].toString().length === 1  || 
                    props.priceRange[1].toString().length === 1 ) ? '120px'
                    : props.priceRange[1].toString().length === 2 ? '140px'
                    : props.priceRange[1].toString().length === 3 ? '160px'
                    : props.priceRange[1].toString().length === 4 ? '180px'
                    : props.priceRange[1].toString().length === 5 ? '200px'
                    : props.priceRange[1].toString().length === 6 ? '220px'
                    : props.priceRange[1].toString().length === 7 ? '240px'
                    : props.priceRange[1].toString().length === 8 ? '260px'
                    : props.priceRange[1].toString().length === 9 ? '280px'
                    : props.priceRange[1].toString().length === 10 ? '300px'
                    : 'max-content'
                }
                value={props.priceRange[1]}
                onChange={props.handleMaxChange}
            />
            <HMText 
                text='Rwf'
                bgColor={white}
                fontWeight={500}
                padding='6.5px 5px'
                position='relative'
                borderTopLeftRadius='0px'
                borderBottomLeftRadius='0px'
                margin='auto auto auto -35px'
                borderTopRightRadius='inherit'
                borderBottomRightRadius='inherit'
            />
        </HMBox>
    )
}

export default HMPriceFilter
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  getMyStore,
} from '../../features/stores/storeSlice'
import { 
  resetOrder,
  getStoreOrders
} from '../../features/orders/orderSlice'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import DataTable from '../../components/common/layout/DataTable'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMButton from '../../components/common/tools/HMButton'
import Title from '../../components/common/tools/Title'
import {
  iconStyle,
  textStyle,
} from '../../components/common/tools/Styles'
import {
  white,
  orange,
  lightBlue,
} from '../../hooks/useColors'

function Sales() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const storeState = useSelector((state) => state.store)
  const { myStores } = storeState
  const orderState = useSelector((state) => state.order)
  const { storeOrders, areStoreOrders, todayStoreOrders } = orderState

  const [sales, setSales] = useState([])
  const [doneSetting, setDoneSetting] = useState(false)

  const isPOS = window.location.pathname.toLowerCase().includes('pos') ? true : false

  useEffect(() => {
    if (user) {
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }

      if (!isPOS) {
        dispatch(getStoreOrders(storeId)) 
      } 
    }
    // eslint-disable-next-line
  }, [user, storeId, myStores])

  useEffect(() => {
    if (areStoreOrders) {
      const tempSales = []
      dispatch(resetOrder())
      storeOrders.forEach(order => {
        const orderDetail = order.orderDetails[0]
        const tempSale = {
          _id: order._id,
          date: order.createdAt,
          status: orderDetail.status,
          total: orderDetail.subtotal,
          orderNumber: order.orderNumber,
          quantity: orderDetail.quantity,
          paymentMethod: order.paymentInfo.method,
          client: `${order.user.firstName} ${order.user.lastName}`,
          paymentStatus: order.paymentInfo.paid ? 'Paid' : 'Unpaid',
        }

        tempSales.push(tempSale)
      })
      setSales(tempSales)
      setDoneSetting(true)
    }
    // eslint-disable-next-line
  }, [areStoreOrders])

  const fields = []
  const columns = [
    { field: 'orderNumber', headerName: 'Sales Number', sortable: true },
    { field: 'date', headerName: 'Sale Date', sortable: false },
    { field: 'client', headerName: 'Client', sortable: true },
    { field: 'total', headerName: 'Order Total', sortable: true },
    { field: 'quantity', headerName: 'Quantity', sortable: true },
    { field: 'paymentMethod', headerName: 'Pay Method', sortable: true },
    { field: 'paymentStatus', headerName: 'Pay Status', sortable: true },
    { field: 'status', headerName: 'Status', sortable: true },
  ]

  columns.forEach((column) => {
    fields.push(column.field)
  })

  return user ? (
    <Page>
      {(orderState.isLoading) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <Title 
          title='Sales' 
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <Section 
        padding={10} 
        bgColor={white}
      >
        {(doneSetting || isPOS) ? (
          <DataTable
            title='Sales'
            isPOS={isPOS}
            addStyle={true}
            viewOnly={true}
            fields={fields}
            columns={columns}
            disableEdit={true}
            defaultRowsNum={20}
            searchBgColor={lightBlue}
            viewLink={isPOS ? 'online' : 'sales'}
            data={isPOS ? todayStoreOrders : sales}
            emptyDataMessage={isPOS ? 'No online sales yet for today!' : 'No sales available!'}
          />
        ) : <></>}
      </Section>
    </Page>
  ) : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default Sales
import React from 'react'
import Link from '@mui/material/Link'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import HMBox from './HMBox'
import HMStoreItem from './HMStoreItem'
import HMCheckbox from '../tools/HMCheckbox'
import HMText from '../tools/HMText'
import {
    fontSize
} from '../tools/Styles'
import {
    blue,
    white,
    orange,
    lightBlack,
    skeletonsLightBlue,
} from '../../../hooks/useColors'
import {
    currencies,
} from '../../../hooks/helperFunctions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMStoreData(props) {
    const storeData = props.storeData
    const items = props.isCart ? storeData.cartItems : storeData.orderItems
    const {
        windowW,
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    return (
        <HMBox
            width='100%'
            display='flex'
            margin='0 0 10px 0'
            className='fade-in'
            flexDirection='column'
            bgColor={props.bgColor || skeletonsLightBlue}
        >
            <HMBox
                width='100%'
                display='flex'
                margin='auto 0'
                padding='0 5px 5px 2.5px'
            >
                <HMCheckbox 
                    margin='auto 5px auto 0'
                    checked={props.isStoreChecked}
                    handleChange={props.handleStoreCheckboxChange} 
                />
                <Link
                    onClick={props.navegateToStorePage}
                    sx={{ margin: 'auto 0', cursor: 'pointer' }}
                >
                    <HMText
                        color={blue}
                        margin='auto 10px auto 0'
                        fontSize={`${fontSize}px`}
                        text={storeData.storeName}
                    />
                </Link>
                <HMText
                    color={white}
                    padding='0 10px'
                    margin='auto 0 auto 5px'
                    text={storeData.storeType}
                    fontSize={`${fontSize - 1}px`}
                    bgColor={storeData.storeType.toLowerCase() === 'pharmacy' ? blue : orange}
                />
                {!isTabletScreen && storeData.deliveryOption ? (
                    <>
                        <AccessTimeTwoToneIcon 
                            sx={{
                                fontSize: `${fontSize}px`,
                                margin: 'auto 0 auto auto',
                            }}
                        />
                        <HMText
                            color={blue}
                            margin='auto 0 auto 5px'
                            fontSize={`${fontSize - 2}px`} 
                            text={storeData.deliveryOption.duration}
                        />
                        <HMText
                            text='|'
                            margin='auto 0'
                            padding='0 10px'
                            fontSize={`${fontSize - 1}px`}
                        />
                    </>
                ) : <></>}
                {storeData.deliveryOption ? (
                    <HMText
                        color={lightBlack}
                        fontSize={`${fontSize - 1}px`}
                        margin={isTabletScreen ? 'auto 0 auto auto' : 'auto 0'}
                        text={isTabletScreen ? `${storeData.deliveryOption.type} delivery` : storeData.deliveryOption.type}
                    />
                ) : <></>}
            </HMBox>
            {items.map((item, index) => (
                <HMStoreItem 
                    key={index}
                    item={item}
                    isCart={props.isCart}
                    isLoading={props.isLoading}
                    isOperator={props.isOperator}
                    updatingListing={props.updatingListing}
                    handleAddInfoModal={() => props.handleAddInfoModal(item)}
                    handleQuantityReduce={() => props.handleQuantityReduce(item)}
                    navegateToProductPage={() => props.navegateToProductPage(item)}
                    handleQuantityChange={(e) => props.handleQuantityChange(e, item)}
                    handleQuantityIncrease={() => props.handleQuantityIncrease( item)} 
                    handlePrescriptionModal={() => props.handlePrescriptionModal(item)}
                    handleItemCheckboxChange={(e) => props.handleItemCheckboxChange(e, item.listing)}
                />
            ))}
            <HMBox
                width='100%'
                display='flex'
            >
                <HMText
                    color={lightBlack}
                    margin='auto 0 auto auto'
                    fontSize={`${fontSize - 1}px`}
                    text={`${storeData.quantity} items, Subtotal:`}
                />
                &nbsp;
                <HMText
                    color={orange}
                    fontWeight={500}
                    margin='auto 10px auto 0'
                    fontSize={`${fontSize}px`}
                    text={`${currency} ${storeData.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
            </HMBox>
        </HMBox>
    )
}

export default HMStoreData
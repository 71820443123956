import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    blue,
    white,
} from '../../../hooks/useColors'
import {
    hideSidebar,
    showSidebar,
    showSidebarForSmall
} from '../../../features/nav/navSlice'

function HMMenuIcon(props) {
    const dispatch = useDispatch()
    const {
        visible,
    } = useSelector((state) => state.nav)

    const menuStyle = {
        cursor: 'pointer',
        color: props.color || blue,
        padding: props.padding || '3px',
        margin: props.margin || 'auto 5px',
        fontSize: props.fontSize || '24px',
        borderRadius: props.borderRadius || '5px',
        border: props.border || `1.5px solid ${blue}`,
        '&:hover': {
            color: props.hoverColor || white,
            backgroundColor: props.hoverBgColor || blue
        },
        '&:active': {
            opacity: 0.8,
            fontSize: props.activeFontSize || '23px',
        }
    }

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 768 ? true : false

    const toggleSideBar = () => {
        if (visible && !isTabletScreen) {
            dispatch(hideSidebar())
        } else if(!visible && !isTabletScreen) {
            dispatch(showSidebar())
        } else if (!visible && isTabletScreen) {
            dispatch(showSidebarForSmall())
        }
    }

    return (
        <MenuRoundedIcon sx={menuStyle} onClick={toggleSideBar} />
    )
}

export default HMMenuIcon
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMProductCard from '../../components/common/layout/HMProductCard'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  getMatchingCategory
} from '../../hooks/helperFunctions'
import {
  fontSize
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  blue,
  gray,
  white,
  orange,
  darkBlue,
  lightBlue,
  lightOrange,
} from '../../hooks/useColors'

function PublicCategories() {
  const { parent } = useParams()
  const navigate = useNavigate()
  const {
    visible
  } = useSelector((state) => state.nav)
  const { 
    publicCategories,
  } = useSelector((state) => state.category)
  const { 
      publicProducts,
  } = useSelector((state) => state.productsWithStoreListings)
    
  const [isValidParent, setisValidParent] = useState(false)
  const [showingCategories, setShowingCategories] = useState([])

  const {
      windowW
  } = useWindowDimensions()

  const isBiggerComputerScreen = windowW < 2100 ? true : false
  const isBigComputerScreen = windowW < 1900 ? true : false
  const isMediumComputerScreen = windowW < 1170 ? true : false
  const isSamllComputerScreen = windowW < 1000 ? true : false
  const isTabletScreen = windowW < 768 ? true : false
  const isSmallTabletScreen = windowW < 670 ? true : false
  
  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Categories', link: null }
  ]

  useEffect(() => {
    if (publicCategories.length) {
      if (parent && publicCategories.find(cat => cat._id === parent)) {
        setisValidParent(true)
        setShowingCategories(publicCategories.find(cat => cat._id === parent).children)
      } else {
        setShowingCategories(publicCategories[0].children)
      }
    }

  }, [publicCategories, parent])

  
  const handleChoosedCategory = (e, category) => {
    setShowingCategories(category.children)
    const categoryClass = document.getElementsByClassName('class')
    for (let i = 0; i < categoryClass.length; i++) {
        categoryClass[i].classList.remove('active')
    } 
    e.target.classList.add('active')
  }

  const handleProductClick = (product) => {
    navigate(`/categories/${product.category.id.parent._id}/${product.category.id._id}/${product._id}`)
  }

  const navigateToCategoryPage = (category) => {
    const cat = getMatchingCategory(category._id, publicCategories)
    navigate(`/categories/${cat.parent.id}/${cat.id}`)
  }

  return (
    <Page>
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
      </FullWidthContainer>
      <Section
        padding={10}
        bgColor={white}
      >
        <HMBox 
          width='100%'
          display='flex'
          padding='20px 0px'
          flexDirection='column'
        >
          <Title 
            width='100%'
            color={darkBlue}
            margin='20px auto'
            size={fontSize + 4}
            title='All Categories'
          />
          {(publicCategories.length) ? (
            <HMBox 
              margin='auto'
              display='flex'
              padding='20px 0px'
              width={isTabletScreen ? '100%' : 'max-content'}
            >
              {publicCategories.map((category, index) => (
                <Box key={index} sx={{margin: 'auto'}}>
                  <HMText 
                    color={blue}
                    cursor='pointer'
                    borderRadius='20'
                    padding='5px 10px'
                    hoverColor={white}
                    hoverBgColor={blue}
                    bgColor={lightBlue}
                    activeColor={white}
                    activeBgColor={orange}
                    margin={isTabletScreen ? 'auto' : 'auto 5px'}
                    text={
                      isTabletScreen && category.name.toLowerCase().includes('otc') ? 'OTC' 
                      : isTabletScreen && category.name.toLowerCase().includes('health') ? 'Health Products' 
                      : isTabletScreen && category.name.toLowerCase().includes('prescription') ? 'Prescription Meds' 
                      : category.name
                    }
                    className={
                      isValidParent ? `category class ${parent === category._id && 'active'}` 
                      : `category class ${index === 0 && 'active'}`
                    }
                    handleClick={(e) => handleChoosedCategory(e, category)}
                  />
                </Box>
              ))}
            </HMBox>
          ) : (
              <HMText 
                  width='100%'
                  text='loading...'
              />
          )}
        </HMBox>
        {(showingCategories.length && publicProducts.length) ? 
          showingCategories.map((category, catIndex) => (
            <Box 
              key={catIndex}
              sx={{width: '100%', marginBottom: '20px'}}
            >
              {publicProducts.find(data => data.product.category.id._id === category._id) ? (
                <>
                  <Title 
                    width='100%'
                    size={fontSize}
                    textAlign='left'
                    fontWeight={500}
                    color={darkBlue}
                    margin='5px auto'
                    title={category.name}
                  />
                  <Divider sx={{ margin: '20px auto', width: '100%', borderBottomWidth: '1px', color: gray, opacity: 0.5 }} />
                </>
              ) : <></>}
              <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                {publicProducts.filter(data => data.product.category.id._id === category._id).map((data, prodIndex) => {
                  return prodIndex < 13 ? (
                    <Grid 
                      item 
                      xs={6} 
                      key={prodIndex}
                      sx={{display: 'flex'}}
                      sm={
                          isSmallTabletScreen ? 4 
                          : isTabletScreen ? 3  
                          : (isSamllComputerScreen && visible) ? 4
                          : ((isMediumComputerScreen && visible) || isSamllComputerScreen) ? 3
                          : ((isBiggerComputerScreen && visible) || isBigComputerScreen) ? 2 
                          : 1
                      }
                    >
                      <HMProductCard 
                        className='fade-in'
                        isStockAvailable={true}
                        title={data.product.name}
                        image={data.product.image}
                        handleClick={() => handleProductClick(data.product)}
                        price={data.storesAndListings[0].storeListing.onlinePrice}
                      />
                    </Grid>
                  ) : prodIndex === 13 ? (
                    <Grid item key={prodIndex} xs={12} sm={12}>
                      <HMButton 
                        margin='auto'
                        color={orange}
                        borderRadius={25}
                        padding='5px 10px'
                        text='View more...'
                        bgColor={lightOrange}
                        borderColor={lightOrange}
                        paddingActive='4.5px 9.5px'
                        fontSize={`${fontSize - 2}px`}
                        handleClick={() => navigateToCategoryPage(category)}
                      />
                    </Grid>
                  ) : <></>
                })}
              </Grid>
            </Box>
          ))
        : (
            <HMText 
                width='100%'
                text='loading...'
            />
        )}
      </Section>
    </Page>
  )
}

export default PublicCategories
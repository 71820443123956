import React from 'react'
import Box from '@mui/material/Box'
import HMBox from '../../components/common/layout/HMBox'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import { 
    fontSize,
} from '../../components/common/tools/Styles'
import {
    darkBlue,
    lightBlue,
    red,
    blue,
    green,
    white,
    orange,
    lightRed,
    lightBlack,
} from '../../hooks/useColors'
import { 
  currencies,
  computateOrderData
} from '../../hooks/helperFunctions'

function OrderOfficialReceipt({ sale }) {
    const tax = 0
    const discount = 0
    const storeId = sale.orderDetails[0].store
    const approvedInsurance = sale.orderDetails[0].approvedInsurance
    const {
        total,
        subtotal,
        deliveryCost,
        insuranceCoverage
    } = computateOrderData(sale)
    const currency = currencies.find(curr => curr.country === 'Rwanda').currency
    const coverage = (sale.insuranceInfo && approvedInsurance) ? sale.insuranceInfo.storeValidation[storeId].coverage : 0 

    const storeTotal = (approvedInsurance ? (total - insuranceCoverage) : total) - deliveryCost

    return (
        <>
            {sale.orderDetails[0].orderItems.map((item, index) => {
                const price = (approvedInsurance && item.usedInsurance) ? item.insurancePrice : item.onlinePrice
                return (
                    <HMBox
                        key={index}
                        width='100%'
                        display='flex'
                        margin='0 0 5px 0'
                        bgColor={lightBlue}
                        padding='7.5px 0 5px 2.5px'
                    >
                        <img 
                            alt={item.productName}
                            className='small-image'
                            src={item.productImage}
                            style={{margin: 'auto 0'}}
                        />
                        <HMBox
                            height='100%'
                            display='flex'
                            margin='auto 0'
                            padding='0 10px'
                            flexDirection='column'
                        >
                            <HMText
                                fontWeight={500}
                                color={lightBlack}
                                margin='auto auto 0 0'
                                text={item.productName}
                                fontSize={`${fontSize - 1}px`}
                            />
                            <HMBox
                                padding='0'
                                display='flex'
                                margin='0 auto auto 0'
                            >
                                <HMText 
                                    margin='auto 0'
                                    text='Quantity'
                                    fontWeight={500}
                                    color={lightBlack}
                                    fontSize={`${fontSize - 2}px`}
                                />  
                                <HMText 
                                    color={lightBlack}
                                    margin='auto auto auto 5px'
                                    text={`( ${item.quantity} )`}
                                    fontSize={`${fontSize - 2}px`}
                                />
                                {(approvedInsurance && item.usedInsurance) ? (
                                    <HMText 
                                        color={white}
                                        bgColor={blue}
                                        padding='0 5px'
                                        text= 'Insurance Applied'
                                        margin='auto auto auto 5px'
                                        fontSize={`${fontSize - 2}px`}
                                    />
                                ) : <></>}
                            </HMBox>
                        </HMBox>
                        <HMText
                            color={orange}
                            fontWeight={500}
                            margin='auto 10px auto auto'
                            fontSize={`${fontSize - 1}px`}
                            text={`${currency} ${(price * item.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    </HMBox>
                )
            })}
            <HMBox
                width='100%'
                display='flex'
                margin='5px auto'
                className='fade-in'
                bgColor={lightBlue}
                flexDirection='column'
            >
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <Title
                        margin='auto 0'
                        size={fontSize}
                        title='Subtotal'
                        color={darkBlue}
                        className='fade-in'
                    />
                    <Title
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        title={`${currency} ${subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <HMText
                        margin='auto 0'
                        text='Discount'
                        color={darkBlue}
                        className='fade-in'
                        fontSize={`${fontSize - 2}px`}
                    />
                    <HMText
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 2}px`}
                        text={`${currency} ${discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                    padding={coverage > 0 ? '0 5px 5px 5px' : '0 5px'}
                >
                    <HMText
                        text='Tax'
                        margin='auto 0'
                        color={darkBlue}
                        className='fade-in'
                        fontSize={`${fontSize - 2}px`}
                    />
                    <HMText
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize - 2}px`}
                        text={`${currency} ${tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
                {insuranceCoverage > 0 ? (
                    <HMBox
                        width='100%'
                        display='flex'
                        className='fade-in'
                    >
                        <HMText
                            margin='auto 0'
                            color={darkBlue}
                            className='fade-in'
                            fontSize={`${fontSize - 2}px`}
                            text={`Insurance Coverage ( ${sale.paymentInfo.method.toLowerCase() === 'insurance' ? coverage : 0}% )`} 
                        />
                        <HMText
                            color={darkBlue}
                            className='fade-in'
                            margin='auto 0 auto auto'
                            fontSize={`${fontSize - 2}px`}
                            text={`${currency} ${insuranceCoverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                    </HMBox>
                ) : <></>}
                <Box 
                    sx={{
                        opacity: 0.3,
                        height: '1px',
                        margin: '5px 0',
                        backgroundImage: 'repeating-linear-gradient(to right, transparent, transparent 5px, #000 5px, #000 10px)'
                    }}
                >
                </Box>
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                >
                    <Title
                        title='Total'
                        margin='auto 0'
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                    />
                    <Title
                        size={fontSize}
                        color={darkBlue}
                        className='fade-in'
                        margin='auto 0 auto auto'
                        title={`${currency} ${storeTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    />
                </HMBox>
            </HMBox>
        </>
    )
}

export default OrderOfficialReceipt
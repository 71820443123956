import {
    createSlice, 
    createAsyncThunk
} from '@reduxjs/toolkit'
import categoryService from './categoryService'

// Get categories from local storage
const categories = JSON.parse(localStorage.getItem('categories')) || []
const subCategories = JSON.parse(localStorage.getItem('subCategories')) || []
const publicCategories = JSON.parse(localStorage.getItem('publicCategories')) || []

const initialState = {
    message: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isSubSuccess: false,
    isBulkDeleted: false,
    isPublicSuccess: false,
    categories: categories.length ? categories : [],
    subCategories: subCategories.length ? subCategories : [],
    publicCategories: publicCategories.length ? publicCategories : [],
}

// Create a new category
export const createCategory = createAsyncThunk(
    'categories/create',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await categoryService.createCategory(data, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update a category
export const updateCategory = createAsyncThunk(
    'categories/update',
    async (category, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await categoryService.updateCategory(category, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele a category
export const deleteCategory = createAsyncThunk(
    'categories/delete',
    async (categoryID, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await categoryService.deleteCategory(categoryID, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delele a categories
export const deleteCategories = createAsyncThunk(
    'categories/bulkDelete',
    async (categoryIds, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await categoryService.deleteCategories(categoryIds, token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get categories
export const getCategories = createAsyncThunk(
    'categories/all',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await categoryService.getCategories(token)
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get categories
export const getPublicCategories = createAsyncThunk(
    'publicCategories/all',
    async (_, thunkAPI) => {
        try {
            return await categoryService.getPublicCategories()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all only sub categories
export const getSubCategories = createAsyncThunk(
    'subCategories/all',
    async (_, thunkAPI) => {
        try {
            return await categoryService.getSubCategories()
        } catch (error) {
            const message = (
                error.response && error.response.data && error.response.data.message
            ) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        resetCategory: (state) => {
            state.message = ''
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.isUpdated = false
            state.isDeleted = false
            state.isCreated = false
            state.isSubSuccess = false
            state.isBulkDeleted = false
            state.isPublicSuccess= false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCategory.pending, (state) => {
                state.isLoading = true
                state.isCreated = false
                state.isError = false
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreated = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isCreated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateCategory.pending, (state) => {
                state.isLoading = true
                state.isUpdated = false
                state.isError = false
            })
            .addCase(updateCategory.fulfilled, (state) => {
                state.isLoading = false
                state.isUpdated = true
                state.isError = false
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isUpdated = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteCategory.pending, (state) => {
                state.isLoading = true
                state.isDeleted = false
                state.isError = false
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isDeleted = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteCategories.pending, (state) => {
                state.isLoading = true
                state.isBulkDeleted = false
                state.isError = false
            })
            .addCase(deleteCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isBulkDeleted = true
                state.isError = false
                state.message = action.payload.message
            })
            .addCase(deleteCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isBulkDeleted = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.categories = action.payload
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getPublicCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPublicCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isPublicSuccess = true
                state.publicCategories = action.payload
            })
            .addCase(getPublicCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSubCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSubSuccess = true
                state.subCategories = action.payload
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.message = action.payload
            })
    }
})

export const { resetCategory } = categorySlice.actions
export default categorySlice.reducer
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { toast } from 'react-toastify'
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import PublishIcon from '@mui/icons-material/Publish'
import AddIcon from '@mui/icons-material/Add'
import { reset } from '../../../features/import/importSlice'
import { 
  resetFaqCategory,
  getFaqCategories, 
  createFaqCategory, 
  updateFaqCategory, 
  deleteFaqCategory, 
} from '../../../features/faqCategories/faqCategorySlice'
import CategoryForm from '../../../components/category/CategoryForm'
import FullWidthContainer from '../../../components/common/layout/FullWidthContainer'
import DataTable from '../../../components/common/layout/DataTable'
import HMModal from '../../../components/common/layout/HMModal'
import HMBox from '../../../components/common/layout/HMBox'
import Page from '../../../components/common/layout/Page'
import HMButton from '../../../components/common/tools/HMButton'
import Spinner from '../../../components/common/tools/Spinner'
import Title from '../../../components/common/tools/Title'
import ReactCSV from '../../../components/common/tools/ReactCSV'
import {
  iconStyle,
  textStyle,
  errorStyle,
  deleteTextStyle,
  deleteItemTextStyle
} from '../../../components/common/tools/Styles'
import { 
  red,
  blue, 
  gray,
  green,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'

function FaqCategory() {
  const {
    message,
    isError,
    isCreated,
    isUpdated,
    isDeleted,
    isSuccess,
    publiFaqCategories,
    isBulkDeleted,
  } = useSelector(state => state.faqCategory)

  const { importData } = useSelector(state => state.import)
  
  const dispatch = useDispatch() 
  
  useEffect(() => {
    if (isSuccess || isError || isCreated || isUpdated || isDeleted || isBulkDeleted) {
      dispatch(resetFaqCategory())
    }
    
    if (isError) {
      toast.error(message)
    } else if (isCreated) {
      toast.success(message)
      dispatch(getFaqCategories())
    } else if (isUpdated) {
      dispatch(getFaqCategories())
      toast.success('Category updated successfully')
    } else if (isDeleted) {
      toast.success('Category deleted successfully')
      dispatch(getFaqCategories())
    } else if (isBulkDeleted) {
      toast.success(message)
      dispatch(getFaqCategories())
    }
    
  }, [dispatch, isSuccess, isError, message, isCreated, isUpdated, isDeleted, isBulkDeleted])
  
  useEffect(() => {
    dispatch(getFaqCategories())
  }, [dispatch])
  
  const fields = []

  const columns = [
    { field: 'name', headerName: 'Name', sortable: true},
    { field: 'definition', headerName: 'Definition', sortable: true },
  ] 
  
  columns.forEach((column) => {
    fields.push(column.field)
  })

  const supportedFiles = {
    'text/csv': [],
  }

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const [isBulkDelete, setIsBulkDelete] = useState(false)
  const [currentCategory, setCurrentCategory] = useState({})
  const [validForDeletion, setValidForDeletion] = useState([])
  const [selectedCateories, setSelectedCateories] = useState([])
  const [currentParentCategory, setCurrentParentCategory] = useState({})

  const getCurentFaqCategory = (id) => {
    return publiFaqCategories.filter(cat => cat._id === id)[0]
  }

  const handleCreateModal = () => {
    setOpen(true)
    setIsEdit(false)
    setIsImport(false)
    setIsDelete(false)
    setIsBulkDelete(false)
    setTitle('Add Faq Category')
  }

  
 
  
  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsEdit(false)
    dispatch(reset())
    setIsDelete(false)
    setIsImport(false)
    setIsBulkDelete(false)
    setCurrentCategory({})
    setValidForDeletion([])
    setCurrentParentCategory({})
  }


  const handleCreate = (e, formData) => {
    const {  
      name,
      definition
    } = formData
    e.preventDefault()
    const newCategory = { name, definition }
    dispatch(createFaqCategory(newCategory))
    handleClose()
  }

  return (
    <Page>
      <FullWidthContainer
        display='flex'
      >
        <Title 
          title='Categories' 
          margin='auto auto auto 0'
        />
        <HMBox 
            float='right'
            display='flex'
        >
          <HMButton 
            type='button'
            bgColor={green}
            isResponsive={true}
            handleClick={handleCreateModal}
            icon={<AddIcon sx={iconStyle} />} 
            text={<Typography sx={textStyle}>Add Faqs Category</Typography>} 
          />
          
        </HMBox>
        
      </FullWidthContainer>
      {!publiFaqCategories?.length ? (<Spinner marginTop={25} />) : (
        <DataTable
          fields={fields}
          columns={columns}
          title='Categories'
          defaultRowsNum={20}
          data={publiFaqCategories}
        />
      )}
    </Page>
  )
}

export default FaqCategory
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import HMButton from '../tools/HMButton'
import HMTextField from '../tools/HMTextField'
import HMPasswordField from '../tools/HMPasswordField'
import {
    textStyle,
} from '../../../components/common/tools/Styles'
import { 
    blue,
    orange
} from '../../../hooks/useColors'

function HMRegisterForm(props) {
    const navigate = useNavigate()
    const {
        email,
        lastName,
        password,
        firstName,
        passwordConfirmation
    } = props.formData

    const path = window.location.pathname

    const handleLogin = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(true)
            props.setIsRegister(false)
            props.setIsForgotPassword(false)
        } else {
            navigate(
                path.includes('admin') ? '/admin/login'
                    : path.includes('store/') ? '/store/login'
                    : path.includes('supplier') ? 'supplier/login'
                    : '/login'
            )
        }
    }

    return (
        <Grid align='center'>
            <form onSubmit={props.onSubmit} sx={{ margin: 'auto' }}>
                <HMTextField 
                    id='firstName'
                    required={true}
                    value={firstName}
                    label='First Name'
                    className='fade-in'
                    onChange={props.onChange}
                    placeholder='Enter firstname'
                />
                <HMTextField 
                    id='lastName'
                    required={true}
                    value={lastName}
                    label='Last Name'
                    className='fade-in'
                    onChange={props.onChange}
                    placeholder='Enter lastname'
                />
                <HMTextField 
                    id='email' 
                    type='email'
                    label='Email'
                    value={email}
                    required={true}
                    className='fade-in'
                    onChange={props.onChange}
                    placeholder='Enter your email'
                />
                <HMPasswordField 
                    value={password}
                    className='fade-in'
                    onChange={props.onChange}
                />
                <HMPasswordField 
                    className='fade-in'
                    onChange={props.onChange}
                    id='passwordConfirmation'
                    label='Password confirmation' 
                    value={passwordConfirmation}
                    placeholder='Confirm your password'
                />
                <HMButton 
                    width='100%'
                    type='submit'
                    text='Register'
                    bgColor={orange}
                    padding='8px 6px'
                    paddingActive='7.5px 5.5px'
                    margin='10px auto 15px auto'
                />
            </form>
            <GoogleLogin
                useOneTap
                width='100'
                text='signup_with'
                theme='filled_blue'
                onError={(error) => props.onError(error)}
                onSuccess={(response) => props.onSuccess(response)}
            />
            <Typography 
                className='fade-in'
                sx={{ ...textStyle, marginTop: '15px' }}
            > 
                Already have an account?
            </Typography>
            <Typography 
                sx={textStyle}
                className='fade-in'
            > 
                <Link 
                    onClick={handleLogin}
                    sx={{
                        color: blue,
                        fontWeight: 500,
                        cursor: 'pointer',
                        textDecoration: 'none'   
                    }}
                >
                    Login
                </Link>
            </Typography>
        </Grid>
    )
}

export default HMRegisterForm
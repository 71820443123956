import React, { useState, useRef, useEffect } from 'react'
import HMTextField from './HMTextField'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {
  red,
  blue,
  white,
  lightRed,
  lightBlue,
  lightBlack,
} from '../../../hooks/useColors'

function DropdownFilter({ 
  type,
  value, 
  field,
  label,
  width,
  prompt, 
  margin,
  bgColor,
  options, 
  onChange,
  required,
  disabled,
  className,
  mainMargin,
}) {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const ref = useRef(null)
  useEffect(() => {
    ['click', 'touchend'].forEach(e => {
      document.addEventListener(e, toggle)
    })
    return () => ['click', 'touchend'].forEach(e => {
      document.removeEventListener(e, toggle)
    })
  }, [])

  function toggle(e) {
    setOpen(e && e.target === ref.current)
  }
  function filter(options) {
    return options.filter(
      option => option[field].toLowerCase().indexOf(query.toLowerCase()) > -1
    )
  }
  function displayValue() {
    if (query.length) {
      return query
    }
    if (value) {
      return value[field]
    }
    return ''
  }
  function selectOption(option) {
    setQuery('')
    onChange(option)
    setOpen(false)
  }

  const dropdownStyle = {
    width: width,
    margin: mainMargin,
  }

  return (
    <div className={`dropdown ${className}`} style={dropdownStyle}>
        <div className='control'>
          <div className='selected-value'  >
            <HMTextField 
              type={type}
              label={label}
              inputRef={ref}
              margin={margin}
              bgColor={bgColor}
              disabled={disabled}
              required={required}
              handleClick={toggle}
              value={displayValue()}
              handleTouchEnd={toggle}
              placeholder={value ? value[field] : prompt}
              onChange={e => {
                setQuery(e.target.value)
                onChange(null)
              }}
            />
          </div>
          {!disabled ? (<div className={`arrow ${open && 'open'}`} />) : <></>}
        </div>
        <Paper elevation={2} className={`options ${open && 'open'}`}>
          {filter(options).map((option, index) => {
            return option ? (
              <Box 
                key={index}
                onClick={() => selectOption(option)}
                onTouchEnd={() => selectOption(option)}
                sx={{
                  fontWeight: (option && value && option.name && value.name && value.name === option.name) ? 500 : 400,
                  color: (option && value && option.name && value.name && value.name === option.name) && (
                    option && option.name.toLowerCase() === 'returned' || 
                    option && option.name.toLowerCase() === 'canceled'
                  ) ? `${red} !important` : (option && value && option.name && value.name && value.name === option.name) ? `${blue} !important` : lightBlack,
                  backgroundColor: (option && value && option.name && value.name && value.name === option.name) && (
                    option && option.name.toLowerCase() === 'returned' || 
                    option && option.name.toLowerCase() === 'canceled'
                  ) ? lightRed : (option && value && option.name && value.name && value.name === option.name) ? lightBlue : 'inherit',
                  '&:hover': {
                    fontWeight: 500,
                    color: (
                      option && option.name && option.name.toLowerCase() === 'returned' || 
                      option && option.name && option.name.toLowerCase() === 'canceled'
                    ) ? `${white} !important` : `${white} !important`,
                    backgroundColor: (
                      option && option.name && option.name.toLowerCase() === 'returned' || 
                      option && option.name && option.name.toLowerCase() === 'canceled'
                    ) ? red : blue,
                  },
                }}
                className={`option ${(option && value && option.name && value.name && value.name === option.name) ? 'selected' : ''}`}
              >
                {option[field]}
              </Box>
            ) : <></>
          })} 
        </Paper>
    </div>
  )
}

export default DropdownFilter
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import RemoveIcon from '@mui/icons-material/Remove'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  getMyStore,
} from '../../features/stores/storeSlice'
import { 
  resetOrder,
  getStoreOrder,
  updateStoreOrder,
} from '../../features/orders/orderSlice'
import {
  resetDelivery, 
  getNearestDeliveryAgent,
} from '../../features/delivery/deliverySlice'
import OrderOfficialReceipt from '../../components/store/OrderOfficialReceipt'
import HMInsuranceCard from '../../components/checkout/HMInsuranceCard'
import AddressDetails from '../../components/checkout/AddressDetails'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMViewAddedInfo from '../../components/common/layout/HMViewAddedInfo'
import HMOrderDetail from '../../components/common/layout/HMOrderDetail'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import DropdownFilter from '../../components/common/tools/DropdownFilter'
import HMRadioButton from '../../components/common/tools/HMRadioButton'
import HMTextField from '../../components/common/tools/HMTextField'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import Title from '../../components/common/tools/Title'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { 
  currencies,
  storeOrderStatus,
  computateOrderData,
} from '../../hooks/helperFunctions'
import {
  red,
  blue,
  gray,
  white,
  green,
  orange,
  darkBlue,
  lightRed,
  lightGray,
  lightBlue,
  lightBlack,
  lightGreen,
  lightOrange,
  lightWarning,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../hooks/useColors'

function Sale({ socket }) {
  const receiptPrint = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { storeId, orderId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const storeState = useSelector((state) => state.store)
  const { myStore, myStores } = storeState
  const orderState = useSelector((state) => state.order)
  const { storeOrder, isStoreOrder, isStoreOrderUpdated } = orderState
  const deliveryState = useSelector((state) => state.delivery)
  // const { deliveryAgents } = deliveryState

  const [sale, setSale] = useState({})
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isPOS, setIsPOS] = useState(false)
  const [currency, setCurrency] = useState('')
  const [saleStatus, setSaleStatus] = useState('')
  const [usedInsurance, setUsedInsurance] = useState(false)
  const [customerPayment, setCustomerPayment] = useState(0)
  const [currentSubtotal, setCurrentSubtotal] = useState(0)
  const [insurancePayment, setInsurancePayment] = useState(0)
  const [isValidateCard, setIsValidateCard] = useState(false)
  const [isChangeStatus, setIsChangeStatus] = useState(false)
  const [isViewAddedInfo, setIsViewAddedInfo] = useState(false)
  const [currentAddedInfo, setCurrentAddedInfo] = useState(null)
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const [isStartProcessing, setIsStartProcessing] = useState(false)
  const [isCustomerNotified, setIsCustomerNotified] = useState(false)
  const [isAfterCutomerConnfirmation, setIsAfterCutomerConnfirmation] = useState(false)
  const [orderComputation, setOrderComputation] = useState({
    total: 0,
    subtotal: 0,
    deliveryCost: 0,
    numberOfStores: 0,
    insuranceCoverage: 0
  })
  const [storeValidationForm, setStoreValidationForm] = useState({
    state: '',
    reason: '',
    coverage: 1
  })
  const {
    total,
    subtotal,
    deliveryCost,
    insuranceCoverage
  } = orderComputation
  const {
    state,
    reason,
    coverage
  } = storeValidationForm

  const {
    windowW,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isSmallScreen = windowW < 400 ? true : false

  const breadOptions = [
    { text: 'Sales', link: isPOS ? `/store-pos/${storeId}/online` : `/store/${storeId}/sales` },
    { text: Object.keys(sale).length ? `${sale.orderNumber}` : '', link: null }
  ]

  useEffect(() => {
    socket.on('order', (data) => {
      if (data._id === orderId) {
        dispatch(getMyStore(storeId))
      }
    })
    socket.on('store_updates_order', (data) => {
      if (data.orderId === orderId) {
        dispatch(getStoreOrder({storeId, orderId}))
      }
    })
    socket.on('assigned_order', (data) => {
      if (data.orderId === orderId) {
        dispatch(getStoreOrder({storeId, orderId}))
      }
    })
    // eslint-disable-next-line
  }, [socket])

  useEffect(() => {
    if (user) {
      if (myStores.length) {
        dispatch(getMyStore(storeId))
      }
      dispatch(getStoreOrder({storeId, orderId})) 
      setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
    }

    setIsPOS(window.location.pathname.toLowerCase().includes('pos') ? true : false)
    // eslint-disable-next-line
  }, [myStores])

  useEffect(() => {
    if (isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() === 'processing') {
      handleInsranceValidation()
    }
    // eslint-disable-next-line
  }, [isAfterCutomerConnfirmation])

  useEffect(() => {
    let insPay = 0
    let tempSubtotal = 0
    
    if (Object.keys(sale).length) {
      for (let item of sale.orderDetails[0].orderItems) {
        let cost = 0
        if ((sale.orderDetails[0].approvedInsurance || state.toLowerCase() === 'valid') && item.usedInsurance) {
          cost = item.quantity * item.insurancePrice 
          tempSubtotal += cost
          insPay += Math.round(cost * (coverage / 100))
        } else {
          tempSubtotal += item.quantity * item.onlinePrice
        }
      }
    }
    setInsurancePayment(insPay)
    setCurrentSubtotal(tempSubtotal)
    setCustomerPayment(tempSubtotal - insPay)
    // eslint-disable-next-line
  }, [coverage, total, sale, deliveryCost])

  useEffect(() => {
    if (isStoreOrder || isStoreOrderUpdated) {
      setSale(storeOrder)
      dispatch(resetOrder()) 
      setOrderComputation(computateOrderData(storeOrder))
      const tempStoreStatus = storeOrder.orderDetails[0].status

      if (storeOrderStatus.includes(tempStoreStatus)) {
        setSaleStatus({ name: tempStoreStatus })
      }

      if (isStoreOrderUpdated && tempStoreStatus.toLowerCase() === 'completed' && Object.keys(myStore).length) {
        handlePrintRecipt()
        dispatch(getNearestDeliveryAgent({
          limit: 10,
          order: orderId,
          store: storeId,
          maxDistance: 30000,
          latitude: myStore.location.latitude,
          longitude: myStore.location.longitude,
          channel: [ 'sms', 'pushNotification' ],
        }))
      }

      if (
        storeOrder.insuranceInfo && 
        'insuranceInfo' in storeOrder && 
        Object.keys(storeOrder.insuranceInfo).length &&
        storeOrder.insuranceInfo.storeValidation[storeId]
      ) {
        const storeValidation = storeOrder.insuranceInfo.storeValidation[storeId]
        if ('userConfirmed' in storeValidation && storeValidation.userConfirmed) {
          setIsAfterCutomerConnfirmation(true)
        } else if ('userConfirmed' in storeValidation && !storeValidation.userConfirmed) {
          setIsCustomerNotified(true)
        }
        setStoreValidationForm(storeValidation)
      }

      setUsedInsurance(storeOrder.orderDetails[0].orderItems.find(item => item.usedInsurance) ? true : false)

      const activityStack = storeOrder.orderDetails[0].activityStack
      if (
        tempStoreStatus.toLowerCase() === 'pending' && 
        (
          !activityStack ||
          (activityStack && activityStack[activityStack.length - 1].status.toLowerCase() !== 'refused')
        )
      ) {
        setOpen(true)
        setIsStartProcessing(true)
        setTitle('Start Processing This Order')
      }
    }

    if (deliveryState.isSuccess) {
      // console.log(deliveryAgents)
      dispatch(resetDelivery())
    }
    // eslint-disable-next-line
  }, [myStore, isStoreOrder, deliveryState, isStoreOrderUpdated])
  
  const changeOrderStatus = (status, reason) => {
    const activityStack = {
      status: status,
      updatedBy: user._id,
      updatedAt: new Date(),
    }

    if (reason) {
      activityStack['reason'] = reason
    }

    const obj = {
      orderId,
      storeId,
      updatedOrder: {
        ...sale,
        orderDetails: sale.orderDetails.map(order => {
          if (order.store === storeId) {
            const tempOrder = {
              ...order,
              status: status,
              activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
            }
            if (reason) {
              tempOrder['cancellationReason'] = reason
            }
            return tempOrder
          } else {
            return order
          }
        }),
        orderActivityStack: [
          ...sale.orderActivityStack,
          {
            updatedBy: user._id,
            updatedAt: new Date(),
            state: status.toLowerCase() === 'canceled' ? 'canceled' : 'at_store',
          }
        ]
      }
    }
    dispatch(updateStoreOrder(obj))
  }

  const navegateToProductPage = (item) => {
    window.open(`/store/${storeId}/listings/${item.listing}`, '_blank', 'noopener,noreferrer')
  }

  const handleViewAddedInfoModal = (addedInfo) => {
    setOpen(true)
    setIsViewAddedInfo(true)
    setCurrentAddedInfo(addedInfo)
    setTitle('Additional Information')
  }

  const handleViewPrescription = (prescription) => {
    window.open(prescription, '_blank', 'noopener,noreferrer')
  }
  const handleInsranceValidation = () => {
    setOpen(true)
    setIsValidateCard(true)
    setTitle(
      isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() === 'processing'
        ? 'Complete Order After User Confirmation' 
        : isAfterCutomerConnfirmation 
        ? 'Infomation About Insurance Card'
        : 'Validate Insurance Card'
    )
  }
  const handleInsranceValidationModal = (e) => {
    e.stopPropagation()
    handleInsranceValidation()
  }
  const handleSendbackToUser = () => {
    const activityStack = {
      updatedBy: user._id,
      status: 'Processing',
      updatedAt: new Date(),
    }

    const approvedInsurance = state.toLowerCase() === 'valid' ? true : false

    const storeValidation = { ...sale.insuranceInfo.storeValidation }
    storeValidation[storeId] = approvedInsurance ? {
      state,
      reason,
      coverage,
      paid: false,
      userConfirmed: false,
    } : {
      state,
      reason,
      coverage,
      userConfirmed: false,
    }

    const obj = {
      orderId,
      storeId,
      updatedOrder: {
        ...sale,
        orderTotal: sale.orderTotal - (subtotal - currentSubtotal),
        insuranceInfo: {
          ...sale.insuranceInfo,
          storeValidation,
        },
        orderDetails: sale.orderDetails.map(order => ({
          ...order,
          approvedInsurance,
          status: 'Processing',
          subtotal: currentSubtotal,
          activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
        })),
        orderActivityStack: [
          ...sale.orderActivityStack,
          {
            updatedBy: user._id,
            updatedAt: new Date(),
            state: 'final_user_confirmation',
          }
        ]
      }
    }

    dispatch(updateStoreOrder(obj))
    handleClose()
  }
  const handleCompleteOrder = () => {
    const activityStack = {
      updatedBy: user._id,
      status: 'Completed',
      updatedAt: new Date(),
    }

    const approvedInsurance = state.toLowerCase() === 'valid' ? true : false

    const storeValidation = { ...sale.insuranceInfo.storeValidation }

    if (!isAfterCutomerConnfirmation) {
      storeValidation[storeId] = approvedInsurance ? {
        ...storeValidation[storeId],
        state,
        reason,
        coverage,
        paid: false,
      } : {
        state,
        reason,
        coverage
      }
    }

    const obj = {
      orderId,
      storeId,
      updatedOrder: {
        ...sale,
        orderTotal: sale.orderTotal - (subtotal - currentSubtotal),
        insuranceInfo: {
          ...sale.insuranceInfo,
          storeValidation,
        },
        orderDetails: sale.orderDetails.map(order => ({
          ...order,
          approvedInsurance,
          status: 'Completed',
          subtotal: currentSubtotal,
          activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
        })),
        orderActivityStack: [
          ...sale.orderActivityStack,
          {
            updatedBy: user._id,
            updatedAt: new Date(),
            state: 'at_store_final',
          }
        ]
      }
    }

    dispatch(updateStoreOrder(obj))
    handleClose()
  }

  const handleChangeStatusModal = () => {
    setOpen(true)
    setIsChangeStatus(true)
    setTitle('Update Sale Status')
  }

  const handleChangeStatus = (reason) => {
    if (reason) {
      changeOrderStatus(saleStatus.name)
    } else {
      changeOrderStatus(saleStatus.name, reason)
    }
    handleClose()
  }

  const handleRefuseProccessing = () => {
    const activityStack = {
      status: 'Refused',
      updatedBy: user._id,
      updatedAt: new Date(),
    }
    const obj = {
      storeId,
      orderId,
      updatedOrder: {
        ...sale,
        orderDetails: sale.orderDetails.map(order => {
          if (order.store === storeId) {
            return {
              ...order,
              activityStack: order.activityStack ? [ ...order.activityStack, activityStack ] : [ activityStack ]
            }
          } else {
            return order
          }
        })
      }
    }
    dispatch(updateStoreOrder(obj))
    handleClose()
  }
  const handleStartProcessing = () => {
    changeOrderStatus('Processing')
    handleClose()
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsValidateCard(false)
    setIsChangeStatus(false)
    setIsViewAddedInfo(false)
    setIsStartProcessing(false)

    if (
      sale.insuranceInfo && 
      'insuranceInfo' in sale && 
      Object.keys(sale.insuranceInfo).length &&
      sale.insuranceInfo.storeValidation[storeId]
    ) {
      setStoreValidationForm(sale.insuranceInfo.storeValidation[storeId]) 
    }
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'coverage') {
      if (value > 0 && value <= 100) {
        setStoreValidationForm((prevState) => ({
            ...prevState,
            [name]: parseInt(value),
        }))
      } 
    } else if (name === 'state') {
      setStoreValidationForm((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    } else if (name === 'cancellationReason') {
      setCancellationReason(value)
    } else {
      setStoreValidationForm((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }
  const handleQuantityReduce = () => {
    setStoreValidationForm((prevState) => ({
      ...prevState,
      coverage: coverage > 1 ? coverage - 1 : coverage,
    }))
  }
  const handleQuantityIncrease = () => {
    setStoreValidationForm((prevState) => ({
      ...prevState,
      coverage: coverage < 100 ? coverage + 1 : coverage,
    }))
  }

  const handleChangingStatus = () => {
    setIsStatusChanging(!isStatusChanging)
    changeOrderStatus('Processing')
  }

  const pageStyle = `{ 
    padding: '20px',
    size: '2.5in 4in',
    backgroundColor: white
  }`

  const handlePrintRecipt = useReactToPrint({
    pageStyle: pageStyle,
    bodyClass: 'print-receipt',
    content: () => receiptPrint.current
  })

  const isInsuranceValidateDisabled = (
    state === '' ||
    (state.toLowerCase() === 'valid' && coverage === 0) ||
    (state.toLowerCase() === 'invalid' && reason === '') 
  ) ? true : false

  return user ? (
    <Page>
      {orderState.isLoading ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        maxHeight='95vh'
        handleClose={handleClose} 
      >
        {isChangeStatus ? (
          <HMBox
            width='100%'
            display='flex'
            minWidth='300px'
            flexDirection='column'
          >
            <HMBox
              padding='0'
              width='100%'
              margin='20px auto auto auto'
            >
              <DropdownFilter 
                type='text'
                field='name'
                required={true}
                value={saleStatus}
                label='Sale Status'
                prompt='Choose status'
                onChange={(val) => {setSaleStatus(val)}}
                options={
                  (
                    !sale.insuranceInfo ||
                    (isAfterCutomerConnfirmation && Object.keys(sale.insuranceInfo).length)
                  ) 
                  ? storeOrderStatus.map(status => ({name: status}))
                  : storeOrderStatus.filter(status => status.toLowerCase() !== 'completed').map(status => ({name: status}))
                }
                className={
                  saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'completed' 
                  ? 'update-order-status completed'
                  : saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled' 
                  ? 'update-order-status danger' 
                  : 'update-order-status'
                }
              />
              {saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled' ? (
                <>
                  <HMAlert 
                    warning={true}
                    bgColor={lightWarning}
                    text='This action cannot be undone. Make sure you really want to cancel this order!'
                  />
                  <HMTextField 
                    required={true}
                    className='fade-in'
                    onChange={handleChange}
                    name='cancellationReason'
                    value={cancellationReason}
                    margin='15px auto 10px auto'
                    placeholder='Why are you canceling the order'
                    label='Reason why you are canceling this order'
                  />
                </>
              ) : <></>}
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='120px'
                color={white}
                margin='auto 0 auto auto'
                bgColor={
                  saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled'
                  ? red 
                  : green
                }
                handleClick={
                  saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled'
                  ? () => handleChangeStatus(cancellationReason)
                  : handleChangeStatus
                }
                disabled={
                  (
                    saleStatus === '' ||
                    saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled' && cancellationReason.length < 10
                  ) ? true : false
                }
                text={
                  <Typography sx={textStyle}>
                    {
                      `${saleStatus && Object.keys(saleStatus).length && saleStatus.name.toLowerCase() === 'canceled' 
                        ? 'Cancel' : 'Complete'} order`
                    }
                  </Typography>
                }
              />
            </HMBox>
          </HMBox>
        ) : isViewAddedInfo ? (
          <HMViewAddedInfo 
            addedInfo={currentAddedInfo} 
          />
        ) : isValidateCard ? (
          <HMBox
            width='100%'
            display='flex'
            flexDirection='column'
          >
            <HMBox
              padding='0'
              width='100%'
              margin='10px auto auto auto'
              className='validate-insurance fade-in'
              minWidth={isTabletScreen ? '100%' : '400px'}
            >
              <HMButton 
                width='100%'
                type='button'
                bgColor={blue}
                fontWeight={500}
                className='fade-in'
                margin='10px auto 20px auto'
                rightIcon={<OpenInNewOutlinedIcon sx={iconStyle} />} 
                text={<Typography sx={textStyle}>View Insurance Card</Typography>}
                handleClick={() => {
                  window.open(sale.insuranceInfo.cardImage, '_blank', 'noopener,noreferrer')
                }}
              />
              {!isAfterCutomerConnfirmation ? (
                <HMRadioButton 
                  name='state'
                  padding='5px'
                  value={state}
                  margin='auto 0 10px 0'
                  onChange={
                    sale.orderDetails[0].status.toLowerCase() === 'processing' 
                    ? handleChange
                    : () => {}
                  }
                  fontSize={`${fontSize + 2}px`}
                  label='Is the insurance card valid?'
                  labelId='ask-prescription-radio-group'
                  flexDirection={isSmallScreen ? 'column' : 'row'}
                  bgColor={state.toLowerCase() === 'invalid' ? lightRed : lightBlue}
                  options={[
                    { label: 'Yes', value: 'Valid' },
                    { label: 'No', value: 'Invalid' }
                  ]}
                />
              ) : <></>}
              {state.toLowerCase() === 'invalid' ? (
                <>
                  {isAfterCutomerConnfirmation ?  (
                    <HMAlert 
                      error={true}
                      bgColor={lightRed}
                      margin='auto auto 10px auto'
                      text={
                        <>
                          <Typography 
                            component='span'
                            sx={{ fontSize: 'inherit' }}
                          >
                            Invalid insurance card . Reason:
                          </Typography>
                          <Typography
                            component='span'
                            sx={{ 
                              fontWeight: 500, 
                              marginLeft: '5px', 
                              fontSize: 'inherit',
                            }}
                          >
                            {reason} 
                          </Typography>
                        </>
                      }
                    />
                  ) : (
                    <HMTextField 
                      name='reason'
                      value={reason}
                      required={true}
                      className='fade-in'
                      margin='10px 0 20px 0'
                      onChange={handleChange}
                      placeholder='Why is the card invalid?'
                      label='Reason why the insurance card is invalid'
                    />
                  ) }
                  {(isCustomerNotified || isAfterCutomerConnfirmation)? (
                    <HMAlert 
                      warning={isCustomerNotified ? true : false}
                      error={isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() === 'canceled' ? true : false}
                      success={isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() !== 'canceled' ? true : false}
                      bgColor={
                        isCustomerNotified 
                        ? lightWarning 
                        : isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() === 'canceled' 
                        ? lightRed
                        : lightGreen
                      }
                      text={
                        isCustomerNotified 
                        ? 'Customer had been notified already!' 
                        : isAfterCutomerConnfirmation && sale.orderDetails[0].status.toLowerCase() === 'canceled' 
                        ? 'Customer canceled this order!'
                        : 'Customer confirmed this order.'
                      }
                    />
                  ) : <></>}
                </>
              ) : state.toLowerCase() === 'valid' ? (
                <>
                  <HMBox
                    width='100%'
                    display='flex'
                    margin='10px 0'
                    padding='5px'
                    className='fade-in'
                    border={`1px solid ${lightBlue}`}
                  >
                    <HMText 
                      color={lightBlack}
                      text='Insurance Coverage'
                      margin='auto 10px auto 0'
                      fontSize={`${fontSize}px`}
                    />
                    {!isAfterCutomerConnfirmation ? (
                      <HMBox 
                        display='flex'
                        padding='1px 5px'
                        width='max-content'
                        bgColor={lightBlue}
                      >
                        <IconButton   
                          onClick={handleQuantityReduce} 
                          sx={{ padding: '3px', margin: 'auto 0' }}
                          disabled={coverage === 1 ? true : false}
                        >
                          <RemoveIcon sx={{width: '20px', height: '20px'}} />
                        </IconButton>
                        <HMTextField 
                          width='50px'
                          type='number'
                          name='coverage'
                          value={coverage}
                          margin='auto 5px'
                          onChange={handleChange}
                          fontSize={`${fontSize + 2}px`}
                          className='cart-product quantity'
                        />
                        <IconButton   
                          onClick={handleQuantityIncrease} 
                          sx={{ padding: '3px', margin: 'auto 0' }}
                          disabled={coverage === 100 ? true : false}
                        >
                          <AddIcon sx={{width: '20px', height: '20px'}} />
                        </IconButton>
                      </HMBox>
                    ) : <></>}
                    <Title 
                      color={blue}
                      padding='1px 5px'
                      borderRadius='5px'
                      size={fontSize - 2}
                      bgColor={lightBlue}
                      title={`${coverage} %`}
                      margin='auto 0 auto auto'
                    />
                  </HMBox> 
                  <HMBox 
                    width='100%'
                    display='flex'
                    padding='5px 0'
                    margin='0 0 10px 0'
                    className='fade-in'
                    flexDirection={`${isTabletScreen ? 'column' : 'row'}`}
                  >
                    <HMBox 
                      width='100%'
                      height='100%'
                      display='flex'
                      className='fade-in'
                      flexDirection='row'
                      border={`1px solid ${lightBlue}`}
                      margin={`${isTabletScreen ? '5px 0' : '0 2.5px 0 0'}`}
                    >
                      <HMText 
                        margin='auto 0'
                        text='Customer pays :'
                      />
                      <Title 
                        color={blue}
                        padding='1px 5px'
                        borderRadius='5px'
                        size={fontSize - 2}
                        bgColor={lightBlue}
                        margin='auto 0 auto auto'
                        title={`${currency} ${customerPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      />
                    </HMBox>
                    {coverage > 0 ? (
                      <HMBox 
                        width='100%'
                        height='100%'
                        display='flex'
                        className='fade-in'
                        flexDirection='row'
                        border={`1px solid ${lightBlue}`}
                        margin={`${isTabletScreen ? '5px 0' : '0 0 0 2.5px'}`}
                      >
                        <HMText 
                          margin='auto 0'
                          text={`${sale.insuranceInfo.insurer.name} pays :`}
                        />
                        <Title 
                          color={orange}
                          padding='1px 5px'
                          borderRadius='5px'
                          size={fontSize - 2}
                          bgColor={lightOrange}
                          margin='auto 0 auto auto'
                          title={`${currency} ${insurancePayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                      </HMBox>
                    ) : (
                      <></>
                    )}
                  </HMBox>
                  {isAfterCutomerConnfirmation ? (
                    <HMBox
                      width='100%'
                      display='flex'
                      padding='5px'
                      className='fade-in'
                      margin='auto auto 10px '
                      border={`1px solid ${lightBlue}`}
                    >
                      <HMText 
                        color={lightBlack}
                        text='Copayment Method :'
                        margin='auto 10px auto 0'
                        fontSize={`${fontSize}px`}
                      />
                      <Title 
                        color={blue}
                        padding='1px 5px'
                        borderRadius='5px'
                        size={fontSize - 2}
                        bgColor={lightBlue}
                        margin='auto 0 auto auto'
                        title={sale.copaymentInfo.method}
                      />
                    </HMBox>
                  ) : <></>}
                  {(isCustomerNotified || isAfterCutomerConnfirmation)? (
                    <HMAlert 
                      warning={isCustomerNotified ? true : false}
                      success={isAfterCutomerConnfirmation ? true : false}
                      bgColor={isCustomerNotified ? lightWarning : lightGreen}
                      text={
                        isCustomerNotified 
                        ? 'Customer had been notified already!' 
                        : 'Customer confirmed this order.'
                      }
                    />
                  ) : <></>}
                </>
              ) : <></>}
            </HMBox>
            {sale.orderDetails[0].status.toLowerCase() === 'processing' ? (
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='15px auto 0 auto'
                className='change-store-button fade-in'
              >
                <HMButton 
                  type='button'
                  width='100px'
                  bgColor={gray}
                  fontWeight={500}
                  margin='auto 10px auto 0'
                  handleClick={handleClose}
                  text={<Typography sx={textStyle}>Cancel</Typography>}
                />
                <HMButton 
                  type='button'
                  width='140px'
                  color={white}
                  bgColor={green}
                  margin='auto 0 auto auto'
                  disabled={isInsuranceValidateDisabled}
                  handleClick={
                    ((state.toLowerCase() === 'valid' && customerPayment === 0) || isAfterCutomerConnfirmation) 
                    ? handleCompleteOrder 
                    : handleSendbackToUser
                  }
                  text={
                    <Typography sx={textStyle}>
                      {customerPayment === 0 || isAfterCutomerConnfirmation ? 'Complete order' : 'Notify customer'}
                    </Typography>
                  }
                />
              </HMBox>
            ) : <></>}
          </HMBox>
        ) : isStartProcessing ? (
          <HMBox
            width='100%'
            display='flex'
            flexDirection='column'
          >
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
                <HMButton 
                  type='button'
                  width='100px'
                  color={white}
                  bgColor={red}
                  fontWeight={500}
                  margin='auto 10px auto 0'
                  handleClick={handleRefuseProccessing}
                  text={<Typography sx={textStyle}>Decline!</Typography>}
                />
                <HMButton 
                  type='button'
                  width='150px'
                  color={white}
                  bgColor={green} 
                  disabled={false}
                  margin='auto 0 auto auto'
                  handleClick={handleStartProcessing}
                  text={<Typography sx={textStyle}>Start processing</Typography>}
                />
            </HMBox>
          </HMBox>
        ) : <></>}
      </HMModal>
      {Object.keys(sale).length ? (
        <Section 
          padding={10} 
          bgColor={white}
        >
          <HMBox
            width='100%'
            display='flex'
            className='fade-in'
          >
            <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              margin='auto auto auto 0'
            >
              <Title 
                size={fontSize + 2}
                margin='auto auto auto 0'
                title={sale.orderNumber}
              />
              <HMText 
                color={lightBlack}
                margin='auto auto auto 0'
                fontSize={`${fontSize - 1}px`}
                text={moment(sale.createdAt).utc().format('MMM DD, YYYY  h:mm:ss a')}
              />
            </HMBox>
            {(
              sale.orderDetails[0].status.toLowerCase() !== 'pending' &&
              sale.orderDetails[0].status.toLowerCase() !== 'canceled' && 
              sale.orderDetails[0].status.toLowerCase() !== 'processing'
            ) ? (
              <HMBox
                width='100%'
                margin='auto'
                display='flex'
              >
                <HMButton 
                  type='button'
                  width='160px'
                  margin='auto'
                  borderRadius={8}
                  color={darkBlue}
                  bgColor={lightGray}
                  padding='2.5px 5px'
                  paddingActive='2px 4.5px'
                  handleClick={handlePrintRecipt}
                  icon={<ReceiptLongIcon sx={{ ...iconStyle }}/>}
                  text={<Typography sx={textStyle}>Generate a Receipt</Typography>} 
                />
              </HMBox>
            ) : <></>}
            <HMBox
              width='100%'
              display='flex'
              flexDirection='column'
              margin='auto 0 auto auto'
            >
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='auto 0 auto auto'
              >
                <HMText 
                  text={`${
                    (sale.orderDetails[0].approvedInsurance && sale.copaymentInfo && Object.keys(sale.copaymentInfo).length)
                    ? 'Copayment' : 'Payment'} Method :`}
                  fontSize={`${fontSize}px`}
                  margin='auto 5px auto auto'
                />
                <HMText 
                  color={white}
                  bgColor={blue}
                  padding='0 5px'
                  margin='auto 0 auto 5px'
                  fontSize={`${fontSize - 1}px`}
                  text={
                    ((sale.orderDetails[0].approvedInsurance || isAfterCutomerConnfirmation) && sale.copaymentInfo && Object.keys(sale.copaymentInfo).length) 
                      ? sale.copaymentInfo.method 
                      : sale.paymentInfo.method
                  }
                />
              </HMBox>
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                margin='5px 0 auto auto'
              >
                <HMText 
                  text='Amount :'
                  fontSize={`${fontSize}px`}
                  margin='auto 5px auto auto'
                />
                <HMText 
                  color={orange}
                  padding='0 5px'
                  fontWeight={500}
                  margin='auto 0 auto 5px'
                  fontSize={`${fontSize + 2}px`}
                  text={`${currency} ${(total - insuranceCoverage - deliveryCost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
                <HMBox
                  color={white}
                  display='flex'
                  borderRadius={0}
                  padding='0 6px 0 7px'
                  margin='auto 0 auto 5px'
                  bgColor={sale.paymentInfo.paid ? green : red}
                > 
                  <HMText
                    color='inherit'
                    fontWeight={400}
                    margin='auto 5px auto auto'
                    fontSize={`${fontSize - 2}px`}
                    text={sale.paymentInfo.paid ? 'paid' : 'unpaid'}
                  />
                  {sale.paymentInfo.paid ? (
                    <CheckCircleRoundedIcon 
                      sx={{
                        margin: 'auto',
                        color: 'inherit',
                        fontSize: `${fontSize - 2}px`,
                      }}
                    />
                  ) : (
                    <UnpublishedIcon
                      sx={{
                        margin: 'auto',
                        color: 'inherit',
                        fontSize: `${fontSize - 2}px`,
                      }}
                    />
                  )}
                </HMBox>
              </HMBox>
            </HMBox>
          </HMBox>
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            flexDirection={isTabletScreen ? 'column' : 'row'}
          >
            <AddressDetails 
              width='100%'
              desableChnageAddress={true}
              bgColor={skeletonsLightBlue}
              addressInfo={sale.deliveryInfo}
              margin={isTabletScreen ? '10px auto 5px auto' : '10px 5px 10px 0'}
            />
            {(sale.insuranceInfo && Object.keys(sale.insuranceInfo).length && usedInsurance) ? (
              <HMInsuranceCard 
                storeId={storeId}
                isOrderPage={true}
                isStoreView={true}
                storeTotal={total}
                coverage={insuranceCoverage}
                bgColor={skeletonsLightBlue}
                insuranceInfo={sale.insuranceInfo}
                insurer={sale.insuranceInfo.insurer}
                handleInsranceValidationModal={handleInsranceValidationModal}
                margin={isTabletScreen ? '5px auto 10px auto' : '10px 0 10px 5px'}
                isDisabled={sale.orderDetails[0].status.toLowerCase() === 'pending'}
              />
            ) : <></>}
          </HMBox>
          <HMBox
            width='100%'
            className='fade-in'
            padding='10px 5px 5px'
            bgColor={seeThroughLightBlue}
          >
            {sale.orderDetails.map((order, index) => (
              <HMOrderDetail 
                key={index}
                state={state}
                isStore={true}
                orderDetail={order}
                insuranceInfo={sale.insuranceInfo}
                isStatusChanging={isStatusChanging}
                handleChangingStatus={handleChangingStatus}
                handleChangeStatusModal={handleChangeStatusModal}
                navegateToProductPage={(item) => navegateToProductPage(item)}
                handleViewAddedInfoModal={(addedInfo) => handleViewAddedInfoModal(addedInfo)}
                handleViewPrescription={(prescription) => handleViewPrescription(prescription)}
              />
            ))}
          </HMBox>
        </Section>
      ) : <></>}
      <Box 
        ref={receiptPrint}
        sx={{
          width: '100%',
          display: 'flex',
          backgroundColor: white,
          flexDirection: 'column',
          minWidth: isTabletScreen ? 'auto' : '400px'
        }}
      >
        <Title 
          display='none'
          margin='10px auto'
          size={fontSize + 6}
          title='Offical Reiceit' 
          className='print-receipt-title'
        />
        {Object.keys(storeOrder).length ? (
          <HMBox
            width='100%'
            display='none'
            padding='10px 0'
            className='print-receipt-body'
          >
            <OrderOfficialReceipt sale={storeOrder} />
          </HMBox>
        ) : <></>}
      </Box>
    </Page>
  )  : (
    <Section 
      padding={10} 
      bgColor={white}
    >
      <HMLoginFirst 
        message='You need to login first before accessing store content'
      />
    </Section>
  )
}

export default Sale
import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { 
  reset,
  resetPassword
} from '../../features/auth/authSlice'
import BeforeLogin from '../../components/common/layout/BeforeLogin'
import HMBox from '../../components/common/layout/HMBox'
import HMPasswordField from '../../components/common/tools/HMPasswordField'
import HMButton from '../../components/common/tools/HMButton'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import { 
  fontSize,
  textStyle,
} from '../../components/common/tools/Styles'
import { 
  blue, 
  lightRed, 
  lightBlue, 
} from '../../hooks/useColors'

function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, token } = useParams()
  const { 
    user, 
    message,
    isError, 
    isLoading, 
    isSuccess, 
  } = useSelector((state) => state.auth)
  
  const path = window.location.pathname
  const [arePasswordsMatching, setArePasswordsMatching] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  })

  const { 
    password, 
    passwordConfirmation, 
  } = formData

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    // Redirect when logged in
    if (isSuccess) {
      navigate('/login')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  useEffect(() => {
    if (password.length > 4 && password === passwordConfirmation) {
      setArePasswordsMatching(true)
    } else {
      setArePasswordsMatching(false)
    }
  }, [passwordConfirmation, password])

  const onChange = (e) => {
    const id = e.target.id
    const value = e.target.value
    setFormData((prevState) => ({
      ...prevState,
      [id]: value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const userData = {
      id,
      token,
      password,
    }
    dispatch(resetPassword(userData))
  }

  return (
    <BeforeLogin
      isLoading={isLoading}
      title='Reset Password'
    >
      <Grid align='center' sx={{ margin: 'auto' }}>
        <HMBox 
          padding='0'
          width='100%'
          height='70px'
          display='flex'
          margin='auto auto 10px auto'
        >
          {(password.length > 4 && !arePasswordsMatching) ? (
            <HMAlert
              error={true}
              margin='10px auto'
              bgColor={lightRed}
              textAlign='justify'
              fontSize={fontSize - 2}
              text='New password and password confirmation must match!'
            />
          ) : (password.length > 4 && arePasswordsMatching) ? (
            <HMAlert
              good={true}
              margin='10px auto'
              textAlign='justify'
              bgColor={lightBlue}
              fontSize={fontSize - 2}
              text='Passwords match. Click on the reset password button to proceed.'
            />
          ) : (
            <> 
              <Divider
                flexItem
                orientation='vertical'
                sx={{
                  mr: 1,
                  height: 70,
                  margin: 'auto 0',
                  borderRightWidth: 5,
                  borderRadius: '3px',
                  background: '#F78800',
                }}
              />
              <HMText
                width='100%'
                textAlign='justify'
                margin='auto 0 auto 10px'
                text='It is advised to set a password of at least 6 characters long, including
                capital letters, small letters, numbers and a special
                characters.'
              />
            </>
          )}
        </HMBox>
        <form onSubmit={onSubmit}>
          <HMPasswordField 
            value={password}
            className='fade-in'
            onChange={onChange}
          />
          <HMPasswordField 
            className='fade-in'
            onChange={onChange}
            id='passwordConfirmation'
            value={passwordConfirmation}
            label='Password confirmation' 
            placeholder='Confirm your password'
          />
          <HMButton
            width='100%'
            type='submit'
            bgColor={blue}
            margin='10px auto'
            className='fade-in'
            text='Reset Password'
            disabled={!arePasswordsMatching}
          />
        </form>
        <HMBox
          width='100%'
          display='flex'
          className='fade-in'
        >
          <Typography 
            sx={{
              ...textStyle,
              margin: 'auto 0'
            }}
          > 
            <Link to='/login'>
              Login
            </Link>
          </Typography>
          <Typography 
            sx={{
              ...textStyle,
              margin: 'auto 0 auto auto'
            }}
          > 
            <Link to='/register'>
              Register
            </Link>
          </Typography>
        </HMBox>
      </Grid>
    </BeforeLogin>
  )
}

export default ResetPassword

import { Box } from "@mui/material"

import HMText from "./HMText";
import {  darkBlue } from "../../../hooks/useColors";
import { fontSize } from "./Styles";

 const DashboardBottomCard = (props) => {
    return (
      <Box
        sx={{
          background: props.bgColor,
          height: '20%',
          width: '100%',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          p: 1,
          mt: props.mt,
        }}
      >
        <img src={props.src} alt="Icon" width={'25%'} height={'95%'} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <HMText text={props.text} color={darkBlue} fontWeight='600'  />
          <HMText
            text={`${props.money}M RF`}
            color={darkBlue} 
            fontWeight="700"
            fontSize={fontSize + 10}
            margin= '5px 0'
          />
        </Box>
      </Box>
    )
  }

  export default DashboardBottomCard;
  
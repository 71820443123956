import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import LeaderboardTwoToneIcon from '@mui/icons-material/LeaderboardTwoTone'
// import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
// import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone'
// import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone'
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone'
// import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
// import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone'
// import PrivacyTipTwoToneIcon from '@mui/icons-material/PrivacyTipTwoTone'
// import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone'
// import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone'
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone'
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone'
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import SettingsOverscanTwoToneIcon from '@mui/icons-material/SettingsOverscanTwoTone'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
// import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone'
// import AddModeratorTwoToneIcon from '@mui/icons-material/AddModeratorTwoTone'
// import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone'
// import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import VaccinesTwoToneIcon from '@mui/icons-material/VaccinesTwoTone'
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone'
import { 
  hideSidebar,
  showSidebar
} from '../../features/nav/navSlice'
import { 
  resetStore,
  getMyStores,
} from '../../features/stores/storeSlice'
import HMUserNavigation from '../../components/common/navigation/HMUserNavigation'
import SidebarItemLink from '../../components/common/navigation/SidebarItemLink'
import HMScrollTop from '../../components/common/navigation/HMScrollTop'
import SidebarItem from '../../components/common/navigation/SidebarItem'
import HMAppBar from '../../components/common/navigation/HMAppBar'
import SideBar from '../../components/common/navigation/SideBar'
import HMStoreCard from '../../components/common/layout/HMStoreCard'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import HMNotification from '../../components/common/tools/HMNotification'
import HMContainer from '../../components/common/layout/HMContainer'
import HMMenuIcon from '../../components/common/tools/HMMenuIcon'
import HMSocials from '../../components/common/tools/HMSocials' 
import HMButton from '../../components/common/tools/HMButton'
import HMText from '../../components/common/tools/HMText'
import {
  fontSize,
  textStyle,
  iconStyle,
  appBarIcon,
  closeOpenStyle
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  storeApplicationStatus
} from '../../hooks/helperFunctions'
import {
  blue,
  white,
  orange,
  darkBlue,
  lightBlack,
} from '../../hooks/useColors'

const stylingIcon = {
  ...iconStyle,
  color: blue,
  margin: 'auto 10px auto 0',
  fontSize: `${fontSize + 4}px`,
}

function Store() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    sidebarWidth
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const { 
    myStore,
    myStores,
    isMyStore,
    areMyStores,
  } = useSelector((state) => state.store)

  const {
    windowW,
    windowH
  } = useWindowDimensions()

  const [activeColor, setActiveColor] = useState(null)
  
  useEffect(() => {
    if (user) {
      dispatch(getMyStores())
    } else {
      navigate('/forbidden')
    }

    if (window.location.pathname === '/store') {
      dispatch(hideSidebar())
    } else {
      dispatch(showSidebar())
    }
  }, [dispatch, navigate, user])

  useEffect(() => {
    if (areMyStores) {
      dispatch(resetStore())
      if(!myStores.length) {
        navigate('/store-application')
      }
    } else if (isMyStore) {
      dispatch(resetStore())
    }
  }, [dispatch, navigate, areMyStores, isMyStore, myStores])

  const isTabletScreen = windowW < 768 ? true : false
  const isSideBarHeight = windowH < 350 ? true : false
  
  const width = isTabletScreen ? '100vw' : `${windowW - sidebarWidth - 12}px` 
  const marginLeft = isTabletScreen ? '0px' : `${sidebarWidth}px`

  const navigateUser = (e) => {
    const name = e.target.name
    if (name.toLowerCase() === 'home') {
      navigate('')
    } else {
      navigate(`${myStore._id}/${name}`)
    }
    if (activeColor) {
      activeColor.color = lightBlack
    }
    setActiveColor(e.target.style)
    e.target.style.color = orange
  }

  const handleStoreClick = (store) => {
    const statsLn = storeApplicationStatus.length
    const ln = store.applicationStack.length

    if (!ln || (ln && store.applicationStack[ln - 1].status === storeApplicationStatus[statsLn -1])) {
      navigate(`${store._id}/dashboard`)
    } else {
      navigate(`application/${store._id}`)
    }
  }

  const handleApplyStore = () => {
    navigate('application')
  }

  const handlePOS = () => { 
    navigate(`/store-pos/${myStore._id}/landing`)
  }

  const path = window.location.pathname
  const isHide = path === '/store' || path.includes('application') ? true : false

  return user ? (
    <>
      <SideBar>
        {!isHide ? (
          <HMBox
            width='100%'
            padding={isTabletScreen ? '0 0 0 4.5%' : '0 0 0 10%'}
          >
            <SidebarItem 
              name='Home'
              navigateUser={navigateUser}
              leftIcon={<CottageTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Dashboard'
              navigateUser={navigateUser} 
              leftIcon={<DashboardCustomizeTwoToneIcon sx={stylingIcon} />}
            />
            <SidebarItem 
              name='Analytics'
              navigateUser={navigateUser} 
              leftIcon={<LeaderboardTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Sales'
              navigateUser={navigateUser} 
              leftIcon={<ListAltTwoToneIcon sx={stylingIcon} />} 
            />
            <SidebarItem 
              name='Product' 
              leftIcon={<VaccinesTwoToneIcon sx={stylingIcon} />} 
              rightIcon={<KeyboardArrowDownTwoToneIcon sx={closeOpenStyle} />} 
            >
              <SidebarItemLink 
                page='listings' 
                text='Products List'   
                navigateUser={navigateUser} 
              />
              <SidebarItemLink 
                text='Add Product'   
                page='listings/create' 
                navigateUser={navigateUser} 
              />
            </SidebarItem>
          </HMBox>
        ) : <></>}
        <HMSocials 
          bottom={!isSideBarHeight && 0}
          position={!isSideBarHeight && 'absolute'}
        />
      </SideBar>
      <HMContainer
        width={width}
        className='admin'
        marginLeft={marginLeft}
      >
        <HMAppBar>
          <HMMenuIcon />
          {path !== '/store' ? (
            <HMText
              color={darkBlue}
              fontWeight={700}
              margin='auto 10px'
              fontSize={`${fontSize + 4}px`}
              text={myStore ? myStore.name : ''}
            />
          ) : <></>}
          <HMBox
            padding='0'
            display='flex'
            margin='auto 0 auto auto'
          >
            {path !== '/store' ? (
              <HMButton 
                color={white}
                bgColor={blue}
                margin={isTabletScreen ? 'auto 5px' : 'auto 10px'}
                padding='2px 5px'
                handleClick={handlePOS}
                paddingActive='1.5px 4.5px'
                text={<Typography sx={textStyle}>POS</Typography>} 
                icon={<ShoppingBasketOutlinedIcon sx={{
                  ...iconStyle, 
                  fontSize: `${fontSize + 2}px`,
                  '&:active' : {
                    fontSize: `${fontSize + 1}px`,
                  }
                }} />} 
              />
            ) : <></>}
            <WbSunnyTwoToneIcon sx={appBarIcon} />
            <SettingsOverscanTwoToneIcon sx={appBarIcon} />
            <AccountBalanceWalletTwoToneIcon sx={appBarIcon} />
            <HMNotification />
            <HMUserNavigation />
          </HMBox>
        </HMAppBar>
        {path !== '/store' ? (
          <>
            <Outlet />
            <HMScrollTop />
          </>
        ) : (
          <Section 
            padding={10} 
            height='85%'
            bgColor={white}
          >
            <Grid 
              container 
              spacing={2}
            >
              {myStores.map((store, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <HMStoreCard
                    key={index}
                    store={store}
                    hideHeart={true}
                    showAddress={true}
                    handleClick={() => handleStoreClick(store)}
                  />
                </Grid>
              ))}
              <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex' }}>
                <HMButton 
                  width='100%'
                  color={blue}
                  type='button'
                  padding='10px'
                  bgColor='inherit'
                  borderRadius={10}
                  className='fade-in'
                  paddingActive='10px'
                  height='match-content'
                  border={`1px solid ${blue}`}
                  handleClick={handleApplyStore}
                  margin={isTabletScreen ? '5px auto' : '0 0 0 5px'}
                  icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                  text={
                    <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                      Apply for a new store
                    </Typography>
                  } 
                />
              </Grid>
            </Grid>
        </Section>
        )}      
      </HMContainer>
    </>
  ) : <></>
}

export default Store
import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'

 import { fontSize, textStyle, iconStyle} from '../../components/common/tools/Styles'
import {
  blue,
  lightBlack,
  black,
  darkBlue,
  white,
  orange
} from '../../hooks/useColors'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Page from '../../components/common/layout/Page'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import { Grid } from '@mui/material'
import HMText from '../../components/common/tools/HMText'
import Section from '../../components/common/layout/Section'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import HMBox from '../../components/common/layout/HMBox'
import HMButton from '../../components/common/tools/HMButton'


const Paragraph = (props) => {

  return (
    <Grid container sx={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
      <Grid item md={10} xs={12}>
        <Typography
          sx={{
            fontSize: {fontSize},
            borderRadius: '8px',
            textAlign: 'justify',
            fontWeight: '500',
            lineHeight: '28px',
            paddingTop: '25px',
            color: lightBlack,
            marginTop: props.marginTop,
          }}
        >
          {props.text}
        </Typography>
       <span> <Typography sx={{fontSize: {fontSize}, color: blue, fontWeight: '500'}}>{props.span}</Typography></span>
      </Grid>
    </Grid>
  )
}

const ParagraphTitle = (props) => {
  return (
    <Grid container sx={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
      <Grid item md={10} xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <HMText
          text={props.title}
          fontWeight="700"
          color={darkBlue}
          padding="4px 0"
          fontSize={fontSize + 2}
          textAlign="justify"
        />
      </Grid>
    </Grid>
  )
}

function TermsAndConditions() {
  const { sidebarWidth } = useSelector((state) => state.nav)
  const { windowW } = useWindowDimensions()
  const isTabletScreen = windowW < 768 ? true : false

  const navigate = useNavigate()
  const breadOptions = [
      { text: 'Home', link: '/landing' },
      { text: 'Terms and condition', link: null }
    ]

  return (
    <Page>
      <FullWidthContainer>
      <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
           <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 10px auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>

      <Section bgcolor={white}
      padding={10}>
          <Grid bgcolor={white} container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              <HMText
                text={`Current as ${(new Date()).toString().split(' ').splice(1,3).join(' ')}`}
                fontWeight="700"
                color={blue}
                padding="4px 0"
                fontSize={fontSize + 2}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              <HMText
                text="Terms and conditions"
                fontWeight="800"
                color={black}
                padding="4px 0"
                fontSize={30}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={10}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              <HMText
                text="By accessing our website, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for complience with any applicable local laws."
                color={lightBlack}
                fontWeight="500"
                padding="5px 0"
                fontSize={fontSize + 2}
                textAlign="center"
              />
            </Grid>

            <Paragraph
              marginTop="5px"
              text=" This website is operated by HarakaMeds. Throughout the site, the terms “we”, “us” and “our” refer to HarakaMeds."
            />
            <Paragraph
              text="HarakaMeds offers this website, including all information, tools, and services available from this site to you, the user, 
            conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By visiting our site and/ or purchasing something from us, you engage in our “Service” 
            and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.
             These Terms of Service apply to all users of the site, 
            including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. "
            />

            <Paragraph
              text=" Please read these Terms of Service carefully before accessing 
            or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree
             to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer,
              acceptance is expressly limited to these Terms of Service. Any new features or tools which are added to the current store shall also be subject to the Terms of Service.
               You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting
                updates and/or changes to our website. It is your responsibility to check this page periodically for changes. 
            Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes."
            />

            <ParagraphTitle title="SECTION 1 – ONLINE STORE TERMS" />

            <Paragraph
              text="By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state, province of residence or 
            country, or that you are the age of majority in your state, province of residence, country and you have given us your consent to allow any of your minor dependents to use this site. You may not use our products
             for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or 
             any code of a destructive nature. A breach or violation of
             any of the Terms will result in an immediate termination of your Services."
            />

            <ParagraphTitle title="SECTION 2 – GENERAL CONDITIONS" />
            <Paragraph
              text="We reserve the right to refuse service to anyone for any reason at any time. You 
              understand that your content (not including credit card information), may be transferred unencrypted and involve (a)
               transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. 
               Credit card information is always encrypted during transfer over networks. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
               the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express wrote permission by us. The headings used in this agreement are 
               included for convenience only and will not limit or otherwise affect these Terms."
            />

            <ParagraphTitle title="SECTION 3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION" />
            <Paragraph
              text="We are not responsible if information made available on this site is not accurate, complete or
             current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis 
             for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material 
             on this site is at your own risk. This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your 
             reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site.
             You agree that it is your responsibility to monitor changes to our site."
            />

            <ParagraphTitle title="SECTION 4 – MODIFICATIONS TO THE SERVICE AND PRICES" />
            <Paragraph
              text="Prices for our products are subject to change without notice. We reserve the right at any 
            time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable 
            to you or to any third-party for any modification, price change, suspension or discontinuance of the Service."
            />

            <ParagraphTitle title="SECTION 5 – PRODUCTS OR SERVICES" />
            <Paragraph
              text="(if applicable) Certain products or services may be available exclusively online through the website. These products or 
            services may have limited quantities and are subject to return or exchange only according to our Return Policy. We have made every effort
             to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor’s
              display of any color will be accurate. We reserve the right but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. 
              We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing 
              are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void 
              where prohibited. We do not warrant that the quality of any products, services, information, 
            or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected."
            />

            <ParagraphTitle title="SECTION 6 – ACCURACY OF BILLING AND ACCOUNT INFORMATION"/>
            <Paragraph text="We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased
             per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card,
              and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify 
              you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, 
              in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all
               purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates so that we can complete
                your transactions and contact you as needed. For more detail, please review our Returns Policy."/>

                <ParagraphTitle title="SECTION 7 – OPTIONAL TOOLS"/>
                <Paragraph text="We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to 
                such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of 
                optional third-party tools. Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools
                 are provided by the relevant third-party provider(s). We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). 
                Such new features and/or services shall also be subject to these Terms of Service."/>

                <ParagraphTitle title="SECTION 8 – THIRD-PARTY LINKS"/>
                <Paragraph text="Certain content, products, and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for 
                examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. We are not liable for any harm or damages 
                related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, 
                or questions regarding third-party products should be directed to the third-party."/>

                <ParagraphTitle title="SECTION 9 – USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS"/>
                <Paragraph text="If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, 
                suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without restriction, edit, 
                copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or
                 (3) to respond to any comments. We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                  You agree that your comments will not violate any rights of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that
                   could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy.
                 We take no responsibility and assume no liability for any comments posted by you or any third-party."/>

                 <ParagraphTitle title="SECTION 10 – PERSONAL INFORMATION"/>
                 <Paragraph text="Your submission of personal information through the store is governed by our Privacy Policy. To view our " span="Privacy Policy."/>

                 <ParagraphTitle title="SECTION 11 – ERRORS, INACCURACIES, AND OMISSIONS"/>
                 <Paragraph text="Occasionally there may be information on our site or in the Service that contains typographical errors, 
                 inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or 
                 update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without
                  limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to 
                 indicate that all information in the Service or on any related website has been modified or updated."/>

                 <ParagraphTitle title="SECTION 12 – PROHIBITED USES"/>
                 <Paragraph text="In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in
                  any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property 
                  rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious
                   code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to 
                   interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use 
                 of the Service or any related website for violating any of the prohibited uses."/>

                 <ParagraphTitle title="SECTION 13 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY"/>
                 <Paragraph text="We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. 
                 You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products
                  and services delivered to you through the service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or 
                  conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. In no case shall HarakaMeds, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be
                   liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence),
                    strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a
                     result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or 
                 jurisdictions, our liability shall be limited to the maximum extent permitted by law."/>

                 <ParagraphTitle title="SECTION 14 – INDEMNIFICATION"/>
                 <Paragraph text="You agree to indemnify, defend and hold harmless HarakaMeds and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of these Terms of Service or the 
                 documents they incorporate by reference, or your violation of any law or the rights of a third-party."/>

                 <ParagraphTitle title="SECTION 15 – SEVERABILITY"/>
                 <Paragraph text="In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless 
                 be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the 
                 validity and enforceability of any other remaining provisions."/>

                 <ParagraphTitle title="SECTION 16 – TERMINATION"/>
                 <Paragraph text="The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all
                  purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; 
                 and/or accordingly may deny you access to our Services (or any part thereof)."/>

                 <ParagraphTitle title="SECTION 17 – ENTIRE AGREEMENT"/>
                 <Paragraph text="The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. 
                 These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and
                  us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). Any ambiguities in the 
                 interpretation of these Terms of Service shall not be construed against the drafting party."/>

                 <ParagraphTitle title="SECTION 18 – CHANGES TO TERMS OF SERVICE"/>
                 <Paragraph text="You can review the most current version of the Terms of Service at any time at this page. We reserve the right, at our sole discretion, to update, change or 
                 replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our
                  website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes."/>

                  <ParagraphTitle title="SECTION 19 – CONTACT INFORMATION"/>
                  <Paragraph text="Questions about the Terms of Service should be sent to us at harakameds@harakameds.com. In these Terms and Conditions: “Account” means the account that you will need to 
                  register for on the Site if you would like to submit an Order on the Site; “Confirmation of Order” means our email to you; “Customer” means individual who places an Order on the Site; “Order” means the order submitted by you to the Site to purchase 
                  a Product from us; “Product” means the goods or services provided on the Site; “you” means the Customer who places an Order; “Site” means HYPERLINK “https://www.harakameds.com” www.harakameds.com Ordering To place an order with us you must be over 18 years of age and possess a valid credit or debit card issued by a bank acceptable to us. You may not use our Product for any illegal or unauthorized
                   purpose nor may you, in the use of the Product, violate any laws in your jurisdiction (including but not limited to copyright laws)."/>

                   <Paragraph text="These Terms and Conditions apply to all Orders made or to be made by us for the sale and supply of Products. When you submit an Order to us,
                    give any delivery instruction or accept delivery of the Products, this shall, in any event, constitute your unqualified acceptance of these Terms and Conditions. These Terms and Conditions shall prevail over any separate terms put forward by
                     you. Any conditions that you submit, propose or stipulate in whatever form and at whatever time, whether in writing, by email or orally, are expressly waived and excluded.
                    No other terms or changes to the Terms and Conditions shall be binding unless agreed by us in writing."/>
                   <Paragraph text="We shall not be obliged to supply the Product to you until we have accepted your Order. Unless expressly stating that we accept your order, an email, letter, fax or another acknowledgment
                    of your Order by us is purely for information purposes and does not constitute the acceptance of Order. We may give you an Order reference number and details of the Product you have ordered and may at our discretion 
                    refuse to accept an Order from you for any reason, including unavailability of supplies or we may offer you an alternative Product (in which case we may require you to re-submit your Order). We may cancel an Order if the Product is not available for any 
                    reason and we will notify you if this is the case and return any payment that you have made. We will usually
                    refund any money received from you using the same method originally used by you to pay for the Product."/>

                    <ParagraphTitle title="Payment"/>
                    <Paragraph text="You must pay for the Product in full at the time of ordering by using mobile money or your credit/debit card from a credit/debit card company acceptable to us. You may also pay by any other 
                    method that we have represented that is acceptable to us, but in any event, we shall not be bound to supply the Product before we have received cleared funds in full. If you are asked for details of a payment card, you must be fully entitled to use that card or account. 
                    The card or account must have sufficient funds to cover the proposed payment to us."/>
                    <Paragraph text="You undertake and warrant that all details you provide to us for the purpose of purchasing the Product from us will be correct, that the credit or debit card, or account or other payment methods which
                     you use is your own and that there are sufficient funds or credit facilities to cover the cost of the Product. We reserve the right to obtain validation of your payment details before providing you with the Product. When you submit an Order to the Site, you agree that you do so subject to
                      these Terms and Conditions current at the date you submit 
                    your Order. You are responsible for reviewing the latest Terms and Conditions each time you submit your Order. "/>

                    <ParagraphTitle title="Delivery"/>
                    <Paragraph text="We aim to deliver the Product to you at the place of delivery requested by you in your Order. We aim to deliver within the time indicated by you at the time of your Order. We always aim to deliver within a reasonable time from the date and time of any Order placed on our platform. We shall use our reasonable endeavors to contact you if we expect that we are unable to meet our estimated
                     delivery date or any other communication related to your delivery."/>

                     <Paragraph text="On delivery of the Product, you may be required to sign upon receiving your delivery for record and acknowledgment. You agree to inspect the Product for any obvious faults, defects or damage before you sign for the delivery. We deliver in our standard packaging. Any special packaging requested by you is subject to additional charges. All risk in the Products shall pass to you upon delivery. From the time when risk passes to you, we will not be liable for the loss or destruction of the Product. You must take care when opening 
                     the Product so as not to damage it, particularly when using any sharp instruments."/>
                     <Paragraph text="HarakaMeds offers two types of delivery, Standard delivery, and Emergency delivery. The standard delivery which may take up to 24 hours costs 500 Rfw while on the other hand the Emergency
                      delivery which may take up 3 hours only is priced at 1,500 Rfw."/>
                      <Paragraph text="HarakaMeds reserves all the rights to make changes the delivery fees, changes may be due to promotion, or other metrics. To stay updated,
                       keep an eye on this page or our ongoing promotions and updates."/>

                      <ParagraphTitle title="Fault"/>
                      <Paragraph text="We warrant that: the Product will be delivered undamaged in the quantities ordered, and the Product will conform with the description as set out on the at the time of your Order. Before delivery, we may make minor adjustments to material, color, weight, measurements, design and other features to the extent that they are reasonable. We will use our reasonable 
                      endeavors to ensure the Product is delivered in acceptable condition."/>
                      <Paragraph text="In order to provide you with any remedies for a faulty Product, we may need your assistance and prompt provision of certain information 
                      regarding the Product, including you, specifying with reasonable detail the way in which it is alleged that the Product is damaged or defective; and you providing us with any information as we reasonably require. If you would like us to replace or provide a refund for the Product where it did conform to the Order and we (at our sole discretion) determine that the Product has: been misused, abused or subjected to neglect; been involved in an accident or damage caused by an incorrect attempt at use; or been dealt with or used contrary to its ordinary use; we may decide not to
                       replace or refund you for the Product and we may require you to pay all reasonable carriage costs and servicing costs."/>
                      <Paragraph text="To the extent permitted by law, we shall not be liable to you for any losses, liabilities, costs, damages, charges or expenses as a result. Limitation
                       of Liability You acknowledge and agree that we limit our liability in respect of all claims, at our option, to the supply of the Product again; or the payment of the cost of having the Product supplied again. You agree that our total aggregate liability for all claims relating to the Product is limited to 100,000.00 Rfw. You agree to indemnify us in relation to all claims, actions, liabilities, costs, and expenses (including legal costs on a full indemnity basis) resulting from any defective Product. To the full extent permitted by law, we exclude all representations or terms 
                      (whether express or implied) other than those expressly set out on the Site and these Terms and Conditions."/>
                      <Paragraph text="We shall not be liable to you for any breach, hindrance or delay in the performance of an Order attributable to any cause beyond our reasonable control, including without limitation any natural disaster, unavoidable incidents and actions of third parties regardless of whether the circumstances in question could have been foreseen. General Provisions No failure or delay by us in exercising any right under these Terms and Conditions shall operate as a waiver of such right or extend to or affect any other or subsequent event or impair any rights or remedies in
                       respect of it or in any way modify or diminish our rights under these Terms and Conditions."/>
                       <Paragraph text="If a provision of these Terms and Conditions is invalid or unenforceable it is to be read down or severed to the extent necessary without affecting the validity or enforceability of the remaining provisions. Nothing in these Terms and Conditions shall create or be deemed to create a partnership, an agency or a relationship of employer and employee between you and us. No person who is not a party to these Terms and Conditions or an Order shall acquire any rights under it or be entitled to benefit from any of its terms even if that person has relied on any such 
                       term or has indicated to any party to these Terms and Conditions or that Order its assent to any such term."/>

          </Grid>
        </Section>
      </FullWidthContainer>
    </Page>
  )
}

export default TermsAndConditions

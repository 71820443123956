import React from 'react'
import { Grid } from '@mui/material'

function FullWidthContainer(props) {
  
  const gridStyle = {
    width: '100%',
    margin: props.margin,
    display: props.display,
    padding: props.padding,
    backgroundColor: props.bgColor,
    marginTop: `${props.marginTop}px`,
    flexDirection: props.flexDirection,
    marginBottom: `${props.marginBottom}px`,
  }
  return (
    <Grid sx={gridStyle} className={props.className}>
        {props.children}
    </Grid>
  )
}

export default FullWidthContainer
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import HMPasswordField from '../tools/HMPasswordField'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import {
    textStyle
} from '../tools/Styles'
import {
    blue,
    orange,
} from '../../../hooks/useColors'

function HMLoginForm(props) {
    const navigate = useNavigate()

    const {
        email,
        password,
        rememberMe
    } = props.formData

    const handleRegister = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(false)
            props.setIsRegister(true)
            props.setIsForgotPassword(false)
        } else {
            navigate('/register')
        }
    }

    const handleForgotPassword = () => {
        if (props.isModalLogin) {
            props.setIsOTP(false)
            props.setIsLogin(false)
            props.setIsRegister(false)
            props.setIsForgotPassword(true)
        } else {
            navigate(
                props.path.includes('admin') ? '/admin/forgot-password'
                    : props.path.includes('store/') ? '/store/forgot-password'
                    : props.path.includes('supplier') ? 'supplier/forgot-password'
                    : '/forgot-password'
            )
        }
    }

    return (
        <Grid align='center' sx={{ margin: 'auto' }} className='HM-Login fade-in'>
            <form onSubmit={props.onSubmit}>
                <HMTextField 
                    name='email' 
                    type='email'
                    label='Email'
                    value={email}
                    required={true}
                    className='fade-in'
                    onChange={props.onChange}
                    placeholder='Enter your email'
                />
                <HMPasswordField 
                    value={password}
                    className='fade-in'
                    onChange={props.onChange}
                />
                <FormControlLabel
                    label='Remember me'
                    className='fade-in'
                    sx={{ width: '100%' }}
                    control={
                        <Checkbox
                            id='rememberMe'
                            name='rememberMe'
                            checked={rememberMe}
                            onChange={props.onChange}
                        />
                    }
                />
                <HMButton 
                    text='Login'
                    width='100%'
                    type='submit'
                    padding='8px 6px'
                    className='fade-in'
                    paddingActive='7.5px 5.5px'
                    margin='10px auto 15px auto'
                    bgColor={props.buttonBgColor || orange}
                />
            </form>
            <GoogleLogin
                useOneTap
                theme='filled_blue'
                onError={(error) => props.onError(error)}
                onSuccess={(response) => props.onSuccess(response)}
                auto_select
            />
            <Typography 
                className='fade-in'
                sx={{ ...textStyle, marginTop: '15px' }}
            >  
                <Link 
                    onClick={handleForgotPassword}
                    sx={{
                        color: blue,
                        fontWeight: 500,
                        cursor: 'pointer',
                        textDecoration: 'none'   
                    }}
                >
                    Forgot Password?
                </Link>
            </Typography>
            {(!props.path.includes('admin') && !props.path.includes('store/') && !props.path.includes('supplier')) ? (
                <>
                    <Typography 
                        sx={textStyle}
                        className='fade-in'
                    >  
                        Do not have an account?
                    </Typography>
                    <Typography 
                        sx={textStyle}
                        className='fade-in'
                    > 
                        <Link 
                            onClick={handleRegister}
                            sx={{
                                color: blue,
                                fontWeight: 500,
                                cursor: 'pointer',
                                textDecoration: 'none'   
                            }}
                        >
                            Register
                        </Link>
                    </Typography>
                </>
            ) : <></>}
        </Grid>
    )
}

export default HMLoginForm
import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import Box from '@mui/material/Box'

function HMEditor({placeholder, value, onBlur}) {

  const config = {
    readonly: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: "bold,italic,underline,ol,ul,link, table, align, brush",
    buttonsMD: "bold,italic,underline,ol,ul,link, table, align, brush",
    buttonsSM: "bold,italic,underline,ol,ul,link, table, align, brush",
    buttonsXS: "bold,italic,underline,ol,ul,link, table, align, brush",
    placeholder: placeholder || 'Start typings...',
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false
  }

  const editor = useRef(null)

  return (
    <Box>

      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        tabIndex={5} 
        onBlur={(content) => onBlur(content)} 
      />
    </Box>
  )
}

export default HMEditor